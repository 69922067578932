import {
  DATE_HYPHEN_FORMAT,
  REGEX_REPLACE_TEMPERATURE,
  TIME_PICKER_FORMAT,
} from "@constants/common";
import { TSelectValue } from "@models/common";
import {
  TSensorByParamReq,
  TSensorByParamRes,
  TSensorsReq,
  TTableDefrost,
  TUpdateSensorReq,
} from "@models/device";
import { TCompanyReq } from "@models/layout";
import { openCaseApi } from "@services/openCaseApi";
import { handleShowToastError } from "@utils/helpers";
import { Form, RadioChangeEvent } from "antd";
import dayjs, { Dayjs } from "dayjs";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { getColumns2, getColumns4 } from "../columns";
import { DOT_CHART, OPEN_CASE_CONSTANT, RADIO_OPT } from "../constant";
import { renderInstallLocation, renderTemperatureType } from "../utils/helpers";
import useOpenCase2 from "./useOpenCase2";
import useOpenCase3 from "./useOpenCase3";
import { useSearchParams } from "react-router-dom";

const { TITLE, NAME } = OPEN_CASE_CONSTANT;

export const defaultDotStyle = {
  fill: "",
  stroke: "",
};

function useOpenCase() {
  const { state, handler, effect } = useOpenCase2();
  const { handler: handler2 } = useOpenCase3();

  const [loadingPage, setLoadingPage] = useState(true);
  const [openModalSetting, setOpenModalSetting] = useState(false);
  const [dotStyle, setDotStyle] = useState<{
    fill: string;
    stroke: string;
  }>(defaultDotStyle);

  const [formDate] = Form.useForm();
  const [form] = Form.useForm();
  const [companies, setCompanies] = useState<TSelectValue[]>([]);
  const [stores, setStores] = useState<TSelectValue[]>([]);
  const [idCh, setIdCh] = useState<TSelectValue[]>([]);
  const [radioOpt, setRadioOpt] = useState(1);
  const [tableDefrost, setTableDefrost] = useState<TTableDefrost[]>([]);
  const [batteryValue, setBatteryValue] = useState("");
  const [latestDay, setLatestDay] = useState("");
  const [purposeId, setPurposeId] = useState("");
  const [sensorId, setSensorId] = useState("");
  const [purposeIdSetting, setPurposeIdSetting] = useState("");
  const [loadingIdCh, setLoadingIdCh] = useState(false);
  const [loadingStore, setLoadingStore] = useState(false);

  const [reportDate, setReportDate] = useState<Dayjs>(dayjs().subtract(1, "d"));

  const [formSetting] = Form.useForm();
  const [messageTemp, setMessageTemp] = useState("");
  const watchedLowerNumber = Form.useWatch(NAME.LOWER_NUMBER, formSetting);
  const watchedUpperNumber = Form.useWatch(NAME.UPPER_NUMBER, formSetting);
  const watchedSetting = Form.useWatch(NAME.SETTING, formSetting);
  const watchedLowerInput = Form.useWatch(NAME.LOWER_INPUT, formSetting);
  const watchedUpperInput = Form.useWatch(NAME.UPPER_INPUT, formSetting);

  const [searchParams, setSearchParams] = useSearchParams();

  const renderColumns2 = useMemo(() => {
    const columns = getColumns2(radioOpt, formSetting);
    return columns;
  }, [radioOpt]);

  const renderColumns4 = useMemo(() => {
    const columns = getColumns4(reportDate);
    return columns;
  }, [reportDate]);

  const handleOpenModalSetting = () => {
    if (radioOpt === RADIO_OPT && +watchedLowerNumber > +watchedUpperNumber) {
      setMessageTemp("下限 <= 上限である必要があります。");
    } else {
      setMessageTemp("");
      setOpenModalSetting(true);
    }
  };

  const handleCloseModalSetting = () => {
    setOpenModalSetting(false);
  };

  const handleChangeRadioOption = (e: RadioChangeEvent) => {
    if (e.target.value === 1) {
      setMessageTemp("");
    }
    setRadioOpt(e.target.value);
  };

  const handleChangeSelect = (val: string) => {
    const selectedPurpose = _.find(state.purposes, function (item) {
      return item.purposeCode === val;
    });

    handler.handleCheckSelectedPurpose(
      selectedPurpose,
      formSetting,
      setPurposeId
    );
  };

  const handleUpdateTemperatureSensor = () => {
    if (radioOpt === 1) {
      updateTemperatureSensor({
        isUseManualSetting: false,
        maxTemp: watchedUpperInput,
        minTemp: watchedLowerInput,
        purposeId: purposeId,
        reasonSetting: "",
        sensorId,
      });
    } else {
      const _upperNumber = +watchedUpperNumber.replace(
        REGEX_REPLACE_TEMPERATURE,
        ""
      );
      const _lowerNumber = +watchedLowerNumber.replace(
        REGEX_REPLACE_TEMPERATURE,
        ""
      );
      updateTemperatureSensor({
        isUseManualSetting: true,
        maxTemp: Number.isInteger(+_upperNumber)
          ? `${_upperNumber}.0`
          : _upperNumber.toString(),
        minTemp: Number.isInteger(+_lowerNumber)
          ? `${_lowerNumber}.0`
          : _lowerNumber.toString(),
        purposeId: purposeIdSetting,
        reasonSetting: watchedSetting,
        sensorId,
      });
    }
  };

  const handleResetIdCh = () => {
    setIdCh([]);
    searchParams.delete("chCode");
    setSearchParams(searchParams, { replace: true });
    form.setFieldValue(NAME.ID_CH, "");
  };

  const handleResetStore = () => {
    setStores([]);
    form.setFieldValue(NAME.STORE, "");
    searchParams.delete("storeCode");
    setSearchParams(searchParams, { replace: true });
  };

  const handleChangeCompany = (val: string) => {
    handleResetIdCh();
    handleResetStore();

    const companyName = _.filter(
      companies,
      (company) => company.value === val
    )[0].label;

    getStoresByCompany({
      companyName,
      companyCode: val,
    });
    handler.getAllPurposes(
      {
        companyCode: val,
        companyName,
      },
      formSetting
    );
  };

  const handleChangeStore = (val: string) => {
    handleResetIdCh();
    const companyCode = form.getFieldValue(NAME.COMPANY);

    getChCodeSensor({
      companyCode,
      storeCode: val,
    });
  };

  const handleChangeReportDate = (val: Dayjs) => {
    setReportDate(val);
    setDotStyle(defaultDotStyle);
    searchParams.set("reportDate", val.format(DATE_HYPHEN_FORMAT));
    setSearchParams(searchParams, { replace: true });

    handler.getReportTemperatureSensor(
      {
        sensorId,
        reportDate: val.format(DATE_HYPHEN_FORMAT),
      },
      setLoadingPage
    );
    handler.getTemperatureSensor(
      {
        sensorId,
        measuredAt: val.format(DATE_HYPHEN_FORMAT),
      },
      setLoadingPage
    );
  };

  const handleGetSensorByParam = (val: TSensorByParamReq) => {
    getSensorByParam(
      {
        companyCode: val.companyCode,
        storeCode: val.storeCode,
        chCode: val.chCode,
      },
      reportDate.format(DATE_HYPHEN_FORMAT)
    );
  };

  const handleSetSearchParams = (
    payload: TSensorByParamReq,
    reportDate: string
  ) => {
    searchParams.set("companyCode", payload.companyCode);
    searchParams.set("storeCode", payload.storeCode);
    searchParams.set("chCode", payload.chCode);
    searchParams.set("reportDate", reportDate);
    setSearchParams(searchParams, { replace: true });
  };

  const handleMappingGetSensorByParam = (response: TSensorByParamRes) => {
    // 除霜情報 - Table defrost
    setTableDefrost([
      {
        key: 1,
        weather: TITLE.SPRING,
        startDate: response.springStartDate,
        startDate1: response.springStartTime1,
        startDate2: response.springStartTime2,
        startDate3: response.springStartTime3,
        startDate4: response.springStartTime4,
        startDate5: response.springStartTime5,
        startDate6: response.springStartTime6,
      },
      {
        key: 2,
        weather: TITLE.SUMMER,
        startDate: response.summerStartDate,
        startDate1: response.summerStartTime1,
        startDate2: response.summerStartTime2,
        startDate3: response.summerStartTime3,
        startDate4: response.summerStartTime4,
        startDate5: response.summerStartTime5,
        startDate6: response.summerStartTime6,
      },
      {
        key: 3,
        weather: TITLE.AUTUMN,
        startDate: response.autumnStartDate,
        startDate1: response.autumnStartTime1,
        startDate2: response.autumnStartTime2,
        startDate3: response.autumnStartTime3,
        startDate4: response.autumnStartTime4,
        startDate5: response.autumnStartTime5,
        startDate6: response.autumnStartTime6,
      },
      {
        key: DOT_CHART,
        weather: TITLE.WINTER,
        startDate: response.winterStartDate,
        startDate1: response.winterStartTime1,
        startDate2: response.winterStartTime2,
        startDate3: response.winterStartTime3,
        startDate4: response.winterStartTime4,
        startDate5: response.winterStartTime5,
        startDate6: response.winterStartTime6,
      },
    ]);
    // ID, 管理CH - Form
    const formValues = {
      [NAME.MACHINE]: response.machine,
      [NAME.INSTALL_LOCATION]: renderInstallLocation(
        response.installedLocation
      ),
      [NAME.DEPARTMENT_NAME]: response.departmentName,
      [NAME.PURPOSE]: response.purposeName,
      [NAME.MANUFACTURER_NAME]: response.manufacturerName,
      [NAME.MANUFACTURER_MODEL]: response.modelCode,
      [NAME.OPERATING_HOUR_FROM]: [
        dayjs(response.timeStart, TIME_PICKER_FORMAT),
        dayjs(response.timeEnd, TIME_PICKER_FORMAT),
      ],
      [NAME.TEMPERATURE_DISTINCTION]: renderTemperatureType(
        response.temperatureType
      ),
    };
    const formSettingValues = {
      [NAME.LOWER_INPUT]: response.purposeMinTemp,
      [NAME.UPPER_INPUT]: response.purposeMaxTemp,
      [NAME.SETTING]: response.reasonSetting,
      [NAME.LOWER_NUMBER]: response.minTemp,
      [NAME.UPPER_NUMBER]: response.maxTemp,
    };
    form.setFieldsValue(formValues);
    formSetting.setFieldsValue(formSettingValues);

    setBatteryValue(response.batteryValue);
    setSensorId(response.id);
    setPurposeIdSetting(response.purposeId);
    setPurposeId(response.purposeId);
    if (response.latestHistorySettingThreshold) {
      setLatestDay(
        dayjs(response.latestHistorySettingThreshold).format("YYYY年MM月DD日")
      );
    }
    if (state.isRoleStore) {
      handler2.handleMappingPurposeRoleStore(
        handler.setSelectPurpose,
        response.purposeCode,
        response.purposeName
      );
      formSetting.setFieldValue(NAME.SELECT, response.purposeCode);
    } else {
      formSetting.setFieldValue(NAME.SELECT, response.purposeCode.toString());
    }

    handler.handleCheckUsePurpose(response, setRadioOpt);
  };

  const handleGetTemperature = (id: string, date: string) => {
    handler.getReportTemperatureSensor({
      sensorId: id,
      reportDate: date,
    });
    handler.getTemperatureSensor({
      sensorId: id,
      measuredAt: date,
    });
  };

  const handleErrorGetSensor = (error: any) => {
    handler.handleErrorGetSensor({
      error,
      form,
      formSetting,
      setTableDefrost,
      setRadioOpt,
      setBatteryValue,
      setLatestDay,
      setPurposeId,
      setPurposeIdSetting,
      setSensorId,
    });
  };

  /** API */

  const getSensorByParam = async (
    payload: TSensorByParamReq,
    reportDate: string
  ) => {
    setLoadingPage(true);

    try {
      const response = await openCaseApi.getSensorByParam(payload);

      handleMappingGetSensorByParam(response);
      handleSetSearchParams(payload, reportDate);
      handleGetTemperature(response.id, reportDate);
    } catch (error) {
      handleShowToastError(error);
      handleErrorGetSensor(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const getStoresByCompany = (payload: TCompanyReq) => {
    handler2.getStoresByCompany(
      payload,
      setStores,
      setLoadingStore,
      setLoadingIdCh,
      setIdCh,
      getChCodeSensor
    );
  };

  const getChCodeSensor = (payload: TSensorsReq) => {
    handler2.getChCodeSensor(payload, setLoadingIdCh, setIdCh);
  };

  const updateTemperatureSensor = async (payload: TUpdateSensorReq) => {
    setLoadingPage(true);

    try {
      const response = await openCaseApi.updateTemperatureSensor(payload);

      if (response.ok) {
        handleMappingGetSensorByParam(response.data);
      }
    } catch (error) {
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
      handleCloseModalSetting();
    }
  };

  /** USE EFFECT */
  useEffect(() => {
    if (!state.isRoleStore) {
      handler2.fetchInitialApis(
        form,
        formDate,
        searchParams,
        handleErrorGetSensor,
        setLoadingPage,
        setLoadingStore,
        setLoadingIdCh,
        handleMappingGetSensorByParam,
        handleSetSearchParams,
        setCompanies,
        setStores,
        setIdCh,
        reportDate,
        setReportDate,
        handleGetTemperature,
        handler.getAllPurposes
      );
    } else {
      const companyCode = searchParams.get("companyCode") || state._companyCode;
      const storeCode = searchParams.get("storeCode") || state._storeCode;
      const chCode = searchParams.get("chCode") || idCh?.[0]?.value;
      const _reportDate =
        searchParams.get("reportDate") || reportDate.format(DATE_HYPHEN_FORMAT);

      setLoadingPage(false);

      // TODO: Handle logic role store
      setReportDate(dayjs(_reportDate));
      handler2.handleMappingCompanyRoleStore(
        setCompanies,
        companyCode,
        state._companyName
      );
      handler2.handleMappingStoreRoleStore(
        setStores,
        storeCode,
        state._storeName
      );
      form.setFieldValue(NAME.COMPANY, companyCode);
      form.setFieldValue(NAME.STORE, storeCode);
      formDate.setFieldValue(NAME.REPORT_DATE, dayjs(_reportDate));

      handler2.getChCodeSensor(
        {
          companyCode,
          storeCode,
        },
        setLoadingIdCh,
        setIdCh
      );

      getSensorByParam({ chCode, companyCode, storeCode }, _reportDate);
    }
  }, []);

  useEffect(() => {
    effect.effectCompanies(companies, form, searchParams);
  }, [companies]);

  useEffect(() => {
    effect.effectStores(stores, form, searchParams);
  }, [stores]);

  useEffect(() => {
    effect.effectIdCh(idCh, form, searchParams);
  }, [idCh]);

  return {
    state: {
      watchedLowerNumber,
      watchedUpperNumber,
      formDate,
      loadingPage,
      isRoleStore: state.isRoleStore,

      form,
      tableDefrost,
      batteryValue,
      stores,
      companies,
      idCh,
      selectPurpose: state.selectPurpose,
      loadingStore,
      loadingIdCh,

      formSetting,
      radioOpt,
      renderColumns2,
      openModalSetting,
      messageTemp,
      latestDay,

      reportDate,
      reportTemp: state.reportTemp,
      hourTemp: state.hourTemp,
      chartTemp: state.chartTemp,
      renderColumns4,
      dotStyle,
      rangeTemp: state.rangeTemp,
    },
    handler: {
      setDotStyle,
      handleOpenModalSetting,
      handleCloseModalSetting,
      handleChangeRadioOption,
      handleUpdateTemperatureSensor,
      handleChangeCompany,
      handleChangeStore,
      handleChangeReportDate,
      handleGetSensorByParam,
      handleChangeSelect,
      handleNavigateBack: handler.handleNavigateBack,
    },
  };
}

export default useOpenCase;
