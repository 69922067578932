import { TableProps } from "antd/lib";
import { FILE_MANAGE_CONSTANT } from "./constant";
import {
  DeleteOutlined,
  DownloadOutlined,
  FolderViewOutlined,
} from "@ant-design/icons";
import { TFileDocumentConvert } from "@models/file";
import { Button, Tooltip } from "antd";
import { COLOR_TOOLTIP } from "@constants/common";

type TTable = {
  setModalView: (val: boolean) => void;
  setModalDownload: (val: boolean) => void;
  setModalDelete: (val: boolean) => void;
  setInfo: (val: any) => void;
};

const { TITLE, TABLE_KEY } = FILE_MANAGE_CONSTANT;

const handleMappingStatusFile = (status: string) => {
  switch (status) {
    case TITLE.PENDING:
      return "pending";
    case TITLE.EXPIRED:
      return "expired";
    case TITLE.ERROR:
      return "error";
    default:
      return "success";
  }
};

export const customColumns = ({
  setInfo,
  setModalDelete,
  setModalDownload,
  setModalView,
}: TTable): TableProps<TFileDocumentConvert>["columns"] => {
  return [
    {
      title: TITLE.NO,
      key: TABLE_KEY.NO,
      dataIndex: TABLE_KEY.NO,
      align: "center",
      width: 100,
    },
    {
      title: TITLE.FILE_NAME,
      key: TABLE_KEY.FILE_NAME,
      dataIndex: TABLE_KEY.FILE_NAME,
      align: "center",
      width: 500,
      render(value) {
        return (
          <div className="management-tab_ellipsis">
            <Tooltip
              color="white"
              overlayClassName="common_custom-tooltip"
              title={value}
              overlayInnerStyle={{
                color: COLOR_TOOLTIP,
              }}
            >
              {value}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: TITLE.STATUS,
      key: TABLE_KEY.STATUS,
      dataIndex: TABLE_KEY.STATUS,
      align: "center",
      width: 210,
      render(value) {
        return (
          <span
            className={`management-tab_status management-tab_status-${handleMappingStatusFile(
              value
            )}`}
          >
            {value}
          </span>
        );
      },
    },
    {
      title: TITLE.AUTHOR,
      key: TABLE_KEY.AUTHOR,
      dataIndex: TABLE_KEY.AUTHOR,
      align: "center",
      width: 200,
    },
    {
      title: TITLE.DOWNLOAD_DATE,
      key: TABLE_KEY.DOWNLOAD_DATE,
      dataIndex: TABLE_KEY.DOWNLOAD_DATE,
      align: "center",
      width: 220,
    },
    {
      title: TITLE.DOWNLOAD_TIME,
      key: TABLE_KEY.DOWNLOAD_TIME,
      dataIndex: TABLE_KEY.DOWNLOAD_TIME,
      align: "center",
      width: 220,
    },
    {
      title: TITLE.ACTION,
      align: "center",
      width: 300,
      render(_, record) {
        const disableBtnDownload = record.status !== "成功";

        return (
          <div className="management-tab_modal-btns">
            <Button
              onClick={() => {
                setModalView(true);
                setInfo(record);
              }}
            >
              <FolderViewOutlined
                style={{
                  fontSize: "21px",
                  color: "#344054",
                  cursor: "pointer",
                }}
              />
            </Button>
            <Button
              disabled={disableBtnDownload}
              onClick={() => {
                setModalDownload(true);
                setInfo(record);
              }}
            >
              <DownloadOutlined
                style={{
                  fontSize: "21px",
                  color: `${disableBtnDownload ? "#99a6bb" : "#344054"}`,
                  cursor: "pointer",
                }}
              />
            </Button>
            <Button
              onClick={() => {
                setModalDelete(true);
                setInfo(record);
              }}
            >
              <DeleteOutlined
                style={{
                  fontSize: "21px",
                  color: "#F04438",
                  cursor: "pointer",
                }}
              />
            </Button>
          </div>
        );
      },
    },
  ];
};
