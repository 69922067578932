import { LoadingOutlined } from "@ant-design/icons";
import {
  ArrowSelect,
  IconPlusRegister,
  LabelForm,
  LoadingCommon,
  TableList,
} from "@components";
import { images } from "@constants";
import { PLACEHOLDER_INPUT, PLACEHOLDER_SELECT } from "@constants/common";
import { ArrowLeftIcon, SearchIcon } from "@constants/svgs";
import { Button, Form, Input, Select } from "antd";
import { EModalType } from "types/others";
import { MAX_LENGTH_50, COMPANY_CONSTANT, MAX_LENGTH_5 } from "./constant";
import useCompanyPage from "./hooks/useCompanyPage";
import {
  ModalDeleteWrapper,
  ModalWrapper,
  CompanyWrapper,
} from "./CompanyStyled";

const { TITLE, LABEL, NAME, MESSAGE } = COMPANY_CONSTANT;

function CompanyPage() {
  const { state, handler } = useCompanyPage();
  const FIELDS_CONFIG = {
    COMPANY: { required: false, label: LABEL.COMPANY, name: NAME.COMPANY },

    COMPANY_CODE: {
      required: false,
      label:
        state.modalType != EModalType.DETAIL ? (
          <LabelForm label={LABEL.COMPANY_CODE} />
        ) : (
          LABEL.COMPANY_CODE
        ),
      name: NAME.COMPANY_CODE,
    },
    COMPANY_NAME: {
      required: false,
      label:
        state.modalType != EModalType.DETAIL ? (
          <LabelForm label={LABEL.COMPANY_NAME} />
        ) : (
          LABEL.COMPANY_NAME
        ),
      name: NAME.COMPANY_NAME,
    },
  };
  return (
    <LoadingCommon loading={state.loadingPage}>
      <CompanyWrapper listdata={state.listCompany.length}>
        <div className="company-page_header">
          <h1>{TITLE.COMPANY_LIST}</h1>
          <Button
            onClick={handler.handleOpenModalRegistration}
            type="primary"
            icon={<IconPlusRegister />}
            disabled={state.disableBtnOther}
          >
            {TITLE.BTN_REGISTRATION}
          </Button>
        </div>

        <div className="company-page_form">
          <Form
            onFinish={handler.handleFinish}
            form={state.form}
            layout="vertical"
          >
            {/* COMPANY */}
            <Form.Item {...FIELDS_CONFIG.COMPANY}>
              <Select
                placeholder={PLACEHOLDER_SELECT}
                allowClear={false}
                size="large"
                options={state.companies}
                suffixIcon={
                  state.loadingCompany ? (
                    <LoadingOutlined spin={state.loadingCompany} />
                  ) : (
                    <ArrowSelect />
                  )
                }
              />
            </Form.Item>

            <Button htmlType="submit" icon={<SearchIcon />} />
          </Form>
        </div>

        <TableList
          tableRef={state.tblRef}
          image={images.noResult}
          columns={state.columns}
          handleScroll={handler.handleScroll}
          listData={state.listCompany}
          messageNoData={MESSAGE.NO_DATA}
          rowKey={state.rowKey}
          borderColor="var(--color-border-secondary)"
        />
      </CompanyWrapper>

      <ModalWrapper
        width={800}
        title={
          <>
            {state.modalType === EModalType.CONFIRM_REGISTRATION && (
              <button
                onClick={() => handler.setModalType(EModalType.REGISTRATION)}
              >
                <ArrowLeftIcon />
              </button>
            )}
            {state.modalType === EModalType.CONFIRM_UPDATE && (
              <button onClick={() => handler.setModalType(EModalType.UPDATE)}>
                <ArrowLeftIcon />
              </button>
            )}
            <span>{state.renderTextModal.title}</span>
          </>
        }
        closeIcon={false}
        maskClosable={false}
        okText={state.renderTextModal.okText}
        cancelText={TITLE.BTN_CANCEL}
        destroyOnClose={true}
        open={state.modalOpen}
        centered
        onCancel={() => {
          handler.handleCloseModal();
        }}
        loading={state.loadingModal}
        confirmLoading={state.loadingExist}
        onOk={() => {
          if (state.modalType === EModalType.DETAIL) {
            handler.handleCloseModal();
          } else if (state.modalType === EModalType.UPDATE) {
            handler.handleValidateForm();
          } else if (state.modalType === EModalType.CONFIRM_REGISTRATION) {
            handler.handleOpenModalConfirmFinal();
          } else if (state.modalType === EModalType.CONFIRM_UPDATE) {
            handler.handleOpenModalConfirmFinal();
          } else {
            handler.handleValidateForm();
          }
        }}
        okButtonProps={{
          style: {
            width: state.modalType === EModalType.DETAIL ? "100%" : "368px",
          },
          disabled: state.disableBtnNext,
        }}
        cancelButtonProps={{
          style: {
            display: state.modalType === EModalType.DETAIL ? "none" : "block",
          },
        }}
      >
        <Form
          layout="vertical"
          form={state.formModal}
          preserve={false}
          disabled={state.disableFields}
        >
          <div className="company-page_modal-form-left">
            <Form.Item {...FIELDS_CONFIG.COMPANY_CODE}>
              <Input
                placeholder={PLACEHOLDER_INPUT}
                size="large"
                maxLength={MAX_LENGTH_5}
                onBlur={(event) => {
                  state.formModal.setFieldValue(
                    NAME.COMPANY_CODE,
                    event.target.value.trim()
                  );
                }}
              />
            </Form.Item>
          </div>

          <div className="company-page_modal-form-right">
            <Form.Item {...FIELDS_CONFIG.COMPANY_NAME}>
              <Input
                placeholder={PLACEHOLDER_INPUT}
                size="large"
                maxLength={MAX_LENGTH_50}
                onBlur={(event) => {
                  state.formModal.setFieldValue(
                    NAME.COMPANY_NAME,
                    event.target.value.trim()
                  );
                }}
              />
            </Form.Item>
          </div>
        </Form>
      </ModalWrapper>

      {/* Modal delete */}
      <ModalDeleteWrapper
        centered
        closeIcon={false}
        open={state.modalDelete}
        title={TITLE.TITLE_DELETE}
        confirmLoading={state.loadingPage}
        onOk={handler.handleOkModalDelete}
        onCancel={handler.handleCloseModalDelete}
        maskClosable={false}
        cancelText={TITLE.BTN_CANCEL}
        okText={TITLE.BTN_DELETE}
        width={416}
      >
        <img src={images.modalDelete} alt="modal_delete" />
        <div className="company-page_modal-delete">
          <p>
            {state.company?.companyCode}_{state.company?.companyName}
          </p>
          <p>{MESSAGE.CONFIRM_DELETE}</p>
        </div>
      </ModalDeleteWrapper>

      {/* Modal registration/update/delete success/fail */}
      <ModalDeleteWrapper
        centered
        closeIcon={false}
        open={state.modalSuccess}
        title={state.renderTextModal.title}
        onOk={handler.handleCloseModalSuccess}
        maskClosable={false}
        okText={state.renderTextModal.okText}
        width={412}
        okButtonProps={{ style: { width: "100%", marginInlineStart: 0 } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <img src={state.renderTextModal.image} alt="company_modal" />
        <div className="company-page_modal-regist-success">
          <p>{state.renderTextModal.message}</p>
        </div>
      </ModalDeleteWrapper>

      {/* Modal registration/update confirm */}
      <ModalDeleteWrapper
        centered
        closeIcon={false}
        open={state.modalConfirm}
        title={state.renderTextModalConfirm.title}
        onOk={handler.handleOkModalConfirmFinal}
        onCancel={handler.handleCloseModalConfirmFinal}
        maskClosable={false}
        okText={state.renderTextModalConfirm.okText}
        cancelText={TITLE.BTN_CANCEL}
        width={412}
        zIndex={9999}
        confirmLoading={state.loadingPage}
      >
        <img src={images.popUpConfirmFinal} alt="company_modal" />
        <div className="company-page_modal-confirm">
          <p>{state.renderTextModalConfirm.message}</p>
        </div>
      </ModalDeleteWrapper>
    </LoadingCommon>
  );
}

export { CompanyPage };
