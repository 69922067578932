export const TEMPERATURE_CONSTANT = {
  NAME: {
    COMPANY: "companyCode",
    STORE: "storeCode",
    DATE: "reportDate",
    PURPOSE: "purposeCode",
    MANAGER: "storeManagerName",
    COMMENT: "comment",
    REMARK: "remark",
    START_DATE: "from",
    END_DATE: "to",
    CHECKBOX: "checkbox",
  },
  LABEL: {
    COMPANY: "会社",
    STORE: "店舗",
    DATE: "日付",
    MANAGER: "店長",
    PURPOSE: "売場",
    DETAIL_TEMPERATURE: "詳細温度の表示",
    COMMENT: "コメント",
    LAYOUT: "レイアウト",
  },
  TITLE: {
    N_A: "N/A",
    NONE: "なし",
    CONFIRMED_COMMENT: "確認済み",
    UNCONFIRMED: "未確認",
    TEMPERATURE_REPORT: "庫内温度日報",
    BTN_EXPORT: "エクスポート",
    BTN_ADMIT: "確認 ",

    // Column
    CH: "CH",
    DEPARTMENT_NAME: "売場名",
    DEPARTMENT_NAME2: "部門名",
    TEMPERATURE_DISTINCTION: "温度区分",
    CONTROLLED_TEMPERATURE: "管理温度帯",
    LOWER_LIMIT: "下限",
    UPPER_LIMIT: "上限",
    INTERNAL_TEMPERATURE: "庫内温度",
    OUTSIDE_TEMPERATURE_RANGE: "管理温度範囲外",
    REMARKS: "備考",
    REFRIGERATE: "冷蔵",
    FREEZE: "冷凍",
    NOT_REFRIGERATE: "非冷",
    NOT_USE: "使用しない",

    // Modal
    CONFIRM: "温度日報の確認",
    APPROVE: "OK",
    REFUSE: "キャンセル",

    DOWNLOAD: "ダウンロード",
    DOWNLOAD_EXCEL: "Excelダウンロード",
    CANCEL: "キャンセル",
    START_DATE: "開始日",
    END_DATE: "終了日",

    EDIT: "編集",
    REMARK_COLUMN: "備考欄",
    BTN_RENEW: "更新 ",
    TEMPERATURE_COLUMN_TIME: "temperature-report_column-time",
  },
  MESSAGE: {
    CONFIRM_APPROVE: "温度日報に同意します。よろしいですか？",
    APPROVE_SUCCESS: "承認に成功しました",
    SPECIFY_DATE: "日付の範囲を指定してください",
    NO_DATA: "検索条件と一致する結果が見つかりません。",
    EXPORT_DATA: "全店舗のデータをエクスポートします。",
    SPECIFY_PERIOD: "2週間以内の期間を指定してください。",
    CONFIRM_SUCCESS: "承認に成功しました。",
    CONFIRM_FAIL: "承認に失敗しました。",
  },
  PLACEHOLDER: {
    COMMENT: "内容を入力してください",
  },
  TABLE_KEY: {
    CH: "chCode",
    PURPOSE_NAME: "purposeName",
    DEPARTMENT_NAME2: "departmentName",
    TEMPERATURE_DISTINCTION: "temperatureType",
    LOWER_LIMIT_CONTROLLED: "minTemp",
    UPPER_LIMIT_CONTROLLED: "maxTemp",
    LOWER_LIMIT_OUTSIDE: "underQuantity",
    UPPER_LIMIT_OUTSIDE: "overQuantity",
    REMARKS: "comment",

    ONE_HOUR: "hour1",
    TWO_HOUR: "hour2",
    THREE_HOUR: "hour3",
    FOUR_HOUR: "hour4",
    FIVE_HOUR: "hour5",
    SIX_HOUR: "hour6",
    SEVEN_HOUR: "hour7",
    EIGHT_HOUR: "hour8",
    NINE_HOUR: "hour9",
    TEN_HOUR: "hour10",
    ELEVEN_HOUR: "hour11",
    TWELVE_HOUR: "hour12",
  },
};

export const OPTION_ALL_PURPOSE = { label: "全て", value: "" };
