export const STORE_CONSTANT = {
  TITLE: {
    STORE_LIST: "店舗一覧",
    BTN_REGISTRATION: "新規登録",

    // Columns
    COMPANY_CODE: "会社",
    STORE_CODE: "店舗",
    STORE_NAME: "店舗名",
    AREA_CODE: "地区",
    OPENING_DATE: "開店年月日",
    CLOSING_DATE: "閉店年月日",
    PURPOSE: "売場面積 (メートル)",
    PANASONIC_ID: "店舗ID\n(パナソニック)",
    OKAMURA_ID: "店舗ID (OKAMURA)",
    KIWI_ID: "店舗ID\n(KIWI)",
    EMAIL: "メールアドレス",
    MANAGER_NAME: "店長名",
    MOBILE_PHONE: "店舗 携帯電話",
    ACTION: "アクション",

    // Modal
    TITLE_DELETE: "削除",
    BTN_DELETE: "削除 ",
    BTN_CANCEL: "キャンセル",
    STORE_REGISTRATION: "店舗登録",
    STORE_UPDATE: "店舗変更",
    STORE_DETAIL: "店舗照会",
    BTN_MODAL_REGIST: "登録 ",
    BTN_CLOSE: "閉じる",
    STORE_LIST_REGISTRATION: "店舗一覧登録",
    BTN_OK: "OK",
    STORE_LIST_UPDATE: "変更成功",
    BTN_NEXT: "次へ",
    CONFIRM_UPDATE_FINAL: "用途成功",
    CONFIRM_REGISTRATION_FINAL: "用途成功",

    FAIL_REGISTER: "用途成功",
    SUCCESS_DELETE: "用途成功",
    FAIL_DELETE: "用途成功",

    NOT_FOUND_STORE: "not_found_store",
  },
  LABEL: {
    COMPANY: "会社",
    AREA: "地区",
    STORE: "店舗",
    STORE_CODE_TABLE: "店舗コード",

    // Modal
    COMPANY_NAME: "会社",
    STORE_CODE: "店舗コード",
    PANASONIC_ID: "店舗ID（パナソニック)",
    KIWI_ID: "店舗ID（KIWI)",
    MOBILE_PHONE: "店舗携帯電話",
    OPENING_DATE: "開店年月日",
    EMAIL: "メールアドレス",
    AREA_CODE: "地区",
    MANAGER_NAME: "店長名",
    OKAMURA_ID: "店舗ID（OKAMURA)",
    STORE_NAME: "店舗名",
    PURPOSE: "売場面積（メートル）",
    CLOSING_DATE: "閉店年月日",
  },
  NAME: {
    COMPANY: "companyCode",
    AREA: "areaCode",
    STORE: "storeCode",
    AREA_ID: "areaId",

    // Modal
    COMPANY_CODE: "companyCode",
    STORE_CODE: "storeCode",
    PANASONIC_ID: "panasonicId",
    KIWI_ID: "kiwiId",
    MOBILE_PHONE: "storePhone",
    OPENING_DATE: "openingDate",
    EMAIL: "email",
    AREA_CODE: "areaCode",
    MANAGER_NAME: "storeManagerName",
    OKAMURA_ID: "okamuraId",
    STORE_NAME: "storeName",
    LAND_AREA: "landArea",
    CLOSING_DATE: "closingDate",
  },
  TABLE_KEY: {
    COMPANY_CODE: "companyCode",
    STORE_CODE: "storeCode",
    STORE_NAME: "storeName",
    AREA_CODE: "areaCode",
    OPENING_DATE: "openingDate",
    CLOSING_DATE: "closingDate",
    PURPOSE: "landArea",
    PANASONIC_ID: "panasonicId",
    OKAMURA_ID: "okamuraId",
    KIWI_ID: "kiwiId",
    EMAIL: "email",
    MANAGER_NAME: "storeManagerName",
    MOBILE_PHONE: "storePhone",
  },
  MESSAGE: {
    NO_DATA: "表示するデータがありません。",
    SUCCESS_DELETE: "店舗が削除に成功された。",
    SUCCESS_REGISTER: "店舗が登録に成功された。",
    SUCCESS_UPDATE: "店舗が変更に成功された。",

    FAIL_DELETE: "店舗が削除に失敗された。",
    FAIL_REGISTER: "店舗が登録に失敗された。",
    FAIL_UPDATE: "店舗が変更に失敗された。",

    CONFIRM_DELETE: "この店舗を削除します。よろしいですか？",
    CONFIRM_UPDATE_FINAL: "この店舗を変更します。よろしいですか？",
    CONFIRM_REGISTRATION_FINAL: "この店舗を登録します。よろしいですか？",

    NOT_FOUND_STORE:
      "他の人がデータを更新したため、更新できません。 データベースからデータをリロードしてください。",
    DATA_EXIST: "値はすでに存在しています。",
    FIELD_INVALID_FORMAT: "値の形式は正しくありません。",
    FIELD_INVALID: "値は無効です。",
    FIELD_ID_ERROR:
      "1つ店舗で店舗ID（パナソニック）または店舗ID（OKAMURA）のみ入力可能。",
  },
};

export const HEIGHT_TABLE_SCROLL = 685;
export const MAX_LENGTH_255 = 255;
export const MAX_LENGTH_3 = 3;
export const MAX_LENGTH_50 = 50;
export const MAX_LENGTH_11 = 11;
export const LIST_KEYS_ALLOW_PHONE = [
  "Backspace",
  "Tab",
  "Shift",
  "ArrowRight",
  "ArrowLeft",
];
export const REGEX_STORE_CODE = /^[a-zA-Z0-9]{1,3}$/;
export const REGEX_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
