import { TableProps } from "antd/lib";
import { STORE_CONSTANT } from "./constant";
import { Button, Tooltip } from "antd";
import { COLOR_TOOLTIP } from "@constants/common";
import {
  DeleteOutlined,
  EditOutlined,
  FolderViewOutlined,
} from "@ant-design/icons";
import { TListStoreTable } from "@models/store";

const { TITLE, TABLE_KEY } = STORE_CONSTANT;

const renderHeaderColumns = (text1: string, text2: string) => {
  return (
    <>
      <p>{text1}</p>
      <span>{text2}</span>
    </>
  );
};

const renderCodeName = (code: string, name: string) => {
  return code + `${name ? `_${name}` : ""}`;
};

export const getColumns = ({
  disableBtnOther,
  handleOpenModalDelete,
  handleOpenModalDetail,
  handleOpenModalUpdate,
}: {
  disableBtnOther: boolean;
  handleOpenModalDelete: (record: TListStoreTable) => void;
  handleOpenModalDetail: (record: TListStoreTable) => void;
  handleOpenModalUpdate: (record: TListStoreTable, id: string) => void;
}): TableProps<TListStoreTable>["columns"] => {
  return [
    {
      title: TITLE.COMPANY_CODE,
      key: TABLE_KEY.COMPANY_CODE,
      dataIndex: TABLE_KEY.COMPANY_CODE,
      align: "center",
      width: 120,
      render: (value, record) => {
        if (record) {
          const codeName = renderCodeName(value, record.companyName);
          return (
            <div className="list-page_ellipsis">
              <Tooltip
                color="white"
                overlayClassName="common_custom-tooltip"
                title={codeName}
                overlayInnerStyle={{
                  color: COLOR_TOOLTIP,
                }}
              >
                {codeName}
              </Tooltip>
            </div>
          );
        }
        return null;
      },
    },
    {
      title: TITLE.STORE_CODE,
      key: TABLE_KEY.STORE_CODE,
      dataIndex: TABLE_KEY.STORE_CODE,
      align: "center",
      width: 120,
      render: (value, record) => {
        if (record) {
          const codeName = renderCodeName(value, record.storeName);
          return (
            <div className="list-page_ellipsis">
              <Tooltip
                color="white"
                overlayClassName="common_custom-tooltip"
                title={codeName}
                overlayInnerStyle={{
                  color: COLOR_TOOLTIP,
                }}
              >
                {codeName}
              </Tooltip>
            </div>
          );
        }
        return null;
      },
    },
    {
      title: TITLE.AREA_CODE,
      key: TABLE_KEY.AREA_CODE,
      dataIndex: TABLE_KEY.AREA_CODE,
      align: "center",
      width: 120,
      render: (value, record) => {
        if (record) {
          const codeName = renderCodeName(value, record.areaName);
          return (
            <div className="list-page_ellipsis">
              <Tooltip
                color="white"
                overlayClassName="common_custom-tooltip"
                title={codeName}
                overlayInnerStyle={{
                  color: COLOR_TOOLTIP,
                }}
              >
                {codeName}
              </Tooltip>
            </div>
          );
        }
        return null;
      },
    },
    {
      title: TITLE.OPENING_DATE,
      key: TABLE_KEY.OPENING_DATE,
      dataIndex: TABLE_KEY.OPENING_DATE,
      align: "center",
      width: 120,
    },
    {
      title: TITLE.CLOSING_DATE,
      key: TABLE_KEY.CLOSING_DATE,
      dataIndex: TABLE_KEY.CLOSING_DATE,
      align: "center",
      width: 120,
    },
    {
      title: renderHeaderColumns("売場面積", "(メートル)"),
      key: TABLE_KEY.PURPOSE,
      dataIndex: TABLE_KEY.PURPOSE,
      align: "center",
      width: 140,
    },
    {
      title: renderHeaderColumns("店舗ID", "(パナソニック)"),
      key: TABLE_KEY.PANASONIC_ID,
      dataIndex: TABLE_KEY.PANASONIC_ID,
      align: "center",
      width: 150,
    },
    {
      title: TITLE.OKAMURA_ID,
      key: TABLE_KEY.OKAMURA_ID,
      dataIndex: TABLE_KEY.OKAMURA_ID,
      align: "center",
      width: 140,
      render: (value) => {
        return (
          <div className="list-page_ellipsis">
            <Tooltip
              color="white"
              overlayClassName="common_custom-tooltip"
              title={value}
              overlayInnerStyle={{
                color: COLOR_TOOLTIP,
              }}
            >
              {value}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: renderHeaderColumns("店舗ID", "(KIWI)"),
      key: TABLE_KEY.KIWI_ID,
      dataIndex: TABLE_KEY.KIWI_ID,
      align: "center",
      width: 140,
    },
    {
      title: TITLE.EMAIL,
      key: TABLE_KEY.EMAIL,
      dataIndex: TABLE_KEY.EMAIL,
      align: "center",
      width: 164,
      render: (value) => {
        return (
          <div className="list-page_ellipsis">
            <Tooltip
              color="white"
              overlayClassName="common_custom-tooltip"
              title={value}
              overlayInnerStyle={{
                color: COLOR_TOOLTIP,
              }}
            >
              {value}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: TITLE.MANAGER_NAME,
      key: TABLE_KEY.MANAGER_NAME,
      dataIndex: TABLE_KEY.MANAGER_NAME,
      align: "center",
      width: 100,
    },
    {
      title: TITLE.MOBILE_PHONE,
      key: TABLE_KEY.MOBILE_PHONE,
      dataIndex: TABLE_KEY.MOBILE_PHONE,
      align: "center",
      width: 140,
    },
    {
      title: TITLE.ACTION,
      align: "center",
      width: 144,
      render(_, record) {
        return (
          <div className="list-page_modal-btns">
            <Button
              onClick={() => {
                handleOpenModalDetail(record);
              }}
            >
              <FolderViewOutlined
                style={{
                  fontSize: "21px",
                  color: "#344054",
                  cursor: "pointer",
                }}
              />
            </Button>
            <Button
              disabled={disableBtnOther}
              onClick={() => {
                handleOpenModalUpdate(record, record.id);
              }}
            >
              <EditOutlined
                style={{
                  fontSize: "21px",
                  color: `${disableBtnOther ? "#99a6bb" : "#344054"}`,
                  cursor: "pointer",
                }}
              />
            </Button>
            <Button
              disabled={disableBtnOther}
              onClick={() => {
                handleOpenModalDelete(record);
              }}
            >
              <DeleteOutlined
                style={{
                  fontSize: "21px",
                  color: `${disableBtnOther ? "#99a6bb" : "#F04438"}`,
                  cursor: "pointer",
                }}
              />
            </Button>
          </div>
        );
      },
    },
  ];
};
