import { TSelectValue } from "@models/common";
import {
  TAllStoreConditionsReq,
  TAllStoreStatusConvertData,
  TAllStoreStatusCSVReq,
  TAllStoreStatusData,
  TAllStoreStatusForm,
  TAllStoreStatusReq,
  TAllStoreStatusState,
} from "@models/store";
import { allStoreApi } from "@services/allStoreApi";
import { layoutApi } from "@services/layoutApi";
import { guidGenerator, handleShowToastError } from "@utils/helpers";
import { FormInstance } from "antd/lib";
import { ALL_DEVICE_CONSTANT } from "../constant";
import { SortOrder } from "antd/es/table/interface";
import { COMMON_MESSAGE_ERROR } from "@constants/common";
import { NavigateFunction } from "react-router-dom";
import { TAxiosResponse } from "types";
import { EHttpStatusCode } from "types/service";
import { appToast } from "@utils/appToast";
import _ from "lodash";

const { NAME } = ALL_DEVICE_CONSTANT;

function useAllDeviceApi() {
  const handleNavigateBack = (navigate: NavigateFunction) => {
    if (window.history?.length && window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("/", { replace: true });
    }
  };

  const handleSortOrder = (direction: string) => {
    switch (direction) {
      case "ascend":
        return "asc";
      case "descend":
        return "desc";
      default:
        return "asc";
    }
  };

  const handleSortOrderV2 = (order: string): SortOrder => {
    switch (order) {
      case "asc":
        return "ascend";
      case "desc":
        return "descend";
      default:
        return "ascend";
    }
  };

  const handleConvertData = (data: TAllStoreStatusData[]) => {
    const convertData: TAllStoreStatusConvertData[] = data.map((item) => {
      return {
        key: guidGenerator(),
        areaName: item.areaName,
        storeCode: item.storeCode,
        storeName: item.storeName,
        chCode: item.chCode,
        manufacturerName: item.manufacturerName,
        purposeName: item.purposeName,
        installedLocation: item.installedLocation,
        minTemp: item.minTemp,
        maxTemp: item.maxTemp,
        batteryValue: item.batteryValue,
        reportTempSensorThreeDaysAgo: item.reportTempSensorThreeDaysAgo,
        reportTempSensorTwoDaysAgo: item.reportTempSensorTwoDaysAgo,
        reportTempSensorOneDayAgo: item.reportTempSensorOneDayAgo,
      };
    });

    return convertData;
  };

  // EFFECT
  const effectCompanies = (
    companiesSelect: TSelectValue[],
    form: FormInstance,
    searchParams: URLSearchParams
  ) => {
    const companyCode = searchParams.get("companyCode");
    if (companyCode) {
      form.setFieldValue(NAME.COMPANY, companyCode);
    } else {
      if (companiesSelect.length) {
        form.setFieldValue(NAME.COMPANY, companiesSelect[0].value);
      }
    }
  };

  // API
  const getCompanies = async (
    companyCode: string,
    isInit: boolean,
    setCompaniesSelect: (val: TSelectValue[]) => void,
    setLoadingPage: (val: boolean) => void,
    getAllStoreConditions: (val: TAllStoreConditionsReq) => void
  ) => {
    try {
      const response = await layoutApi.getCompanies();

      if (response.ok) {
        const convertCompanies = response.data.map((val) => ({
          value: val.companyCode,
          label: val.companyName,
          children: val.id,
        }));
        const findCompany = _.find(response.data, function (item) {
          return item.companyCode === companyCode;
        });
        setCompaniesSelect(convertCompanies);

        getAllStoreConditions({
          companyCode: findCompany
            ? findCompany.companyCode
            : convertCompanies[0].value,
          companyName: findCompany
            ? findCompany.companyName
            : convertCompanies[0].label,
        });
      }
    } catch (error) {
      handleShowToastError(error);
    } finally {
      if (isInit) {
        setLoadingPage(false);
      }
    }
  };

  const getAllStoreConditions = async (
    payload: TAllStoreConditionsReq,
    setAreasSelect: (val: TSelectValue[]) => void,
    setLocationsSelect: (val: TSelectValue[]) => void,
    setPurposesSelect: (val: TSelectValue[]) => void,
    setLoadingOther: (val: boolean) => void
  ) => {
    setLoadingOther(true);
    try {
      const response = await allStoreApi.getAllStoreConditions(payload);

      if (response.ok) {
        const convertAreas = response.data.areas.map((val) => ({
          value: val.areaCode,
          label: val.areaName,
          children: val.id,
        }));
        setAreasSelect(convertAreas);
        setLocationsSelect(
          Object.values(response.data.installedLocation).map((item) => ({
            value: item,
            label: item,
          }))
        );
        const convertPurposes = response.data.purposes.map((val) => ({
          value: val.purposeCode,
          label: val.purposeName,
          children: val.id,
        }));
        setPurposesSelect(convertPurposes);
      }
    } catch (error) {
      handleShowToastError(error);
    } finally {
      setLoadingOther(false);
    }
  };

  const getAllStoreStatusInit = async (
    payload: TAllStoreStatusReq,
    itemsCode: { areaCode: string; purposeCode: string },
    setLoadingPage: (val: boolean) => void,
    setResultData: (val: TAllStoreStatusState) => void,
    setCompaniesSelect: (val: TSelectValue[]) => void,
    getAllStoreConditions: (val: TAllStoreConditionsReq) => void,
    handleSetSearchParams: (val: TAllStoreStatusForm) => void
  ) => {
    setLoadingPage(true);

    try {
      const resCompany = await layoutApi.getCompanies();
      const convertCompanies = resCompany.data.map((val) => ({
        value: val.companyCode,
        label: val.companyName,
        children: val.id,
      }));
      setCompaniesSelect(convertCompanies);
      const findCompany = resCompany.data.find(
        (item) => item.id === payload.companyId
      );

      getAllStoreConditions({
        companyCode: findCompany
          ? findCompany.companyCode
          : convertCompanies[0].value,
        companyName: findCompany
          ? findCompany.companyName
          : convertCompanies[0].label,
      });

      if (resCompany.ok && resCompany.data.length) {
        const response = await allStoreApi.getAllStoreStatus({
          ...payload,
          companyId: findCompany
            ? findCompany.id
            : convertCompanies[0].children,
        });

        if (response.ok) {
          setResultData({
            totalCompanySensor: response.data.totalCompanySensor,
            totalRecord: response.data.totalRecord,
            data: handleConvertData(response.data.data),
          });
          handleSetSearchParams({
            ...payload,
            areaCode: itemsCode.areaCode,
            purposeCode: itemsCode.purposeCode,
            companyCode: findCompany
              ? findCompany.companyCode
              : convertCompanies[0].value,
            companyId: findCompany
              ? findCompany.id
              : convertCompanies[0].children,
          });
        }
      } else {
        setResultData({ data: [], totalCompanySensor: 0, totalRecord: 0 });
      }
    } catch (error) {
      setResultData({ data: [], totalCompanySensor: 0, totalRecord: 0 });

      const err = error as TAxiosResponse;
      if (err.status === EHttpStatusCode.FORBIDDEN) {
        appToast.error({ message: COMMON_MESSAGE_ERROR });
        return;
      }

      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const getAllStoreStatusSearch = async (
    payload: TAllStoreStatusReq,
    setLoadingPage: (val: boolean) => void,
    setResultData: (val: TAllStoreStatusState) => void
  ) => {
    setLoadingPage(true);

    try {
      const response = await allStoreApi.getAllStoreStatus(payload);

      if (response.ok) {
        setResultData({
          totalCompanySensor: response.data.totalCompanySensor,
          totalRecord: response.data.totalRecord,
          data: handleConvertData(response.data.data),
        });
      }
    } catch (error) {
      setResultData({ data: [], totalCompanySensor: 0, totalRecord: 0 });

      const err = error as TAxiosResponse;
      if (err.status === EHttpStatusCode.FORBIDDEN) {
        appToast.error({ message: COMMON_MESSAGE_ERROR });
        return;
      }

      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const getAllStoreStatusScroll = async (
    payload: TAllStoreStatusReq,
    resultData: TAllStoreStatusState,
    setLoadingPage: (val: boolean) => void,
    setResultData: (val: TAllStoreStatusState) => void
  ) => {
    setLoadingPage(true);

    try {
      const response = await allStoreApi.getAllStoreStatus(payload);

      if (response.ok) {
        if (resultData.data.length !== response.pagination?.totalCount) {
          setResultData({
            ...resultData,
            data: [
              ...resultData.data,
              ...handleConvertData(response.data.data),
            ],
          });
        }
      }
    } catch (error) {
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const getAllStoreStatusSort = async (
    payload: TAllStoreStatusReq,
    resultData: TAllStoreStatusState,
    setLoadingPage: (val: boolean) => void,
    setResultData: (val: TAllStoreStatusState) => void
  ) => {
    setLoadingPage(true);

    try {
      const response = await allStoreApi.getAllStoreStatus(payload);

      if (response.ok) {
        setResultData({
          ...resultData,
          data: handleConvertData(response.data.data),
        });
      }
    } catch (error) {
      handleShowToastError(error);
      setResultData({ data: [], totalCompanySensor: 0, totalRecord: 0 });
    } finally {
      setLoadingPage(false);
    }
  };

  const exportAllStoreStatusCSV = async (
    payload: TAllStoreStatusCSVReq,
    setLoadingPage: (val: boolean) => void
  ) => {
    setLoadingPage(true);

    try {
      const response = await allStoreApi.getAllStoreStatusCSV(payload);

      if (response.ok) {
        const base64 = `data:text/csv;base64,${response.data.fileData}`;
        const elementAnchor = document.createElement("a");
        elementAnchor.href = base64;
        elementAnchor.download = response.data.fileName; // File name
        elementAnchor.click();
      }
    } catch (error) {
      handleShowToastError(error);
    }
  };

  return {
    handler: {
      handleNavigateBack,
      handleSortOrderV2,
      handleConvertData,
      handleSortOrder,

      getCompanies,
      getAllStoreConditions,
      exportAllStoreStatusCSV,

      getAllStoreStatusInit,
      getAllStoreStatusSearch,
      getAllStoreStatusScroll,
      getAllStoreStatusSort,
    },
    effect: {
      effectCompanies,
    },
  };
}

export default useAllDeviceApi;
