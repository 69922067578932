import { createSlice } from "@reduxjs/toolkit";
import { ReactNode } from "react";
import { TPayloadAction } from "types";

type TCommonState = {
  showModal: boolean;
  modalTitle: ReactNode;
  onOkModal: null | (() => void);
  type: "systemError" | "";
};

const initialState: TCommonState = {
  showModal: false,
  modalTitle: "",
  onOkModal: null,
  type: "",
};

type TModal = {
  title?: string;
  onOk?: null | (() => void);
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    // Modal
    showModal(state, action: TPayloadAction<TModal>) {
      state.showModal = true;
      state.modalTitle = action.payload.data.title || "";
      state.onOkModal = action.payload.data.onOk || null;
    },
    showModalSystemError(state) {
      state.showModal = true;
      state.type = "systemError";
    },
    closeModal(state) {
      state.showModal = false;
      state.modalTitle = "";
      state.onOkModal = null;
    },
  },
});

const { actions, reducer } = commonSlice;

export { actions as commonActions, reducer as commonReducer };
