import styled from "styled-components";

export const TableListWrapper = styled.div`
  .ant-table-tbody-virtual-scrollbar-vertical {
    background: var(--color-secondary);
    border-radius: 4px;
  }

  .ant-table-tbody-virtual-scrollbar {
    visibility: visible !important;
    right: -8px !important;

    .ant-table-tbody-virtual-scrollbar-thumb {
      background: var(--color-text-secondary) !important;
    }
  }

  .ant-table {
    .ant-table-thead {
      .ant-table-cell {
        border-bottom: 4px solid var(--color-border-primary) !important;
      }
    }

    .ant-table-tbody {
      .ant-table-placeholder {
        .ant-empty {
          text-align: center;
        }
      }

      .ant-table-row.row_active {
        .ant-table-cell {
          background-color: var(--color-border-primary) !important;
        }
      }

      .ant-table-row {
        .ant-table-cell {
          padding: 16px 10px;

          .list-page_ellipsis {
            display: -webkit-box;
            -webkit-line-clamp: 1; /* Number of lines */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
          }

          .list-page_modal-btns {
            display: flex;
            justify-content: center;
            column-gap: 26px;

            .ant-btn-default {
              padding: 0;
              height: unset;
              border-color: transparent;
              box-shadow: none;
              background: none;
            }

            .ant-btn-default:disabled {
              border-color: transparent;
              background: transparent;
            }
          }
        }
      }
    }
  }
`;
