import { Component, ErrorInfo, ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  errInfo: Error | null;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    errInfo: null,
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errInfo: error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", { error, errorInfo });
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div>
          <h3>Message</h3>
          <p>{this.state.errInfo?.message}</p>
          <hr />
          <h3>Name</h3>
          <p>{this.state.errInfo?.name}</p>
          <hr />
          <h3>Stack</h3>
          <p>{this.state.errInfo?.stack}</p>
          <hr />
          <h3>Cause</h3>
          <p>{JSON.stringify(this.state.errInfo?.cause)}</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
