import { TemperatureDetailIcon, ThreeDotIcon } from "@constants/svgs";
import { STATISTICS_CONSTANT } from "./constant";
import { TableProps, Tooltip } from "antd";
import { Dayjs } from "dayjs";
import { EDayOfWeek } from "types/others";
import { COLOR_TOOLTIP, DATA_EMPTY } from "@constants/common";
import { TConvertAllReport } from "@models/report";

const { TITLE, TABLE_KEY } = STATISTICS_CONSTANT;
const dateFormatColumn = "M月D日";

const renderDateOfWeek = (date: number) => {
  switch (date) {
    case EDayOfWeek.MON:
      return "月";
    case EDayOfWeek.TUES:
      return "火";
    case EDayOfWeek.WED:
      return "水";
    case EDayOfWeek.THUR:
      return "木";
    case EDayOfWeek.FRI:
      return "金";
    case EDayOfWeek.SAT:
      return "土";
    default:
      return "日";
  }
};

const renderTitleColumn = (dateSelect: Dayjs, subtract: number) => {
  return `${dateSelect
    .subtract(subtract, "d")
    .format(dateFormatColumn)} (${renderDateOfWeek(
    dateSelect.subtract(subtract, "d").day()
  )})`;
};

const renderCellFullName = (value: any, index: number) => {
  return value ? (
    <span className="statistics-page_ellipsis">
      <Tooltip
        color="white"
        overlayClassName="common_custom-tooltip"
        title={value[index]}
        overlayInnerStyle={{
          color: COLOR_TOOLTIP,
        }}
      >
        {value[index]}
      </Tooltip>
    </span>
  ) : (
    DATA_EMPTY
  );
};

const renderCellComment = (
  value: any,
  index: number,
  record: TConvertAllReport,
  handleRedirectDailyReport: (storeCode: string, reportDate?: string) => void
) => {
  return value && value[index] ? (
    <span
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
      onClick={() =>
        handleRedirectDailyReport(record.storeCode, record?.reportDate?.[index])
      }
    >
      <TemperatureDetailIcon />
    </span>
  ) : null;
};

const renderClassNameTenTemp = (index: number, record: any) => {
  if (record?.tenThreshold[index] === -1) {
    return "lower";
  } else if (record?.tenThreshold[index] === 1) {
    return "upper";
  } else {
    return undefined;
  }
};

const renderClassNameFifteenTemp = (index: number, record: any) => {
  if (record?.fifteenThreshold[index] === -1) {
    return "lower";
  } else if (record?.fifteenThreshold[index] === 1) {
    return "upper";
  } else {
    return undefined;
  }
};

const renderCellTenClock = (value: any, index: number, record: any) => {
  if (value && record) {
    if (record?.tenClock?.[index] === "なし") {
      return DATA_EMPTY;
    } else {
      if (
        record?.tenIsNoCooling?.[index] ||
        record?.tenDefrostTime?.[index] ||
        record?.tenIsActive?.[index] === false
      ) {
        return (
          <span style={{ textDecorationLine: "line-through" }}>
            {value[index]}
          </span>
        );
      } else {
        const className = renderClassNameTenTemp(index, record);
        return <span className={className}>{value[index]}</span>;
      }
    }
  } else {
    return null;
  }
};

const renderCellFifteenClock = (value: any, index: number, record: any) => {
  if (value && record) {
    if (record?.fifteenClock?.[index] === "なし") {
      return DATA_EMPTY;
    } else {
      if (
        record?.fifteenIsNoCooling?.[index] ||
        record?.fifteenDefrostTime?.[index] ||
        record?.fifteenIsActive?.[index] === false
      ) {
        return (
          <span style={{ textDecorationLine: "line-through" }}>
            {value[index]}
          </span>
        );
      } else {
        const className = renderClassNameFifteenTemp(index, record);
        return <span className={className}>{value[index]}</span>;
      }
    }
    // if (record?.fifteenIsNoCooling?.[index]) {
    //   return (
    //     <span style={{ textDecorationLine: "line-through" }}>
    //       {value[index]}
    //     </span>
    //   );
    // } else {
    //   if (record?.fifteenIsActive?.[index] === false) {
    //     return <span>{DATA_EMPTY}</span>;
    //   } else {
    //     if (record.fifteenDefrostTime[index]) {
    //       return (
    //         <span style={{ textDecorationLine: "line-through" }}>
    //           {value[index]}
    //         </span>
    //       );
    //     } else {
    //       const className = renderClassNameFifteenTemp(index, record);
    //       return <span className={className}>{value[index]}</span>;
    //     }
    //   }
    // }
  } else {
    return null;
  }
};

export const getColumns = ({
  chooseDate,
  handleRedirectOpenCase,
  handleRedirectDailyReport,
}: {
  chooseDate: Dayjs;
  handleRedirectOpenCase: (
    val: string,
    isCh: boolean,
    storeCode: string,
    other?: string
  ) => void;
  handleRedirectDailyReport: (storeCode: string, reportDate?: string) => void;
}): TableProps<TConvertAllReport>["columns"] => {
  return [
    {
      title: (
        <span>
          <ThreeDotIcon />{" "}
        </span>
      ),
      key: TABLE_KEY.THREE_DOT,
      dataIndex: TABLE_KEY.THREE_DOT,
      align: "center",
      width: 48,
      fixed: "left",
    },
    {
      title: TITLE.DISTRICT,
      key: TABLE_KEY.DISTRICT,
      dataIndex: TABLE_KEY.DISTRICT,
      align: "center",
      width: 50,
      fixed: "left",
    },
    {
      title: TITLE.STORE_NUMBER,
      key: TABLE_KEY.STORE_NUMBER,
      dataIndex: TABLE_KEY.STORE_NUMBER,
      align: "center",
      width: 50,
      fixed: "left",
      render(value, record) {
        return typeof record.key === "number" ? (
          <span
            onClick={() => handleRedirectDailyReport(record.storeCode)}
            className="statistics-page_store-number"
          >
            {value}
          </span>
        ) : null;
      },
    },
    {
      title: TITLE.STORE_NAME,
      key: TABLE_KEY.STORE_NAME,
      dataIndex: TABLE_KEY.STORE_NAME,
      align: "center",
      width: 160,
      fixed: "left",
      render(value, record) {
        return (
          <span
            onClick={() => handleRedirectDailyReport(record.storeCode)}
            className="statistics-page_store-name statistics-page_ellipsis"
          >
            <Tooltip
              color="white"
              overlayClassName="common_custom-tooltip"
              title={value}
              overlayInnerStyle={{
                color: COLOR_TOOLTIP,
              }}
            >
              {value}
            </Tooltip>
          </span>
        );
      },
    },
    {
      title: TITLE.CH,
      key: TABLE_KEY.CH,
      dataIndex: TABLE_KEY.CH,
      align: "center",
      fixed: "left",
      render(value, record) {
        return (
          <span
            onClick={() => {
              handleRedirectOpenCase(value, true, record.storeCode);
            }}
            className="statistics-page_ch"
          >
            {value}
          </span>
        );
      },
      width: 70,
    },
    {
      title: TITLE.SALE_NAME,
      key: TABLE_KEY.SALE_NAME,
      dataIndex: TABLE_KEY.SALE_NAME,
      align: "center",
      fixed: "left",
      render(value, record) {
        return (
          <span
            onClick={() => {
              handleRedirectOpenCase(
                value,
                false,
                record.storeCode,
                record?.chCode
              );
            }}
            className="statistics-page_sale-name statistics-page_ellipsis"
          >
            <Tooltip
              color="white"
              overlayClassName="common_custom-tooltip"
              title={value}
              overlayInnerStyle={{
                color: COLOR_TOOLTIP,
              }}
            >
              {value}
            </Tooltip>
          </span>
        );
      },
      width: 120,
    },
    {
      title: TITLE.DEPARTMENT_NAME,
      key: TABLE_KEY.DEPARTMENT_NAME,
      dataIndex: TABLE_KEY.DEPARTMENT_NAME,
      align: "center",
      fixed: "left",
      width: 105,
      render(value) {
        return (
          <div className="statistics-page_ellipsis">
            <Tooltip
              color="white"
              overlayClassName="common_custom-tooltip"
              title={value}
              overlayInnerStyle={{
                color: COLOR_TOOLTIP,
              }}
            >
              {value}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: TITLE.TEMPERATURE_DISTINCTION,
      key: TABLE_KEY.TEMPERATURE_DISTINCTION,
      dataIndex: TABLE_KEY.TEMPERATURE_DISTINCTION,
      align: "center",
      fixed: "left",
      width: 105,
      render(value) {
        return (
          <div className="statistics-page_ellipsis">
            <Tooltip
              color="white"
              overlayClassName="common_custom-tooltip"
              title={value}
              overlayInnerStyle={{
                color: COLOR_TOOLTIP,
              }}
            >
              {value}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: TITLE.INSTALL_LOCATION,
      key: TABLE_KEY.INSTALL_LOCATION,
      dataIndex: TABLE_KEY.INSTALL_LOCATION,
      align: "center",
      fixed: "left",
      width: 105,
      render(value) {
        return (
          <div className="statistics-page_ellipsis">
            <Tooltip
              color="white"
              overlayClassName="common_custom-tooltip"
              title={value}
              overlayInnerStyle={{
                color: COLOR_TOOLTIP,
              }}
            >
              {value}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: TITLE.CONTROLLED_TEMPERATURE,
      align: "center",
      children: [
        {
          title: TITLE.LOWER_LIMIT,
          key: TABLE_KEY.LOWER_LIMIT,
          dataIndex: TABLE_KEY.LOWER_LIMIT,
          align: "center",
          fixed: "left",
          render(value) {
            return (
              <span className="statistics-page_limit">
                {typeof value === "number" ? `${value}°C` : null}
              </span>
            );
          },
          width: 80,
        },
        {
          title: TITLE.UPPER_LIMIT,
          key: TABLE_KEY.UPPER_LIMIT,
          dataIndex: TABLE_KEY.UPPER_LIMIT,
          align: "center",
          fixed: "left",
          render(value) {
            return (
              <span className="statistics-page_limit">
                {typeof value === "number" ? `${value}°C` : null}
              </span>
            );
          },
          width: 80,
        },
      ],
    },

    {
      title: renderTitleColumn(chooseDate, 6),
      align: "center",
      children: [
        {
          title: TITLE.BLUE_NUMBER,
          key: TABLE_KEY.BLUE_NUMBER,
          dataIndex: TABLE_KEY.BLUE_NUMBER,
          align: "center",
          width: 88,
          render: (value) => {
            return value && value.length ? value[0] : 0;
          },
        },
        {
          title: TITLE.RED_NUMBER,
          key: TABLE_KEY.RED_NUMBER,
          dataIndex: TABLE_KEY.RED_NUMBER,
          align: "center",
          width: 88,
          render: (value) => (value && value.length ? value[0] : 0),
        },
        {
          title: TITLE.TEN_CLOCK,
          key: TABLE_KEY.TEN_CLOCK,
          dataIndex: TABLE_KEY.TEN_CLOCK,
          align: "center",
          width: 88,
          render: (value, record) => renderCellTenClock(value, 0, record),
        },
        {
          title: TITLE.FIFTEEN_CLOCK,
          key: TABLE_KEY.FIFTEEN_CLOCK,
          dataIndex: TABLE_KEY.FIFTEEN_CLOCK,
          align: "center",
          width: 88,
          render: (value, record) => renderCellFifteenClock(value, 0, record),
        },
        {
          title: TITLE.FULL_NAME,
          key: TABLE_KEY.FULL_NAME,
          dataIndex: TABLE_KEY.FULL_NAME,
          align: "center",
          render: (value) => renderCellFullName(value, 0),
          width: 88,
        },
        {
          title: TITLE.COMMENT,
          key: TABLE_KEY.COMMENT,
          dataIndex: TABLE_KEY.COMMENT,
          align: "center",
          render: (value, record) =>
            renderCellComment(value, 0, record, handleRedirectDailyReport),
          width: 90,
        },
      ],
    },
    {
      title: renderTitleColumn(chooseDate, 5),
      align: "center",
      children: [
        {
          title: TITLE.BLUE_NUMBER,
          key: TABLE_KEY.BLUE_NUMBER,
          dataIndex: TABLE_KEY.BLUE_NUMBER,
          align: "center",
          width: 88,
          render: (value) => (value && value.length ? value[1] : 0),
        },
        {
          title: TITLE.RED_NUMBER,
          key: TABLE_KEY.RED_NUMBER,
          dataIndex: TABLE_KEY.RED_NUMBER,
          align: "center",
          width: 88,
          render: (value) => (value && value.length ? value[1] : 0),
        },
        {
          title: TITLE.TEN_CLOCK,
          key: TABLE_KEY.TEN_CLOCK,
          dataIndex: TABLE_KEY.TEN_CLOCK,
          align: "center",
          width: 88,
          render: (value, record) => renderCellTenClock(value, 1, record),
        },
        {
          title: TITLE.FIFTEEN_CLOCK,
          key: TABLE_KEY.FIFTEEN_CLOCK,
          dataIndex: TABLE_KEY.FIFTEEN_CLOCK,
          align: "center",
          width: 88,
          render: (value, record) => renderCellFifteenClock(value, 1, record),
        },
        {
          title: TITLE.FULL_NAME,
          key: TABLE_KEY.FULL_NAME,
          dataIndex: TABLE_KEY.FULL_NAME,
          align: "center",
          render: (value) => renderCellFullName(value, 1),
          width: 88,
        },
        {
          title: TITLE.COMMENT,
          key: TABLE_KEY.COMMENT,
          dataIndex: TABLE_KEY.COMMENT,
          align: "center",
          render: (value, record) =>
            renderCellComment(value, 1, record, handleRedirectDailyReport),
          width: 90,
        },
      ],
    },
    {
      title: renderTitleColumn(chooseDate, 4),
      align: "center",
      children: [
        {
          title: TITLE.BLUE_NUMBER,
          key: TABLE_KEY.BLUE_NUMBER,
          dataIndex: TABLE_KEY.BLUE_NUMBER,
          align: "center",
          width: 88,
          render: (value) => (value && value.length ? value[2] : 0),
        },
        {
          title: TITLE.RED_NUMBER,
          key: TABLE_KEY.RED_NUMBER,
          dataIndex: TABLE_KEY.RED_NUMBER,
          align: "center",
          width: 88,
          render: (value) => (value && value.length ? value[2] : 0),
        },
        {
          title: TITLE.TEN_CLOCK,
          key: TABLE_KEY.TEN_CLOCK,
          dataIndex: TABLE_KEY.TEN_CLOCK,
          align: "center",
          width: 88,
          render: (value, record) => renderCellTenClock(value, 2, record),
        },
        {
          title: TITLE.FIFTEEN_CLOCK,
          key: TABLE_KEY.FIFTEEN_CLOCK,
          dataIndex: TABLE_KEY.FIFTEEN_CLOCK,
          align: "center",
          width: 88,
          render: (value, record) => renderCellFifteenClock(value, 2, record),
        },
        {
          title: TITLE.FULL_NAME,
          key: TABLE_KEY.FULL_NAME,
          dataIndex: TABLE_KEY.FULL_NAME,
          align: "center",
          render: (value) => renderCellFullName(value, 2),
          width: 88,
        },
        {
          title: TITLE.COMMENT,
          key: TABLE_KEY.COMMENT,
          dataIndex: TABLE_KEY.COMMENT,
          align: "center",
          render: (value, record) =>
            renderCellComment(value, 2, record, handleRedirectDailyReport),
          width: 90,
        },
      ],
    },
    {
      title: renderTitleColumn(chooseDate, 3),
      align: "center",
      children: [
        {
          title: TITLE.BLUE_NUMBER,
          key: TABLE_KEY.BLUE_NUMBER,
          dataIndex: TABLE_KEY.BLUE_NUMBER,
          align: "center",
          width: 88,
          render: (value) => (value && value.length ? value[3] : 0),
        },
        {
          title: TITLE.RED_NUMBER,
          key: TABLE_KEY.RED_NUMBER,
          dataIndex: TABLE_KEY.RED_NUMBER,
          align: "center",
          width: 88,
          render: (value) => (value && value.length ? value[3] : 0),
        },
        {
          title: TITLE.TEN_CLOCK,
          key: TABLE_KEY.TEN_CLOCK,
          dataIndex: TABLE_KEY.TEN_CLOCK,
          align: "center",
          width: 88,
          render: (value, record) => renderCellTenClock(value, 3, record),
        },
        {
          title: TITLE.FIFTEEN_CLOCK,
          key: TABLE_KEY.FIFTEEN_CLOCK,
          dataIndex: TABLE_KEY.FIFTEEN_CLOCK,
          align: "center",
          width: 88,
          render: (value, record) => renderCellFifteenClock(value, 3, record),
        },
        {
          title: TITLE.FULL_NAME,
          key: TABLE_KEY.FULL_NAME,
          dataIndex: TABLE_KEY.FULL_NAME,
          align: "center",
          render: (value) => renderCellFullName(value, 3),
          width: 88,
        },
        {
          title: TITLE.COMMENT,
          key: TABLE_KEY.COMMENT,
          dataIndex: TABLE_KEY.COMMENT,
          align: "center",
          render: (value, record) =>
            renderCellComment(value, 3, record, handleRedirectDailyReport),
          width: 90,
        },
      ],
    },
    {
      title: renderTitleColumn(chooseDate, 2),
      align: "center",
      children: [
        {
          title: TITLE.BLUE_NUMBER,
          key: TABLE_KEY.BLUE_NUMBER,
          dataIndex: TABLE_KEY.BLUE_NUMBER,
          align: "center",
          width: 88,
          render: (value) => (value && value.length ? value[4] : 0),
        },
        {
          title: TITLE.RED_NUMBER,
          key: TABLE_KEY.RED_NUMBER,
          dataIndex: TABLE_KEY.RED_NUMBER,
          align: "center",
          width: 88,
          render: (value) => (value && value.length ? value[4] : 0),
        },
        {
          title: TITLE.TEN_CLOCK,
          key: TABLE_KEY.TEN_CLOCK,
          dataIndex: TABLE_KEY.TEN_CLOCK,
          align: "center",
          width: 88,
          render: (value, record) => renderCellTenClock(value, 4, record),
        },
        {
          title: TITLE.FIFTEEN_CLOCK,
          key: TABLE_KEY.FIFTEEN_CLOCK,
          dataIndex: TABLE_KEY.FIFTEEN_CLOCK,
          align: "center",
          width: 88,
          render: (value, record) => renderCellFifteenClock(value, 4, record),
        },
        {
          title: TITLE.FULL_NAME,
          key: TABLE_KEY.FULL_NAME,
          dataIndex: TABLE_KEY.FULL_NAME,
          align: "center",
          render: (value) => renderCellFullName(value, 4),
          width: 88,
        },
        {
          title: TITLE.COMMENT,
          key: TABLE_KEY.COMMENT,
          dataIndex: TABLE_KEY.COMMENT,
          align: "center",
          render: (value, record) =>
            renderCellComment(value, 4, record, handleRedirectDailyReport),
          width: 90,
        },
      ],
    },
    {
      title: renderTitleColumn(chooseDate, 1),
      align: "center",
      children: [
        {
          title: TITLE.BLUE_NUMBER,
          key: TABLE_KEY.BLUE_NUMBER,
          dataIndex: TABLE_KEY.BLUE_NUMBER,
          align: "center",
          width: 88,
          render: (value) => (value && value.length ? value[5] : 0),
        },
        {
          title: TITLE.RED_NUMBER,
          key: TABLE_KEY.RED_NUMBER,
          dataIndex: TABLE_KEY.RED_NUMBER,
          align: "center",
          width: 88,
          render: (value) => (value && value.length ? value[5] : 0),
        },
        {
          title: TITLE.TEN_CLOCK,
          key: TABLE_KEY.TEN_CLOCK,
          dataIndex: TABLE_KEY.TEN_CLOCK,
          align: "center",
          width: 88,
          render: (value, record) => renderCellTenClock(value, 5, record),
        },
        {
          title: TITLE.FIFTEEN_CLOCK,
          key: TABLE_KEY.FIFTEEN_CLOCK,
          dataIndex: TABLE_KEY.FIFTEEN_CLOCK,
          align: "center",
          width: 88,
          render: (value, record) => renderCellFifteenClock(value, 5, record),
        },
        {
          title: TITLE.FULL_NAME,
          key: TABLE_KEY.FULL_NAME,
          dataIndex: TABLE_KEY.FULL_NAME,
          align: "center",
          render: (value) => renderCellFullName(value, 5),
          width: 88,
        },
        {
          title: TITLE.COMMENT,
          key: TABLE_KEY.COMMENT,
          dataIndex: TABLE_KEY.COMMENT,
          align: "center",
          render: (value, record) =>
            renderCellComment(value, 5, record, handleRedirectDailyReport),
          width: 90,
        },
      ],
    },
    {
      title: renderTitleColumn(chooseDate, 0),
      align: "center",
      children: [
        {
          title: TITLE.BLUE_NUMBER,
          key: TABLE_KEY.BLUE_NUMBER,
          dataIndex: TABLE_KEY.BLUE_NUMBER,
          align: "center",
          width: 88,
          render: (value) => (value && value.length ? value[6] : 0),
        },
        {
          title: TITLE.RED_NUMBER,
          key: TABLE_KEY.RED_NUMBER,
          dataIndex: TABLE_KEY.RED_NUMBER,
          align: "center",
          width: 88,
          render: (value) => (value && value.length ? value[6] : 0),
        },
        {
          title: TITLE.TEN_CLOCK,
          key: TABLE_KEY.TEN_CLOCK,
          dataIndex: TABLE_KEY.TEN_CLOCK,
          align: "center",
          width: 88,
          render: (value, record) => renderCellTenClock(value, 6, record),
        },
        {
          title: TITLE.FIFTEEN_CLOCK,
          key: TABLE_KEY.FIFTEEN_CLOCK,
          dataIndex: TABLE_KEY.FIFTEEN_CLOCK,
          align: "center",
          width: 88,
          render: (value, record) => renderCellFifteenClock(value, 6, record),
        },
        {
          title: TITLE.FULL_NAME,
          key: TABLE_KEY.FULL_NAME,
          dataIndex: TABLE_KEY.FULL_NAME,
          align: "center",
          render: (value) => renderCellFullName(value, 6),
          width: 88,
        },
        {
          title: TITLE.COMMENT,
          key: TABLE_KEY.COMMENT,
          dataIndex: TABLE_KEY.COMMENT,
          align: "center",
          render: (value, record) =>
            renderCellComment(value, 6, record, handleRedirectDailyReport),
          width: 90,
        },
      ],
    },
  ];
};
