import {
  TChartTemperature,
  THourTemperature,
  TPurposesReq,
  TPurposesRes,
  TReportTemperature,
  TReportTemperatureSensorReq,
  TSensorByParamRes,
  TSensorTemperatureReq,
  TTableDefrost,
} from "@models/device";
import { openCaseApi } from "@services/openCaseApi";
import {
  renameKeys,
  renderReportTempOver,
  renderReportTempUnder,
} from "../utils/helpers";
import { handleShowToastError } from "@utils/helpers";
import { useContext, useState } from "react";
import { OPEN_CASE_CONSTANT, RADIO_OPT } from "../constant";
import { TAxiosResponse } from "types";
import { FormInstance } from "antd/lib";
import { useNavigate } from "react-router-dom";
import { AppContext } from "@layouts/LayoutAdmin/LayoutAdmin";
import { EUserRoles } from "@models/user";
import { TSelectValue } from "@models/common";
import dayjs from "dayjs";

const { TITLE, OBJECT_KEYS, NAME, MESSAGE } = OPEN_CASE_CONSTANT;

const defaultRangeTemp = {
  maxTemp: 0,
  minTemp: 0,
};

function useOpenCase2() {
  const [reportTemp, setReportTemp] = useState<TReportTemperature[]>([]);
  const [chartTemp, setChartTemp] = useState<TChartTemperature[]>([]);
  const [hourTemp, setHourTemp] = useState<THourTemperature[]>([]);

  const [selectPurpose, setSelectPurpose] = useState<TSelectValue[]>([]);
  const [purposes, setPurposes] = useState<TPurposesRes[]>([]);
  const [rangeTemp, setRangeTemp] = useState<{
    maxTemp: number;
    minTemp: number;
  }>(defaultRangeTemp);

  const navigate = useNavigate();

  const { role, companyName, storeName, companyCode, storeCode } =
    useContext(AppContext);
  const _companyName = companyName || "";
  const _storeName = storeName || "";
  const _companyCode = companyCode || "";
  const _storeCode = storeCode || "";
  const isRoleStore = role && role[0] === EUserRoles.STORE;

  const handleNavigateBack = () => {
    if (window.history?.length && window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("/", { replace: true });
    }
  };

  const handleMappingSelect = ({
    code,
    min,
    max,
    form,
  }: {
    code: string;
    min: string;
    max: string;
    form: FormInstance;
  }) => {
    form.setFieldValue(NAME.SELECT, code);
    form.setFieldValue(NAME.LOWER_INPUT, min);
    form.setFieldValue(NAME.UPPER_INPUT, max);
  };

  const handleSelectPurposes = async (
    response: TAxiosResponse<TPurposesRes[]>,
    form: FormInstance
  ) => {
    if (response.data.length) {
      handleMappingSelect({
        code: response.data[0].purposeCode,
        min: response.data[0].minTemp,
        max: response.data[0].maxTemp,
        form,
      });
    } else {
      handleMappingSelect({
        code: "",
        min: "",
        max: "",
        form,
      });
    }
  };

  const handleCheckUsePurpose = (
    response: TSensorByParamRes,
    setRadioOpt: (val: number) => void
  ) => {
    if (response.isUseManualSetting) {
      setRadioOpt(RADIO_OPT);
    } else {
      setRadioOpt(1);
    }
  };

  const handleCheckSelectedPurpose = (
    selectedPurpose: TPurposesRes | undefined,
    form: FormInstance,
    setPurposeId: (val: string) => void
  ) => {
    if (selectedPurpose) {
      form.setFieldValue(NAME.LOWER_INPUT, selectedPurpose.minTemp);
      form.setFieldValue(NAME.UPPER_INPUT, selectedPurpose.maxTemp);
      setPurposeId(selectedPurpose.id);
    }
  };

  const handleErrorGetSensor = ({
    error,
    form,
    formSetting,
    setTableDefrost,
    setRadioOpt,
    setBatteryValue,
    setLatestDay,
    setPurposeId,
    setPurposeIdSetting,
    setSensorId,
  }: {
    error: any;
    form: FormInstance;
    formSetting: FormInstance;
    setTableDefrost: (val: TTableDefrost[]) => void;
    setRadioOpt: (val: number) => void;
    setBatteryValue: (val: string) => void;
    setLatestDay: (val: string) => void;
    setPurposeId: (val: string) => void;
    setPurposeIdSetting: (val: string) => void;
    setSensorId: (val: string) => void;
  }) => {
    if (error?.data?.errors[0]?.code === MESSAGE.NOT_FOUND) {
      const formValues = {
        [NAME.MACHINE]: "",
        [NAME.INSTALL_LOCATION]: "",
        [NAME.DEPARTMENT_NAME]: "",
        [NAME.PURPOSE]: "",
        [NAME.MANUFACTURER_NAME]: "",
        [NAME.MANUFACTURER_MODEL]: "",
        [NAME.OPERATING_HOUR_FROM]: ["", ""],
        [NAME.TEMPERATURE_DISTINCTION]: "",
      };
      const formSettingValues = {
        [NAME.SELECT]: "",
        [NAME.LOWER_INPUT]: "",
        [NAME.UPPER_INPUT]: "",
        [NAME.SETTING]: "",
        [NAME.LOWER_NUMBER]: "",
        [NAME.UPPER_NUMBER]: "",
      };
      form.setFieldsValue(formValues);
      formSetting.setFieldsValue(formSettingValues);

      setTableDefrost([]);
      setRadioOpt(1);
      setBatteryValue("");
      setLatestDay("");
      setPurposeId("");
      setPurposeIdSetting("");
      setPurposes([]);
      setSensorId("");
      setReportTemp([]);
      setChartTemp([]);
      setHourTemp([]);
      setRangeTemp(defaultRangeTemp);
    }
  };

  /** EFFECT */
  const effectCompanies = (
    companies: TSelectValue[],
    form: FormInstance,
    searchParams: URLSearchParams
  ) => {
    const companyCode = searchParams.get("companyCode");
    if (companyCode) {
      form.setFieldValue(NAME.COMPANY, companyCode);
    } else {
      if (companies.length) {
        form.setFieldValue(NAME.COMPANY, companies[0].value);
      }
    }
  };

  const effectStores = (
    stores: TSelectValue[],
    form: FormInstance,
    searchParams: URLSearchParams
  ) => {
    const storeCode = searchParams.get("storeCode");
    if (storeCode) {
      form.setFieldValue(NAME.STORE, storeCode);
    } else {
      if (stores.length) {
        form.setFieldValue(NAME.STORE, stores[0].value);
      }
    }
  };

  const effectIdCh = (
    idCh: TSelectValue[],
    form: FormInstance,
    searchParams: URLSearchParams
  ) => {
    const chCode = searchParams.get("chCode");
    if (chCode) {
      form.setFieldValue(NAME.ID_CH, chCode);
    } else {
      if (idCh.length) {
        form.setFieldValue(NAME.ID_CH, idCh[0].value);
      }
    }
  };

  /** API */
  const getTemperatureSensor = async (
    payload: TSensorTemperatureReq,
    setLoadingPage?: (val: boolean) => void
  ) => {
    setLoadingPage?.(true);

    try {
      const response = await openCaseApi.getTemperatureSensor(payload);

      if (response.ok && response.data) {
        const newObj = renameKeys(response.data.value, OBJECT_KEYS);
        setHourTemp([
          {
            key: 1,
            time: TITLE.TEMPERATURE,
            ...newObj,
          },
        ]);

        const chartData = Object.values(response.data.value).map((val, i) => {
          if (val.temperature === TITLE.N_A) {
            return {
              name: `${i}:00`,
            };
          }
          return {
            name: `${i}:00`,
            temperature:
              val.temperature === TITLE.N_A
                ? "0.0_false_null_false"
                : `${val.temperature}_${val.isDefrostingTime}_${val.isActive}_${val.isNoCooling}`,
          };
        });
        setChartTemp(chartData);
        setRangeTemp({
          ...rangeTemp,
          maxTemp: response.data.maxTemp,
          minTemp: response.data.minTemp,
        });
      } else {
        setHourTemp([]);
        setChartTemp([]);
      }
    } catch (error) {
      handleShowToastError(error);
    } finally {
      setLoadingPage?.(false);
    }
  };

  const getReportTemperatureSensor = async (
    payload: TReportTemperatureSensorReq,
    setLoadingPage?: (val: boolean) => void
  ) => {
    setLoadingPage?.(true);

    try {
      const response = await openCaseApi.getReportTemperatureSensor(payload);

      if (response.ok && response.data.length) {
        const listReportTemperatureSensor = response.data.map(
          (value) => value.reportDate.split("T")[0]
        );
        const listReportTemperatureSensorTemp = Array(22)
          .fill(0)
          .map((_, index) => {
            const currentDate = dayjs(payload.reportDate)
              .subtract(index, "day")
              .format("YYYY-MM-DD");
            if (listReportTemperatureSensor.includes(currentDate)) {
              return response.data?.[
                listReportTemperatureSensor.indexOf(currentDate)
              ];
            }
            return undefined;
          });

        setReportTemp([
          {
            key: 1,
            dayChart: TITLE.LOWER_LIMIT,
            dayAgo21: renderReportTempUnder(
              listReportTemperatureSensorTemp[TITLE.DAY_21]
            ),
            dayAgo20: renderReportTempUnder(
              listReportTemperatureSensorTemp[TITLE.DAY_20]
            ),
            dayAgo19: renderReportTempUnder(
              listReportTemperatureSensorTemp[TITLE.DAY_19]
            ),
            dayAgo18: renderReportTempUnder(
              listReportTemperatureSensorTemp[TITLE.DAY_18]
            ),
            dayAgo17: renderReportTempUnder(
              listReportTemperatureSensorTemp[TITLE.DAY_17]
            ),
            dayAgo16: renderReportTempUnder(
              listReportTemperatureSensorTemp[TITLE.DAY_16]
            ),
            dayAgo15: renderReportTempUnder(
              listReportTemperatureSensorTemp[TITLE.DAY_15]
            ),
            dayAgo14: renderReportTempUnder(
              listReportTemperatureSensorTemp[TITLE.DAY_14]
            ),
            dayAgo13: renderReportTempUnder(
              listReportTemperatureSensorTemp[TITLE.DAY_13]
            ),
            dayAgo12: renderReportTempUnder(
              listReportTemperatureSensorTemp[TITLE.DAY_12]
            ),
            dayAgo11: renderReportTempUnder(
              listReportTemperatureSensorTemp[TITLE.DAY_11]
            ),
            dayAgo10: renderReportTempUnder(
              listReportTemperatureSensorTemp[TITLE.DAY_10]
            ),
            dayAgo9: renderReportTempUnder(
              listReportTemperatureSensorTemp[TITLE.DAY_9]
            ),
            dayAgo8: renderReportTempUnder(
              listReportTemperatureSensorTemp[TITLE.DAY_8]
            ),
            dayAgo7: renderReportTempUnder(
              listReportTemperatureSensorTemp[TITLE.DAY_7]
            ),
            dayAgo6: renderReportTempUnder(
              listReportTemperatureSensorTemp[TITLE.DAY_6]
            ),
            dayAgo5: renderReportTempUnder(
              listReportTemperatureSensorTemp[TITLE.DAY_5]
            ),
            dayAgo4: renderReportTempUnder(
              listReportTemperatureSensorTemp[TITLE.DAY_4]
            ),
            dayAgo3: renderReportTempUnder(
              listReportTemperatureSensorTemp[TITLE.DAY_3]
            ),
            dayAgo2: renderReportTempUnder(
              listReportTemperatureSensorTemp[TITLE.DAY_2]
            ),
            dayAgo1: renderReportTempUnder(listReportTemperatureSensorTemp[1]),
            dayAgo0: renderReportTempUnder(listReportTemperatureSensorTemp[0]),
          },
          {
            key: 2,
            dayChart: TITLE.UPPER_LIMIT,
            dayAgo21: renderReportTempOver(
              listReportTemperatureSensorTemp[TITLE.DAY_21]
            ),
            dayAgo20: renderReportTempOver(
              listReportTemperatureSensorTemp[TITLE.DAY_20]
            ),
            dayAgo19: renderReportTempOver(
              listReportTemperatureSensorTemp[TITLE.DAY_19]
            ),
            dayAgo18: renderReportTempOver(
              listReportTemperatureSensorTemp[TITLE.DAY_18]
            ),
            dayAgo17: renderReportTempOver(
              listReportTemperatureSensorTemp[TITLE.DAY_17]
            ),
            dayAgo16: renderReportTempOver(
              listReportTemperatureSensorTemp[TITLE.DAY_16]
            ),
            dayAgo15: renderReportTempOver(
              listReportTemperatureSensorTemp[TITLE.DAY_15]
            ),
            dayAgo14: renderReportTempOver(
              listReportTemperatureSensorTemp[TITLE.DAY_14]
            ),
            dayAgo13: renderReportTempOver(
              listReportTemperatureSensorTemp[TITLE.DAY_13]
            ),
            dayAgo12: renderReportTempOver(
              listReportTemperatureSensorTemp[TITLE.DAY_12]
            ),
            dayAgo11: renderReportTempOver(
              listReportTemperatureSensorTemp[TITLE.DAY_11]
            ),
            dayAgo10: renderReportTempOver(
              listReportTemperatureSensorTemp[TITLE.DAY_10]
            ),
            dayAgo9: renderReportTempOver(
              listReportTemperatureSensorTemp[TITLE.DAY_9]
            ),
            dayAgo8: renderReportTempOver(
              listReportTemperatureSensorTemp[TITLE.DAY_8]
            ),
            dayAgo7: renderReportTempOver(
              listReportTemperatureSensorTemp[TITLE.DAY_7]
            ),
            dayAgo6: renderReportTempOver(
              listReportTemperatureSensorTemp[TITLE.DAY_6]
            ),
            dayAgo5: renderReportTempOver(
              listReportTemperatureSensorTemp[TITLE.DAY_5]
            ),
            dayAgo4: renderReportTempOver(
              listReportTemperatureSensorTemp[TITLE.DAY_4]
            ),
            dayAgo3: renderReportTempOver(
              listReportTemperatureSensorTemp[TITLE.DAY_3]
            ),
            dayAgo2: renderReportTempOver(
              listReportTemperatureSensorTemp[TITLE.DAY_2]
            ),
            dayAgo1: renderReportTempOver(listReportTemperatureSensorTemp[1]),
            dayAgo0: renderReportTempOver(listReportTemperatureSensorTemp[0]),
          },
        ]);
      } else {
        setReportTemp([]);
      }
    } catch (error) {
      handleShowToastError(error);
    } finally {
      setLoadingPage?.(false);
    }
  };

  const getAllPurposes = async (payload: TPurposesReq, form: FormInstance) => {
    try {
      const response = await openCaseApi.getAllPurposes(payload);

      if (response.ok) {
        const convertPurposes = response.data.map((val) => ({
          value: val.purposeCode,
          label: val.purposeName,
        }));
        setSelectPurpose(convertPurposes);
        setPurposes(response.data);

        handleSelectPurposes(response, form);
      }
    } catch (error) {
      handleShowToastError(error);
    }
  };

  return {
    state: {
      reportTemp,
      chartTemp,
      hourTemp,
      isRoleStore,
      selectPurpose,
      purposes,
      rangeTemp,

      _companyName,
      _storeName,
      _storeCode,
      _companyCode,
    },
    handler: {
      setSelectPurpose,
      getTemperatureSensor,
      getReportTemperatureSensor,
      handleSelectPurposes,
      handleCheckUsePurpose,
      handleNavigateBack,
      handleCheckSelectedPurpose,
      getAllPurposes,
      handleErrorGetSensor,
    },
    effect: {
      effectCompanies,
      effectStores,
      effectIdCh,
    },
  };
}

export default useOpenCase2;
