import { AxiosRequestConfig } from "axios";

export enum EOrder {
  ASC = "ascend",
  DESC = "descend",
}

export enum EHttpStatusCode {
  OK = 200,
  CREATED = 201,
  NOT_MODIFIED = 304,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  REQUEST_TIMEOUT = 408,
  UNPROCESSABLE_ENTITY = 422,
  INTERNAL_SERVER_ERROR = 500,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
}

export enum ELoginFailResCode {
  INVALID_USERNAME_FORMAT = "Invalid username format",
  INVALID_PASSWORD_FORMAT = "Invalid password format",
  NOT_EXIST_USER = "Not exist user",
  WRONG_PASSWORD = "Wrong password",
  DEACTIVE_USER = "Deactive user",
}

export enum EForgotPasswordResCode {
  NOT_FOUND = "NOT_FOUND",
  NOT_ACCEPTABLE = "NOT_ACCEPTABLE",
  FAILED = "failed",
}

export enum EResetPasswordResCode {
  CAN_NOT_RESET = 304,
}

export type TSearchRequest = {
  keyword: string;
  page: number;
  size: number;
  order?: string;
  direction?: EOrder;
};

export type TSearchResponse<I = any> = {
  items: I[];
  total: number;
  size: number;
  current: number;
  totalPage: number;
};

export type TImportRowError = {
  rowNumber: number;
  columns: {
    name: string;
    value: string;
    error: string;
  }[];
};

export type TAxiosError = {
  config: AxiosRequestConfig;
  data?: any;
  request?: any;
  headers?: any;
  status?: number;
};
