import { TableProps } from "antd/lib";
import { DEVICE_CONSTANT } from "./constant";
import { Button } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  FolderViewOutlined,
} from "@ant-design/icons";
import { TListDevice } from "@models/device";

const { TITLE, TABLE_KEY } = DEVICE_CONSTANT;

const renderCodeName = (code: string, name: string) => {
  return code + `${name ? `_${name}` : ""}`;
};

export const getColumns = ({
  disableBtnOther,
  handleOpenModalDelete,
  handleOpenModalDetail,
  handleOpenModalUpdate,
}: {
  disableBtnOther: boolean;
  handleOpenModalDelete: (record: TListDevice) => void;
  handleOpenModalDetail: (record: TListDevice) => void;
  handleOpenModalUpdate: (record: TListDevice, id: string) => void;
}): TableProps<TListDevice>["columns"] => {
  return [
    {
      title: TITLE.NO,
      key: TABLE_KEY.NO,
      dataIndex: TABLE_KEY.NO,
      align: "center",
      width: 75,
    },
    {
      title: TITLE.COMPANY_CODE,
      key: TABLE_KEY.COMPANY_CODE,
      dataIndex: TABLE_KEY.COMPANY_CODE,
      align: "center",
      width: 400,
      render: (value, record) => {
        if (record) {
          return renderCodeName(value, record.companyName);
        }
        return null;
      },
    },
    {
      title: TITLE.STORE_CODE,
      key: TABLE_KEY.STORE_CODE,
      dataIndex: TABLE_KEY.STORE_CODE,
      align: "center",
      width: 300,
      render: (value, record) => {
        if (record) {
          return renderCodeName(value, record.storeName);
        }
        return null;
      },
    },
    {
      title: TITLE.CH_CODE,
      key: TABLE_KEY.CH_CODE,
      dataIndex: TABLE_KEY.CH_CODE,
      align: "center",
      width: 271,
    },
    {
      title: TITLE.MANUFACTURER_CODE_COL,
      key: TABLE_KEY.MANUFACTURER_CODE,
      dataIndex: TABLE_KEY.MANUFACTURER_CODE,
      align: "center",
      width: 271,
      render: (value, record) => {
        if (record) {
          return renderCodeName(value, record.manufacturerName);
        }
        return null;
      },
    },
    {
      title: TITLE.MANUFACTURER_MODEL,
      key: TABLE_KEY.MANUFACTURER_MODEL,
      dataIndex: TABLE_KEY.MANUFACTURER_MODEL,
      align: "center",
      width: 220,
    },
    {
      title: TITLE.ACTION,
      align: "center",
      width: 250,
      render(_, record) {
        return (
          <div className="list-page_modal-btns">
            <Button
              onClick={() => {
                handleOpenModalDetail(record);
              }}
            >
              <FolderViewOutlined
                style={{
                  fontSize: "21px",
                  color: "#344054",
                  cursor: "pointer",
                }}
              />
            </Button>
            <Button
              disabled={disableBtnOther}
              onClick={() => {
                handleOpenModalUpdate(record, record.sensorId);
              }}
            >
              <EditOutlined
                style={{
                  fontSize: "21px",
                  color: `${disableBtnOther ? "#99a6bb" : "#344054"}`,
                  cursor: "pointer",
                }}
              />
            </Button>
            <Button
              disabled={disableBtnOther}
              onClick={() => {
                handleOpenModalDelete(record);
              }}
            >
              <DeleteOutlined
                style={{
                  fontSize: "21px",
                  color: `${disableBtnOther ? "#99a6bb" : "#F04438"}`,
                  cursor: "pointer",
                }}
              />
            </Button>
          </div>
        );
      },
    },
  ];
};
