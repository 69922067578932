import { TAxiosResponse } from "types";
import { axiosInstance } from "./axiosInstance";
import { TCommonError } from "@models/common";
import {
  TFormModalRegisterReq,
  TListStoreReq,
  TListStoreRes,
  TFormModalRes,
  TStoresByAreaRes,
  TFormModalUpdateReq,
  TCheckStoreExistReq,
} from "@models/store";
import { DEFAULT_PAGE_SIZE } from "@constants/common";

const storeApi = {
  getListStore: async (payload: TListStoreReq) => {
    const _pageIndex = payload.pageIndex ? payload.pageIndex : 1;
    const _payload: Omit<TListStoreReq, "areaId" | "pageIndex"> = {
      areaCode: payload.areaCode || "",
      companyCode: payload.companyCode || "",
      storeCode: payload.storeCode || "",
    };
    const url = `/store/search-stores?CompanyCode=${_payload.companyCode}&AreaCode=${_payload.areaCode}&StoreCode=${_payload.storeCode}&PageIndex=${_pageIndex}&PageSize=${DEFAULT_PAGE_SIZE}`;

    const response: TAxiosResponse<TListStoreRes[], TCommonError> =
      await axiosInstance.get(url);
    return response;
  },

  getStoresByArea: async (payload: string) => {
    const url = `/store/stores-by-area?AreaId=${payload}`;
    const response: TAxiosResponse<TStoresByAreaRes[], TCommonError> =
      await axiosInstance.get(url);
    return response;
  },

  getDetailStore: async (payload: string) => {
    const url = `/store/stores/${payload}`;
    const response: TAxiosResponse<TFormModalRes, TCommonError> =
      await axiosInstance.get(url);
    return response;
  },

  registrationStore: async (payload: TFormModalRegisterReq) => {
    const _payload: TFormModalRegisterReq = {
      ...payload,
      storeCode: payload.storeCode.trim(),
      storeName: payload.storeName.trim(),
      panasonicId: payload.panasonicId ? payload.panasonicId.trim() : "",
      okamuraId: payload.okamuraId ? payload.okamuraId.trim() : "",
      kiwiId: payload.kiwiId ? payload.kiwiId.trim() : "",
      email: payload.email || "",
    };
    const url = "/store/register-store";
    const response: TAxiosResponse<TFormModalRes, TCommonError> =
      await axiosInstance.post(url, _payload);
    return response;
  },

  updateStore: async (payload: TFormModalUpdateReq) => {
    const _payload: TFormModalUpdateReq = {
      ...payload,
      storeCode: payload.storeCode.trim(),
      storeName: payload.storeName.trim(),
      panasonicId: payload.panasonicId ? payload.panasonicId.trim() : "",
      okamuraId: payload.okamuraId ? payload.okamuraId.trim() : "",
      kiwiId: payload.kiwiId ? payload.kiwiId.trim() : "",
    };
    const url = "/store/update-store";
    const response: TAxiosResponse<any, TCommonError> = await axiosInstance.put(
      url,
      _payload
    );
    return response;
  },

  deleteStore: async (payload: string) => {
    const url = `/store/delete-store/${payload}`;
    const response: TAxiosResponse<any, TCommonError> =
      await axiosInstance.delete(url);
    return response;
  },

  checkStoreExist: async (payload: TCheckStoreExistReq) => {
    const url = `/store/stores/exists`;
    const response: TAxiosResponse<any, TCommonError> =
      await axiosInstance.post(url, payload);
    return response;
  },
};

export { storeApi };
