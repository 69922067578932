import { COMMON_MESSAGE_ERROR, OPTION_EMPTY } from "@constants/common";
import { TSelectValue } from "@models/common";
import {
  TCheckSUserExitsReq,
  TFormDetailUserRes,
  TFormUserModalRes,
  TFormUserReq,
  TListUserReq,
  TListUserTable,
  TRegisUserReq,
  TUpdateUserReq,
} from "@models/user";
import { layoutApi } from "@services/layoutApi";
import { userApi } from "@services/userApi";
import { appToast } from "@utils/appToast";
import { handleShowToastError } from "@utils/helpers";
import { FormInstance } from "antd/lib";
import _ from "lodash";
import { TAxiosResponse } from "types";
import { EModalType } from "types/others";
import { EHttpStatusCode } from "types/service";
import { USER_CONSTANT } from "../constant";

const { NAME, MESSAGE, TITLE } = USER_CONSTANT;

const userRoleOption: any = [
  {
    value: 1,
    label: "店長",
  },
  {
    value: 2,
    label: "Mentex",
  },
  {
    value: 3,
    label: "Admin",
  },
];

function useUserApi() {
  const registrationUser = async (
    params: TRegisUserReq,
    handleOkModalSuccess: (val: TFormUserModalRes) => void,
    setLoadingPage: (val: boolean) => void,
    handleModalFail: () => void,
    handleCommonError: () => void
  ) => {
    setLoadingPage(true);
    try {
      const response = await userApi.registrationUser(params);
      if (response.ok) {
        handleOkModalSuccess(response.data);
      }
    } catch (error: any) {
      handleCommonError();
      if (
        error?.status === EHttpStatusCode.BAD_REQUEST &&
        error?.data?.errors?.[0]?.description
      ) {
        handleModalFail();
        return;
      }
      const err = error as TAxiosResponse;
      if (err.status === EHttpStatusCode.FORBIDDEN) {
        appToast.error({ message: COMMON_MESSAGE_ERROR });
        return;
      }
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const detailUser = async (
    payload: string,
    companiesModal: TSelectValue[],
    setStoreModal: (val: TSelectValue[]) => void,
    handleSetFormValues: (val: TFormDetailUserRes) => void,
    setLoadingModal: (val: boolean) => void
  ) => {
    setLoadingModal(true);
    try {
      const response = await userApi.getDetailUser(payload);

      if (response.ok) {
        const { data } = response;
        const findCompany = companiesModal.find(
          (item) => item.value === data.companyCode
        );
        if (findCompany) {
          const responseStore = await layoutApi.getStoresByCompany({
            companyCode: findCompany.value,
            companyName: findCompany.label.split("_")[1],
          });
          if (responseStore.ok && responseStore.data.length) {
            setStoreModal(
              responseStore.data.map((item) => ({
                ...item,
                label: item.storeCode + "_" + item.storeName,
                value: item.storeCode,
              }))
            );
          } else {
            setStoreModal([]);
          }
        } else {
          setStoreModal([]);
        }
        handleSetFormValues({ ...data, id: payload });
      }
    } catch (error) {
      handleShowToastError(error);
      handleSetFormValues({
        id: "",
        companyCode: "",
        name: "",
        role: undefined,
        storeCode: "",
        userName: "",
      });
    } finally {
      setLoadingModal(false);
    }
  };

  const updateUser = async (
    params: TUpdateUserReq,
    handleOkModalSuccess: (val: any) => void,
    setLoadingPage: (val: boolean) => void,
    handleModalFail: () => void,
    handleCommonError: () => void
  ) => {
    setLoadingPage(true);
    try {
      const response = await userApi.updateUser(params);
      if (response.ok) {
        handleOkModalSuccess(response.data);
      }
    } catch (error: any) {
      handleCommonError();
      if (
        error?.status === EHttpStatusCode.BAD_REQUEST &&
        error?.data?.errors?.[0]?.description
      ) {
        handleModalFail();
        return;
      }
      const err = error as TAxiosResponse;
      if (err.status === EHttpStatusCode.FORBIDDEN) {
        appToast.error({ message: COMMON_MESSAGE_ERROR });
        return;
      }
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const deleteUser = async (
    payload: string,
    listUser: TListUserTable[],
    setListUser: (val: TListUserTable[]) => void,
    setLoadingPage: (val: boolean) => void,
    setModalSuccess: (val: boolean) => void,
    setModalType: (val: EModalType | null | string) => void,
    handleCloseModalDelete: () => void,
    handleDeleteFail: (val: string) => void
  ) => {
    setLoadingPage(true);

    try {
      const response = await userApi.deleteUser(payload);
      if (response.ok) {
        setListUser(
          [
            ..._.filter(listUser, function (item) {
              return item.id !== payload;
            }),
          ]
            .filter((item) => !item.isDeleted)
            .map((item, index) => ({ ...item, key: index }))
        );

        setModalSuccess(true);
        setModalType(EModalType.SUCCESS_DELETE);
        handleCloseModalDelete();
      }
    } catch (error: any) {
      if (
        error?.status === EHttpStatusCode.BAD_REQUEST &&
        error?.data?.errors?.[0]?.description === "Not found user"
      ) {
        handleDeleteFail("");
        return;
      }
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const getListUserSearch = async (
    payload: TListUserReq,
    setLoadingPage: (val: boolean) => void,
    setListUser: (val: TListUserTable[]) => void,
    handleSetSearchParams: (val: TFormUserReq) => void,
    setPageIndex: (val: number) => void,
    setHasNext: (val: boolean) => void
  ) => {
    setLoadingPage(true);

    try {
      const response = await userApi.getListUser(payload);
      if (response.ok) {
        setListUser(
          response.data.map((item, index) => ({
            ...item,
            key: index + 1,
          }))
        );
        handleSetSearchParams(payload);
        setPageIndex(1);
        setHasNext(response.pagination?.hasNext as boolean);
      }
    } catch (error) {
      setListUser([]);
      setPageIndex(0);
      setHasNext(false);
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const getListUserScroll = async (
    payload: TListUserReq,
    listUser: TListUserTable[],
    pageIndex: number,
    // loadedIds: Set<string>,
    setLoadingPage: (val: boolean) => void,
    setListUser: (val: any) => void,
    // setLoadedIds: (val: any) => void,
    setPageIndex: (val: number) => void,
    setHasNext: (val: boolean) => void
  ) => {
    setLoadingPage(true);
    try {
      const response = await userApi.getListUser(payload);

      if (response.ok) {
        setHasNext(response.pagination?.hasNext as boolean);

        if (listUser.length !== response.pagination?.totalCount) {
          const startIndex = listUser.length;
          setPageIndex(pageIndex + 1);
          setListUser([
            ...listUser,
            ...response.data.map((item, index) => ({
              ...item,
              key: startIndex + index + 1,
            })),
          ]);

          // const filteredRecords = response.data
          //   .filter((record) => !loadedIds.has(record.id) && !record.isDeleted)
          //   .map((item, index) => ({
          //     ...item,
          //     key: startIndex + index + 1,
          //   }));
          // setListUser((prevData: TListUserRes[]) => [
          //   ...prevData,
          //   ...filteredRecords,
          // ]);
          // setLoadedIds(
          //   (prevIds: any) =>
          //     new Set([
          //       ...prevIds,
          //       ...filteredRecords.map((record) => record.id),
          //     ])
          // );
        }
      }
    } catch (error) {
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const fetchInitialApis = async (
    pageIndex: number,
    setLoadingPage: (val: boolean) => void,
    setLoadingCompany: (val: boolean) => void,
    handleSetSearchParams: (val: TFormUserReq) => void,
    setCompanies: (val: TSelectValue[]) => void,
    setStores: (val: TSelectValue[]) => void,
    setRoles: (val: TSelectValue[]) => void,
    setListUser: (val: TListUserTable[]) => void,
    setCompaniesModal: (val: TSelectValue[]) => void,
    setRolesModal: (val: TSelectValue[]) => void,
    setHasNext: (val: boolean) => void,
    setPageIndex: (val: number) => void
  ) => {
    setLoadingPage(true);

    try {
      const resCompany = await layoutApi.getCompanies();
      if (resCompany.ok) {
        const convertCompanies = resCompany.data.map((val) => ({
          value: val.companyCode,
          label: val.companyCode + "_" + val.companyName,
          children: val.id,
        }));
        const convertRole = userRoleOption.map((item: any) => ({
          value: item.value,
          label: item.value + "_" + item.label,
        }));
        setCompanies([OPTION_EMPTY, ...convertCompanies]);
        setCompaniesModal(convertCompanies);
        setRoles([OPTION_EMPTY, ...convertRole]);
        setRolesModal(convertRole);
        const resStore = await layoutApi.getStoresByCompany({
          companyCode: convertCompanies[0].value,
          companyName: convertCompanies[0].label.split("_")[1],
        });

        if (resStore.ok && resStore.data.length) {
          const convertStores = resStore.data.map((val) => ({
            value: val.storeCode,
            label: val.storeCode + "_" + val.storeName,
          }));
          setStores([OPTION_EMPTY, ...convertStores]);
        }
        handleSetSearchParams({
          username: "",
          name: "",
          companyCode: resCompany.data[0].companyCode,
          storeCode: resStore.data[0].storeCode,
          role: 1,
        });

        const response = await userApi.getListUser({
          username: "",
          name: "",
          companyCode: resCompany.data[0].companyCode,
          storeCode: resStore.data[0].storeCode,
          role: 1,
          pageIndex,
        });
        if (response.ok) {
          const convertListUser: TListUserTable[] = response.data
            .filter((item) => !item.isDeleted)
            .map((item, index) => ({
              ...item,
              key: index + 1,
            }));
          setListUser(convertListUser);
          setPageIndex(1);
          setHasNext(response.pagination?.hasNext as boolean);
        }
      }
    } catch (error) {
      setListUser([]);
      setPageIndex(0);
      setHasNext(false);
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
      setLoadingCompany(false);
    }
  };

  const fetchApiRedirect = async (
    payload: TFormUserReq,
    pageIndex: number,
    setLoadingPage: (val: boolean) => void,
    setLoadingCompany: (val: boolean) => void,
    handleSetSearchParams: (val: TFormUserReq) => void,
    setCompanies: (val: TSelectValue[]) => void,
    setRole: (val: TSelectValue[]) => void,
    setStores: (val: TSelectValue[]) => void,
    setListUser: (val: TListUserTable[]) => void,
    setCompaniesModal: (val: TSelectValue[]) => void,
    setRolesModal: (val: TSelectValue[]) => void,
    setHasNext: (val: boolean) => void,
    setPageIndex: (val: number) => void
  ) => {
    setLoadingPage(true);

    try {
      const resCompany = await layoutApi.getCompanies();
      if (resCompany.ok) {
        const convertCompanies = resCompany.data.map((val) => ({
          value: val.companyCode,
          label: val.companyCode + "_" + val.companyName,
          children: val.id,
        }));
        const convertRole = userRoleOption.map((item: any) => ({
          value: item.value,
          label: item.value + "_" + item.label,
        }));
        setCompanies([OPTION_EMPTY, ...convertCompanies]);
        setCompaniesModal(convertCompanies);
        setRole([OPTION_EMPTY, ...convertRole]);
        setRolesModal(convertRole);
        const resStore = await layoutApi.getStoresByCompany({
          companyCode: convertCompanies[0].value,
          companyName: convertCompanies[0].label.split("_")[1],
        });
        if (resStore.ok && resStore.data.length) {
          const convertStores = resStore.data.map((val) => ({
            value: val.storeCode,
            label: val.storeCode + "_" + val.storeName,
          }));
          setStores([OPTION_EMPTY, ...convertStores]);
        }
      }

      handleSetSearchParams({
        username: payload.username,
        name: payload.name,
        companyCode: payload.companyCode,
        storeCode: payload.storeCode,
        role: payload.role,
      });

      const response = await userApi.getListUser({ ...payload, pageIndex });
      if (response.ok) {
        const convertListUser: TListUserTable[] = response.data
          .filter((item) => !item.isDeleted)
          .map((item, index) => ({
            ...item,
            key: index + 1,
          }));
        setListUser(convertListUser);
        setPageIndex(1);
        setHasNext(response.pagination?.hasNext as boolean);
      }
    } catch (error) {
      setListUser([]);
      setPageIndex(0);
      setHasNext(false);
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
      setLoadingCompany(false);
    }
  };

  const checkExistUser = async (
    payload: TCheckSUserExitsReq,
    formModal: FormInstance,
    modalType: EModalType,
    setModalType: (val: EModalType | null | string) => void,
    setLoadingPage: (val: boolean) => void
  ) => {
    setLoadingPage(true);
    try {
      const response = await userApi.checkUserExist(payload);
      if (response.ok) {
        setModalType(modalType);
      }
    } catch (error: any) {
      if (
        error.status === EHttpStatusCode.BAD_REQUEST &&
        error?.data?.errors.length
      ) {
        const result = _.uniq(
          error.data.errors.map((item: any) => item.columnName?.toString())
        );

        if (!result.length) {
          return;
        } else {
          const errorCode: any = result[0];
          if (errorCode === TITLE.ID) {
            formModal.setFields([
              { name: [NAME.MODAL_USERNAME], errors: [MESSAGE.DATA_EXIST] },
            ]);
          }
          if (errorCode === "会社・事業部コード") {
            formModal.setFields([
              {
                name: [NAME.MODAL_COMPANY_CODE],
                errors: [MESSAGE.EXIST_COMPANY_STORE],
              },
              {
                name: [NAME.MODAL_STORE_CODE],
                errors: [MESSAGE.EXIST_COMPANY_STORE],
              },
            ]);
          }
        }
        return;
      }
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const checkUpdateUserExist = async (
    payload: TCheckSUserExitsReq,
    formModal: FormInstance,
    modalType: EModalType,
    setModalType: (val: EModalType | null | string) => void,
    setLoadingPage: (val: boolean) => void
  ) => {
    setLoadingPage(true);
    try {
      const response = await userApi.checkUpdateUserExist(payload);
      if (response.ok) {
        setModalType(modalType);
      }
    } catch (error: any) {
      if (
        error.status === EHttpStatusCode.BAD_REQUEST &&
        error?.data?.errors.length
      ) {
        const result = _.uniq(
          error.data.errors.map((item: any) => item.columnName?.toString())
        );

        if (!result.length) {
          return;
        } else {
          const errorCode: any = result[0];
          if (errorCode === "CompanyCode") {
            formModal.setFields([
              {
                name: [NAME.MODAL_COMPANY_CODE],
                errors: [MESSAGE.EXIST_COMPANY_STORE],
              },
              {
                name: [NAME.MODAL_STORE_CODE],
                errors: [MESSAGE.EXIST_COMPANY_STORE],
              },
            ]);
          }
        }
        return;
      }
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  // EFFECT
  const effectCompany = (
    companyParam: string,
    form: FormInstance,
    companies: TSelectValue[]
  ) => {
    if (companyParam) {
      form.setFieldValue(NAME.COMPANY, companyParam);
    } else {
      if (companies.length) {
        form.setFieldValue(NAME.COMPANY, companies[1].value);
      } else {
        form.setFieldValue(NAME.COMPANY, "");
      }
    }
  };

  const effectStore = (storeParam: string, form: FormInstance) => {
    if (storeParam) {
      form.setFieldValue(NAME.STORE, storeParam);
    }
  };

  const effectRole = (roleParam: string, form: FormInstance) => {
    if (roleParam) {
      form.setFieldValue(NAME.ROLE, Number(roleParam));
    }
  };

  return {
    handler: {
      getListUserSearch,
      getListUserScroll,
      fetchInitialApis,
      fetchApiRedirect,
      checkExistUser,
      checkUpdateUserExist,
      registrationUser,
      updateUser,
      detailUser,
      deleteUser,
    },
    effect: {
      effectCompany,
      effectStore,
      effectRole,
    },
  };
}

export default useUserApi;
