export enum EFormType {
  ADD,
  UPDATE,
}

export enum EDayOfWeek {
  SUN,
  MON,
  TUES,
  WED,
  THUR,
  FRI,
  SAT,
}

export enum ETabName {
  USER = "user",
  COMPANY = "company",
  AREA = "area",
  STORE = "store",
  SENSOR = "sensor",
  MANUFACTURER = "manufacturer",
  PURPOSE = "purpose",
  DEPARTMENT = "department",
}

export enum ETemperatureType {
  REFRIGERATED = "1",
  FROZEN = "2",
  UNREFRIGERATED = "3",
  NOT_USED = "9",
}

export enum EInstallLocation {
  SALE_FLOOR = "0",
  BACKYARD = "1",
}

export enum EModalType {
  REGISTRATION = "registration",
  DETAIL = "detail",
  UPDATE = "update",
  DELETE = "delete",

  CONFIRM_REGISTRATION = "confirm_registration",
  CONFIRM_UPDATE = "confirm_update",

  CONFIRM_REGISTRATION_FINAL = "confirm_registration_final",
  CONFIRM_UPDATE_FINAL = "confirm_update_final",

  SUCCESS_REGISTRATION = "success_registration",
  SUCCESS_UPDATE = "success_update",
  SUCCESS_DELETE = "success_delete",

  FAIL_REGISTRATION = "fail_registration",
  FAIL_UPDATE = "fail_update",
  FAIL_DELETE = "fail_delete",
}
