import { images, svgs } from "@constants";
import { MapIcon, SearchIcon } from "@constants/svgs";
import {
  Button,
  ConfigProvider,
  DatePicker,
  Divider,
  Form,
  Input,
  Select,
  Switch,
  Table,
} from "antd";
import dayjs from "dayjs";

import EmptyDataTable from "@components/EmptyDataTable/EmptyDataTable";
import { DATE_FORMAT } from "@constants/common";
import { handleDisabledDate } from "@utils/helpers";
import {
  ConfirmApproveModalWrapper,
  ModalEditRemarkWrapper,
  ModalPeriodTimeWrapper,
  TemperatureReportWrapper,
} from "./TemperatureReportStyled";
import { TEMPERATURE_CONSTANT } from "./constant";
import useTemperatureReport from "./hook/useTemperatureReport";
import { LoadingCommon } from "@components";
import MessageError from "./components/MessageError";
import CheckboxExport from "./components/CheckboxExport";
import SuffixIcon from "./components/SuffixIcon";
import { handleDisableSelect, renderTitleButtonConfirm } from "./utils/helpers";

const { TITLE, LABEL, NAME, PLACEHOLDER, MESSAGE } = TEMPERATURE_CONSTANT;

const FIELDS_CONFIG = {
  COMPANY: { required: false, label: LABEL.COMPANY, name: NAME.COMPANY },
  STORE: {
    required: false,
    label: LABEL.STORE,
    name: NAME.STORE,
  },
  DATE: { required: false, label: LABEL.DATE, name: NAME.DATE },
  MANAGER: { required: false, label: LABEL.MANAGER, name: NAME.MANAGER },
  PURPOSE: {
    required: false,
    label: LABEL.PURPOSE,
    name: NAME.PURPOSE,
  },
  COMMENT: {
    required: false,
    label: LABEL.COMMENT,
    name: NAME.COMMENT,
  },
  START_DATE: {
    required: false,
    label: TITLE.START_DATE,
    name: NAME.START_DATE,
  },
  END_DATE: {
    required: false,
    label: TITLE.END_DATE,
    name: NAME.END_DATE,
  },
  CHECKBOX_EXPORT: {
    required: false,
    label: "",
    name: NAME.CHECKBOX,
    valuePropName: "checked",
  },
};

function TemperatureReportPage() {
  const { state, handler } = useTemperatureReport();

  return (
    <LoadingCommon loading={state.loadingPage}>
      <TemperatureReportWrapper
        changeswitch={`${state.changeSwitch}`}
        listdata={state.sensorReports.length}
      >
        <div className="temperature-report_header">
          <h1>{TITLE.TEMPERATURE_REPORT}</h1>
          <div className="temperature-report_btns">
            <Button
              onClick={() => handler.setOpenModalConfirm(true)}
              type="primary"
              disabled={
                !state.sensorReports.length ||
                state.disableComment ||
                state.disableFieldAdmin
              }
            >
              {renderTitleButtonConfirm(state.disableComment)}
            </Button>
            <Button onClick={handler.handleOpenModalExport}>
              {TITLE.BTN_EXPORT}
            </Button>
          </div>
        </div>

        <div className="temperature-report_form">
          <Form
            form={state.form}
            name="temperature-report"
            layout="vertical"
            onFinish={handler.handleSubmit}
            initialValues={{
              [NAME.DATE]: dayjs().subtract(1, "d"),
            }}
          >
            <div className="temperature-report_top">
              <div className="temperature-report_form-top">
                {/* MANAGER */}
                <Form.Item {...FIELDS_CONFIG.MANAGER}>
                  <Input
                    disabled
                    suffix={<img src={svgs.userApprove} alt="user_approve" />}
                    size="large"
                    autoComplete="off"
                  />
                </Form.Item>

                {/* DATE */}
                <Form.Item {...FIELDS_CONFIG.DATE}>
                  <DatePicker
                    allowClear={false}
                    showNow={false}
                    disabledDate={handleDisabledDate}
                    inputReadOnly={true}
                    size="large"
                    format={DATE_FORMAT}
                    suffixIcon={
                      <img
                        src={svgs.datePickerTemperature}
                        alt="datepicker_temperature"
                      />
                    }
                  />
                </Form.Item>

                <div className="temperature-report_icon">
                  <div className="temperature-report_switch">
                    <Switch
                      defaultChecked
                      onChange={handler.handleChangeSwitch}
                    />
                    <span>{LABEL.DETAIL_TEMPERATURE}</span>
                  </div>
                  <Divider type="vertical" />
                  <div className="temperature-report_map-link">
                    <p onClick={handler.handleRedirectLayout}>
                      <MapIcon />
                      <span>{LABEL.LAYOUT}</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="temperature-report_form-bottom">
                {/* COMPANY */}
                <Form.Item {...FIELDS_CONFIG.COMPANY}>
                  <Select
                    size="large"
                    options={state.companies}
                    suffixIcon={
                      <SuffixIcon
                        disableItemStore={state.disableItemStore}
                        loading={state.loadingCompany}
                      />
                    }
                    disabled={handleDisableSelect(
                      state.disableItemStore,
                      state.loadingCompany
                    )}
                    onChange={(val) => handler.handleChangeCompany(val)}
                  />
                </Form.Item>

                {/* STORE */}
                <Form.Item {...FIELDS_CONFIG.STORE}>
                  <Select
                    size="large"
                    options={state.selectedStores}
                    suffixIcon={
                      <SuffixIcon
                        disableItemStore={state.disableItemStore}
                        loading={state.loadingStore}
                      />
                    }
                    disabled={handleDisableSelect(
                      state.disableItemStore,
                      state.loadingStore
                    )}
                  />
                </Form.Item>

                {/* PURPOSE */}
                <Form.Item {...FIELDS_CONFIG.PURPOSE}>
                  <Select
                    size="large"
                    options={state.purposes}
                    suffixIcon={
                      <SuffixIcon
                        disableItemStore={false}
                        loading={state.loadingPurpose}
                      />
                    }
                    disabled={state.loadingPurpose}
                  />
                </Form.Item>

                <Button htmlType="submit" icon={<SearchIcon />} />
              </div>
            </div>

            <div className="temperature-report_bottom">
              {/* COMMENT */}
              <Form.Item
                className="temperature-report_textarea"
                {...FIELDS_CONFIG.COMMENT}
              >
                <Input.TextArea
                  disabled={state.disableFieldAdmin || state.disableComment}
                  rows={6}
                  placeholder={PLACEHOLDER.COMMENT}
                  maxLength={200}
                  showCount
                />
              </Form.Item>
            </div>
          </Form>
        </div>

        <div className="temperature-report_table">
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  headerBg: "var(--background-header-table)",
                  borderColor: "var(--color-border-secondary)",
                  headerColor: "var(--color-header-table)",
                  rowHoverBg: "var(--color-row-hover)",
                },
              },
            }}
            renderEmpty={() =>
              !state.sensorReports.length ? (
                <EmptyDataTable message={MESSAGE.NO_DATA} />
              ) : undefined
            }
          >
            <Table
              dataSource={state.sensorReports}
              columns={state.columnsCustom}
              pagination={false}
              scroll={{ y: state.sensorReports.length ? 601 : undefined }}
              bordered
              virtual={state.sensorReports.length > 0}
            />
          </ConfigProvider>
        </div>
      </TemperatureReportWrapper>

      <ModalEditRemarkWrapper
        centered
        closeIcon={false}
        open={state.openModalEditRemark}
        title={TITLE.EDIT}
        confirmLoading={state.loadingPage}
        onOk={() => {
          handler.handleReportTempSensorComment();
        }}
        onCancel={() => {
          handler.handleCloseModalEditRemark();
        }}
        maskClosable={false}
        okText={TITLE.BTN_RENEW}
        cancelText={TITLE.CANCEL}
        width={412}
      >
        <div className="temperature-report_modal-edit">
          <p>{TITLE.REMARK_COLUMN}</p>
          <Input.TextArea
            name="modal_edit"
            value={state.commentModalEdit || ""}
            onChange={(e) => handler.setCommentModalEdit(e.target.value)}
            rows={5}
            maxLength={50}
            showCount
          />
        </div>
      </ModalEditRemarkWrapper>

      <ConfirmApproveModalWrapper
        centered
        closeIcon={false}
        open={state.openModalConfirm}
        title={TITLE.CONFIRM}
        confirmLoading={state.loadingPage}
        onOk={() => {
          handler.handleConfirmReportComment();
        }}
        onCancel={() => {
          handler.setOpenModalConfirm(false);
        }}
        maskClosable={false}
        okText={TITLE.APPROVE}
        cancelText={TITLE.REFUSE}
        width={412}
      >
        <img
          src={images.confirmApproveTemperature}
          alt="confirm_approve_temperature"
        />
        <p className="temperature-report_message-approve">
          {MESSAGE.CONFIRM_APPROVE}
        </p>
      </ConfirmApproveModalWrapper>

      <ModalPeriodTimeWrapper
        closeIcon={false}
        destroyOnClose
        centered
        open={state.openModalPeriodTime}
        title={TITLE.DOWNLOAD_EXCEL}
        confirmLoading={state.loadingPage}
        onOk={() => {
          handler.handleCheckExport();
        }}
        onCancel={() => {
          handler.handleCloseModalExport();
        }}
        maskClosable={false}
        okText={TITLE.APPROVE}
        cancelText={TITLE.CANCEL}
        width={464}
        messageerror={state.messageErrorExport}
      >
        <img
          style={{ marginTop: "16px" }}
          src={images.periodTime}
          alt="period_time"
        />
        <p className="period-time_message">{MESSAGE.SPECIFY_DATE}</p>
        <Form
          form={state.form}
          layout="vertical"
          initialValues={{
            [NAME.START_DATE]: dayjs().subtract(1, "d"),
            [NAME.END_DATE]: dayjs().subtract(1, "d"),
          }}
        >
          <div className="period-time_datepicker">
            <Form.Item {...FIELDS_CONFIG.START_DATE}>
              <DatePicker
                size="large"
                format={DATE_FORMAT}
                allowClear={false}
                inputReadOnly
                suffixIcon={
                  <img
                    src={svgs.datePickerTemperature}
                    alt="period-time_datepicker-start"
                  />
                }
                disabledDate={handler.handleDisableFromDate}
              />
            </Form.Item>
            <Divider type="horizontal" />
            <Form.Item {...FIELDS_CONFIG.END_DATE}>
              <DatePicker
                size="large"
                format={DATE_FORMAT}
                allowClear={false}
                inputReadOnly
                suffixIcon={
                  <img
                    src={svgs.datePickerTemperature}
                    alt="period-time_datepicker-end"
                  />
                }
                disabledDate={handler.handleDisableToDate}
              />
            </Form.Item>
          </div>

          <MessageError messageError={state.messageErrorExport} />
          <CheckboxExport
            disableItemStore={state.disableItemStore}
            message={MESSAGE.EXPORT_DATA}
            config={FIELDS_CONFIG.CHECKBOX_EXPORT}
          />
        </Form>
      </ModalPeriodTimeWrapper>
    </LoadingCommon>
  );
}

export { TemperatureReportPage };
