import { Button, Layout, Menu, SiderProps } from "antd";
import styled from "styled-components";
const { Sider, Content } = Layout;

type TCollapse = {
  collapse: "collapsed" | "expand";
};

const ICON_SIZE = "24px";
const SIDEBAR_COLLAPSED = "var(--sidebar-width-collapsed)";
const SIDEBAR_NORMAL = "var(--sidebar-width)";

export const LayoutAdminWrapperStyled = styled(Layout)`
  background-color: var(--color-white);

  .ant-layout {
    background-color: transparent;
  }
`;

export const SidebarStyled = styled(Sider)<SiderProps & TCollapse>`
  &&& {
    width: ${(props) =>
      props.collapse === "collapsed"
        ? SIDEBAR_COLLAPSED
        : SIDEBAR_NORMAL} !important;
    min-width: ${(props) =>
      props.collapse === "collapsed"
        ? SIDEBAR_COLLAPSED
        : SIDEBAR_NORMAL} !important;
    max-width: ${(props) =>
      props.collapse === "collapsed"
        ? SIDEBAR_COLLAPSED
        : SIDEBAR_NORMAL} !important;

    position: fixed;
    top: 64px;
    left: 0;
    bottom: 0;

    background-color: var(--color-white);

    -webkit-box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%),
      0 0px 6px 0 rgb(206 206 238 / 54%);
    box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%),
      0 0px 6px 0 rgb(206 206 238 / 54%);

    z-index: 999;
  }

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }

  position: relative;

  &::after {
    position: absolute;
    content: "";
    display: block;
    bottom: 76px;
    border-top: 1px solid #f1f3f7;
    width: 100%;
  }
`;

export const MenuStyled = styled(Menu)<{
  collapse: string;
}>`
  &&& {
    /* Common */
    &.ant-menu {
      font-size: var(--font-size-sm);
    }

    &.ant-menu-inline,
    &.ant-menu-vertical {
      border-right: none !important;
      padding: 10px 6px 0;

      display: ${(props) =>
        props.collapse === "collapsed" ? "flex" : "unset"};
      flex-direction: ${(props) =>
        props.collapse === "collapsed" ? "column" : "unset"};
      align-items: ${(props) =>
        props.collapse === "collapsed" ? "center" : "unset"};
    }

    &.ant-menu-inline {
      .ant-menu-item,
      .ant-menu-submenu-title {
        padding-left: 16px !important;
      }
    }

    .ant-menu-item:not(:first-child) {
      margin-top: 12px;
    }

    .ant-menu-item,
    .ant-menu-submenu {
      svg {
        vertical-align: middle;
        color: #98a2b3;
      }
    }

    a {
      color: #98a2b3;
      font-weight: var(--font-medium);
      font-size: var(--font-size-sm);
    }

    .ant-menu-item,
    .ant-menu-submenu-title {
      height: 44px;
      width: ${(props) => (props.collapse === "collapsed" ? "44px" : "100%")};
      margin-bottom: 0px;
      display: flex;
      align-items: center;
    }

    .ant-menu-item,
    .ant-menu-submenu,
    .ant-menu-submenu-title {
      margin: 0;

      &::after {
        border-right: none;
      }

      svg {
        min-width: ${ICON_SIZE} !important;
        min-height: ${ICON_SIZE} !important;
      }
    }

    /* Update padding for icon when collapse menu */
    &.ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
    &.ant-menu.ant-menu-inline-collapsed
      .ant-menu-submenu
      .ant-menu-submenu-title {
      padding: 0 calc(50% - 32px / 2);
    }

    /* on select or open */
    .ant-menu-item-selected {
      background-color: var(--color-sidebar-active);
      border-radius: 12px;
      outline: 4px solid #d7e7ff;

      svg,
      a {
        color: var(--color-white);
      }

      svg {
        path,
        rect {
          fill: white;
        }
      }
    }
  }
`;

export const ContentStyled = styled(Content)<TCollapse>`
  margin-left: var(--sidebar-width-collapsed);
  margin-top: var(--header-height);
  padding: 16px 24px;

  background-color: transparent;
`;

export const CollapseButtonWrapperStyled = styled.div`
  padding: 16px;
  flex: 1;

  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative;

  &&& {
    .ant-btn-default {
      color: #a5b2c3;
      border: none;
      :hover {
        border: none;
        color: #a5b2c3;
      }
    }
  }

  .ver-build {
    position: absolute;
    bottom: 80px;
    left: 10px;
    flex: 1;

    text-align: end;
    color: #98a2b3;
    font-weight: var(--font-bold);
  }
`;

export const CollapseButtonStyled = styled(Button)`
  width: 44px;
  height: 44px;
  padding: 0;

  font-size: 24px;

  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-delay: 0s;
`;
