import { Result } from "antd";
import styled from "styled-components";

export const WrapperStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--z-index-modal-antd);

  background-color: var(--color-white);
`;

export const ResultStyled = styled(Result)`
  padding: 20vmin 10vmin;

  .ant-result-icon {
    margin-bottom: 29px;

    .anticon.anticon-close-circle {
      font-size: 70px;
    }
  }

  .ant-result-title {
    font-weight: 700;
    color: var(--color-text-input);
    line-height: unset;
    margin-bottom: 32px;
  }
  
  .ant-result-subtitle {
    color: var(--color-text-input);
    font-weight: 400;
    font-size: var(--font-size-lg);
  }
`;
