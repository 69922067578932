import { Tooltip } from "antd";
import ThermoIcon from "./ThermoIcon";
import { handleBackgroundColor, handleCheckColor } from "../utils/helpers";
import { DATA_EMPTY } from "@constants/common";

type TCustomTooltipProps = {
  temperature: number | string;
  hour: string;
  changeSwitch: boolean;
  isDefrost: boolean;
  threshold: number;
  isActive: boolean | null;
  isNoCooling: boolean;
};

function CustomTooltip(props: TCustomTooltipProps) {
  const {
    changeSwitch,
    temperature,
    hour,
    isDefrost,
    threshold,
    isActive,
    isNoCooling,
  } = props;
  const renderTemperature = () => {
    if (temperature === DATA_EMPTY) {
      return temperature;
    } else {
      return `${temperature}°C`;
    }
  };

  const styleTemperature = () => {
    if (temperature === DATA_EMPTY) {
      return {
        color: "var(--color-text-primary)",
        textDecoration: "unset",
      };
    } else {
      if (isNoCooling || isDefrost || isActive === false) {
        return {
          color: "var(--color-text-primary)",
          textDecoration: "line-through",
        };
      } else {
        return {
          color: handleCheckColor(threshold),
          textDecoration: "unset",
        };
      }
    }
  };

  const classNameTemperature = () => {
    if (temperature === DATA_EMPTY) {
      return "temperature-report_normal";
    } else {
      if (isNoCooling || isDefrost || isActive === false) {
        return "temperature-report_defrost";
      } else {
        return handleBackgroundColor(threshold);
      }
    }
  };

  return (
    <>
      {changeSwitch ? (
        <span className="temperature-report_time">
          {hour}

          <span
            className={`temperature-report_detail-temperature 
              ${classNameTemperature()}
            `}
          >
            {renderTemperature()}
          </span>
        </span>
      ) : (
        <Tooltip
          color="white"
          placement="right"
          overlayClassName="temperature-report_custom-tooltip"
          overlayInnerStyle={{ color: styleTemperature().color }}
          title={() => {
            return (
              <>
                <ThermoIcon color={styleTemperature().color} />
                <p
                  style={{ textDecoration: styleTemperature().textDecoration }}
                >
                  {renderTemperature()}
                </p>
              </>
            );
          }}
        >
          {temperature === DATA_EMPTY ? (
            <span
              style={{
                textDecoration: "line-through",
                color: "#667085",
              }}
            >
              {hour}
            </span>
          ) : (
            <span style={{ textDecoration: styleTemperature().textDecoration }}>
              {hour}
            </span>
          )}
        </Tooltip>
      )}
    </>
  );
}

export default CustomTooltip;
