import { Layout } from "antd";
import { FC, ReactNode } from "react";
import { ErrorLayoutStyled } from "./ErrorLayout.styled";

type TProps = {
  children: ReactNode;
};

const { Content } = Layout;

const ErrorLayout: FC<TProps> = ({ children }) => {
  return (
    <ErrorLayoutStyled>
      <Layout>
        <Content className="main-content">{children}</Content>
      </Layout>
    </ErrorLayoutStyled>
  );
};

export { ErrorLayout };
