import { AliasToken } from "antd/es/theme/internal";

export const defaultTheme: Partial<AliasToken> = {
  // borderRadius: 2,
  fontFamily: "NotoSansJP, Arial, Helvetica, Verdana, Tahoma, sans-serif",
  colorPrimary: "#006EB0",
  fontSize: 16,
  colorText: "#070708",
  colorBorder: "#d0d5dd",
};
