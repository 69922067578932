import { openCaseApi } from "@services/openCaseApi";
import { handleShowToastError } from "@utils/helpers";
import {
  TPurposesReq,
  TSensorByParamReq,
  TSensorByParamRes,
  TSensorsReq,
} from "@models/device";
import { TSelectValue } from "@models/common";
import { TCompanyReq } from "@models/layout";
import { layoutApi } from "@services/layoutApi";
import { FormInstance } from "antd/lib";
import { OPEN_CASE_CONSTANT } from "../constant";
import dayjs, { Dayjs } from "dayjs";
import _ from "lodash";
import { DATE_HYPHEN_FORMAT } from "@constants/common";

const { NAME } = OPEN_CASE_CONSTANT;

function useOpenCase3() {
  const handleMappingCompanyRoleStore = (
    setCompanies: (val: TSelectValue[]) => void,
    companyCode: string,
    companyName: string
  ) => {
    setCompanies([
      {
        label: companyName,
        value: companyCode,
      },
    ]);
  };

  const handleMappingStoreRoleStore = (
    setStores: (val: TSelectValue[]) => void,
    storeCode: string,
    storeName: string
  ) => {
    setStores([
      {
        label: storeName,
        value: storeCode,
      },
    ]);
  };

  const handleMappingPurposeRoleStore = (
    setSelectPurpose: (val: TSelectValue[]) => void,
    purposeCode: string,
    purposeName: string
  ) => {
    setSelectPurpose([
      {
        label: purposeName,
        value: purposeCode,
      },
    ]);
  };

  // API
  const getChCodeSensor = async (
    payload: TSensorsReq,
    setLoadingIdCh: (val: boolean) => void,
    setIdCh: (val: TSelectValue[]) => void
  ) => {
    setLoadingIdCh(true);

    try {
      const response = await openCaseApi.getChCodeSensor(payload);

      if (response.ok) {
        const convertIdCh = response.data.map((val) => ({
          value: val.chCode,
          label: val.chCode,
        }));
        setIdCh(convertIdCh);
      }
    } catch (error) {
      handleShowToastError(error);
    } finally {
      setLoadingIdCh(false);
    }
  };

  const getStoresByCompany = async (
    payload: TCompanyReq,
    setStores: (val: TSelectValue[]) => void,
    setLoadingStore: (val: boolean) => void,
    setLoadingIdCh: (val: boolean) => void,
    setIdCh: (val: TSelectValue[]) => void,
    getChCodeSensor: (
      val: TSensorsReq,
      setLoadingIdCh: (val: boolean) => void,
      setIdCh: (val: TSelectValue[]) => void
    ) => void
  ) => {
    setLoadingStore(true);
    setLoadingIdCh(true);

    try {
      const response = await layoutApi.getStoresByCompany(payload);

      if (response.ok) {
        const convertStores = response.data.map((val) => ({
          value: val.storeCode,
          label: val.storeName,
        }));
        setStores(convertStores);

        getChCodeSensor(
          {
            companyCode: payload.companyCode,
            storeCode: convertStores[0].value,
          },
          setLoadingIdCh,
          setIdCh
        );
      }
    } catch (error) {
      handleShowToastError(error);
    } finally {
      setLoadingStore(false);
      setLoadingIdCh(false);
    }
  };

  const fetchInitialApisRedirect = async (
    payload: TSensorByParamReq,
    reportDate: string,
    handleErrorGetSensor: (val: any) => void,
    setLoadingPage: (val: boolean) => void,
    setLoadingStore: (val: boolean) => void,
    setLoadingIdCh: (val: boolean) => void,
    handleMappingGetSensorByParam: (
      val: TSensorByParamRes,
      date: string
    ) => void,
    handleSetSearchParams: (payload: TSensorByParamReq, date: string) => void,
    setCompanies: (val: TSelectValue[]) => void,
    setStores: (val: TSelectValue[]) => void,
    setIdCh: (val: TSelectValue[]) => void,
    handleGetTemperature: (id: string, date: string) => void,
    getAllPurposes: (payload: TPurposesReq, form: FormInstance) => void,
    form: FormInstance
  ) => {
    setLoadingPage(true);
    setLoadingStore(true);
    setLoadingIdCh(true);
    try {
      const responseCompany = await layoutApi.getCompanies();

      if (responseCompany.ok) {
        const convertCompanies = responseCompany.data.map((val) => ({
          value: val.companyCode,
          label: val.companyName,
        }));
        const findCompany = _.find(convertCompanies, function (val) {
          return val.value === payload.companyCode;
        });
        setCompanies(convertCompanies);
        getAllPurposes(
          {
            companyCode: payload.companyCode,
            companyName: findCompany ? findCompany.label : "",
          },
          form
        );
        const [resStore, resCh] = await Promise.all([
          layoutApi.getStoresByCompany({
            companyCode: payload.companyCode,
            companyName: findCompany ? findCompany.label : "",
          }),
          openCaseApi.getChCodeSensor({
            companyCode: payload.companyCode,
            storeCode: payload.storeCode,
          }),
        ]);

        if (resStore.ok && resStore.data.length) {
          const convertStores = resStore.data.map((val) => ({
            value: val.storeCode,
            label: val.storeName,
          }));
          setStores(convertStores);
        }

        if (resCh.ok) {
          const convertCh = resCh.data.map((val) => ({
            value: val.chCode,
            label: val.chCode,
          }));
          setIdCh(convertCh);
        }
      }

      const response = await openCaseApi.getSensorByParam(payload);

      handleSetSearchParams(payload, reportDate);
      handleMappingGetSensorByParam(response, reportDate);
      handleGetTemperature(response.id, reportDate);
    } catch (error) {
      handleShowToastError(error);
      handleErrorGetSensor(error);
    } finally {
      setLoadingPage(false);
      setLoadingStore(false);
      setLoadingIdCh(false);
    }
  };

  const fetchInitialApisNoParam = async (
    date: Dayjs,
    setLoadingPage: (val: boolean) => void,
    handleErrorGetSensor: (val: any) => void,
    handleMappingGetSensorByParam: (
      val: TSensorByParamRes,
      date: string
    ) => void,
    handleSetSearchParams: (payload: TSensorByParamReq, date: string) => void,
    setCompanies: (val: TSelectValue[]) => void,
    setStores: (val: TSelectValue[]) => void,
    setIdCh: (val: TSelectValue[]) => void,
    handleGetTemperature: (id: string, date: string) => void,
    getAllPurposes: (payload: TPurposesReq, form: FormInstance) => void,
    form: FormInstance
  ) => {
    try {
      const responseCompany = await layoutApi.getCompanies();
      let convertCompanies: TSelectValue[] = [];
      let convertStores: TSelectValue[] = [];
      let convertChCode: TSelectValue[] = [];

      if (responseCompany.ok) {
        convertCompanies = responseCompany.data.map((val) => ({
          value: val.companyCode,
          label: val.companyName,
        }));
        setCompanies(convertCompanies);
        getAllPurposes(
          {
            companyCode: convertCompanies[0].value,
            companyName: convertCompanies[0].label,
          },
          form
        );
      }

      const resStore = await layoutApi.getStoresByCompany({
        companyCode: convertCompanies[0].value,
        companyName: convertCompanies[0].label,
      });
      if (resStore.ok && resStore.data.length) {
        convertStores = resStore.data.map((val) => ({
          value: val.storeCode,
          label: val.storeName,
        }));
        setStores(convertStores);
      }

      const resChCode = await openCaseApi.getChCodeSensor({
        companyCode: convertCompanies[0].value,
        storeCode: convertStores[0].value,
      });
      if (resChCode.ok) {
        convertChCode = resChCode.data.map((val) => ({
          value: val.chCode,
          label: val.chCode,
        }));
        setIdCh(convertChCode);
      }

      const response = await openCaseApi.getSensorByParam({
        chCode: convertChCode[0].value,
        companyCode: convertCompanies[0].value,
        storeCode: convertStores[0].value,
      });
      handleSetSearchParams(
        {
          chCode: convertChCode[0].value,
          companyCode: convertCompanies[0].value,
          storeCode: convertStores[0].value,
        },
        date.format(DATE_HYPHEN_FORMAT)
      );
      handleMappingGetSensorByParam(response, date.format(DATE_HYPHEN_FORMAT));
      handleGetTemperature(response.id, date.format(DATE_HYPHEN_FORMAT));
    } catch (error) {
      handleShowToastError(error);
      handleErrorGetSensor(error);
    } finally {
      setLoadingPage(false);
    }
  };

  // Handle logic fetch initial for role ADMIN/MENTEX
  const fetchInitialApis = (
    form: FormInstance,
    formDate: FormInstance,
    searchParams: URLSearchParams,
    handleErrorGetSensor: (val: any) => void,
    setLoadingPage: (val: boolean) => void,
    setLoadingStore: (val: boolean) => void,
    setLoadingIdCh: (val: boolean) => void,
    handleMappingGetSensorByParam: (
      val: TSensorByParamRes,
      date: string
    ) => void,
    handleSetSearchParams: (payload: TSensorByParamReq, date: string) => void,
    setCompanies: (val: TSelectValue[]) => void,
    setStores: (val: TSelectValue[]) => void,
    setIdCh: (val: TSelectValue[]) => void,
    date: Dayjs,
    setReportDate: (val: Dayjs) => void,
    handleGetTemperature: (id: string, date: string) => void,
    getAllPurposes: (payload: TPurposesReq, form: FormInstance) => void
  ) => {
    const companyCode = searchParams.get("companyCode");
    const storeCode = searchParams.get("storeCode");
    const chCode = searchParams.get("chCode");
    const reportDate = searchParams.get("reportDate");

    if (companyCode && storeCode && chCode && reportDate) {
      form.setFieldValue(NAME.COMPANY, companyCode);
      form.setFieldValue(NAME.STORE, storeCode);
      form.setFieldValue(NAME.ID_CH, chCode);
      formDate.setFieldValue(NAME.REPORT_DATE, dayjs(reportDate));
      setReportDate(dayjs(reportDate));

      fetchInitialApisRedirect(
        { companyCode, storeCode, chCode },
        reportDate,
        handleErrorGetSensor,
        setLoadingPage,
        setLoadingStore,
        setLoadingIdCh,
        handleMappingGetSensorByParam,
        handleSetSearchParams,
        setCompanies,
        setStores,
        setIdCh,
        handleGetTemperature,
        getAllPurposes,
        form
      );
    } else {
      formDate.setFieldValue(NAME.REPORT_DATE, date);
      fetchInitialApisNoParam(
        date,
        setLoadingPage,
        handleErrorGetSensor,
        handleMappingGetSensorByParam,
        handleSetSearchParams,
        setCompanies,
        setStores,
        setIdCh,
        handleGetTemperature,
        getAllPurposes,
        form
      );
    }
  };

  return {
    state: {},
    handler: {
      getChCodeSensor,
      getStoresByCompany,

      handleMappingCompanyRoleStore,
      handleMappingStoreRoleStore,
      handleMappingPurposeRoleStore,
      fetchInitialApis,
    },
  };
}

export default useOpenCase3;
