import { ErrorBoundary } from "@components";
import { defaultTheme } from "@constants/theme";
import { ConfigProvider } from "antd";
import { createBrowserHistory } from "history";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./index.css";
import { store } from "./redux/store";

import jpJP from "antd/locale/ja_JP";

const persistor = persistStore(store);
const history = createBrowserHistory();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (root) {
  // 1. Set up the browser history with the updated location
  // (minus the # sign)
  const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }

  // 2. Render our app
  root.render(
    // <React.StrictMode>
    <ConfigProvider
      theme={{
        token: defaultTheme,
      }}
      locale={jpJP}
    >
      <ErrorBoundary>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    </ConfigProvider>
    // </React.StrictMode>
  );
}
