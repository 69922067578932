import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";

type TProps = {
  children: React.ReactNode;
  loading: boolean;
};

const LoadingCommon = ({ children, loading }: TProps) => {
  return (
    <Spin
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
      }}
      spinning={loading}
      indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
    >
      {children}
    </Spin>
  );
};

export { LoadingCommon };
