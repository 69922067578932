function IconPlusRegister() {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.72841 0H5.27105C5.17802 0 5.13151 0.0465116 5.13151 0.139535V5.41003H0.139535C0.0465116 5.41003 0 5.45655 0 5.54957L0 7.00693C0 7.09996 0.0465116 7.14647 0.139535 7.14647H5.13151V12.4186C5.13151 12.5116 5.17802 12.5581 5.27105 12.5581H6.72841C6.82143 12.5581 6.86794 12.5116 6.86794 12.4186V7.14647H11.8605C11.9535 7.14647 12 7.09996 12 7.00693V5.54957C12 5.45655 11.9535 5.41003 11.8605 5.41003H6.86794V0.139535C6.86794 0.0465116 6.82143 0 6.72841 0Z"
        fill="white"
      />
    </svg>
  );
}

export { IconPlusRegister };
