type TThermoIconProps = {
  color: string;
};

function ThermoIcon({ color }: TThermoIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3164_66638)">
        <path
          d="M14.0939 13.2274V3.14853C14.0939 1.40962 12.6843 0 10.9453 0C9.20644 0 7.79682 1.40962 7.79682 3.14853V13.2229C5.07542 14.9592 4.27676 18.5729 6.01304 21.2943C7.08454 22.9737 8.93757 23.9918 10.9297 23.9955L10.961 24C14.1892 23.994 16.8011 21.3721 16.7951 18.1441C16.7913 16.1519 15.7733 14.2989 14.0939 13.2274ZM8.48605 22.4442C6.11637 21.0761 5.30451 18.046 6.67262 15.6764C7.10747 14.9232 7.73287 14.2978 8.48605 13.8629C8.62507 13.7827 8.71044 13.6341 8.70982 13.4736V3.14853C8.70982 1.904 9.71872 0.895105 10.9632 0.895105C12.2078 0.895105 13.2167 1.904 13.2167 3.14853V13.4736C13.2161 13.6341 13.3014 13.7827 13.4405 13.8629C15.8101 15.231 16.622 18.2611 15.2539 20.6308C13.8858 23.0004 10.8557 23.8124 8.48605 22.4442Z"
          fill={color}
          stroke={color}
          strokeWidth="0.25"
        />
        <path
          d="M11.4086 14.9371V8.14775C11.4086 7.90059 11.2082 7.7002 10.961 7.7002C10.7139 7.7002 10.5135 7.90059 10.5135 8.14775V14.9371C8.82279 15.1796 7.64875 16.7467 7.89121 18.4374C8.11007 19.9634 9.41938 21.0952 10.961 21.091C12.669 21.0957 14.0574 19.7149 14.0622 18.0069C14.0664 16.4653 12.9346 15.156 11.4086 14.9371ZM10.961 20.1914C9.74738 20.1914 8.76355 19.2076 8.76355 17.9939C8.76601 16.7813 9.74839 15.7989 10.961 15.7964C12.1747 15.7964 13.1585 16.7803 13.1585 17.9939C13.1585 19.2076 12.1746 20.1914 10.961 20.1914Z"
          fill={color}
          stroke={color}
          strokeWidth="0.25"
        />
        <path
          d="M18.4575 3.07715H15.6603C15.4132 3.07715 15.2128 3.27754 15.2128 3.5247C15.2128 3.77186 15.4132 3.97225 15.6603 3.97225H18.4575C18.7047 3.97225 18.9051 3.77186 18.9051 3.5247C18.9051 3.27754 18.7047 3.07715 18.4575 3.07715Z"
          fill={color}
          stroke={color}
          strokeWidth="0.25"
        />
        <path
          d="M17.8623 5.31445H16.2198C15.9726 5.31445 15.7722 5.51484 15.7722 5.76201C15.7722 6.00917 15.9726 6.20956 16.2198 6.20956H17.8623C18.1094 6.20956 18.3098 6.00917 18.3098 5.76201C18.3098 5.51484 18.1094 5.31445 17.8623 5.31445Z"
          fill={color}
          stroke={color}
          strokeWidth="0.25"
        />
        <path
          d="M18.4575 7.55273H15.6603C15.4132 7.55273 15.2128 7.75313 15.2128 8.00029C15.2128 8.24745 15.4132 8.44784 15.6603 8.44784H18.4575C18.7047 8.44784 18.9051 8.24745 18.9051 8.00029C18.9051 7.75313 18.7047 7.55273 18.4575 7.55273Z"
          fill={color}
          stroke={color}
          strokeWidth="0.25"
        />
        <path
          d="M17.8623 9.79004H16.2198C15.9726 9.79004 15.7722 9.99043 15.7722 10.2376C15.7722 10.4848 15.9726 10.6851 16.2198 10.6851H17.8623C18.1094 10.6851 18.3098 10.4848 18.3098 10.2376C18.3098 9.99043 18.1094 9.79004 17.8623 9.79004Z"
          fill={color}
          stroke={color}
          strokeWidth="0.25"
        />
        <path
          d="M18.4575 12.0283H15.6603C15.4132 12.0283 15.2128 12.2287 15.2128 12.4759C15.2128 12.723 15.4132 12.9234 15.6603 12.9234H18.4575C18.7047 12.9234 18.9051 12.723 18.9051 12.4759C18.9051 12.2287 18.7047 12.0283 18.4575 12.0283Z"
          fill={color}
          stroke={color}
          strokeWidth="0.25"
        />
      </g>
      <defs>
        <clipPath id="clip0_3164_66638">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ThermoIcon;
