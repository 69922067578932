import styled from "styled-components";
import { Alert, AlertProps } from "antd";

export const AlertStyled = styled(Alert)<AlertProps & { isShow: boolean }>`
  font-size: var(--font-size-sm);
  width: fit-content;
  max-width: 283rem;
  display: block;
  opacity: ${(props) => (props.isShow ? 1 : 0)};
  margin: 11rem auto;
  border-color: var(--border-error);
  background-color: var(--background-error);

  .ant-alert-message {
    color: var(--color-text-error);
    text-align: center;
    white-space: pre-line;
  }
`;
