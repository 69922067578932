import { Modal } from "antd";
import styled from "styled-components";

export const AllDeviceWrapper = styled.div<{
  listdata: number;
}>`
  .all-device_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .all-device_header-title {
      display: flex;
      align-items: center;

      button {
        width: 48px;
        height: 48px;
        background-color: #edeff1;
        border-radius: 100%;
        border: none;
        position: relative;
        cursor: pointer;

        svg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      h1 {
        margin-left: 16px;
        line-height: 46px;
      }
    }

    .all-device_header-btn {
      button {
        width: 160px;
        height: 48px;
        border-radius: 7px;
        font-weight: var(--font-bold);
      }

      button:last-child {
        color: var(--color-primary);
        border-color: var(--color-primary);
      }
    }
  }

  .all-device_form {
    .ant-form-item {
      margin-bottom: 0;

      .ant-form-item-label {
        padding: 0 0 4px;

        label {
          font-weight: var(--font-normal);
        }
      }

      .ant-select-lg,
      .ant-input-lg {
        height: 56px;
      }
    }

    .ant-select-selection-placeholder {
      font-size: var(--font-size-sm);
      font-weight: var(--font-medium);
      color: var(--color-border-primary);
    }

    .all-device_form-top {
      margin-bottom: 12px;

      .ant-select-in-form-item {
        width: 290px;

        .ant-select-selector {
          padding: 16px;

          .ant-select-selection-item {
            font-size: var(--font-size-xl);
            font-weight: var(--font-medium);
            line-height: 24px;
          }
        }

        .ant-select-arrow {
          inset-inline-end: 16px;
        }
      }
    }

    .all-device_form-bottom {
      display: flex;
      align-items: center;
      column-gap: 8px;

      .ant-btn-default {
        width: 56px;
        height: 56px;
        background: #d7e7ff;
        border: none;
        margin-top: 28px;
      }

      .ant-input-lg {
        padding: 16px;
        font-size: var(--font-size-base);
        font-weight: var(--font-medium);
      }

      input::placeholder {
        font-size: var(--font-size-sm);
        font-weight: var(--font-medium);
        color: var(--color-border-primary);
      }

      .all-device_form-area {
        .ant-select-in-form-item {
          width: 94px;

          .ant-select-selector {
            padding: 16px;

            .ant-select-selection-item {
              font-size: var(--font-size-xl);
              font-weight: var(--font-medium);
              line-height: 24px;
            }
          }

          .ant-select-arrow {
            inset-inline-end: 16px;
          }
        }
      }

      .all-device_form-purpose,
      .all-device_form-install {
        .ant-select-selector {
          padding: 16px;

          .ant-select-selection-item {
            font-size: var(--font-size-xl);
            font-weight: var(--font-medium);
            line-height: 24px;
          }
        }

        .ant-select-arrow {
          inset-inline-end: 16px;
        }
      }

      .all-device_form-store-code,
      .all-device_form-manufacturer,
      .all-device_form-purpose {
        width: 300px;
      }

      .all-device_form-store-name,
      .all-device_form-install {
        width: 251px;
      }

      .all-device_form-object {
        width: 200px;
        height: 56px;
        padding: 16px 12px;
        border: 1px solid var(--color-border-primary);
        background-color: var(--color-border-secondary);
        border-radius: 8px;
        font-size: var(--font-size-xl);
        font-weight: var(--font-medium);
        line-height: 24px;
        margin-top: 28px;
      }
    }
  }

  .all-device_table {
    margin-top: 24px;

    .ant-table-container {
      border-end-end-radius: 8px;
      border-end-start-radius: 8px;
      border-start-end-radius: 0px;
      border-top: none !important;

      .ant-table-tbody-virtual-scrollbar-vertical {
        background: var(--color-secondary);
        border-radius: 4px;
      }

      .ant-table-tbody-virtual-scrollbar {
        /* visibility: visible !important; */
        right: -8px !important;

        .ant-table-tbody-virtual-scrollbar-thumb {
          background: var(--color-text-secondary) !important;
        }
      }

      .ant-table-thead {
        .ant-table-cell {
          padding: 16px 12px;
        }
        .ant-table-column-sort {
          background: var(--background-header-table);
        }

        tr:first-child .ant-table-cell:nth-child(10) {
          border-start-end-radius: 8px;
        }

        tr:first-child .ant-table-cell {
          border-top: 1px solid var(--color-border-primary);
        }

        tr:first-child .ant-table-cell,
        tr:last-child .ant-table-cell {
          border-bottom: 4px solid var(--color-border-primary) !important;
        }

        tr:first-child .ant-table-cell:last-child {
          border-start-end-radius: 8px;
        }

        tr:first-child .ant-table-cell:nth-child(8),
        tr:first-child .ant-table-cell:nth-child(9) {
          border-bottom: 1px solid var(--color-border-primary) !important;
        }

        .ant-table-cell-scrollbar {
          background-color: var(--color-white);
          border-inline-end: none !important;
        }
      }

      .ant-table-tbody {
        height: ${(props) => (props.listdata > 0 ? "unset" : "590px")};

        .ant-table-tbody-virtual-holder {
          max-height: 580px !important;
        }

        .ant-table-row:last-child {
          .ant-table-cell:first-child {
            border-end-start-radius: 8px;
          }
          .ant-table-cell:last-child {
            border-end-end-radius: 8px;
          }
        }

        .ant-table-cell {
          font-weight: var(--font-medium);
          background-color: var(--color-white);
          padding: 14px 12px;

          .all-device_battery-high,
          .all-device_battery-low {
            display: inline-block;
            line-height: 24px;
            font-size: var(--font-size-sm);
            border-radius: 16px;
            width: 60px;
          }

          .all-device_battery-high {
            color: #12b76a;
            background-color: #f1fff3;
          }

          .all-device_battery-low {
            color: #d92d20;
            background-color: #fee4e2;
          }

          .all-device_ellipsis {
            display: -webkit-box;
            -webkit-line-clamp: 1; /* Number of lines */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
          }

          .all-device_link {
            color: var(--color-link);
            cursor: pointer;
          }
        }

        .ant-table-placeholder {
          .ant-table-cell {
            background-color: #f9fafb !important;
            border-end-end-radius: 8px;
            border-end-start-radius: 8px;

            .ant-empty-image {
              height: 200px !important;
            }
          }
        }
      }
    }
  }
`;

export const ModalDownloadWrapper = styled(Modal)`
  .ant-modal-content {
    padding: 0;
    background: var(--color-white);
    box-shadow: 0px 2px 8px 0px rgba(5, 40, 97, 0.08);
    border-radius: 12px;
  }

  .ant-modal-header {
    margin-bottom: 0;
    padding: 16px 24px;
    background: none;

    .ant-modal-title {
      font-weight: var(--font-bold);
      font-size: var(--font-size-base);
      line-height: 24px;
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    column-gap: 16px;
    padding: 12px 24px 24px;
    margin-top: 0;

    .ant-btn {
      width: 202px;
      height: 48px;
      box-shadow: none;
      font-weight: var(--font-bold);
      margin-inline-start: 0;
    }

    .ant-btn-default {
      color: var(--color-primary);
      border-color: var(--color-primary);
    }
  }

  .ant-modal-body {
    padding: 0 24px;
    border-bottom: 1px solid #f1f3f7;
    border-top: 1px solid #f1f3f7;
    text-align: center;

    .all-device_modal-download {
      margin: 30px 0 24px;
      font-weight: var(--font-medium);
    }
  }
`;
