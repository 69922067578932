import { TableProps } from "antd/lib";
import { Button, Tooltip } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  FolderViewOutlined,
} from "@ant-design/icons";
import { PURPOSE_CONSTANT } from "./constant";
import { TListPurpose } from "@models/purpose";
import { COLOR_TOOLTIP } from "@constants/common";

const { TITLE, TABLE_KEY } = PURPOSE_CONSTANT;

const renderCodeName = (code: string, name: string) => {
  return code + `${name ? `_${name}` : ""}`;
};

const renderHeaderColumns = (text1: string, text2: string) => {
  return (
    <>
      <p>{text1}</p>
      <span>{text2}</span>
    </>
  );
};

export const getColumns = ({
  disableBtnOther,
  handleOpenModalDelete,
  handleOpenModalDetail,
  handleOpenModalUpdate,
}: {
  disableBtnOther: boolean;
  handleOpenModalDelete: (record: any) => void;
  handleOpenModalDetail: (record: any) => void;
  handleOpenModalUpdate: (record: any, id: string) => void;
}): TableProps<TListPurpose>["columns"] => {
  return [
    {
      title: TITLE.NO,
      key: TABLE_KEY.NO,
      dataIndex: TABLE_KEY.NO,
      align: "center",
      width: 75,
    },
    {
      title: renderHeaderColumns("会社・", "事業部コード"),
      key: TABLE_KEY.COMPANY,
      dataIndex: TABLE_KEY.COMPANY,
      align: "center",
      width: 250,
      render: (value, record) => {
        if (record) {
          return renderCodeName(value, record.companyName);
        }
        return null;
      },
    },
    {
      title: TITLE.PURPOSE_CODE,
      key: TABLE_KEY.PURPOSE_CODE,
      dataIndex: TABLE_KEY.PURPOSE_CODE,
      align: "center",
      width: 200,
    },
    {
      title: TITLE.PURPOSE_NAME,
      key: TABLE_KEY.PURPOSE_NAME,
      dataIndex: TABLE_KEY.PURPOSE_NAME,
      align: "center",
      width: 280,
      render: (value) => {
        return (
          <div className="list-page_ellipsis">
            <Tooltip
              color="white"
              overlayClassName="common_custom-tooltip"
              title={value}
              overlayInnerStyle={{
                color: COLOR_TOOLTIP,
              }}
            >
              {value}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: TITLE.DEPARTMENT,
      key: TABLE_KEY.DEPARTMENT,
      dataIndex: TABLE_KEY.DEPARTMENT,
      align: "center",
      width: 262,
      render: (value, record) => {
        if (record) {
          return (
            <div className="list-page_ellipsis">
              <Tooltip
                color="white"
                overlayClassName="common_custom-tooltip"
                title={renderCodeName(value, record.departmentName)}
                overlayInnerStyle={{
                  color: COLOR_TOOLTIP,
                }}
              >
                {renderCodeName(value, record.departmentName)}
              </Tooltip>
            </div>
          );
        }
        return null;
      },
    },
    {
      title: TITLE.MIN_TEMP,
      key: TABLE_KEY.MIN_TEMP,
      dataIndex: TABLE_KEY.MIN_TEMP,
      align: "center",
      width: 262,
    },
    {
      title: TITLE.MAX_TEMP,
      key: TABLE_KEY.MAX_TEMP,
      dataIndex: TABLE_KEY.MAX_TEMP,
      align: "center",
      width: 262,
    },
    {
      title: TITLE.ACTION,
      align: "center",
      width: 196,
      render(_, record) {
        return (
          <div className="list-page_modal-btns">
            <Button
              onClick={() => {
                handleOpenModalDetail(record);
              }}
            >
              <FolderViewOutlined
                style={{
                  fontSize: "21px",
                  color: "#344054",
                  cursor: "pointer",
                }}
              />
            </Button>
            <Button
              disabled={disableBtnOther}
              onClick={() => {
                handleOpenModalUpdate(record, record.id);
              }}
            >
              <EditOutlined
                style={{
                  fontSize: "21px",
                  color: `${disableBtnOther ? "#99a6bb" : "#344054"}`,
                  cursor: "pointer",
                }}
              />
            </Button>
            <Button
              disabled={disableBtnOther}
              onClick={() => {
                handleOpenModalDelete(record);
              }}
            >
              <DeleteOutlined
                style={{
                  fontSize: "21px",
                  color: `${disableBtnOther ? "#99a6bb" : "#F04438"}`,
                  cursor: "pointer",
                }}
              />
            </Button>
          </div>
        );
      },
    },
  ];
};
