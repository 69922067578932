export const COMPANY_CONSTANT = {
  TITLE: {
    COMPANY_LIST: "会社一覧",
    BTN_REGISTRATION: "新規登録",

    // Columns
    NO: "NO",
    COMPANY_CODE: "会社コード",
    COMPANY_NAME: "会社名",
    ACTION: "アクション",

    // Modal
    TITLE_DELETE: "削除",
    BTN_DELETE: "削除 ",
    BTN_CANCEL: "キャンセル",
    COMPANY_REGISTRATION: "会社登録",
    COMPANY_UPDATE: "会社変更 ",
    OK_UPDATE: "変更 ",
    COMPANY_DETAIL: "会社照会",
    BTN_MODAL_REGIST: "登録 ",
    BTN_CLOSE: "閉じる",
    COMPANY_LIST_REGISTRATION: "新規登録",
    BTN_OK: "OK",
    COMPANY_LIST_UPDATE: "変更成功",
    BTN_NEXT: "次へ",
    CONFIRM_UPDATE_FINAL: "用途成功",
    CONFIRM_REGISTRATION_FINAL: "用途成功",

    FAIL_REGISTER: "用途成功",
    SUCCESS_DELETE: "用途成功",
    FAIL_DELETE: "用途成功",

    NOT_FOUND_COMPANY: "not_found_company",
  },
  LABEL: {
    COMPANY: "会社",
    COMPANY_CODE: "会社コード",
    COMPANY_NAME: "会社名",
  },
  NAME: {
    COMPANY: "companyCode",

    // Modal
    COMPANY_CODE: "companyCode",
    COMPANY_NAME: "companyName",
  },
  TABLE_KEY: {
    NO: "key",
    COMPANY_CODE: "companyCode",
    COMPANY_NAME: "companyName",
  },
  MESSAGE: {
    NO_DATA: "表示するデータがありません。",
    SUCCESS_DELETE: "会社が削除に成功された。",
    SUCCESS_REGISTER: "会社が登録に成功された。",
    SUCCESS_UPDATE: "会社が変更に成功された。",

    FAIL_DELETE: "会社が削除に失敗された。",
    FAIL_REGISTER: "会社が登録に失敗された。",
    FAIL_UPDATE: "会社が変更に失敗された。",

    CONFIRM_DELETE: "この会社を削除します。よろしいですか？",
    CONFIRM_UPDATE_FINAL: "この会社を変更します。よろしいですか？",
    CONFIRM_REGISTRATION_FINAL: "この会社を登録します。よろしいですか？",

    NOT_FOUND_COMPANY:
      "他の人がデータを更新したため、更新できません。 データベースからデータをリロードしてください。",
    DATA_EXIST: "値はすでに存在しています。",
    FIELD_INVALID_FORMAT: "値の形式は正しくありません。",
    FIELD_INVALID: "値は無効です。",
  },
};

export const HEIGHT_TABLE_SCROLL = 685;
export const MAX_LENGTH_5 = 5;
export const MAX_LENGTH_50 = 50;
export const REGEX_COMPANY_CODE = /^[a-zA-Z0-9]{3,5}$/;
