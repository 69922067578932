import { TCompanyReq } from "@models/layout";
import { layoutApi } from "@services/layoutApi";
import { STORE_CONSTANT } from "../constant";
import { handleShowToastError } from "@utils/helpers";
import { TSelectValue } from "@models/common";
import {
  TCheckStoreExistReq,
  TFormModalRegisterReq,
  TFormModalRes,
  TFormModalUpdateReq,
  TFormStoreReq,
  TListStoreReq,
  TListStoreTable,
} from "@models/store";
import { storeApi } from "@services/storeApi";
import _ from "lodash";
import { FormInstance } from "antd/lib";
import { COMMON_MESSAGE_ERROR, OPTION_EMPTY } from "@constants/common";
import { EModalType } from "types/others";
import { EHttpStatusCode } from "types/service";
import { allStoreApi } from "@services/allStoreApi";
import { TAxiosResponse } from "types";
import { appToast } from "@utils/appToast";

const { NAME, MESSAGE } = STORE_CONSTANT;

function useStoreApi() {
  // API
  const getStoresByArea = async (
    payload: string,
    setStores: (val: TSelectValue[]) => void,
    setLoadingStore: (val: boolean) => void
  ) => {
    setLoadingStore(true);

    try {
      const response = await storeApi.getStoresByArea(payload);

      if (response.ok) {
        const convertStores = response.data.map((val) => ({
          value: val.storeCode,
          label: val.storeCode + "_" + val.storeName,
        }));
        setStores([OPTION_EMPTY, ...convertStores]);
      }
    } catch (error) {
      setStores([]);
      handleShowToastError(error);
    } finally {
      setLoadingStore(false);
    }
  };

  const getAllStoreConditions = async (
    payload: TCompanyReq,
    setAreas: (val: TSelectValue[]) => void,
    setLoadingArea: (val: boolean) => void,
    getStoresByArea: (payload: string) => void,
    setAreasModal: (val: TSelectValue[]) => void,
    setLoadingAreaModal: (val: boolean) => void,
    modalType: EModalType | null | string
  ) => {
    if (modalType) {
      setLoadingAreaModal(true);
    } else {
      setLoadingArea(true);
    }

    try {
      const response = await allStoreApi.getAllStoreConditions(payload);

      if (response.ok && response.data.areas.length) {
        const convertAreas = response.data.areas.map((val) => ({
          value: val.areaCode,
          label: val.areaCode + "_" + val.areaName,
          children: val.id,
        }));

        if (modalType) {
          setAreasModal(
            convertAreas.map((item) => ({
              ...item,
              value: item.children,
              children: item.value,
            }))
          );
        } else {
          setAreas([OPTION_EMPTY, ...convertAreas]);
          getStoresByArea(convertAreas[0].children);
        }
      }
    } catch (error) {
      if (modalType) {
        setAreasModal([]);
      } else {
        setAreas([]);
      }
      handleShowToastError(error);
    } finally {
      if (modalType) {
        setLoadingAreaModal(false);
      } else {
        setLoadingArea(false);
      }
    }
  };

  const registrationStore = async (
    params: TFormModalRegisterReq,
    handleOkModalSuccess: (val: TFormModalRes) => void,
    setLoadingPage: (val: boolean) => void,
    handleModalFail: () => void,
    handleCommonError: () => void
  ) => {
    setLoadingPage(true);

    try {
      const response = await storeApi.registrationStore(params);
      if (response.ok) {
        handleOkModalSuccess(response.data);
      }
    } catch (error: any) {
      handleCommonError();
      if (
        error?.status === EHttpStatusCode.BAD_REQUEST &&
        error?.data?.errors?.[0]?.description
      ) {
        handleModalFail();
        return;
      }
      const err = error as TAxiosResponse;
      if (err.status === EHttpStatusCode.FORBIDDEN) {
        appToast.error({ message: COMMON_MESSAGE_ERROR });
        return;
      }
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const updateStore = async (
    params: TFormModalUpdateReq,
    handleOkModalSuccess: (val: TFormModalRes) => void,
    setLoadingPage: (val: boolean) => void,
    handleModalFail: () => void,
    handleCommonError: () => void
  ) => {
    setLoadingPage(true);

    try {
      const response = await storeApi.updateStore(params);
      if (response.ok) {
        handleOkModalSuccess(response.data);
      }
    } catch (error: any) {
      handleCommonError();
      if (
        error?.status === EHttpStatusCode.BAD_REQUEST &&
        error?.data?.errors?.[0]?.description
      ) {
        handleModalFail();
        return;
      }
      const err = error as TAxiosResponse;
      if (err.status === EHttpStatusCode.FORBIDDEN) {
        appToast.error({ message: COMMON_MESSAGE_ERROR });
        return;
      }
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const detailStore = async (
    payload: string,
    companiesModal: TSelectValue[],
    setAreasModal: (val: TSelectValue[]) => void,
    handleSetFormValues: (val: TFormModalRes) => void,
    setLoadingModal: (val: boolean) => void
  ) => {
    setLoadingModal(true);
    try {
      const response = await storeApi.getDetailStore(payload);

      if (response.ok) {
        const { data } = response;
        const findCompany = companiesModal.find(
          (item) => item.value === data.companyId
        );
        if (findCompany) {
          const responseArea = await allStoreApi.getAllStoreConditions({
            companyCode: findCompany.children as string,
            companyName: findCompany.label.split("_")[1],
          });
          if (responseArea.ok && responseArea.data.areas.length) {
            setAreasModal(
              responseArea.data.areas.map((item) => ({
                ...item,
                label: item.areaCode + "_" + item.areaName,
                value: item.id,
                children: item.areaCode,
              }))
            );
          } else {
            setAreasModal([]);
          }
        } else {
          setAreasModal([]);
        }

        handleSetFormValues({ ...data, id: payload });
      }
    } catch (error) {
      handleShowToastError(error);
      handleSetFormValues({
        areaId: undefined,
        closingDate: undefined,
        companyId: undefined,
        email: "",
        id: "",
        kiwiId: "",
        landArea: undefined,
        okamuraId: "",
        openingDate: undefined,
        panasonicId: "",
        storeCode: "",
        storeManagerName: "",
        storeName: "",
        storePhone: "",
      });
    } finally {
      setLoadingModal(false);
    }
  };

  const deleteStore = async (
    payload: string,
    listStore: TListStoreTable[],
    setListStore: (val: TListStoreTable[]) => void,
    setLoadingPage: (val: boolean) => void,
    setModalSuccess: (val: boolean) => void,
    setModalType: (val: EModalType | null | string) => void,
    handleCloseModalDelete: () => void,
    handleDeleteFail: () => void
  ) => {
    setLoadingPage(true);

    try {
      const response = await storeApi.deleteStore(payload);
      if (response.ok) {
        setListStore(
          [
            ..._.filter(listStore, function (item) {
              return item.id !== payload;
            }),
          ]
            .filter((item) => !item.isDeleted)
            .map((item, index) => ({ ...item, key: index }))
        );

        setModalSuccess(true);
        setModalType(EModalType.SUCCESS_DELETE);
        handleCloseModalDelete();
      }
    } catch (error: any) {
      if (
        error?.status === EHttpStatusCode.BAD_REQUEST &&
        error?.data?.errors?.[0]?.description === "Not found store"
      ) {
        handleDeleteFail();
        return;
      }
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const checkExistStore = async (
    payload: TCheckStoreExistReq,
    formModal: FormInstance,
    setModalType: (val: EModalType | null | string) => void,
    setLoadingPage: (val: boolean) => void
  ) => {
    setLoadingPage(true);
    try {
      const response = await storeApi.checkStoreExist(payload);
      if (response.ok) {
        setModalType(EModalType.CONFIRM_REGISTRATION);
      }
    } catch (error: any) {
      if (
        error.status === EHttpStatusCode.BAD_REQUEST &&
        error?.data?.errors.length
      ) {
        const result = _.uniq(
          error.data.errors.map((item: any) => item.columnName?.toString())
        );
        for (let i = 0; i < result.length; i++) {
          switch (result[i]) {
            case "店舗ID（KIWI）":
              formModal.setFields([
                { name: [NAME.KIWI_ID], errors: [MESSAGE.DATA_EXIST] },
              ]);
              break;
            case "店舗ID（パナソニック）":
              formModal.setFields([
                { name: [NAME.PANASONIC_ID], errors: [MESSAGE.DATA_EXIST] },
              ]);
              break;
            case "店舗ID（OKAMURA）":
              formModal.setFields([
                { name: [NAME.OKAMURA_ID], errors: [MESSAGE.DATA_EXIST] },
              ]);
              break;
            default:
              formModal.setFields([
                { name: [NAME.STORE_CODE], errors: [MESSAGE.DATA_EXIST] },
              ]);
              break;
          }
        }
        return;
      }

      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const getListStoreSearch = async (
    payload: TListStoreReq,
    setLoadingPage: (val: boolean) => void,
    setListStore: (val: TListStoreTable[]) => void,
    handleSetSearchParams: (val: TFormStoreReq) => void,
    setPageIndex: (val: number) => void,
    setHasNext: (val: boolean) => void
  ) => {
    setLoadingPage(true);

    try {
      const response = await storeApi.getListStore(payload);
      if (response.ok) {
        setListStore(
          response.data
            .filter((item) => !item.isDeleted)
            .map((item, index) => ({
              ...item,
              key: index,
            }))
        );
        if (payload.companyCode) {
          handleSetSearchParams(payload);
        } else {
          handleSetSearchParams({ ...payload, areaId: "" });
        }
        setPageIndex(1);
        setHasNext(response.pagination?.hasNext as boolean);
      }
    } catch (error) {
      setListStore([]);
      setPageIndex(0);
      setHasNext(false);
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  // TODO: Recheck
  const getListStoreScroll = async (
    payload: TListStoreReq,
    listStore: TListStoreTable[],
    pageIndex: number,
    // loadedIds: Set<string>,
    setLoadingPage: (val: boolean) => void,
    setListStore: (val: any) => void,
    // setLoadedIds: (val: any) => void,
    setPageIndex: (val: number) => void,
    setHasNext: (val: boolean) => void
  ) => {
    setLoadingPage(true);
    try {
      const response = await storeApi.getListStore(payload);

      if (response.ok) {
        setHasNext(response.pagination?.hasNext as boolean);

        if (listStore.length !== response.pagination?.totalCount) {
          const startIndex = listStore.length;
          // const filteredRecords = response.data
          //   .filter((record) => !loadedIds.has(record.id) && !record.isDeleted)
          //   .map((item, index) => ({
          //     ...item,
          //     key: startIndex + index + 1,
          //   }));
          // setListStore((prevData: TListStoreRes[]) => [
          //   ...prevData,
          //   ...filteredRecords,
          // ]);
          // setLoadedIds(
          //   (prevIds: any) =>
          //     new Set([
          //       ...prevIds,
          //       ...filteredRecords.map((record) => record.id),
          //     ])
          // );
          setListStore([
            ...listStore,
            ...response.data.map((item, index) => ({
              ...item,
              key: startIndex + index + 1,
            })),
          ]);

          setPageIndex(pageIndex + 1);
        }
      }
    } catch (error) {
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const fetchInitialApis = async (
    pageIndex: number,
    setLoadingPage: (val: boolean) => void,
    setLoadingCompany: (val: boolean) => void,
    handleSetSearchParams: (val: TFormStoreReq) => void,
    setCompanies: (val: TSelectValue[]) => void,
    setAreas: (val: TSelectValue[]) => void,
    setStores: (val: TSelectValue[]) => void,
    setListStore: (val: TListStoreTable[]) => void,
    setCompaniesModal: (val: TSelectValue[]) => void,
    setAreasModal: (val: TSelectValue[]) => void,
    setHasNext: (val: boolean) => void,
    setPageIndex: (val: number) => void
  ) => {
    setLoadingPage(true);

    try {
      const resCompany = await layoutApi.getCompanies();
      if (resCompany.ok) {
        const convertCompanies = resCompany.data.map((val) => ({
          value: val.companyCode,
          label: val.companyCode + "_" + val.companyName,
          children: val.id,
        }));
        setCompanies([OPTION_EMPTY, ...convertCompanies]);
        setCompaniesModal(
          convertCompanies.map((item) => ({
            ...item,
            value: item.children,
            children: item.value,
          }))
        );

        const resOther = await allStoreApi.getAllStoreConditions({
          companyCode: convertCompanies[0].value,
          companyName: convertCompanies[0].label.split("_")[1],
        });

        if (resOther.ok && resOther.data.areas.length) {
          const convertAreas = resOther.data.areas.map((val) => ({
            value: val.areaCode,
            label: val.areaCode + "_" + val.areaName,
            children: val.id,
          }));
          setAreas([OPTION_EMPTY, ...convertAreas]);
          setAreasModal(
            convertAreas.map((item) => ({
              ...item,
              value: item.children,
              children: item.value,
            }))
          );
        }

        const resStore = await storeApi.getStoresByArea(
          resOther.data.areas[0].id
        );

        if (resStore.ok && resStore.data.length) {
          const convertStores = resStore.data.map((val) => ({
            value: val.storeCode,
            label: val.storeCode + "_" + val.storeName,
          }));
          setStores([OPTION_EMPTY, ...convertStores]);
        }

        handleSetSearchParams({
          areaCode: resOther.data.areas[0].areaCode,
          companyCode: resCompany.data[0].companyCode,
          storeCode: resStore.data[0].storeCode,
          areaId: resOther.data.areas[0].id,
        });

        const response = await storeApi.getListStore({
          areaCode: resOther.data.areas[0].areaCode,
          companyCode: resCompany.data[0].companyCode,
          storeCode: resStore.data[0].storeCode,
          areaId: resOther.data.areas[0].id,

          pageIndex,
        });
        if (response.ok) {
          const convertListStore: TListStoreTable[] = response.data
            .filter((item) => !item.isDeleted)
            .map((item, index) => ({
              ...item,
              key: index,
            }));
          setListStore(convertListStore);
          setPageIndex(1);
          setHasNext(response.pagination?.hasNext as boolean);
        }
      }
    } catch (error) {
      setListStore([]);
      setPageIndex(0);
      setHasNext(false);

      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
      setLoadingCompany(false);
    }
  };

  const fetchApiRedirect = async (
    payload: TFormStoreReq,
    pageIndex: number,
    setLoadingPage: (val: boolean) => void,
    setLoadingCompany: (val: boolean) => void,
    handleSetSearchParams: (val: TFormStoreReq) => void,
    setCompanies: (val: TSelectValue[]) => void,
    setAreas: (val: TSelectValue[]) => void,
    setStores: (val: TSelectValue[]) => void,
    setListStore: (val: TListStoreTable[]) => void,
    setCompaniesModal: (val: TSelectValue[]) => void,
    setAreasModal: (val: TSelectValue[]) => void,
    setHasNext: (val: boolean) => void,
    setPageIndex: (val: number) => void
  ) => {
    setLoadingPage(true);

    try {
      const resCompany = await layoutApi.getCompanies();
      if (resCompany.ok) {
        const convertCompanies = resCompany.data.map((val) => ({
          value: val.companyCode,
          label: val.companyCode + "_" + val.companyName,
          children: val.id,
        }));
        setCompanies([OPTION_EMPTY, ...convertCompanies]);
        setCompaniesModal(
          convertCompanies.map((item) => ({
            ...item,
            value: item.children,
            children: item.value,
          }))
        );

        const findCompany = _.find(convertCompanies, function (item) {
          return item.value === payload.companyCode;
        });

        const resOther = await allStoreApi.getAllStoreConditions({
          companyCode: findCompany
            ? findCompany.value
            : convertCompanies[0].value,
          companyName: findCompany
            ? findCompany.label.split("_")[1]
            : convertCompanies[0].label.split("_")[1],
        });

        if (resOther.ok && resOther.data.areas.length) {
          const convertAreas = resOther.data.areas.map((val) => ({
            value: val.areaCode,
            label: val.areaCode + "_" + val.areaName,
            children: val.id,
          }));
          setAreas([OPTION_EMPTY, ...convertAreas]);
          setAreasModal(
            convertAreas.map((item) => ({
              ...item,
              value: item.children,
              children: item.value,
            }))
          );
        }

        const resStore = await storeApi.getStoresByArea(
          resOther.data.areas[0].id || payload.areaId
        );

        if (resStore.ok && resStore.data.length) {
          const convertStores = resStore.data.map((val) => ({
            value: val.storeCode,
            label: val.storeCode + "_" + val.storeName,
          }));
          setStores([OPTION_EMPTY, ...convertStores]);
        }
      }

      handleSetSearchParams({
        areaCode: payload.areaCode,
        companyCode: payload.companyCode,
        storeCode: payload.storeCode,
        areaId: payload.areaId,
      });

      const response = await storeApi.getListStore({ ...payload, pageIndex });
      if (response.ok) {
        const convertListStore: TListStoreTable[] = response.data
          .filter((item) => !item.isDeleted)
          .map((item, index) => ({
            ...item,
            key: index,
          }));
        setListStore(convertListStore);
        setPageIndex(1);
        setHasNext(response.pagination?.hasNext as boolean);
      }
    } catch (error) {
      setListStore([]);
      setPageIndex(0);
      setHasNext(false);

      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
      setLoadingCompany(false);
    }
  };

  // EFFECT
  const effectCompany = (
    companyParam: string,
    form: FormInstance,
    companies: TSelectValue[]
  ) => {
    if (companyParam) {
      form.setFieldValue(NAME.COMPANY, companyParam);
    } else {
      if (companies.length) {
        form.setFieldValue(NAME.COMPANY, companies[1].value);
      } else {
        form.setFieldValue(NAME.COMPANY, "");
      }
    }
  };

  const effectStore = (storeParam: string, form: FormInstance) => {
    if (storeParam) {
      form.setFieldValue(NAME.STORE, storeParam);
    }
  };

  const effectArea = (
    areaParam: string,
    form: FormInstance,
    areas: TSelectValue[]
  ) => {
    if (areaParam) {
      form.setFieldValue(NAME.AREA, areaParam);
    } else {
      if (areas.length > 1) {
        form.setFieldValue(NAME.AREA, areas[1].value);
      } else {
        form.setFieldValue(NAME.AREA, undefined);
      }
    }
  };

  return {
    handler: {
      getStoresByArea,
      getAllStoreConditions,
      getListStoreSearch,
      getListStoreScroll,
      fetchInitialApis,
      fetchApiRedirect,

      registrationStore,
      updateStore,
      deleteStore,
      detailStore,
      checkExistStore,
    },
    effect: {
      effectCompany,
      effectStore,
      effectArea,
    },
  };
}

export default useStoreApi;
