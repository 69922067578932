export const OPEN_CASE_CONSTANT = {
  MESSAGE: {
    NO_DATA: "表示するデータがありません。",
    ALARM_SETTING: "警報設定を確認します。よろしいですか？",
    NOT_FOUND: "Not found sensor",
  },
  LABEL: {
    COMPANY: "会社",
    STORE: "店舗",
    MACHINE: "機器",
    ID_CH: "ID, 管理CH",
    DEPARTMENT_NAME: "部門名",
    MANUFACTURER_MODEL: "メーカ形式",
    MANUFACTURER_NAME: "メーカ名",
    TEMPERATURE_DISTINCTION: "温度区分",
    INSTALL_LOCATION: "設置場所",
    OPERATING_HOUR_FROM: "稼働時間",
    CATEGORY: "カテゴリ",
    INDIVIDUAL: "個別",
    PURPOSE: "用途（売場名）",
    BATTERY_LEVEL: "電池残量",
  },
  NAME: {
    REPORT_DATE: "reportDate",
    COMPANY: "companyCode",
    STORE: "storeCode",
    MACHINE: "machine",
    ID_CH: "chCode",
    DEPARTMENT_NAME: "departmentName",
    MANUFACTURER_NAME: "manufacturerName",
    MANUFACTURER_MODEL: "manufacturerModel",
    TEMPERATURE_DISTINCTION: "temperatureType",
    INSTALL_LOCATION: "installedLocation",
    OPERATING_HOUR_FROM: "timeStart",
    CATEGORY: "category",
    INDIVIDUAL: "individual",
    SELECT: "select",
    LOWER_INPUT: "lowerInput",
    UPPER_INPUT: "upperInput",
    LOWER_NUMBER: "lowerNumber",
    UPPER_NUMBER: "upperNumber",
    STORE_NAME: "storeName",
    BATTERY_LEVEL: "batteryValue",
    SETTING: "reasonSetting",
    PURPOSE: "purposeName",
  },
  PLACEHOLDER: {
    FROM_HOUR: "何時から",
    TO_HOUR: "何時まで",
    COMMENT: "コメントを入力してください (最大200文字)",
  },
  TITLE: {
    N_A: "N/A",
    INFORMATION_INQUIRY: "ケース情報照会",
    BTN_EXPORT: "エクスポート",
    BTN_SET: "設定する",
    BTN_NOT_SET: "設定しない",
    APPLICABLE_DATE: "適用日",

    NONE: "なし",
    REFRIGERATED: "冷蔵",
    FROZEN: "冷凍",
    UNREFRIGERATED: "非冷",
    NOT_USED: "使用しない",
    SALE_FLOOR: "売場",
    BACKYARD: "バックヤード",

    // Table
    DEFROST_INFORMATION: "除霜情報",
    SPRING: "春",
    SUMMER: "夏",
    AUTUMN: "秋",
    WINTER: "冬",
    START_DATE: "開始日",
    START_DATE_1: "開始時間 (1)",
    START_DATE_2: "開始時間 (2)",
    START_DATE_3: "開始時間 (3)",
    START_DATE_4: "開始時間 (4)",
    START_DATE_5: "開始時間 (5)",
    START_DATE_6: "開始時間 (6)",

    ALARM_SETTINGS: "警報設定",
    CATEGORY: "カテゴリ",
    INDIVIDUAL: "個別",
    PRESET_TEMPERATURE: "設定温度",
    MASK_REASON: "理由",
    BY_DAY: "日別",
    BTN_SETTING: "設定 ",

    TIME: "時間",
    HOUR_0: "0",
    HOUR_1: "1",
    HOUR_2: "2",
    HOUR_3: "3",
    HOUR_4: "4",
    HOUR_5: "5",
    HOUR_6: "6",
    HOUR_7: "7",
    HOUR_8: "8",
    HOUR_9: "9",
    HOUR_10: "10",
    HOUR_11: "11",
    HOUR_12: "12",
    HOUR_13: "13",
    HOUR_14: "14",
    HOUR_15: "15",
    HOUR_16: "16",
    HOUR_17: "17",
    HOUR_18: "18",
    HOUR_19: "19",
    HOUR_20: "20",
    HOUR_21: "21",
    HOUR_22: "22",
    HOUR_23: "23",

    DAY_21: 21,
    DAY_20: 20,
    DAY_19: 19,
    DAY_18: 18,
    DAY_17: 17,
    DAY_16: 16,
    DAY_15: 15,
    DAY_14: 14,
    DAY_13: 13,
    DAY_12: 12,
    DAY_11: 11,
    DAY_10: 10,
    DAY_9: 9,
    DAY_8: 8,
    DAY_7: 7,
    DAY_6: 6,
    DAY_5: 5,
    DAY_4: 4,
    DAY_3: 3,
    DAY_2: 2,

    // Chart
    TEMPERATURE_CHART: "過去の管理温度外の出現回数",
    DAY_CHART: "日",
    DAY_AGO: "日前",
    LOWER_LIMIT: "下限",
    UPPER_LIMIT: "上限",
    TEMPERATURE: "温度",
    DEFROST: "霜取り",
    OUT_TIME: "稼働時間外",
  },
  TABLE_KEY: {
    WEATHER: "weather",
    START_DATE: "startDate",
    START_DATE_1: "startDate1",
    START_DATE_2: "startDate2",
    START_DATE_3: "startDate3",
    START_DATE_4: "startDate4",
    START_DATE_5: "startDate5",
    START_DATE_6: "startDate6",

    INDIVIDUAL: "individual",
    PRESET_TEMPERATURE: "presetTemperature",
    MASK_REASON: "maskReason",

    TIME: "time",
    HOUR_0: "hour_0",
    HOUR_1: "hour_1",
    HOUR_2: "hour_2",
    HOUR_3: "hour_3",
    HOUR_4: "hour_4",
    HOUR_5: "hour_5",
    HOUR_6: "hour_6",
    HOUR_7: "hour_7",
    HOUR_8: "hour_8",
    HOUR_9: "hour_9",
    HOUR_10: "hour_10",
    HOUR_11: "hour_11",
    HOUR_12: "hour_12",
    HOUR_13: "hour_13",
    HOUR_14: "hour_14",
    HOUR_15: "hour_15",
    HOUR_16: "hour_16",
    HOUR_17: "hour_17",
    HOUR_18: "hour_18",
    HOUR_19: "hour_19",
    HOUR_20: "hour_20",
    HOUR_21: "hour_21",
    HOUR_22: "hour_22",
    HOUR_23: "hour_23",

    // Chart
    DAY_CHART: "dayChart",
    DAY_AGO_21: "dayAgo21",
    DAY_AGO_20: "dayAgo20",
    DAY_AGO_19: "dayAgo19",
    DAY_AGO_18: "dayAgo18",
    DAY_AGO_17: "dayAgo17",
    DAY_AGO_16: "dayAgo16",
    DAY_AGO_15: "dayAgo15",
    DAY_AGO_14: "dayAgo14",
    DAY_AGO_13: "dayAgo13",
    DAY_AGO_12: "dayAgo12",
    DAY_AGO_11: "dayAgo11",
    DAY_AGO_10: "dayAgo10",
    DAY_AGO_9: "dayAgo9",
    DAY_AGO_8: "dayAgo8",
    DAY_AGO_7: "dayAgo7",
    DAY_AGO_6: "dayAgo6",
    DAY_AGO_5: "dayAgo5",
    DAY_AGO_4: "dayAgo4",
    DAY_AGO_3: "dayAgo3",
    DAY_AGO_2: "dayAgo2",
    DAY_AGO_1: "dayAgo1",
    DAY_AGO_0: "dayAgo0",
  },
  OBJECT_KEYS: {
    "00:00": "hour_0",
    "01:00": "hour_1",
    "02:00": "hour_2",
    "03:00": "hour_3",
    "04:00": "hour_4",
    "05:00": "hour_5",
    "06:00": "hour_6",
    "07:00": "hour_7",
    "08:00": "hour_8",
    "09:00": "hour_9",
    "10:00": "hour_10",
    "11:00": "hour_11",
    "12:00": "hour_12",
    "13:00": "hour_13",
    "14:00": "hour_14",
    "15:00": "hour_15",
    "16:00": "hour_16",
    "17:00": "hour_17",
    "18:00": "hour_18",
    "19:00": "hour_19",
    "20:00": "hour_20",
    "21:00": "hour_21",
    "22:00": "hour_22",
    "23:00": "hour_23",
  },
};
export const BACKGROUND_HEADER_TABLE = "var(--background-header-table)";
export const COLOR_LEGEND_CHART = "var(--color-text-secondary)";
export const FONT_WEIGHT_LEGEND = "var(--font-medium)";
export const INPUT_ELLIPSIS = "input-ellipsis";
export const RADIO_OPT = 2;
export const GUTTER_HORIZONTAL = 40;
export const MIN_BATTERY = 20;
export const MATH_MIN = 90;
export const MATH_MIN_DECIMAL = 0.9;
export const DOT_CHART = 4;
