import { TAxiosResponse } from "types";
import { axiosInstance } from "./axiosInstance";
import { TCommonError } from "@models/common";
import { DEFAULT_PAGE_SIZE } from "@constants/common";
import { TCheckCompanyExistReq, TCompanyRegistrationRes, TCompanyUpdateReq, TFormModalRegisterReq, TListCompanyReq, TListCompanyRes } from "@models/company";

const companyApi = {
  getCompaniesByCode: async (payload: TListCompanyReq) => {
    const _pageIndex = payload.pageIndex ? payload.pageIndex : 1;
    const _payload: Omit<TListCompanyReq, "pageIndex"> = {
      companyCode: payload.companyCode || "",
    };
    const url = `/Company/search?CompanyCode=${_payload.companyCode}&PageIndex=${_pageIndex}&PageSize=${DEFAULT_PAGE_SIZE}`;

    const response: TAxiosResponse<TListCompanyRes[], TCommonError> =
      await axiosInstance.get(url);
    return response;
  },

  getDetailCompany: async (payload: string) => {
    const url = `/Company/${payload}`;
    const response: TAxiosResponse<TCompanyRegistrationRes, TCommonError> =
      await axiosInstance.get(url);
    return response;
  },

  registrationCompany: async (payload: TFormModalRegisterReq) => {
    const url = "/Company/create";
    const response: TAxiosResponse<TCompanyRegistrationRes, TCommonError> =
      await axiosInstance.post(url, payload);
    return response;
  },

  deleteCompany: async (payload: string) => {
    const url = `/Company/delete/${payload}`;
    const response: TAxiosResponse<any, TCommonError> =
      await axiosInstance.delete(url);
    return response;
  },

  updateCompany: async (payload: TCompanyUpdateReq) => {
    const url = "/Company/update";
    const response: TAxiosResponse<TCompanyUpdateReq, TCommonError> =
      await axiosInstance.put(url, payload);
    return response;
  },

  checkCompanyExist: async (payload: TCheckCompanyExistReq) => {
    const url = `/Company/exists`;
    const response: TAxiosResponse<any, TCommonError> =
      await axiosInstance.post(url, payload);
    return response;
  },
};

export { companyApi };
