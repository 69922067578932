import { Tabs } from "antd";
import { useState } from "react";
import { DataTab } from "./components/DataTab";
import { MASTER_DATA_CONSTANT } from "./constants";
import { MasterDataWrapper } from "./MasterDataStyled";
import { ETabName } from "types/others";

const { TITLE } = MASTER_DATA_CONSTANT;

function MasterDataPage() {
  const [tabName, setTabName] = useState("user");
  const listTabs = [
    {
      label: TITLE.USER,
      key: ETabName.USER,
      children: <DataTab tabName={tabName} />,
    },
    {
      label: TITLE.COMPANY,
      key: ETabName.COMPANY,
      children: <DataTab tabName={tabName} />,
    },
    {
      label: TITLE.DISTRICT, // api import area
      key: ETabName.AREA,
      children: <DataTab tabName={tabName} />,
    },
    {
      label: TITLE.STORE,
      key: ETabName.STORE,
      children: <DataTab tabName={tabName} />,
    },
    {
      label: TITLE.DEPARTMENT,
      key: ETabName.DEPARTMENT,
      children: <DataTab tabName={tabName} />,
    },
    {
      label: TITLE.DEVICE, // api import sensor
      key: ETabName.SENSOR,
      children: <DataTab tabName={tabName} />,
    },
    {
      label: TITLE.MANUFACTURER,
      key: ETabName.MANUFACTURER,
      children: <DataTab tabName={tabName} />,
    },
    {
      label: TITLE.PURPOSE,
      key: ETabName.PURPOSE,
      children: <DataTab tabName={tabName} />,
    },
  ];

  return (
    <MasterDataWrapper>
      <div className="master-data_header">
        <h1>{TITLE.MASTER_DATA}</h1>
      </div>

      <div className="master-data_tab">
        <Tabs
          onTabClick={(key) => setTabName(key)}
          type="card"
          items={listTabs}
        />
      </div>
    </MasterDataWrapper>
  );
}

export { MasterDataPage };
