import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import { adminPaths } from "routers/routes";

const Error_403 = () => {
  const navigate = useNavigate();

  return (
    <Result
      status="403"
      title="403"
      subTitle="この画面へのアクセス権限がないため利用できません。"
      extra={
        <Button
          onClick={() =>
            navigate(adminPaths.temperatureReport, { replace: true })
          }
          type="primary"
        >
          ホームに戻る
        </Button>
      }
    />
  );
};

export { Error_403 };
