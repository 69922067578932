import { ArrowSelect, LoadingCommon } from "@components";
import EmptyDataTable from "@components/EmptyDataTable/EmptyDataTable";
import { images } from "@constants";
import { TEN } from "@constants/common";
import { ArrowLeftIcon, SearchIcon } from "@constants/svgs";
import { Button, ConfigProvider, Form, Input, Select, Table } from "antd";
import { AllDeviceWrapper, ModalDownloadWrapper } from "./AllDeviceStyled";
import { ALL_DEVICE_CONSTANT, HEIGHT_TABLE } from "./constant";
import useAllDevice from "./hooks/useAllDevice";
import SuffixIcon from "./components/SuffixIcon";

const { LABEL, NAME, TITLE, PLACEHOLDER, MESSAGE } = ALL_DEVICE_CONSTANT;

const FIELDS_CONFIG = {
  COMPANY: {
    required: false,
    name: NAME.COMPANY,
    label: (
      <>
        {LABEL.COMPANY}{" "}
        <span style={{ marginLeft: "4px", color: "#d92d20" }}>*</span>
      </>
    ),
  },
  AREA: {
    required: false,
    name: NAME.AREA,
    label: LABEL.AREA,
    className: "all-device_form-area",
  },
  STORE_CODE: {
    required: false,
    name: NAME.STORE_CODE,
    label: LABEL.STORE_CODE,
    className: "all-device_form-store-code",
  },
  STORE_NAME: {
    required: false,
    name: NAME.STORE_NAME,
    label: LABEL.STORE_NAME,
    className: "all-device_form-store-name",
  },
  MANUFACTURER: {
    required: false,
    name: NAME.MANUFACTURER,
    label: LABEL.MANUFACTURER,
    className: "all-device_form-manufacturer",
  },
  PURPOSE: {
    required: false,
    name: NAME.PURPOSE,
    label: LABEL.PURPOSE,
    className: "all-device_form-purpose",
  },
  INSTALL: {
    required: false,
    name: NAME.INSTALL,
    label: LABEL.INSTALL,
    className: "all-device_form-install",
  },
};

function AllDevicePage() {
  const { handler, state } = useAllDevice();

  return (
    <LoadingCommon loading={state.loadingPage}>
      <AllDeviceWrapper listdata={state.resultData.data.length}>
        <div className="all-device_header">
          <div className="all-device_header-title">
            <button onClick={handler.handleNavigateBack}>
              <ArrowLeftIcon />
            </button>
            <h1>{TITLE.ALL_STORE}</h1>
          </div>
          <div className="all-device_header-btn">
            <Button onClick={() => handler.setModalDownload(true)}>
              {TITLE.BTN_EXPORT}
            </Button>
          </div>
        </div>

        <div className="all-device_form">
          <Form
            layout="vertical"
            form={state.form}
            onFinish={handler.handleSearch}
            initialValues={{
              [NAME.STORE_CODE]: "",
              [NAME.STORE_NAME]: "",
              [NAME.MANUFACTURER]: "",
            }}
          >
            <div className="all-device_form-top">
              <Form.Item {...FIELDS_CONFIG.COMPANY}>
                <Select
                  size="large"
                  options={state.companiesSelect}
                  onChange={(val) => handler.handleChangeCompany(val)}
                  suffixIcon={<ArrowSelect />}
                />
              </Form.Item>
            </div>

            <div className="all-device_form-bottom">
              <Form.Item {...FIELDS_CONFIG.AREA}>
                <Select
                  size="large"
                  placeholder={PLACEHOLDER.INPUT}
                  options={state.areasSelect}
                  disabled={state.loadingOther}
                  suffixIcon={<SuffixIcon loading={state.loadingOther} />}
                />
              </Form.Item>

              <Form.Item {...FIELDS_CONFIG.STORE_CODE}>
                <Input size="large" placeholder={PLACEHOLDER.INPUT} />
              </Form.Item>

              <Form.Item {...FIELDS_CONFIG.STORE_NAME}>
                <Input size="large" placeholder={PLACEHOLDER.INPUT} />
              </Form.Item>

              <Form.Item {...FIELDS_CONFIG.MANUFACTURER}>
                <Input size="large" placeholder={PLACEHOLDER.INPUT} />
              </Form.Item>

              <Form.Item {...FIELDS_CONFIG.PURPOSE}>
                <Select
                  size="large"
                  placeholder={PLACEHOLDER.INPUT}
                  options={state.purposesSelect}
                  disabled={state.loadingOther}
                  suffixIcon={<SuffixIcon loading={state.loadingOther} />}
                />
              </Form.Item>

              <Form.Item {...FIELDS_CONFIG.INSTALL}>
                <Select
                  size="large"
                  placeholder={PLACEHOLDER.INPUT}
                  options={state.locationsSelect}
                  disabled={state.loadingOther}
                  suffixIcon={<SuffixIcon loading={state.loadingOther} />}
                />
              </Form.Item>

              <div className="all-device_form-object">
                {TITLE.OBJECT} : {state.resultData.totalRecord}/
                {state.resultData.totalCompanySensor}
              </div>

              <Button htmlType="submit" icon={<SearchIcon />} />
            </div>
          </Form>
        </div>

        <div className="all-device_table">
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  headerBg: "var(--background-header-table)",
                  borderColor: "var(--color-border-primary)",
                  headerColor: "var(--color-header-table)",
                  fontWeightStrong: 700,
                },
              },
            }}
            renderEmpty={() =>
              !state.resultData.data.length ? (
                <EmptyDataTable message={MESSAGE.NO_DATA} />
              ) : undefined
            }
          >
            <Table
              dataSource={state.resultData.data}
              columns={state.columns}
              bordered
              showSorterTooltip={false}
              scroll={{
                y:
                  state.resultData.data.length > TEN ? HEIGHT_TABLE : undefined,
              }}
              pagination={false}
              onScroll={handler.handleScroll}
              onChange={handler.handleChangeTable}
              virtual={state.resultData.data.length > 0}
            />
          </ConfigProvider>
        </div>
      </AllDeviceWrapper>

      <ModalDownloadWrapper
        centered
        closeIcon={false}
        open={state.modalDownload}
        title={TITLE.DOWNLOAD_CSV}
        confirmLoading={state.loadingPage}
        onOk={() => {
          handler.handleExportData();
        }}
        onCancel={() => handler.setModalDownload(false)}
        maskClosable={false}
        cancelText={TITLE.BTN_CANCEL}
        okText={TITLE.BTN_OK}
        width={412}
      >
        <img src={images.confirmApproveTemperature} alt="modal_download" />
        <p className="all-device_modal-download">{MESSAGE.DOWNLOAD}</p>
      </ModalDownloadWrapper>
    </LoadingCommon>
  );
}

export { AllDevicePage };
