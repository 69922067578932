import { LoadingCommon } from "@components";
import CustomLegendChart from "@components/CustomLegendChart/CustomLegendChart";
import EmptyDataTable from "@components/EmptyDataTable/EmptyDataTable";
import { images } from "@constants";
import { TIME_PICKER_FORMAT } from "@constants/common";
import svgs, { ArrowLeftIcon, SearchIcon } from "@constants/svgs";
import ThermoIcon from "@pages/TemperatureReportPage/components/ThermoIcon";
import { handleDisabledDate } from "@utils/helpers";
import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Table,
  TimePicker,
} from "antd";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { AlarmSettingWrapper, OpenCaseWrapper } from "./OpenCaseStyled";
import { columns, columns3 } from "./columns";
import {
  BACKGROUND_HEADER_TABLE,
  COLOR_LEGEND_CHART,
  DOT_CHART,
  FONT_WEIGHT_LEGEND,
  GUTTER_HORIZONTAL,
  INPUT_ELLIPSIS,
  OPEN_CASE_CONSTANT,
  RADIO_OPT,
} from "./constant";
import useOpenCase from "./hook/useOpenCase";
import { InputTooltip } from "./components/InputTooltip";
import DotIconChart from "@components/DotIconChart/DotIconChart";
import { useCallback } from "react";
import SuffixIcon from "./components/SuffixIcon";
import _ from "lodash";

const { LABEL, NAME, TITLE, PLACEHOLDER, MESSAGE } = OPEN_CASE_CONSTANT;

const FIELDS_CONFIG = {
  COMPANY: { required: false, name: NAME.COMPANY, label: LABEL.COMPANY },
  STORE: { required: false, name: NAME.STORE, label: LABEL.STORE },
  MACHINE: {
    required: false,
    name: NAME.MACHINE,
    label: LABEL.MACHINE,
    className: INPUT_ELLIPSIS,
  },
  ID_CH: { required: false, name: NAME.ID_CH, label: LABEL.ID_CH },
  DEPARTMENT_NAME: {
    required: false,
    name: NAME.DEPARTMENT_NAME,
    label: LABEL.DEPARTMENT_NAME,
    className: INPUT_ELLIPSIS,
  },
  MANUFACTURER_NAME: {
    required: false,
    name: NAME.MANUFACTURER_NAME,
    label: LABEL.MANUFACTURER_NAME,
    className: INPUT_ELLIPSIS,
  },
  MANUFACTURER_MODEL: {
    required: false,
    name: NAME.MANUFACTURER_MODEL,
    label: LABEL.MANUFACTURER_MODEL,
    className: INPUT_ELLIPSIS,
  },
  TEMPERATURE_DISTINCTION: {
    required: false,
    name: NAME.TEMPERATURE_DISTINCTION,
    label: LABEL.TEMPERATURE_DISTINCTION,
  },
  INSTALL_LOCATION: {
    required: false,
    name: NAME.INSTALL_LOCATION,
    label: LABEL.INSTALL_LOCATION,
  },
  PURPOSE: {
    required: false,
    name: NAME.PURPOSE,
    label: LABEL.PURPOSE,
    className: INPUT_ELLIPSIS,
  },
  OPERATING_HOUR_FROM: {
    required: false,
    label: LABEL.OPERATING_HOUR_FROM,
    name: NAME.OPERATING_HOUR_FROM,
    className: "open-case_form-hour",
  },
};

const data2 = [
  {
    key: 1,
    individual: "下限",
  },
  {
    key: 2,
    individual: "上限",
  },
];

const RenderLegendChart = () => {
  return (
    <Row justify={"center"} gutter={[GUTTER_HORIZONTAL, 0]}>
      <Col>
        <CustomLegendChart color="#12B76A" />
        <span
          style={{
            marginLeft: "4px",
            color: COLOR_LEGEND_CHART,
            fontWeight: FONT_WEIGHT_LEGEND,
          }}
        >
          {TITLE.TEMPERATURE}
        </span>
      </Col>
      <Col>
        <CustomLegendChart color="#D92D20" />
        <span
          style={{
            marginLeft: "4px",
            color: COLOR_LEGEND_CHART,
            fontWeight: FONT_WEIGHT_LEGEND,
          }}
        >
          {TITLE.UPPER_LIMIT}
        </span>
      </Col>
      <Col>
        <CustomLegendChart color="#074173" />
        <span
          style={{
            marginLeft: "4px",
            color: COLOR_LEGEND_CHART,
            fontWeight: FONT_WEIGHT_LEGEND,
          }}
        >
          {TITLE.LOWER_LIMIT}
        </span>
      </Col>
      <Col>
        <CustomLegendChart color="#17f3f7" />
        <span
          style={{
            marginLeft: "4px",
            color: COLOR_LEGEND_CHART,
            fontWeight: FONT_WEIGHT_LEGEND,
          }}
        >
          {TITLE.DEFROST}
        </span>
      </Col>
      <Col>
        <CustomLegendChart color="#ccc" />
        <span
          style={{
            marginLeft: "4px",
            color: COLOR_LEGEND_CHART,
            fontWeight: FONT_WEIGHT_LEGEND,
          }}
        >
          {TITLE.OUT_TIME}
        </span>
      </Col>
    </Row>
  );
};

const InputLowerTemperature = (props: any) => (
  <div className="open-case_table-second-input">
    <div className="open-case_table-second-input-label">
      {TITLE.LOWER_LIMIT}
    </div>
    <Divider type="vertical" />
    <Input {...props} readOnly size="large" />
  </div>
);

const InputUpperTemperature = (props: any) => (
  <div className="open-case_table-second-input">
    <div className="open-case_table-second-input-label">
      {TITLE.UPPER_LIMIT}
    </div>
    <Divider type="vertical" />
    <Input {...props} readOnly size="large" />
  </div>
);

const InputShowTooltip = (props: any) => (
  <InputTooltip title={props.title}>
    <Input size="large" disabled {...props} title="" />
  </InputTooltip>
);

function OpenCasePage() {
  const { handler, state } = useOpenCase();

  const CustomizedDot = useCallback(
    (props: any) => {
      // eslint-disable-next-line react/prop-types
      const { cx, cy, payload } = props;
      const _chartTemp = state.chartTemp.map((item) => ({
        ...item,
        temperature: Number.parseFloat(
          (item.temperature as string)?.split("_")[0]
        ),
        defrost: (item.temperature as string)?.split("_")[1],
        isActive: (item.temperature as string)?.split("_")[2],
        isNoCooling: (item.temperature as string)?.split("_")[3],
      }));
      const findValue = _chartTemp.find((item) => item.name === payload.name);

      if (
        typeof findValue?.temperature !== "number" ||
        Number.isNaN(findValue?.temperature)
      ) {
        return <></>;
      }

      if (
        findValue?.isNoCooling === "true" ||
        findValue?.isActive === "false"
      ) {
        return (
          <DotIconChart x={cx - DOT_CHART} y={cy - DOT_CHART} color="#cccccc" />
        );
      } else {
        if (findValue?.defrost === "true") {
          return (
            <DotIconChart
              x={cx - DOT_CHART}
              y={cy - DOT_CHART}
              color="#17f3f7"
            />
          );
        } else {
          if (findValue?.temperature >= state.rangeTemp.maxTemp) {
            return (
              <DotIconChart
                x={cx - DOT_CHART}
                y={cy - DOT_CHART}
                color="#D92D20"
              />
            );
          } else if (findValue?.temperature <= state.rangeTemp.minTemp) {
            return (
              <DotIconChart
                x={cx - DOT_CHART}
                y={cy - DOT_CHART}
                color="#074173"
              />
            );
          } else {
            return (
              <DotIconChart
                x={cx - DOT_CHART}
                y={cy - DOT_CHART}
                color="#12B76A"
              />
            );
          }
        }
      }
    },
    [state.rangeTemp]
  );

  const CustomTooltipChart = useCallback(
    ({ active, payload, label }: any) => {
      if (active && payload && payload.length) {
        const findIndex = _.findIndex(state.chartTemp, function (item) {
          return item.name === payload[0]?.payload?.name;
        });
        const _chartTemp = state.chartTemp.map((item) => ({
          ...item,
          temperature: Number.parseFloat(
            (item.temperature as string)?.split("_")[0]
          ),
          defrost: (item.temperature as string)?.split("_")[1],
          isActive: (item.temperature as string)?.split("_")[2],
          isNoCooling: (item.temperature as string)?.split("_")[3],
        }));

        if (
          _chartTemp[findIndex]?.isActive === "false" ||
          _chartTemp?.[findIndex]?.isNoCooling === "true"
        ) {
          handler.setDotStyle({
            fill: "#cccccc",
            stroke: "#cccccc4d",
          });
        } else {
          if (_chartTemp[findIndex]?.defrost === "true") {
            handler.setDotStyle({
              fill: "#17f3f7",
              stroke: `#17f3f74d`,
            });
          } else {
            if (payload[0]?.value >= state.rangeTemp.maxTemp) {
              handler.setDotStyle({
                fill: "#D92D20",
                stroke: "#D92D204D",
              });
            } else if (payload[0]?.value <= state.rangeTemp.minTemp) {
              handler.setDotStyle({
                fill: "#074173",
                stroke: "#0741734D",
              });
            } else {
              handler.setDotStyle({
                fill: "#12B76A",
                stroke: "#12B76A4D",
              });
            }
          }
        }

        if (!Number.isNaN(payload[0]?.value)) {
          return (
            <div className="open-case_chart-custom-tooltip">
              <label>{label}</label>
              <div>
                <ThermoIcon color="var(--color-text-secondary)" />
                <p>
                  {state.chartTemp[findIndex]?.temperature?.split("_")[0]}
                  °C
                </p>
              </div>
            </div>
          );
        } else {
          return null;
        }
      }

      return null;
    },
    [state.dotStyle]
  );

  return (
    <LoadingCommon loading={state.loadingPage}>
      <OpenCaseWrapper
        radiooption={state.radioOpt}
        batterylevel={+state.batteryValue}
      >
        <div className="open-case_header">
          <button onClick={handler.handleNavigateBack}>
            <ArrowLeftIcon />
          </button>
          <h1>{TITLE.INFORMATION_INQUIRY}</h1>
        </div>

        <div className="open-case_form">
          <Form
            form={state.form}
            layout="vertical"
            name="open-case_form"
            onFinish={handler.handleGetSensorByParam}
          >
            <div className="open-case_form-search">
              <Form.Item {...FIELDS_CONFIG.COMPANY}>
                <Select
                  size="large"
                  options={state.companies}
                  disabled={state.isRoleStore}
                  suffixIcon={
                    <SuffixIcon
                      loading={state.loadingPage}
                      disable={state.isRoleStore as boolean}
                    />
                  }
                  onChange={(val) => handler.handleChangeCompany(val)}
                />
              </Form.Item>
              <Form.Item {...FIELDS_CONFIG.STORE}>
                <Select
                  size="large"
                  options={state.stores}
                  disabled={state.isRoleStore || state.loadingStore}
                  suffixIcon={
                    <SuffixIcon
                      loading={state.loadingStore}
                      disable={state.isRoleStore as boolean}
                    />
                  }
                  onChange={(val) => handler.handleChangeStore(val)}
                />
              </Form.Item>
              <Form.Item {...FIELDS_CONFIG.ID_CH}>
                <Select
                  size="large"
                  options={state.idCh}
                  disabled={state.loadingIdCh}
                  suffixIcon={
                    <SuffixIcon loading={state.loadingIdCh} disable={false} />
                  }
                />
              </Form.Item>

              <Button
                name="open-case_form"
                htmlType="submit"
                icon={<SearchIcon />}
              />
            </div>

            <div className="open-case_form-result">
              <div className="open-case_form-result-top">
                <Form.Item {...FIELDS_CONFIG.MACHINE}>
                  <InputShowTooltip
                    title={state.form.getFieldValue(NAME.MACHINE)}
                  />
                </Form.Item>

                <Form.Item {...FIELDS_CONFIG.INSTALL_LOCATION}>
                  <Input size="large" disabled />
                </Form.Item>

                <Form.Item {...FIELDS_CONFIG.DEPARTMENT_NAME}>
                  <InputShowTooltip
                    title={state.form.getFieldValue(NAME.DEPARTMENT_NAME)}
                  />
                </Form.Item>

                <Form.Item {...FIELDS_CONFIG.PURPOSE}>
                  <InputShowTooltip
                    title={state.form.getFieldValue(NAME.PURPOSE)}
                  />
                </Form.Item>

                <div className="open-case_battery-container">
                  {LABEL.BATTERY_LEVEL}

                  <div className="open-case_battery">
                    <div className="open-case_battery-charge"></div>
                    <div className="open-case_battery-charge-level">
                      {state.batteryValue
                        ? `${state.batteryValue}%`
                        : TITLE.NONE}
                    </div>
                  </div>
                </div>
              </div>

              <div className="open-case_form-result-bottom">
                <Form.Item {...FIELDS_CONFIG.MANUFACTURER_NAME}>
                  <InputShowTooltip
                    title={state.form.getFieldValue(NAME.MANUFACTURER_NAME)}
                  />
                </Form.Item>

                <Form.Item {...FIELDS_CONFIG.MANUFACTURER_MODEL}>
                  <InputShowTooltip
                    title={state.form.getFieldValue(NAME.MANUFACTURER_MODEL)}
                  />
                </Form.Item>

                <Form.Item {...FIELDS_CONFIG.OPERATING_HOUR_FROM}>
                  <TimePicker.RangePicker
                    placeholder={[PLACEHOLDER.FROM_HOUR, PLACEHOLDER.TO_HOUR]}
                    format={TIME_PICKER_FORMAT}
                    size="large"
                    disabled
                  />
                </Form.Item>

                <Form.Item {...FIELDS_CONFIG.TEMPERATURE_DISTINCTION}>
                  <Input size="large" disabled />
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>

        <div className="open-case_table">
          <div className="open-case_table-first">
            <h2>{TITLE.DEFROST_INFORMATION}</h2>
            <ConfigProvider
              theme={{
                components: {
                  Table: {
                    headerBg: BACKGROUND_HEADER_TABLE,
                    borderColor: "var(--color-border-secondary)",
                    headerColor: "var(--color-header-table)",
                    rowHoverBg: "var(--color-row-hover)",
                  },
                },
              }}
              renderEmpty={() =>
                !state.tableDefrost.length ? (
                  <EmptyDataTable message={MESSAGE.NO_DATA} />
                ) : undefined
              }
            >
              <Table
                columns={columns}
                pagination={false}
                dataSource={state.tableDefrost}
                bordered
              />
            </ConfigProvider>
          </div>
          <div className="open-case_table-second">
            <h2>{TITLE.ALARM_SETTINGS}</h2>

            <Form form={state.formSetting}>
              <Radio.Group
                onChange={handler.handleChangeRadioOption}
                value={state.radioOpt}
              >
                <Space style={{ width: "100%" }} direction="vertical">
                  <div className="open-case_table-second-radio">
                    <Radio value={1}>{TITLE.CATEGORY}</Radio>
                    <div className="open-case_table-second-item">
                      <Form.Item
                        className="open-case_table-second-select"
                        name={NAME.SELECT}
                      >
                        <Select
                          disabled={
                            state.radioOpt === RADIO_OPT || state.loadingStore
                          }
                          size="large"
                          options={state.selectPurpose}
                          suffixIcon={
                            <SuffixIcon
                              loading={state.loadingStore}
                              disable={state.radioOpt === RADIO_OPT}
                            />
                          }
                          onChange={handler.handleChangeSelect}
                        />
                      </Form.Item>

                      <Form.Item name={NAME.LOWER_INPUT}>
                        <InputLowerTemperature />
                      </Form.Item>

                      <Form.Item name={NAME.UPPER_INPUT}>
                        <InputUpperTemperature />
                      </Form.Item>

                      <Form.Item
                        style={{ marginLeft: "36px" }}
                        label={TITLE.APPLICABLE_DATE}
                      >
                        {state.latestDay}
                      </Form.Item>
                    </div>
                  </div>

                  <Radio value={2}>{TITLE.INDIVIDUAL}</Radio>
                  <ConfigProvider
                    theme={{
                      components: {
                        Table: {
                          headerBg: BACKGROUND_HEADER_TABLE,
                          borderColor: "#EAECF0",
                          headerColor: "var(--color-header-table)",
                        },
                      },
                    }}
                  >
                    <Table
                      pagination={false}
                      bordered
                      columns={state.renderColumns2}
                      dataSource={data2}
                    />
                  </ConfigProvider>
                </Space>
              </Radio.Group>

              <div className="open-case_table-btn">
                {state.messageTemp && <p>{state.messageTemp}</p>}
                <Button
                  disabled={
                    state.radioOpt === RADIO_OPT &&
                    ((!state.watchedLowerNumber &&
                      state.watchedLowerNumber !== 0) ||
                      (!state.watchedUpperNumber &&
                        state.watchedUpperNumber !== 0))
                  }
                  onClick={() => {
                    handler.handleOpenModalSetting();
                  }}
                  type="primary"
                >
                  {TITLE.BTN_SETTING}
                </Button>
              </div>
            </Form>
          </div>

          <div className="open-case_table-third">
            <div className="open-case_table-third-select">
              <div className="open-case_table-third-select-label">
                {TITLE.BY_DAY}
              </div>
              <Divider type="vertical" />
              <Form form={state.formDate}>
                <Form.Item
                  name={NAME.REPORT_DATE}
                  style={{ marginBottom: "0" }}
                >
                  <DatePicker
                    inputReadOnly={true}
                    allowClear={false}
                    onChange={(val) => handler.handleChangeReportDate(val)}
                    disabledDate={handleDisabledDate}
                    size="large"
                    format={"M月D日"}
                    suffixIcon={
                      <img
                        src={svgs.datePickerTemperature}
                        alt="datepicker_temperature"
                      />
                    }
                  />
                </Form.Item>
              </Form>
            </div>

            <div className="open-case_table-hour">
              <ConfigProvider
                theme={{
                  components: {
                    Table: {
                      headerBg: BACKGROUND_HEADER_TABLE,
                      borderColor: "#EAECF0",
                    },
                  },
                }}
                renderEmpty={() =>
                  !state.hourTemp.length ? (
                    <EmptyDataTable message={MESSAGE.NO_DATA} />
                  ) : undefined
                }
              >
                <Table
                  pagination={false}
                  bordered
                  columns={columns3}
                  dataSource={state.hourTemp}
                />
              </ConfigProvider>
            </div>

            <div className="open-case_table-third-chart">
              {state.chartTemp.length ? (
                <div>
                  <LineChart
                    width={1788}
                    height={418}
                    data={state.chartTemp.map((item) => ({
                      ...item,
                      temperature: Number.parseFloat(
                        (item.temperature as string)?.split("_")[0]
                      ),
                    }))}
                    margin={{
                      top: 20,
                      right: 20,
                      left: -10,
                      bottom: -4,
                    }}
                    className="open-case_table-third-chart-line"
                  >
                    <CartesianGrid
                      strokeDasharray="0 0"
                      vertical={false}
                      stroke="#F2F4F7"
                    />
                    <XAxis
                      tickLine={false}
                      dataKey="name"
                      padding={{ left: 15, right: 4 }}
                      stroke="var(--color-border-primary)"
                      tick={{
                        fill: "var(--color-text-secondary)",
                        fontSize: "var(--font-size-sm)",
                        fontWeight: "var(--font-medium)",
                      }}
                    />
                    <YAxis
                      allowDecimals={false}
                      tickLine={false}
                      tickCount={10}
                      domain={[
                        state.rangeTemp.minTemp - 5,
                        state.rangeTemp.maxTemp + 5,
                      ]}
                      stroke="var(--color-border-primary)"
                      tick={{
                        fill: "var(--color-text-secondary)",
                        fontSize: "var(--font-size-sm)",
                        fontWeight: "var(--font-medium)",
                      }}
                    />
                    <Tooltip
                      allowEscapeViewBox={{ x: true, y: true }}
                      content={<CustomTooltipChart />}
                      wrapperStyle={{
                        top: "-100px",
                        left: "-75px",
                      }}
                    />
                    <Legend
                      content={<RenderLegendChart />}
                      style={{ marginTop: "16px" }}
                    />
                    <ReferenceLine
                      y={state.rangeTemp?.maxTemp}
                      stroke="#D92D20"
                    />
                    <ReferenceLine
                      y={state.rangeTemp?.minTemp}
                      stroke="#074173"
                    />
                    <Line
                      dot={(props) => CustomizedDot(props)}
                      dataKey="temperature"
                      stroke="#12B76A"
                      activeDot={{
                        strokeWidth: 7,
                        stroke: state.dotStyle.stroke,
                        fill: state.dotStyle.fill,
                      }}
                    />
                  </LineChart>
                </div>
              ) : null}

              <p className="open-case_table-third-chart-title">
                {TITLE.TEMPERATURE_CHART}
              </p>

              <ConfigProvider
                theme={{
                  components: {
                    Table: {
                      borderColor: "#EAECF0",
                    },
                  },
                }}
                renderEmpty={() =>
                  !state.reportTemp.length ? (
                    <EmptyDataTable message={MESSAGE.NO_DATA} />
                  ) : undefined
                }
              >
                <Table
                  bordered
                  pagination={false}
                  columns={state.renderColumns4}
                  dataSource={state.reportTemp}
                />
              </ConfigProvider>
            </div>
          </div>
        </div>
      </OpenCaseWrapper>

      <AlarmSettingWrapper
        centered
        closeIcon={false}
        open={state.openModalSetting}
        title={TITLE.ALARM_SETTINGS}
        onOk={() => {
          handler.handleUpdateTemperatureSensor();
        }}
        onCancel={() => {
          handler.handleCloseModalSetting();
        }}
        maskClosable={false}
        okText={TITLE.BTN_SET}
        cancelText={TITLE.BTN_NOT_SET}
        width={416}
      >
        <img
          src={images.confirmApproveTemperature}
          alt="confirm_approve_temperature"
        />
        <p className="temperature-report_message-approve">
          {MESSAGE.ALARM_SETTING}
        </p>
      </AlarmSettingWrapper>
    </LoadingCommon>
  );
}

export { OpenCasePage };
