import {
  ArrowSelect,
  IconPlusRegister,
  LoadingCommon,
  TableList,
} from "@components";
import { Button, Form, Input, InputNumber, Select } from "antd";
import { HEIGHT_TABLE_SCROLL, PURPOSE_CONSTANT } from "./constant";
import {
  MAX_LENGTH_255,
  PLACEHOLDER_INPUT,
  PLACEHOLDER_SELECT,
  REGEX_CHECK_FORMAT_TEMP,
  REGEX_FORMAT_TEMPERATURE,
} from "@constants/common";
import { ArrowLeftIcon, SearchIcon } from "@constants/svgs";
import {
  ModalDeleteWrapper,
  ModalWrapper,
  PurposeWrapper,
} from "./PurposeStyled";
import { images } from "@constants";
import usePurposePage from "./hooks/usePurposePage";
import { LoadingOutlined } from "@ant-design/icons";
import { EModalType } from "types/others";

const { TITLE, MESSAGE, LABEL, NAME } = PURPOSE_CONSTANT;

const renderLabelForm = (label: string) => {
  return (
    <>
      <span style={{ color: "#d92d20", marginRight: "4px" }}>*</span> {label}
    </>
  );
};

const FIELDS_CONFIG = {
  COMPANY: {
    required: false,
    label: LABEL.COMPANY,
    name: NAME.COMPANY,
  },
  PURPOSE: { required: false, label: LABEL.PURPOSE, name: NAME.PURPOSE },
  DEPARTMENT: {
    required: false,
    label: LABEL.DEPARTMENT,
    name: NAME.DEPARTMENT,
  },
  // Modal
  COMPANY_MODAL: {
    required: false,
    label: renderLabelForm(LABEL.COMPANY_MODAL),
    name: NAME.COMPANY_ID_MODAL,
  },
  DEPARTMENT_MODAL: {
    required: false,
    label: renderLabelForm(LABEL.DEPARTMENT_MODAL),
    name: NAME.DEPARTMENT_ID_MODAL,
  },
  PURPOSE_CODE_MODAL: {
    required: false,
    label: renderLabelForm(LABEL.PURPOSE_CODE_MODAL),
    name: NAME.PURPOSE_CODE_MODAL,
  },
  PURPOSE_NAME_MODAL: {
    required: false,
    label: renderLabelForm(LABEL.PURPOSE_NAME_MODAL),
    name: NAME.PURPOSE_NAME_MODAL,
  },
  PURPOSE_MIN_TEMP: {
    required: false,
    label: renderLabelForm(LABEL.PURPOSE_MIN_TEMP),
    name: NAME.PURPOSE_MIN_TEMP,
  },
  PURPOSE_MAX_TEMP: {
    required: false,
    label: renderLabelForm(LABEL.PURPOSE_MAX_TEMP),
    name: NAME.PURPOSE_MAX_TEMP,
  },
};

function PurposePage() {
  const { state, handler } = usePurposePage();

  return (
    <>
      <LoadingCommon loading={state.loadingPage}>
        <PurposeWrapper listdata={0}>
          <div className="purpose-page_header">
            <h1>{TITLE.HEADER_TITLE}</h1>
            <Button
              onClick={handler.handleOpenModalRegistration}
              type="primary"
              icon={<IconPlusRegister />}
              disabled={state.disableBtnOther}
            >
              {TITLE.BTN_REGISTRATION}
            </Button>
          </div>

          <div className="purpose-page_form">
            <Form
              onFinish={handler.handleFinish}
              form={state.form}
              layout="vertical"
            >
              {/* COMPANY */}
              <Form.Item {...FIELDS_CONFIG.COMPANY}>
                <Select
                  placeholder={PLACEHOLDER_SELECT}
                  allowClear={false}
                  size="large"
                  options={state.companies}
                  onChange={(val) => handler.handleChangeCompany(val)}
                  suffixIcon={
                    state.loadingCompany ? (
                      <LoadingOutlined spin={state.loadingCompany} />
                    ) : (
                      <ArrowSelect />
                    )
                  }
                />
              </Form.Item>

              {/* DEPARTMENT */}
              <Form.Item {...FIELDS_CONFIG.DEPARTMENT}>
                <Select
                  placeholder={PLACEHOLDER_SELECT}
                  allowClear={false}
                  size="large"
                  options={state.departments}
                  disabled={state.loadingDepartment}
                  onChange={(val) => handler.handleChangeDepartment(val)}
                  suffixIcon={
                    state.loadingDepartment ? (
                      <LoadingOutlined spin={state.loadingDepartment} />
                    ) : (
                      <ArrowSelect />
                    )
                  }
                />
              </Form.Item>

              {/* PURPOSE */}
              <Form.Item {...FIELDS_CONFIG.PURPOSE}>
                <Select
                  placeholder={PLACEHOLDER_SELECT}
                  allowClear={false}
                  size="large"
                  options={state.purposes}
                  disabled={state.loadingPurpose}
                  suffixIcon={
                    state.loadingPurpose ? (
                      <LoadingOutlined spin={state.loadingPurpose} />
                    ) : (
                      <ArrowSelect />
                    )
                  }
                />
              </Form.Item>

              <Button htmlType="submit" icon={<SearchIcon />} />
            </Form>
          </div>

          <TableList
            tableRef={state.tblRef}
            image={images.noResult}
            columns={state.columns}
            handleScroll={handler.handleScroll}
            heightTableScroll={HEIGHT_TABLE_SCROLL}
            listData={state.listPurpose}
            messageNoData={MESSAGE.NO_DATA}
            rowKey={state.rowKey}
            borderColor="var(--color-border-primary)"
          />
        </PurposeWrapper>
      </LoadingCommon>

      <ModalWrapper
        confirmLoading={state.loadingExist}
        loading={state.loadingModal}
        styles={{
          body: {
            overflowY: "auto",
            maxHeight: "660px",
          },
        }}
        width={800}
        title={
          <>
            {state.modalType === EModalType.CONFIRM_REGISTRATION && (
              <button
                onClick={() => handler.setModalType(EModalType.REGISTRATION)}
              >
                <ArrowLeftIcon />
              </button>
            )}
            {state.modalType === EModalType.CONFIRM_UPDATE && (
              <button onClick={() => handler.setModalType(EModalType.UPDATE)}>
                <ArrowLeftIcon />
              </button>
            )}
            <span>{state.renderTextModal.title}</span>
          </>
        }
        closeIcon={false}
        maskClosable={false}
        okText={state.renderTextModal.okText}
        cancelText={TITLE.BTN_CANCEL}
        destroyOnClose={true}
        open={state.modalOpen}
        centered
        onCancel={() => {
          handler.handleCloseModal();
        }}
        onOk={() => {
          if (state.modalType === EModalType.DETAIL) {
            handler.handleCloseModal();
          } else if (state.modalType === EModalType.UPDATE) {
            handler.handleValidateForm();
          } else if (state.modalType === EModalType.CONFIRM_REGISTRATION) {
            handler.handleOpenModalConfirmFinal();
          } else if (state.modalType === EModalType.CONFIRM_UPDATE) {
            handler.handleOpenModalConfirmFinal();
          } else {
            handler.handleValidateForm();
          }
        }}
        okButtonProps={{
          style: {
            width: state.modalType === EModalType.DETAIL ? "100%" : "368px",
          },
          disabled: state.disableBtnNext,
        }}
        cancelButtonProps={{
          style: {
            display: state.modalType === EModalType.DETAIL ? "none" : "block",
          },
        }}
      >
        <Form
          layout="vertical"
          form={state.formModal}
          preserve={false}
          disabled={state.disableFields}
        >
          <div className="purpose-page_modal-form-flex">
            <div className="purpose-page_modal-form-left">
              <Form.Item {...FIELDS_CONFIG.COMPANY_MODAL}>
                <Select
                  placeholder={PLACEHOLDER_SELECT}
                  allowClear={false}
                  size="large"
                  options={state.companiesModal}
                  suffixIcon={
                    <ArrowSelect disable={state.disableFieldsEdit} isModal />
                  }
                  disabled={state.disableFieldsEdit}
                  onChange={(val) => handler.handleChangeCompanyModal(val)}
                />
              </Form.Item>

              <Form.Item {...FIELDS_CONFIG.PURPOSE_CODE_MODAL}>
                <Input
                  placeholder={PLACEHOLDER_INPUT}
                  size="large"
                  maxLength={MAX_LENGTH_255}
                  disabled={state.disableFieldsEdit}
                  onChange={(event) => {
                    state.formModal.setFieldValue(
                      NAME.PURPOSE_CODE_MODAL,
                      event.target.value.replaceAll(" ", "")
                    );
                  }}
                  onKeyDown={(event) => {
                    if (event.code === "Space") {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>

              <Form.Item
                style={{ marginBottom: 0 }}
                {...FIELDS_CONFIG.PURPOSE_MIN_TEMP}
              >
                <InputNumber
                  onBlur={(event) => {
                    let _value = "";
                    const parts = event.target.value.split(".");
                    _value = parts[0] + (parts[1] ? `.${parts[1][0]}` : "");
                    const formattedValue = _value.replace(
                      REGEX_FORMAT_TEMPERATURE,
                      "$1$2"
                    );
                    if (
                      formattedValue &&
                      !formattedValue.match(REGEX_CHECK_FORMAT_TEMP)
                    ) {
                      state.formModal.setFieldValue(NAME.PURPOSE_MIN_TEMP, "0");
                    } else {
                      state.formModal.setFieldValue(
                        NAME.PURPOSE_MIN_TEMP,
                        formattedValue
                      );
                    }
                  }}
                  step="0.1"
                  placeholder={PLACEHOLDER_INPUT}
                  controls={false}
                  keyboard={false}
                  size="large"
                  onKeyDown={(event: any) =>
                    handler.handleKeyDownTemperature(event)
                  }
                />
              </Form.Item>
            </div>

            <div className="purpose-page_modal-form-right">
              <Form.Item {...FIELDS_CONFIG.DEPARTMENT_MODAL}>
                <Select
                  placeholder={PLACEHOLDER_SELECT}
                  allowClear={false}
                  size="large"
                  options={state.departmentsModal}
                  disabled={
                    state.loadingDepartmentModal || state.disableFieldsEdit
                  }
                  suffixIcon={
                    state.loadingDepartmentModal ? (
                      <LoadingOutlined spin={state.loadingDepartmentModal} />
                    ) : (
                      <ArrowSelect disable={state.disableFieldsEdit} isModal />
                    )
                  }
                />
              </Form.Item>

              <Form.Item {...FIELDS_CONFIG.PURPOSE_NAME_MODAL}>
                <Input
                  placeholder={PLACEHOLDER_INPUT}
                  size="large"
                  maxLength={MAX_LENGTH_255}
                  onBlur={(event) => {
                    state.formModal.setFieldValue(
                      NAME.PURPOSE_NAME_MODAL,
                      event.target.value.trim()
                    );
                  }}
                />
              </Form.Item>

              <Form.Item
                style={{ marginBottom: 0 }}
                {...FIELDS_CONFIG.PURPOSE_MAX_TEMP}
              >
                <InputNumber
                  onBlur={(event) => {
                    let _value = "";
                    const parts = event.target.value.split(".");
                    _value = parts[0] + (parts[1] ? `.${parts[1][0]}` : "");
                    const formattedValue = _value.replace(
                      REGEX_FORMAT_TEMPERATURE,
                      "$1$2"
                    );
                    if (
                      formattedValue &&
                      !formattedValue.match(REGEX_CHECK_FORMAT_TEMP)
                    ) {
                      state.formModal.setFieldValue(NAME.PURPOSE_MAX_TEMP, "0");
                    } else {
                      state.formModal.setFieldValue(
                        NAME.PURPOSE_MAX_TEMP,
                        formattedValue
                      );
                    }
                  }}
                  step="0.1"
                  placeholder={PLACEHOLDER_INPUT}
                  controls={false}
                  keyboard={false}
                  size="large"
                  onKeyDown={(event: any) =>
                    handler.handleKeyDownTemperature(event)
                  }
                />
              </Form.Item>
            </div>
          </div>
        </Form>
      </ModalWrapper>

      {/* Modal delete */}
      <ModalDeleteWrapper
        centered
        closeIcon={false}
        open={state.modalDelete}
        title={TITLE.TITLE_DELETE}
        confirmLoading={state.loadingPage}
        onOk={handler.handleOkModalDelete}
        onCancel={handler.handleCloseModalDelete}
        maskClosable={false}
        cancelText={TITLE.BTN_CANCEL}
        okText={TITLE.TITLE_DELETE}
        width={416}
      >
        <img src={images.modalDelete} alt="modal_delete" />
        <div className="purpose-page_modal-delete">
          {state.purpose && (
            <p>
              {state.purpose.purposeCode}_{state.purpose.purposeName}
            </p>
          )}
          <p>{MESSAGE.DELETE_DEVICE}</p>
        </div>
      </ModalDeleteWrapper>

      {/* Modal registration/update/delete success/fail */}
      <ModalDeleteWrapper
        centered
        closeIcon={false}
        open={state.modalSuccess}
        title={state.renderTextModal.title}
        onOk={handler.handleCloseModalSuccess}
        maskClosable={false}
        okText={state.renderTextModal.okText}
        width={412}
        okButtonProps={{ style: { width: "100%", marginInlineStart: 0 } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <img src={state.renderTextModal.image} alt="store_modal" />
        <div className="purpose-page_modal-regist-success">
          <p>{state.renderTextModal.message}</p>
        </div>
      </ModalDeleteWrapper>

      {/* Modal registration/update confirm */}
      <ModalDeleteWrapper
        centered
        closeIcon={false}
        open={state.modalConfirm}
        title={state.renderTextModalConfirm.title}
        onOk={handler.handleOkModalConfirmFinal}
        onCancel={handler.handleCloseModalConfirmFinal}
        maskClosable={false}
        okText={state.renderTextModalConfirm.okText}
        cancelText={TITLE.BTN_CANCEL}
        width={412}
        zIndex={9999}
        confirmLoading={state.loadingPage}
      >
        <img src={images.popUpConfirmFinal} alt="device_modal" />
        <div className="purpose-page_modal-confirm">
          <p>{state.renderTextModalConfirm.message}</p>
        </div>
      </ModalDeleteWrapper>
    </>
  );
}

export { PurposePage };
