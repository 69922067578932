import { Modal } from "antd";
import styled from "styled-components";

export const TemperatureReportWrapper = styled.div<{
  changeswitch: string;
  listdata: number;
}>`
  .temperature-report_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    .temperature-report_btns {
      button {
        width: 160px;
        height: 48px;
        border-radius: 7px;
        font-weight: var(--font-bold);
      }

      button:first-child {
        margin-right: 16px;
        box-shadow: none;
      }

      button:last-child {
        color: var(--color-primary);
        border-color: var(--color-primary);
      }

      button:disabled {
        background-color: var(--color-border-secondary);
      }
    }
  }

  .temperature-report_form {
    margin-bottom: 16px;

    form {
      display: flex;
      justify-content: space-between;
      column-gap: 16px;

      .temperature-report_top {
        display: flex;
        flex-direction: column;
      }
      .temperature-report_form-top {
        display: flex;
        column-gap: 12px;
        align-items: center;
        margin-bottom: 12px;
        flex-grow: 1;
      }

      .temperature-report_form-bottom {
        display: flex;
        align-items: center;
        column-gap: 12px;

        .ant-btn-default {
          width: 55px;
          height: 56px;
          background: #d7e7ff;
          border: none;
          margin-top: 28px;
        }
      }

      .temperature-report_bottom {
        display: flex;
        flex: 1;

        .ant-form-item.temperature-report_textarea {
          margin-bottom: 0;
          flex-grow: 1;

          .ant-input-textarea-affix-wrapper {
            max-height: 152px;
            height: 152px;
            padding: 8px 16px;
          }

          textarea {
            resize: none;
            padding: 0;
            font-weight: var(--font-medium);
            font-size: var(--font-size-xl);
            color: var(--color-text-primary);
          }

          .ant-input-suffix {
            .ant-input-data-count {
              bottom: 0;
              display: inline-block;
              margin-right: 8px;
            }
          }

          textarea.ant-input-disabled {
            color: var(--color-secondary);
          }

          textarea.ant-input-disabled,
          input {
            background-color: var(--color-border-secondary);
            border-color: var(--color-border-secondary);
          }

          .ant-input::placeholder {
            color: var(--color-secondary);
            font-weight: var(--font-medium);
          }
        }
      }

      .ant-form-item {
        width: 15.1vw;
        margin-bottom: 0;

        .ant-picker {
          width: 100%;
          height: 56px;
        }

        .ant-select-single,
        .ant-input-affix-wrapper {
          height: 56px;
        }

        .ant-select-selector,
        .ant-picker-large,
        .ant-input-affix-wrapper {
          padding: 16px;
        }

        .ant-form-item-label {
          padding: 0 0 4px;

          label {
            font-weight: var(--font-normal);
          }
        }

        .ant-select-selection-item,
        .ant-picker-input input,
        .ant-input-affix-wrapper input {
          font-size: var(--font-size-xl);
          font-weight: var(--font-medium);
          line-height: 24px;
        }

        .ant-input-affix-wrapper-disabled input {
          color: var(--color-secondary);
          font-size: var(--font-size-sm);
        }

        .ant-select-arrow {
          inset-inline-end: 16px;
        }

        .ant-input-disabled,
        .ant-select-disabled .ant-select-selector {
          background-color: var(--color-border-secondary);
        }
      }
    }

    .temperature-report_icon {
      width: 17.3vw;
      margin-top: 24px;
      display: flex;
      align-items: center;
      column-gap: 16px;
      flex-grow: 1;

      .temperature-report_switch {
        width: 156px;

        .ant-switch {
          margin-right: 8px;
          min-width: 36px;
          height: 20px;
          background: var(--color-border-secondary);

          .ant-switch-handle {
            width: 16px;
            height: 16px;
          }
        }

        .ant-switch.ant-switch-checked {
          background: var(--color-primary);
        }

        .ant-switch.ant-switch-checked .ant-switch-handle {
          inset-inline-start: calc(100% - 18px);
        }

        span {
          font-weight: var(--font-medium);
        }
      }

      .ant-divider-vertical {
        height: 42px;
        margin-inline: 0;
        border-inline-start: 1px solid #dee4e9;
      }

      .temperature-report_map-link {
        padding: 0 16px;

        p {
          display: flex;
          align-items: center;
          column-gap: 8px;
          cursor: pointer;

          span {
            font-weight: var(--font-medium);
            color: var(--color-primary);
          }
        }
      }
    }
  }

  .temperature-report_table {
    .ant-table-thead .ant-table-cell:first-child,
    .ant-table-tbody .ant-table-cell:first-child {
      padding: 10px !important;
    }

    .ant-table-container {
      border-start-end-radius: 0px;
      border-top: none !important;
    }

    .ant-table-tbody-virtual-scrollbar-vertical {
      background: var(--color-secondary);
      border-radius: 4px;
    }

    .ant-table-tbody-virtual-scrollbar {
      visibility: visible !important;
      right: -8px !important;

      .ant-table-tbody-virtual-scrollbar-thumb {
        background: var(--color-text-secondary) !important;
      }
    }

    .ant-table-thead {
      .ant-table-cell-scrollbar {
        background-color: var(--color-white);
        border-inline-end: none !important;
      }

      tr:first-child .ant-table-cell:nth-child(8) {
        border-start-end-radius: 8px;
      }

      tr:first-child .ant-table-cell {
        border-top: 1px solid var(--color-border-secondary);
      }

      tr:last-child .ant-table-cell,
      tr:first-child .ant-table-cell {
        border-bottom: 4px solid var(--color-border-secondary) !important;
      }

      tr:first-child .ant-table-cell:nth-child(5),
      tr:first-child .ant-table-cell:nth-child(7) {
        border-bottom: 1px solid var(--color-border-secondary) !important;
      }

      tr th {
        font-weight: var(--font-bold);
      }

      tr:first-child th:last-child {
        text-align: center;
      }
    }

    .ant-table-tbody {
      height: ${(props) => (props.listdata <= 0 ? "590px" : "unset")};

      .ant-table-row-extra {
        .ant-table-cell:first-child,
        .ant-table-cell:nth-child(3),
        .ant-table-cell:nth-child(4),
        .ant-table-cell:nth-child(5),
        .ant-table-cell:nth-child(6),
        .ant-table-cell:nth-child(19),
        .ant-table-cell:nth-child(20) {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .ant-table-cell:nth-child(2) {
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }

        .ant-table-cell:last-child {
          div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 8px;
            padding: 10px;
            line-height: 24px;
          }
        }
      }

      .ant-table-row {
        .ant-table-cell:nth-child(5),
        .ant-table-cell:nth-child(6) {
          padding: 12px 10px;
        }

        .ant-table-cell:last-child {
          div {
            height: 100%;
          }
        }

        .ant-table-cell {
          padding: 0 !important;
          line-height: ${(props) =>
            props.changeswitch === "true" ? "34px" : "50px"};
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          .temperature-report_time {
            color: var(--color-text-primary);
            font-weight: var(--font-normal);

            .temperature-report_detail-temperature {
              margin: 0 0 5px;
              width: 60px;
              line-height: 32px;
              display: block;
              color: var(--color-text-primary);
              border-radius: 2px;
            }

            .temperature-report_normal {
              background-color: var(--color-border-secondary);
            }

            .temperature-report_defrost {
              background-color: var(--color-border-secondary) !important;
              text-decoration: line-through;
            }
          }

          .temperature-report_custom {
            font-weight: var(--font-medium);
          }

          .temperature-report_custom-temperature {
            font-weight: var(--font-bold);
          }

          .temperature-report_ch {
            display: -webkit-box;
            -webkit-line-clamp: 1; /* Number of lines */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
          }

          .temperature-report_link {
            color: #0094ff;
            font-weight: var(--font-medium);
            cursor: pointer;
            padding-left: 12px;

            display: -webkit-box;
            -webkit-line-clamp: 1; /* Number of lines */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
          }

          .temperature-report_remark {
            display: -webkit-box;
            -webkit-line-clamp: 2; /* Number of lines */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
          }
        }

        .temperature-report_lower-off {
          background-color: #d7e7ff;

          .temperature-report_time {
            color: var(--color-text-primary) !important;
          }
        }

        .temperature-report_lower-on {
          .temperature-report_detail-temperature {
            background-color: #d7e7ff !important;
          }
        }

        .temperature-report_upper-off {
          background-color: #fecdca;

          .temperature-report_time {
            color: var(--color-text-primary) !important;
          }
        }

        .temperature-report_upper-on {
          .temperature-report_detail-temperature {
            background-color: #fecdca !important;
          }
        }
      }
    }
  }
`;

export const ModalEditRemarkWrapper = styled(Modal)`
  .ant-modal-content {
    padding: 0;
    background: var(--color-white);
    box-shadow: 0px 2px 8px 0px rgba(5, 40, 97, 0.08);

    border-radius: 12px;
  }

  .ant-modal-header {
    margin-bottom: 0;
    padding: 16px 24px;
    background: none;

    .ant-modal-title {
      font-weight: var(--font-bold);
      font-size: var(--font-size-base);
      line-height: 24px;
    }
  }

  .ant-modal-footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    padding: 12px 24px 24px;
    margin-top: 0;

    .ant-btn {
      width: 174px;
      height: 48px;
      box-shadow: none;
      font-weight: var(--font-bold);
      margin-inline-start: 0px !important;
    }

    .ant-btn-default {
      color: var(--color-primary);
      border-color: var(--color-primary);
    }
  }

  .ant-modal-body {
    padding: 16px 22px;
    height: 192px;
    border-bottom: 1px solid #f1f3f7;
    border-top: 1px solid #f1f3f7;

    .temperature-report_modal-edit {
      p {
        font-weight: var(--font-medium);
        font-size: var(--font-size-sm);
        color: var(--color-secondary);
        padding-bottom: 4px;
      }

      textarea {
        max-height: 132px;
        padding: 8px 16px;
        font-weight: var(--font-medium);
        color: var(--color-text-primary);
        resize: none;
      }

      .ant-input-suffix {
        .ant-input-data-count {
          bottom: 0;
          display: inline-block;
          margin-right: 8px;
        }
      }
    }

    .ant-form {
      .ant-form-item {
        .ant-form-item-label {
          padding: 0 0 4px;

          label {
            font-weight: var(--font-medium);
            font-size: var(--font-size-sm);
            color: var(--color-secondary);
          }
        }

        textarea {
          max-height: 132px;
          padding: 8px 16px;
          font-weight: var(--font-medium);
        }
      }
    }
  }
`;

export const ConfirmApproveModalWrapper = styled(Modal)`
  .ant-modal-content {
    padding: 0;
    background: var(--color-white);
    box-shadow: 0px 2px 8px 0px rgba(5, 40, 97, 0.08);

    border-radius: 12px;

    .ant-modal-close {
      inset-inline-end: 19px;
    }
  }

  .ant-modal-header {
    margin-bottom: 0;
    padding: 16px 24px;
    background: none;

    .ant-modal-title {
      font-weight: var(--font-bold);
      font-size: var(--font-size-base);
      line-height: 24px;
    }
  }

  .ant-modal-footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    padding: 12px 24px 24px;
    margin-top: 0;

    .ant-btn {
      width: 174px;
      height: 48px;
      box-shadow: none;
      font-weight: var(--font-bold);
      margin-inline-start: 0px !important;
    }

    .ant-btn-default {
      color: var(--color-primary);
      border-color: var(--color-primary);
    }
  }

  .ant-modal-body {
    padding: 0 24px;
    height: 211px;
    border-bottom: 1px solid #f1f3f7;
    border-top: 1px solid #f1f3f7;
    text-align: center;

    .temperature-report_message-approve {
      margin-top: 8px;
      font-weight: var(--font-medium);
      font-size: var(--font-size);
      line-height: 20px;
    }
  }
`;

export const ModalPeriodTimeWrapper = styled(Modal)<{
  messageerror: string;
}>`
  .ant-modal-content {
    padding: 0;
    background: var(--color-white);
    box-shadow: 0px 2px 8px 0px rgba(5, 40, 97, 0.08);
    border-radius: 12px;
  }

  .ant-modal-header {
    margin-bottom: 0;
    padding: 16px 24px;
    background: none;

    .ant-modal-title {
      font-weight: var(--font-bold);
      font-size: var(--font-size-base);
      line-height: 24px;
    }
  }

  .ant-modal-footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    padding: 12px 50px 24px;
    margin-top: 0;

    .ant-btn {
      width: 174px;
      height: 48px;
      box-shadow: none;
      font-weight: var(--font-bold);
      margin-inline-start: 0px !important;
    }

    .ant-btn-default {
      color: var(--color-primary);
      border-color: var(--color-primary);
    }
  }

  .ant-modal-body {
    padding: 0 24px;
    border-bottom: 1px solid #f1f3f7;
    border-top: 1px solid #f1f3f7;
    text-align: center;

    .period-time_message {
      margin-top: 14px;
      font-weight: var(--font-medium);
      font-size: var(--font-size-xl);
      line-height: 24px;
    }

    .period-time_datepicker {
      display: flex;
      align-items: center;
      column-gap: 4px;
      margin: ${(props) => (props.messageerror ? "16px 0 8px" : "16px 0")};

      .ant-form-item {
        margin-bottom: 0;

        .ant-form-item-label {
          font-weight: var(--font-normal);
          line-height: 16px;
          padding: 0 0 4px;

          label {
            font-size: var(--font-size-base);
          }
        }
      }

      .ant-divider-horizontal {
        min-width: 8px;
        width: 8px;
        border-block-start: 1px solid #cfd6e2;
        margin-top: 46px;
      }

      .ant-picker {
        height: 56px;

        .ant-picker-input input {
          font-size: var(--font-size-xl);
          font-weight: var(--font-medium);
          line-height: 24px;
        }

        .ant-picker-suffix {
          font-size: 24px;
          color: var(--color-secondary);
        }
      }

      .ant-picker-large {
        padding: 16px 12px;
      }
    }

    .period-time_message-error {
      font-size: var(--font-size-sm);
      font-weight: var(--font-normal);
      color: var(--color-error);
      text-align: left;
      margin-bottom: ${(props) => (props.messageerror ? "16px" : "0")};
    }

    .period-time_checkbox {
      text-align: left;
      margin-bottom: 16px;

      .ant-checkbox-wrapper:hover {
        .ant-checkbox-inner {
          border-color: var(--color-sidebar-active);
        }
      }

      .ant-checkbox-wrapper {
        align-items: center;

        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: var(--color-sidebar-active);
            border-color: var(--color-sidebar-active);
          }
        }

        span:last-child {
          font-size: var(--font-size-sm);
          color: var(--color-text-secondary);
        }
      }
    }
  }
`;
