import { ClockCircleOutlined } from "@ant-design/icons";
import { TIME_PICKER_FORMAT } from "@constants/common";
import { TimePicker } from "antd";

function TimePickerItem({
  placeholder,
  valueWatch,
  disableTime,
  ...props
}: any) {
  return (
    <TimePicker
      {...props}
      disabled={!valueWatch || disableTime}
      style={{ width: "352px" }}
      format={TIME_PICKER_FORMAT}
      size="large"
      className="device-page_modal-time"
      showNow={false}
      needConfirm={false}
      inputReadOnly={true}
      placeholder={placeholder}
      suffixIcon={
        <ClockCircleOutlined
          style={{
            color: disableTime || !valueWatch ? "#98A2B3" : "#344054",
          }}
        />
      }
    />
  );
}

export default TimePickerItem;
