export const ALL_DEVICE_CONSTANT = {
  TITLE: {
    ALL_STORE: "全店稼動状況照会",
    BTN_EXPORT: "エクスポート",
    NONE: "なし",

    OBJECT: "对象",

    // Columns
    AREA: "地区",
    STORE_CODE: "店舗コード",
    STORE_NAME: "店舗名",
    CH: "管理CH",
    MANUFACTURER: "メーカ、型式",
    PURPOSE: "用途（売場名）",
    INSTALL: "設置場所",
    ALARM_SETTING: "警報設定",
    LOWER_LIMIT: "下限",
    UPPER_LIMIT: "上限",
    NUMBER_ALARM: "警報回数 (日前 )",
    NUMBER_3: "-3",
    NUMBER_2: "-2",
    NUMBER_1: "-1",
    BATTERY_LEVEL: "電池残量",

    // Modal
    DOWNLOAD_CSV: "CSVダウンロード",
    BTN_OK: "OK",
    BTN_CANCEL: "キャンセル",
  },
  LABEL: {
    COMPANY: "会社",
    AREA: "地区",
    STORE_CODE: "店舗コード",
    STORE_NAME: "店舗名",
    MANUFACTURER: "メーカ、型式",
    PURPOSE: "用途（売場名）",
    INSTALL: "設置場所",
  },
  NAME: {
    COMPANY: "companyCode",
    AREA: "areaCode",
    STORE_CODE: "storeCode",
    STORE_NAME: "storeName",
    MANUFACTURER: "manufacturerName",
    PURPOSE: "purposeCode",
    INSTALL: "installedLocation",
    SORT_FIELD: "sortField",
    SORT_DIRECTION: "sortDirection",
  },
  PLACEHOLDER: {
    BAROJA_STORE: "バローハ店",
    INPUT: "入力",
  },
  MESSAGE: {
    NO_DATA:
      "情報を検索するために、検索条件を設定し、検索ボタンを押してください。",
    DOWNLOAD: "ダウンロードしますが、よろしいでしょうか",
  },
  TABLE_KEY: {
    AREA: "areaName",
    STORE_CODE: "storeCode",
    STORE_NAME: "storeName",
    CH: "chCode",
    MANUFACTURER: "manufacturerName",
    PURPOSE: "purposeName",
    INSTALL: "installedLocation",
    LOWER_LIMIT: "minTemp",
    UPPER_LIMIT: "maxTemp",
    NUMBER_3: "reportTempSensorThreeDaysAgo",
    NUMBER_2: "reportTempSensorTwoDaysAgo",
    NUMBER_1: "reportTempSensorOneDayAgo",
    BATTERY_LEVEL: "batteryValue",
  },
};

export const BATTERY_LEVEL_LIMIT = 20;
export const HEIGHT_TABLE = 580;
export const PAGE_INDEX = 1;
