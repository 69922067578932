import { Modal } from "antd";
import styled from "styled-components";

export const DeviceWrapper = styled.div<{
  listdata: number;
}>`
  .device-page_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;

    .device-page_btns {
      display: flex;
      align-items: center;
      column-gap: 16px;

      button {
        width: 160px;
        height: 48px;
        border-radius: 8px;
        font-weight: var(--font-medium);
        box-shadow: none;
      }

      button:last-child {
        color: var(--color-primary);
        border-color: var(--color-primary);
        font-weight: var(--font-bold);
      }
    }
  }

  .device-page_form {
    form {
      display: flex;
      column-gap: 12px;

      .ant-select-selection-placeholder,
      input::placeholder {
        font-size: var(--font-size-sm);
        font-weight: var(--font-medium);
        color: var(--color-border-primary);
      }

      .ant-btn-default {
        width: 56px;
        height: 56px;
        background: #d7e7ff !important;
        border: none;
        margin-top: 28px;
      }

      .ant-form-item {
        width: 290px;
        margin-bottom: 12px;

        .ant-select-single,
        .ant-input-lg {
          height: 56px;
        }

        .ant-select-selector,
        .ant-input-lg {
          padding: 16px;
        }

        .ant-select-selector {
          .ant-select-selection-search {
            .ant-select-selection-search-input {
              padding: 16px 6px;
              height: 54px !important;
              font-size: var(--font-size-xl);
              font-weight: var(--font-medium);
            }
          }
        }

        .ant-form-item-label {
          padding: 0 0 4px;

          label {
            font-weight: var(--font-normal);
          }
        }

        .ant-select-selection-item,
        .ant-input-lg {
          font-size: var(--font-size-xl);
          font-weight: var(--font-medium);
          line-height: 24px;
        }

        .ant-select-arrow {
          inset-inline-end: 16px;
        }
      }
    }
  }

  .device-page_table {
    .ant-table-tbody-virtual-scrollbar-vertical {
      background: var(--color-secondary);
      border-radius: 4px;
    }

    .ant-table-tbody-virtual-scrollbar {
      visibility: visible !important;
      right: -8px !important;

      .ant-table-tbody-virtual-scrollbar-thumb {
        background: var(--color-text-secondary) !important;
      }
    }

    .ant-table {
      .ant-table-thead {
        .ant-table-cell {
          border-bottom: 4px solid var(--color-border-secondary) !important;
        }
      }

      .ant-table-tbody {
        height: ${(props) => (props.listdata <= 0 ? "628px" : "unset")};

        .ant-table-tbody-virtual-holder {
          max-height: 685px !important;
        }

        .ant-table-placeholder {
          .ant-empty {
            text-align: left;
            margin-inline-start: 700px;
          }
        }

        .ant-table-row.device-page_active {
          .ant-table-cell {
            background-color: var(--color-border-primary) !important;
          }
        }

        .ant-table-row {
          .device-page_mobile-phone {
            padding: 16px 10px;
          }

          .ant-table-cell {
            .device-page_ellipsis {
              display: -webkit-box;
              -webkit-line-clamp: 1; /* Number of lines */
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
            }

            .device-page_modal-btns {
              display: flex;
              justify-content: center;
              column-gap: 26px;

              .ant-btn-default {
                padding: 0;
                height: unset;
                border-color: transparent;
                box-shadow: none;
                background: none;
              }

              .ant-btn-default:disabled {
                border-color: transparent;
                background: transparent;
              }
            }
          }
        }
      }
    }
  }
`;

export const ModalWrapper = styled(Modal)`
  .ant-modal-header {
    margin-bottom: 0;
    padding: 16px 24px;
    background: none;

    .ant-modal-title {
      display: flex;
      align-items: center;
      column-gap: 12px;

      button {
        width: 48px;
        height: 48px;
        background-color: #edeff1;
        border-radius: 100%;
        border: none;
        position: relative;
        cursor: pointer;

        svg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      span {
        font-weight: var(--font-bold);
        font-size: var(--font-size-base);
        line-height: 24px;
      }
    }
  }

  .ant-modal-content {
    padding: 0;
    background: var(--color-white);
    box-shadow: 0px 2px 8px 0px rgba(5, 40, 97, 0.08);
    border-radius: 12px;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    column-gap: 16px;
    padding: 12px 24px 24px;
    margin-top: 0;
    z-index: 10;
    position: relative;

    .ant-btn {
      width: 368px;
      height: 48px;
      box-shadow: none;
      font-weight: var(--font-bold);
      margin-inline-start: 0;
    }

    .ant-btn-default {
      color: var(--color-primary);
      border-color: var(--color-primary);
    }

    .ant-btn-primary {
      margin-inline-start: 0 !important;
    }
  }

  .ant-modal-body {
    padding: 16px 24px;
    border-bottom: 1px solid var(--color-border-secondary);
    border-top: 1px solid var(--color-border-secondary);

    .ant-form {
      .device-page_modal-form-flex {
        display: flex;
        justify-content: space-between;
        column-gap: 16px;
      }

      .ant-select-selection-placeholder,
      .ant-input::placeholder,
      input::placeholder {
        font-size: var(--font-size-sm) !important;
        font-weight: var(--font-medium) !important;
        color: var(--color-border-primary) !important;
      }

      .ant-form-item {
        margin-bottom: 16px;
      }

      .ant-form-item-label {
        padding: 0 0 4px;

        label {
          font-weight: var(--font-normal);
        }
      }

      .ant-picker {
        width: 368px;
        height: 56px;
      }

      .ant-select-lg,
      .ant-input-lg,
      .ant-input-number-lg {
        height: 56px;
      }

      .ant-select-in-form-item {
        .ant-select-selector {
          padding: 16px;

          .ant-select-selection-item {
            font-size: var(--font-size-xl);
            font-weight: var(--font-medium);
            line-height: 24px;
          }

          .ant-select-selection-search {
            .ant-select-selection-search-input {
              padding: 16px 6px;
              height: 54px !important;
              font-size: var(--font-size-xl);
              font-weight: var(--font-medium);
            }
          }
        }

        .ant-select-arrow {
          inset-inline-end: 16px;
        }
      }

      .ant-input-lg {
        padding: 16px;
        font-size: var(--font-size-xl);
        font-weight: var(--font-medium);
        line-height: 24px;
      }

      .ant-picker-large {
        padding: 16px;

        input {
          font-size: var(--font-size-xl);
          font-weight: var(--font-medium);
          line-height: 24px;
        }
      }

      textarea {
        max-height: 100px;
        padding: 8px 16px;
        font-weight: var(--font-medium);
        color: var(--color-text-primary);
        resize: none;
      }

      .ant-input-suffix {
        .ant-input-data-count {
          bottom: 0;
          display: inline-block;
          margin-right: 8px;
        }
      }

      /* Disabled */
      .ant-select-disabled .ant-select-selector,
      .ant-input-disabled,
      .ant-input-number-disabled {
        color: var(--color-secondary);
        background: var(--color-border-primary);
      }

      .ant-picker-disabled {
        background: var(--color-border-primary);
      }

      .ant-picker-disabled input {
        color: var(--color-secondary);
      }

      /* FLEX */
      .device-page_modal-form-left,
      .device-page_modal-form-right {
        .ant-input-number-lg {
          width: 368px;

          .ant-input-number-input {
            height: 54px;
            padding: 16px;
            font-size: var(--font-size-xl);
            font-weight: var(--font-medium);
          }
        }

        .ant-select-in-form-item {
          width: 368px;
        }

        .device-page_modal-time {
          .ant-picker-suffix {
            font-size: var(--font-size-xl);
          }
        }
      }
    }
  }
`;

export const ModalDeleteWrapper = styled(Modal)`
  .ant-modal-content {
    padding: 0;
    background: var(--color-white);
    box-shadow: 0px 2px 8px 0px rgba(5, 40, 97, 0.08);
    border-radius: 12px;
  }

  .ant-modal-header {
    margin-bottom: 0;
    padding: 16px 24px;
    background: none;

    .ant-modal-title {
      font-weight: var(--font-bold);
      font-size: var(--font-size-base);
      line-height: 24px;
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    column-gap: 16px;
    padding: 12px 24px 24px;
    margin-top: 0;

    .ant-btn {
      width: 202px;
      height: 48px;
      box-shadow: none;
      font-weight: var(--font-bold);
      margin-inline-start: 0;
    }

    .ant-btn-default {
      color: var(--color-primary);
      border-color: var(--color-primary);
    }
  }

  .ant-modal-body {
    padding: 0 24px;
    border-bottom: 1px solid #f1f3f7;
    border-top: 1px solid #f1f3f7;
    text-align: center;

    .device-page_modal-delete {
      margin: 30px 0 24px;

      p:first-child {
        color: var(--color-text-secondary);
        font-weight: var(--font-normal);
        margin-bottom: 16px;
      }

      p:last-child {
        font-weight: var(--font-medium);
        font-size: var(--font-size-sm);
        line-height: 20px;
      }
    }

    .device-page_modal-regist-success,
    .device-page_modal-confirm {
      p {
        font-weight: var(--font-medium);
        font-size: var(--font-size-base);
        line-height: 24px;
      }
    }

    .device-page_modal-regist-success {
      margin: 24px 0 16px;
    }

    .device-page_modal-confirm {
      p {
        margin: 48px 0 40px;
      }
    }
  }
`;
