import { useAppDispatch, useAppSelector } from "@config/hooks";
import { images, svgs } from "@constants";
import { InfoCallIcon, InfoSMSIcon, InfoUserIcon } from "@constants/svgs";
import { Divider, Dropdown, Image } from "antd";
import { DropdownWrapper, ModalLogoutWrapper } from "./HeaderStyled";
import { authActions } from "@redux/slices/authSlice";
import { useState } from "react";

function DropdownButton() {
  const [modalLogout, setModalLogout] = useState(false);
  const [loading, setIsLoading] = useState(false);

  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    setIsLoading(true);
    dispatch(
      authActions.logout({
        data: "",
        onFinal: () => setIsLoading(false),
      })
    );
  };

  return (
    currentUser && (
      <>
        <DropdownWrapper>
          <Dropdown
            overlayClassName="ant-dropdown-container"
            dropdownRender={() => (
              <div className="dropdown-custom">
                <div className="dropdown-custom_top">
                  <span className="dropdown-custom_top-title">MENTEX</span>
                  <span
                    onClick={() => setModalLogout(true)}
                    className="dropdown-custom_top-btn"
                  >
                    ログアウト
                  </span>
                </div>
                <Divider type="horizontal" />
                <div className="dropdown-custom_bottom">
                  <div>
                    <Image
                      src={svgs.user}
                      alt="avatar"
                      preview={false}
                      className="avatar-img"
                    />
                  </div>
                  <div className="dropdown-custom_bottom-info">
                    <p className="dropdown-custom_bottom-info-id">
                      {currentUser.username}
                    </p>
                    <p className="dropdown-custom_bottom-info-common">
                      <InfoUserIcon />
                      <span>{currentUser.name}</span>
                    </p>
                    <p className="dropdown-custom_bottom-info-common">
                      <InfoCallIcon />
                      <span>{currentUser.phoneNumber}</span>
                    </p>
                    <p className="dropdown-custom_bottom-info-common">
                      <InfoSMSIcon />
                      <span>{currentUser.email}</span>
                    </p>
                  </div>
                </div>
              </div>
            )}
            trigger={["click"]}
          >
            <span onClick={(e) => e.preventDefault()}>
              <Image
                src={svgs.user}
                alt="avatar"
                preview={false}
                className="avatar-img"
              />
              <span className="avatar-name">{currentUser.username}</span>
            </span>
          </Dropdown>
        </DropdownWrapper>

        <ModalLogoutWrapper
          centered
          confirmLoading={loading}
          closeIcon={false}
          open={modalLogout}
          title="確認"
          onOk={() => {
            handleLogout();
          }}
          onCancel={() => setModalLogout(false)}
          maskClosable={false}
          okText="ログアウト"
          cancelText="キャンセル"
          width={416}
        >
          <img src={images.logout} alt="modal_logout" />
          <p className="logout-message">ログアウトしてもよろしいでしょうか</p>
        </ModalLogoutWrapper>
      </>
    )
  );
}

export { DropdownButton };
