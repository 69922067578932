import { AppContext } from "@layouts/LayoutAdmin/LayoutAdmin";
import { TSelectValue } from "@models/common";
import { EUserRoles } from "@models/user";
import { Form } from "antd";
import { Table } from "antd/lib";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { EModalType } from "types/others";
import { getColumns } from "../columns";
import { REGEX_COMPANY_CODE, COMPANY_CONSTANT } from "../constant";
import useCompanyApi from "./useCompanyApi";
import { images } from "@constants";
import {
  TFormCompanyReq,
  TFormModalRegisterReq,
  TFormModalRes,
  TListCompanyReq,
  TListCompanyTable,
} from "@models/company";

const { NAME, TITLE, MESSAGE } = COMPANY_CONSTANT;

function useCompanyPage() {
  const [form] = Form.useForm();
  const [formModal] = Form.useForm();
  const companyCodeWatch = Form.useWatch(NAME.COMPANY_CODE, formModal);
  const companyNameWatch = Form.useWatch(NAME.COMPANY_NAME, formModal);

  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingCompany, setLoadingCompany] = useState(true);
  const [listCompany, setListCompany] = useState<TListCompanyTable[]>([]);
  const [companies, setCompanies] = useState<TSelectValue[]>([]);
  const [hasNext, setHasNext] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [rowKey, setRowKey] = useState<number | null>(null);
  const [recordId, setRecordId] = useState("");

  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingExist, setLoadingExist] = useState(false);
  const [companiesModal, setCompaniesModal] = useState<TSelectValue[]>([]);
  const [company, setCompany] = useState<TListCompanyTable | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalType, setModalType] = useState<EModalType | null | string>(null);
  const [modalTypeConfirm, setModalTypeConfirm] = useState<EModalType | null>(
    null
  );
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const companyParam = searchParams.get(NAME.COMPANY) || "";

  const { handler, effect } = useCompanyApi();
  const { role } = useContext(AppContext);
  const roleAdmin = role ? role[0] === EUserRoles.ADMIN : false;

  const disableBtnOther = roleAdmin ? false : true;

  const disableBtnNext =
    modalType !== EModalType.DETAIL &&
    (!companyCodeWatch ||
      !companyNameWatch ||
      (companyNameWatch && !companyNameWatch.replace(/\s/g, "").length));

  const disableFields =
    modalType === EModalType.CONFIRM_REGISTRATION ||
    modalType === EModalType.CONFIRM_UPDATE ||
    modalType === EModalType.DETAIL;

  const disableFieldsEdit = modalType === EModalType.UPDATE || disableFields;

  const tblRef: Parameters<typeof Table>[0]["ref"] = useRef(null);

  const handleSetFormValues = (record: TFormModalRes) => {
    setRecordId(record.id);

    formModal.setFieldValue(NAME.COMPANY_CODE, record.companyCode);
    formModal.setFieldValue(NAME.COMPANY_NAME, record.companyName);
  };

  const handleSetSearchParams = (values: TFormCompanyReq) => {
    searchParams.set(NAME.COMPANY, values.companyCode || "");

    setSearchParams(searchParams, { replace: true });
  };

  // Modal success
  const handleCloseModalSuccess = () => {
    setModalSuccess(false);
    setModalType(null);
    setRowKey(null);
  };

  const handleModalSuccess = () => {
    setModalSuccess(true);
    setModalType(null);

    if (modalTypeConfirm === EModalType.CONFIRM_REGISTRATION_FINAL) {
      setModalType(EModalType.SUCCESS_REGISTRATION);
    }

    if (modalTypeConfirm === EModalType.CONFIRM_UPDATE_FINAL) {
      setModalType(EModalType.SUCCESS_UPDATE);
    }
  };

  // Modal fail
  const handleModalFail = () => {
    setModalSuccess(true);

    if (modalTypeConfirm === EModalType.CONFIRM_REGISTRATION_FINAL) {
      setModalType(EModalType.FAIL_REGISTRATION);
    }

    if (modalTypeConfirm === EModalType.CONFIRM_UPDATE_FINAL) {
      setModalType(EModalType.FAIL_UPDATE);
    }
  };

  const handleCommonError = () => {
    setModalConfirm(false);
    setModalType(null);
    setCompany(null);
    setRowKey(null);
    setModalOpen(false);
  };

  const handleDeleteFail = () => {
    setModalSuccess(true);
    setModalConfirm(false);
    setModalDelete(false);
    setRowKey(null);
    setModalType(TITLE.NOT_FOUND_COMPANY);
  };

  // Modal confirm final
  const handleOpenModalConfirmFinal = () => {
    setModalConfirm(true);
    if (modalType === EModalType.CONFIRM_REGISTRATION) {
      setModalTypeConfirm(EModalType.CONFIRM_REGISTRATION_FINAL);
    }

    if (modalType === EModalType.CONFIRM_UPDATE) {
      setModalTypeConfirm(EModalType.CONFIRM_UPDATE_FINAL);
    }
  };

  const handleOkModalConfirmFinal = () => {
    handleOkModal();
  };

  const handleCloseModalConfirmFinal = () => setModalConfirm(false);

  // Modal registration
  const handleOpenModalRegistration = () => {
    setModalOpen(true);
    setModalType(EModalType.REGISTRATION);
  };

  // Modal detail
  const handleOpenModalDetail = (record: TListCompanyTable) => {
    setModalOpen(true);
    setModalType(EModalType.DETAIL);
    setRowKey(record.key);

    handler.detailCompany(record.id, handleSetFormValues, setLoadingModal);
  };

  // Modal update
  const handleOpenModalUpdate = (record: TListCompanyTable, id: string) => {
    setModalOpen(true);
    setModalType(EModalType.UPDATE);
    setRowKey(record.key);

    handler.detailCompany(id, handleSetFormValues, setLoadingModal);
  };

  const handleCloseModal = () => {
    setModalType(null);
    setCompany(null);
    setRowKey(null);
    setModalOpen(false);
  };

  const handleFormResetFieldError = () => {
    formModal.setFields([
      {
        name: [NAME.COMPANY_CODE],
        errors: [],
      },
      {
        name: [NAME.COMPANY_NAME],
        errors: [],
      },
    ]);
  };

  const handleValidateForm = () => {
    handleFormResetFieldError();

    const validateCompanyCode = companyCodeWatch
      ? REGEX_COMPANY_CODE.test(companyCodeWatch.trim())
      : "";
    const _validateCompanyCode =
      !validateCompanyCode && validateCompanyCode !== "";

    let checkError = false;

    if (_validateCompanyCode) {
      formModal.setFields([
        {
          name: [NAME.COMPANY_CODE],
          errors: [MESSAGE.FIELD_INVALID],
        },
      ]);
      checkError = true;
    }

    if (!checkError) {
      formModal.setFields([{ name: [NAME.COMPANY_CODE], errors: [] }]);

      const companyCode = formModal.getFieldValue(NAME.COMPANY_CODE);
      if (modalType === EModalType.REGISTRATION) {
        handler.checkExistCompany(
          { companyCode, typeCheck: "CREATE" },
          formModal,
          EModalType.CONFIRM_REGISTRATION,
          setModalType,
          setLoadingExist
        );
      }

      if (modalType === EModalType.UPDATE) {
        handler.checkExistCompany(
          { companyCode, companyId: recordId, typeCheck: "UPDATE" },
          formModal,
          EModalType.CONFIRM_UPDATE,
          setModalType,
          setLoadingExist
        );
      }
    }
  };

  // Modal delete company
  const handleOpenModalDelete = (record: TListCompanyTable) => {
    setCompany(record);
    setRowKey(record.key);
    setModalDelete(true);
  };

  const handleCloseModalDelete = () => {
    setCompany(null);
    setRowKey(null);
    setModalDelete(false);
  };

  const handleFinish = (values: TListCompanyReq) => {
    // setListCompany([]);
    tblRef.current?.scrollTo({ top: 0 });
    setPageIndex(1);

    getListCompanySearch({
      ...values,
      pageIndex: 0,
    });
  };

  const handleScroll = (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (
      scrollTop + clientHeight >= scrollHeight - 5 &&
      !loadingPage &&
      hasNext
    ) {
      setPageIndex((prev) => prev + 1);
      getListCompanyScroll({
        companyCode: companyParam,
        pageIndex: pageIndex + 1,
      });
    }
  };

  const handleOkModalSuccess = (_data: TFormModalRes) => {
    setModalOpen(false);
    handleModalSuccess();
    handleCloseModalConfirmFinal();
    // setListCompany([]);
    tblRef.current?.scrollTo({ top: 0 });
    setPageIndex(1);
    getListCompanySearch({
      companyCode: companyParam,
      pageIndex: 0,
    });
  };

  const renderTextModal = useMemo(() => {
    switch (modalType) {
      case EModalType.REGISTRATION:
        return {
          title: TITLE.COMPANY_REGISTRATION,
          okText: TITLE.BTN_NEXT,
          message: "",
          image: undefined,
        };
      case EModalType.DETAIL:
        return {
          title: TITLE.COMPANY_DETAIL,
          okText: TITLE.BTN_CLOSE,
          message: "",
          image: undefined,
        };
      case EModalType.UPDATE:
        return {
          title: TITLE.COMPANY_UPDATE,
          okText: TITLE.BTN_NEXT,
          message: "",
          image: undefined,
        };

      case EModalType.CONFIRM_REGISTRATION:
        return {
          title: TITLE.COMPANY_REGISTRATION,
          okText: TITLE.BTN_MODAL_REGIST,
          message: "",
          image: undefined,
        };
      case EModalType.CONFIRM_UPDATE:
        return {
          title: TITLE.COMPANY_UPDATE,
          okText: TITLE.OK_UPDATE,
          message: "",
          image: undefined,
        };

      case EModalType.SUCCESS_REGISTRATION:
        return {
          title: TITLE.COMPANY_LIST_REGISTRATION,
          okText: TITLE.BTN_OK,
          message: MESSAGE.SUCCESS_REGISTER,
          image: images.confirmApproveTemperature,
        };
      case EModalType.SUCCESS_UPDATE:
        return {
          title: TITLE.COMPANY_LIST_UPDATE,
          okText: TITLE.BTN_OK,
          message: MESSAGE.SUCCESS_UPDATE,
          image: images.confirmApproveTemperature,
        };
      case EModalType.SUCCESS_DELETE:
        return {
          title: TITLE.SUCCESS_DELETE,
          okText: TITLE.BTN_OK,
          message: MESSAGE.SUCCESS_DELETE,
          image: images.confirmApproveTemperature,
        };

      case EModalType.FAIL_REGISTRATION:
        return {
          title: TITLE.FAIL_REGISTER,
          okText: TITLE.BTN_OK,
          message: MESSAGE.FAIL_REGISTER,
          image: images.importDataFail,
        };
      case EModalType.FAIL_UPDATE:
        return {
          title: TITLE.FAIL_REGISTER,
          okText: TITLE.BTN_OK,
          message: MESSAGE.FAIL_UPDATE,
          image: images.importDataFail,
        };
      case EModalType.FAIL_DELETE:
        return {
          title: TITLE.FAIL_DELETE,
          okText: TITLE.BTN_OK,
          message: MESSAGE.FAIL_DELETE,
          image: images.importDataFail,
        };
      case TITLE.NOT_FOUND_COMPANY:
        return {
          title: TITLE.FAIL_DELETE,
          okText: TITLE.BTN_OK,
          message: MESSAGE.NOT_FOUND_COMPANY,
          image: images.importDataFail,
        };

      default:
        return {
          title: "",
          okText: "",
          message: "",
          image: undefined,
        };
    }
  }, [modalType]);

  const renderTextModalConfirm = useMemo(() => {
    switch (modalTypeConfirm) {
      case EModalType.CONFIRM_REGISTRATION_FINAL:
        return {
          title: TITLE.CONFIRM_REGISTRATION_FINAL,
          okText: TITLE.BTN_OK,
          message: MESSAGE.CONFIRM_REGISTRATION_FINAL,
        };
      case EModalType.CONFIRM_UPDATE_FINAL:
        return {
          title: TITLE.CONFIRM_UPDATE_FINAL,
          okText: TITLE.BTN_OK,
          message: MESSAGE.CONFIRM_UPDATE_FINAL,
        };

      default:
        return {
          title: "",
          okText: "",
          message: "",
        };
    }
  }, [modalTypeConfirm]);

  // API
  const handleOkModalDelete = async () => {
    if (company) {
      handler.deleteCompany(
        company.id,
        form.getFieldValue(NAME.COMPANY),
        pageIndex,
        listCompany,
        setListCompany,
        setLoadingPage,
        setModalSuccess,
        setModalType,
        handleCloseModalDelete,
        handleDeleteFail,
        setHasNext
      );
    }
  };

  const handleOkModal = () => {
    const params: TFormModalRegisterReq = {
      companyCode: companyCodeWatch,
      companyName: companyNameWatch,
    };

    if (modalTypeConfirm === EModalType.CONFIRM_REGISTRATION_FINAL) {
      handler.registrationCompany(
        params,
        handleOkModalSuccess,
        setLoadingPage,
        handleModalFail,
        handleCommonError
      );
    }

    if (modalTypeConfirm === EModalType.CONFIRM_UPDATE_FINAL) {
      handler.updateCompany(
        { ...params, id: recordId },
        handleOkModalSuccess,
        setLoadingPage,
        handleModalFail,
        handleCommonError
      );
    }
  };

  const getListCompanySearch = async (payload: TListCompanyReq) => {
    handler.getListCompanySearch(
      payload,
      setLoadingPage,
      setListCompany,
      handleSetSearchParams,
      setPageIndex,
      setHasNext
    );
  };

  const getListCompanyScroll = async (payload: TListCompanyReq) => {
    handler.getListCompanyScroll(
      payload,
      listCompany,
      pageIndex,
      setLoadingPage,
      setListCompany,
      setPageIndex,
      setHasNext
    );
  };

  const fetchInitialApis = async () => {
    handler.fetchInitialApis(
      pageIndex,
      setLoadingPage,
      setLoadingCompany,
      handleSetSearchParams,
      setCompanies,
      setListCompany,
      setCompaniesModal,
      setHasNext,
      setPageIndex
    );
  };

  const fetchApiRedirect = async (payload: TFormCompanyReq) => {
    handler.fetchApiRedirect(
      payload,
      pageIndex,
      setLoadingPage,
      setLoadingCompany,
      handleSetSearchParams,
      setCompanies,
      setListCompany,
      setCompaniesModal,
      setHasNext,
      setPageIndex
    );
  };

  const columns = useMemo(() => {
    const columns = getColumns({
      disableBtnOther,
      handleOpenModalDelete,
      handleOpenModalDetail,
      handleOpenModalUpdate,
    });
    return columns;
  }, [companiesModal]);

  // EFFECT
  useEffect(() => {
    if (companyParam) {
      fetchApiRedirect({
        companyCode: companyParam,
      });
    } else {
      fetchInitialApis();
    }
  }, []);

  useEffect(() => {
    effect.effectCompany(companyParam, form);
  }, [companies]);

  return {
    state: {
      columns,
      listCompany,
      company,
      loadingPage,
      rowKey,
      tblRef,
      form,
      companies,
      loadingCompany,

      disableBtnOther,
      disableBtnNext,
      disableFields,
      disableFieldsEdit,

      loadingExist,
      loadingModal,
      companiesModal,
      formModal,
      renderTextModal,
      renderTextModalConfirm,
      modalSuccess,
      modalOpen,
      modalDelete,
      modalType,
      modalConfirm,
    },
    handler: {
      setModalType,
      handleFinish,
      handleScroll,
      handleOpenModalRegistration,
      handleCloseModal,
      handleCloseModalDelete,
      handleOkModalDelete,
      handleOkModal,
      handleCloseModalSuccess,
      handleOpenModalConfirmFinal,
      handleValidateForm,
      handleCloseModalConfirmFinal,
      handleOkModalConfirmFinal,
    },
  };
}

export default useCompanyPage;
