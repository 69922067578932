import { TAxiosResponse } from "types";
import { axiosInstance } from "./axiosInstance";
import { TCommonError } from "@models/common";

const formDataType = "multipart/form-data";

const masterDataApi = {
  importCompany: async (payload: File) => {
    const url = "/company/import";
    const formData = new FormData();
    formData.append("masterCompanyFile", payload);

    const response: TAxiosResponse<TMasterDataCompanyRes[], TCommonError> =
      await axiosInstance.post(url, formData, {
        headers: {
          "Content-type": formDataType,
        },
      });

    return response;
  },

  importArea: async (payload: File) => {
    const url = "/area/import";
    const formData = new FormData();
    formData.append("masterAreaFile", payload);

    const response: TAxiosResponse<TMasterDataAreaRes[], TCommonError> =
      await axiosInstance.post(url, formData, {
        headers: {
          "Content-type": formDataType,
        },
      });

    return response;
  },

  importManufacturer: async (payload: File) => {
    const url = "/manufacturer/import";
    const formData = new FormData();
    formData.append("masterManufacturerFile", payload);

    const response: TAxiosResponse<TMasterDataManufacturerRes[], TCommonError> =
      await axiosInstance.post(url, formData, {
        headers: {
          "Content-type": formDataType,
        },
      });

    return response;
  },

  importPurpose: async (payload: File) => {
    const url = "/purpose/import";
    const formData = new FormData();
    formData.append("masterPurposeFile", payload);

    const response: TAxiosResponse<TMasterDataPurposeRes[], TCommonError> =
      await axiosInstance.post(url, formData, {
        headers: {
          "Content-type": formDataType,
        },
      });

    return response;
  },

  importUser: async (payload: File) => {
    const url = "/user/import";
    const formData = new FormData();
    formData.append("masterUserFile", payload);

    const response: TAxiosResponse<TMasterDataUserRes[], TCommonError> =
      await axiosInstance.post(url, formData, {
        headers: {
          "Content-type": formDataType,
        },
        timeout: 120000
      });

    return response;
  },

  importStore: async (payload: File) => {
    const url = "/store/import";
    const formData = new FormData();
    formData.append("masterStoreFile", payload);

    const response: TAxiosResponse<TMasterDataSensorRes[], TCommonError> =
      await axiosInstance.post(url, formData, {
        headers: {
          "Content-type": formDataType,
        },
      });

    return response;
  },

  importSensor: async (payload: File) => {
    const url = "/sensor/import";
    const formData = new FormData();
    formData.append("masterSensorFile", payload);

    const response: TAxiosResponse<TMasterDataSensorRes[], TCommonError> =
      await axiosInstance.post(url, formData, {
        headers: {
          "Content-type": formDataType,
        },
      });

    return response;
  },

  importDepartment: async (payload: File) => {
    const url = "/department/import";
    const formData = new FormData();
    formData.append("masterDepartmentFile", payload);

    const response: TAxiosResponse<TMasterDataDepartmentRes[], TCommonError> =
      await axiosInstance.post(url, formData, {
        headers: {
          "Content-type": formDataType,
        },
      });

    return response;
  },
};

export { masterDataApi };

type TMasterDataCompanyRes = {
  companyCode: string;
  companyName: string;
};

type TMasterDataAreaRes = {
  areaCode: string;
  areaName: string;
};

type TMasterDataDepartmentRes = {
  departmentCode: "string";
  departmentName: "string";
};

type TMasterDataManufacturerRes = {
  code: "string";
  name: "string";
  description: "string";
};

type TMasterDataPurposeRes = {
  id: "3fa85f64-5717-4562-b3fc-2c963f66afa6";
  purposeCode: "string";
  purposeName: "string";
  departmentCode: "string";
  departmentName: "string";
  companyCode: "string";
  minTemp: "string";
  maxTemp: "string";
};

type TMasterDataSensorRes = {
  storeCode: "string";
  storeName: "string";
};

type TMasterDataUserRes = {
  userName: "string";
  name: "string";
  companyCode: "string";
  storeCode: "string";
  role: "string";
};
