import { DATE_FORMAT, TIME_PICKER_FORMAT } from "@constants/common";
import dayjs from "dayjs";

const handleRenderFieldDate = (date: string) => {
  return date ? dayjs(date, DATE_FORMAT) : undefined;
};

const handleRenderFieldDateSeason = (date: string, format: string) => {
  return date ? dayjs(date, format) : undefined;
};

const handleRenderFieldInput = (value: any) => {
  return value || "";
};

const handleRenderFieldTime = (time: string) => {
  return time ? dayjs(time, TIME_PICKER_FORMAT) : undefined;
};

const handleFormatFieldDate = (date: any, format: string) => {
  return date ? dayjs(date).format(format) : "";
};

const handleFormatFieldTime = (time: any) => {
  return time ? dayjs(time).format(TIME_PICKER_FORMAT) : "";
};

const handleFormatTemperatureType = (val: string) => {
  switch (val) {
    case "冷蔵":
      return "1";
    case "冷凍":
      return "2";
    case "非冷":
      return "3";
    case "使用しない":
      return "9";
    default:
      return "";
  }
};

const handleFormatLocation = (val: string) => {
  switch (val) {
    case "売場":
      return "0";
    case "バック":
      return "1";
    default:
      return "";
  }
};

export {
  handleRenderFieldDateSeason,
  handleRenderFieldInput,
  handleRenderFieldDate,
  handleRenderFieldTime,
  handleFormatFieldDate,
  handleFormatFieldTime,
  handleFormatTemperatureType,
  handleFormatLocation,
};
