import { all } from "redux-saga/effects";
import { authWatcher } from "./authSaga";
import { commonWatcher } from "./commonSaga";

export function* rootSaga() {
  yield all([
    // Common
    ...commonWatcher,
    ...authWatcher,
  ]);
}
