type TMessageErrorProps = {
  messageError: string;
};

function MessageError({ messageError }: TMessageErrorProps) {
  return messageError ? (
    <p className="period-time_message-error">{messageError}</p>
  ) : null;
}

export default MessageError;
