import { images } from "@constants";
import { AppContext } from "@layouts/LayoutAdmin/LayoutAdmin";
import { TSelectValue } from "@models/common";
import {
  TFormDeviceReq,
  TFormModalDeviceRes,
  TFormRegisterDeviceReq,
  TListDevice,
  TListDeviceReq,
  TPurposesRes,
} from "@models/device";
import { EUserRoles } from "@models/user";
import { DatePicker, Form } from "antd";
import { GetProps, Table } from "antd/lib";
import dayjs from "dayjs";
import ipRegex from "ip-regex";
import _ from "lodash";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { EModalType } from "types/others";
import { getColumns } from "../columns";
import {
  DATE_MONTH_FORMAT,
  DEVICE_CONSTANT,
  LIST_KEYS_ALLOW_PURPOSE,
} from "../constant";
import {
  handleFormatFieldDate,
  handleFormatFieldTime,
  handleFormatLocation,
  handleFormatTemperatureType,
  handleRenderFieldDate,
  handleRenderFieldDateSeason,
  handleRenderFieldInput,
  handleRenderFieldTime,
} from "../utils/helpers";
import useDeviceApi from "./useDeviceApi";
import {
  DATE_FORMAT,
  OPTION_EMPTY,
  REGEX_CHARACTER_NUMBER,
  REGEX_REPLACE_TEMPERATURE,
} from "@constants/common";

const { NAME, TITLE, MESSAGE } = DEVICE_CONSTANT;

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

export const panelStyle: React.CSSProperties = {
  marginBottom: 16,
  borderRadius: 8,
};

function useDevicePage() {
  const [form] = Form.useForm();
  const [formModal] = Form.useForm();
  const companyCodeWatch = Form.useWatch(NAME.COMPANY_MODAL, formModal);
  const storeCodeWatch = Form.useWatch(NAME.STORE_MODAL, formModal);
  const chCodeWatch = Form.useWatch(NAME.CH_MODAL, formModal);
  const manufacturerModelWatch = Form.useWatch(
    NAME.MANUFACTURER_MODEL_MODAL,
    formModal
  );
  const batteryWatch = Form.useWatch(NAME.BATTERY, formModal);
  const temperatureTypeWatch = Form.useWatch(
    NAME.TEMPERATURE_DISTINCTION,
    formModal
  );
  const installedLocationWatch = Form.useWatch(
    NAME.INSTALL_LOCATION,
    formModal
  );
  const purposeCodeWatch = Form.useWatch(NAME.PURPOSE_CODE, formModal);
  const minTempWatch = Form.useWatch(NAME.LOWER_LIMIT, formModal);
  const maxTempWatch = Form.useWatch(NAME.UPPER_LIMIT, formModal);
  const springStartDateWatch = Form.useWatch(NAME.START_DATE_SPRING, formModal);
  const summerStartDateWatch = Form.useWatch(NAME.START_DATE_SUMMER, formModal);
  const autumnStartDateWatch = Form.useWatch(NAME.START_DATE_AUTUMN, formModal);
  const winterStartDateWatch = Form.useWatch(NAME.START_DATE_WINTER, formModal);

  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingCompany, setLoadingCompany] = useState(true);
  const [loadingStore, setLoadingStore] = useState(false);
  const [loadingCh, setLoadingCh] = useState(false);
  const [listDevice, setListDevice] = useState<TListDevice[]>([]);
  const [companies, setCompanies] = useState<TSelectValue[]>([]);
  const [stores, setStores] = useState<TSelectValue[]>([]);
  const [chCode, setChCode] = useState<TSelectValue[]>([]);
  const [manufacturers, setManufacturers] = useState<TSelectValue[]>([]);
  const [hasNext, setHasNext] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [rowKey, setRowKey] = useState<number | null>(null);
  const [device, setDevice] = useState<any>(null);
  const [recordId, setRecordId] = useState("");
  // const [loadedIds, setLoadedIds] = useState<Set<string>>(new Set());

  const [purposesModal, setPurposesModal] = useState<TPurposesRes[]>([]);
  const [companiesModal, setCompaniesModal] = useState<TSelectValue[]>([]);
  const [manufacturersModal, setManufacturersModal] = useState<TSelectValue[]>(
    []
  );
  const [storesModal, setStoresModal] = useState<TSelectValue[]>([]);
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingStoreModal, setLoadingStoreModal] = useState(false);
  const [loadingExist, setLoadingExist] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalType, setModalType] = useState<EModalType | null | string>(null);
  const [modalTypeConfirm, setModalTypeConfirm] = useState<EModalType | null>(
    null
  );
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [keyPurpose, setKeyPurpose] = useState<string[]>(["1"]);
  const [keySeason, setKeySeason] = useState<string[]>(["1", "2", "3", "4"]);

  const [searchParams, setSearchParams] = useSearchParams();
  const companyParam = searchParams.get(NAME.COMPANY_CODE) || "";
  const storeParam = searchParams.get(NAME.STORE_CODE) || "";
  const chCodeParam = searchParams.get(NAME.CH_CODE) || "";
  const manufacturerCodeParam = searchParams.get(NAME.MANUFACTURER_CODE) || "";
  const modelCodeParam = searchParams.get(NAME.MANUFACTURER_MODEL) || "";

  const { handler, effect } = useDeviceApi();
  const { role } = useContext(AppContext);
  const roleAdmin = role ? role[0] === EUserRoles.ADMIN : false;

  const tblRef: Parameters<typeof Table>[0]["ref"] = useRef(null);

  const disableBtnOther = roleAdmin ? false : true;

  const disableFields =
    modalType === EModalType.CONFIRM_REGISTRATION ||
    modalType === EModalType.CONFIRM_UPDATE ||
    modalType === EModalType.DETAIL;

  const disableFieldsEdit = modalType === EModalType.UPDATE || disableFields;

  const disableBtnNext =
    modalType !== EModalType.DETAIL &&
    (!companyCodeWatch ||
      !storeCodeWatch ||
      !manufacturerModelWatch ||
      (manufacturerModelWatch &&
        !manufacturerModelWatch.replace(/\s/g, "").length) ||
      !batteryWatch ||
      !chCodeWatch ||
      !installedLocationWatch ||
      (chCodeWatch && !chCodeWatch.replace(/\s/g, "").length) ||
      !temperatureTypeWatch ||
      !manufacturerModelWatch ||
      (keyPurpose[0] === "1" && !purposeCodeWatch) ||
      (keyPurpose[0] === "2" &&
        ((!minTempWatch && minTempWatch !== 0) ||
          (!maxTempWatch && maxTempWatch !== 0))));

  const handleChangeCollapsePurpose = (key: string | string[]) => {
    if (key.length) {
      setKeyPurpose(key as string[]);
    } else {
      if (keyPurpose[0] === "1") {
        setKeyPurpose(["2"]);
      } else {
        setKeyPurpose(["1"]);
      }
    }
  };

  const handleChangeCollapseSeason = (key: string | string[]) => {
    setKeySeason(key as string[]);
  };

  const handleKeyDownTemperature = (event: any) => {
    if (
      /^-?\d*$/.test(event.key) ||
      LIST_KEYS_ALLOW_PURPOSE.includes(event.code)
    ) {
      return;
    }

    event.preventDefault();
  };

  const handleDateTimeChange = (name: string) => {
    const valueDate = formModal.getFieldValue(name);
    if (name === NAME.START_DATE_SPRING && !valueDate) {
      formModal.setFieldValue(NAME.START_TIME_1_SPRING, undefined);
      formModal.setFieldValue(NAME.START_TIME_2_SPRING, undefined);
      formModal.setFieldValue(NAME.START_TIME_3_SPRING, undefined);
      formModal.setFieldValue(NAME.START_TIME_4_SPRING, undefined);
      formModal.setFieldValue(NAME.START_TIME_5_SPRING, undefined);
      formModal.setFieldValue(NAME.START_TIME_6_SPRING, undefined);
    }

    if (name === NAME.START_DATE_SUMMER && !valueDate) {
      formModal.setFieldValue(NAME.START_TIME_1_SUMMER, undefined);
      formModal.setFieldValue(NAME.START_TIME_2_SUMMER, undefined);
      formModal.setFieldValue(NAME.START_TIME_3_SUMMER, undefined);
      formModal.setFieldValue(NAME.START_TIME_4_SUMMER, undefined);
      formModal.setFieldValue(NAME.START_TIME_5_SUMMER, undefined);
      formModal.setFieldValue(NAME.START_TIME_6_SUMMER, undefined);
    }

    if (name === NAME.START_DATE_AUTUMN && !valueDate) {
      formModal.setFieldValue(NAME.START_TIME_1_AUTUMN, undefined);
      formModal.setFieldValue(NAME.START_TIME_2_AUTUMN, undefined);
      formModal.setFieldValue(NAME.START_TIME_3_AUTUMN, undefined);
      formModal.setFieldValue(NAME.START_TIME_4_AUTUMN, undefined);
      formModal.setFieldValue(NAME.START_TIME_5_AUTUMN, undefined);
      formModal.setFieldValue(NAME.START_TIME_6_AUTUMN, undefined);
    }

    if (name === NAME.START_DATE_WINTER && !valueDate) {
      formModal.setFieldValue(NAME.START_TIME_1_WINTER, undefined);
      formModal.setFieldValue(NAME.START_TIME_2_WINTER, undefined);
      formModal.setFieldValue(NAME.START_TIME_3_WINTER, undefined);
      formModal.setFieldValue(NAME.START_TIME_4_WINTER, undefined);
      formModal.setFieldValue(NAME.START_TIME_5_WINTER, undefined);
      formModal.setFieldValue(NAME.START_TIME_6_WINTER, undefined);
    }
  };

  const handleDisableFromDate: RangePickerProps["disabledDate"] = (current) => {
    const closeDateWatch = formModal.getFieldValue(NAME.CLOSE_DATE);
    return closeDateWatch && current.isAfter(closeDateWatch, "day");
  };

  const handleDisableToDate: RangePickerProps["disabledDate"] = (current) => {
    const openDateWatch = formModal.getFieldValue(NAME.OPEN_DATE);
    return openDateWatch && current.isBefore(openDateWatch, "day");
  };

  const handleSetFormValues = (record: TFormModalDeviceRes) => {
    setRecordId(record.id);

    formModal.setFieldValue(NAME.COMPANY_MODAL, record.companyId);
    formModal.setFieldValue(NAME.STORE_MODAL, record.storeId);
    if (record.isUseBattery) {
      formModal.setFieldValue(NAME.BATTERY, "1");
    } else {
      formModal.setFieldValue(NAME.BATTERY, "0");
    }
    formModal.setFieldValue(
      NAME.TEMPERATURE_DISTINCTION,
      record.temperatureType
    );
    formModal.setFieldValue(NAME.CH_MODAL, record.chCode);
    formModal.setFieldValue(NAME.INSTALL_LOCATION, record.installedLocation);
    formModal.setFieldValue(NAME.MANUFACTURER_MODEL_MODAL, record.modelCode);
    formModal.setFieldValue(NAME.MANUFACTURER_MODAL, record.manufacturerId);
    formModal.setFieldValue(
      NAME.OPEN_DATE,
      handleRenderFieldDate(record.installedDate)
    );
    formModal.setFieldValue(
      NAME.CLOSE_DATE,
      handleRenderFieldDate(record.diposalDate)
    );
    formModal.setFieldValue(
      NAME.TIME_START,
      handleRenderFieldTime(record.timeStart)
    );
    formModal.setFieldValue(
      NAME.TIME_END,
      handleRenderFieldTime(record.timeEnd)
    );
    formModal.setFieldValue(NAME.MACHINE, record.machine);
    formModal.setFieldValue(NAME.IP_ADDRESS, record.ipAddress);
    formModal.setFieldValue(
      NAME.START_DATE_SPRING,
      handleRenderFieldDateSeason(record.springStartDate, DATE_MONTH_FORMAT)
    );
    formModal.setFieldValue(
      NAME.START_TIME_1_SPRING,
      handleRenderFieldTime(record.springStartTime1)
    );
    formModal.setFieldValue(
      NAME.START_TIME_2_SPRING,
      handleRenderFieldTime(record.springStartTime2)
    );
    formModal.setFieldValue(
      NAME.START_TIME_3_SPRING,
      handleRenderFieldTime(record.springStartTime3)
    );
    formModal.setFieldValue(
      NAME.START_TIME_4_SPRING,
      handleRenderFieldTime(record.springStartTime4)
    );
    formModal.setFieldValue(
      NAME.START_TIME_5_SPRING,
      handleRenderFieldTime(record.springStartTime5)
    );
    formModal.setFieldValue(
      NAME.START_TIME_6_SPRING,
      handleRenderFieldTime(record.springStartTime6)
    );
    formModal.setFieldValue(
      NAME.START_DATE_SUMMER,
      handleRenderFieldDateSeason(record.summerStartDate, DATE_MONTH_FORMAT)
    );
    formModal.setFieldValue(
      NAME.START_TIME_1_SUMMER,
      handleRenderFieldTime(record.summerStartTime1)
    );
    formModal.setFieldValue(
      NAME.START_TIME_2_SUMMER,
      handleRenderFieldTime(record.summerStartTime2)
    );
    formModal.setFieldValue(
      NAME.START_TIME_3_SUMMER,
      handleRenderFieldTime(record.summerStartTime3)
    );
    formModal.setFieldValue(
      NAME.START_TIME_4_SUMMER,
      handleRenderFieldTime(record.summerStartTime4)
    );
    formModal.setFieldValue(
      NAME.START_TIME_5_SUMMER,
      handleRenderFieldTime(record.summerStartTime5)
    );
    formModal.setFieldValue(
      NAME.START_TIME_6_SUMMER,
      handleRenderFieldTime(record.summerStartTime6)
    );
    formModal.setFieldValue(
      NAME.START_DATE_AUTUMN,
      handleRenderFieldDateSeason(record.autumnStartDate, DATE_MONTH_FORMAT)
    );
    formModal.setFieldValue(
      NAME.START_TIME_1_AUTUMN,
      handleRenderFieldTime(record.autumnStartTime1)
    );
    formModal.setFieldValue(
      NAME.START_TIME_2_AUTUMN,
      handleRenderFieldTime(record.autumnStartTime2)
    );
    formModal.setFieldValue(
      NAME.START_TIME_3_AUTUMN,
      handleRenderFieldTime(record.autumnStartTime3)
    );
    formModal.setFieldValue(
      NAME.START_TIME_4_AUTUMN,
      handleRenderFieldTime(record.autumnStartTime4)
    );
    formModal.setFieldValue(
      NAME.START_TIME_5_AUTUMN,
      handleRenderFieldTime(record.autumnStartTime5)
    );
    formModal.setFieldValue(
      NAME.START_TIME_6_AUTUMN,
      handleRenderFieldTime(record.autumnStartTime6)
    );
    formModal.setFieldValue(
      NAME.START_DATE_WINTER,
      handleRenderFieldDateSeason(record.winterStartDate, DATE_MONTH_FORMAT)
    );
    formModal.setFieldValue(
      NAME.START_TIME_1_WINTER,
      handleRenderFieldTime(record.winterStartTime1)
    );
    formModal.setFieldValue(
      NAME.START_TIME_2_WINTER,
      handleRenderFieldTime(record.winterStartTime2)
    );
    formModal.setFieldValue(
      NAME.START_TIME_3_WINTER,
      handleRenderFieldTime(record.winterStartTime3)
    );
    formModal.setFieldValue(
      NAME.START_TIME_4_WINTER,
      handleRenderFieldTime(record.winterStartTime4)
    );
    formModal.setFieldValue(
      NAME.START_TIME_5_WINTER,
      handleRenderFieldTime(record.winterStartTime5)
    );
    formModal.setFieldValue(
      NAME.START_TIME_6_WINTER,
      handleRenderFieldTime(record.winterStartTime6)
    );
    formModal.setFieldValue(NAME.LOWER_LIMIT, record.minTemp);
    formModal.setFieldValue(NAME.UPPER_LIMIT, record.maxTemp);
    formModal.setFieldValue(NAME.REASON, record.reasonSetting);
    formModal.setFieldValue(NAME.PURPOSE_CODE, record.purposeId);
    formModal.setFieldValue(NAME.PURPOSE_MIN, record.purposeMinTemp);
    formModal.setFieldValue(NAME.PURPOSE_MAX, record.purposeMaxTemp);

    if (record.isUseManualSetting) {
      setKeyPurpose(["2"]);
    } else {
      setKeyPurpose(["1"]);
    }
  };

  const handleSetSearchParams = (values: TFormDeviceReq) => {
    searchParams.set(NAME.COMPANY_CODE, values.companyId || "");
    searchParams.set(NAME.STORE_CODE, values.storeId || "");
    searchParams.set(NAME.CH_CODE, values.chCode || "");
    searchParams.set(NAME.MANUFACTURER_CODE, values.manufacturerCode || "");
    searchParams.set(NAME.MANUFACTURER_MODEL, values.modelCode || "");

    setSearchParams(searchParams, { replace: true });
  };

  const handleResetChCode = () => {
    searchParams.delete(NAME.CH_CODE);
    form.setFieldValue(NAME.CH_CODE, undefined);
    setChCode([OPTION_EMPTY]);
  };

  const handleResetOther = () => {
    searchParams.delete(NAME.STORE_CODE);
    form.setFieldValue(NAME.STORE_CODE, undefined);
    setStores([OPTION_EMPTY]);

    handleResetChCode();
  };

  const handleChangeCompany = (val: string) => {
    if (val) {
      const findCompany = _.find(companies, (company) => company.value === val);
      if (findCompany) {
        handler.getStoresByCompany(
          {
            companyCode: findCompany.children as string,
            companyName: findCompany.label.split("_")[1],
          },
          false,
          form,
          setStores,
          setLoadingStore,
          setLoadingStoreModal,
          setLoadingCh,
          setChCode
        );
      }
    }
    handleResetOther();
  };

  const handleChangeCompanyModal = (val: string) => {
    formModal.setFieldValue(NAME.STORE_MODAL, undefined);
    setStoresModal([]);
    formModal.setFieldValue(NAME.PURPOSE_CODE, undefined);
    formModal.setFieldValue(NAME.PURPOSE_MAX, "");
    formModal.setFieldValue(NAME.PURPOSE_MIN, "");
    setPurposesModal([]);

    const findCompany = _.find(
      companiesModal,
      (company) => company.value === val
    );
    if (findCompany) {
      const payloadCompany = {
        companyCode: findCompany.children as string,
        companyName: findCompany.label.split("_")[1],
      };
      handler.getStoresByCompany(
        payloadCompany,
        true,
        form,
        setStores,
        setLoadingStore,
        setLoadingStoreModal,
        setLoadingCh,
        setChCode,
        setStoresModal
      );
      handler.getAllPurposes(
        formModal,
        payloadCompany,
        setPurposesModal,
        setLoadingStoreModal
      );
    }
  };

  const handleChangeStore = (val: string) => {
    if (val) {
      const findStore = _.find(stores, (store) => store.value === val);
      const companyId = form.getFieldValue(NAME.COMPANY_CODE);
      const findCompany = _.find(companies, function (item) {
        return item.value === companyId;
      });
      if (findCompany && findStore) {
        handler.getChCodeSensor(
          {
            companyCode: findCompany.children as string,
            storeCode: findStore.children as string,
          },
          setLoadingCh,
          setChCode,
          form
        );
      }
    }
    handleResetChCode();
  };

  const handleChangePurposeModal = (val: string) => {
    const findPurpose = _.find(purposesModal, function (item) {
      return item.id === val;
    });
    if (findPurpose) {
      formModal.setFieldValue(NAME.PURPOSE_MIN, findPurpose.minTemp);
      formModal.setFieldValue(NAME.PURPOSE_MAX, findPurpose.maxTemp);
    }
  };

  const handleFormResetFieldError = () => {
    formModal.setFields([
      {
        name: [NAME.IP_ADDRESS],
        errors: [],
      },
      {
        name: [NAME.LOWER_LIMIT],
        errors: [],
      },

      {
        name: [NAME.UPPER_LIMIT],
        errors: [],
      },

      {
        name: [NAME.TIME_START],
        errors: [],
      },
      {
        name: [NAME.TIME_END],
        errors: [],
      },
      {
        name: [NAME.CH_MODAL],
        errors: [],
      },
    ]);
  };

  const handleValidateForm = () => {
    handleFormResetFieldError();

    const ipAddressWatch = formModal.getFieldValue(NAME.IP_ADDRESS);
    const timeStartWatch = formModal.getFieldValue(NAME.TIME_START);
    const timeEndWatch = formModal.getFieldValue(NAME.TIME_END);
    const chCode = !REGEX_CHARACTER_NUMBER.test(chCodeWatch) ? true : false;

    const validateIpAddress = ipAddressWatch
      ? ipRegex.v4({ includeBoundaries: true }).test(ipAddressWatch)
      : "";
    const validateTemperature =
      keyPurpose[0] === "2" && +minTempWatch > +maxTempWatch ? true : false;
    const validateMaxTemp =
      keyPurpose[0] === "2" &&
      maxTempWatch &&
      (maxTempWatch > 999.9 || maxTempWatch < -999.9)
        ? true
        : false;
    const validateMinTemp =
      keyPurpose[0] === "2" &&
      minTempWatch &&
      (minTempWatch < -999.9 || minTempWatch > 999.9)
        ? true
        : false;
    const validateTime =
      timeStartWatch &&
      timeEndWatch &&
      dayjs(timeEndWatch).diff(timeStartWatch, "minutes") < 0
        ? true
        : false;

    const _validateIpAddress = !validateIpAddress && validateIpAddress !== "";
    let checkError = false;

    if (_validateIpAddress) {
      formModal.setFields([
        {
          name: [NAME.IP_ADDRESS],
          errors: [MESSAGE.FIELD_INVALID],
        },
      ]);
      formModal.scrollToField(NAME.IP_ADDRESS);

      checkError = true;
    }
    if (validateTime) {
      formModal.setFields([
        {
          name: [NAME.TIME_START],
          errors: [MESSAGE.START_END],
        },
        {
          name: [NAME.TIME_END],
          errors: [MESSAGE.START_END],
        },
      ]);
      formModal.scrollToField(NAME.TIME_START);
      checkError = true;
    }
    if (validateTemperature) {
      formModal.setFields([
        {
          name: [NAME.LOWER_LIMIT],
          errors: [MESSAGE.LOWER_UPPER],
        },
        {
          name: [NAME.UPPER_LIMIT],
          errors: [MESSAGE.LOWER_UPPER],
        },
      ]);
      formModal.scrollToField(NAME.LOWER_LIMIT);
      checkError = true;
    }
    if (validateMaxTemp) {
      formModal.setFields([
        {
          name: [NAME.UPPER_LIMIT],
          errors: [MESSAGE.FIELD_INVALID_FORMAT],
        },
      ]);
      formModal.scrollToField(NAME.UPPER_LIMIT);
      checkError = true;
    }
    if (validateMinTemp) {
      formModal.setFields([
        {
          name: [NAME.LOWER_LIMIT],
          errors: [MESSAGE.FIELD_INVALID_FORMAT],
        },
      ]);
      formModal.scrollToField(NAME.LOWER_LIMIT);
      checkError = true;
    }
    if (chCode) {
      formModal.setFields([
        {
          name: [NAME.CH_MODAL],
          errors: [MESSAGE.FIELD_INVALID],
        },
      ]);
      formModal.scrollToField(NAME.CH_MODAL);
      checkError = true;
    }

    if (!checkError) {
      if (modalType === EModalType.REGISTRATION) {
        formModal.setFields([{ name: [NAME.CH_MODAL], errors: [] }]);
        const companyId = formModal.getFieldValue(NAME.COMPANY_MODAL);
        const storeId = formModal.getFieldValue(NAME.STORE_MODAL);
        const chCode = formModal.getFieldValue(NAME.CH_MODAL);
        handler.checkExistDevice(
          { chCode, companyId, storeId },
          formModal,
          setModalType,
          setLoadingExist
        );
      }

      if (modalType === EModalType.UPDATE) {
        setModalType(EModalType.CONFIRM_UPDATE);
      }
    }
  };

  // Modal confirm final
  const handleOpenModalConfirmFinal = () => {
    setModalConfirm(true);
    if (modalType === EModalType.CONFIRM_REGISTRATION) {
      setModalTypeConfirm(EModalType.CONFIRM_REGISTRATION_FINAL);
    }

    if (modalType === EModalType.CONFIRM_UPDATE) {
      setModalTypeConfirm(EModalType.CONFIRM_UPDATE_FINAL);
    }
  };

  const handleOkModalConfirmFinal = () => handleOkModal();

  const handleCloseModalConfirmFinal = () => setModalConfirm(false);

  // Modal success
  const handleCloseModalSuccess = () => {
    setModalSuccess(false);
    setModalType(null);
    setRowKey(null);
    setKeyPurpose(["1"]);
    setKeySeason(["1", "2", "3", "4"]);
  };

  // TODO: Recheck
  const handleModalSuccess = (_data?: any) => {
    setModalSuccess(true);

    // const _data: TListDevice = {
    //   chCode: data.chCode,
    //   companyCode: data.companyCode,
    //   companyName: data.companyName,
    //   isDeleted: data.isDeleted,
    //   manufacturerCode: data.manufacturerCode,
    //   manufacturerName: data.manufacturerName,
    //   modelCode: data.modelCode,
    //   sensorId: data.sensorId,
    //   storeCode: data.storeCode,
    //   storeName: data.storeName,
    //   key: 1,
    // };

    if (modalTypeConfirm === EModalType.CONFIRM_REGISTRATION_FINAL) {
      setModalType(EModalType.SUCCESS_REGISTRATION);
      // setLoadedIds((prevIds) => new Set([...prevIds, _data.sensorId]));
      // setListDevice([
      //   { ..._data },
      //   ...listDevice.map((item) => ({ ...item, key: item.key + 1 })),
      // ]);
    }

    if (modalTypeConfirm === EModalType.CONFIRM_UPDATE_FINAL) {
      setModalType(EModalType.SUCCESS_UPDATE);
      // const _filteredDevice = listDevice.filter(
      //   (item) => item.sensorId !== _data.sensorId
      // );
      // setListDevice([
      //   { ..._data },
      //   ..._filteredDevice.map((item) => ({ ...item, key: item.key + 1 })),
      // ]);
    }
  };

  // Modal fail
  const handleModalFail = () => {
    setModalSuccess(true);

    if (modalTypeConfirm === EModalType.CONFIRM_REGISTRATION_FINAL) {
      setModalType(EModalType.FAIL_REGISTRATION);
    }

    if (modalTypeConfirm === EModalType.CONFIRM_UPDATE_FINAL) {
      setModalType(EModalType.FAIL_UPDATE);
    }
  };

  const handleDeleteFail = () => {
    setModalSuccess(true);
    setModalConfirm(false);
    setModalDelete(false);
    setRowKey(null);
    setModalType(TITLE.NOT_FOUND_DEVICE);
  };

  const handleCommonError = () => {
    setModalConfirm(false);
    setModalType(null);
    setDevice(null);
    setRowKey(null);
    setModalOpen(false);
  };

  // Modal registration
  const handleOpenModalRegistration = () => {
    setPurposesModal([]);
    setStoresModal([]);
    setModalOpen(true);
    setModalType(EModalType.REGISTRATION);

    formModal.setFieldValue(NAME.LOWER_LIMIT, "");
    formModal.setFieldValue(NAME.UPPER_LIMIT, "");
    formModal.setFieldValue(NAME.REASON, "");
    formModal.setFieldValue(NAME.PURPOSE_CODE, undefined);
    formModal.setFieldValue(NAME.PURPOSE_MIN, "");
    formModal.setFieldValue(NAME.PURPOSE_MAX, "");
  };

  //  Modal delete
  const handleCloseModalDelete = () => {
    setDevice(null);
    setRowKey(null);
    setModalDelete(false);
  };

  const handleOpenModalDelete = (record: any) => {
    setDevice(record);
    setRowKey(record.key);
    setModalDelete(true);
  };

  // Modal detail
  const handleOpenModalDetail = (record: TListDevice) => {
    setModalOpen(true);
    setModalType(EModalType.DETAIL);
    setRowKey(record.key);

    handler.detailDevice(
      record.sensorId,
      companiesModal,
      setStoresModal,
      setPurposesModal,
      handleSetFormValues,
      setLoadingModal
    );
  };

  // Modal update
  const handleOpenModalUpdate = (record: any, id: string) => {
    setModalOpen(true);
    setModalType(EModalType.UPDATE);
    setRowKey(record.key);
    handleSetFormValues(record);

    handler.detailDevice(
      id,
      companiesModal,
      setStoresModal,
      setPurposesModal,
      handleSetFormValues,
      setLoadingModal
    );
  };

  const handleCloseModal = () => {
    setModalType(null);
    setDevice(null);
    setRowKey(null);
    setKeyPurpose(["1"]);
    setKeySeason(["1", "2", "3", "4"]);
    setModalOpen(false);
  };

  // TODO: Recheck
  const handleOkModalSuccess = (_data: any) => {
    setModalOpen(false);
    // handleModalSuccess(data);
    handleModalSuccess();
    handleCloseModalConfirmFinal();
    tblRef.current?.scrollTo({ index: 0 });

    setListDevice([]);
    setPageIndex(1);
    getListDeviceSearch({
      chCode: chCodeParam,
      companyId: companyParam,
      manufacturerCode: manufacturerCodeParam,
      modelCode: modelCodeParam,
      storeId: storeParam,
      pageIndex: 0,
    });
  };

  // TODO: Recheck
  const handleFinish = (values: TFormDeviceReq) => {
    handleSetSearchParams({
      chCode: values.chCode,
      companyId: values.companyId,
      manufacturerCode: values.manufacturerCode || "",
      modelCode: values.modelCode || "",
      storeId: values.storeId,
    });
    setListDevice([]);
    setPageIndex(1);
    // setLoadedIds(new Set([]));

    getListDeviceSearch({
      ...values,
      pageIndex: 0,
    });
  };

  const getListDeviceSearch = async (payload: TListDeviceReq) => {
    handler.getListDeviceSearch(
      payload,
      setLoadingPage,
      setListDevice,
      handleSetSearchParams,
      setPageIndex,
      setHasNext
    );
  };

  const getListDeviceScroll = async (payload: TListDeviceReq) => {
    handler.getListDeviceScroll(
      payload,
      listDevice,
      pageIndex,
      // loadedIds,
      setLoadingPage,
      setListDevice,
      // setLoadedIds,
      setPageIndex,
      setHasNext
    );
  };

  // MEMO
  const renderTextModal = useMemo(() => {
    switch (modalType) {
      case EModalType.REGISTRATION:
        return {
          title: TITLE.TITLE_REGISTRATION,
          okText: TITLE.BTN_NEXT,
          message: "",
          image: undefined,
        };
      case EModalType.DETAIL:
        return {
          title: TITLE.TITLE_DETAIL,
          okText: TITLE.BTN_CLOSE,
          message: "",
          image: undefined,
        };
      case EModalType.UPDATE:
        return {
          title: TITLE.TITLE_UPDATE,
          okText: TITLE.BTN_NEXT,
          message: "",
          image: undefined,
        };

      case EModalType.CONFIRM_REGISTRATION:
        return {
          title: TITLE.TITLE_REGISTRATION,
          okText: TITLE.BTN_REGISTRATION_MODAL,
          message: "",
          image: undefined,
        };
      case EModalType.CONFIRM_UPDATE:
        return {
          title: TITLE.TITLE_UPDATE,
          okText: TITLE.BTN_UPDATE,
          message: "",
          image: undefined,
        };

      case EModalType.SUCCESS_REGISTRATION:
        return {
          title: TITLE.TITLE_REGISTRATION,
          okText: TITLE.BTN_OK,
          message: MESSAGE.REGISTER_SUCCESS,
          image: images.confirmApproveTemperature,
        };
      case EModalType.SUCCESS_UPDATE:
        return {
          title: TITLE.SUCCESS_UPDATE,
          okText: TITLE.BTN_OK,
          message: MESSAGE.UPDATE_SUCCESS,
          image: images.confirmApproveTemperature,
        };
      case EModalType.SUCCESS_DELETE:
        return {
          title: TITLE.SUCCESS_DELETE,
          okText: TITLE.BTN_OK,
          message: MESSAGE.SUCCESS_DELETE,
          image: images.confirmApproveTemperature,
        };

      case EModalType.FAIL_REGISTRATION:
        return {
          title: TITLE.SUCCESS_TITLE,
          okText: TITLE.BTN_OK,
          message: MESSAGE.FAIL_REGISTER,
          image: images.importDataFail,
        };
      case EModalType.FAIL_UPDATE:
        return {
          title: TITLE.SUCCESS_TITLE,
          okText: TITLE.BTN_OK,
          message: MESSAGE.FAIL_UPDATE,
          image: images.importDataFail,
        };
      case EModalType.FAIL_DELETE:
        return {
          title: TITLE.FAIL_DELETE,
          okText: TITLE.BTN_OK,
          message: MESSAGE.FAIL_DELETE,
          image: images.importDataFail,
        };
      case TITLE.NOT_FOUND_DEVICE:
        return {
          title: TITLE.FAIL_DELETE,
          okText: TITLE.BTN_OK,
          message: MESSAGE.NOT_FOUND_DEVICE,
          image: images.importDataFail,
        };

      default:
        return {
          title: "",
          okText: "",
          message: "",
        };
    }
  }, [modalType]);

  const renderTextModalConfirm = useMemo(() => {
    switch (modalTypeConfirm) {
      case EModalType.CONFIRM_REGISTRATION_FINAL:
        return {
          title: TITLE.SUCCESS_TITLE,
          okText: TITLE.BTN_OK,
          message: MESSAGE.CONFIRM_REGISTRATION_FINAL,
        };
      case EModalType.CONFIRM_UPDATE_FINAL:
        return {
          title: TITLE.SUCCESS_TITLE,
          okText: TITLE.BTN_OK,
          message: MESSAGE.CONFIRM_UPDATE_FINAL,
        };

      default:
        return {
          title: "",
          okText: "",
          message: "",
        };
    }
  }, [modalTypeConfirm]);

  const columns = useMemo(() => {
    const columns = getColumns({
      disableBtnOther,
      handleOpenModalDelete,
      handleOpenModalDetail,
      handleOpenModalUpdate,
    });
    return columns;
  }, [companiesModal]);

  // API
  const handleScroll = (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (
      scrollTop + clientHeight >= scrollHeight - 5 &&
      !loadingPage &&
      hasNext
    ) {
      setPageIndex((prev) => prev + 1);
      getListDeviceScroll({
        companyId: companyParam,
        storeId: storeParam,
        chCode: chCodeParam,
        manufacturerCode: manufacturerCodeParam,
        modelCode: modelCodeParam,
        pageIndex: pageIndex + 1,
      });
    }
  };

  const handleOkModalDelete = () => {
    if (device) {
      handler.deleteDevice(
        device.sensorId,
        listDevice,
        setListDevice,
        setLoadingPage,
        setModalSuccess,
        setModalType,
        handleCloseModalDelete,
        handleDeleteFail
      );
    }
  };

  const handleOkModal = () => {
    const manufacturerCodeWatch = formModal.getFieldValue(
      NAME.MANUFACTURER_MODAL
    );
    const ipAddressWatch = formModal.getFieldValue(NAME.IP_ADDRESS);
    const timeStartWatch = formModal.getFieldValue(NAME.TIME_START);
    const timeEndWatch = formModal.getFieldValue(NAME.TIME_END);
    const openDateWatch = formModal.getFieldValue(NAME.OPEN_DATE);
    const closeDateWatch = formModal.getFieldValue(NAME.CLOSE_DATE);
    const purposeIdWatch = formModal.getFieldValue(NAME.PURPOSE_CODE);
    const machineWatch = formModal.getFieldValue(NAME.MACHINE);
    const reasonWatch = formModal.getFieldValue(NAME.REASON);
    const springStartTime1Watch = formModal.getFieldValue(
      NAME.START_TIME_1_SPRING
    );
    const springStartTime2Watch = formModal.getFieldValue(
      NAME.START_TIME_2_SPRING
    );
    const springStartTime3Watch = formModal.getFieldValue(
      NAME.START_TIME_3_SPRING
    );
    const springStartTime4Watch = formModal.getFieldValue(
      NAME.START_TIME_4_SPRING
    );
    const springStartTime5Watch = formModal.getFieldValue(
      NAME.START_TIME_5_SPRING
    );
    const springStartTime6Watch = formModal.getFieldValue(
      NAME.START_TIME_6_SPRING
    );
    const summerStartTime1Watch = formModal.getFieldValue(
      NAME.START_TIME_1_SUMMER
    );
    const summerStartTime2Watch = formModal.getFieldValue(
      NAME.START_TIME_2_SUMMER
    );
    const summerStartTime3Watch = formModal.getFieldValue(
      NAME.START_TIME_3_SUMMER
    );
    const summerStartTime4Watch = formModal.getFieldValue(
      NAME.START_TIME_4_SUMMER
    );
    const summerStartTime5Watch = formModal.getFieldValue(
      NAME.START_TIME_5_SUMMER
    );
    const summerStartTime6Watch = formModal.getFieldValue(
      NAME.START_TIME_6_SUMMER
    );
    const autumnStartTime1Watch = formModal.getFieldValue(
      NAME.START_TIME_1_AUTUMN
    );
    const autumnStartTime2Watch = formModal.getFieldValue(
      NAME.START_TIME_2_AUTUMN
    );
    const autumnStartTime3Watch = formModal.getFieldValue(
      NAME.START_TIME_3_AUTUMN
    );
    const autumnStartTime4Watch = formModal.getFieldValue(
      NAME.START_TIME_4_AUTUMN
    );
    const autumnStartTime5Watch = formModal.getFieldValue(
      NAME.START_TIME_5_AUTUMN
    );
    const autumnStartTime6Watch = formModal.getFieldValue(
      NAME.START_TIME_6_AUTUMN
    );
    const winterStartTime1Watch = formModal.getFieldValue(
      NAME.START_TIME_1_WINTER
    );
    const winterStartTime2Watch = formModal.getFieldValue(
      NAME.START_TIME_2_WINTER
    );
    const winterStartTime3Watch = formModal.getFieldValue(
      NAME.START_TIME_3_WINTER
    );
    const winterStartTime4Watch = formModal.getFieldValue(
      NAME.START_TIME_4_WINTER
    );
    const winterStartTime5Watch = formModal.getFieldValue(
      NAME.START_TIME_5_WINTER
    );
    const winterStartTime6Watch = formModal.getFieldValue(
      NAME.START_TIME_6_WINTER
    );

    // TODO
    let params: TFormRegisterDeviceReq = {
      companyId: companyCodeWatch,
      storeId: storeCodeWatch,
      chCode: handleRenderFieldInput(chCodeWatch),
      manufacturerId: manufacturerCodeWatch,
      modelCode: handleRenderFieldInput(manufacturerModelWatch),
      isUseBattery: batteryWatch,
      temperatureType: handleFormatTemperatureType(temperatureTypeWatch),
      installedLocation: handleFormatLocation(installedLocationWatch),
      installedDate: openDateWatch
        ? dayjs(openDateWatch).format(DATE_FORMAT)
        : null,
      diposalDate: closeDateWatch
        ? dayjs(closeDateWatch).format(DATE_FORMAT)
        : null,
      timeStart: handleFormatFieldTime(timeStartWatch),
      timeEnd: handleFormatFieldTime(timeEndWatch),
      purposeId: purposeIdWatch,
      isUseManualSetting: false,
      minTemp: "",
      maxTemp: "",
      machine: handleRenderFieldInput(machineWatch),
      ipAddress: handleRenderFieldInput(ipAddressWatch),
      reasonSetting: handleRenderFieldInput(reasonWatch),
      springStartDate: handleFormatFieldDate(
        springStartDateWatch,
        DATE_MONTH_FORMAT
      ),
      springStartTime1: handleFormatFieldTime(springStartTime1Watch),
      springStartTime2: handleFormatFieldTime(springStartTime2Watch),
      springStartTime3: handleFormatFieldTime(springStartTime3Watch),
      springStartTime4: handleFormatFieldTime(springStartTime4Watch),
      springStartTime5: handleFormatFieldTime(springStartTime5Watch),
      springStartTime6: handleFormatFieldTime(springStartTime6Watch),
      summerStartDate: handleFormatFieldDate(
        summerStartDateWatch,
        DATE_MONTH_FORMAT
      ),
      summerStartTime1: handleFormatFieldTime(summerStartTime1Watch),
      summerStartTime2: handleFormatFieldTime(summerStartTime2Watch),
      summerStartTime3: handleFormatFieldTime(summerStartTime3Watch),
      summerStartTime4: handleFormatFieldTime(summerStartTime4Watch),
      summerStartTime5: handleFormatFieldTime(summerStartTime5Watch),
      summerStartTime6: handleFormatFieldTime(summerStartTime6Watch),
      autumnStartDate: handleFormatFieldDate(
        autumnStartDateWatch,
        DATE_MONTH_FORMAT
      ),
      autumnStartTime1: handleFormatFieldTime(autumnStartTime1Watch),
      autumnStartTime2: handleFormatFieldTime(autumnStartTime2Watch),
      autumnStartTime3: handleFormatFieldTime(autumnStartTime3Watch),
      autumnStartTime4: handleFormatFieldTime(autumnStartTime4Watch),
      autumnStartTime5: handleFormatFieldTime(autumnStartTime5Watch),
      autumnStartTime6: handleFormatFieldTime(autumnStartTime6Watch),
      winterStartDate: handleFormatFieldDate(
        winterStartDateWatch,
        DATE_MONTH_FORMAT
      ),
      winterStartTime1: handleFormatFieldTime(winterStartTime1Watch),
      winterStartTime2: handleFormatFieldTime(winterStartTime2Watch),
      winterStartTime3: handleFormatFieldTime(winterStartTime3Watch),
      winterStartTime4: handleFormatFieldTime(winterStartTime4Watch),
      winterStartTime5: handleFormatFieldTime(winterStartTime5Watch),
      winterStartTime6: handleFormatFieldTime(winterStartTime6Watch),
    };
    if (keyPurpose[0] === "1") {
      const purposeMinTempWatch = formModal.getFieldValue(NAME.PURPOSE_MIN);
      const purposeMaxTempWatch = formModal.getFieldValue(NAME.PURPOSE_MAX);
      params = {
        ...params,
        isUseManualSetting: 0,
        minTemp: purposeMinTempWatch,
        maxTemp: purposeMaxTempWatch,
      };
    } else {
      const minTemp = formModal
        .getFieldValue(NAME.LOWER_LIMIT)
        .replace(REGEX_REPLACE_TEMPERATURE, "");
      const maxTemp = formModal
        .getFieldValue(NAME.UPPER_LIMIT)
        .replace(REGEX_REPLACE_TEMPERATURE, "");
      params = {
        ...params,
        isUseManualSetting: 1,
        minTemp: Number.isInteger(+minTemp)
          ? `${minTemp}.0`
          : minTemp.toString(),
        maxTemp: Number.isInteger(+maxTemp)
          ? `${maxTemp}.0`
          : maxTemp.toString(),
      };
    }

    if (modalTypeConfirm === EModalType.CONFIRM_REGISTRATION_FINAL) {
      handler.registrationDevice(
        params,
        handleOkModalSuccess,
        setLoadingPage,
        handleModalFail,
        handleCommonError
      );
    }

    if (modalTypeConfirm === EModalType.CONFIRM_UPDATE_FINAL) {
      handler.updateDevice(
        { ...params, id: recordId },
        handleOkModalSuccess,
        setLoadingPage,
        handleModalFail,
        handleCommonError
      );
    }
  };

  const fetchInitialApis = () => {
    handler.fetchInitialApis(
      pageIndex,
      setLoadingPage,
      setLoadingCompany,
      setCompanies,
      setCompaniesModal,
      setStores,
      setStoresModal,
      setChCode,
      setListDevice,
      setHasNext,
      setPageIndex,
      handleSetSearchParams,
      setManufacturersModal,
      setManufacturers
    );
  };

  const fetchApiRedirect = (payload: TFormDeviceReq) => {
    handler.fetchApiRedirect(
      payload,
      pageIndex,
      setLoadingPage,
      setLoadingCompany,
      setCompanies,
      setCompaniesModal,
      setStores,
      setStoresModal,
      setChCode,
      setListDevice,
      setHasNext,
      setPageIndex,
      handleSetSearchParams,
      setManufacturersModal,
      setManufacturers
    );
  };

  // EFFECT
  useEffect(() => {
    if (companyParam && storeParam) {
      fetchApiRedirect({
        chCode: chCodeParam,
        companyId: companyParam,
        storeId: storeParam,
        manufacturerCode: manufacturerCodeParam,
        modelCode: modelCodeParam,
      });
    } else {
      fetchInitialApis();
    }
  }, []);

  useEffect(() => {
    effect.effectCompany(companyParam, form, companies);
  }, [companies]);

  useEffect(() => {
    effect.effectStore(storeParam, form, stores);
  }, [stores]);

  useEffect(() => {
    effect.effectChCode(chCodeParam, form);
  }, [chCode]);

  useEffect(() => {
    effect.effectManufacturer(manufacturerCodeParam, form);
  }, [manufacturers]);

  return {
    state: {
      form,
      loadingPage,
      loadingCompany,
      loadingStore,
      loadingCh,
      companies,
      stores,
      chCode,
      manufacturers,
      tblRef,

      manufacturersModal,
      springStartDateWatch,
      summerStartDateWatch,
      autumnStartDateWatch,
      winterStartDateWatch,
      loadingModal,
      companiesModal,
      storesModal,
      purposesModal,
      keyPurpose,
      keySeason,
      device,
      formModal,
      loadingStoreModal,
      modalType,
      modalOpen,
      modalDelete,
      modalSuccess,
      modalConfirm,
      renderTextModal,
      renderTextModalConfirm,
      loadingExist,

      disableBtnNext,
      disableFields,
      disableFieldsEdit,

      disableBtnOther,
      listDevice,
      rowKey,
      columns,
    },
    handler: {
      handleFinish,
      handleOkModal,
      handleScroll,

      handleDateTimeChange,
      handleChangePurposeModal,
      handleChangeCompanyModal,
      handleKeyDownTemperature,
      handleChangeCollapsePurpose,
      handleChangeCollapseSeason,
      setModalType,
      handleDisableFromDate,
      handleDisableToDate,
      handleCloseModal,
      handleValidateForm,
      handleOpenModalConfirmFinal,
      handleOkModalDelete,
      handleCloseModalDelete,
      handleCloseModalSuccess,
      handleOkModalConfirmFinal,
      handleCloseModalConfirmFinal,
      handleChangeCompany,
      handleChangeStore,
      handleOpenModalRegistration,
    },
  };
}

export default useDevicePage;
