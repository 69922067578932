import { images } from "@constants";
import { OPTION_EMPTY, REGEX_CHARACTER_NUMBER } from "@constants/common";
import { AppContext } from "@layouts/LayoutAdmin/LayoutAdmin";
import { TSelectValue } from "@models/common";
import {
  EUserRoles,
  TFormDetailUserRes,
  TFormUserReq,
  TGetStoreByCompany,
  TListUserReq,
  TListUserTable,
  TRegisUserReq,
} from "@models/user";
import { layoutApi } from "@services/layoutApi";
import { handleShowToastError } from "@utils/helpers";
import { Form } from "antd";
import { FormInstance, Table } from "antd/lib";
import _ from "lodash";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { EModalType } from "types/others";
import { getColumns } from "../columns";
import { USER_CONSTANT } from "../constant";
import useUserApi from "./useUserApi";

const { NAME, TITLE, MESSAGE } = USER_CONSTANT;

function useUserPage() {
  const [form] = Form.useForm();
  const [formModal] = Form.useForm();
  const userNameWatch = Form.useWatch(NAME.MODAL_USERNAME, formModal);
  const nameWatch = Form.useWatch(NAME.MODAL_NAME, formModal);
  const passwordWatch = Form.useWatch(NAME.MODAL_PASSWORD, formModal);
  const companyCodeWatch = Form.useWatch(NAME.MODAL_COMPANY_CODE, formModal);
  const storeCodeWatch = Form.useWatch(NAME.MODAL_STORE_CODE, formModal);
  const roleWatch = Form.useWatch(NAME.MODAL_ROLE, formModal);

  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingCompany, setLoadingCompany] = useState(true);
  const [loadingStore, setLoadingStore] = useState(false);
  const [listUser, setListUser] = useState<TListUserTable[]>([]);
  const [companies, setCompanies] = useState<TSelectValue[]>([]);
  const [stores, setStores] = useState<TSelectValue[]>([]);
  const [roles, setRoles] = useState<TSelectValue[]>([]);
  const [hasNext, setHasNext] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [rowKey, setRowKey] = useState<number | null>(null);
  const [recordId, setRecordId] = useState("");
  // const [loadedIds, setLoadedIds] = useState<Set<string>>(new Set());

  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingExist, setLoadingExist] = useState(false);
  const [companiesModal, setCompaniesModal] = useState<TSelectValue[]>([]);
  const [rolesModal, setRolesModal] = useState<TSelectValue[]>([]);
  const [storeModal, setStoreModal] = useState<TSelectValue[]>([]);
  const [user, setUser] = useState<TListUserTable | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [disableCompany, setDisableCompany] = useState(false);
  const [disableStore, setDisableStore] = useState(false);
  const [modalType, setModalType] = useState<EModalType | null | string>(null);
  const [modalTypeConfirm, setModalTypeConfirm] = useState<EModalType | null>(
    null
  );
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const nameParam = searchParams.get(NAME.NAME) || "";
  const userNameParam = searchParams.get(NAME.USERNAME) || "";
  const companyParam = searchParams.get(NAME.COMPANY) || "";
  const storeParam = searchParams.get(NAME.STORE) || "";
  const roleParam = searchParams.get(NAME.ROLE) || "";

  const { handler, effect } = useUserApi();
  const { role, username } = useContext(AppContext);

  const roleAdmin = role ? role[0] === EUserRoles.ADMIN : false;

  const disableBtnOther = roleAdmin ? false : true;
  const disableCompanyStore = roleWatch !== 1 || roleWatch === undefined;

  const disableBtnNext =
    modalType !== EModalType.DETAIL &&
    (!userNameWatch ||
      (!passwordWatch && modalType === EModalType.REGISTRATION) ||
      !roleWatch ||
      (roleWatch === 1 && (!companyCodeWatch || !storeCodeWatch)));

  const disableFields =
    modalType === EModalType.CONFIRM_REGISTRATION ||
    modalType === EModalType.CONFIRM_UPDATE ||
    modalType === EModalType.DETAIL;

  const disableFieldsEdit = modalType === EModalType.UPDATE || disableFields;

  const tblRef: Parameters<typeof Table>[0]["ref"] = useRef(null);

  const handleSetFormValues = (record: TFormDetailUserRes) => {
    setRecordId(record.id);
    formModal.setFieldValue(NAME.MODAL_USERNAME, record.userName);
    formModal.setFieldValue(NAME.MODAL_NAME, record.name);
    formModal.setFieldValue(NAME.MODAL_COMPANY_CODE, record.companyCode);
    formModal.setFieldValue(NAME.MODAL_STORE_CODE, record.storeCode);
    formModal.setFieldValue(NAME.MODAL_ROLE, record.role);
  };

  const handleSetSearchParams = (values: TFormUserReq) => {
    searchParams.set(NAME.USERNAME, values.username || "");
    searchParams.set(NAME.NAME, values.name || "");
    searchParams.set(NAME.COMPANY, values.companyCode || "");
    searchParams.set(NAME.STORE, values.storeCode || "");
    searchParams.set(NAME.ROLE, String(values.role) || "");
    setSearchParams(searchParams, { replace: true });
  };

  // Modal success
  const handleCloseModalSuccess = () => {
    setModalSuccess(false);
    setModalType(null);
    setRowKey(null);
  };

  const handleModalSuccess = () => {
    setModalSuccess(true);
    setModalType(null);
    if (modalTypeConfirm === EModalType.CONFIRM_REGISTRATION_FINAL) {
      setModalType(EModalType.SUCCESS_REGISTRATION);
    }
    if (modalTypeConfirm === EModalType.CONFIRM_UPDATE_FINAL) {
      setModalType(EModalType.SUCCESS_UPDATE);
    }
  };

  // Modal fail
  const handleModalFail = () => {
    setModalSuccess(true);

    if (modalTypeConfirm === EModalType.CONFIRM_REGISTRATION_FINAL) {
      setModalType(EModalType.FAIL_REGISTRATION);
    }

    if (modalTypeConfirm === EModalType.CONFIRM_UPDATE_FINAL) {
      setModalType(EModalType.FAIL_UPDATE);
    }
  };

  const handleCommonError = () => {
    setModalConfirm(false);
    setModalType(null);
    setUser(null);
    setRowKey(null);
    setModalOpen(false);
  };

  const handleDeleteFail = (message: string) => {
    if (message) {
      setModalType(TITLE.SELF_DELETE);
    } else {
      setModalType(TITLE.NOT_FOUND_STORE);
    }
    setModalSuccess(true);
    setModalConfirm(false);
    setModalDelete(false);
    setRowKey(null);
  };

  // Modal confirm final
  const handleOpenModalConfirmFinal = () => {
    setModalConfirm(true);
    if (modalType === EModalType.CONFIRM_REGISTRATION) {
      setModalTypeConfirm(EModalType.CONFIRM_REGISTRATION_FINAL);
    }

    if (modalType === EModalType.CONFIRM_UPDATE) {
      setModalTypeConfirm(EModalType.CONFIRM_UPDATE_FINAL);
    }
  };

  const handleOkModalConfirmFinal = () => {
    handleOkModal();
  };

  const handleCloseModalConfirmFinal = () => setModalConfirm(false);

  // Modal registration
  const handleOpenModalRegistration = () => {
    setModalOpen(true);
    setStoreModal([]);
    setModalType(EModalType.REGISTRATION);
  };

  // Modal detail
  const handleOpenModalDetail = (record: TListUserTable) => {
    setModalOpen(true);
    setModalType(EModalType.DETAIL);
    setRowKey(record.key);

    handler.detailUser(
      record.id,
      companiesModal,
      setStoreModal,
      handleSetFormValues,
      setLoadingModal
    );
  };

  // Modal update
  const handleOpenModalUpdate = (record: TListUserTable, id: string) => {
    setModalOpen(true);
    setModalType(EModalType.UPDATE);
    setRowKey(record.key);
    handler.detailUser(
      id,
      companiesModal,
      setStoreModal,
      handleSetFormValues,
      setLoadingModal
    );
  };

  const handleCloseModal = () => {
    setModalType(null);
    setUser(null);
    setRowKey(null);
    setModalOpen(false);
  };

  const handleFormResetFieldError = () => {
    formModal.setFields([
      {
        name: [NAME.MODAL_USERNAME],
        errors: [],
      },
      {
        name: [NAME.MODAL_NAME],
        errors: [],
      },
      {
        name: [NAME.MODAL_PASSWORD],
        errors: [],
      },
      {
        name: [NAME.MODAL_STORE_CODE],
        errors: [],
      },
      {
        name: [NAME.MODAL_COMPANY_CODE],
        errors: [],
      },
      {
        name: [NAME.MODAL_ROLE],
        errors: [],
      },
    ]);
  };

  const handleValidateForm = () => {
    handleFormResetFieldError();
    let checkError = false;
    const validateUsername =
      userNameWatch && userNameWatch.toString().length < 6;
    const validateName = nameWatch && nameWatch.toString().length > 255;
    const validatePassword =
      (passwordWatch && passwordWatch.toString().length != 8) ||
      !REGEX_CHARACTER_NUMBER.test(passwordWatch)
        ? true
        : false;

    if (validateUsername) {
      formModal.setFields([
        {
          name: [NAME.MODAL_USERNAME],
          errors: [MESSAGE.FIELD_INVALID],
        },
      ]);
      checkError = true;
    }
    if (validateName) {
      formModal.setFields([
        {
          name: [NAME.MODAL_NAME],
          errors: [MESSAGE.FIELD_INVALID],
        },
      ]);
      checkError = true;
    }
    if (validatePassword) {
      formModal.setFields([
        {
          name: [NAME.MODAL_PASSWORD],
          errors: [MESSAGE.FIELD_INVALID],
        },
      ]);
      checkError = true;
    }
    if (roleWatch === 1 && !companyCodeWatch) {
      formModal.setFields([
        {
          name: [NAME.MODAL_COMPANY_CODE],
          errors: [MESSAGE.FIELD_INVALID],
        },
      ]);
      checkError = true;
    }
    if (roleWatch === 1 && !storeCodeWatch) {
      formModal.setFields([
        {
          name: [NAME.MODAL_STORE_CODE],
          errors: [MESSAGE.FIELD_INVALID],
        },
      ]);
      checkError = true;
    }

    if (!checkError) {
      const username = formModal.getFieldValue(NAME.MODAL_USERNAME);
      const companyCode = formModal.getFieldValue(NAME.MODAL_COMPANY_CODE);
      const storeCode = formModal.getFieldValue(NAME.MODAL_STORE_CODE);
      const role = formModal.getFieldValue(NAME.MODAL_ROLE);

      formModal.setFields([
        { name: [NAME.MODAL_USERNAME], errors: [] },
        { name: [NAME.MODAL_COMPANY_CODE], errors: [] },
        { name: [NAME.MODAL_STORE_CODE], errors: [] },
      ]);

      if (modalType === EModalType.REGISTRATION) {
        handler.checkExistUser(
          { username, companyCode, storeCode, role },
          formModal,
          EModalType.CONFIRM_REGISTRATION,
          setModalType,
          setLoadingExist
        );
      }

      if (modalType === EModalType.UPDATE) {
        if (role === 1) {
          handler.checkUpdateUserExist(
            { id: recordId, companyCode, storeCode, role },
            formModal,
            EModalType.CONFIRM_UPDATE,
            setModalType,
            setLoadingExist
          );
        } else {
          setModalType(EModalType.CONFIRM_UPDATE);
        }
      }
    }
  };

  const handleResetOther = () => {
    searchParams.delete(NAME.STORE);
    form.setFieldValue(NAME.STORE, undefined);
    setStores([OPTION_EMPTY]);
  };

  const getStoreByCompany = async (
    payload: TGetStoreByCompany,
    form?: FormInstance
  ) => {
    setLoadingStore(true);
    try {
      const response = await layoutApi.getStoresByCompany(payload);

      if (response.ok) {
        const convertStores = response.data.map((val) => ({
          value: val.storeCode,
          label: val.storeCode + "_" + val.storeName,
        }));
        setStores([OPTION_EMPTY, ...convertStores]);
        setStoreModal(convertStores);
        if (form) {
          form.setFieldValue(NAME.STORE, OPTION_EMPTY.value);
        }
      }
    } catch (error) {
      setStores([]);
      setStoreModal([]);
      handleShowToastError(error);
    } finally {
      setLoadingStore(false);
    }
  };

  const handleChangeCompany = (val: string) => {
    if (val === "") {
      setDisableStore(true);
    }
    if (val) {
      setDisableStore(false);
      const findCompany = _.find(companies, (company) => company.value === val);
      getStoreByCompany(
        {
          companyCode: findCompany?.value as string,
          companyName: findCompany?.label.split("_")[1] as string,
        },
        form
      );
    }
    handleResetOther();
  };

  const handleChangeRole = (val: string | number) => {
    if (val === "") {
      setDisableStore(true);
      setDisableCompany(false);
      form.setFieldValue(NAME.COMPANY, "");
      form.setFieldValue(NAME.STORE, "");
    }
    if (val === 1) {
      setDisableCompany(false);
      setDisableStore(false);
      form.setFieldValue(NAME.COMPANY, companies[1]?.value);
      getStoreByCompany(
        {
          companyCode: companies[1]?.value,
          companyName: companies[1].label.split("_")[1],
        },
        form
      );
    }
    if (val === 2 || val === 3) {
      setDisableCompany(true);
      setDisableStore(true);
      form.setFieldValue(NAME.COMPANY, "");
      form.setFieldValue(NAME.STORE, "");
    }
  };

  const handleChangeCompanyModal = (val: string) => {
    setStoreModal([]);
    formModal.setFieldValue(NAME.MODAL_STORE_CODE, undefined);
    const findCompany = _.find(
      companiesModal,
      (company) => company.value === val
    );
    getStoreByCompany({
      companyCode: findCompany?.value as string,
      companyName: findCompany?.label.split("_")[1] as string,
    });
  };

  const handleChangeRoleModal = (val: number) => {
    if (val != 1) {
      formModal.resetFields([NAME.MODAL_COMPANY_CODE]);
      formModal.resetFields([NAME.MODAL_STORE_CODE]);
    } else {
      setStoreModal([OPTION_EMPTY]);
    }
  };

  // Modal delete store
  const handleOpenModalDelete = (record: TListUserTable) => {
    setUser(record);
    setRowKey(record.key);
    setModalDelete(true);
  };

  const handleCloseModalDelete = () => {
    setUser(null);
    setRowKey(null);
    setModalDelete(false);
  };

  const handleFinish = (values: TListUserReq) => {
    // setListUser([]);
    // setLoadedIds(new Set([]));
    setPageIndex(1);
    getListUserSearch({
      ...values,
      pageIndex: 0,
    });
    tblRef.current?.scrollTo({ top: 0 });
  };

  const handleScroll = (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (
      scrollTop + clientHeight >= scrollHeight - 5 &&
      !loadingPage &&
      hasNext
    ) {
      setPageIndex((prev) => prev + 1);
      getListUserScroll({
        name: userNameParam,
        username: nameParam,
        companyCode: companyParam,
        role: Number(roleParam),
        storeCode: storeParam,
        pageIndex: pageIndex + 1,
      });
    }
  };

  const handleOkModalSuccess = () => {
    setModalOpen(false);
    handleModalSuccess();
    handleCloseModalConfirmFinal();

    // setListUser([]);
    setPageIndex(1);
    getListUserSearch({
      username: userNameParam,
      name: nameParam,
      companyCode: companyParam,
      storeCode: storeParam,
      role: Number(roleParam),
      pageIndex: 0,
    });
    tblRef.current?.scrollTo({ top: 0 });
  };

  const renderTextModal = useMemo(() => {
    switch (modalType) {
      case EModalType.REGISTRATION:
        return {
          title: TITLE.USER_REGISTRATION,
          okText: TITLE.BTN_NEXT,
          message: "",
          image: undefined,
        };
      case EModalType.DETAIL:
        return {
          title: TITLE.USER_DETAIL,
          okText: TITLE.BTN_CLOSE,
          message: "",
          image: undefined,
        };
      case EModalType.UPDATE:
        return {
          title: TITLE.USER_UPDATE,
          okText: TITLE.BTN_NEXT,
          message: "",
          image: undefined,
        };

      case EModalType.CONFIRM_REGISTRATION:
        return {
          title: TITLE.USER_REGISTRATION,
          okText: TITLE.BTN_MODAL_REGIST,
          message: "",
          image: undefined,
        };
      case EModalType.CONFIRM_UPDATE:
        return {
          title: TITLE.USER_UPDATE,
          okText: TITLE.USER_UPDATE,
          message: "",
          image: undefined,
        };

      case EModalType.SUCCESS_REGISTRATION:
        return {
          title: TITLE.USER_REGISTRATION,
          okText: TITLE.BTN_OK,
          message: MESSAGE.SUCCESS_REGISTER,
          image: images.confirmApproveTemperature,
        };
      case EModalType.SUCCESS_UPDATE:
        return {
          title: TITLE.USER_UPDATE,
          okText: TITLE.BTN_OK,
          message: MESSAGE.SUCCESS_UPDATE,
          image: images.confirmApproveTemperature,
        };
      case EModalType.SUCCESS_DELETE:
        return {
          title: TITLE.SUCCESS_DELETE,
          okText: TITLE.BTN_OK,
          message: MESSAGE.SUCCESS_DELETE,
          image: images.confirmApproveTemperature,
        };

      case EModalType.FAIL_REGISTRATION:
        return {
          title: TITLE.FAIL_REGISTER,
          okText: TITLE.BTN_OK,
          message: MESSAGE.FAIL_REGISTER,
          image: images.importDataFail,
        };
      case EModalType.FAIL_UPDATE:
        return {
          title: TITLE.FAIL_REGISTER,
          okText: TITLE.BTN_OK,
          message: MESSAGE.FAIL_UPDATE,
          image: images.importDataFail,
        };
      case EModalType.FAIL_DELETE:
        return {
          title: TITLE.FAIL_DELETE,
          okText: TITLE.BTN_OK,
          message: MESSAGE.FAIL_DELETE,
          image: images.importDataFail,
        };
      case TITLE.NOT_FOUND_STORE:
        return {
          title: TITLE.FAIL_DELETE,
          okText: TITLE.BTN_OK,
          message: MESSAGE.NOT_FOUND_STORE,
          image: images.importDataFail,
        };
      case TITLE.SELF_DELETE:
        return {
          title: TITLE.FAIL_DELETE,
          okText: TITLE.BTN_OK,
          message: MESSAGE.SELF_DELETE_ACCOUNT,
          image: images.importDataFail,
        };

      default:
        return {
          title: "",
          okText: "",
          message: "",
          image: undefined,
        };
    }
  }, [modalType]);

  const renderTextModalConfirm = useMemo(() => {
    switch (modalTypeConfirm) {
      case EModalType.CONFIRM_REGISTRATION_FINAL:
        return {
          title: TITLE.CONFIRM_REGISTRATION_FINAL,
          okText: TITLE.BTN_OK,
          message: MESSAGE.CONFIRM_REGISTRATION_FINAL,
        };
      case EModalType.CONFIRM_UPDATE_FINAL:
        return {
          title: TITLE.CONFIRM_UPDATE_FINAL,
          okText: TITLE.BTN_OK,
          message: MESSAGE.CONFIRM_UPDATE_FINAL,
        };

      default:
        return {
          title: "",
          okText: "",
          message: "",
        };
    }
  }, [modalTypeConfirm]);

  // API
  const handleOkModalDelete = async () => {
    if (user) {
      if (user.userName === username) {
        handleDeleteFail(MESSAGE.SELF_DELETE_ACCOUNT);
      } else {
        handler.deleteUser(
          user.id,
          listUser,
          setListUser,
          setLoadingPage,
          setModalSuccess,
          setModalType,
          handleCloseModalDelete,
          handleDeleteFail
        );
      }
    }
  };

  const handleOkModal = () => {
    const params: TRegisUserReq = {
      username: userNameWatch,
      name: nameWatch || "",
      password: passwordWatch || "",
      companyCode: companyCodeWatch || "",
      storeCode: storeCodeWatch || "",
      role: roleWatch,
    };
    if (modalTypeConfirm === EModalType.CONFIRM_REGISTRATION_FINAL) {
      handler.registrationUser(
        params,
        handleOkModalSuccess,
        setLoadingPage,
        handleModalFail,
        handleCommonError
      );
    }

    if (modalTypeConfirm === EModalType.CONFIRM_UPDATE_FINAL) {
      handler.updateUser(
        { ...params, id: recordId },
        handleOkModalSuccess,
        setLoadingPage,
        handleModalFail,
        handleCommonError
      );
    }
  };

  const getListUserSearch = async (payload: TListUserReq) => {
    handler.getListUserSearch(
      payload,
      setLoadingPage,
      setListUser,
      handleSetSearchParams,
      setPageIndex,
      setHasNext
    );
  };

  const getListUserScroll = async (payload: TListUserReq) => {
    handler.getListUserScroll(
      payload,
      listUser,
      pageIndex,
      // loadedIds,
      setLoadingPage,
      setListUser,
      // setLoadedIds,
      setPageIndex,
      setHasNext
    );
  };

  const fetchInitialApis = async () => {
    handler.fetchInitialApis(
      pageIndex,
      setLoadingPage,
      setLoadingCompany,
      handleSetSearchParams,
      setCompanies,
      setStores,
      setRoles,
      setListUser,
      setCompaniesModal,
      setRolesModal,
      setHasNext,
      setPageIndex
    );
  };

  const fetchApiRedirect = async (payload: TFormUserReq) => {
    handler.fetchApiRedirect(
      payload,
      pageIndex,
      setLoadingPage,
      setLoadingCompany,
      handleSetSearchParams,
      setCompanies,
      setRoles,
      setStores,
      setListUser,
      setCompaniesModal,
      setRolesModal,
      setHasNext,
      setPageIndex
    );
  };

  const columns = useMemo(() => {
    const columns = getColumns({
      disableBtnOther,
      handleOpenModalDelete,
      handleOpenModalDetail,
      handleOpenModalUpdate,
    });
    return columns;
  }, [companiesModal]);

  // EFFECT
  useEffect(() => {
    if (companyParam && storeParam && roleParam) {
      fetchApiRedirect({
        username: userNameParam,
        name: nameParam,
        companyCode: companyParam,
        storeCode: storeParam,
        role: Number(roleParam),
      });
      form.setFieldValue(NAME.ROLE, Number(roleParam));
    } else {
      fetchInitialApis();
      form.setFieldValue(NAME.ROLE, 1);
    }
  }, []);

  useEffect(() => {
    effect.effectCompany(companyParam, form, companies);
  }, [companies]);

  useEffect(() => {
    effect.effectStore(storeParam, form);
  }, [stores]);

  return {
    state: {
      columns,
      listUser,
      user,
      loadingPage,
      rowKey,
      tblRef,

      form,
      companies,
      stores,
      roles,
      loadingCompany,
      loadingStore,

      disableBtnOther,
      disableBtnNext,
      disableFields,
      disableFieldsEdit,

      loadingExist,
      loadingModal,
      companiesModal,
      formModal,
      storeModal,
      rolesModal,
      renderTextModal,
      renderTextModalConfirm,
      modalSuccess,
      modalOpen,
      modalDelete,
      modalType,
      modalConfirm,
      roleWatch,
      disableCompany,
      disableStore,
      disableCompanyStore,
    },
    handler: {
      setModalType,
      handleFinish,
      handleScroll,
      handleChangeCompany,
      handleChangeRole,

      handleChangeCompanyModal,
      handleChangeRoleModal,
      handleOpenModalRegistration,
      handleCloseModal,
      handleCloseModalDelete,
      handleOkModalDelete,
      handleOkModal,
      handleCloseModalSuccess,
      handleOpenModalConfirmFinal,
      handleValidateForm,
      handleCloseModalConfirmFinal,
      handleOkModalConfirmFinal,
    },
  };
}

export default useUserPage;
