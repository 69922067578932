import { Tooltip } from "antd";
import { ALL_DEVICE_CONSTANT, BATTERY_LEVEL_LIMIT } from "./constant";
import { COLOR_TOOLTIP, DATA_EMPTY } from "@constants/common";
import { TableProps } from "antd/lib";
import { SortOrder } from "antd/es/table/interface";
import { TAllStoreStatusConvertData } from "@models/store";

const { TITLE, TABLE_KEY } = ALL_DEVICE_CONSTANT;

const sortDirections: SortOrder[] = ["ascend", "descend", "ascend"];

const renderNumberAlarm = (value: number) => {
  if (!Number.isFinite(value)) {
    return DATA_EMPTY;
  } else {
    return value;
  }
};

const renderNumberTemperature = (value: number) => {
  if (!Number.isFinite(value)) {
    return TITLE.NONE;
  } else {
    return value + "°C";
  }
};

const renderValueTooltip = (value: string) => {
  return value ? (
    <span className="all-device_ellipsis">
      <Tooltip
        color="white"
        overlayClassName="common_custom-tooltip"
        title={value}
        overlayInnerStyle={{
          color: COLOR_TOOLTIP,
        }}
      >
        {value}
      </Tooltip>
    </span>
  ) : (
    TITLE.NONE
  );
};

const renderDefaultSortOrder = (
  _sortField: string,
  _sortDirection: string,
  val: string,
  handleSortOrderV2: (val: string) => SortOrder
) => {
  return _sortField === val ? handleSortOrderV2(_sortDirection) : undefined;
};

export const getColumns = (
  searchParams: URLSearchParams,
  handleRedirectPage: (record: TAllStoreStatusConvertData) => void,
  handleSortOrderV2: (val: string) => SortOrder
): TableProps<TAllStoreStatusConvertData>["columns"] => {
  const _sortField = searchParams.get("sortField") || "storecode";
  const _sortDirection = searchParams.get("sortDirection") || "asc";

  return [
    {
      title: TITLE.AREA,
      key: TABLE_KEY.AREA,
      dataIndex: TABLE_KEY.AREA,
      sorter: true,
      sortDirections,
      defaultSortOrder: renderDefaultSortOrder(
        _sortField,
        _sortDirection,
        "areaname",
        handleSortOrderV2
      ),
      width: 90,
      align: "center",
      render: (value) => {
        return value ? (
          <span className="all-device_ellipsis">
            <Tooltip
              color="white"
              overlayClassName="common_custom-tooltip"
              title={value}
              overlayInnerStyle={{
                color: COLOR_TOOLTIP,
              }}
            >
              {value}
            </Tooltip>
          </span>
        ) : (
          TITLE.NONE
        );
      },
    },
    {
      title: TITLE.STORE_CODE,
      key: TABLE_KEY.STORE_CODE,
      dataIndex: TABLE_KEY.STORE_CODE,
      defaultSortOrder: renderDefaultSortOrder(
        _sortField,
        _sortDirection,
        "storecode",
        handleSortOrderV2
      ),
      sorter: true,
      sortDirections,
      width: 200,
      align: "center",
      render: (value) => renderValueTooltip(value),
    },
    {
      title: TITLE.STORE_NAME,
      key: TABLE_KEY.STORE_NAME,
      dataIndex: TABLE_KEY.STORE_NAME,
      sorter: true,
      sortDirections,
      defaultSortOrder: renderDefaultSortOrder(
        _sortField,
        _sortDirection,
        "storename",
        handleSortOrderV2
      ),
      width: 190,
      align: "center",
      render: (value) => renderValueTooltip(value),
    },
    {
      title: TITLE.CH,
      key: TABLE_KEY.CH,
      dataIndex: TABLE_KEY.CH,
      sorter: true,
      sortDirections,
      defaultSortOrder: renderDefaultSortOrder(
        _sortField,
        _sortDirection,
        "chcode",
        handleSortOrderV2
      ),
      width: 180,
      align: "center",
      render(value, record) {
        return value ? (
          <span
            onClick={() => handleRedirectPage(record)}
            className="all-device_ellipsis all-device_link"
          >
            <Tooltip
              color="white"
              overlayClassName="common_custom-tooltip"
              title={value}
              overlayInnerStyle={{
                color: COLOR_TOOLTIP,
              }}
            >
              {value}
            </Tooltip>
          </span>
        ) : (
          TITLE.NONE
        );
      },
    },
    {
      title: TITLE.MANUFACTURER,
      key: TABLE_KEY.MANUFACTURER,
      dataIndex: TABLE_KEY.MANUFACTURER,
      width: 200,
      sorter: true,
      sortDirections,
      defaultSortOrder: renderDefaultSortOrder(
        _sortField,
        _sortDirection,
        "manufacturername",
        handleSortOrderV2
      ),
      align: "center",
      render: (value) => renderValueTooltip(value),
    },
    {
      title: TITLE.PURPOSE,
      key: TABLE_KEY.PURPOSE,
      dataIndex: TABLE_KEY.PURPOSE,
      width: 200,
      sorter: true,
      sortDirections,
      defaultSortOrder: renderDefaultSortOrder(
        _sortField,
        _sortDirection,
        "purposename",
        handleSortOrderV2
      ),
      align: "center",
      render: (value) => renderValueTooltip(value),
    },
    {
      title: TITLE.INSTALL,
      key: TABLE_KEY.INSTALL,
      dataIndex: TABLE_KEY.INSTALL,
      width: 180,
      sorter: true,
      sortDirections,
      defaultSortOrder: renderDefaultSortOrder(
        _sortField,
        _sortDirection,
        "installedlocation",
        handleSortOrderV2
      ),
      align: "center",
      render: (value) => renderValueTooltip(value),
    },
    {
      title: TITLE.ALARM_SETTING,
      children: [
        {
          title: TITLE.LOWER_LIMIT,
          dataIndex: TABLE_KEY.LOWER_LIMIT,
          key: TABLE_KEY.LOWER_LIMIT,
          width: 100,
          render: (value) => renderNumberTemperature(value),
          sorter: true,
          sortDirections,
          defaultSortOrder: renderDefaultSortOrder(
            _sortField,
            _sortDirection,
            "mintemp",
            handleSortOrderV2
          ),
          align: "center",
        },
        {
          title: TITLE.UPPER_LIMIT,
          dataIndex: TABLE_KEY.UPPER_LIMIT,
          key: TABLE_KEY.UPPER_LIMIT,
          width: 100,
          render: (value) => renderNumberTemperature(value),
          sorter: true,
          sortDirections,
          defaultSortOrder: renderDefaultSortOrder(
            _sortField,
            _sortDirection,
            "maxtemp",
            handleSortOrderV2
          ),
          align: "center",
        },
      ],
    },
    {
      title: TITLE.NUMBER_ALARM,
      children: [
        {
          title: TITLE.NUMBER_3,
          dataIndex: TABLE_KEY.NUMBER_3,
          key: TABLE_KEY.NUMBER_3,
          width: 70,
          sorter: true,
          sortDirections,
          defaultSortOrder: renderDefaultSortOrder(
            _sortField,
            _sortDirection,
            "reporttempsensorthreedaysago",
            handleSortOrderV2
          ),
          align: "center",
          render: (value) => renderNumberAlarm(value),
        },
        {
          title: TITLE.NUMBER_2,
          dataIndex: TABLE_KEY.NUMBER_2,
          key: TABLE_KEY.NUMBER_2,
          width: 70,
          sorter: true,
          sortDirections,
          defaultSortOrder: renderDefaultSortOrder(
            _sortField,
            _sortDirection,
            "reporttempsensortwodaysago",
            handleSortOrderV2
          ),
          align: "center",
          render: (value) => renderNumberAlarm(value),
        },
        {
          title: TITLE.NUMBER_1,
          dataIndex: TABLE_KEY.NUMBER_1,
          key: TABLE_KEY.NUMBER_1,
          width: 70,
          sorter: true,
          sortDirections,
          defaultSortOrder: renderDefaultSortOrder(
            _sortField,
            _sortDirection,
            "reporttempsensoronedayago",
            handleSortOrderV2
          ),
          align: "center",
          render: (value) => renderNumberAlarm(value),
        },
      ],
    },
    {
      title: TITLE.BATTERY_LEVEL,
      key: TABLE_KEY.BATTERY_LEVEL,
      dataIndex: TABLE_KEY.BATTERY_LEVEL,
      width: 140,
      sorter: true,
      sortDirections,
      defaultSortOrder: renderDefaultSortOrder(
        _sortField,
        _sortDirection,
        "batteryvalue",
        handleSortOrderV2
      ),
      align: "center",
      render(value) {
        if (!Number.isFinite(value)) {
          return value;
        } else if (value >= BATTERY_LEVEL_LIMIT) {
          return <span className="all-device_battery-high">{value}%</span>;
        } else {
          return <span className="all-device_battery-low">{value}%</span>;
        }
      },
    },
  ];
};
