export const LOGIN_CONSTANT = {
  NAME: {
    USERNAME: "username",
    PASSWORD: "password",
  },
  LABEL: {
    ID: "ID",
    PASSWORD: "パスワード",
  },
  TITLE: {
    LOGIN: "ログイン",
    DESCRIPTION: "ログインするには、以下の情報を入力してください。",
  },
  MESSAGE: {
    WRONG_INFO:
      "IDまたはパスワードが正しくありません。もう一度お試しください。",
  },
  PLACEHOLDER: {
    INPUT: "入力",
  },
};
export const REGEX_ID = /^[a-zA-Z0-9]{1,6}$/;
export const REGEX_PASSWORD = /^[a-zA-Z0-9]{1,8}$/;
