import styled from "styled-components";

export const LayoutDiagramWrapper = styled.div<{
  hasimage: string;
}>`
  .layout-diagram_header {
    h1 {
      line-height: 40px;
    }
  }

  .layout-diagram_form {
    margin: 24px 0 20px;

    form {
      display: flex;
      align-items: center;
      column-gap: 16px;

      .ant-btn-default {
        width: 56px;
        height: 56px;
        background: #d7e7ff !important;
        border: none;
        margin-top: 28px;
      }

      .ant-form-item {
        width: 290px;
        margin-bottom: 0;

        .ant-select-single,
        .ant-input-affix-wrapper {
          height: 56px;
        }

        .ant-select-selector {
          padding: 16px;
        }

        .ant-form-item-label {
          padding: 0 0 4px;

          label {
            font-weight: var(--font-normal);
          }
        }

        .ant-select-selection-item {
          font-size: var(--font-size-xl);
          font-weight: var(--font-medium);
          line-height: 24px;
        }

        .ant-select-arrow {
          inset-inline-end: 16px;
        }

        .ant-select-disabled .ant-select-selector {
          background-color: var(--color-border-secondary);
        }
      }
    }
  }

  .layout-diagram_container {
    height: 796px;
    background-color: ${(props) =>
      props.hasimage ? "#344054" : "var(--color-border-secondary)"};
    border-radius: 8px;
    position: relative;

    .layout-diagram_result {
      height: inherit;
      overflow: hidden;
      position: relative;

      .react-draggable {
        width: 1078px;
        margin: 0 auto;
      }

      img {
        height: 796px;
        cursor: move;
        transition: all 0.3s ease;
      }

      .layout_toolbar {
        position: fixed;
        bottom: 77px;
        left: 50%;
        padding: 8px 35px;
        color: #fff;
        font-size: 20px;
        background-color: #333333;
        border-radius: 32px;
        transform: translateX(-50%);
        display: flex;
        column-gap: 52px;
        height: 48px;
      }

      .layout_toolbar button:first-child {
        margin-right: 22px;
      }

      .layout_toolbar button:hover:enabled {
        opacity: 0.3;
        background: transparent !important;
        border-color: transparent !important;
        color: transparent !important;
      }

      .layout_toolbar .ant-btn-default {
        background: transparent;
        border-color: transparent;
      }
    }

    .layout-diagram_result_no {
      background-color: var(--color-border-secondary);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      div {
        width: 300px;
        height: 200px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      p {
        margin-top: 32px;
        font-weight: var(--font-medium);
        font-size: var(--font-size-base);
        text-align: center;
        color: var(--color-text-secondary);
      }
    }
  }
`;
