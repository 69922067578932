import { LoadingOutlined } from "@ant-design/icons";
import {
  ArrowSelect,
  DatePickerIcon,
  IconPlusRegister,
  LabelForm,
  LoadingCommon,
  TableList,
} from "@components";
import { images } from "@constants";
import {
  DATE_FORMAT,
  PLACEHOLDER_INPUT,
  PLACEHOLDER_SELECT,
} from "@constants/common";
import { ArrowLeftIcon, SearchIcon } from "@constants/svgs";
import { Button, DatePicker, Form, Input, InputNumber, Select } from "antd";
import { EModalType } from "types/others";
import {
  HEIGHT_TABLE_SCROLL,
  LIST_KEYS_ALLOW_PHONE,
  MAX_LENGTH_11,
  MAX_LENGTH_255,
  MAX_LENGTH_3,
  MAX_LENGTH_50,
  STORE_CONSTANT,
} from "./constant";
import useStorePage from "./hooks/useStorePage";
import { ModalDeleteWrapper, ModalWrapper, StoreWrapper } from "./StoreStyled";

const { TITLE, LABEL, NAME, MESSAGE } = STORE_CONSTANT;

const FIELDS_CONFIG = {
  COMPANY: { required: false, label: LABEL.COMPANY, name: NAME.COMPANY },
  AREA: { required: false, label: LABEL.AREA, name: NAME.AREA },
  STORE: { required: false, label: LABEL.STORE, name: NAME.STORE },

  COMPANY_CODE: {
    required: false,
    label: <LabelForm label={LABEL.COMPANY_NAME} />,
    name: NAME.COMPANY_CODE,
  },
  STORE_CODE: {
    required: false,
    label: <LabelForm label={LABEL.STORE_CODE} />,
    name: NAME.STORE_CODE,
  },
  PANASONIC_ID: {
    required: false,
    label: LABEL.PANASONIC_ID,
    name: NAME.PANASONIC_ID,
  },
  KIWI_ID: { required: false, label: LABEL.KIWI_ID, name: NAME.KIWI_ID },
  MOBILE_PHONE: {
    required: false,
    label: LABEL.MOBILE_PHONE,
    name: NAME.MOBILE_PHONE,
  },
  OPENING_DATE: {
    required: false,
    label: LABEL.OPENING_DATE,
    name: NAME.OPENING_DATE,
  },
  EMAIL: { required: false, label: LABEL.EMAIL, name: NAME.EMAIL },
  AREA_CODE: {
    required: false,
    label: <LabelForm label={LABEL.AREA_CODE} />,
    name: NAME.AREA_CODE,
  },
  OKAMURA_ID: {
    required: false,
    label: LABEL.OKAMURA_ID,
    name: NAME.OKAMURA_ID,
  },
  STORE_NAME: {
    required: false,
    label: <LabelForm label={LABEL.STORE_NAME} />,
    name: NAME.STORE_NAME,
  },
  PURPOSE: { required: false, label: LABEL.PURPOSE, name: NAME.LAND_AREA },
  CLOSING_DATE: {
    required: false,
    label: LABEL.CLOSING_DATE,
    name: NAME.CLOSING_DATE,
  },
};

function StorePage() {
  const { state, handler } = useStorePage();

  return (
    <LoadingCommon loading={state.loadingPage}>
      <StoreWrapper listdata={state.listStore.length}>
        <div className="store-page_header">
          <h1>{TITLE.STORE_LIST}</h1>
          <Button
            onClick={handler.handleOpenModalRegistration}
            type="primary"
            icon={<IconPlusRegister />}
            disabled={state.disableBtnOther}
          >
            {TITLE.BTN_REGISTRATION}
          </Button>
        </div>

        <div className="store-page_form">
          <Form
            onFinish={handler.handleFinish}
            form={state.form}
            layout="vertical"
          >
            {/* COMPANY */}
            <Form.Item {...FIELDS_CONFIG.COMPANY}>
              <Select
                placeholder={PLACEHOLDER_SELECT}
                allowClear={false}
                size="large"
                options={state.companies}
                onChange={(val) => handler.handleChangeCompany(val)}
                suffixIcon={
                  state.loadingCompany ? (
                    <LoadingOutlined spin={state.loadingCompany} />
                  ) : (
                    <ArrowSelect />
                  )
                }
              />
            </Form.Item>

            {/* AREA */}
            <Form.Item {...FIELDS_CONFIG.AREA}>
              <Select
                placeholder={PLACEHOLDER_SELECT}
                allowClear={false}
                size="large"
                options={state.areas}
                disabled={state.loadingArea}
                onChange={(val) => handler.handleChangeArea(val)}
                suffixIcon={
                  state.loadingArea ? (
                    <LoadingOutlined spin={state.loadingArea} />
                  ) : (
                    <ArrowSelect />
                  )
                }
              />
            </Form.Item>

            {/* STORE */}
            <Form.Item {...FIELDS_CONFIG.STORE}>
              <Select
                placeholder={PLACEHOLDER_SELECT}
                allowClear={false}
                size="large"
                options={state.stores}
                disabled={state.loadingStore}
                suffixIcon={
                  state.loadingStore ? (
                    <LoadingOutlined spin={state.loadingStore} />
                  ) : (
                    <ArrowSelect />
                  )
                }
              />
            </Form.Item>

            <Button htmlType="submit" icon={<SearchIcon />} />
          </Form>
        </div>

        <TableList
          tableRef={state.tblRef}
          image={images.noResult}
          columns={state.columns}
          handleScroll={handler.handleScroll}
          heightTableScroll={HEIGHT_TABLE_SCROLL}
          listData={state.listStore}
          messageNoData={MESSAGE.NO_DATA}
          rowKey={state.rowKey}
          borderColor="var(--color-border-secondary)"
        />
      </StoreWrapper>

      <ModalWrapper
        width={800}
        title={
          <>
            {state.modalType === EModalType.CONFIRM_REGISTRATION && (
              <button
                onClick={() => handler.setModalType(EModalType.REGISTRATION)}
              >
                <ArrowLeftIcon />
              </button>
            )}
            {state.modalType === EModalType.CONFIRM_UPDATE && (
              <button onClick={() => handler.setModalType(EModalType.UPDATE)}>
                <ArrowLeftIcon />
              </button>
            )}
            <span>{state.renderTextModal.title}</span>
          </>
        }
        closeIcon={false}
        maskClosable={false}
        okText={state.renderTextModal.okText}
        cancelText={TITLE.BTN_CANCEL}
        destroyOnClose={true}
        open={state.modalOpen}
        centered
        onCancel={() => {
          handler.handleCloseModal();
        }}
        loading={state.loadingModal}
        confirmLoading={state.loadingExist}
        onOk={() => {
          if (state.modalType === EModalType.DETAIL) {
            handler.handleCloseModal();
          } else if (state.modalType === EModalType.UPDATE) {
            handler.handleValidateForm();
          } else if (state.modalType === EModalType.CONFIRM_REGISTRATION) {
            handler.handleOpenModalConfirmFinal();
          } else if (state.modalType === EModalType.CONFIRM_UPDATE) {
            handler.handleOpenModalConfirmFinal();
          } else {
            handler.handleValidateForm();
          }
        }}
        okButtonProps={{
          style: {
            width: state.modalType === EModalType.DETAIL ? "100%" : "368px",
          },
          disabled: state.disableBtnNext,
        }}
        cancelButtonProps={{
          style: {
            display: state.modalType === EModalType.DETAIL ? "none" : "block",
          },
        }}
      >
        <Form
          layout="vertical"
          form={state.formModal}
          preserve={false}
          disabled={state.disableFields}
        >
          <div className="store-page_modal-form-left">
            <Form.Item {...FIELDS_CONFIG.COMPANY_CODE}>
              <Select
                placeholder={PLACEHOLDER_SELECT}
                allowClear={false}
                size="large"
                options={state.companiesModal}
                onChange={(val) => handler.handleChangeCompanyModal(val)}
                suffixIcon={
                  <ArrowSelect disable={state.disableFieldsEdit} isModal />
                }
                disabled={state.disableFieldsEdit}
              />
            </Form.Item>

            <Form.Item {...FIELDS_CONFIG.STORE_CODE}>
              <Input
                placeholder={PLACEHOLDER_INPUT}
                size="large"
                maxLength={MAX_LENGTH_3}
                disabled={state.disableFieldsEdit}
                onKeyDown={(event) => {
                  if (event.code === "Space") {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>

            <Form.Item {...FIELDS_CONFIG.STORE_NAME}>
              <Input
                placeholder={PLACEHOLDER_INPUT}
                size="large"
                maxLength={MAX_LENGTH_50}
                onChange={(event) => {
                  state.formModal.setFieldValue(
                    NAME.STORE_NAME,
                    event.target.value.replaceAll(" ", "")
                  );
                }}
              />
            </Form.Item>

            <Form.Item {...FIELDS_CONFIG.PURPOSE}>
              <InputNumber
                placeholder={PLACEHOLDER_INPUT}
                controls={false}
                size="large"
                keyboard={false}
                onKeyDown={(event) => {
                  if (
                    /^\d*$/.test(event.key) ||
                    [
                      ...LIST_KEYS_ALLOW_PHONE,
                      "Period",
                      "NumpadDecimal",
                    ].includes(event.code)
                  ) {
                    return;
                  }
                  event.preventDefault();
                }}
              />
            </Form.Item>

            <Form.Item {...FIELDS_CONFIG.OPENING_DATE}>
              <DatePicker
                showNow={false}
                size="large"
                format={DATE_FORMAT}
                inputReadOnly={true}
                disabledDate={handler.handleDisableFromDate}
                suffixIcon={<DatePickerIcon disable={state.disableFields} />}
              />
            </Form.Item>

            <Form.Item {...FIELDS_CONFIG.MOBILE_PHONE}>
              <Input
                placeholder={PLACEHOLDER_INPUT}
                maxLength={MAX_LENGTH_11}
                size="large"
                onKeyDown={(event: any) => {
                  if (
                    /^\d*$/.test(event.key) ||
                    LIST_KEYS_ALLOW_PHONE.includes(event.code)
                  ) {
                    return;
                  }

                  event.preventDefault();
                }}
              />
            </Form.Item>
          </div>

          <div className="store-page_modal-form-right">
            <Form.Item {...FIELDS_CONFIG.AREA_CODE}>
              <Select
                placeholder={PLACEHOLDER_SELECT}
                allowClear={false}
                size="large"
                options={state.areasModal}
                disabled={state.loadingAreaModal || state.disableFieldsEdit}
                suffixIcon={
                  state.loadingAreaModal ? (
                    <LoadingOutlined spin={state.loadingAreaModal} />
                  ) : (
                    <ArrowSelect disable={state.disableFieldsEdit} isModal />
                  )
                }
              />
            </Form.Item>

            <Form.Item {...FIELDS_CONFIG.PANASONIC_ID}>
              <Input
                placeholder={PLACEHOLDER_INPUT}
                size="large"
                onChange={(event) => {
                  state.formModal.setFieldValue(
                    NAME.PANASONIC_ID,
                    event.target.value.replaceAll(" ", "")
                  );
                }}
                onKeyDown={(event) => {
                  if (event.code === "Space") {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>

            <Form.Item {...FIELDS_CONFIG.OKAMURA_ID}>
              <Input
                placeholder={PLACEHOLDER_INPUT}
                size="large"
                onChange={(event) => {
                  state.formModal.setFieldValue(
                    NAME.OKAMURA_ID,
                    event.target.value.replaceAll(" ", "")
                  );
                }}
                onKeyDown={(event) => {
                  if (event.code === "Space") {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>

            <Form.Item {...FIELDS_CONFIG.KIWI_ID}>
              <Input
                placeholder={PLACEHOLDER_INPUT}
                size="large"
                onChange={(event) => {
                  state.formModal.setFieldValue(
                    NAME.KIWI_ID,
                    event.target.value.replaceAll(" ", "")
                  );
                }}
                onKeyDown={(event) => {
                  if (event.code === "Space") {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>

            <Form.Item {...FIELDS_CONFIG.CLOSING_DATE}>
              <DatePicker
                showNow={false}
                size="large"
                format={DATE_FORMAT}
                inputReadOnly={true}
                disabledDate={handler.handleDisableToDate}
                suffixIcon={<DatePickerIcon disable={state.disableFields} />}
              />
            </Form.Item>

            <Form.Item {...FIELDS_CONFIG.EMAIL}>
              <Input
                placeholder={PLACEHOLDER_INPUT}
                maxLength={MAX_LENGTH_255}
                size="large"
                onChange={(event) => {
                  state.formModal.setFieldValue(
                    NAME.EMAIL,
                    event.target.value.replaceAll(" ", "")
                  );
                }}
                onKeyDown={(event) => {
                  if (event.code === "Space") {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </div>
        </Form>
      </ModalWrapper>

      {/* Modal delete */}
      <ModalDeleteWrapper
        centered
        closeIcon={false}
        open={state.modalDelete}
        title={TITLE.TITLE_DELETE}
        confirmLoading={state.loadingPage}
        onOk={handler.handleOkModalDelete}
        onCancel={handler.handleCloseModalDelete}
        maskClosable={false}
        cancelText={TITLE.BTN_CANCEL}
        okText={TITLE.BTN_DELETE}
        width={416}
      >
        <img src={images.modalDelete} alt="modal_delete" />
        <div className="store-page_modal-delete">
          <p>
            {state.store?.storeCode}_{state.store?.storeName}
          </p>
          <p>{MESSAGE.CONFIRM_DELETE}</p>
        </div>
      </ModalDeleteWrapper>

      {/* Modal registration/update/delete success/fail */}
      <ModalDeleteWrapper
        centered
        closeIcon={false}
        open={state.modalSuccess}
        title={state.renderTextModal.title}
        onOk={handler.handleCloseModalSuccess}
        maskClosable={false}
        okText={state.renderTextModal.okText}
        width={412}
        okButtonProps={{ style: { width: "100%", marginInlineStart: 0 } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <img src={state.renderTextModal.image} alt="store_modal" />
        <div className="store-page_modal-regist-success">
          <p>{state.renderTextModal.message}</p>
        </div>
      </ModalDeleteWrapper>

      {/* Modal registration/update confirm */}
      <ModalDeleteWrapper
        centered
        closeIcon={false}
        open={state.modalConfirm}
        title={state.renderTextModalConfirm.title}
        onOk={handler.handleOkModalConfirmFinal}
        onCancel={handler.handleCloseModalConfirmFinal}
        maskClosable={false}
        okText={state.renderTextModalConfirm.okText}
        cancelText={TITLE.BTN_CANCEL}
        width={412}
        zIndex={9999}
        confirmLoading={state.loadingPage}
      >
        <img src={images.popUpConfirmFinal} alt="store_modal" />
        <div className="store-page_modal-confirm">
          <p>{state.renderTextModalConfirm.message}</p>
        </div>
      </ModalDeleteWrapper>
    </LoadingCommon>
  );
}

export { StorePage };
