import { Modal } from "antd";
import styled from "styled-components";

export const CompanyWrapper = styled.div<{
  listdata: number;
}>`
  .company-page_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;

    button {
      width: 160px;
      height: 48px;
      border-radius: 8px;
      font-weight: var(--font-medium);
      box-shadow: none;
    }
  }

  .company-page_form {
    form {
      display: flex;
      column-gap: 12px;

      .ant-select-selection-placeholder {
        font-size: var(--font-size-sm);
        font-weight: var(--font-medium);
        color: var(--color-border-primary);
      }

      .ant-btn-default {
        width: 56px;
        height: 56px;
        background: #d7e7ff !important;
        border: none;
        margin-top: 28px;
      }

      .ant-form-item {
        width: 290px;
      }

      .ant-form-item {
        width: 311px;
        margin-bottom: 16px;

        .ant-select-single {
          height: 56px;
        }

        .ant-select-selector {
          padding: 16px;
        }

        .ant-form-item-label {
          padding: 0 0 4px;

          label {
            font-weight: var(--font-normal);
          }
        }

        .ant-select-selection-item {
          font-size: var(--font-size-xl);
          font-weight: var(--font-medium);
          line-height: 24px;
        }

        .ant-select-arrow {
          inset-inline-end: 16px;
        }
      }
    }
  }
`;

export const ModalWrapper = styled(Modal)`
  .ant-modal-header {
    margin-bottom: 0;
    padding: 16px 24px;
    background: none;

    .ant-modal-title {
      display: flex;
      align-items: center;
      column-gap: 12px;

      button {
        width: 48px;
        height: 48px;
        background-color: #edeff1;
        border-radius: 100%;
        border: none;
        position: relative;
        cursor: pointer;

        svg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      span {
        font-weight: var(--font-bold);
        font-size: var(--font-size-base);
        line-height: 24px;
      }
    }
  }

  .ant-modal-content {
    padding: 0;
    background: var(--color-white);
    box-shadow: 0px 2px 8px 0px rgba(5, 40, 97, 0.08);
    border-radius: 12px;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    column-gap: 16px;
    padding: 12px 24px 24px;
    margin-top: 0;

    .ant-btn {
      width: 368px;
      height: 48px;
      box-shadow: none;
      font-weight: var(--font-bold);
      margin-inline-start: 0;
    }

    .ant-btn-default {
      color: var(--color-primary);
      border-color: var(--color-primary);
    }

    .ant-btn-primary {
      margin-inline-start: 0 !important;
    }
  }

  .ant-modal-body {
    padding: 16px 24px;
    border-bottom: 1px solid #f1f3f7;
    border-top: 1px solid #f1f3f7;

    .ant-form {
      display: flex;
      justify-content: space-between;
      column-gap: 16px;

      .ant-select-selection-placeholder,
      input::placeholder {
        font-size: var(--font-size-sm);
        font-weight: var(--font-medium);
        color: var(--color-border-primary);
      }

      .company-page_modal-form-left,
      .company-page_modal-form-right {
        width: 368px;
        .ant-form-item:not(:last-child) {
          margin-bottom: 16px;
        }

        .ant-form-item:last-child {
          margin-bottom: 0;
        }

        .ant-form-item-label {
          padding: 0 0 4px;

          label {
            font-weight: var(--font-normal);
          }
        }

        .ant-picker {
          width: 100%;
          height: 56px;
        }

        .ant-select-lg,
        .ant-input-lg,
        .ant-input-number-lg {
          height: 56px;
        }

        .ant-input-number-lg {
          width: 368px;

          .ant-input-number-input {
            height: 54px;
            padding: 16px;
            font-size: var(--font-size-xl);
            font-weight: var(--font-medium);
          }
        }

        .ant-select-in-form-item {
          width: 368px;

          .ant-select-selector {
            padding: 16px;

            .ant-select-selection-item {
              font-size: var(--font-size-xl);
              font-weight: var(--font-medium);
              line-height: 24px;
            }
          }

          .ant-select-arrow {
            inset-inline-end: 16px;
          }
        }

        .ant-input-lg {
          padding: 16px;
          font-size: var(--font-size-xl);
          font-weight: var(--font-medium);
          line-height: 24px;
        }

        .ant-picker-large {
          padding: 16px;

          input {
            font-size: var(--font-size-xl);
            font-weight: var(--font-medium);
            line-height: 24px;
          }
        }

        /* Disabled */
        .ant-select-disabled .ant-select-selector,
        .ant-input-disabled,
        .ant-input-number-disabled {
          color: var(--color-secondary);
          background: var(--color-border-primary);
        }

        .ant-picker-disabled input {
          color: var(--color-secondary);
        }

        .ant-picker-disabled {
          background: var(--color-border-primary);
        }
      }
    }
  }
`;

export const ModalDeleteWrapper = styled(Modal)`
  .ant-modal-content {
    padding: 0;
    background: var(--color-white);
    box-shadow: 0px 2px 8px 0px rgba(5, 40, 97, 0.08);
    border-radius: 12px;
  }

  .ant-modal-header {
    margin-bottom: 0;
    padding: 16px 24px;
    background: none;

    .ant-modal-title {
      font-weight: var(--font-bold);
      font-size: var(--font-size-base);
      line-height: 24px;
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    column-gap: 16px;
    padding: 12px 24px 24px;
    margin-top: 0;

    .ant-btn {
      width: 202px;
      height: 48px;
      box-shadow: none;
      font-weight: var(--font-bold);
      margin-inline-start: 0;
    }

    .ant-btn-default {
      color: var(--color-primary);
      border-color: var(--color-primary);
    }
  }

  .ant-modal-body {
    padding: 0 24px;
    border-bottom: 1px solid #f1f3f7;
    border-top: 1px solid #f1f3f7;
    text-align: center;

    .company-page_modal-delete {
      margin: 30px 0 24px;

      p:first-child {
        color: var(--color-text-secondary);
        font-weight: var(--font-normal);
        margin-bottom: 16px;
      }

      p:last-child {
        font-weight: var(--font-medium);
        font-size: var(--font-size-sm);
        line-height: 20px;
      }
    }

    .company-page_modal-regist-success,
    .company-page_modal-confirm {
      p {
        font-weight: var(--font-medium);
        font-size: var(--font-size-base);
        line-height: 24px;
      }
    }

    .company-page_modal-regist-success {
      margin: 24px 0 16px;
    }

    .company-page_modal-confirm {
      p {
        margin: 48px 0 40px;
      }
    }
  }
`;
