type Props = {
  disable: boolean;
};

function ZoomOutIcon({ disable }: Props) {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 15.5996H19.1667"
        stroke={`${disable ? "#98a2b3" : "white"}`}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8333 28.0003C22.8289 28.0003 28.5 22.3293 28.5 15.3337C28.5 8.33805 22.8289 2.66699 15.8333 2.66699C8.83769 2.66699 3.16663 8.33805 3.16663 15.3337C3.16663 22.3293 8.83769 28.0003 15.8333 28.0003Z"
        stroke={`${disable ? "#98a2b3" : "white"}`}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.8333 29.3337L27.1666 26.667"
        stroke={`${disable ? "#98a2b3" : "white"}`}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ZoomOutIcon;
