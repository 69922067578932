import { COLOR_TOOLTIP } from "@constants/common";
import { Tooltip } from "antd";
import React from "react";

type Props = {
  children: React.ReactNode;
  title: string;
};

function InputTooltip({ children, title }: Props) {
  return (
    <Tooltip
      color="white"
      overlayInnerStyle={{
        color: COLOR_TOOLTIP,
      }}
      title={title}
    >
      {children}
    </Tooltip>
  );
}

export { InputTooltip };
