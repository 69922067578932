import { TLoginReq, TLoginRes } from "@models/login";
import { authActions } from "@redux/slices/authSlice";
import { authApi } from "@services/authApi";
import { handleShowToastError } from "@utils/helpers";
import { call, put, takeEvery } from "redux-saga/effects";
import { TAxiosResponse, TPayloadAction } from "types";
import { EHttpStatusCode } from "types/service";

// Worker
export function* loginWorker({ payload }: TPayloadAction<TLoginReq>) {
  try {
    const response: TAxiosResponse<TLoginRes> = yield call(
      authApi.login,
      payload.data
    );
    if (response.ok) {
      yield put(
        authActions.loginSuccess({
          data: { ...response.data },
        })
      );
      payload.onSuccess?.();
    }
  } catch (error: any) {
    yield put(authActions.loginFailure());
    // TODO: handle callback | onFailure
    if (!error.data || error.status === EHttpStatusCode.INTERNAL_SERVER_ERROR) {
      payload.onError?.("");
      return;
    }
    payload.onError?.(error?.data?.errors[0]?.code);
  } finally {
    payload.onFinal?.();
  }
}

export function* logoutWorker({ payload }: TPayloadAction<any>) {
  try {
    yield call(authApi.logout);
    yield put(authActions.logoutSuccess());
  } catch (error: any) {
    handleShowToastError(error);
  } finally {
    payload.onFinal?.();
  }
}

//Watcher
export const authWatcher = [
  takeEvery(authActions.login, loginWorker),
  takeEvery(authActions.logout, logoutWorker),
];
