import { AppContext } from "@layouts/LayoutAdmin/LayoutAdmin";
import { TSelectValue } from "@models/common";
import { TCompanyReq } from "@models/layout";
import {
  TFormModalRegisterReq,
  TFormModalRes,
  TFormStoreReq,
  TListStoreReq,
  TListStoreTable,
} from "@models/store";
import { EUserRoles } from "@models/user";
import { DatePicker, Form } from "antd";
import { GetProps, Table } from "antd/lib";
import dayjs from "dayjs";
import _ from "lodash";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { EModalType } from "types/others";
import { getColumns } from "../columns";
import { REGEX_EMAIL, REGEX_STORE_CODE, STORE_CONSTANT } from "../constant";
import useStoreApi from "./useStoreApi";
import {
  DATE_FORMAT,
  OPTION_EMPTY,
  REGEX_DECIMAL_TEMPERATURE,
} from "@constants/common";
import { images } from "@constants";

const { NAME, TITLE, MESSAGE } = STORE_CONSTANT;

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

function useStorePage() {
  const [form] = Form.useForm();
  const [formModal] = Form.useForm();
  const companyIdWatch = Form.useWatch(NAME.COMPANY_CODE, formModal);
  const storeCodeWatch = Form.useWatch(NAME.STORE_CODE, formModal);
  const panasonicIdWatch = Form.useWatch(NAME.PANASONIC_ID, formModal);
  const kiwiIdWatch = Form.useWatch(NAME.KIWI_ID, formModal);
  const mobilePhoneWatch = Form.useWatch(NAME.MOBILE_PHONE, formModal);
  const openingDateWatch = Form.useWatch(NAME.OPENING_DATE, formModal);
  const emailWatch = Form.useWatch(NAME.EMAIL, formModal);
  const areaIdWatch = Form.useWatch(NAME.AREA_CODE, formModal);
  const okamuraIdWatch = Form.useWatch(NAME.OKAMURA_ID, formModal);
  const storeNameWatch = Form.useWatch(NAME.STORE_NAME, formModal);
  const landAreaWatch = Form.useWatch(NAME.LAND_AREA, formModal);
  const closingDateWatch = Form.useWatch(NAME.CLOSING_DATE, formModal);

  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingCompany, setLoadingCompany] = useState(true);
  const [loadingArea, setLoadingArea] = useState(false);
  const [loadingStore, setLoadingStore] = useState(false);
  const [listStore, setListStore] = useState<TListStoreTable[]>([]);
  const [companies, setCompanies] = useState<TSelectValue[]>([]);
  const [stores, setStores] = useState<TSelectValue[]>([]);
  const [areas, setAreas] = useState<TSelectValue[]>([]);
  const [hasNext, setHasNext] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [rowKey, setRowKey] = useState<number | null>(null);
  const [recordId, setRecordId] = useState("");
  //const [loadedIds, setLoadedIds] = useState<Set<string>>(new Set());

  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingAreaModal, setLoadingAreaModal] = useState(false);
  const [loadingExist, setLoadingExist] = useState(false);
  const [areasModal, setAreasModal] = useState<TSelectValue[]>([]);
  const [companiesModal, setCompaniesModal] = useState<TSelectValue[]>([]);
  const [store, setStore] = useState<TListStoreTable | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalType, setModalType] = useState<EModalType | null | string>(null);
  const [modalTypeConfirm, setModalTypeConfirm] = useState<EModalType | null>(
    null
  );
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const companyParam = searchParams.get(NAME.COMPANY) || "";
  const storeParam = searchParams.get(NAME.STORE) || "";
  const areaParam = searchParams.get(NAME.AREA) || "";
  const areaIdParam = searchParams.get(NAME.AREA_ID) || "";

  const { handler, effect } = useStoreApi();
  const { role } = useContext(AppContext);
  const roleAdmin = role ? role[0] === EUserRoles.ADMIN : false;

  const disableBtnOther = roleAdmin ? false : true;

  const disableBtnNext =
    modalType !== EModalType.DETAIL &&
    (!companyIdWatch ||
      !areaIdWatch ||
      !storeCodeWatch ||
      !storeNameWatch ||
      (storeNameWatch && !storeNameWatch.replace(/\s/g, "").length));

  const disableFields =
    modalType === EModalType.CONFIRM_REGISTRATION ||
    modalType === EModalType.CONFIRM_UPDATE ||
    modalType === EModalType.DETAIL;

  const disableFieldsEdit = modalType === EModalType.UPDATE || disableFields;

  const tblRef: Parameters<typeof Table>[0]["ref"] = useRef(null);

  const handleDisableFromDate: RangePickerProps["disabledDate"] = (current) => {
    return closingDateWatch && current.isAfter(closingDateWatch, "day");
  };

  const handleDisableToDate: RangePickerProps["disabledDate"] = (current) => {
    return openingDateWatch && current.isBefore(openingDateWatch, "day");
  };

  const handleSetFormValues = (record: TFormModalRes) => {
    setRecordId(record.id);

    formModal.setFieldValue(NAME.COMPANY_CODE, record.companyId);
    formModal.setFieldValue(NAME.STORE_CODE, record.storeCode);
    formModal.setFieldValue(NAME.PANASONIC_ID, record.panasonicId);
    formModal.setFieldValue(NAME.KIWI_ID, record.kiwiId);
    formModal.setFieldValue(NAME.MOBILE_PHONE, record.storePhone);
    formModal.setFieldValue(
      NAME.OPENING_DATE,
      record.openingDate ? dayjs(record.openingDate) : undefined
    );
    formModal.setFieldValue(NAME.EMAIL, record.email);
    formModal.setFieldValue(NAME.AREA_CODE, record.areaId);
    formModal.setFieldValue(NAME.MANAGER_NAME, record.storeManagerName);
    formModal.setFieldValue(NAME.OKAMURA_ID, record.okamuraId);
    formModal.setFieldValue(NAME.STORE_NAME, record.storeName);
    formModal.setFieldValue(NAME.LAND_AREA, record.landArea);
    formModal.setFieldValue(
      NAME.CLOSING_DATE,
      record.closingDate ? dayjs(record.closingDate) : undefined
    );
  };

  const handleSetSearchParams = (values: TFormStoreReq) => {
    searchParams.set(NAME.COMPANY, values.companyCode || "");
    searchParams.set(NAME.AREA, values.areaCode || "");
    searchParams.set(NAME.STORE, values.storeCode || "");
    searchParams.set(NAME.AREA_ID, values.areaId || "");

    setSearchParams(searchParams, { replace: true });
  };

  // Modal success
  const handleCloseModalSuccess = () => {
    setModalSuccess(false);
    setModalType(null);
    setRowKey(null);
  };

  // TODO: Recheck
  const handleModalSuccess = (_data?: TFormModalRes) => {
    setModalSuccess(true);
    setModalType(null);

    // const _data: TListStoreTable = {
    //   areaId: data.areaId as string,
    //   areaCode: data.areaCode as string,
    //   areaName: data.areaName as string,
    //   closingDate: data.closingDate as string,
    //   companyId: data.companyId as string,
    //   companyCode: data.companyCode as string,
    //   companyName: data.companyName as string,
    //   email: data.email as string,
    //   id: data.id,
    //   isDeleted: data.isDeleted as boolean,
    //   kiwiId: data.kiwiId as string,
    //   landArea: data.landArea as number,
    //   okamuraId: data.okamuraId as string,
    //   openingDate: data.openingDate as string,
    //   panasonicId: data.panasonicId as string,
    //   storeCode: data.storeCode as string,
    //   storeManagerName: data.storeManagerName as string,
    //   storeName: data.storeName as string,
    //   storePhone: data.storePhone as string,
    //   key: 0,
    // };

    if (modalTypeConfirm === EModalType.CONFIRM_REGISTRATION_FINAL) {
      setModalType(EModalType.SUCCESS_REGISTRATION);
      // setLoadedIds((prevIds) => new Set([...prevIds, _data.id]));
      // setListStore([
      //   { ..._data },
      //   ...listStore.map((item, index) => ({ ...item, key: index + 1 })),
      // ]);
    }

    if (modalTypeConfirm === EModalType.CONFIRM_UPDATE_FINAL) {
      setModalType(EModalType.SUCCESS_UPDATE);
      // const _filteredStore = listStore.filter((item) => item.id !== _data.id);
      // setListStore([
      //   { ..._data },
      //   ..._filteredStore.map((item, index) => ({ ...item, key: index + 1 })),
      // ]);
    }
    // tblRef.current?.scrollTo({ index: 0 });
  };

  // Modal fail
  const handleModalFail = () => {
    setModalSuccess(true);

    if (modalTypeConfirm === EModalType.CONFIRM_REGISTRATION_FINAL) {
      setModalType(EModalType.FAIL_REGISTRATION);
    }

    if (modalTypeConfirm === EModalType.CONFIRM_UPDATE_FINAL) {
      setModalType(EModalType.FAIL_UPDATE);
    }
  };

  const handleCommonError = () => {
    setModalConfirm(false);
    setModalType(null);
    setStore(null);
    setRowKey(null);
    setModalOpen(false);
  };

  const handleDeleteFail = () => {
    setModalSuccess(true);
    setModalConfirm(false);
    setModalDelete(false);
    setRowKey(null);
    setModalType(TITLE.NOT_FOUND_STORE);
  };

  // Modal confirm final
  const handleOpenModalConfirmFinal = () => {
    setModalConfirm(true);
    if (modalType === EModalType.CONFIRM_REGISTRATION) {
      setModalTypeConfirm(EModalType.CONFIRM_REGISTRATION_FINAL);
    }

    if (modalType === EModalType.CONFIRM_UPDATE) {
      setModalTypeConfirm(EModalType.CONFIRM_UPDATE_FINAL);
    }
  };

  const handleOkModalConfirmFinal = () => {
    handleOkModal();
  };

  const handleCloseModalConfirmFinal = () => setModalConfirm(false);

  // Modal registration
  const handleOpenModalRegistration = () => {
    setAreasModal([]);
    setModalOpen(true);
    setModalType(EModalType.REGISTRATION);
  };

  // Modal detail
  const handleOpenModalDetail = (record: TListStoreTable) => {
    setModalOpen(true);
    setModalType(EModalType.DETAIL);
    setRowKey(record.key);

    handler.detailStore(
      record.id,
      companiesModal,
      setAreasModal,
      handleSetFormValues,
      setLoadingModal
    );
  };

  // Modal update
  const handleOpenModalUpdate = (record: TListStoreTable, id: string) => {
    setModalOpen(true);
    setModalType(EModalType.UPDATE);
    setRowKey(record.key);

    handler.detailStore(
      id,
      companiesModal,
      setAreasModal,
      handleSetFormValues,
      setLoadingModal
    );
  };

  const handleCloseModal = () => {
    setModalType(null);
    setStore(null);
    setRowKey(null);
    setModalOpen(false);
  };

  const handleFormResetFieldError = () => {
    formModal.setFields([
      {
        name: [NAME.EMAIL],
        errors: [],
      },
      {
        name: [NAME.MOBILE_PHONE],
        errors: [],
      },
      {
        name: [NAME.LAND_AREA],
        errors: [],
      },
      {
        name: [NAME.PANASONIC_ID],
        errors: [],
      },
      {
        name: [NAME.OKAMURA_ID],
        errors: [],
      },
      {
        name: [NAME.STORE_CODE],
        errors: [],
      },
    ]);
  };

  const handleValidateForm = () => {
    handleFormResetFieldError();

    const validateEmail = emailWatch ? REGEX_EMAIL.test(emailWatch.trim()) : "";
    const validatePhone =
      mobilePhoneWatch && mobilePhoneWatch.toString().length < 10;
    const validatePurpose = landAreaWatch
      ? REGEX_DECIMAL_TEMPERATURE.test(landAreaWatch)
      : "";
    const validateLimitPurpose = landAreaWatch > 999999999.9 ? true : false;
    const validateId =
      (panasonicIdWatch && okamuraIdWatch && kiwiIdWatch) ||
      (panasonicIdWatch && okamuraIdWatch && !kiwiIdWatch)
        ? true
        : false;
    const validateStoreCode = storeCodeWatch
      ? REGEX_STORE_CODE.test(storeCodeWatch.trim())
      : "";

    const _validateEmail = !validateEmail && validateEmail !== "";
    const _validatePurpose = !validatePurpose && validatePurpose !== "";
    const __validatePurpose = _validatePurpose || validateLimitPurpose;
    const _validateStoreCode = !validateStoreCode && validateStoreCode !== "";
    let checkError = false;

    if (_validateEmail) {
      formModal.setFields([
        {
          name: [NAME.EMAIL],
          errors: [MESSAGE.FIELD_INVALID_FORMAT],
        },
      ]);
      checkError = true;
    }
    if (validatePhone) {
      formModal.setFields([
        {
          name: [NAME.MOBILE_PHONE],
          errors: [MESSAGE.FIELD_INVALID],
        },
      ]);
      checkError = true;
    }
    if (__validatePurpose) {
      formModal.setFields([
        {
          name: [NAME.LAND_AREA],
          errors: [MESSAGE.FIELD_INVALID],
        },
      ]);
      checkError = true;
    }
    if (_validateStoreCode) {
      formModal.setFields([
        {
          name: [NAME.STORE_CODE],
          errors: [MESSAGE.FIELD_INVALID],
        },
      ]);
      checkError = true;
    }
    if (validateId) {
      formModal.setFields([
        {
          name: [NAME.PANASONIC_ID],
          errors: [MESSAGE.FIELD_ID_ERROR],
        },
        {
          name: [NAME.OKAMURA_ID],
          errors: [MESSAGE.FIELD_ID_ERROR],
        },
      ]);
      checkError = true;
    }

    if (!checkError) {
      if (modalType === EModalType.REGISTRATION) {
        formModal.setFields([
          { name: [NAME.KIWI_ID], errors: [] },
          { name: [NAME.PANASONIC_ID], errors: [] },
          { name: [NAME.OKAMURA_ID], errors: [] },
          { name: [NAME.STORE_CODE], errors: [] },
        ]);

        // TODO: Check after BE deploy
        const companyId = formModal.getFieldValue(NAME.COMPANY_CODE);
        const storeCode = formModal.getFieldValue(NAME.STORE_CODE);
        const kiwiId = formModal.getFieldValue(NAME.KIWI_ID);
        const panasonicId = formModal.getFieldValue(NAME.PANASONIC_ID);
        const okamuraId = formModal.getFieldValue(NAME.OKAMURA_ID);
        handler.checkExistStore(
          { companyId, kiwiId, panasonicId, okamuraId, storeCode },
          formModal,
          setModalType,
          setLoadingExist
        );
      }

      if (modalType === EModalType.UPDATE) {
        setModalType(EModalType.CONFIRM_UPDATE);
      }
    }
  };

  const handleResetOther = () => {
    searchParams.delete(NAME.STORE);
    form.setFieldValue(NAME.STORE, undefined);
    setStores([OPTION_EMPTY]);

    searchParams.delete(NAME.AREA);
    form.setFieldValue(NAME.AREA, undefined);
    setAreas([OPTION_EMPTY]);
  };

  const handleResetStore = () => {
    searchParams.delete(NAME.STORE);
    form.setFieldValue(NAME.STORE, undefined);
    setStores([OPTION_EMPTY]);
  };

  const handleChangeCompany = (val: string) => {
    if (val) {
      const findCompany = _.find(companies, (company) => company.value === val);
      if (findCompany) {
        getAllStoreConditions({
          companyCode: val,
          companyName: findCompany.label.split("_")[1],
        });
      }
    }
    handleResetOther();
  };

  const handleChangeCompanyModal = (val: string) => {
    formModal.setFieldValue(NAME.AREA_CODE, undefined);
    setAreasModal([]);

    const findCompany = _.find(
      companiesModal,
      (company) => company.value === val
    );
    if (findCompany) {
      getAllStoreConditions({
        companyCode: findCompany.children as string,
        companyName: findCompany.label.split("_")[1],
      });
    }
  };

  const handleChangeArea = (val: string) => {
    if (val) {
      const findArea = _.find(areas, (area) => area.value === val);
      if (findArea) {
        getStoresByArea(findArea.children as string);
      }
    }
    handleResetStore();
  };

  // Modal delete store
  const handleOpenModalDelete = (record: TListStoreTable) => {
    setStore(record);
    setRowKey(record.key);
    setModalDelete(true);
  };

  const handleCloseModalDelete = () => {
    setStore(null);
    setRowKey(null);
    setModalDelete(false);
  };

  const handleFinish = (values: TListStoreReq) => {
    const findArea = _.find(areas, function (item) {
      return item.value === values.areaCode;
    });
    setListStore([]);
    setPageIndex(1);
    // setLoadedIds(new Set([]));

    getListStoreSearch({
      ...values,
      areaId: findArea?.children as string,
      pageIndex: 0,
    });
  };

  const handleScroll = (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (
      scrollTop + clientHeight >= scrollHeight - 5 &&
      !loadingPage &&
      hasNext
    ) {
      setPageIndex((prev) => prev + 1);
      getListStoreScroll({
        companyCode: companyParam,
        areaCode: areaParam,
        storeCode: storeParam,
        areaId: areaIdParam,
        pageIndex: pageIndex + 1,
      });
    }
  };

  // TODO: Recheck
  const handleOkModalSuccess = (_data: TFormModalRes) => {
    setModalOpen(false);
    // handleModalSuccess(data);
    handleModalSuccess();
    handleCloseModalConfirmFinal();

    setListStore([]);
    setPageIndex(1);
    getListStoreSearch({
      areaCode: areaParam,
      companyCode: companyParam,
      storeCode: storeParam,
      areaId: areaIdParam,
      pageIndex: 0,
    });
  };

  const renderTextModal = useMemo(() => {
    switch (modalType) {
      case EModalType.REGISTRATION:
        return {
          title: TITLE.STORE_REGISTRATION,
          okText: TITLE.BTN_NEXT,
          message: "",
          image: undefined,
        };
      case EModalType.DETAIL:
        return {
          title: TITLE.STORE_DETAIL,
          okText: TITLE.BTN_CLOSE,
          message: "",
          image: undefined,
        };
      case EModalType.UPDATE:
        return {
          title: TITLE.STORE_UPDATE,
          okText: TITLE.BTN_NEXT,
          message: "",
          image: undefined,
        };

      case EModalType.CONFIRM_REGISTRATION:
        return {
          title: TITLE.STORE_REGISTRATION,
          okText: TITLE.BTN_MODAL_REGIST,
          message: "",
          image: undefined,
        };
      case EModalType.CONFIRM_UPDATE:
        return {
          title: TITLE.STORE_UPDATE,
          okText: TITLE.STORE_UPDATE,
          message: "",
          image: undefined,
        };

      case EModalType.SUCCESS_REGISTRATION:
        return {
          title: TITLE.STORE_LIST_REGISTRATION,
          okText: TITLE.BTN_OK,
          message: MESSAGE.SUCCESS_REGISTER,
          image: images.confirmApproveTemperature,
        };
      case EModalType.SUCCESS_UPDATE:
        return {
          title: TITLE.STORE_LIST_UPDATE,
          okText: TITLE.BTN_OK,
          message: MESSAGE.SUCCESS_UPDATE,
          image: images.confirmApproveTemperature,
        };
      case EModalType.SUCCESS_DELETE:
        return {
          title: TITLE.SUCCESS_DELETE,
          okText: TITLE.BTN_OK,
          message: MESSAGE.SUCCESS_DELETE,
          image: images.confirmApproveTemperature,
        };

      case EModalType.FAIL_REGISTRATION:
        return {
          title: TITLE.FAIL_REGISTER,
          okText: TITLE.BTN_OK,
          message: MESSAGE.FAIL_REGISTER,
          image: images.importDataFail,
        };
      case EModalType.FAIL_UPDATE:
        return {
          title: TITLE.FAIL_REGISTER,
          okText: TITLE.BTN_OK,
          message: MESSAGE.FAIL_UPDATE,
          image: images.importDataFail,
        };
      case EModalType.FAIL_DELETE:
        return {
          title: TITLE.FAIL_DELETE,
          okText: TITLE.BTN_OK,
          message: MESSAGE.FAIL_DELETE,
          image: images.importDataFail,
        };
      case TITLE.NOT_FOUND_STORE:
        return {
          title: TITLE.FAIL_DELETE,
          okText: TITLE.BTN_OK,
          message: MESSAGE.NOT_FOUND_STORE,
          image: images.importDataFail,
        };

      default:
        return {
          title: "",
          okText: "",
          message: "",
          image: undefined,
        };
    }
  }, [modalType]);

  const renderTextModalConfirm = useMemo(() => {
    switch (modalTypeConfirm) {
      case EModalType.CONFIRM_REGISTRATION_FINAL:
        return {
          title: TITLE.CONFIRM_REGISTRATION_FINAL,
          okText: TITLE.BTN_OK,
          message: MESSAGE.CONFIRM_REGISTRATION_FINAL,
        };
      case EModalType.CONFIRM_UPDATE_FINAL:
        return {
          title: TITLE.CONFIRM_UPDATE_FINAL,
          okText: TITLE.BTN_OK,
          message: MESSAGE.CONFIRM_UPDATE_FINAL,
        };

      default:
        return {
          title: "",
          okText: "",
          message: "",
        };
    }
  }, [modalTypeConfirm]);

  // API
  const handleOkModalDelete = async () => {
    if (store) {
      handler.deleteStore(
        store.id,
        listStore,
        setListStore,
        setLoadingPage,
        setModalSuccess,
        setModalType,
        handleCloseModalDelete,
        handleDeleteFail
      );
    }
  };

  const handleOkModal = () => {
    const params: TFormModalRegisterReq = {
      areaId: areaIdWatch,
      companyId: companyIdWatch,
      email: emailWatch,
      kiwiId: kiwiIdWatch,
      landArea: landAreaWatch,
      okamuraId: okamuraIdWatch,
      openingDate: openingDateWatch ? openingDateWatch.format(DATE_FORMAT) : "",
      panasonicId: panasonicIdWatch,
      storeCode: storeCodeWatch,
      storeName: storeNameWatch,
      storePhone: mobilePhoneWatch,
      closingDate: closingDateWatch ? closingDateWatch.format(DATE_FORMAT) : "",
    };

    if (modalTypeConfirm === EModalType.CONFIRM_REGISTRATION_FINAL) {
      handler.registrationStore(
        params,
        handleOkModalSuccess,
        setLoadingPage,
        handleModalFail,
        handleCommonError
      );
    }

    if (modalTypeConfirm === EModalType.CONFIRM_UPDATE_FINAL) {
      handler.updateStore(
        { ...params, id: recordId },
        handleOkModalSuccess,
        setLoadingPage,
        handleModalFail,
        handleCommonError
      );
    }
  };

  const getStoresByArea = async (payload: string) => {
    handler.getStoresByArea(payload, setStores, setLoadingStore);
  };

  const getAllStoreConditions = async (payload: TCompanyReq) => {
    handler.getAllStoreConditions(
      payload,
      setAreas,
      setLoadingArea,
      getStoresByArea,
      setAreasModal,
      setLoadingAreaModal,
      modalType
    );
  };

  const getListStoreSearch = async (payload: TListStoreReq) => {
    handler.getListStoreSearch(
      payload,
      setLoadingPage,
      setListStore,
      handleSetSearchParams,
      setPageIndex,
      setHasNext
    );
  };

  // TODO: Recheck
  const getListStoreScroll = async (payload: TListStoreReq) => {
    handler.getListStoreScroll(
      payload,
      listStore,
      pageIndex,
      // loadedIds,
      setLoadingPage,
      setListStore,
      // setLoadedIds,
      setPageIndex,
      setHasNext
    );
  };

  const fetchInitialApis = async () => {
    handler.fetchInitialApis(
      pageIndex,
      setLoadingPage,
      setLoadingCompany,
      handleSetSearchParams,
      setCompanies,
      setAreas,
      setStores,
      setListStore,
      setCompaniesModal,
      setAreasModal,
      setHasNext,
      setPageIndex
    );
  };

  const fetchApiRedirect = async (payload: TFormStoreReq) => {
    handler.fetchApiRedirect(
      payload,
      pageIndex,
      setLoadingPage,
      setLoadingCompany,
      handleSetSearchParams,
      setCompanies,
      setAreas,
      setStores,
      setListStore,
      setCompaniesModal,
      setAreasModal,
      setHasNext,
      setPageIndex
    );
  };

  const columns = useMemo(() => {
    const columns = getColumns({
      disableBtnOther,
      handleOpenModalDelete,
      handleOpenModalDetail,
      handleOpenModalUpdate,
    });
    return columns;
  }, [companiesModal]);

  // EFFECT
  useEffect(() => {
    if (companyParam && storeParam && areaParam) {
      fetchApiRedirect({
        areaCode: areaParam,
        companyCode: companyParam,
        storeCode: storeParam,
        areaId: areaIdParam,
      });
    } else {
      fetchInitialApis();
    }
  }, []);

  useEffect(() => {
    effect.effectCompany(companyParam, form, companies);
  }, [companies]);

  useEffect(() => {
    effect.effectStore(storeParam, form);
  }, [stores]);

  useEffect(() => {
    effect.effectArea(areaParam, form, areas);
  }, [areas]);

  return {
    state: {
      columns,
      listStore,
      store,
      loadingPage,
      rowKey,
      tblRef,

      form,
      companies,
      stores,
      areas,
      loadingCompany,
      loadingArea,
      loadingStore,

      disableBtnOther,
      disableBtnNext,
      disableFields,
      disableFieldsEdit,

      loadingExist,
      loadingModal,
      companiesModal,
      loadingAreaModal,
      areasModal,
      formModal,
      renderTextModal,
      renderTextModalConfirm,
      modalSuccess,
      modalOpen,
      modalDelete,
      modalType,
      modalConfirm,
    },
    handler: {
      setModalType,
      handleFinish,
      handleScroll,
      handleChangeCompany,
      handleChangeArea,

      handleChangeCompanyModal,
      handleOpenModalRegistration,
      handleCloseModal,
      handleCloseModalDelete,
      handleOkModalDelete,
      handleOkModal,
      handleCloseModalSuccess,
      handleOpenModalConfirmFinal,
      handleDisableFromDate,
      handleDisableToDate,
      handleValidateForm,
      handleCloseModalConfirmFinal,
      handleOkModalConfirmFinal,
    },
  };
}

export default useStorePage;
