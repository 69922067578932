export const DATE_FORMAT = "YYYY/MM/DD";
export const DATE_FORMAT_EXPORT = "YYYYMMDD";
export const DATE_HYPHEN_FORMAT = "YYYY-MM-DD";
export const DATE_TIME_FORMAT = "YYYY/MM/DD HH:mm";
export const DATE_PICKER_FORMAT = ["YYYY/MM/DD", "YYYY/M/D"];
export const TIME_PICKER_FORMAT = "HH:mm";

export const TOKEN_COOKIE = "access-token";
export const REFRESH_TOKEN_COOKIE = "refresh-token";
export const REQUEST_TIME_OUT = 60000;

export const PLACEHOLDER_SELECT = "選択";
export const PLACEHOLDER_INPUT = "入力";
export const COMMON_MESSAGE_ERROR =
  "問題が発生しました。もう一度お試しください。";
export const COMMON_MESSAGE_TIMEOUT =
  "リクエストタイムアウトが60秒を超えました。もう一度お試しください。";
export const COMMON_ERR_NETWORK = "ERR_NETWORK";
export const COMMON_ERR_TIMEOUT = "ERR_TIMEOUT";
export const COLOR_TOOLTIP = "var(--color-text-primary)";
export const DATA_EMPTY = "-";
export const TEN = 10;
export const DEFAULT_PAGE_SIZE = 50;
export const MAX_LENGTH_255 = 255;

export const OPTION_EMPTY = { label: "", value: "" };
export const COMPANY_DEFAULT_OPTION = { value: "", label: "", children: "" };
export const REGEX_REPLACE_TEMPERATURE = /(\.0)+$/; // convert value 1.0.0 to 1.0
export const REGEX_DECIMAL_TEMPERATURE = /^\d+(\.\d)?$/;
export const REGEX_CHARACTER_NUMBER = /^[a-zA-Z0-9 ]*$/;
export const REGEX_FORMAT_TEMPERATURE = /^(-?\d{1,3})\d*(\.\d)?/; // Convert value to format xxx.x or -xxx.x
export const REGEX_CHECK_FORMAT_TEMP = /^(-?\d{1,3})(\.\d)?$/; // Check value match format xxx.x or -xxx.x
