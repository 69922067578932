export const FILE_MANAGE_CONSTANT = {
  TITLE: {
    FILE_MANAGEMENT: "出力ファイル管理",

    // TAB
    TEMPERATURE_DAILY: "温度日報画面",
    SUMMARY_TABLE: "集計表画面",
    ALL_DEVICE: "全店稼働状況照会",

    // COLUMNS
    NO: "NO",
    FILE_NAME: "ファイル名",
    STATUS: "ステータス",
    AUTHOR: "作成者",
    DOWNLOAD_DATE: "ダウンロード日付",
    DOWNLOAD_TIME: "ダウンロード時間",
    ACTION: "アクション",

    SUCCESS: "成功",
    ERROR: "失敗",
    PENDING: "保留中",
    EXPIRED: "期限切れ",

    // MODAL
    TITLE_VIEW: "指定した条件",
    TITLE_DOWNLOAD: "再ダウンロード",
    TITLE_DELETE: "出力ファイル管理",
    BTN_CLOSE: "閉じる",
    BTN_CANCEL: "キャンセル",
    BTN_OK: "OK",

    FILE_NAME_DOT: "ファイル名：",
    COMPANY_DOT: "会社：",
    DATE_DOT: "日付：",
  },
  NAME: {
    SEARCH: "search",
  },
  PLACEHOLDER: {
    SEARCH: "検索",
  },
  TABLE_KEY: {
    NO: "key",
    FILE_NAME: "name",
    STATUS: "status",
    AUTHOR: "exportedBy",
    DOWNLOAD_DATE: "exportedAt",
    DOWNLOAD_TIME: "exportedTime",
  },
  MESSAGE: {
    NO_DATA: "表示するデータがありません。",
    DOWNLOAD_AGAIN: "ファイルを再ダウンロードしますが、よろしいでしょうか？",
    DELETE_FILE: "ファイルを一覧から削除しますが、よろしいでしょうか?",
  },
};

export const HEIGHT_TABLE = 630;
