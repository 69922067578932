import { Modal } from "antd";
import styled from "styled-components";

export const MasterDataWrapper = styled.div`
  .master-data_header {
    margin-bottom: 24px;

    h1 {
      line-height: 40px;
    }
  }

  .master-data_tab {
    .ant-tabs-nav::before {
      border-bottom: 1px solid var(--color-secondary);
      width: calc(100% - 5px);
    }

    .ant-tabs-nav {
      margin-bottom: 0;
    }

    .ant-tabs-nav-list {
      .ant-tabs-tab:first-child,
      .ant-tabs-tab:nth-child(2),
      .ant-tabs-tab:nth-child(3),
      .ant-tabs-tab:nth-child(4),
      .ant-tabs-tab:nth-child(5) {
        width: 120px;
      }

      .ant-tabs-tab:nth-child(8),
      .ant-tabs-tab:nth-child(7),
      .ant-tabs-tab:nth-child(6) {
        width: 160px;
      }

      .ant-tabs-tab {
        font-weight: var(--font-medium);
        border: 1px solid var(--color-secondary);
        background-color: var(--background-header-table);
      }

      .ant-tabs-tab-active {
        color: var(--color-sidebar-active);
        border-bottom-color: var(--color-white) !important;
        background-color: var(--color-white);
      }
    }

    .ant-tabs-content-holder {
      height: 880px;
      padding: 16px;
      border-left: 1px solid var(--color-secondary);
      border-right: 1px solid var(--color-secondary);
      border-bottom: 1px solid var(--color-secondary);
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
      background-color: #f9fafb;

      .ant-tabs-content,
      .ant-tabs-tabpane {
        height: 100%;
      }
    }
  }
`;

export const DataTabWrapper = styled.div`
  position: relative;
  height: inherit;

  .master-data_tab-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    p {
      text-align: center;
      color: #5d646f;
      font-weight: var(--font-normal);
      font-size: var(--font-size-sm);
      margin-bottom: 40px;
    }

    .master-data_tab-content-btn {
      .ant-btn {
        width: 178px;
        height: 48px;
        border-radius: 8px;
        background: var(--color-sidebar-active);
        color: var(--color-white);
        font-weight: var(--font-medium);
        display: block;
        margin: 0 auto;
      }
    }
  }
`;

export const ModalFailWrapper = styled(Modal)`
  .ant-modal-content {
    padding: 0;
    background: var(--color-white);
    box-shadow: 0px 2px 8px 0px rgba(5, 40, 97, 0.08);
    border-radius: 12px;
  }

  .ant-modal-header {
    margin-bottom: 0;
    padding: 16px 24px;
    background: none;

    .ant-modal-title {
      font-weight: var(--font-bold);
      font-size: var(--font-size-base);
      line-height: 24px;
      color: var(--color-header-table);
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: center;
    padding: 12px 24px 24px;
    margin-top: 0;

    .ant-btn {
      width: 202px;
      height: 48px;
      box-shadow: none;
      font-weight: var(--font-bold);
      margin-inline-start: 0;
    }

    .ant-btn-default {
      display: none;
    }
  }

  .ant-modal-body {
    padding: 0 24px;
    /* height: 287px; */
    border-bottom: 1px solid #f1f3f7;
    border-top: 1px solid #f1f3f7;
    text-align: center;

    .master-data_fail-message {
      margin-top: 36px;
      width: 302px;
      margin-left: auto;
      margin-right: auto;
      color: var(--color-text-secondary);

      p {
        font-weight: var(--font-medium);
        font-size: var(--font-size-sm);
        line-height: 20px;
      }

      p:last-child {
        margin: 8px 0;
      }
    }
  }
`;
