import { TCommonError } from "@models/common";
import { axiosInstance } from "./axiosInstance";
import { TAxiosResponse } from "types";
import { DEFAULT_PAGE_SIZE } from "@constants/common";
import {
  TCheckDeviceExistReq,
  TFormModalDeviceRes,
  TFormRegisterDeviceReq,
  TListDeviceReq,
  TListDeviceRes,
  TListManufacturerRes,
} from "@models/device";

const deviceApi = {
  getAllManufacturers: async () => {
    const url = "/Manufacturer/manufacturers";
    const response: TAxiosResponse<TListManufacturerRes[], TCommonError> =
      await axiosInstance.get(url);
    return response;
  },

  getListDevice: async (payload: TListDeviceReq) => {
    const _payload = {
      ...payload,
      companyId: payload.companyId || "",
      storeId: payload.storeId || "",
      chCode: payload.chCode || "",
      manufacturerCode: payload.manufacturerCode
        ? payload.manufacturerCode
        : "",
      modelCode: payload.modelCode ? payload.modelCode.trim() : "",
    };
    const _pageIndex = payload.pageIndex ? payload.pageIndex : 1;
    const url = `/Sensor/search-sensors?CompanyId=${_payload.companyId}&StoreId=${_payload.storeId}&CHCode=${_payload.chCode}&ManufacturerCode=${_payload.manufacturerCode}&ModelCode=${_payload.modelCode}&PageIndex=${_pageIndex}&PageSize=${DEFAULT_PAGE_SIZE}`;

    const response: TAxiosResponse<TListDeviceRes, TCommonError> =
      await axiosInstance.get(url);
    return response;
  },

  registrationDevice: async (payload: TFormRegisterDeviceReq) => {
    const url = "/Sensor/sensors/create";
    const response: TAxiosResponse<any, TCommonError> =
      await axiosInstance.post(url, payload);
    return response;
  },

  updateDevice: async (payload: any) => {
    const url = "/Sensor/sensors/update";
    const response: TAxiosResponse<any, TCommonError> = await axiosInstance.put(
      url,
      payload
    );
    return response;
  },

  getDetailDevice: async (payload: string) => {
    const url = `/Sensor/sensors/${payload}`;
    const response: TAxiosResponse<TFormModalDeviceRes, TCommonError> =
      await axiosInstance.get(url);
    return response;
  },

  deleteDevice: async (payload: string) => {
    const url = `/Sensor/sensors/${payload}`;
    const response: TAxiosResponse<any, TCommonError> =
      await axiosInstance.delete(url);
    return response;
  },

  checkDeviceExist: async (payload: TCheckDeviceExistReq) => {
    const url = `/Sensor/sensors/exists`;
    const response: TAxiosResponse<any, TCommonError> =
      await axiosInstance.post(url, payload);
    return response;
  },
};

export { deviceApi };
