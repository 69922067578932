import EmptyDataTable from "@components/EmptyDataTable/EmptyDataTable";
import { ConfigProvider, Table } from "antd";
import { TableListWrapper } from "./TableListStyled";

const HEIGHT_TABLE_SCROLL_MAX = 600;
const HEIGHT_TABLE_SCROLL_MIN = 500;

type TTableListProps = {
  rowKey: number | null;
  listData: any[];
  messageNoData: string;
  columns?: any[];
  heightTableScroll?: number;
  handleScroll: (e: any) => void;
  borderColor: string;
  image: string;
  tableRef: any;
};

function TableList(props: TTableListProps) {
  const {
    columns,
    handleScroll,
    listData,
    messageNoData,
    rowKey,
    borderColor,
    image,
    tableRef,
  } = props;

  return (
    <TableListWrapper>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerBg: "var(--background-header-table)",
              borderColor,
              rowHoverBg: "var(--color-row-hover)",
              headerColor: "var(--color-header-table)",
              fontWeightStrong: 700,
            },
          },
        }}
        renderEmpty={() =>
          !listData.length ? (
            <EmptyDataTable image={image} message={messageNoData} />
          ) : undefined
        }
      >
        <Table
          ref={tableRef}
          dataSource={listData}
          columns={columns}
          pagination={false}
          virtual={listData.length > 0}
          scroll={{
            y:
              listData.length <= 10
                ? HEIGHT_TABLE_SCROLL_MIN
                : HEIGHT_TABLE_SCROLL_MAX,
          }}
          onScroll={handleScroll}
          bordered
          rowClassName={(record) => {
            return rowKey === record.key ? "row_active" : "";
          }}
        />
      </ConfigProvider>
    </TableListWrapper>
  );
}

export { TableList };
