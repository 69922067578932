import { TableProps } from "antd/lib";
import { COMPANY_CONSTANT } from "./constant";
import { Button, Tooltip } from "antd";
import { COLOR_TOOLTIP } from "@constants/common";
import {
  DeleteOutlined,
  EditOutlined,
  FolderViewOutlined,
} from "@ant-design/icons";
import { TListCompanyTable } from "@models/company";

const { TITLE, TABLE_KEY } = COMPANY_CONSTANT;

export const getColumns = ({
  disableBtnOther,
  handleOpenModalDelete,
  handleOpenModalDetail,
  handleOpenModalUpdate,
}: {
  disableBtnOther: boolean;
  handleOpenModalDelete: (record: TListCompanyTable) => void;
  handleOpenModalDetail: (record: TListCompanyTable) => void;
  handleOpenModalUpdate: (record: TListCompanyTable, id: string) => void;
}): TableProps<TListCompanyTable>["columns"] => {
  return [
    {
      title: TITLE.NO,
      key: TABLE_KEY.NO,
      dataIndex: TABLE_KEY.NO,
      align: "center",
      width: 75,
    },
    {
      title: TITLE.COMPANY_CODE,
      key: TABLE_KEY.COMPANY_CODE,
      dataIndex: TABLE_KEY.COMPANY_CODE,
      align: "center",
      width: 570,
      render: (_value, record) => {
        if (record) {
          return (
            <div className="list-page_ellipsis">
              <Tooltip
                color="white"
                overlayClassName="common_custom-tooltip"
                title={record.companyCode}
                overlayInnerStyle={{
                  color: COLOR_TOOLTIP,
                }}
              >
                {record.companyCode}
              </Tooltip>
            </div>
          );
        }
        return null;
      },
    },
    {
      title: TITLE.COMPANY_NAME,
      key: TABLE_KEY.COMPANY_NAME,
      dataIndex: TABLE_KEY.COMPANY_NAME,
      align: "center",
      width: 570,
      render: (_value, record) => {
        if (record) {
          return (
            <div className="list-page_ellipsis">
              <Tooltip
                color="white"
                overlayClassName="common_custom-tooltip"
                title={record.companyName}
                overlayInnerStyle={{
                  color: COLOR_TOOLTIP,
                }}
              >
                {record.companyName}
              </Tooltip>
            </div>
          );
        }
        return null;
      },
    },
    {
      title: TITLE.ACTION,
      align: "center",
      width: 570,
      render(_, record) {
        return (
          <div className="list-page_modal-btns">
            <Button
              onClick={() => {
                handleOpenModalDetail(record);
              }}
            >
              <FolderViewOutlined
                style={{
                  fontSize: "21px",
                  color: "#344054",
                  cursor: "pointer",
                }}
              />
            </Button>
            <Button
              disabled={disableBtnOther}
              onClick={() => {
                handleOpenModalUpdate(record, record.id);
              }}
            >
              <EditOutlined
                style={{
                  fontSize: "21px",
                  color: `${disableBtnOther ? "#99a6bb" : "#344054"}`,
                  cursor: "pointer",
                }}
              />
            </Button>
            <Button
              disabled={disableBtnOther}
              onClick={() => {
                handleOpenModalDelete(record);
              }}
            >
              <DeleteOutlined
                style={{
                  fontSize: "21px",
                  color: `${disableBtnOther ? "#99a6bb" : "#F04438"}`,
                  cursor: "pointer",
                }}
              />
            </Button>
          </div>
        );
      },
    },
  ];
};
