import { TableProps, Tooltip } from "antd";
import { TEMPERATURE_CONSTANT } from "./constant";
import CustomTooltip from "./components/CustomTooltip";
import { EditOutlined } from "@ant-design/icons";
import { TConvertSensorReports } from "@models/device";
import { COLOR_TOOLTIP, DATA_EMPTY } from "@constants/common";
import { handleClassNameCell, sharedOnCell } from "./utils/helpers";

const { TITLE, TABLE_KEY } = TEMPERATURE_CONSTANT;

export const getColumns = ({
  disableFieldAdmin,
  changeSwitch,
  handleOpenModalEditRemark,
  handleRedirectPage,
}: {
  disableFieldAdmin: boolean;
  changeSwitch: boolean;
  handleOpenModalEditRemark: (record: any) => void;
  handleRedirectPage: (val: TConvertSensorReports) => void;
}): TableProps<TConvertSensorReports>["columns"] => {
  return [
    {
      title: TITLE.CH,
      key: TABLE_KEY.CH,
      dataIndex: TABLE_KEY.CH,
      align: "center",
      width: 55,
      onCell: sharedOnCell,
      render: (value) =>
        value ? (
          <span className="temperature-report_ch">
            <Tooltip
              color="white"
              overlayClassName="common_custom-tooltip"
              title={value}
              overlayInnerStyle={{
                color: COLOR_TOOLTIP,
              }}
            >
              {value}
            </Tooltip>
          </span>
        ) : (
          DATA_EMPTY
        ),
    },
    {
      title: TITLE.DEPARTMENT_NAME,
      dataIndex: TABLE_KEY.PURPOSE_NAME,
      key: TABLE_KEY.PURPOSE_NAME,
      width: 100,
      onCell: sharedOnCell,
      render(value, record) {
        return value ? (
          <span
            onClick={() => handleRedirectPage(record)}
            className="temperature-report_custom temperature-report_link"
          >
            <Tooltip
              color="white"
              overlayClassName="common_custom-tooltip"
              title={value}
              overlayInnerStyle={{
                color: COLOR_TOOLTIP,
              }}
            >
              {value}
            </Tooltip>
          </span>
        ) : (
          DATA_EMPTY
        );
      },
    },
    {
      title: TITLE.DEPARTMENT_NAME2,
      dataIndex: TABLE_KEY.DEPARTMENT_NAME2,
      key: TABLE_KEY.DEPARTMENT_NAME2,
      width: 90,
      align: "center",
      onCell: sharedOnCell,
      render: (value) => value || DATA_EMPTY,
    },
    {
      title: TITLE.TEMPERATURE_DISTINCTION,
      dataIndex: TABLE_KEY.TEMPERATURE_DISTINCTION,
      key: TABLE_KEY.TEMPERATURE_DISTINCTION,
      width: 100,
      align: "center",
      onCell: sharedOnCell,
    },
    {
      title: TITLE.CONTROLLED_TEMPERATURE,
      align: "center",
      children: [
        {
          align: "center",
          title: TITLE.LOWER_LIMIT,
          dataIndex: TABLE_KEY.LOWER_LIMIT_CONTROLLED,
          key: TABLE_KEY.LOWER_LIMIT_CONTROLLED,
          width: 80,
          onCell: sharedOnCell,
          render(value) {
            return (
              <span className="temperature-report_custom">
                {value ? `${value}°C` : TITLE.NONE}
              </span>
            );
          },
        },
        {
          align: "center",
          title: TITLE.UPPER_LIMIT,
          dataIndex: TABLE_KEY.UPPER_LIMIT_CONTROLLED,
          key: TABLE_KEY.UPPER_LIMIT_CONTROLLED,
          width: 80,
          onCell: sharedOnCell,
          render(value) {
            return (
              <span className="temperature-report_custom">
                {value ? `${value}°C` : TITLE.NONE}
              </span>
            );
          },
        },
      ],
    },
    {
      align: "center",
      title: TITLE.INTERNAL_TEMPERATURE,
      dataIndex: TABLE_KEY.ONE_HOUR,
      key: TABLE_KEY.ONE_HOUR,
      colSpan: 12,
      width: 84,
      onCell: (record) =>
        handleClassNameCell(
          record.hour1[1] as string,
          record.hour1[2] as boolean,
          +record.hour1[3],
          changeSwitch,
          record.hour1[4] as boolean,
          record.hour1[5] as boolean
        ),
      render(value) {
        return (
          <CustomTooltip
            temperature={value[1]}
            hour={value[0]}
            changeSwitch={changeSwitch}
            isDefrost={value[2]}
            threshold={value[3]}
            isActive={value[4]}
            isNoCooling={value[5]}
          />
        );
      },
    },
    {
      align: "center",
      title: "twoHour",
      dataIndex: TABLE_KEY.TWO_HOUR,
      key: TABLE_KEY.TWO_HOUR,
      colSpan: 0,
      onCell: (record) =>
        handleClassNameCell(
          record.hour2[1] as string,
          record.hour2[2] as boolean,
          +record.hour2[3],
          changeSwitch,
          record.hour2[4] as boolean,
          record.hour2[5] as boolean
        ),
      width: 84,
      render(value) {
        return (
          <CustomTooltip
            temperature={value[1]}
            hour={value[0]}
            changeSwitch={changeSwitch}
            isDefrost={value[2]}
            threshold={value[3]}
            isActive={value[4]}
            isNoCooling={value[5]}
          />
        );
      },
    },
    {
      align: "center",
      title: "threeHour",
      dataIndex: TABLE_KEY.THREE_HOUR,
      key: TABLE_KEY.THREE_HOUR,
      colSpan: 0,
      onCell: (record) =>
        handleClassNameCell(
          record.hour3[1] as string,
          record.hour3[2] as boolean,
          +record.hour3[3],
          changeSwitch,
          record.hour3[4] as boolean,
          record.hour3[5] as boolean
        ),
      width: 84,
      render(value) {
        return (
          <CustomTooltip
            temperature={value[1]}
            hour={value[0]}
            changeSwitch={changeSwitch}
            isDefrost={value[2]}
            threshold={value[3]}
            isActive={value[4]}
            isNoCooling={value[5]}
          />
        );
      },
    },
    {
      align: "center",
      title: "fourHour",
      dataIndex: TABLE_KEY.FOUR_HOUR,
      key: TABLE_KEY.FOUR_HOUR,
      colSpan: 0,
      onCell: (record) =>
        handleClassNameCell(
          record.hour4[1] as string,
          record.hour4[2] as boolean,
          +record.hour4[3],
          changeSwitch,
          record.hour4[4] as boolean,
          record.hour4[5] as boolean
        ),
      width: 84,
      render(value) {
        return (
          <CustomTooltip
            temperature={value[1]}
            hour={value[0]}
            changeSwitch={changeSwitch}
            isDefrost={value[2]}
            threshold={value[3]}
            isActive={value[4]}
            isNoCooling={value[5]}
          />
        );
      },
    },
    {
      align: "center",
      title: "fiveHour",
      dataIndex: TABLE_KEY.FIVE_HOUR,
      key: TABLE_KEY.FIVE_HOUR,
      colSpan: 0,
      onCell: (record) =>
        handleClassNameCell(
          record.hour5[1] as string,
          record.hour5[2] as boolean,
          +record.hour5[3],
          changeSwitch,
          record.hour5[4] as boolean,
          record.hour5[5] as boolean
        ),
      width: 84,
      render(value) {
        return (
          <CustomTooltip
            temperature={value[1]}
            hour={value[0]}
            changeSwitch={changeSwitch}
            isDefrost={value[2]}
            threshold={value[3]}
            isActive={value[4]}
            isNoCooling={value[5]}
          />
        );
      },
    },
    {
      align: "center",
      title: "sixHour",
      dataIndex: TABLE_KEY.SIX_HOUR,
      key: TABLE_KEY.SIX_HOUR,
      colSpan: 0,
      onCell: (record) =>
        handleClassNameCell(
          record.hour6[1] as string,
          record.hour6[2] as boolean,
          +record.hour6[3],
          changeSwitch,
          record.hour6[4] as boolean,
          record.hour6[5] as boolean
        ),
      width: 84,
      render(value) {
        return (
          <CustomTooltip
            temperature={value[1]}
            hour={value[0]}
            changeSwitch={changeSwitch}
            isDefrost={value[2]}
            threshold={value[3]}
            isActive={value[4]}
            isNoCooling={value[5]}
          />
        );
      },
    },
    {
      align: "center",
      title: "sevenHour",
      dataIndex: TABLE_KEY.SEVEN_HOUR,
      key: TABLE_KEY.SEVEN_HOUR,
      colSpan: 0,
      onCell: (record) =>
        handleClassNameCell(
          record.hour7[1] as string,
          record.hour7[2] as boolean,
          +record.hour7[3],
          changeSwitch,
          record.hour7[4] as boolean,
          record.hour7[5] as boolean
        ),
      width: 84,
      render(value) {
        return (
          <CustomTooltip
            temperature={value[1]}
            hour={value[0]}
            changeSwitch={changeSwitch}
            isDefrost={value[2]}
            threshold={value[3]}
            isActive={value[4]}
            isNoCooling={value[5]}
          />
        );
      },
    },
    {
      align: "center",
      title: "eightHour",
      dataIndex: TABLE_KEY.EIGHT_HOUR,
      key: TABLE_KEY.EIGHT_HOUR,
      colSpan: 0,
      onCell: (record) =>
        handleClassNameCell(
          record.hour8[1] as string,
          record.hour8[2] as boolean,
          +record.hour8[3],
          changeSwitch,
          record.hour8[4] as boolean,
          record.hour8[5] as boolean
        ),
      width: 84,
      render(value) {
        return (
          <CustomTooltip
            temperature={value[1]}
            hour={value[0]}
            changeSwitch={changeSwitch}
            isDefrost={value[2]}
            threshold={value[3]}
            isActive={value[4]}
            isNoCooling={value[5]}
          />
        );
      },
    },
    {
      align: "center",
      title: "nineHour",
      dataIndex: TABLE_KEY.NINE_HOUR,
      key: TABLE_KEY.NINE_HOUR,
      colSpan: 0,
      onCell: (record) =>
        handleClassNameCell(
          record.hour9[1] as string,
          record.hour9[2] as boolean,
          +record.hour9[3],
          changeSwitch,
          record.hour9[4] as boolean,
          record.hour9[5] as boolean
        ),
      width: 84,
      render(value) {
        return (
          <CustomTooltip
            temperature={value[1]}
            hour={value[0]}
            changeSwitch={changeSwitch}
            isDefrost={value[2]}
            threshold={value[3]}
            isActive={value[4]}
            isNoCooling={value[5]}
          />
        );
      },
    },
    {
      align: "center",
      title: "tenHour",
      dataIndex: TABLE_KEY.TEN_HOUR,
      key: TABLE_KEY.TEN_HOUR,
      colSpan: 0,
      onCell: (record) =>
        handleClassNameCell(
          record.hour10[1] as string,
          record.hour10[2] as boolean,
          +record.hour10[3],
          changeSwitch,
          record.hour10[4] as boolean,
          record.hour10[5] as boolean
        ),
      width: 84,
      render(value) {
        return (
          <CustomTooltip
            temperature={value[1]}
            hour={value[0]}
            changeSwitch={changeSwitch}
            isDefrost={value[2]}
            threshold={value[3]}
            isActive={value[4]}
            isNoCooling={value[5]}
          />
        );
      },
    },
    {
      align: "center",
      title: "elevenHour",
      dataIndex: TABLE_KEY.ELEVEN_HOUR,
      key: TABLE_KEY.ELEVEN_HOUR,
      colSpan: 0,
      onCell: (record) =>
        handleClassNameCell(
          record.hour11[1] as string,
          record.hour11[2] as boolean,
          +record.hour11[3],
          changeSwitch,
          record.hour11[4] as boolean,
          record.hour11[5] as boolean
        ),
      width: 84,
      render(value) {
        return (
          <CustomTooltip
            temperature={value[1]}
            hour={value[0]}
            changeSwitch={changeSwitch}
            isDefrost={value[2]}
            threshold={value[3]}
            isActive={value[4]}
            isNoCooling={value[5]}
          />
        );
      },
    },
    {
      align: "center",
      title: "twelveHour",
      dataIndex: TABLE_KEY.TWELVE_HOUR,
      key: TABLE_KEY.TWELVE_HOUR,
      colSpan: 0,
      onCell: (record) =>
        handleClassNameCell(
          record.hour12[1] as string,
          record.hour12[2] as boolean,
          +record.hour12[3],
          changeSwitch,
          record.hour12[4] as boolean,
          record.hour12[5] as boolean
        ),
      width: 84,
      render(value) {
        return (
          <CustomTooltip
            temperature={value[1]}
            hour={value[0]}
            changeSwitch={changeSwitch}
            isDefrost={value[2]}
            threshold={value[3]}
            isActive={value[4]}
            isNoCooling={value[5]}
          />
        );
      },
    },
    {
      title: TITLE.OUTSIDE_TEMPERATURE_RANGE,
      align: "center",
      children: [
        {
          align: "center",
          title: TITLE.LOWER_LIMIT,
          dataIndex: TABLE_KEY.LOWER_LIMIT_OUTSIDE,
          key: TABLE_KEY.LOWER_LIMIT_OUTSIDE,
          width: 80,
          onCell: sharedOnCell,
          render(value) {
            return (
              <span className="temperature-report_custom temperature-report_custom-temperature">
                {value || 0}
              </span>
            );
          },
        },
        {
          align: "center",
          title: TITLE.UPPER_LIMIT,
          dataIndex: TABLE_KEY.UPPER_LIMIT_OUTSIDE,
          key: TABLE_KEY.UPPER_LIMIT_OUTSIDE,
          width: 80,
          onCell: sharedOnCell,
          render(value) {
            return (
              <span className="temperature-report_custom temperature-report_custom-temperature">
                {value || 0}
              </span>
            );
          },
        },
      ],
    },
    {
      title: TITLE.REMARKS,
      dataIndex: TABLE_KEY.REMARKS,
      key: TABLE_KEY.REMARKS,
      width: 130,
      onCell: (_, index) => {
        if (index && index % 2 !== 0) {
          return { rowSpan: 0 };
        }
        return {
          rowSpan: 2,
          style: {
            display: disableFieldAdmin ? "flex" : "unset",
          },
        };
      },
      render(value, record) {
        return (
          <div>
            {value ? (
              <Tooltip
                color="white"
                overlayClassName="temperature-report_custom-tooltip-remark"
                placement="leftBottom"
                title={value}
                overlayInnerStyle={{
                  color: "var(--color-text-primary)",
                }}
              >
                <span className="temperature-report_remark">{value}</span>
              </Tooltip>
            ) : (
              DATA_EMPTY
            )}

            {!disableFieldAdmin && (
              <EditOutlined
                onClick={() => handleOpenModalEditRemark(record)}
                style={{
                  fontSize: "24px",
                  color: "var(--color-secondary)",
                  cursor: "pointer",
                }}
              />
            )}
          </div>
        );
      },
    },
  ];
};
