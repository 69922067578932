import { LoadingOutlined } from "@ant-design/icons";
import { ArrowSelect, LoadingCommon } from "@components";
import { images } from "@constants";
import { RotateLeftIcon, RotateRightIcon, SearchIcon } from "@constants/svgs";
import { AppContext } from "@layouts/LayoutAdmin/LayoutAdmin";
import { TSelectValue } from "@models/common";
import { TCompanyReq, TCompanyRes, TGetImageReq } from "@models/layout";
import { EUserRoles } from "@models/user";
import { layoutApi } from "@services/layoutApi";
import { handleShowToastError } from "@utils/helpers";
import { Button, Form, Select } from "antd";
import { MouseEvent, useContext, useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { LayoutDiagramWrapper } from "./LayoutDiagramStyled";
import ZoomInIcon from "./components/ZoomInIcon";
import ZoomOutIcon from "./components/ZoomOutIcon";
import {
  ANGLE_ROTATE,
  LAYOUT_CONSTANT,
  MAX_BOUND,
  MAX_SCALE,
  MIN_BOUND,
  MIN_SCALE,
  STEP_SCALE,
} from "./constant";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";

const { NAME, LABEL, TITLE, MESSAGE } = LAYOUT_CONSTANT;

const FIELDS_CONFIG = {
  COMPANY: { required: false, label: LABEL.COMPANY, name: NAME.COMPANY },
  STORE: { required: false, label: LABEL.STORE, name: NAME.STORE },
};

function LayoutDiagramPage() {
  const { role, companyCode, companyName, storeCode, storeName } =
    useContext(AppContext);
  const _companyCode = companyCode || "";
  const _companyName = companyName || "";
  const _storeCode = storeCode || "";
  const _storeName = storeName || "";
  const disableItem = role && role[0] === EUserRoles.STORE;

  const [form] = Form.useForm();

  const [scale, setScale] = useState(1);
  const [dragging, _setDragging] = useState(false);
  const [rotation, setRotation] = useState(0);
  const nodeRef = useRef<HTMLDivElement | null>(null);

  const [companies, setCompanies] = useState<TSelectValue[]>([]);
  const [stores, setStores] = useState<TSelectValue[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingStore, setLoadingStore] = useState(false);
  const [urlImage, setUrlImage] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  const handleZoomIn = () => {
    setScale((prevScale) => Math.min(prevScale + STEP_SCALE, MAX_SCALE));
  };

  const handleZoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - STEP_SCALE, MIN_SCALE));
  };

  const handleRotateLeft = () => {
    setRotation((prevRotation) => prevRotation - ANGLE_ROTATE);
  };

  const handleRotateRight = () => {
    setRotation((prevRotation) => prevRotation + ANGLE_ROTATE);
  };

  const handleMouseDown = (e: MouseEvent<HTMLImageElement>) => {
    if (!dragging) {
      e.preventDefault();
    }
  };

  const handleSearch = (value: TGetImageReq) => {
    searchParams.set("companyCode", value.companyCode);
    searchParams.set("storeCode", value.storeCode);
    setSearchParams(searchParams, { replace: true });

    handleGetLayoutImage({
      companyCode: value.companyCode,
      storeCode: value.storeCode,
    });
  };

  const handleResetStore = () => {
    form.setFieldValue(NAME.STORE, "");
    setStores([]);
    searchParams.delete("storeCode");
  };

  const handleChangeCompany = (val: string) => {
    handleResetStore();
    const companyName = companies.find(
      (company) => company.value === val
    )?.label;

    if (companyName) {
      handleGetStoresByChangeCompany({
        companyName,
        companyCode: val,
      });
    }
  };

  const handleSetSearchParams = (companyCode: string, storeCode: string) => {
    searchParams.set("companyCode", companyCode);
    searchParams.set("storeCode", storeCode);
    setSearchParams(searchParams, { replace: true });
  };

  // API
  const handleGetStoresByChangeCompany = async (payload: TCompanyReq) => {
    setLoadingStore(true);

    try {
      const response = await layoutApi.getStoresByCompany(payload);

      if (response.ok) {
        const convertStores = response.data.map((val) => ({
          value: val.storeCode,
          label: val.storeName,
        }));
        setStores(convertStores);
      }
    } catch (error) {
      handleShowToastError(error);
    } finally {
      setLoadingStore(false);
    }
  };

  const handleGetStoresByCompany = async (payload: TCompanyRes) => {
    setLoadingStore(true);

    try {
      const response = await layoutApi.getStoresByCompany(payload);

      if (response.ok) {
        const convertStores = response.data.map((val) => ({
          value: val.storeCode,
          label: val.storeName,
        }));
        setStores(convertStores);
        handleSetSearchParams(payload.companyCode, convertStores[0].value);
        await handleGetLayoutImage({
          companyCode: payload.companyCode,
          storeCode: convertStores[0].value,
        });
      }
    } catch (error) {
      handleShowToastError(error);
    } finally {
      setLoadingStore(false);
    }
  };

  const handleGetCompanies = async () => {
    try {
      const response = await layoutApi.getCompanies();

      if (response.ok) {
        const convertCompanies = response.data.map((val) => ({
          value: val.companyCode,
          label: val.companyName,
        }));

        setCompanies(convertCompanies);
        await handleGetStoresByCompany(response.data[0]);
      }
    } catch (error) {
      handleShowToastError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetLayoutImage = async (payload: TGetImageReq) => {
    setLoading(true);

    try {
      const response = await layoutApi.getLayoutImage(payload);

      if (response.ok) {
        const { binaryData, contentType } = response.data;
        setUrlImage(`data:${contentType};base64,${binaryData}`);
      }
    } catch (error) {
      handleShowToastError(error);

      setUrlImage("");
    } finally {
      setLoading(false);
    }
  };

  const fetchInitialApisRedirect = async (payload: TGetImageReq) => {
    try {
      const response = await layoutApi.getCompanies();

      if (response.ok) {
        const convertCompanies = response.data.map((val) => ({
          value: val.companyCode,
          label: val.companyName,
        }));
        const findCompany = _.find(convertCompanies, function (val) {
          return val.value === payload.companyCode;
        });

        setCompanies(convertCompanies);

        const responseStores = await layoutApi.getStoresByCompany({
          companyCode: payload.companyCode,
          companyName: findCompany ? findCompany.label : "",
        });

        if (responseStores.ok) {
          const convertStores = responseStores.data.map((val) => ({
            value: val.storeCode,
            label: val.storeName,
          }));
          setStores(convertStores);
          await handleGetLayoutImage({
            companyCode: payload.companyCode,
            storeCode: payload.storeCode,
          });
        }
      }
    } catch (error) {
      handleShowToastError(error);
      setLoading(false);
    }
  };

  const fetchInitialApis = () => {
    const companyCode = searchParams.get("companyCode");
    const storeCode = searchParams.get("storeCode");

    if (companyCode && storeCode) {
      fetchInitialApisRedirect({ companyCode, storeCode });
    } else {
      handleGetCompanies();
    }
  };

  useEffect(() => {
    if (!disableItem) {
      fetchInitialApis();
    } else {
      setLoading(false);

      setCompanies([
        ...companies,
        { label: _companyName, value: _companyCode },
      ]);
      setStores([...stores, { label: _storeName, value: _storeCode }]);
      form.setFieldValue(NAME.COMPANY, _companyCode);
      form.setFieldValue(NAME.STORE, _storeCode);

      handleSetSearchParams(_companyCode, _storeCode);
      handleGetLayoutImage({
        companyCode: _companyCode,
        storeCode: _storeCode,
      });
    }
  }, []);

  useEffect(() => {
    const companyCodeParam = searchParams.get("companyCode");
    if (companyCodeParam) {
      form.setFieldValue(NAME.COMPANY, +companyCodeParam);
    } else {
      if (companies.length) {
        form.setFieldValue(NAME.COMPANY, companies[0].value);
      }
    }
  }, [companies]);

  useEffect(() => {
    const storeCodeParam = searchParams.get("storeCode");
    if (storeCodeParam) {
      form.setFieldValue(NAME.STORE, storeCodeParam);
    } else {
      if (stores.length) {
        form.setFieldValue(NAME.STORE, stores[0].value);
      }
    }
  }, [stores]);

  return (
    <LoadingCommon loading={loading}>
      <LayoutDiagramWrapper hasimage={urlImage}>
        <div className="layout-diagram_header">
          <h1>{TITLE.LAYOUT_DIAGRAM}</h1>
        </div>

        <div className="layout-diagram_form">
          <Form
            name="layout-diagram"
            layout="vertical"
            form={form}
            onFinish={(val) => handleSearch(val)}
          >
            {/* COMPANY */}
            <Form.Item {...FIELDS_CONFIG.COMPANY}>
              <Select
                size="large"
                options={companies}
                disabled={disableItem}
                suffixIcon={<ArrowSelect disable={disableItem} />}
                onChange={(val) => handleChangeCompany(val)}
              />
            </Form.Item>
            {/* STORE */}
            <Form.Item {...FIELDS_CONFIG.STORE}>
              <Select
                size="large"
                options={stores}
                disabled={disableItem || loadingStore}
                suffixIcon={
                  loadingStore ? (
                    <LoadingOutlined spin={loadingStore} />
                  ) : (
                    <ArrowSelect disable={disableItem} />
                  )
                }
              />
            </Form.Item>
            <Button htmlType="submit" icon={<SearchIcon />} />
          </Form>
        </div>

        <div className="layout-diagram_container">
          {urlImage ? (
            <div className="layout-diagram_result">
              <Draggable
                nodeRef={nodeRef}
                bounds={{
                  top: MIN_BOUND,
                  left: MIN_BOUND,
                  bottom: MAX_BOUND,
                  right: MAX_BOUND,
                }}
              >
                <div ref={nodeRef}>
                  <img
                    onMouseDown={handleMouseDown}
                    src={urlImage}
                    alt="layout_image"
                    style={{
                      transform: `scale(${scale}) rotate(${rotation}deg)`,
                    }}
                  />
                </div>
              </Draggable>

              <div className="layout_toolbar">
                <div>
                  <Button
                    onClick={handleRotateRight}
                    icon={<RotateRightIcon />}
                  />
                  <Button
                    onClick={handleRotateLeft}
                    icon={<RotateLeftIcon />}
                  />
                </div>

                <div>
                  <Button
                    onClick={handleZoomOut}
                    icon={<ZoomOutIcon disable={scale === MIN_SCALE} />}
                    disabled={scale === MIN_SCALE}
                  />

                  <Button
                    onClick={handleZoomIn}
                    icon={<ZoomInIcon disable={scale === MAX_SCALE} />}
                    disabled={scale === MAX_SCALE}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="layout-diagram_result_no">
              <div>
                <img src={images.noResult} alt="no_result" />
              </div>
              <p>{MESSAGE.NO_RESULT}</p>
            </div>
          )}
        </div>
      </LayoutDiagramWrapper>
    </LoadingCommon>
  );
}

export { LayoutDiagramPage };
