import {
  TAllReportRes,
  TAllReportSensorRes,
  TChildrenAllReport,
  TConvertAllReport,
} from "@models/report";
import dayjs from "dayjs";

// convert all report
const handleConvertAllReport = (listData: TAllReportRes[], date: string) => {
  return listData
    .map((item) => {
      const { storeReports, ...other } = item;
      if (!storeReports.length) {
        return {
          ...other,
          storeReports: [],
        };
      }

      const listStoreReportsDate = storeReports.map(
        (value) => value.reportDate.split("T")[0]
      );
      const storeReportsTemp = Array(7)
        .fill(0)
        .map((_, index) => {
          const currentDate = dayjs(date)
            .subtract(index, "day")
            .format("YYYY-MM-DD");
          if (listStoreReportsDate?.includes(currentDate?.toString())) {
            return storeReports?.[
              listStoreReportsDate?.indexOf(currentDate?.toString())
            ];
          }
          return {
            isCommented: false,
            reportDate: "",
            underQuantity: 0,
            overQuantity: 0,
            comments: null,
            storeManagerName: "",
          };
        });

      return {
        ...other,
        storeReports: storeReportsTemp,
      };
    })
    .reduce((result: TConvertAllReport[], item, index: number) => {
      const { storeReports, ...other } = item;

      const arrUnderQuantity: number[] = [];
      const arrOverQuantity: number[] = [];
      const arrStoreManagerName: string[] = [];
      const arrComments: (string | null)[] = [];
      const arrReportDate: string[] = [];
      const arrIsCommented: boolean[] = [];

      for (let i = storeReports.length - 1; i >= 0; i--) {
        arrUnderQuantity.push(storeReports[i].underQuantity);
        arrOverQuantity.push(storeReports[i].overQuantity);
        arrStoreManagerName.push(storeReports[i].storeManagerName);
        arrComments.push(storeReports[i].comments);
        arrReportDate.push(storeReports[i].reportDate);
        arrIsCommented.push(storeReports[i].isCommented);
      }

      for (let i = 0; i < arrIsCommented.length; i++) {
        if (arrIsCommented[i] === true) {
          arrComments[i] = "true";
        }
      }

      return [
        ...result,
        {
          ...other,
          key: index,
          blueNumber: arrUnderQuantity,
          redNumber: arrOverQuantity,
          storeManagerName: arrStoreManagerName,
          comments: arrComments,
          reportDate: arrReportDate,
          isCommented: arrIsCommented,
          children: [],
        },
      ];
    }, []);
};

// convert all report sensor
const handleConvertAllReportSensor = (
  listData: TAllReportSensorRes,
  storeCode: string,
  date: string
) => {
  const result = listData
    .map((item) => {
      const { sensorReportItems, ...other } = item;
      if (!sensorReportItems.length) {
        return {
          ...other,
          sensorReportItems: [],
        };
      }

      const listSensorReportsDate = sensorReportItems.map(
        (value) => value.reportDate.split("T")[0]
      );
      const sensorReportsTemp = Array(7)
        .fill(0)
        .map((_, index) => {
          const currentDate = dayjs(date)
            .subtract(index, "day")
            .format("YYYY-MM-DD");
          if (listSensorReportsDate.includes(currentDate)) {
            return sensorReportItems[
              listSensorReportsDate.indexOf(currentDate)
            ];
          }
          return {
            comment: null,
            overQuantity: 0,
            underQuantity: 0,
            reportDate: "",
            popularTemperature: {
              "10:00": {
                temperature: "",
                isDefrostingTime: false,
                threshold: 0,
                isActive: null,
                isNoCooling: false,
              },
              "15:00": {
                temperature: "",
                isDefrostingTime: false,
                threshold: 0,
                isActive: null,
                isNoCooling: false,
              },
            },
          };
        });

      return {
        ...other,
        sensorReportItems: sensorReportsTemp,
      };
    })
    .reduce((result: TChildrenAllReport[], item) => {
      const { sensorReportItems, ...other } = item;

      const arrUnderQuantity: number[] = [];
      const arrOverQuantity: number[] = [];
      const arrTenClock: string[] = [];
      const arrFifteenClock: string[] = [];
      const arrTenThreshold: number[] = [];
      const arrFifteenThreshold: number[] = [];
      const arrFifteenDefrostTime: boolean[] = [];
      const arrTenDefrostTime: boolean[] = [];
      const arrTenIsActive: (boolean | null)[] = [];
      const arrFifteenIsActive: (boolean | null)[] = [];
      const arrTenIsNoCooling: boolean[] = [];
      const arrFifteenIsNoCooling: boolean[] = [];
      const arrComment: (string | null)[] = [];
      const arrReportDate: string[] = [];

      for (let i = sensorReportItems.length - 1; i >= 0; i--) {
        arrUnderQuantity.push(sensorReportItems[i].underQuantity);
        arrOverQuantity.push(sensorReportItems[i].overQuantity);
        arrComment.push(sensorReportItems[i].comment);
        arrReportDate.push(sensorReportItems[i].reportDate);
        arrTenClock.push(
          sensorReportItems[i].popularTemperature["10:00"].temperature
        );
        arrFifteenClock.push(
          sensorReportItems[i].popularTemperature["15:00"].temperature
        );
        arrTenThreshold.push(
          sensorReportItems[i].popularTemperature["10:00"].threshold
        );
        arrFifteenThreshold.push(
          sensorReportItems[i].popularTemperature["15:00"].threshold
        );
        arrFifteenDefrostTime.push(
          sensorReportItems[i].popularTemperature["15:00"].isDefrostingTime
        );
        arrTenDefrostTime.push(
          sensorReportItems[i].popularTemperature["10:00"].isDefrostingTime
        );
        arrTenIsActive.push(
          sensorReportItems[i].popularTemperature["10:00"].isActive
        );
        arrFifteenIsActive.push(
          sensorReportItems[i].popularTemperature["15:00"].isActive
        );
        arrTenIsNoCooling.push(
          sensorReportItems[i].popularTemperature["10:00"].isNoCooling
        );
        arrFifteenIsNoCooling.push(
          sensorReportItems[i].popularTemperature["15:00"].isNoCooling
        );
      }

      return [
        ...result,
        {
          ...other,
          storeCode,
          key: other.id,
          children: null,
          storeManagerName: [],
          reportDate: arrReportDate,
          comments: arrComment,
          blueNumber: arrUnderQuantity,
          redNumber: arrOverQuantity,
          tenClock: arrTenClock,
          fifteenClock: arrFifteenClock,
          tenThreshold: arrTenThreshold,
          fifteenThreshold: arrFifteenThreshold,
          tenDefrostTime: arrTenDefrostTime,
          fifteenDefrostTime: arrFifteenDefrostTime,
          tenIsActive: arrTenIsActive,
          fifteenIsActive: arrFifteenIsActive,
          tenIsNoCooling: arrTenIsNoCooling,
          fifteenIsNoCooling: arrFifteenIsNoCooling,
        },
      ];
    }, []);

  return result;
};

export { handleConvertAllReport, handleConvertAllReportSensor };
