type TCustomLegendChartProps = {
  color: string;
};

function CustomLegendChart({ color }: TCustomLegendChartProps) {
  return (
    <svg
      width="56"
      height="8"
      viewBox="0 0 56 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="56" height="8" rx="4" fill={color} />
    </svg>
  );
}

export default CustomLegendChart;
