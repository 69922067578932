import { notification } from "antd";
import type { ArgsProps } from "antd/lib/notification";

const NOTIFICATION_CLASS_NAME = "common_notification";
const NOTIFICATION_SUCCESS__CLASS_NAME = "common_notification_success";
const NOTIFICATION_ERROR__CLASS_NAME = "common_notification_error";

const COMMON_NOTIFICATION_PROPS: Omit<ArgsProps, "message"> = {
  placement: "topRight",
  duration: 10,
};

export const appToast = {
  success: (props: ArgsProps) =>
    notification.success({
      ...COMMON_NOTIFICATION_PROPS,
      ...props,
      className: `${NOTIFICATION_CLASS_NAME} ${NOTIFICATION_SUCCESS__CLASS_NAME}`,
    }),
  error: (props: ArgsProps) =>
    notification.error({
      ...COMMON_NOTIFICATION_PROPS,
      ...props,
      className: `${NOTIFICATION_CLASS_NAME} ${NOTIFICATION_ERROR__CLASS_NAME}`,
    }),
};
