import { EInstallLocation, ETemperatureType } from "types/others";
import { OPEN_CASE_CONSTANT } from "../constant";
import { DATA_EMPTY, DATE_HYPHEN_FORMAT } from "@constants/common";
import { Dayjs } from "dayjs";
import { TReportTemperatureSensorRes } from "@models/device";

const { TITLE } = OPEN_CASE_CONSTANT;

const renameKeys = (
  obj: {
    [key: string]: {
      temperature: string;
      isDefrostingTime: boolean;
      threshold: number;
      isActive: boolean | null;
      isNoCooling: boolean;
    };
  },
  newKeys: { [key: string]: string }
) => {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = newKeys[key];
    return {
      [newKey]:
        obj[key].temperature === TITLE.N_A
          ? [DATA_EMPTY, "0", false, null, false]
          : [
              obj[key].temperature,
              obj[key].threshold.toString(),
              obj[key].isDefrostingTime,
              obj[key].isActive,
              obj[key].isNoCooling,
            ],
    };
  });
  return Object.assign({}, ...keyValues);
};

const renderTemperatureType = (val: string) => {
  switch (val) {
    case ETemperatureType.REFRIGERATED:
      return TITLE.REFRIGERATED;
    case ETemperatureType.FROZEN:
      return TITLE.FROZEN;
    case ETemperatureType.UNREFRIGERATED:
      return TITLE.UNREFRIGERATED;
    case ETemperatureType.NOT_USED:
      return TITLE.NOT_USED;
    default:
      return val;
  }
};

const renderInstallLocation = (val: string) => {
  switch (val) {
    case EInstallLocation.SALE_FLOOR:
      return TITLE.SALE_FLOOR;
    case EInstallLocation.BACKYARD:
      return TITLE.BACKYARD;
    default:
      return val;
  }
};

const formatDateParam = (reportDate: Dayjs, redirectDate?: Dayjs) => {
  return (redirectDate || reportDate).format(DATE_HYPHEN_FORMAT);
};

const renderReportTempUnder = (value?: TReportTemperatureSensorRes) => {
  return value ? value.underQuantity.toString() : DATA_EMPTY;
};

const renderReportTempOver = (value?: TReportTemperatureSensorRes) => {
  return value ? value.overQuantity.toString() : DATA_EMPTY;
};

const renderClassNameHourTemp = (value: string) => {
  if (value === "1") {
    return "upper";
  } else if (value === "-1") {
    return "lower";
  } else {
    return undefined;
  }
};

export {
  renameKeys,
  renderTemperatureType,
  renderInstallLocation,
  formatDateParam,
  renderReportTempUnder,
  renderReportTempOver,
  renderClassNameHourTemp,
};
