import { images } from "@constants";
import {
  MASTER_DATA_CONSTANT,
  MAX_SIZE,
} from "@pages/MasterDataPage/constants";
import { masterDataApi } from "@services/masterDataApi";
import { appToast } from "@utils/appToast";
import { handleShowToastError } from "@utils/helpers";
import { Button } from "antd";
import { ChangeEventHandler, useRef, useState } from "react";
import { ETabName } from "types/others";
import { DataTabWrapper, ModalFailWrapper } from "../MasterDataStyled";
import _ from "lodash";
import { TAxiosResponse } from "types";
import { EHttpStatusCode } from "types/service";

const { MESSAGE, TITLE } = MASTER_DATA_CONSTANT;

type TDataTabProps = {
  tabName: string;
};

function DataTab({ tabName }: TDataTabProps) {
  // Import file
  const [isImport, setIsImport] = useState(false);
  const importInputRef = useRef<HTMLInputElement>(null);

  const [modalUploadFail, setModalUploadFail] = useState(false);
  const [errorColumns, setErrorColumns] = useState<string[]>([]);
  const [errorHeader, setErrorHeader] = useState("");
  const [errorRow, setErrorRow] = useState(0);

  const handleImport = async () => {
    importInputRef.current?.click();
  };

  const handleShowError = (response: TAxiosResponse) => {
    const errorCol = _.uniq(
      response.errors
        .filter((err) => err.columnName)
        .map((item) => `「${item.columnName}」列`)
    );

    setErrorRow(response.errors[0].rowIndex);
    setErrorColumns(errorCol);
  };

  const handleFileFormatWrong = (error: any) => {
    if (
      error.status === EHttpStatusCode.BAD_REQUEST &&
      error?.data?.code === MESSAGE.ERROR_HEADER_CODE
    ) {
      setModalUploadFail(true);
      setErrorRow(0);
      const result = error?.data?.description
        .split("Expected '")[1]
        .split("'")[0]
        .split(", ")
        .map((item: string) => `「${item}」列`);
      setErrorHeader(result.join("、") + "が正しくありません。");
      return;
    }
  };

  const handleImportCompany = async (file: File) => {
    try {
      const response = await masterDataApi.importCompany(file);

      if (response.ok) {
        appToast.success({ message: MESSAGE.IMPORT_SUCCESS });
      } else {
        setModalUploadFail(true);

        handleShowError(response);
      }
    } catch (error) {
      // Handle import wrong file format
      handleFileFormatWrong(error);
      // Error disconnect internet or server error
      handleShowToastError(error);
    } finally {
      setIsImport(false);
    }
  };

  const handleImportArea = async (file: File) => {
    try {
      const response = await masterDataApi.importArea(file);

      if (response.ok) {
        appToast.success({ message: MESSAGE.IMPORT_SUCCESS });
      } else {
        setModalUploadFail(true);
        handleShowError(response);
      }
    } catch (error) {
      // Handle import wrong file format
      handleFileFormatWrong(error);
      // Error disconnect internet or server error
      handleShowToastError(error);
    } finally {
      setIsImport(false);
    }
  };

  const handleImportManufacturer = async (file: File) => {
    try {
      const response = await masterDataApi.importManufacturer(file);

      if (response.ok) {
        appToast.success({ message: MESSAGE.IMPORT_SUCCESS });
      } else {
        setModalUploadFail(true);
        handleShowError(response);
      }
    } catch (error) {
      // Handle import wrong file format
      handleFileFormatWrong(error);
      // Error disconnect internet or server error
      handleShowToastError(error);
    } finally {
      setIsImport(false);
    }
  };

  const handleImportPurpose = async (file: File) => {
    try {
      const response = await masterDataApi.importPurpose(file);

      if (response.ok) {
        appToast.success({ message: MESSAGE.IMPORT_SUCCESS });
      } else {
        setModalUploadFail(true);
        handleShowError(response);
      }
    } catch (error) {
      // Handle import wrong file format
      handleFileFormatWrong(error);
      // Error disconnect internet or server error
      handleShowToastError(error);
    } finally {
      setIsImport(false);
    }
  };

  const handleImportUser = async (file: File) => {
    try {
      const response = await masterDataApi.importUser(file);

      if (response.ok) {
        appToast.success({ message: MESSAGE.IMPORT_SUCCESS });
      } else {
        setModalUploadFail(true);
        handleShowError(response);
      }
    } catch (error) {
      // Handle import wrong file format
      handleFileFormatWrong(error);
      // Error disconnect internet or server error
      handleShowToastError(error);
    } finally {
      setIsImport(false);
    }
  };

  const handleImportStore = async (file: File) => {
    try {
      const response = await masterDataApi.importStore(file);

      if (response.ok) {
        appToast.success({ message: MESSAGE.IMPORT_SUCCESS });
      } else {
        setModalUploadFail(true);
        handleShowError(response);
      }
    } catch (error) {
      // Handle import wrong file format
      handleFileFormatWrong(error);
      // Error disconnect internet or server error
      handleShowToastError(error);
    } finally {
      setIsImport(false);
    }
  };

  const handleImportSensor = async (file: File) => {
    try {
      const response = await masterDataApi.importSensor(file);

      if (response.ok) {
        appToast.success({ message: MESSAGE.IMPORT_SUCCESS });
      } else {
        setModalUploadFail(true);
        // Handle CHCode required 3 characters
        if (
          response.errors.length === 1 &&
          response.errors[0].columnName === TITLE.ID_MANAGEMENT &&
          response.errors[0].description === MESSAGE.CHCODE_CHARACTER_ENG
        ) {
          setErrorRow(response.errors[0].rowIndex);
          setErrorHeader(MESSAGE.CHCODE_CHARACTER_JP);
          return;
        }
        handleShowError(response);
      }
    } catch (error) {
      // Handle import wrong file format
      handleFileFormatWrong(error);
      // Error disconnect internet or server error
      handleShowToastError(error);
    } finally {
      setIsImport(false);
    }
  };

  const handleImportDepartment = async (file: File) => {
    try {
      const response = await masterDataApi.importDepartment(file);

      if (response.ok) {
        appToast.success({ message: MESSAGE.IMPORT_SUCCESS });
      } else {
        setModalUploadFail(true);
        handleShowError(response);
      }
    } catch (error) {
      // Handle import wrong file format
      handleFileFormatWrong(error);
      // Error disconnect internet or server error
      handleShowToastError(error);
    } finally {
      setIsImport(false);
    }
  };

  const handleCallApiImport = async (file: File) => {
    setIsImport(true);

    switch (tabName) {
      case ETabName.COMPANY:
        // import company
        await handleImportCompany(file);
        return;
      case ETabName.AREA:
        // import AREA
        await handleImportArea(file);
        return;
      case ETabName.MANUFACTURER:
        // import MANUFACTURER
        await handleImportManufacturer(file);
        return;
      case ETabName.PURPOSE:
        // import PURPOSE
        await handleImportPurpose(file);
        return;
      case ETabName.STORE:
        // import STORE
        await handleImportStore(file);
        return;
      case ETabName.SENSOR:
        // import SENSOR
        await handleImportSensor(file);
        return;
      case ETabName.DEPARTMENT:
        // import DEPARTMENT
        await handleImportDepartment(file);
        return;

      default:
        // import user
        await handleImportUser(file);
        return;
    }
  };

  const handleChangeInputImport: ChangeEventHandler<HTMLInputElement> = async (
    event
  ) => {
    const fileToImport = event.target.files?.[0];

    if (!fileToImport) {
      return;
    }

    if (fileToImport.type !== "text/csv") {
      appToast.error({ message: MESSAGE.FILE_FORMAT });
    } else if (fileToImport.size >= MAX_SIZE) {
      appToast.error({ message: MESSAGE.FILE_LARGE });
    } else {
      await handleCallApiImport(fileToImport);
    }

    event.target.value = "";
  };

  return (
    <>
      <DataTabWrapper>
        <div className="master-data_tab-content">
          <img src={images.importData} alt="import_data" />
          <p>{MESSAGE.NO_COMPANY}</p>

          <div className="master-data_tab-content-btn">
            <input
              type="file"
              ref={importInputRef}
              hidden
              onChange={handleChangeInputImport}
            />
            <Button
              loading={isImport}
              disabled={isImport}
              onClick={handleImport}
            >
              {TITLE.BTN_IMPORT}
            </Button>
          </div>
        </div>
      </DataTabWrapper>

      <ModalFailWrapper
        centered
        closeIcon={false}
        open={modalUploadFail}
        title={TITLE.TITLE_FAIL}
        onOk={() => {
          setModalUploadFail(false);
          setErrorColumns([]);
          setErrorHeader("");
          setErrorRow(0);
        }}
        maskClosable={false}
        okText={TITLE.BTN_CLOSE}
        width={416}
      >
        <img src={images.importDataFail} alt="master-data_fail" />
        <div className="master-data_fail-message">
          {errorRow ? (
            <p>
              {errorRow} {MESSAGE.ERROR_LINE}
            </p>
          ) : null}

          <p>
            {errorHeader || (
              <>
                {errorColumns.join("、")}
                {MESSAGE.ERROR_COLUMN}
              </>
            )}
          </p>
        </div>
      </ModalFailWrapper>
    </>
  );
}

export { DataTab };
