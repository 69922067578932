import { DEFAULT_PAGE_SIZE } from "@constants/common";
import { TCommonError } from "@models/common";
import {
  TCheckSUserExitsReq,
  TFormDetailUserRes,
  TFormUserModalRes,
  TListUserReq,
  TListUserRes,
  TRegisUserReq,
  TUpdateUserReq,
} from "@models/user";
import { TAxiosResponse } from "types";
import { axiosInstance } from "./axiosInstance";

const userApi = {
  getListUser: async (payload: TListUserReq) => {
    const _pageIndex = payload.pageIndex ? payload.pageIndex : 1;
    const _payload: Omit<TListUserReq, "pageIndex"> = {
      username: payload.username || "",
      name: payload.name || "",
      companyCode: payload.companyCode || "",
      storeCode: payload.storeCode || "",
      role: payload.role || "",
    };
    const url = `/user/users?userName=${_payload.username}&Name=${_payload.name}&companyCode=${_payload.companyCode}&StoreCode=${_payload.storeCode}&Role=${_payload.role}&page=${_pageIndex}&size=${DEFAULT_PAGE_SIZE}`;

    const response: TAxiosResponse<TListUserRes[], TCommonError> =
      await axiosInstance.get(url);
    return response;
  },

  getDetailUser: async (payload: string) => {
    const url = `/User/${payload}`;
    const response: TAxiosResponse<TFormDetailUserRes, TCommonError> =
      await axiosInstance.get(url);
    return response;
  },

  registrationUser: async (payload: TRegisUserReq) => {
    const _payload: TRegisUserReq = {
      ...payload,
      username: payload.username.trim(),
      name: payload.name.trim(),
      password: payload.password.trim(),
      companyCode: payload.companyCode.trim(),
      storeCode: payload.storeCode.trim(),
      role: payload.role,
    };
    const url = "/User/create";
    const response: TAxiosResponse<TFormUserModalRes, TCommonError> =
      await axiosInstance.post(url, _payload);
    return response;
  },

  updateUser: async (payload: TUpdateUserReq) => {
    const _payload: TUpdateUserReq = {
      ...payload,
      username: payload.username.trim(),
      name: payload.name.trim(),
      password: payload.password.trim(),
      companyCode: payload.companyCode.trim(),
      storeCode: payload.storeCode.trim(),
      role: payload.role,
    };
    const url = "/User/update";
    const response: TAxiosResponse<any, TCommonError> = await axiosInstance.put(
      url,
      _payload
    );
    return response;
  },

  deleteUser: async (payload: string) => {
    const url = `/User/${payload}`;
    const response: TAxiosResponse<any, TCommonError> =
      await axiosInstance.delete(url);
    return response;
  },

  checkUserExist: async (payload: TCheckSUserExitsReq) => {
    const url = `/User/exists`;
    const response: TAxiosResponse<any, TCommonError> =
      await axiosInstance.post(url, payload);
    return response;
  },

  checkUpdateUserExist: async (payload: TCheckSUserExitsReq) => {
    const url = `/User/update/exist`;
    const response: TAxiosResponse<any, TCommonError> =
      await axiosInstance.post(url, payload);
    return response;
  },
};

export { userApi };
