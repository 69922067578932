export type TUser = {
  username: string;
  name: string | null;
  role: EUserRoles[];
  email: string | null;
  phoneNumber: string | null;
  companyCode: string | null;
  companyName: string | null;
  storeCode: string | null;
  storeName: string | null;
};

export enum EUserRoles {
  STORE = "STORE",
  ADMIN = "ADMIN",
  MENTEX = "MENTEX",
}

export enum EUserAccessRoles {
  MENTEX = "MENTEX",
  ADMIN = "ADMIN",
  STORE = "STORE",
}

export type TListUserReq = {
  username: string;
  name: string;
  companyCode: string;
  storeCode: string;
  role: number | string;
  pageIndex: number;
};

export type TFormUserReq = Omit<TListUserReq, "pageIndex">;

export type TListUserRes = {
  id: string;
  userName: string;
  name: string;
  companyCode: string;
  storeCode: string;
  role: string;
  companyName: string;
  storeName: string;
  isDeleted: boolean;
};

export type TListUserTable = TListUserRes & {
  key: number;
};

export type TFormModalUserRegisterReq = {
  username: string;
  name: string;
  password: string;
  companyCode: string;
  storeCode: string;
  role: number;
};

export type TGetStoreByCompany = {
  companyCode: string;
  companyName: string;
};

export type TFormDetailUserRes = {
  id: string;
  userName: string;
  name: string;
  companyCode: string;
  storeCode: string;
  role: number | undefined;
};

export type TFormUserModalRes = {
  id: string;
  userName: string;
  name: string;
  companyCode: string;
  storeCode: string;
  role: string;
  companyName: string;
  storeName: string;
  isDeleted: boolean;
};

export type TRegisUserReq = {
  username: string;
  name: string;
  password: string;
  companyCode: string;
  storeCode: string;
  role: number;
};

export type TUpdateUserReq = TRegisUserReq & {
  id?: string;
};

export type TCheckSUserExitsReq = {
  id?: string;
  username?: string;
  companyCode: string;
  storeCode: string;
  role: string;
};
