import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useAppSelector } from "@config/hooks";
import {
  CompanyIcon,
  DeviceIcon,
  FileManagementIcon,
  LayoutDiagramIcon,
  MasterDataIcon,
  PurposeIcon,
  SpreadsheetIcon,
  StatusInquiryIcon,
  StoreIcon,
  TemperatureReportIcon,
  UserListIcon,
} from "@constants/svgs";
import { EUserAccessRoles, EUserRoles } from "@models/user";
import { getAccessRoleFromUserRole } from "@utils/helpers";
import { Layout, MenuProps } from "antd";
import { MenuInfo } from "rc-menu/lib/interface";
import { PropsWithChildren, createContext, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  CollapseButtonStyled,
  CollapseButtonWrapperStyled,
  ContentStyled,
  LayoutAdminWrapperStyled,
  MenuStyled,
  SidebarStyled,
} from "./LayoutAdminStyled";
import { Header } from "./components/Header/Header";

const buildVersion = `${process.env.REACT_APP_BUILD_ENV}_${process.env.REACT_APP_BUILD_DATE}_${process.env.REACT_APP_BUILD_VERSION}`;

// ----- CONTEXT -----
type TAppContext = {
  collapsed: boolean;
  role: EUserRoles[] | undefined;
  companyCode?: string | null;
  companyName?: string | null;
  storeCode?: string | null;
  storeName?: string | null;
  username?: string;
};

export const AppContext = createContext<TAppContext>({
  collapsed: false,
  role: undefined,
  companyCode: null,
  companyName: null,
  storeCode: null,
  storeName: null,
});
// ----- CONTEXT -----

type MenuItem = Required<MenuProps>["items"][number];
type TMenuItem = {
  title: string;
  path: string;
  key: string;
  icon?: JSX.Element;
  children?: TMenuItem[];
  onClick?: (e: React.MouseEvent<HTMLAnchorElement> | undefined) => void;
  afterClick?: () => void;
  accessRoles?: EUserAccessRoles[];
};

const navItems: TMenuItem[] = [
  {
    title: "温度日報",
    path: "/dailyreport",
    key: "/dailyreport",
    icon: (
      <span>
        <TemperatureReportIcon />
      </span>
    ),
  },
  {
    title: "集計表",
    path: "/allreport",
    key: "/allreport",
    icon: (
      <span>
        <SpreadsheetIcon />
      </span>
    ),
    accessRoles: [EUserAccessRoles.ADMIN, EUserAccessRoles.MENTEX],
  },
  {
    title: "レイアウト図",
    path: "/layout",
    key: "/layout",
    icon: (
      <span>
        <LayoutDiagramIcon />
      </span>
    ),
  },
  {
    title: "全店稼動状況照会",
    path: "/alldevice",
    key: "/alldevice",
    icon: (
      <span>
        <StatusInquiryIcon />
      </span>
    ),
    accessRoles: [EUserAccessRoles.ADMIN, EUserAccessRoles.MENTEX],
  },
  {
    title: "マスタデータ",
    path: "/masterdata",
    key: "/masterdata",
    icon: (
      <span>
        <MasterDataIcon />
      </span>
    ),
    accessRoles: [EUserAccessRoles.ADMIN],
  },
  {
    title: "出力ファイル管理",
    path: "/filemanage",
    key: "/filemanage",
    icon: (
      <span>
        <FileManagementIcon />
      </span>
    ),
    accessRoles: [EUserAccessRoles.ADMIN],
  },
  {
    title: "店舗",
    path: "/store",
    key: "/store",
    icon: (
      <span>
        <StoreIcon />
      </span>
    ),
    accessRoles: [EUserAccessRoles.ADMIN, EUserAccessRoles.MENTEX],
  },
  {
    title: "ユーザー一覧",
    path: "/user-list",
    key: "/user-list",
    icon: (
      <span>
        <UserListIcon />
      </span>
    ),
    accessRoles: [EUserAccessRoles.ADMIN, EUserAccessRoles.MENTEX],
  },
  {
    title: "会社一覧",
    path: "/company",
    key: "/company",
    icon: (
      <span>
        <CompanyIcon />
      </span>
    ),
    accessRoles: [EUserAccessRoles.ADMIN, EUserAccessRoles.MENTEX],
  },
  {
    title: "機器",
    path: "/device",
    key: "/device",
    icon: (
      <span>
        <DeviceIcon />
      </span>
    ),
    accessRoles: [EUserAccessRoles.ADMIN, EUserAccessRoles.MENTEX],
  },
  {
    title: "用途",
    path: "/purpose",
    key: "/purpose",
    icon: (
      <span>
        <PurposeIcon />
      </span>
    ),
    accessRoles: [EUserAccessRoles.ADMIN, EUserAccessRoles.MENTEX],
  },
];

const getNavbarItems = (
  items: TMenuItem[],
  userRole?: EUserAccessRoles
): MenuItem[] => {
  if (!userRole) {
    return [];
  }
  const result = items.reduce((itemAccessible: MenuItem[], item) => {
    if (!item.accessRoles || item.accessRoles?.includes(userRole)) {
      const handleClick = (e: any) => {
        item.onClick?.(e);
        item.afterClick?.();
      };
      const navItem: MenuItem = {
        label: (
          <Link to={item.path} onClick={(e) => handleClick(e)}>
            {item.title}
          </Link>
        ),
        key: item.key,
        icon: item.icon,
      };
      return [...itemAccessible, navItem];
    }
    return itemAccessible;
  }, []);
  return result;
};

const LayoutAdmin = (props: PropsWithChildren) => {
  const { children } = props;
  const [collapsed, setCollapsed] = useState(true);
  const location = useLocation();

  const {
    role: userRole,
    companyCode,
    companyName,
    storeCode,
    storeName,
    username,
  } = useAppSelector((state) => state.auth.currentUser) || {};
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const handleChangeMenu = (info: MenuInfo) => {
    const [currentOpenKey] = openKeys;
    if (info.keyPath.indexOf(currentOpenKey) === -1) {
      setOpenKeys([]);
    }
  };

  const triggerCollapse = () => {
    setCollapsed((prevState) => !prevState);
  };

  const userAccessRole = getAccessRoleFromUserRole(userRole);
  const sidebarItems = getNavbarItems(navItems, userAccessRole);

  const collapsedText = useMemo(() => {
    if (collapsed) {
      return "collapsed";
    }
    return "expand";
  }, [collapsed]);

  return (
    <AppContext.Provider
      value={{
        collapsed,
        role: userRole,
        companyCode,
        companyName,
        storeCode,
        storeName,
        username,
      }}
    >
      <LayoutAdminWrapperStyled>
        <SidebarStyled
          collapse={collapsedText} // styled custom prop
          collapsed={collapsed} // default prop of antd
        >
          <MenuStyled
            defaultSelectedKeys={[location.pathname]}
            selectedKeys={[location.pathname]}
            mode="inline"
            hidden={false}
            items={sidebarItems}
            openKeys={openKeys}
            onSelect={handleChangeMenu}
            collapse={collapsedText}
          />

          <CollapseButtonWrapperStyled>
            {!collapsed && <div className="ver-build">{buildVersion}</div>}
            <CollapseButtonStyled size="large" onClick={triggerCollapse}>
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </CollapseButtonStyled>
          </CollapseButtonWrapperStyled>
        </SidebarStyled>

        <Layout>
          <Header />
          <ContentStyled collapse={collapsedText}>{children}</ContentStyled>
        </Layout>
      </LayoutAdminWrapperStyled>
    </AppContext.Provider>
  );
};

export { LayoutAdmin };
