import { images } from "@constants";
import styled from "styled-components";

export const LoginPageWrapper = styled.div`
  .background-form {
    height: var(--window-height);
    background-image: url(${images.background_login});
    background-repeat: round;
  }
`;

export const FormLoginWrapper = styled.div<{
  changeheight: string;
}>`
  padding: 32px 40px 40px;
  width: 656px;
  height: ${(props) => (props.changeheight ? "630px" : "590px")};

  border-radius: 12px;
  background-color: var(--color-white);

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .ver-build {
    text-align: center;
    color: var(--color-secondary);
  }

  .logo-form {
    padding: 16px 0;
  }

  .logo-form img {
    margin: 0 auto;
    display: block;
    width: 238px;
    height: 44px;
  }

  &&& {
    .login-form_title {
      margin: 40px 0 24px;

      h3 {
        margin-bottom: 2px;
        line-height: 40px;
        font-size: 32px;
        font-weight: var(--font-medium);
      }

      p {
        font-weight: var(--font-normal);
        color: #98a2b3;
      }
    }

    .ant-form-item-label {
      font-weight: var(--font-normal);
      padding-bottom: 4px;
    }

    .ant-input-affix-wrapper {
      border-radius: 4px;
      padding: 16px;

      .ant-input {
        font-weight: var(--font-medium);
      }

      .ant-input::placeholder {
        color: var(--color-secondary);
      }

      .ant-input[type="password"] {
        font-size: 30px;
        letter-spacing: 2px;
        height: 26px;
        margin-top: -3px;
      }

      .ant-input[type="password"]::placeholder,
      .ant-input[type="text"] {
        font-size: var(--font-size-base);
      }
    }

    .id-input .ant-input-suffix {
      display: none;
    }

    .ant-input-outlined {
      border-color: ${(props) =>
        props.changeheight
          ? "var(--color-error)"
          : "var(--color-border-primary)"};
      box-shadow: none;
    }

    .ant-input-outlined:focus-within {
      border-width: ${(props) =>
        props.changeheight ? "1px" : "2px"} !important;
      border-color: ${(props) =>
        props.changeheight ? "var(--color-error)" : "#1172eb !important"};
      box-shadow: 0px 0px 0px 2px #deefff80 !important;
      outline: none !important;
    }

    .ant-input-affix-wrapper-focused {
      box-shadow: none;
    }

    .ant-input-password-icon.anticon {
      color: #344054;
      font-size: var(--font-size-xl);
    }

    .logo-form_message-error {
      font-weight: var(--font-normal);
      color: var(--color-error);
      margin-bottom: 24px;
    }

    .login-form_button {
      width: 100%;
      height: 56px;
      box-shadow: none;

      border: none;
      color: var(--color-white);
      font-weight: var(--font-bold);
      font-size: var(--font-size-lg);
    }

    .login-form_button:disabled {
      background-color: var(--color-border-secondary);
      color: #98a2b3;
    }
  }
`;
