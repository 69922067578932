import { combineReducers } from "@reduxjs/toolkit";

import { authReducer } from "./authSlice";
import { commonReducer } from "./commonSlice";

export const rootReducer = combineReducers({
  // Common
  auth: authReducer,
  common: commonReducer,
});
