import { TCommonError } from "@models/common";
import { axiosInstance } from "./axiosInstance";
import { TAxiosResponse } from "types";
import {
  TAllStoreConditionsReq,
  TAllStoreConditionsRes,
  TAllStoreStatusCSVReq,
  TAllStoreStatusCSVRes,
  TAllStoreStatusReq,
  TAllStoreStatusRes,
} from "@models/store";
import { DEFAULT_PAGE_SIZE } from "@constants/common";

const allStoreApi = {
  getAllStoreStatus: async (payload: TAllStoreStatusReq) => {
    const _page = payload.page ? payload.page : 1;

    const response: TAxiosResponse<TAllStoreStatusRes, TCommonError> =
      await axiosInstance.get("/Store/store-operating-status", {
        params: {
          CompanyId: payload.companyId,
          AreaId: payload.areaId,
          StoreCode: payload.storeCode.trim(),
          StoreName: payload.storeName.trim(),
          ManufacturerName: payload.manufacturerName.trim(),
          PurposeId: payload.purposeId,
          InstalledLocation: payload.installedLocation,
          SortField: payload.sortField,
          SortDirection: payload.sortDirection,
          Page: _page,
          Size: DEFAULT_PAGE_SIZE,
        },
      });
    return response;
  },

  getAllStoreStatusCSV: async (payload: TAllStoreStatusCSVReq) => {
    const url = `/Store/store-operating-status/csv?CompanyCode=${payload.companyCode}&CompanyName=${payload.companyName}`;
    const response: TAxiosResponse<TAllStoreStatusCSVRes, TCommonError> =
      await axiosInstance.get(url);
    return response;
  },

  getAllStoreConditions: async (payload: TAllStoreConditionsReq) => {
    const url = "/Store/search-conditions";
    const response: TAxiosResponse<TAllStoreConditionsRes, TCommonError> =
      await axiosInstance.post(url, payload);
    return response;
  },
};

export { allStoreApi };
