import { LoadingCommon } from "@components";
import EmptyDataTable from "@components/EmptyDataTable/EmptyDataTable";
import { images } from "@constants";
import { TEN } from "@constants/common";
import { SearchIcon } from "@constants/svgs";
import { Button, ConfigProvider, Input, Table } from "antd";
import {
  ManagementTabWrapper,
  ModalDownloadWrapper,
  ModalViewWrapper,
} from "../FileManageStyled";
import { FILE_MANAGE_CONSTANT, HEIGHT_TABLE } from "../constant";
import useFileManage from "../hooks/useFileManage";
import { SetURLSearchParams } from "react-router-dom";

type TManagementTabProps = {
  tabName: string;
  searchParams: URLSearchParams;
  setSearchParams: SetURLSearchParams;
};

const { TITLE, MESSAGE, PLACEHOLDER } = FILE_MANAGE_CONSTANT;

function ManagementTab({
  tabName,
  searchParams,
  setSearchParams,
}: TManagementTabProps) {
  const { state, handler } = useFileManage({
    exportedType: tabName,
    searchParams,
    setSearchParams,
  });

  return (
    <LoadingCommon loading={state.loadingPage}>
      <ManagementTabWrapper listdata={state.listDocuments.length}>
        <div className="management-tab_input">
          <Input
            ref={state.valueInputRef}
            placeholder={PLACEHOLDER.SEARCH}
            size="large"
            maxLength={50}
          />
          <div>
            <Button
              onClick={() => handler.handleSearch()}
              icon={<SearchIcon />}
            />
          </div>
        </div>

        <div className="management-tab_table">
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  headerBg: "var(--background-header-table)",
                  borderColor: "var(--color-border-primary)",
                  headerColor: "var(--color-header-table)",
                  rowHoverBg: "var(--color-row-hover)",
                  fontWeightStrong: 700,
                },
              },
            }}
            renderEmpty={() =>
              !state.listDocuments.length ? (
                <EmptyDataTable message={MESSAGE.NO_DATA} />
              ) : undefined
            }
          >
            <Table
              rowKey={"id"}
              bordered
              virtual={state.listDocuments.length > 0}
              columns={state.columns}
              dataSource={state.listDocuments}
              scroll={{
                y: state.listDocuments.length > TEN ? HEIGHT_TABLE : undefined,
              }}
              pagination={false}
              onScroll={handler.handleScroll}
            />
          </ConfigProvider>
        </div>
      </ManagementTabWrapper>

      <ModalViewWrapper
        centered
        closeIcon={false}
        open={state.modalView}
        title={TITLE.TITLE_VIEW}
        onOk={handler.handleOkModalView}
        maskClosable={false}
        okText={TITLE.BTN_CLOSE}
        width={416}
      >
        <img src={images.modalFileManage} alt="modal_view" />
        <div className="management-tab_modal-view">
          <p>
            {TITLE.FILE_NAME_DOT}
            {state.info?.name}
          </p>
          <p>
            {TITLE.COMPANY_DOT}
            {state.info?.companyName}
          </p>
          <p>
            {TITLE.DATE_DOT}
            {state.info?.startDate}{" "}
            {state.info?.endDate ? `~ ${state.info.endDate}` : null}
          </p>
        </div>
      </ModalViewWrapper>

      <ModalDownloadWrapper
        centered
        closeIcon={false}
        open={state.modalDownload}
        title={TITLE.TITLE_DOWNLOAD}
        confirmLoading={state.loadingPage}
        onOk={handler.handleOkModalDownload}
        onCancel={handler.handleCancelModalDownload}
        maskClosable={false}
        cancelText={TITLE.BTN_CANCEL}
        okText={TITLE.BTN_OK}
        width={434}
      >
        <img src={images.confirmApproveTemperature} alt="modal_download" />
        <div className="management-tab_modal-download">
          <p>{state.info?.name}</p>
          <p>{MESSAGE.DOWNLOAD_AGAIN}</p>
        </div>
      </ModalDownloadWrapper>

      <ModalDownloadWrapper
        centered
        closeIcon={false}
        open={state.modalDelete}
        title={TITLE.TITLE_DELETE}
        confirmLoading={state.loadingPage}
        onOk={handler.handleOkModalDelete}
        onCancel={handler.handleCancelModalDelete}
        maskClosable={false}
        cancelText={TITLE.BTN_CANCEL}
        okText={TITLE.BTN_OK}
        width={426}
      >
        <img src={images.modalDelete} alt="modal_delete" />
        <div className="management-tab_modal-download">
          <p>{state.info?.name}</p>
          <p>{MESSAGE.DELETE_FILE}</p>
        </div>
      </ModalDownloadWrapper>
    </LoadingCommon>
  );
}

export default ManagementTab;
