import { COMMON_MESSAGE_ERROR, OPTION_EMPTY } from "@constants/common";
import { TSelectValue } from "@models/common";
import { layoutApi } from "@services/layoutApi";
import { handleShowToastError } from "@utils/helpers";
import { FormInstance } from "antd";
import { PURPOSE_CONSTANT } from "../constant";
import {
  TCheckPurposeExistReq,
  TFormPurposeReq,
  TListPurpose,
  TListPurposeReq,
  TPurposeRegistrationReq,
  TPurposeRegistrationRes,
  TPurposeUpdateReq,
} from "@models/purpose";
import { purposeApi } from "@services/purposeApi";
import { EHttpStatusCode } from "types/service";
import { TAxiosResponse } from "types";
import { appToast } from "@utils/appToast";
import { EModalType } from "types/others";
import _ from "lodash";

const { NAME, MESSAGE } = PURPOSE_CONSTANT;

function usePurposeApi() {
  // ***** API *****
  const getPurposesByDepartment = async (
    payload: string,
    form: FormInstance,
    setPurposes: (val: TSelectValue[]) => void,
    setLoadingPurpose: (val: boolean) => void
  ) => {
    setLoadingPurpose(true);
    try {
      const response = await purposeApi.getPurposesByDepartment(payload);

      if (response.ok && response.data.length) {
        const convertPurposes = response.data.map((val) => ({
          value: val.id,
          label: val.purposeCode + "_" + val.purposeName,
          children: val.purposeCode,
        }));
        setPurposes([OPTION_EMPTY, ...convertPurposes]);
        form.setFieldValue(NAME.PURPOSE, convertPurposes[0].value);
      } else {
        setPurposes([]);
        setLoadingPurpose(false);
      }
    } catch (error) {
      handleShowToastError(error);
    } finally {
      setLoadingPurpose(false);
    }
  };

  const getAllDepartments = async (
    payload: string,
    isModal: boolean,
    form: FormInstance,
    setDepartments: (val: TSelectValue[]) => void,
    setDepartmentsModal: (val: TSelectValue[]) => void,
    setLoadingDepartmentModal: (val: boolean) => void,
    setPurposes: (val: TSelectValue[]) => void,
    setLoadingPurpose: (val: boolean) => void
  ) => {
    setLoadingDepartmentModal(true);
    try {
      const response = await purposeApi.getAllDepartments(payload);

      if (response.ok && response.data.length) {
        const convertDepartments = response.data.map((val) => ({
          value: val.id,
          label: val.departmentCode + "_" + val.departmentName,
          children: val.departmentCode,
        }));
        if (isModal) {
          setDepartmentsModal(convertDepartments);
        } else {
          setDepartments([OPTION_EMPTY, ...convertDepartments]);
          await getPurposesByDepartment(
            convertDepartments[0].value,
            form,
            setPurposes,
            setLoadingPurpose
          );
        }
      } else {
        setDepartmentsModal([]);
        setDepartments([]);
        setLoadingDepartmentModal(false);
      }
    } catch (error) {
      handleShowToastError(error);
    } finally {
      setLoadingDepartmentModal(false);
    }
  };

  const registrationPurpose = async (
    params: TPurposeRegistrationReq,
    handleOkModalSuccess: (data: any) => void,
    setLoadingPage: (val: boolean) => void,
    handleModalFail: () => void,
    handleCommonError: () => void
  ) => {
    setLoadingPage(true);

    try {
      const response = await purposeApi.registrationPurpose(params);
      if (response.ok) {
        handleOkModalSuccess(response.data);
      }
    } catch (error: any) {
      handleCommonError();

      if (
        error?.status === EHttpStatusCode.BAD_REQUEST &&
        error?.data?.errors?.[0]?.description
      ) {
        handleModalFail();
        return;
      }
      const err = error as TAxiosResponse;
      if (err.status === EHttpStatusCode.FORBIDDEN) {
        appToast.error({ message: COMMON_MESSAGE_ERROR });
        return;
      }
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const updatePurpose = async (
    params: TPurposeUpdateReq,
    handleOkModalSuccess: (data: TPurposeRegistrationRes) => void,
    setLoadingPage: (val: boolean) => void,
    handleModalFail: () => void,
    handleCommonError: () => void
  ) => {
    setLoadingPage(true);

    try {
      const response = await purposeApi.updatePurpose(params);
      if (response.ok) {
        handleOkModalSuccess(response.data);
      }
    } catch (error: any) {
      handleCommonError();

      if (
        error?.status === EHttpStatusCode.BAD_REQUEST &&
        error?.data?.errors?.[0]?.description
      ) {
        handleModalFail();
        return;
      }
      const err = error as TAxiosResponse;
      if (err.status === EHttpStatusCode.FORBIDDEN) {
        appToast.error({ message: COMMON_MESSAGE_ERROR });
        return;
      }
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const detailPurpose = async (
    payload: string,
    companiesModal: TSelectValue[],
    setDepartmentsModal: (val: TSelectValue[]) => void,
    handleSetFormValues: (val: TPurposeRegistrationRes) => void,
    setLoadingModal: (val: boolean) => void
  ) => {
    setLoadingModal(true);
    try {
      const response = await purposeApi.getDetailPurpose(payload);

      if (response.ok) {
        const { data } = response;
        const findCompany = companiesModal.find(
          (item) => item.value === data.companyId
        );
        if (findCompany) {
          const resDepartment = await purposeApi.getAllDepartments(
            findCompany.value
          );
          if (resDepartment.ok && resDepartment.data.length) {
            const convertDepartment = resDepartment.data.map((val) => ({
              value: val.id,
              label: val.departmentCode + "_" + val.departmentName,
              children: val.departmentCode,
            }));
            setDepartmentsModal(convertDepartment);
          } else {
            setDepartmentsModal([]);
          }

          handleSetFormValues(data);
        } else {
          setDepartmentsModal([]);
        }
      }
    } catch (error) {
      setDepartmentsModal([]);
      handleShowToastError(error);
    } finally {
      setLoadingModal(false);
    }
  };

  const deletePurpose = async (
    payload: string,
    listPurpose: TListPurpose[],
    setListPurpose: (val: TListPurpose[]) => void,
    setLoadingPage: (val: boolean) => void,
    setModalSuccess: (val: boolean) => void,
    setModalType: (val: EModalType | null | string) => void,
    handleCloseModalDelete: () => void,
    handleDeleteFail: () => void
  ) => {
    setLoadingPage(true);

    try {
      const response = await purposeApi.deletePurpose(payload);
      if (response.ok) {
        setListPurpose(
          [
            ..._.filter(listPurpose, function (item) {
              return item.id !== payload;
            }),
          ].map((item, index) => ({ ...item, key: index + 1 }))
        );

        setModalSuccess(true);
        setModalType(EModalType.SUCCESS_DELETE);
        handleCloseModalDelete();
      }
    } catch (error: any) {
      if (
        error?.status === EHttpStatusCode.BAD_REQUEST &&
        error?.data?.errors?.[0]?.description === "Not found purpose"
      ) {
        handleDeleteFail();
        return;
      }
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const checkExistPurpose = async (
    payload: TCheckPurposeExistReq,
    formModal: FormInstance,
    setModalType: (val: EModalType | null | string) => void,
    setLoadingExist: (val: boolean) => void
  ) => {
    setLoadingExist(true);
    try {
      const response = await purposeApi.checkPurposeExist(payload);
      if (response.ok) {
        setModalType(EModalType.CONFIRM_REGISTRATION);
      }
    } catch (error: any) {
      if (
        error.status === EHttpStatusCode.BAD_REQUEST &&
        error?.data?.errors.length &&
        error?.data?.errors[0].description === "Purpose is already existed"
      ) {
        formModal.setFields([
          // {
          //   name: [NAME.COMPANY_ID_MODAL],
          //   errors: [MESSAGE.DATA_EXIST],
          // },
          // {
          //   name: [NAME.DEPARTMENT_ID_MODAL],
          //   errors: [MESSAGE.DATA_EXIST],
          // },
          {
            name: [NAME.PURPOSE_CODE_MODAL],
            errors: [MESSAGE.DATA_EXIST],
          },
        ]);
        return;
      }

      handleShowToastError(error);
    } finally {
      setLoadingExist(false);
    }
  };

  const getListPurposeSearch = async (
    payload: TListPurposeReq,
    setLoadingPage: (val: boolean) => void,
    setListPurpose: (val: TListPurpose[]) => void,
    handleSetSearchParams: (val: TFormPurposeReq) => void,
    setPageIndex: (val: number) => void,
    setHasNext: (val: boolean) => void
  ) => {
    setLoadingPage(true);

    try {
      const response = await purposeApi.getListPurpose(payload);
      if (response.ok) {
        setListPurpose(
          response.data.map((item, index) => ({
            ...item,
            key: index + 1,
          }))
        );
        handleSetSearchParams(payload);
        setPageIndex(1);
        setHasNext(response.pagination?.hasNext as boolean);
      }
    } catch (error) {
      setListPurpose([]);
      setPageIndex(0);
      setHasNext(false);
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const getListPurposeScroll = async (
    payload: TListPurposeReq,
    listPurpose: TListPurpose[],
    pageIndex: number,
    // loadedIds: Set<string>,
    setLoadingPage: (val: boolean) => void,
    setListPurpose: (val: any) => void,
    // setLoadedIds: (val: any) => void,
    setPageIndex: (val: number) => void,
    setHasNext: (val: boolean) => void
  ) => {
    setLoadingPage(true);
    try {
      const response = await purposeApi.getListPurpose(payload);
      if (response.ok) {
        setHasNext(response.pagination?.hasNext as boolean);

        if (listPurpose.length !== response.pagination?.totalCount) {
          const startIndex = listPurpose.length;
          setPageIndex(pageIndex + 1);
          // const filteredRecords = response.data
          //   .filter((record) => !loadedIds.has(record.id))
          //   .map((item, index) => ({
          //     ...item,
          //     key: startIndex + index + 1,
          //   }));
          // setListPurpose((prevData: TListPurpose[]) => [
          //   ...prevData,
          //   ...filteredRecords,
          // ]);
          // setLoadedIds(
          //   (prevIds: any) =>
          //     new Set([
          //       ...prevIds,
          //       ...filteredRecords.map((record) => record.id),
          //     ])
          // );
          setListPurpose([
            ...listPurpose,
            ...response.data.map((item, index) => ({
              ...item,
              key: startIndex + index + 1,
            })),
          ]);
        }
      }
    } catch (error) {
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const fetchInitialApis = async (
    pageIndex: number,
    setLoadingPage: (val: boolean) => void,
    setLoadingCompany: (val: boolean) => void,
    setCompanies: (val: TSelectValue[]) => void,
    setCompaniesModal: (val: TSelectValue[]) => void,
    setDepartments: (val: TSelectValue[]) => void,
    setDepartmentsModal: (val: TSelectValue[]) => void,
    setPurposes: (val: TSelectValue[]) => void,
    setListPurpose: (val: any[]) => void,
    setHasNext: (val: boolean) => void,
    setPageIndex: (val: number) => void,
    handleSetSearchParams: (values: any) => void
  ) => {
    setLoadingPage(true);
    let convertDepartments: TSelectValue[] = [];
    let convertPurposes: TSelectValue[] = [];

    try {
      const resCompany = await layoutApi.getCompanies();
      if (resCompany.ok) {
        const convertCompanies = resCompany.data.map((val) => ({
          value: val.id,
          label: val.companyCode + "_" + val.companyName,
          children: val.companyCode,
        }));
        setCompanies([OPTION_EMPTY, ...convertCompanies]);
        setCompaniesModal(convertCompanies);

        const resDepartment = await purposeApi.getAllDepartments(
          resCompany.data[0].id
        );
        if (resDepartment.ok && resDepartment.data.length) {
          convertDepartments = resDepartment.data.map((val) => ({
            value: val.id,
            label: val.departmentCode + "_" + val.departmentName,
            children: val.departmentCode,
          }));
          setDepartments([OPTION_EMPTY, ...convertDepartments]);
          setDepartmentsModal(convertDepartments);

          const resPurpose = await purposeApi.getPurposesByDepartment(
            resDepartment.data[0].id
          );
          if (resPurpose.ok && resPurpose.data.length) {
            convertPurposes = resPurpose.data.map((item) => ({
              value: item.id,
              label: item.purposeCode + "_" + item.purposeName,
            }));
            setPurposes([OPTION_EMPTY, ...convertPurposes]);
            handleSetSearchParams({
              companyId: resCompany.data[0].id,
              departmentId: resDepartment.data[0].id,
              purposeId: resPurpose.data[0].id,
            });
          }
        }

        const response = await purposeApi.getListPurpose({
          companyId: resCompany?.data?.[0]?.id || "",
          departmentId: resDepartment?.data?.[0]?.id || "",
          purposeId: convertPurposes?.[0]?.value || "",
          pageIndex,
        });
        if (response.ok) {
          const _listPurpose = response.data.map((item, index) => ({
            ...item,
            key: index + 1,
          }));
          setListPurpose(_listPurpose);
          setPageIndex(1);
          setHasNext(response.pagination?.hasNext as boolean);
        }
      }
    } catch (error) {
      setListPurpose([]);
      setPageIndex(0);
      setHasNext(false);

      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
      setLoadingCompany(false);
    }
  };

  const fetchApiRedirect = async (
    payload: TFormPurposeReq,
    pageIndex: number,
    setLoadingPage: (val: boolean) => void,
    setLoadingCompany: (val: boolean) => void,
    setCompanies: (val: TSelectValue[]) => void,
    setCompaniesModal: (val: TSelectValue[]) => void,
    setDepartments: (val: TSelectValue[]) => void,
    setDepartmentsModal: (val: TSelectValue[]) => void,
    setPurposes: (val: TSelectValue[]) => void,
    setListPurpose: (val: any[]) => void,
    setHasNext: (val: boolean) => void,
    setPageIndex: (val: number) => void,
    handleSetSearchParams: (values: any) => void
  ) => {
    setLoadingPage(true);

    try {
      let convertDepartments: TSelectValue[] = [];
      let convertPurposes: TSelectValue[] = [];

      const resCompany = await layoutApi.getCompanies();
      if (resCompany.ok) {
        const convertCompanies = resCompany.data.map((val) => ({
          value: val.id,
          label: val.companyCode + "_" + val.companyName,
          children: val.companyCode,
        }));
        setCompanies([OPTION_EMPTY, ...convertCompanies]);
        setCompaniesModal(convertCompanies);

        const findCompany = resCompany.data.find(function (item) {
          return item.id === payload.companyId;
        });

        const _companyId = findCompany
          ? findCompany.id
          : convertCompanies[0].value;
        const resDepartment = await purposeApi.getAllDepartments(_companyId);
        if (resDepartment.ok && resDepartment.data.length) {
          convertDepartments = resDepartment.data.map((val) => ({
            value: val.id,
            label: val.departmentCode + "_" + val.departmentName,
            children: val.departmentCode,
          }));
          setDepartments([OPTION_EMPTY, ...convertDepartments]);
          setDepartmentsModal(convertDepartments);

          const findDepartment = resDepartment.data.find(function (item) {
            return item.id === payload.departmentId;
          });
          const _departmentId = findDepartment
            ? findDepartment.id
            : convertDepartments[0].value;
          const resPurpose = await purposeApi.getPurposesByDepartment(
            _departmentId
          );
          if (resPurpose.ok && resPurpose.data.length) {
            convertPurposes = resPurpose.data.map((item) => ({
              value: item.id,
              label: item.purposeCode + "_" + item.purposeName,
            }));
            setPurposes([OPTION_EMPTY, ...convertPurposes]);
            handleSetSearchParams({
              companyId: payload.companyId,
              departmentId: payload.departmentId,
              purposeId: payload.purposeId,
            });
          }
        }

        const response = await purposeApi.getListPurpose({
          companyId: resCompany?.data?.[0]?.id || "",
          departmentId: resDepartment?.data?.[0]?.id || "",
          purposeId: convertPurposes?.[0]?.value || "",
          pageIndex,
        });
        if (response.ok) {
          const _listPurpose = response.data.map((item, index) => ({
            ...item,
            key: index + 1,
          }));
          setListPurpose(_listPurpose);
          setPageIndex(1);
          setHasNext(response.pagination?.hasNext as boolean);
        }
      }
    } catch (error) {
      setListPurpose([]);
      setPageIndex(0);
      setHasNext(false);

      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
      setLoadingCompany(false);
    }
  };

  // ***** EFFECT *****
  const effectCompany = (
    companyParam: string,
    form: FormInstance,
    companies: TSelectValue[]
  ) => {
    if (companyParam) {
      form.setFieldValue(NAME.COMPANY, companyParam);
    } else {
      if (companies.length) {
        form.setFieldValue(NAME.COMPANY, companies[1].value);
      } else {
        form.setFieldValue(NAME.COMPANY, "");
      }
    }
  };

  const effectPurpose = (
    purposeParam: string,
    form: FormInstance,
    purposes: TSelectValue[]
  ) => {
    if (purposeParam) {
      form.setFieldValue(NAME.PURPOSE, purposeParam);
    } else {
      if (purposes.length > 1) {
        form.setFieldValue(NAME.PURPOSE, purposes[1].value);
      } else {
        form.setFieldValue(NAME.PURPOSE, undefined);
      }
    }
  };

  const effectDepartment = (
    departmentParam: string,
    form: FormInstance,
    departments: TSelectValue[]
  ) => {
    if (departmentParam) {
      form.setFieldValue(NAME.DEPARTMENT, departmentParam);
    } else {
      if (departments.length > 1) {
        form.setFieldValue(NAME.DEPARTMENT, departments[1].value);
      } else {
        form.setFieldValue(NAME.DEPARTMENT, undefined);
      }
    }
  };

  return {
    handler: {
      getAllDepartments,
      getPurposesByDepartment,
      fetchInitialApis,
      fetchApiRedirect,
      getListPurposeSearch,
      getListPurposeScroll,

      registrationPurpose,
      detailPurpose,
      updatePurpose,
      deletePurpose,
      checkExistPurpose,
    },
    effect: {
      effectCompany,
      effectPurpose,
      effectDepartment,
    },
  };
}

export default usePurposeApi;
