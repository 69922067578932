import { DATA_EMPTY } from "@constants/common";
import { TConvertSensorReportsResult, TSensorReports } from "@models/device";
import { guidGenerator } from "@utils/helpers";
import { TEMPERATURE_CONSTANT } from "../constant";
import { ETemperatureType } from "types/others";

const { TITLE } = TEMPERATURE_CONSTANT;

const renderTitleButtonConfirm = (disableItem: boolean) => {
  return disableItem ? TITLE.CONFIRMED_COMMENT : TITLE.BTN_ADMIT;
};

const handleDisableSelect = (isStore: boolean, loading: boolean) => {
  return isStore || loading;
};

const handleConvertSensorData = (
  data: TSensorReports[]
): TConvertSensorReportsResult => {
  const convertData = data.reduce((result: any, item) => {
    const { tempValue, ...other } = item;
    let keys: any = [];
    const keyLength = Object.keys(tempValue).length;
    if (!keyLength) {
      keys = [];
    } else {
      keys = Object.keys(tempValue).map((val) => {
        const newObj = [
          val,
          tempValue[val].temperature === TITLE.NONE ||
          tempValue[val].temperature === TITLE.N_A
            ? DATA_EMPTY
            : tempValue[val].temperature,
          tempValue[val].isDefrostingTime,
          tempValue[val].threshold,
          tempValue[val].isActive,
          tempValue[val].isNoCooling,
        ];

        return newObj;
      });
    }

    if (!keys.length) {
      return [...result];
    }

    const x1 = keys.slice(0, 12);
    const x2 = keys.slice(12);

    const group1 = x1.reduce(
      (a: TSensorReports, v: (string | number | boolean)[], index: number) => ({
        ...a,
        ...other,
        [`hour${index + 1}`]: v,
        key: guidGenerator(),
      }),
      {}
    );
    const group2 = x2.reduce(
      (b: TSensorReports, v: (string | number | boolean)[], index: number) => ({
        ...b,
        ...other,
        [`hour${index + 1}`]: v,
        key: guidGenerator(),
      }),
      {}
    );
    const newArr = [group1, group2];

    return [...result, ...newArr];
  }, []);

  return convertData;
};

const handleCheckColor = (threshold: number) => {
  if (threshold === -1) {
    return "var(--color-sidebar-active)";
  } else if (threshold === 1) {
    return "#FF5555";
  } else {
    return "var(--color-text-primary)";
  }
};

const handleBackgroundColor = (threshold: number) => {
  if (threshold === -1) {
    return "temperature-report_lower-off";
  } else if (threshold === 1) {
    return "temperature-report_upper-off";
  } else {
    return "temperature-report_normal";
  }
};

const handleClassNameCell = (
  value: string,
  isDefrost: boolean,
  threshold: number,
  changeSwitch: boolean,
  isActive: boolean,
  isCooling: boolean
) => {
  if (value === DATA_EMPTY) {
    return {
      style: { color: "var(--color-text-primary)" },
    };
  } else {
    if (isCooling || isDefrost || isActive === false) {
      return {
        style: { color: "#667085" },
      };
    } else {
      if (threshold === -1 && !changeSwitch) {
        return {
          className: "temperature-report_lower-off",
        };
      } else if (threshold === -1 && changeSwitch) {
        return {
          className: "temperature-report_lower-on",
        };
      } else if (threshold === 1 && !changeSwitch) {
        return {
          className: "temperature-report_upper-off",
        };
      } else if (threshold === 1 && changeSwitch) {
        return {
          className: "temperature-report_upper-on",
        };
      } else {
        return {
          className: undefined,
        };
      }
    }
  }
};

const sharedOnCell = (_: any, index?: number) => {
  if (index && index % 2 !== 0) {
    return { rowSpan: 0 };
  }
  return { rowSpan: 2 };
};

const renderTemperatureType = (value: string) => {
  switch (value) {
    case ETemperatureType.REFRIGERATED:
      return TITLE.REFRIGERATE;
    case ETemperatureType.FROZEN:
      return TITLE.FREEZE;
    case ETemperatureType.UNREFRIGERATED:
      return TITLE.NOT_REFRIGERATE;
    default:
      return TITLE.NOT_USE;
  }
};

export {
  renderTitleButtonConfirm,
  handleDisableSelect,
  handleConvertSensorData,
  handleCheckColor,
  handleClassNameCell,
  sharedOnCell,
  renderTemperatureType,
  handleBackgroundColor,
};
