import { TSelectValue } from "@models/common";
import { TConvertSensorReportsResult } from "@models/device";
import { TStoreRes } from "@models/layout";
import { dailyReportApi } from "@services/dailyReportApi";
import { layoutApi } from "@services/layoutApi";
import { openCaseApi } from "@services/openCaseApi";
import { handleShowToastError } from "@utils/helpers";
import { FormInstance } from "antd/lib";
import dayjs from "dayjs";
import _ from "lodash";
import { OPTION_ALL_PURPOSE, TEMPERATURE_CONSTANT } from "../constant";
import { handleConvertSensorData } from "../utils/helpers";
import useTemperatureReportApi from "./useTemperatureReportApi";

const { NAME, TITLE } = TEMPERATURE_CONSTANT;

function useTemperatureReportRouter() {
  const { handler } = useTemperatureReportApi();

  const fetchInitialApisRedirect = async (
    companyCode: string,
    storeCode: string,
    reportDate: string,
    purposeCode: string,
    form: FormInstance,
    setLoadingPage: (val: boolean) => void,
    setLoadingCompany: (val: boolean) => void,
    setCompanies: (val: TSelectValue[]) => void,
    setSelectedStores: (val: TSelectValue[]) => void,
    setPurposes: (val: TSelectValue[]) => void,
    setStores: (val: TStoreRes[]) => void,
    setSensorReports: (val: TConvertSensorReportsResult) => void,
    setDisableComment: (val: boolean) => void
  ) => {
    try {
      const resCompany = await layoutApi.getCompanies();

      if (resCompany.ok) {
        const convertCompanies = resCompany.data.map((val) => ({
          value: val.companyCode,
          label: val.companyName,
        }));
        setCompanies(convertCompanies);
        form.setFieldValue(NAME.COMPANY, companyCode);
        const findCompany = _.find(convertCompanies, function (val) {
          return val.value === companyCode;
        });

        const [resStore, resPurpose] = await Promise.all([
          layoutApi.getStoresByCompany({
            companyCode: companyCode,
            companyName: findCompany?.label as string,
          }),
          openCaseApi.getAllPurposes({
            companyCode: companyCode,
            companyName: findCompany?.label as string,
          }),
        ]);

        if (resStore.ok && resStore.data.length) {
          const convertStores = resStore.data.map((val) => ({
            value: val.storeCode,
            label: val.storeCode + "_" + val.storeName,
          }));
          setSelectedStores(convertStores);
          setStores(resStore.data);
          form.setFieldValue(NAME.STORE, storeCode);
        }

        if (resPurpose.ok && resPurpose.data.length) {
          const convertPurposes = resPurpose.data.map((val) => ({
            value: val.purposeCode,
            label: val.purposeName,
          }));
          setPurposes([OPTION_ALL_PURPOSE, ...convertPurposes]);
        } else {
          setPurposes([OPTION_ALL_PURPOSE]);
        }

        const response = await dailyReportApi.getDailyReport({
          companyCode: companyCode,
          storeCode: storeCode,
          purposeCode: purposeCode,
          reportDate: reportDate,
        });

        if (response.ok) {
          const { sensorDailyReports, storeReport } = response.data;
          handler.handleStoreReport(form, storeReport, setDisableComment);
          setSensorReports(handleConvertSensorData(sensorDailyReports));
        }
      }
    } catch (error) {
      setSensorReports([]);
      setDisableComment?.(false);
      form.setFieldValue(NAME.MANAGER, TITLE.UNCONFIRMED);
      handleShowToastError(error);

      // TODO: handle error
    } finally {
      setLoadingPage(false);
      setLoadingCompany(false);
    }
  };

  const fetchInitialApis = (
    {
      form,
      setStoreCodeRedirect,
      setLoadingPage,
      setLoadingCompany,
      setCompanies,
      setSelectedStores,
      setPurposes,
      setStores,
      setSensorReports,
      setDisableComment,
    }: {
      form: FormInstance;
      setStoreCodeRedirect: (val: string) => void;
      setLoadingPage: (val: boolean) => void;
      setLoadingCompany: (val: boolean) => void;
      setCompanies: (val: TSelectValue[]) => void;
      setSelectedStores: (val: TSelectValue[]) => void;
      setPurposes: (val: TSelectValue[]) => void;
      setStores: (val: TStoreRes[]) => void;
      setSensorReports: (val: TConvertSensorReportsResult) => void;
      setDisableComment: (val: boolean) => void;
    },
    payload: any,
    searchParams: URLSearchParams
  ) => {
    const companyCode = searchParams.get("companyCode") || "";
    const storeCode = searchParams.get("storeCode") || "";
    const reportDate = searchParams.get("reportDate") || "";
    const purposeCode = searchParams.get("purposeCode") || "";

    if (companyCode && storeCode && reportDate) {
      form.setFieldValue(NAME.DATE, dayjs(reportDate));
      setStoreCodeRedirect(storeCode);
      fetchInitialApisRedirect(
        companyCode,
        storeCode,
        reportDate,
        purposeCode,
        form,
        setLoadingPage,
        setLoadingCompany,
        setCompanies,
        setSelectedStores,
        setPurposes,
        setStores,
        setSensorReports,
        setDisableComment
      );
    } else {
      payload();
    }
  };

  return {
    handler: {
      fetchInitialApis,
    },
  };
}

export default useTemperatureReportRouter;
