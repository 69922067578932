import {
  COMMON_ERR_TIMEOUT,
  REQUEST_TIME_OUT,
  TOKEN_COOKIE,
} from "@constants/common";
import { removeAllCookies } from "@utils/helpers";
import axios from "axios";
import queryString from "query-string";
import { publicPaths } from "routers/routes";
import { EHttpStatusCode } from "types/service";
import Cookies from "universal-cookie";
import { authApi } from "./authApi";

type TRequestFailed = {
  resolve: (token: string) => void;
  reject: (error: Error) => void;
};

const cookies = new Cookies();
const loginUlr = "/auth/login";
const refreshTokenUlr = "/auth/refresh-token";
let isRefreshing = false;
let failedQueue: TRequestFailed[] = [];

const requestUrl = () => {
  switch (process.env.REACT_APP_BUILD_ENV) {
    case "local":
      return process.env.REACT_APP_BACKEND_API_LOCAL;
    case "dev":
      return process.env.REACT_APP_BACKEND_API_DEV;
    case "staging":
      return process.env.REACT_APP_BACKEND_API_STAGING;
    case "prod":
      return process.env.REACT_APP_BACKEND_API_PROD;
    default:
      return "";
  }
};

const requestBaseURL = requestUrl();

const recallFailedRequests = (error: any, token: string) => {
  failedQueue.forEach((promise) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(token);
    }
  });

  failedQueue = [];
};

export const axiosInstance = axios.create({
  baseURL: requestBaseURL,
  timeout: REQUEST_TIME_OUT,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Expose-Headers": "x-pagination, Access-Token, Uid",
  },
  paramsSerializer: (params) => {
    return queryString.stringify(params, {
      skipEmptyString: true,
    });
  },
});

const handleStatusUnauthorized = async (originalRequest: any) => {
  if (isRefreshing) {
    return new Promise(function (resolve, reject) {
      failedQueue.push({ resolve, reject });
    })
      .then((accessToken) => {
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        return axiosInstance(originalRequest);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
};

axiosInstance.interceptors.request.use(
  function (config: any) {
    // Do something before request is sent
    try {
      const accessToken = cookies.get(TOKEN_COOKIE);

      if (config.headers === undefined) {
        config.headers = {};
      }

      if (config.url !== loginUlr) {
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
        if (config.url === refreshTokenUlr) {
          config.headers.Authorization = "";
        }
      }
    } catch (err) {
      console.log("~ axiosClient.interceptors.request.use ~ err", err);
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    if (error.message === `timeout of ${REQUEST_TIME_OUT}ms exceeded`) {
      return Promise.reject(COMMON_ERR_TIMEOUT);
    }

    if (error.code === "ERR_NETWORK") {
      return Promise.reject(error.code);
    }

    const { status } = error.response;
    const originalRequest = error.config;
    if (originalRequest.url === refreshTokenUlr) {
      return Promise.reject(error.response);
    }

    switch (status) {
      case EHttpStatusCode.BAD_REQUEST: // TODO: Define what to do on 400
        break;

      case EHttpStatusCode.FORBIDDEN: // TODO: Define what to do on 403
        break;
      case EHttpStatusCode.UNAUTHORIZED: // TODO: Define what to do on 401
        try {
          if (!originalRequest._retry && originalRequest.url !== loginUlr) {
            handleStatusUnauthorized(originalRequest);

            isRefreshing = true;
            originalRequest._retry = true;
            const { data } = await authApi.refreshToken();
            const { accessToken } = data;

            cookies.set(TOKEN_COOKIE, accessToken, {
              path: "/",
            });
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            // recall remain requests
            isRefreshing = false;
            recallFailedRequests(null, accessToken);
            return axiosInstance(originalRequest);
          } else {
            removeAllCookies();
          }
        } catch (err) {
          // reject remain request
          recallFailedRequests(err, "");
          removeAllCookies();
          window.location.replace(publicPaths.login);
        }
        break;
      case EHttpStatusCode.REQUEST_TIMEOUT: // TODO: Define what to do on 408
        break;
      case EHttpStatusCode.INTERNAL_SERVER_ERROR:
        break;
      default:
        break;
    }

    return Promise.reject(error.response);
  }
);
