export const STATISTICS_CONSTANT = {
  NAME: {
    COMPANY: "companyCode",
    DATE: "reportDate",
    START_DATE: "dateStart",
    END_DATE: "dateEnd",
  },
  LABEL: {
    COMPANY: "会社",
    DATE: "日付",
  },
  TITLE: {
    SPREADSHEET: "集計表",
    BTN_EXPORT: "エクスポート",
    BTN_ADMIT: "承認 ",

    // Columns
    STORE_NUMBER: "店番",
    DISTRICT: "地区",
    STORE_NAME: "店名",
    CH: "CH",
    SALE_NAME: "売場名",
    DEPARTMENT_NAME: "部門名",
    TEMPERATURE_DISTINCTION: "温度区分",
    INSTALL_LOCATION: "設置場所",
    CONTROLLED_TEMPERATURE: "管理温度帯",
    LOWER_LIMIT: "下限",
    UPPER_LIMIT: "上限",

    BLUE_NUMBER: "青の数",
    RED_NUMBER: "赤の数",
    TEN_CLOCK: "10時",
    FIFTEEN_CLOCK: "15時",
    FULL_NAME: "氏名",
    DISTRICT_HEAD_APPROVE: "地区長承認",
    COMMENT: "コメント",

    // Modal
    CONFIRM: "確認",
    BTN_APPROVE: "承認する",
    BTN_NOT_APPROVE: "承認しない",

    TITLE_DOWNLOAD: "CSVダウンロード",
    START_DATE: "開始日",
    END_DATE: "終了日",
    BTN_OK: "OK",
    BTN_CANCEL: "キャンセル",
  },
  TABLE_KEY: {
    THREE_DOT: "threeDot",
    STORE_NUMBER: "storeCode",
    DISTRICT: "areaCode",
    STORE_NAME: "storeName",

    CH: "chCode",
    SALE_NAME: "purposeName",
    DEPARTMENT_NAME: "departmentName",
    TEMPERATURE_DISTINCTION: "temperatureType",
    INSTALL_LOCATION: "installedLocation",
    LOWER_LIMIT: "minTemp",
    UPPER_LIMIT: "maxTemp",

    BLUE_NUMBER: "blueNumber",
    RED_NUMBER: "redNumber",
    TEN_CLOCK: "tenClock",
    FIFTEEN_CLOCK: "fifteenClock",
    FULL_NAME: "storeManagerName",
    DISTRICT_HEAD_APPROVE: "districtHeadApprove",
    COMMENT: "comments",

    BLUE_NUMBER_1: "blueNumber1",
    RED_NUMBER_1: "redNumber1",
    TEN_CLOCK_1: "tenClock1",
    FIFTEEN_CLOCK_1: "fifteenClock1",
  },
  MESSAGE: {
    APPROVE_SCHEDULE: "集計表を承認します。よろしいですか？",
    NO_DATA: "検索条件と一致する結果が見つかりません。",
    SPECIFY_DATE: "日付の範囲を指定してください",
    SPECIFY_PERIOD: "1週間以内の期間を指定してください。",
  },
};
