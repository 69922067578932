import {
  COMMON_ERR_NETWORK,
  COMMON_ERR_TIMEOUT,
  COMMON_MESSAGE_ERROR,
  COMMON_MESSAGE_TIMEOUT,
} from "@constants/common";
import { EUserAccessRoles, EUserRoles } from "@models/user";
import { DatePicker, type GetProps } from "antd";
import { SortOrder } from "antd/lib/table/interface";
import dayjs from "dayjs";
import { EHttpStatusCode, EOrder } from "types/service";
import Cookies from "universal-cookie";
import { appToast } from "./appToast";

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

const cookies = new Cookies();

// Disable date calendar
export const handleDisabledDate: RangePickerProps["disabledDate"] = (
  current
) => {
  // Get yesterday
  const yesterday = dayjs().subtract(1, "day").startOf("day");

  // Get the date one year ago from yesterday
  const oneYearAgo = yesterday.subtract(1, "year");

  // Can not select days after today and today
  // Can not select over 1 year from previous day
  return (
    current &&
    (current >= dayjs().startOf("day") ||
      current > yesterday ||
      current < oneYearAgo)
  );
};

/**
 * Remove all saved cookies
 */
export const removeAllCookies = () => {
  const allCookies = Object.getOwnPropertyNames(cookies.getAll());
  for (let i = 0; i < allCookies.length; i++) {
    cookies.remove(allCookies[i], { path: "/" });
  }
};

export const getAccessRoleFromUserRole = (userRole?: EUserRoles[]) => {
  // Admin
  if (userRole?.[0] === EUserRoles.ADMIN) {
    return EUserAccessRoles.ADMIN;
  }
  // Mentex
  if (userRole?.[0] === EUserRoles.MENTEX) {
    return EUserAccessRoles.MENTEX;
  }
  // Store
  if (userRole?.[0] === EUserRoles.STORE) {
    return EUserAccessRoles.STORE;
  }
  // Others
  return;
};

// Convert SortOrder of antd to map with api
export const getSortDirection = (tableSortOrder?: SortOrder) => {
  switch (tableSortOrder) {
    case "ascend": {
      return EOrder.ASC;
    }
    case "descend": {
      return EOrder.DESC;
    }
    default:
      return null;
  }
};

// Generate file name when export list shop/supplier/maker
export const genFileNameForExport = ({
  prefixName,
  companyName,
  storeName,
  from,
  to,
}: {
  prefixName: string;
  companyName: string;
  storeName: string;
  from: string;
  to: string;
}) => {
  if (to) {
    return `${prefixName}_${companyName}会社_${storeName}_${from}_${to}`;
  }
  return `${prefixName}_${companyName}会社_${from}`;
};

// Error disconnect internet or server error
export const handleShowToastError = (error: any) => {
  if (
    error === COMMON_ERR_NETWORK ||
    error.status === EHttpStatusCode.INTERNAL_SERVER_ERROR
  ) {
    appToast.error({ message: COMMON_MESSAGE_ERROR });
    return;
  }

  if (error === COMMON_ERR_TIMEOUT) {
    appToast.error({ message: COMMON_MESSAGE_TIMEOUT });
    return;
  }
};

// Handle generate random ID
export const guidGenerator = () => {
  const S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
};
