import { Modal } from "antd";
import styled from "styled-components";

export const FileManageWrapper = styled.div`
  .file-manage_header {
    margin-bottom: 24px;

    h1 {
      line-height: 40px;
    }
  }

  .file-manage_tab {
    .ant-tabs-nav::before {
      border-bottom: 1px solid var(--color-secondary);
      width: calc(100% - 5px);
    }

    .ant-tabs-nav {
      margin-bottom: 0;
    }

    .ant-tabs-nav-list {
      .ant-tabs-tab:first-child,
      .ant-tabs-tab:nth-child(2) {
        width: 120px;
      }

      .ant-tabs-tab:last-child {
        width: 152px;
      }

      .ant-tabs-tab {
        font-weight: var(--font-medium);
        border: 1px solid var(--color-secondary);
        padding: 8px 12px;
        background-color: var(--background-header-table);
      }

      .ant-tabs-tab-active {
        color: var(--color-sidebar-active);
        border-bottom-color: var(--color-white) !important;
        background-color: var(--color-white);
      }
    }

    .ant-tabs-content-holder {
      height: 860px;
      padding: 22px 20px;
      border-left: 1px solid var(--color-secondary);
      border-right: 1px solid var(--color-secondary);
      border-bottom: 1px solid var(--color-secondary);
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
      background-color: #f9fafb;

      .ant-tabs-content,
      .ant-tabs-tabpane {
        height: 100%;
      }
    }
  }
`;

export const ManagementTabWrapper = styled.div<{
  listdata: number;
}>`
  .management-tab_input {
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-bottom: 15px;

    .ant-btn-default {
      width: 56px;
      height: 56px;
      background: #d7e7ff !important;
      border: none;
    }

    .ant-input {
      width: 400px;
      height: 56px;
      padding: 18px 16px;
    }

    .ant-input-lg {
      font-size: var(--font-size-xl);
      font-weight: var(--font-medium);
      line-height: 24px;
    }

    .ant-input::placeholder {
      font-size: var(--font-size-sm);
      font-weight: var(--font-medium);
    }
  }

  .management-tab_table {
    .ant-table-container {
      border-start-end-radius: 0px;
      border-top: none !important;
    }

    .ant-table-tbody-virtual-scrollbar-vertical {
      background: var(--color-secondary);
      border-radius: 4px;
    }

    .ant-table-tbody-virtual-scrollbar {
      visibility: visible !important;
      right: -8px !important;

      .ant-table-tbody-virtual-scrollbar-thumb {
        background: var(--color-text-secondary) !important;
      }
    }

    .ant-table-thead {
      tr:first-child .ant-table-cell:last-child {
        border-start-end-radius: 8px;
      }

      tr:first-child .ant-table-cell {
        border-top: 1px solid var(--color-border-primary);
        border-bottom: 4px solid var(--color-border-primary) !important;
        padding: 44px 12px;
      }
    }

    .ant-table-tbody {
      height: ${(props) => (!props.listdata ? "628px" : "unset")};

      .ant-table-tbody-virtual-holder {
        max-height: 630px !important;
      }

      .ant-table-placeholder {
        .ant-table-cell {
          border-end-start-radius: 8px;
          border-end-end-radius: 8px;

          .ant-empty-image {
            height: 160px !important;
          }

          .ant-empty-description {
            font-weight: var(--font-normal) !important;
          }
        }
      }

      .ant-table-cell {
        font-weight: var(--font-medium);
        padding: 16px 6px;

        .management-tab_ellipsis {
          display: -webkit-box;
          -webkit-line-clamp: 1; /* Number of lines */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
        }

        .management-tab_status {
          display: inline-block;
          line-height: 24px;
          font-size: var(--font-size-sm);
          border-radius: 16px;
          width: 100px;
        }

        .management-tab_status-pending,
        .management-tab_status-expired, // TODO: not know color
        .management-tab_status-success {
          color: #12b76a;
          background-color: var(--color-notification-success);
        }

        .management-tab_status-error {
          color: #d92d20;
          background-color: var(--color-notification-error);
        }

        .management-tab_modal-btns {
          display: flex;
          justify-content: center;
          column-gap: 26px;

          .ant-btn-default {
            padding: 0;
            height: unset;
            border-color: transparent;
            box-shadow: none;
            background: none;
          }

          .ant-btn-default:disabled {
            border-color: transparent;
            background: transparent;
          }
        }
      }
    }
  }
`;

export const ModalViewWrapper = styled(Modal)`
  .ant-modal-content {
    padding: 0;
    background: var(--color-white);
    box-shadow: 0px 2px 8px 0px rgba(5, 40, 97, 0.08);
    border-radius: 12px;
  }

  .ant-modal-header {
    margin-bottom: 0;
    padding: 16px 24px;
    background: none;

    .ant-modal-title {
      font-weight: var(--font-bold);
      font-size: var(--font-size-base);
      line-height: 24px;
      color: var(--color-header-table);
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: center;
    padding: 12px 24px 24px;
    margin-top: 0;

    .ant-btn {
      width: 202px;
      height: 48px;
      box-shadow: none;
      font-weight: var(--font-bold);
      margin-inline-start: 0;
    }

    .ant-btn-default {
      display: none;
    }
  }

  .ant-modal-body {
    padding: 0 24px;
    border-bottom: 1px solid #f1f3f7;
    border-top: 1px solid #f1f3f7;
    text-align: center;

    .management-tab_modal-view {
      margin: 16px 0;
      width: 360px;
      margin-left: auto;
      margin-right: auto;

      text-align: left;
      color: var(--color-text-secondary);
      font-size: var(--font-size-sm);
      font-weight: var(--font-medium);
      line-height: 20px;
    }

    .management-tab_modal-view p::before {
      content: "";
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: var(--color-text-secondary);
      display: inline-block;
      margin: 2px 12px;
    }
  }
`;

export const ModalDownloadWrapper = styled(Modal)`
  .ant-modal-content {
    padding: 0;
    background: var(--color-white);
    box-shadow: 0px 2px 8px 0px rgba(5, 40, 97, 0.08);
    border-radius: 12px;
  }

  .ant-modal-header {
    margin-bottom: 0;
    padding: 16px 24px;
    background: none;

    .ant-modal-title {
      font-weight: var(--font-bold);
      font-size: var(--font-size-base);
      line-height: 24px;
      color: var(--color-header-table);
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    column-gap: 16px;
    padding: 12px 24px 24px;
    margin-top: 0;

    .ant-btn {
      width: 202px;
      height: 48px;
      box-shadow: none;
      font-weight: var(--font-bold);
      margin-inline-start: 0;
    }

    .ant-btn-default {
      color: var(--color-primary);
      border-color: var(--color-primary);
    }
  }

  .ant-modal-body {
    padding: 0 24px;
    border-bottom: 1px solid #f1f3f7;
    border-top: 1px solid #f1f3f7;
    text-align: center;

    .management-tab_modal-download {
      margin: 30px 0 24px;

      p:first-child {
        color: var(--color-text-secondary);
        font-weight: var(--font-normal);
        margin-bottom: 16px;
      }

      p:last-child {
        font-weight: var(--font-medium);
        font-size: var(--font-size-sm);
        line-height: 20px;
      }
    }
  }
`;
