import {
  TCompanyReq,
  TCompanyRes,
  TGetImageReq,
  TStoreRes,
} from "@models/layout";
import { TAxiosResponse } from "types";
import { axiosInstance } from "./axiosInstance";

const layoutApi = {
  getCompanies: async () => {
    const url = "/company/companies";
    const response: TAxiosResponse<TCompanyRes[]> = await axiosInstance.get(
      url
    );

    return response;
  },

  getStoresByCompany: async (payload: TCompanyReq) => {
    const _payload: TCompanyReq = {
      ...payload,
      companyCode: payload.companyCode || "",
      companyName: payload.companyName || "",
    };
    const url = "/store/stores";
    const response: TAxiosResponse<TStoreRes[]> = await axiosInstance.post(
      url,
      _payload
    );

    return response;
  },

  getLayoutImage: async (payload: TGetImageReq) => {
    const url = `Store/map?CompanyCode=${payload.companyCode}&StoreCode=${payload.storeCode}`;
    const response: TAxiosResponse<TLayoutImageRes> = await axiosInstance.get(
      url
    );

    return response;
  },
};

export { layoutApi };

type TLayoutImageRes = {
  mapName: string;
  binaryData: string;
  contentType: string;
};
