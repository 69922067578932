import {
  DeleteOutlined,
  EditOutlined,
  FolderViewOutlined,
} from "@ant-design/icons";
import { COLOR_TOOLTIP, DATA_EMPTY } from "@constants/common";
import { TListUserTable } from "@models/user";
import { Button, Tooltip } from "antd";
import { TableProps } from "antd/lib";
import { USER_CONSTANT } from "./constant";

const { TITLE, TABLE_KEY } = USER_CONSTANT;

export const getColumns = ({
  disableBtnOther,
  handleOpenModalDelete,
  handleOpenModalDetail,
  handleOpenModalUpdate,
}: {
  disableBtnOther: boolean;
  handleOpenModalDelete: (record: TListUserTable) => void;
  handleOpenModalDetail: (record: TListUserTable) => void;
  handleOpenModalUpdate: (record: TListUserTable, id: string) => void;
}): TableProps<TListUserTable>["columns"] => {
  return [
    {
      title: TITLE.NO,
      key: TABLE_KEY.NO,
      dataIndex: TABLE_KEY.NO,
      align: "center",
      width: 120,
    },
    {
      title: TITLE.ID,
      key: TABLE_KEY.ID,
      dataIndex: TABLE_KEY.ID,
      align: "center",
      width: 120,
      render: (_value, record) => {
        if (record) {
          return (
            <div className="list-page_ellipsis">
              <Tooltip
                color="white"
                overlayClassName="common_custom-tooltip"
                title={record.userName}
                overlayInnerStyle={{
                  color: COLOR_TOOLTIP,
                }}
              >
                {record.userName}
              </Tooltip>
            </div>
          );
        }
        return null;
      },
    },
    {
      title: TITLE.USERNAME,
      key: TABLE_KEY.USERNAME,
      dataIndex: TABLE_KEY.USERNAME,
      align: "center",
      width: 120,
      render: (_value, record) => {
        if (record) {
          return (
            <div className="list-page_ellipsis">
              <Tooltip
                color="white"
                overlayClassName="common_custom-tooltip"
                title={record.name}
                overlayInnerStyle={{
                  color: COLOR_TOOLTIP,
                }}
              >
                {record.name}
              </Tooltip>
            </div>
          );
        }
        return null;
      },
    },
    {
      title: TITLE.AUTHORITY,
      key: TABLE_KEY.ROLE_INFO,
      dataIndex: TABLE_KEY.ROLE_INFO,
      align: "center",
      width: 120,
      render: (_value, record) => {
        if (record) {
          const recordRender =
            record.role === "STORE"
              ? `1_店長`
              : record.role === "MENTEX"
              ? `2_Mentex`
              : record.role === "ADMIN"
              ? `3_Admin`
              : "";
          return (
            <div className="list-page_ellipsis">
              <Tooltip
                color="white"
                overlayClassName="common_custom-tooltip"
                title={recordRender}
                overlayInnerStyle={{
                  color: COLOR_TOOLTIP,
                }}
              >
                {recordRender}
              </Tooltip>
            </div>
          );
        }
        return null;
      },
    },
    {
      title: TITLE.COMPANY,
      key: TABLE_KEY.COMPANY_INFO,
      dataIndex: TABLE_KEY.COMPANY_INFO,
      align: "center",
      width: 120,
      render: (value, record) => {
        if (record) {
          const _companyInfo = value
            ? record.companyCode + "_" + record.companyName
            : DATA_EMPTY;

          return _companyInfo ? (
            <div className="list-page_ellipsis">
              <Tooltip
                color="white"
                overlayClassName="common_custom-tooltip"
                title={record.companyCode + "_" + record.companyName}
                overlayInnerStyle={{
                  color: COLOR_TOOLTIP,
                }}
              >
                {value
                  ? record.companyCode + "_" + record.companyName
                  : DATA_EMPTY}
              </Tooltip>
            </div>
          ) : (
            DATA_EMPTY
          );
        }
        return null;
      },
    },
    {
      title: TITLE.STORE,
      key: TABLE_KEY.STORE_INFO,
      dataIndex: TABLE_KEY.STORE_INFO,
      align: "center",
      width: 120,
      render: (value, record) => {
        if (record) {
          const _storeInfo = value
            ? record.storeCode + "_" + record.storeName
            : null;

          return _storeInfo ? (
            <div className="list-page_ellipsis">
              <Tooltip
                color="white"
                overlayClassName="common_custom-tooltip"
                title={record.storeCode + "_" + record.storeName}
                overlayInnerStyle={{
                  color: COLOR_TOOLTIP,
                }}
              >
                {record.storeCode + "_" + record.storeName}
              </Tooltip>
            </div>
          ) : (
            DATA_EMPTY
          );
        }
        return null;
      },
    },

    {
      title: TITLE.ACTION,
      align: "center",
      width: 144,
      render(_, record) {
        return (
          <div className="list-page_modal-btns">
            <Button
              onClick={() => {
                handleOpenModalDetail(record);
              }}
            >
              <FolderViewOutlined
                style={{
                  fontSize: "21px",
                  color: "#344054",
                  cursor: "pointer",
                }}
              />
            </Button>
            <Button
              disabled={disableBtnOther}
              onClick={() => {
                handleOpenModalUpdate(record, record.id);
              }}
            >
              <EditOutlined
                style={{
                  fontSize: "21px",
                  color: `${disableBtnOther ? "#99a6bb" : "#344054"}`,
                  cursor: "pointer",
                }}
              />
            </Button>
            <Button
              disabled={disableBtnOther}
              onClick={() => {
                handleOpenModalDelete(record);
              }}
            >
              <DeleteOutlined
                style={{
                  fontSize: "21px",
                  color: `${disableBtnOther ? "#99a6bb" : "#F04438"}`,
                  cursor: "pointer",
                }}
              />
            </Button>
          </div>
        );
      },
    },
  ];
};
