import { useAppDispatch } from "@config/hooks";
import { TOKEN_COOKIE } from "@constants/common";
import { useWindowHeight } from "@hooks";
import { authActions } from "@redux/slices/authSlice";
import { useLayoutEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Cookies from "universal-cookie";

import dayjs from "dayjs";
import "dayjs/locale/ja";
import { AppRouting } from "./routers";

dayjs.locale("ja");
const cookies = new Cookies();

function App() {
  const token = cookies.get(TOKEN_COOKIE);
  const dispatch = useAppDispatch();
  useWindowHeight();

  useLayoutEffect(() => {
    if (!token) {
      dispatch(authActions.clearUser());
    }
  }, []);

  return (
    <BrowserRouter>
      <AppRouting />
    </BrowserRouter>
  );
}

export default App;
