import { AppContext } from "@layouts/LayoutAdmin/LayoutAdmin";
import { TSelectValue } from "@models/common";
import { EUserRoles } from "@models/user";
import { Form } from "antd";
import { Table } from "antd/lib";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { EModalType } from "types/others";
import { getColumns } from "../columns";
import { LIST_KEYS_ALLOW_PURPOSE, PURPOSE_CONSTANT } from "../constant";
import { images } from "@constants";
import { useSearchParams } from "react-router-dom";
import usePurposeApi from "./usePurposeApi";
import {
  OPTION_EMPTY,
  REGEX_CHARACTER_NUMBER,
  REGEX_REPLACE_TEMPERATURE,
} from "@constants/common";
import {
  TListPurpose,
  TListPurposeReq,
  TListPurposeRes,
  TPurposeRegistrationReq,
  TPurposeRegistrationRes,
} from "@models/purpose";

const { NAME, TITLE, MESSAGE } = PURPOSE_CONSTANT;

function usePurposePage() {
  const [form] = Form.useForm();
  const [formModal] = Form.useForm();
  const companyIdWatch = Form.useWatch(NAME.COMPANY_ID_MODAL, formModal);
  const departmentIdWatch = Form.useWatch(NAME.DEPARTMENT_ID_MODAL, formModal);
  const purposeCodeWatch = Form.useWatch(NAME.PURPOSE_CODE_MODAL, formModal);
  const purposeNameWatch = Form.useWatch(NAME.PURPOSE_NAME_MODAL, formModal);
  const purposeMinWatch = Form.useWatch(NAME.PURPOSE_MIN_TEMP, formModal);
  const purposeMaxWatch = Form.useWatch(NAME.PURPOSE_MAX_TEMP, formModal);

  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingCompany, setLoadingCompany] = useState(true);
  const [loadingPurpose, setLoadingPurpose] = useState(false);
  const [loadingDepartment, setLoadingDepartment] = useState(false);
  const [listPurpose, setListPurpose] = useState<TListPurpose[]>([]);
  const [companies, setCompanies] = useState<TSelectValue[]>([]);
  const [purposes, setPurposes] = useState<TSelectValue[]>([]);
  const [departments, setDepartments] = useState<TSelectValue[]>([]);
  const [hasNext, setHasNext] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [rowKey, setRowKey] = useState<number | null>(null);
  const [recordId, setRecordId] = useState("");
  const [purpose, setPurpose] = useState<TListPurposeRes | null>(null);
  // const [loadedIds, setLoadedIds] = useState<Set<string>>(new Set());

  const [companiesModal, setCompaniesModal] = useState<TSelectValue[]>([]);
  const [departmentsModal, setDepartmentsModal] = useState<TSelectValue[]>([]);
  const [loadingDepartmentModal, setLoadingDepartmentModal] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingExist, setLoadingExist] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalType, setModalType] = useState<EModalType | null | string>(null);
  const [modalTypeConfirm, setModalTypeConfirm] = useState<EModalType | null>(
    null
  );
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const companyParam = searchParams.get(NAME.COMPANY) || "";
  const departmentParam = searchParams.get(NAME.DEPARTMENT) || "";
  const purposeParam = searchParams.get(NAME.PURPOSE) || "";

  const { effect, handler } = usePurposeApi();
  const { role } = useContext(AppContext);
  const roleAdmin = role ? role[0] === EUserRoles.ADMIN : false;

  const disableBtnOther = roleAdmin ? false : true;

  const disableFields =
    modalType === EModalType.CONFIRM_REGISTRATION ||
    modalType === EModalType.CONFIRM_UPDATE ||
    modalType === EModalType.DETAIL;

  const disableFieldsEdit = modalType === EModalType.UPDATE || disableFields;

  const disableBtnNext =
    modalType !== EModalType.DETAIL &&
    (!companyIdWatch ||
      !departmentIdWatch ||
      !purposeCodeWatch ||
      !purposeNameWatch ||
      (purposeNameWatch && !purposeNameWatch.replace(/\s/g, "").length) ||
      !purposeMinWatch ||
      !purposeMaxWatch ||
      (!purposeMinWatch && purposeMinWatch !== 0) ||
      (!purposeMaxWatch && purposeMaxWatch !== 0));

  const tblRef: Parameters<typeof Table>[0]["ref"] = useRef(null);

  // ***** LOGIC FUNCTION *****
  const handleKeyDownTemperature = (event: any) => {
    if (
      /^-?\d*$/.test(event.key) ||
      LIST_KEYS_ALLOW_PURPOSE.includes(event.code)
    ) {
      return;
    }

    event.preventDefault();
  };

  const handleSetSearchParams = (values: any) => {
    searchParams.set(NAME.COMPANY, values.companyId || "");
    searchParams.set(NAME.DEPARTMENT, values.departmentId || "");
    searchParams.set(NAME.PURPOSE, values.purposeId || "");

    setSearchParams(searchParams, { replace: true });
  };

  const handleChangeCompany = (val: string) => {
    searchParams.delete(NAME.DEPARTMENT);
    form.setFieldValue(NAME.DEPARTMENT, undefined);
    setDepartments([OPTION_EMPTY]);
    searchParams.delete(NAME.PURPOSE);
    form.setFieldValue(NAME.PURPOSE, undefined);
    setPurposes([OPTION_EMPTY]);

    if (val) {
      handler.getAllDepartments(
        val,
        false,
        form,
        setDepartments,
        setDepartmentsModal,
        setLoadingDepartment,
        setPurposes,
        setLoadingPurpose
      );
    }
  };

  const handleChangeDepartment = (val: string) => {
    searchParams.delete(NAME.PURPOSE);
    form.setFieldValue(NAME.PURPOSE, undefined);
    setPurposes([OPTION_EMPTY]);

    if (val) {
      handler.getPurposesByDepartment(
        val,
        form,
        setPurposes,
        setLoadingPurpose
      );
    }
  };

  const handleChangeCompanyModal = (val: string) => {
    formModal.setFieldValue(NAME.DEPARTMENT_ID_MODAL, undefined);
    setDepartmentsModal([]);
    handler.getAllDepartments(
      val,
      true,
      form,
      setDepartments,
      setDepartmentsModal,
      setLoadingDepartmentModal,
      setPurposes,
      setLoadingPurpose
    );
  };

  const handleScroll = (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (
      scrollTop + clientHeight >= scrollHeight - 5 &&
      !loadingPage &&
      hasNext
    ) {
      setPageIndex((prev) => prev + 1);
      getListPurposeScroll({
        companyId: companyParam,
        departmentId: departmentParam,
        purposeId: purposeParam,
        pageIndex: pageIndex + 1,
      });
    }
  };

  const handleSetFormValues = (values: TPurposeRegistrationRes) => {
    setRecordId(values.id);
    formModal.setFieldValue(NAME.COMPANY_ID_MODAL, values.companyId);
    formModal.setFieldValue(NAME.DEPARTMENT_ID_MODAL, values.departmentId);
    formModal.setFieldValue(NAME.PURPOSE_CODE_MODAL, values.purposeCode);
    formModal.setFieldValue(NAME.PURPOSE_NAME_MODAL, values.purposeName);
    formModal.setFieldValue(NAME.PURPOSE_MIN_TEMP, values.minTemp);
    formModal.setFieldValue(NAME.PURPOSE_MAX_TEMP, values.maxTemp);
  };

  // Modal success
  const handleCloseModalSuccess = () => {
    setModalSuccess(false);
    setModalType(null);
    setRowKey(null);
  };

  const handleModalSuccess = (_values?: TPurposeRegistrationRes) => {
    setModalSuccess(true);

    // const _data: TListPurpose = {
    //   companyCode: values.companyCode,
    //   companyId: values.companyId,
    //   companyName: values.companyName,
    //   departmentCode: values.departmentCode,
    //   departmentId: values.departmentId,
    //   departmentName: values.departmentName,
    //   maxTemp: values.maxTemp,
    //   minTemp: values.minTemp,
    //   purposeCode: values.purposeCode,
    //   purposeName: values.purposeName,
    //   id: values.id,
    //   key: 1,
    // };

    if (modalTypeConfirm === EModalType.CONFIRM_REGISTRATION_FINAL) {
      setModalType(EModalType.SUCCESS_REGISTRATION);
      // setLoadedIds((prevIds) => new Set([...prevIds, _data.id]));
      // setListPurpose([
      //   { ..._data },
      //   ...listPurpose.map((item) => ({ ...item, key: item.key + 1 })),
      // ]);
    }

    if (modalTypeConfirm === EModalType.CONFIRM_UPDATE_FINAL) {
      setModalType(EModalType.SUCCESS_UPDATE);
      // const _filteredDevice = listPurpose.filter(
      //   (item) => item.id !== _data.id
      // );
      // setListPurpose([
      //   { ..._data },
      //   ..._filteredDevice.map((item) => ({ ...item, key: item.key + 1 })),
      // ]);
    }
  };

  // TODO: Recheck
  const handleOkModalSuccess = (_data: TPurposeRegistrationRes) => {
    setModalOpen(false);
    // handleModalSuccess(data);
    handleModalSuccess();
    handleCloseModalConfirmFinal();
    // tblRef.current?.scrollTo({ index: 0 });

    setListPurpose([]);
    setPageIndex(1);
    getListPurposeSearch({
      companyId: companyParam,
      departmentId: departmentParam,
      purposeId: purposeParam,
      pageIndex: 0,
    });
  };

  const handleCloseModal = () => {
    setModalType(null);
    setPurpose(null);
    setRowKey(null);
    setModalOpen(false);
  };

  // Modal fail
  const handleModalFail = () => {
    setModalSuccess(true);

    if (modalTypeConfirm === EModalType.CONFIRM_REGISTRATION_FINAL) {
      setModalType(EModalType.FAIL_REGISTRATION);
    }

    if (modalTypeConfirm === EModalType.CONFIRM_UPDATE_FINAL) {
      setModalType(EModalType.FAIL_UPDATE);
    }
  };

  const handleDeleteFail = () => {
    setModalSuccess(true);
    setModalConfirm(false);
    setModalDelete(false);
    setRowKey(null);
    setModalType(TITLE.NOT_FOUND_PURPOSE);
  };

  const handleCommonError = () => {
    setModalConfirm(false);
    setModalType(null);
    setPurpose(null);
    setRowKey(null);
    setModalOpen(false);
  };

  // Modal delete purpose
  const handleOpenModalDelete = (record: any) => {
    setPurpose(record);
    setRowKey(record.key);
    setModalDelete(true);
  };

  const handleCloseModalDelete = () => {
    setPurpose(null);
    setRowKey(null);
    setModalDelete(false);
  };

  // Modal detail
  const handleOpenModalDetail = (record: any) => {
    setModalOpen(true);
    setModalType(EModalType.DETAIL);
    setRowKey(record.key);

    handler.detailPurpose(
      record.id,
      companiesModal,
      setDepartmentsModal,
      handleSetFormValues,
      setLoadingModal
    );
  };

  // Modal update
  const handleOpenModalUpdate = (record: any) => {
    setModalOpen(true);
    setModalType(EModalType.UPDATE);
    setRowKey(record.key);

    handler.detailPurpose(
      record.id,
      companiesModal,
      setDepartmentsModal,
      handleSetFormValues,
      setLoadingModal
    );
  };

  const handleOpenModalRegistration = () => {
    setModalOpen(true);
    setModalType(EModalType.REGISTRATION);
    setDepartmentsModal([]);
  };

  // Modal confirm final
  const handleOpenModalConfirmFinal = () => {
    setModalConfirm(true);
    if (modalType === EModalType.CONFIRM_REGISTRATION) {
      setModalTypeConfirm(EModalType.CONFIRM_REGISTRATION_FINAL);
    }

    if (modalType === EModalType.CONFIRM_UPDATE) {
      setModalTypeConfirm(EModalType.CONFIRM_UPDATE_FINAL);
    }
  };

  const handleOkModalConfirmFinal = () => handleOkModal();

  const handleCloseModalConfirmFinal = () => setModalConfirm(false);

  const handleFormResetFieldError = () => {
    formModal.setFields([
      {
        name: [NAME.PURPOSE_NAME_MODAL],
        errors: [],
      },
      {
        name: [NAME.PURPOSE_MIN_TEMP],
        errors: [],
      },
      {
        name: [NAME.PURPOSE_MAX_TEMP],
        errors: [],
      },
    ]);
  };

  const handleValidateForm = () => {
    handleFormResetFieldError();

    let checkError = false;
    const validatePurposeCode =
      purposeCodeWatch && REGEX_CHARACTER_NUMBER.test(purposeCodeWatch)
        ? true
        : false;
    const validateTemperature =
      +purposeMinWatch > +purposeMaxWatch ? true : false;
    const validateMaxTemp =
      purposeMaxWatch && (purposeMaxWatch > 999.9 || purposeMaxWatch < -999.9)
        ? true
        : false;
    const validateMinTemp =
      purposeMinWatch && (purposeMinWatch < -999.9 || purposeMinWatch > 999.9)
        ? true
        : false;

    if (!validatePurposeCode) {
      formModal.setFields([
        { name: [NAME.PURPOSE_CODE_MODAL], errors: [MESSAGE.FIELD_INVALID] },
      ]);
      checkError = true;
    }
    if (validateTemperature) {
      formModal.setFields([
        {
          name: [NAME.PURPOSE_MIN_TEMP],
          errors: [MESSAGE.LOWER_UPPER],
        },
        {
          name: [NAME.PURPOSE_MAX_TEMP],
          errors: [MESSAGE.LOWER_UPPER],
        },
      ]);
      checkError = true;
    }
    if (validateMaxTemp) {
      formModal.setFields([
        {
          name: [NAME.PURPOSE_MAX_TEMP],
          errors: [MESSAGE.FIELD_INVALID_FORMAT],
        },
      ]);
      checkError = true;
    }
    if (validateMinTemp) {
      formModal.setFields([
        {
          name: [NAME.PURPOSE_MIN_TEMP],
          errors: [MESSAGE.FIELD_INVALID_FORMAT],
        },
      ]);
      checkError = true;
    }

    if (!checkError) {
      if (modalType === EModalType.REGISTRATION) {
        formModal.setFields([{ name: [NAME.PURPOSE_CODE_MODAL], errors: [] }]);
        handler.checkExistPurpose(
          {
            companyId: companyIdWatch,
            departmentId: departmentIdWatch,
            purposeCode: purposeCodeWatch,
          },
          formModal,
          setModalType,
          setLoadingExist
        );
      }

      if (modalType === EModalType.UPDATE) {
        setModalType(EModalType.CONFIRM_UPDATE);
      }
    }
  };

  const handleFinish = (values: any) => {
    handleSetSearchParams({
      purposeId: values.purposeId,
      companyId: values.companyId,
      departmentId: values.departmentId,
    });
    setListPurpose([]);
    setPageIndex(1);
    // setLoadedIds(new Set([]));

    getListPurposeSearch({
      ...values,
      pageIndex: 0,
    });
  };

  const getListPurposeSearch = async (payload: TListPurposeReq) => {
    handler.getListPurposeSearch(
      payload,
      setLoadingPage,
      setListPurpose,
      handleSetSearchParams,
      setPageIndex,
      setHasNext
    );
  };

  const getListPurposeScroll = async (payload: TListPurposeReq) => {
    handler.getListPurposeScroll(
      payload,
      listPurpose,
      pageIndex,
      // loadedIds,
      setLoadingPage,
      setListPurpose,
      // setLoadedIds,
      setPageIndex,
      setHasNext
    );
  };

  // ***** MEMO *****
  const renderTextModal = useMemo(() => {
    switch (modalType) {
      case EModalType.REGISTRATION:
        return {
          title: TITLE.TITLE_REGISTRATION,
          okText: TITLE.BTN_NEXT,
          message: "",
          image: undefined,
        };
      case EModalType.DETAIL:
        return {
          title: TITLE.TITLE_DETAIL,
          okText: TITLE.BTN_CLOSE,
          message: "",
          image: undefined,
        };
      case EModalType.UPDATE:
        return {
          title: TITLE.TITLE_UPDATE,
          okText: TITLE.BTN_NEXT,
          message: "",
          image: undefined,
        };

      case EModalType.CONFIRM_REGISTRATION:
        return {
          title: TITLE.TITLE_REGISTRATION,
          okText: TITLE.BTN_REGISTRATION_MODAL,
          message: "",
          image: undefined,
        };
      case EModalType.CONFIRM_UPDATE:
        return {
          title: TITLE.TITLE_UPDATE,
          okText: TITLE.BTN_UPDATE,
          message: "",
          image: undefined,
        };

      case EModalType.SUCCESS_REGISTRATION:
        return {
          title: TITLE.SUCCESS_REGISTRATION,
          okText: TITLE.BTN_OK,
          message: MESSAGE.REGISTER_SUCCESS,
          image: images.confirmApproveTemperature,
        };
      case EModalType.SUCCESS_UPDATE:
        return {
          title: TITLE.SUCCESS_TITLE,
          okText: TITLE.BTN_OK,
          message: MESSAGE.UPDATE_SUCCESS,
          image: images.confirmApproveTemperature,
        };
      case EModalType.SUCCESS_DELETE:
        return {
          title: TITLE.SUCCESS_TITLE,
          okText: TITLE.BTN_OK,
          message: MESSAGE.SUCCESS_DELETE,
          image: images.confirmApproveTemperature,
        };

      case EModalType.FAIL_REGISTRATION:
        return {
          title: TITLE.SUCCESS_TITLE,
          okText: TITLE.BTN_OK,
          message: MESSAGE.FAIL_REGISTER,
          image: images.importDataFail,
        };
      case EModalType.FAIL_UPDATE:
        return {
          title: TITLE.SUCCESS_TITLE,
          okText: TITLE.BTN_OK,
          message: MESSAGE.FAIL_UPDATE,
          image: images.importDataFail,
        };
      case EModalType.FAIL_DELETE:
        return {
          title: TITLE.SUCCESS_TITLE,
          okText: TITLE.BTN_OK,
          message: MESSAGE.FAIL_DELETE,
          image: images.importDataFail,
        };
      case TITLE.NOT_FOUND_PURPOSE:
        return {
          title: TITLE.SUCCESS_TITLE,
          okText: TITLE.BTN_OK,
          message: MESSAGE.NOT_FOUND_PURPOSE,
          image: images.importDataFail,
        };

      default:
        return {
          title: "",
          okText: "",
          message: "",
        };
    }
  }, [modalType]);

  const renderTextModalConfirm = useMemo(() => {
    switch (modalTypeConfirm) {
      case EModalType.CONFIRM_REGISTRATION_FINAL:
        return {
          title: TITLE.SUCCESS_TITLE,
          okText: TITLE.BTN_OK,
          message: MESSAGE.CONFIRM_REGISTRATION_FINAL,
        };
      case EModalType.CONFIRM_UPDATE_FINAL:
        return {
          title: TITLE.SUCCESS_TITLE,
          okText: TITLE.BTN_OK,
          message: MESSAGE.CONFIRM_UPDATE_FINAL,
        };

      default:
        return {
          title: "",
          okText: "",
          message: "",
        };
    }
  }, [modalTypeConfirm]);

  const columns = useMemo(() => {
    const columns = getColumns({
      disableBtnOther,
      handleOpenModalDelete,
      handleOpenModalDetail,
      handleOpenModalUpdate,
    });
    return columns;
  }, [companiesModal]);

  // ***** API *****
  const handleOkModalDelete = () => {
    if (purpose) {
      handler.deletePurpose(
        purpose.id,
        listPurpose,
        setListPurpose,
        setLoadingPage,
        setModalSuccess,
        setModalType,
        handleCloseModalDelete,
        handleDeleteFail
      );
    }
  };

  const handleOkModal = () => {
    const minTemp = purposeMinWatch.replace(REGEX_REPLACE_TEMPERATURE, "");
    const maxTemp = purposeMaxWatch.replace(REGEX_REPLACE_TEMPERATURE, "");
    let params: TPurposeRegistrationReq = {
      companyId: companyIdWatch,
      companyCode: "",
      companyName: "",
      departmentId: departmentIdWatch,
      departmentCode: "",
      departmentName: "",
      purposeCode: purposeCodeWatch,
      purposeName: purposeNameWatch,
      minTemp: Number.isInteger(+minTemp) ? `${minTemp}.0` : minTemp.toString(),
      maxTemp: Number.isInteger(+maxTemp) ? `${maxTemp}.0` : maxTemp.toString(),
    };

    const findCompany = companiesModal.find(
      (item) => item.value === companyIdWatch
    );
    const findDepartment = departmentsModal.find(
      (item) => item.value === departmentIdWatch
    );
    if (findCompany) {
      params = {
        ...params,
        companyCode: findCompany.children as string,
        companyName: findCompany.label.split("_")[1],
      };
    }
    if (findDepartment) {
      params = {
        ...params,
        departmentCode: findDepartment.children as string,
        departmentName: findDepartment.label.split("_")[1],
      };
    }

    if (modalTypeConfirm === EModalType.CONFIRM_REGISTRATION_FINAL) {
      handler.registrationPurpose(
        params,
        handleOkModalSuccess,
        setLoadingPage,
        handleModalFail,
        handleCommonError
      );
    }

    if (modalTypeConfirm === EModalType.CONFIRM_UPDATE_FINAL) {
      handler.updatePurpose(
        { ...params, id: recordId },
        handleOkModalSuccess,
        setLoadingPage,
        handleModalFail,
        handleCommonError
      );
    }
  };

  const fetchInitialApis = () => {
    handler.fetchInitialApis(
      pageIndex,
      setLoadingPage,
      setLoadingCompany,
      setCompanies,
      setCompaniesModal,
      setDepartments,
      setDepartmentsModal,
      setPurposes,
      setListPurpose,
      setHasNext,
      setPageIndex,
      handleSetSearchParams
    );
  };

  const fetchApiRedirect = (payload: any) => {
    handler.fetchApiRedirect(
      payload,
      pageIndex,
      setLoadingPage,
      setLoadingCompany,
      setCompanies,
      setCompaniesModal,
      setDepartments,
      setDepartmentsModal,
      setPurposes,
      setListPurpose,
      setHasNext,
      setPageIndex,
      handleSetSearchParams
    );
  };

  // ***** EFFECT *****
  useEffect(() => {
    if (companyParam && departmentParam && purposeParam) {
      fetchApiRedirect({
        purposeId: purposeParam,
        companyId: companyParam,
        departmentId: departmentParam,
      });
    } else {
      fetchInitialApis();
    }
  }, []);

  useEffect(() => {
    effect.effectCompany(companyParam, form, companies);
  }, [companies]);

  useEffect(() => {
    effect.effectPurpose(purposeParam, form, purposes);
  }, [purposes]);

  useEffect(() => {
    effect.effectDepartment(departmentParam, form, departments);
  }, [departments]);

  return {
    state: {
      form,
      loadingPage,
      loadingCompany,
      loadingPurpose,
      loadingDepartment,
      listPurpose,
      companies,
      purposes,
      departments,
      rowKey,
      tblRef,
      disableBtnOther,
      columns,

      purpose,
      modalDelete,
      modalSuccess,
      modalConfirm,
      modalType,
      modalOpen,
      formModal,
      loadingModal,
      loadingDepartmentModal,
      disableFields,
      disableFieldsEdit,
      disableBtnNext,
      renderTextModal,
      renderTextModalConfirm,
      companiesModal,
      departmentsModal,
      loadingExist,
    },
    handler: {
      handleScroll,
      handleFinish,
      handleChangeCompany,
      handleChangeDepartment,

      setLoadingPurpose,
      setModalType,
      handleCloseModal,
      handleOkModalDelete,
      handleCloseModalDelete,
      handleCloseModalSuccess,
      handleOkModalConfirmFinal,
      handleCloseModalConfirmFinal,
      handleValidateForm,
      handleOpenModalConfirmFinal,
      handleKeyDownTemperature,
      handleOpenModalRegistration,
      handleChangeCompanyModal,
    },
  };
}

export default usePurposePage;
