import { TCommonError } from "@models/common";
import { TAxiosResponse } from "types";
import { axiosInstance } from "./axiosInstance";
import {
  TCheckPurposeExistReq,
  TFormPurposeReq,
  TListDepartmentRes,
  TListPurposeReq,
  TListPurposeRes,
  TPurposeRegistrationReq,
  TPurposeRegistrationRes,
  TPurposeUpdateReq,
} from "@models/purpose";
import { DEFAULT_PAGE_SIZE } from "@constants/common";
import { TPurposesRes } from "@models/device";

const purposeApi = {
  getAllDepartments: async (payload: string) => {
    const url = `/Department/departments?CompanyId=${payload}`;
    const response: TAxiosResponse<TListDepartmentRes[], TCommonError> =
      await axiosInstance.get(url);
    return response;
  },

  getPurposesByDepartment: async (payload: string) => {
    const url = `/Purpose/purposes-by-department?DepartmentId=${payload}`;
    const response: TAxiosResponse<TPurposesRes[], TCommonError> =
      await axiosInstance.get(url);
    return response;
  },

  getListPurpose: async (payload: TListPurposeReq) => {
    const _payload: TFormPurposeReq = {
      companyId: payload.companyId || "",
      departmentId: payload.departmentId || "",
      purposeId: payload.purposeId || "",
    };
    const _pageIndex = payload.pageIndex ? payload.pageIndex : 1;
    const url = `Purpose/search?CompanyId=${_payload.companyId}&DepartmentId=${_payload.departmentId}&PurposeId=${_payload.purposeId}&PageIndex=${_pageIndex}&PageSize=${DEFAULT_PAGE_SIZE}`;
    const response: TAxiosResponse<TListPurposeRes[], TCommonError> =
      await axiosInstance.get(url);
    return response;
  },

  registrationPurpose: async (payload: TPurposeRegistrationReq) => {
    const url = "/Purpose/create";
    const response: TAxiosResponse<TPurposeRegistrationRes, TCommonError> =
      await axiosInstance.post(url, payload);
    return response;
  },

  updatePurpose: async (payload: TPurposeUpdateReq) => {
    const url = "/Purpose/update";
    const response: TAxiosResponse<TPurposeRegistrationRes, TCommonError> =
      await axiosInstance.put(url, payload);
    return response;
  },

  getDetailPurpose: async (payload: string) => {
    const url = `/Purpose/${payload}`;
    const response: TAxiosResponse<TPurposeRegistrationRes, TCommonError> =
      await axiosInstance.get(url);
    return response;
  },

  deletePurpose: async (payload: string) => {
    const url = `/Purpose/${payload}`;
    const response: TAxiosResponse<any, TCommonError> =
      await axiosInstance.delete(url);
    return response;
  },

  checkPurposeExist: async (payload: TCheckPurposeExistReq) => {
    const url = `/purpose/exists`;
    const response: TAxiosResponse<any, TCommonError> =
      await axiosInstance.post(url, payload);
    return response;
  },
};

export { purposeApi };
