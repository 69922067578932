import { COMMON_MESSAGE_ERROR, DATE_HYPHEN_FORMAT } from "@constants/common";
import { TSelectValue } from "@models/common";
import {
  TConvertSensorReportsResult,
  TDailyReport,
  TExportDailyReport,
  TPurposesReq,
  TReportComment,
  TReportSensorComment,
  TStoreReport,
} from "@models/device";
import { TCompanyReq, TStoreRes } from "@models/layout";
import { dailyReportApi } from "@services/dailyReportApi";
import { layoutApi } from "@services/layoutApi";
import { openCaseApi } from "@services/openCaseApi";
import { handleShowToastError } from "@utils/helpers";
import dayjs, { Dayjs } from "dayjs";
import { handleConvertSensorData } from "../utils/helpers";
import { FormInstance } from "antd/lib";
import { OPTION_ALL_PURPOSE, TEMPERATURE_CONSTANT } from "../constant";
import _ from "lodash";
import { appToast } from "@utils/appToast";
import { EHttpStatusCode } from "types/service";
import { TAxiosResponse } from "types";

const { NAME, TITLE, MESSAGE } = TEMPERATURE_CONSTANT;

function useTemperatureReportApi() {
  const handleChangeSwitch = (
    checked: boolean,
    setChangeSwitch: (val: boolean) => void
  ) => {
    if (checked) {
      setChangeSwitch(true);
    } else {
      setChangeSwitch(false);
    }
  };

  const handleStoreReport = (
    form: FormInstance,
    storeReport: TStoreReport,
    setDisableComment?: (val: boolean) => void
  ) => {
    form.setFieldValue(NAME.COMMENT, storeReport.comments);
    if (storeReport.isApproved) {
      form.setFieldValue(NAME.MANAGER, storeReport.storeManagerName);
      setDisableComment?.(true);
    } else {
      form.setFieldValue(NAME.MANAGER, TITLE.UNCONFIRMED);
      setDisableComment?.(false);
    }
  };

  const callApiExportFile = async (
    fromDate: Dayjs,
    toDate: Dayjs,
    company: TSelectValue,
    stores: TStoreRes[][],
    setLoadingPage: (val: boolean) => void,
    handleCloseModalExport: () => void
  ) => {
    while (stores.length > 0) {
      const apiCalls = stores[0].map((item) =>
        exportDailyReport(
          {
            fromDate: fromDate.format(DATE_HYPHEN_FORMAT),
            toDate: toDate.format(DATE_HYPHEN_FORMAT),
            companyCode: company.value,
            companyName: company.label,
            storeCode: item.storeCode,
            storeName: item.storeName,
          },
          setLoadingPage,
          handleCloseModalExport
        )
      );

      await Promise.allSettled(apiCalls).finally(() => {
        if (stores.length === 1) {
          setLoadingPage(false);
          handleCloseModalExport();
        }
      });
      stores.shift();
      await new Promise((resolve) => setTimeout(resolve, 1500));
    }
  };

  const handleExportFile = (
    fromDate: Dayjs,
    toDate: Dayjs,
    stores: TStoreRes[],
    companyCode: string,
    storeCode: string,
    isChecked: boolean,
    _storeName: string,
    companies: TSelectValue[],
    setLoadingPage: (val: boolean) => void,
    handleCloseModalExport: () => void
  ) => {
    const findCompany = _.find(companies, function (val) {
      return val.value === companyCode;
    });
    const _stores = _.chunk(stores, 6);

    if (findCompany) {
      if (isChecked) {
        callApiExportFile(
          fromDate,
          toDate,
          findCompany,
          _stores,
          setLoadingPage,
          handleCloseModalExport
        );
      } else {
        exportDailyReport(
          {
            fromDate: fromDate.format(DATE_HYPHEN_FORMAT),
            toDate: toDate.format(DATE_HYPHEN_FORMAT),
            companyCode,
            companyName: findCompany.label,
            storeCode,
            storeName: _storeName,
          },
          setLoadingPage,
          handleCloseModalExport
        );
      }
    }
  };

  // EFFECT
  const effectCompanies = (
    companies: TSelectValue[],
    form: FormInstance,
    setCode: (val: string) => void,
    searchParams: URLSearchParams
  ) => {
    const companyCode = searchParams.get("companyCode");
    if (companyCode) {
      form.setFieldValue(NAME.COMPANY, +companyCode);
      setCode(companyCode);
    } else {
      if (companies.length) {
        form.setFieldValue(NAME.COMPANY, companies[0].value);
        setCode(companies[0].value);
      }
    }
  };

  const effectStores = (
    stores: TStoreRes[],
    form: FormInstance,
    setStateStoreCode: (val: string) => void,
    searchParams: URLSearchParams
  ) => {
    const storeCode = searchParams.get("storeCode");
    if (storeCode) {
      form.setFieldValue(NAME.STORE, storeCode);
      setStateStoreCode(storeCode);
    } else {
      if (stores.length) {
        form.setFieldValue(NAME.STORE, stores[0].storeCode);
        setStateStoreCode(stores[0].storeCode);
      }
    }
  };

  const effectPurposes = (
    purposes: TSelectValue[],
    form: FormInstance,
    searchParams: URLSearchParams
  ) => {
    const purposeCode = searchParams.get("purposeCode");
    if (purposes.length) {
      if (purposeCode) {
        form.setFieldValue(NAME.PURPOSE, purposeCode);
      } else {
        form.setFieldValue(NAME.PURPOSE, purposes[0].value);
      }
    }
  };

  // API
  const sendReportComment = async (
    payload: TReportComment,
    form: FormInstance,
    setLoadingPage: (val: boolean) => void,
    setOpenModalConfirm: (val: boolean) => void,
    getDailyReport: (val: TDailyReport) => void
  ) => {
    setLoadingPage(true);

    try {
      const response = await dailyReportApi.reportComment(payload);

      if (response.ok) {
        appToast.success({ message: MESSAGE.CONFIRM_SUCCESS });
        setOpenModalConfirm(false);
        getDailyReport({
          reportDate: payload.reportDate,
          companyCode: payload.companyCode,
          purposeCode: form.getFieldValue(NAME.PURPOSE),
          storeCode: payload.storeCode,
        });
      }
    } catch (error) {
      setLoadingPage(false);
      const err = error as TAxiosResponse;
      if (err.status === EHttpStatusCode.BAD_REQUEST) {
        appToast.error({ message: MESSAGE.CONFIRM_FAIL });
        return;
      }

      if (err.status === EHttpStatusCode.FORBIDDEN) {
        appToast.error({ message: COMMON_MESSAGE_ERROR });
        return;
      }

      handleShowToastError(error);
    }
  };

  const sendReportTempSensorComment = async (
    payload: TReportSensorComment,
    form: FormInstance,
    setLoadingPage: (val: boolean) => void,
    handleCloseModalEditRemark: () => void,
    getDailyReport: (val: TDailyReport) => void
  ) => {
    setLoadingPage(true);

    try {
      const response = await dailyReportApi.reportTempSensorComment(payload);

      if (response.ok) {
        handleCloseModalEditRemark();
        getDailyReport({
          reportDate: payload.reportDate,
          companyCode: form.getFieldValue(NAME.COMPANY),
          purposeCode: form.getFieldValue(NAME.PURPOSE),
          storeCode: form.getFieldValue(NAME.STORE),
        });
      }
    } catch (error) {
      setLoadingPage(false);

      const err = error as TAxiosResponse;
      if (err.status === EHttpStatusCode.FORBIDDEN) {
        appToast.error({ message: COMMON_MESSAGE_ERROR });
        return;
      }
      handleShowToastError(error);
    }
  };

  const getStoresByCompany = async (
    payload: TCompanyReq,
    setSelectedStores: (val: TSelectValue[]) => void,
    setStores: (val: TStoreRes[]) => void,
    setLoadingStore: (val: boolean) => void
  ) => {
    setLoadingStore(true);

    try {
      const response = await layoutApi.getStoresByCompany(payload);

      if (response.ok) {
        const convertStores = response.data.map((val) => ({
          value: val.storeCode,
          label: val.storeCode + "_" + val.storeName,
        }));
        setSelectedStores(convertStores);
        setStores(response.data);
      }
    } catch (error) {
      handleShowToastError(error);
    } finally {
      setLoadingStore(false);
    }
  };

  const getAllPurposes = async (
    payload: TPurposesReq,
    setPurposes: (val: TSelectValue[]) => void,
    setLoadingPurpose: (val: boolean) => void
  ) => {
    setLoadingPurpose(true);

    try {
      const response = await openCaseApi.getAllPurposes(payload);

      if (response.ok) {
        const convertPurposes = response.data.map((val) => ({
          value: val.purposeCode,
          label: val.purposeName,
        }));
        setPurposes([OPTION_ALL_PURPOSE, ...convertPurposes]);
      }
    } catch (error) {
      handleShowToastError(error);
    } finally {
      setLoadingPurpose(false);
    }
  };

  const exportDailyReport = async (
    payload: TExportDailyReport,
    setLoadingPage: (val: boolean) => void,
    handleCloseModalExport: () => void
  ) => {
    setLoadingPage(true);
    try {
      const response = await dailyReportApi.exportDailyReport(payload);

      if (response.ok) {
        const base64 = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.data.fileData}`;
        const elementAnchor = document.createElement("a");
        elementAnchor.href = base64;
        elementAnchor.download = response.data.fileName; // File name
        document.body.appendChild(elementAnchor);
        elementAnchor.click();
        document.body.removeChild(elementAnchor);
      }
    } catch (error) {
      handleShowToastError(error);

      // TODO: handle logic error 400
    } finally {
      setLoadingPage(false);
      handleCloseModalExport();
    }
  };

  const getDailyReport = async (
    payload: TDailyReport,
    form: FormInstance,
    setLoadingPage: (val: boolean) => void,
    setSensorReports: (val: TConvertSensorReportsResult) => void,
    setDisableComment?: (val: boolean) => void
  ) => {
    setLoadingPage(true);

    try {
      const response = await dailyReportApi.getDailyReport(payload);

      if (response.ok) {
        const { sensorDailyReports, storeReport } = response.data;
        handleStoreReport(form, storeReport, setDisableComment);
        setSensorReports(handleConvertSensorData(sensorDailyReports));
      }
    } catch (error) {
      handleShowToastError(error);

      form.setFieldValue(NAME.MANAGER, TITLE.UNCONFIRMED);
      setSensorReports([]);
      setDisableComment?.(false);
    } finally {
      setLoadingPage(false);
    }
  };

  // Call concurrent api for role STORE
  const fetchInitialApisRoleStore = async (
    payload: TPurposesReq,
    form: FormInstance,
    setPurposes: (val: TSelectValue[]) => void,
    setLoadingPurpose: (val: boolean) => void,
    setLoadingPage: (val: boolean) => void,
    setDisableComment: (val: boolean) => void,
    setSensorReports: (val: TConvertSensorReportsResult) => void,
    searchParams: URLSearchParams,
    setSearchParams: (val: URLSearchParams) => void,
    storeCode?: string | null
  ) => {
    setLoadingPurpose(true);
    setLoadingPage(true);

    try {
      const response = await openCaseApi.getAllPurposes(payload);

      if (response.ok) {
        const convertPurposes = response.data.map((val) => ({
          value: val.purposeCode,
          label: val.purposeName,
        }));
        const _convertPurposes = [OPTION_ALL_PURPOSE, ...convertPurposes];
        setPurposes(_convertPurposes);

        const purposeCode =
          searchParams.get("purposeCode") || _convertPurposes[0].value;
        searchParams.set("companyCode", payload.companyCode);
        searchParams.set("storeCode", storeCode || "");
        searchParams.set("purposeCode", purposeCode);
        searchParams.set(
          "reportDate",
          form.getFieldValue(NAME.DATE).format(DATE_HYPHEN_FORMAT)
        );
        setSearchParams(searchParams);

        const responseReport = await dailyReportApi.getDailyReport({
          companyCode: payload.companyCode,
          storeCode,
          purposeCode: purposeCode,
          reportDate: form.getFieldValue(NAME.DATE).format(DATE_HYPHEN_FORMAT),
        });

        if (responseReport.ok) {
          const { sensorDailyReports, storeReport } = responseReport.data;
          handleStoreReport(form, storeReport, setDisableComment);
          setSensorReports(handleConvertSensorData(sensorDailyReports));
        }
      }
    } catch (error) {
      setDisableComment?.(false);
      form.setFieldValue(NAME.MANAGER, TITLE.UNCONFIRMED);
      handleShowToastError(error);
      setSensorReports([]);
    } finally {
      setLoadingPurpose(false);
      setLoadingPage(false);
    }
  };

  // Call concurrent api for role ADMIN/MENTEX
  const fetchInitialApis = async (
    form: FormInstance,
    setLoadingPage: (val: boolean) => void,
    setLoadingCompany: (val: boolean) => void,
    setCompanies: (val: TSelectValue[]) => void,
    setSelectedStores: (val: TSelectValue[]) => void,
    setPurposes: (val: TSelectValue[]) => void,
    setStores: (val: TStoreRes[]) => void,
    setStoreCodeRedirect: (val: string) => void,
    setSensorReports: (val: TConvertSensorReportsResult) => void,
    searchParams: URLSearchParams,
    setSearchParams: (val: URLSearchParams) => void,
    setDisableComment: (val: boolean) => void
  ) => {
    try {
      const resCompany = await layoutApi.getCompanies();

      if (resCompany.ok) {
        const convertCompanies = resCompany.data.map((val) => ({
          value: val.companyCode,
          label: val.companyName,
        }));
        setCompanies(convertCompanies);

        const [resStore, resPurpose] = await Promise.all([
          layoutApi.getStoresByCompany({
            companyCode: convertCompanies[0].value,
            companyName: convertCompanies[0].label,
          }),
          openCaseApi.getAllPurposes({
            companyCode: convertCompanies[0].value,
            companyName: convertCompanies[0].label,
          }),
        ]);

        if (resStore.ok && resStore.data.length) {
          const convertStores = resStore.data.map((val) => ({
            value: val.storeCode,
            label: val.storeCode + "_" + val.storeName,
          }));
          setSelectedStores(convertStores);
          setStores(resStore.data);
          setStoreCodeRedirect(convertStores[0].value);
        }

        let _convertPurposes: TSelectValue[] = [OPTION_ALL_PURPOSE];
        if (resPurpose.ok && resPurpose.data.length) {
          const convertPurposes = resPurpose.data.map((val) => ({
            value: val.purposeCode,
            label: val.purposeName,
          }));
          _convertPurposes = [..._convertPurposes, ...convertPurposes];
        }
        setPurposes(_convertPurposes);

        searchParams.set("companyCode", convertCompanies[0].value);
        searchParams.set("storeCode", resStore.data[0].storeCode);
        searchParams.set("purposeCode", _convertPurposes[0].value);
        searchParams.set(
          "reportDate",
          dayjs().subtract(1, "d").format(DATE_HYPHEN_FORMAT)
        );
        setSearchParams(searchParams);

        const response = await dailyReportApi.getDailyReport({
          companyCode: convertCompanies[0].value,
          storeCode: resStore.data[0].storeCode,
          purposeCode: _convertPurposes[0].value,
          reportDate: dayjs().subtract(1, "d").format(DATE_HYPHEN_FORMAT),
        });

        if (response.ok) {
          const { sensorDailyReports, storeReport } = response.data;
          handleStoreReport(form, storeReport, setDisableComment);
          setSensorReports(handleConvertSensorData(sensorDailyReports));
        }
      }
    } catch (error) {
      setSensorReports([]);
      setDisableComment?.(false);
      form.setFieldValue(NAME.MANAGER, TITLE.UNCONFIRMED);
      handleShowToastError(error);

      // TODO: handle error
    } finally {
      setLoadingPage(false);
      setLoadingCompany(false);
    }
  };

  return {
    handler: {
      handleChangeSwitch,
      handleStoreReport,

      getStoresByCompany,
      getAllPurposes,
      getDailyReport,
      handleExportFile,
      sendReportTempSensorComment,
      sendReportComment,

      fetchInitialApis,
      fetchInitialApisRoleStore,
    },
    effect: {
      effectCompanies,
      effectStores,
      effectPurposes,
    },
  };
}

export default useTemperatureReportApi;
