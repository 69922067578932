import { Tabs } from "antd";
import { FILE_MANAGE_CONSTANT } from "./constant";
import { FileManageWrapper } from "./FileManageStyled";
import ManagementTab from "./components/ManagementTab";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

dayjs.extend(utc);

const { TITLE } = FILE_MANAGE_CONSTANT;

function FileManagePage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const _exportedType = searchParams.get("exportedType") || "0";

  const handleClickTab = (key: string) => {
    searchParams.set("exportedType", key);
    setSearchParams(searchParams);
  };

  const listTabs = [
    {
      label: TITLE.TEMPERATURE_DAILY,
      key: "0", // temperature_daily
      children: (
        <ManagementTab
          tabName={_exportedType}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      ),
    },
    {
      label: TITLE.SUMMARY_TABLE,
      key: "1", // summary_table
      children: (
        <ManagementTab
          tabName={_exportedType}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      ),
    },
    {
      label: TITLE.ALL_DEVICE,
      key: "2", // all device
      children: (
        <ManagementTab
          tabName={_exportedType}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      ),
    },
  ];

  return (
    <FileManageWrapper>
      <div className="file-manage_header">
        <h1>{TITLE.FILE_MANAGEMENT}</h1>
      </div>

      <div className="file-manage_tab">
        <Tabs
          destroyInactiveTabPane
          onTabClick={(key) => handleClickTab(key)}
          type="card"
          items={listTabs}
          activeKey={_exportedType}
        />
      </div>
    </FileManageWrapper>
  );
}

export { FileManagePage };
