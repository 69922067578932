import { DATE_HYPHEN_FORMAT } from "@constants/common";
import { AppContext } from "@layouts/LayoutAdmin/LayoutAdmin";
import { TSelectValue } from "@models/common";
import {
  TConvertSensorReports,
  TConvertSensorReportsResult,
  TDailyReport,
  TDailyReportForm,
  TPurposesReq,
  TReportComment,
  TReportSensorComment,
} from "@models/device";
import { TCompanyReq, TStoreRes } from "@models/layout";
import { EUserRoles } from "@models/user";
import { handleDisabledDate } from "@utils/helpers";
import type { DatePicker, GetProps } from "antd";
import { Form } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs, { Dayjs } from "dayjs";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { adminPaths } from "routers/routes";
import { getColumns } from "../columns";
import { TEMPERATURE_CONSTANT } from "../constant";
import useTemperatureReportApi from "./useTemperatureReportApi";
import useTemperatureReportRouter from "./useTemperatureReportRouter";
import _ from "lodash";

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

const { NAME, MESSAGE } = TEMPERATURE_CONSTANT;

function useTemperatureReport() {
  const { handler, effect } = useTemperatureReportApi();
  const { handler: handler2 } = useTemperatureReportRouter();

  const [disableComment, setDisableComment] = useState(false);
  const [changeSwitch, setChangeSwitch] = useState(true);
  const [companies, setCompanies] = useState<TSelectValue[]>([]);
  const [selectedStores, setSelectedStores] = useState<TSelectValue[]>([]);
  const [stores, setStores] = useState<TStoreRes[]>([]);
  const [purposes, setPurposes] = useState<TSelectValue[]>([]);
  const [code, setCode] = useState(""); // companyCode
  const [stateStoreCode, setStateStoreCode] = useState(""); // storeCode
  const [storeCodeRedirect, setStoreCodeRedirect] = useState("");
  const [loadingCompany, setLoadingCompany] = useState(true);
  const [loadingStore, setLoadingStore] = useState(false);
  const [loadingPurpose, setLoadingPurpose] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [sensorReports, setSensorReports] =
    useState<TConvertSensorReportsResult>([]);

  // Modal
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModalPeriodTime, setModalPeriodTime] = useState(false);
  const [openModalEditRemark, setOpenModalEditRemark] = useState(false);
  const [commentModalEdit, setCommentModalEdit] = useState<string | null>("");
  const [sensorIdModalEdit, setSensorIdModalEdit] = useState("");
  const [messageErrorExport, setMessageErrorExport] = useState("");
  const [chooseDate, setChooseDate] = useState(dayjs().subtract(1, "d"));

  const [form] = Form.useForm();
  const watchFromDate = Form.useWatch(NAME.START_DATE, form);
  const watchToDate = Form.useWatch(NAME.END_DATE, form);

  const { role, companyCode, companyName, storeCode, storeName } =
    useContext(AppContext);
  const _companyCode = companyCode || "";
  const _companyName = companyName || "";
  const _storeCode = storeCode || "";
  const _storeName = storeName || "";
  // Disable item for role STORE
  const disableItemStore = role ? role[0] === EUserRoles.STORE : false;
  // Disable comment & remark for role ADMIN
  const disableFieldAdmin = role ? role[0] === EUserRoles.ADMIN : false;

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // Redirect to layout page
  const handleRedirectLayout = () => {
    const companyCode = searchParams.get("companyCode");
    const storeCode = searchParams.get("storeCode");

    if (storeCode) {
      navigate(
        `${adminPaths.layoutDiagram}?companyCode=${companyCode}&storeCode=${storeCode}`
      );
    } else {
      navigate(
        `${adminPaths.layoutDiagram}?companyCode=${code}&storeCode=${storeCodeRedirect}`
      );
    }
  };

  const handleStoreCode = (val1: string, val2: string) => {
    return disableItemStore ? val1 : val2;
  };

  // Redirect to open case page
  const handleRedirectPage = (record: TConvertSensorReports) => {
    navigate(
      `${adminPaths.openCase}?companyCode=${code}&storeCode=${handleStoreCode(
        _storeCode,
        stateStoreCode
      )}&reportDate=${chooseDate.format(DATE_HYPHEN_FORMAT)}&chCode=${
        record.chCode
      }`
    );
  };

  const handleSubmit = (val: TDailyReportForm) => {
    setChooseDate(val.reportDate);
    setCode(val.companyCode);
    setStateStoreCode(val.storeCode);
    setStoreCodeRedirect(val.storeCode);

    searchParams.set("companyCode", val.companyCode);
    searchParams.set("storeCode", handleStoreCode(_storeCode, val.storeCode));
    searchParams.set("purposeCode", val.purposeCode);
    searchParams.set("reportDate", val.reportDate.format(DATE_HYPHEN_FORMAT));
    setSearchParams(searchParams);

    getDailyReport({
      companyCode: val.companyCode,
      storeCode: handleStoreCode(_storeCode, val.storeCode),
      purposeCode: val.purposeCode,
      reportDate: val.reportDate.format(DATE_HYPHEN_FORMAT),
    });
  };

  const handleCheckExport = () => {
    const toDate: Dayjs = watchToDate;
    const fromDate: Dayjs = watchFromDate;
    const storeCodeExport = form.getFieldValue(NAME.STORE);
    const findStore = stores.find((item) => item.storeCode === storeCodeExport);
    const storeNameExport = disableItemStore
      ? _storeName
      : (findStore?.storeName as string);

    if (Math.abs(toDate.diff(fromDate, "d")) > 13) {
      setMessageErrorExport(MESSAGE.SPECIFY_PERIOD);
    } else {
      setMessageErrorExport("");
      handler.handleExportFile(
        fromDate,
        toDate,
        stores,
        form.getFieldValue(NAME.COMPANY),
        form.getFieldValue(NAME.STORE),
        form.getFieldValue(NAME.CHECKBOX),
        storeNameExport,
        companies,
        setLoadingPage,
        handleCloseModalExport
      );
    }
  };

  const handleChangeSwitch = (checked: boolean) => {
    handler.handleChangeSwitch(checked, setChangeSwitch);
  };

  const handleDisableFromDate: RangePickerProps["disabledDate"] = (current) => {
    const disableDate = handleDisabledDate?.(current, {
      type: "date",
      from: undefined,
    });

    return (
      disableDate ||
      current.isAfter(dayjs(), "day") ||
      current.isAfter(watchToDate, "day")
    );
  };

  const handleDisableToDate: RangePickerProps["disabledDate"] = (current) => {
    return (
      current.isAfter(dayjs().subtract(1, "d"), "day") ||
      current.isBefore(watchFromDate, "day")
    );
  };

  const handleOpenModalExport = () => {
    const date = form.getFieldValue(NAME.DATE);
    form.setFieldValue(NAME.START_DATE, date);
    form.setFieldValue(NAME.END_DATE, date);
    setModalPeriodTime(true);
  };

  const handleCloseModalExport = () => {
    const date = form.getFieldValue(NAME.DATE);
    form.setFieldValue(NAME.START_DATE, date);
    form.setFieldValue(NAME.END_DATE, date);
    form.setFieldValue(NAME.CHECKBOX, false);

    setModalPeriodTime(false);
    setMessageErrorExport("");
  };

  const handleOpenModalEditRemark = (record: TConvertSensorReports) => {
    setCommentModalEdit(record.comment);
    setSensorIdModalEdit(record.id);
    setOpenModalEditRemark(true);
  };

  const handleCloseModalEditRemark = () => {
    setOpenModalEditRemark(false);
    setCommentModalEdit("");
    setSensorIdModalEdit("");
  };

  const columnsCustom = useMemo(() => {
    const columns = getColumns({
      disableFieldAdmin,
      changeSwitch,
      handleOpenModalEditRemark,
      handleRedirectPage,
    });
    return columns as ColumnsType<TConvertSensorReports>;
  }, [changeSwitch, chooseDate, code, stateStoreCode]);

  const handleResetPurpose = () => {
    searchParams.delete("purposeCode");
    form.setFieldValue(NAME.PURPOSE, "");
    setPurposes([]);
  };

  const handleResetStore = () => {
    searchParams.delete("storeCode");
    form.setFieldValue(NAME.STORE, "");
    setStateStoreCode("");
    setStores([]);
  };

  const handleChangeCompany = (val: string) => {
    handleResetPurpose();
    handleResetStore();

    const findCompany = companies.find((company) => company.value === val);

    if (findCompany) {
      getStoresByCompany({
        companyName: findCompany.label,
        companyCode: val,
      });
      getAllPurposes({ companyCode: val, companyName: findCompany.label });
    }
  };

  const handleConfirmReportComment = () => {
    const comments = form.getFieldValue(NAME.COMMENT);
    const reportDate: Dayjs = form.getFieldValue(NAME.DATE);
    const companyCode = form.getFieldValue(NAME.COMPANY);
    const storeCode = form.getFieldValue(NAME.STORE);

    const findStore = _.find(selectedStores, function (item) {
      return item.value === storeCode;
    });

    if (findStore) {
      sendReportComment({
        companyCode,
        storeCode,
        storeName: findStore.label || _storeName,
        comments,
        reportDate: dayjs(reportDate).format(DATE_HYPHEN_FORMAT),
      });
    }
  };

  const handleReportTempSensorComment = () => {
    const reportDate: Dayjs = form.getFieldValue(NAME.DATE);
    sendReportTempSensorComment({
      sensorId: sensorIdModalEdit,
      comment: commentModalEdit,
      reportDate: reportDate.format(DATE_HYPHEN_FORMAT),
    });
  };

  /** API */
  const getStoresByCompany = async (payload: TCompanyReq) => {
    handler.getStoresByCompany(
      payload,
      setSelectedStores,
      setStores,
      setLoadingStore
    );
  };

  const getAllPurposes = async (payload: TPurposesReq) => {
    handler.getAllPurposes(payload, setPurposes, setLoadingPurpose);
  };

  const getDailyReport = async (payload: TDailyReport) => {
    handler.getDailyReport(
      payload,
      form,
      setLoadingPage,
      setSensorReports,
      setDisableComment
    );
  };

  // Call concurrent api for role ADMIN/MENTEX
  const fetchInitialApis = async () => {
    handler.fetchInitialApis(
      form,
      setLoadingPage,
      setLoadingCompany,
      setCompanies,
      setSelectedStores,
      setPurposes,
      setStores,
      setStoreCodeRedirect,
      setSensorReports,
      searchParams,
      setSearchParams,
      setDisableComment
    );
  };

  // TODO
  const sendReportComment = async (payload: TReportComment) => {
    handler.sendReportComment(
      payload,
      form,
      setLoadingPage,
      setOpenModalConfirm,
      getDailyReport
    );
  };

  const sendReportTempSensorComment = async (payload: TReportSensorComment) => {
    handler.sendReportTempSensorComment(
      payload,
      form,
      setLoadingPage,
      handleCloseModalEditRemark,
      getDailyReport
    );
  };

  /** EFFECT */
  useEffect(() => {
    const reportDate =
      searchParams.get("reportDate") ||
      dayjs().subtract(1, "d").format(DATE_HYPHEN_FORMAT);
    setChooseDate(dayjs(reportDate));

    if (!disableItemStore) {
      handler2.fetchInitialApis(
        {
          form,
          setStoreCodeRedirect,
          setLoadingPage,
          setLoadingCompany,
          setCompanies,
          setSelectedStores,
          setPurposes,
          setStores,
          setSensorReports,
          setDisableComment,
        },
        fetchInitialApis,
        searchParams
      );
    } else {
      setLoadingCompany(false);

      // TODO: Handle logic role store
      setCompanies([
        ...companies,
        { label: _companyName, value: _companyCode },
      ]);
      setSelectedStores([
        ...selectedStores,
        { label: _storeCode + "_" + _storeName, value: _storeCode },
      ]);
      form.setFieldValue(NAME.COMPANY, _companyCode);
      form.setFieldValue(NAME.STORE, _storeCode);
      form.setFieldValue(NAME.DATE, dayjs(reportDate));

      handler.fetchInitialApisRoleStore(
        {
          companyCode: _companyCode,
          companyName: _companyName,
        },
        form,
        setPurposes,
        setLoadingPurpose,
        setLoadingPage,
        setDisableComment,
        setSensorReports,
        searchParams,
        setSearchParams,
        _storeCode
      );
    }
  }, []);

  useEffect(() => {
    effect.effectCompanies(companies, form, setCode, searchParams);
  }, [companies]);

  useEffect(() => {
    effect.effectStores(stores, form, setStateStoreCode, searchParams);
  }, [stores]);

  useEffect(() => {
    effect.effectPurposes(purposes, form, searchParams);
  }, [purposes]);

  return {
    state: {
      form,
      disableFieldAdmin,
      disableComment,
      disableItemStore,
      changeSwitch,
      columnsCustom,
      companies,
      selectedStores,
      purposes,
      loadingPage,
      loadingStore,
      loadingCompany,
      loadingPurpose,
      sensorReports,

      openModalConfirm,
      openModalPeriodTime,
      openModalEditRemark,
      commentModalEdit,
      messageErrorExport,
      searchParams,
    },
    handler: {
      setSearchParams,
      handleChangeSwitch,
      handleSubmit,
      handleChangeCompany,
      handleRedirectLayout,

      setOpenModalConfirm,
      handleConfirmReportComment,
      handleCloseModalEditRemark,
      setCommentModalEdit,
      handleDisableFromDate,
      handleDisableToDate,
      handleOpenModalExport,
      handleCloseModalExport,
      handleCheckExport,
      handleReportTempSensorComment,
    },
  };
}

export default useTemperatureReport;
