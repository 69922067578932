import { REFRESH_TOKEN_COOKIE, TOKEN_COOKIE } from "@constants/common";
import { TLoginRes } from "@models/login";
import { TUser } from "@models/user";
import { createSlice } from "@reduxjs/toolkit";
import { removeAllCookies } from "@utils/helpers";
import { TPayloadAction } from "types";
import Cookies from "universal-cookie";

type TAuthState = {
  forgotPassLoading: boolean;
  currentUser: TUser | null;
  loadingToken: boolean;
};

const initialState: TAuthState = {
  forgotPassLoading: false,
  currentUser: null,
  loadingToken: false,
};

const cookies = new Cookies();

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(_state, _action) {
      /* TODO: reducer for pending*/
    },
    loginSuccess(state, action: TPayloadAction<TLoginRes>) {
      /* TODO: reducer for success*/

      const { accessToken, refreshToken, account } = action.payload.data;

      cookies.set(TOKEN_COOKIE, accessToken, {
        path: "/",
      });

      cookies.set(REFRESH_TOKEN_COOKIE, refreshToken, {
        path: "/",
      });

      state.currentUser = account;
    },
    loginFailure(_state) {
      //
    },

    logout(_state, _action: TPayloadAction<any>) {
      //
    },
    logoutSuccess(state) {
      removeAllCookies();
      state.currentUser = null;
    },
    logoutFailure(_state) {
      //
    },
    clearUser(state) {
      state.currentUser = null;
    },
    //  Update user info
    updateUserInfo(state, action: TPayloadAction<TUser>) {
      state.currentUser = action.payload.data;
    },
  },
});

const { actions, reducer } = authSlice;

export { actions as authActions, reducer as authReducer };
