import { COMMON_MESSAGE_ERROR, OPTION_EMPTY } from "@constants/common";
import { TSelectValue } from "@models/common";
import {
  TCheckDeviceExistReq,
  TFormDeviceReq,
  TFormModalDeviceRes,
  TFormRegisterDeviceReq,
  TListDevice,
  TListDeviceReq,
  TPurposesReq,
  TPurposesRes,
  TSensorsReq,
} from "@models/device";
import { TCompanyReq } from "@models/layout";
import { deviceApi } from "@services/deviceApi";
import { layoutApi } from "@services/layoutApi";
import { openCaseApi } from "@services/openCaseApi";
import { handleShowToastError } from "@utils/helpers";
import { FormInstance } from "antd/lib";
import _ from "lodash";
import { DEVICE_CONSTANT } from "../constant";
import { EHttpStatusCode } from "types/service";
import { EModalType } from "types/others";
import { TAxiosResponse } from "types";
import { appToast } from "@utils/appToast";

const { NAME, MESSAGE } = DEVICE_CONSTANT;

function useDeviceApi() {
  // API
  const getStoresByCompany = async (
    payload: TCompanyReq,
    isModal: boolean,
    form: FormInstance,
    setStores: (val: TSelectValue[]) => void,
    setLoadingStore: (val: boolean) => void,
    setLoadingStoreModal: (val: boolean) => void,
    setLoadingCh: (val: boolean) => void,
    setChCode: (val: TSelectValue[]) => void,
    setStoresModal?: (val: TSelectValue[]) => void
  ) => {
    if (isModal) {
      setLoadingStoreModal(true);
    } else {
      setLoadingStore(true);
    }
    setLoadingCh(true);

    try {
      const response = await layoutApi.getStoresByCompany(payload);

      if (response.ok) {
        const convertStores = response.data.map((val) => ({
          value: val.id,
          label: val.storeCode + "_" + val.storeName,
          children: val.storeCode,
        }));
        setStores([OPTION_EMPTY, ...convertStores]);

        if (!isModal) {
          await getChCodeSensor(
            {
              companyCode: payload.companyCode,
              storeCode: convertStores[0].children as string,
            },
            setLoadingCh,
            setChCode,
            form
          );
          form.setFieldValue(NAME.STORE_CODE, convertStores[0].value);
        } else {
          setStoresModal?.(convertStores);
        }
      }
    } catch (error) {
      handleShowToastError(error);
    } finally {
      setLoadingStore(false);
      setLoadingStoreModal(false);
      setLoadingCh(false);
    }
  };

  const getChCodeSensor = async (
    payload: TSensorsReq,
    setLoadingCh: (val: boolean) => void,
    setChCode: (val: TSelectValue[]) => void,
    form: FormInstance
  ) => {
    setLoadingCh(true);

    try {
      const response = await openCaseApi.getChCodeSensor(payload);

      if (response.ok) {
        const convertIdCh = response.data.map((val) => ({
          value: val.chCode,
          label: val.chCode,
        }));
        setChCode([OPTION_EMPTY, ...convertIdCh]);
        form.setFieldValue(NAME.CH_CODE, convertIdCh[0].value);
      }
    } catch (error) {
      handleShowToastError(error);
    } finally {
      setLoadingCh(false);
    }
  };

  const getAllPurposes = async (
    formModal: FormInstance,
    payload: TPurposesReq,
    setPurposesModal: (val: TPurposesRes[]) => void,
    setLoadingStoreModal: (val: boolean) => void
  ) => {
    setLoadingStoreModal(true);
    try {
      const response = await openCaseApi.getAllPurposes(payload);

      if (response.ok && response.data.length) {
        const convertPurposes = response.data.map((val) => ({
          ...val,
          value: val.id,
          label: val.purposeCode + "_" + val.purposeName,
          children: val.purposeCode,
        }));
        formModal.setFieldValue([NAME.PURPOSE_CODE], response.data[0].id);
        formModal.setFieldValue([NAME.PURPOSE_MAX], response.data[0].maxTemp);
        formModal.setFieldValue([NAME.PURPOSE_MIN], response.data[0].minTemp);
        setPurposesModal(convertPurposes);
      } else {
        setPurposesModal([]);
        setLoadingStoreModal(false);
      }
    } catch (error) {
      handleShowToastError(error);
    } finally {
      setLoadingStoreModal(false);
    }
  };

  const getListDeviceSearch = async (
    payload: TListDeviceReq,
    setLoadingPage: (val: boolean) => void,
    setListDevice: (val: TListDevice[]) => void,
    handleSetSearchParams: (val: TFormDeviceReq) => void,
    setPageIndex: (val: number) => void,
    setHasNext: (val: boolean) => void
  ) => {
    setLoadingPage(true);

    try {
      const response = await deviceApi.getListDevice(payload);
      if (response.ok) {
        setListDevice(
          response.data.searchSensorResponses
            .filter((item) => !item.isDeleted)
            .map((item, index) => ({
              ...item,
              key: index + 1,
            }))
        );
        handleSetSearchParams(payload);
        setPageIndex(1);
        setHasNext(response.pagination?.hasNext as boolean);
      }
    } catch (error) {
      setListDevice([]);
      setPageIndex(0);
      setHasNext(false);
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const getListDeviceScroll = async (
    payload: TListDeviceReq,
    listDevice: TListDevice[],
    pageIndex: number,
    // loadedIds: Set<string>,
    setLoadingPage: (val: boolean) => void,
    setListDevice: (val: any) => void,
    // setLoadedIds: (val: any) => void,
    setPageIndex: (val: number) => void,
    setHasNext: (val: boolean) => void
  ) => {
    setLoadingPage(true);
    try {
      const response = await deviceApi.getListDevice(payload);
      if (response.ok) {
        setHasNext(response.pagination?.hasNext as boolean);

        if (listDevice.length !== response.pagination?.totalCount) {
          const startIndex = listDevice.length;
          setPageIndex(pageIndex + 1);
          // const filteredRecords = response.data.searchSensorResponses
          //   .filter(
          //     (record) => !loadedIds.has(record.sensorId) && !record.isDeleted
          //   )
          //   .map((item, index) => ({
          //     ...item,
          //     key: startIndex + index + 1,
          //   }));
          // setListDevice((prevData: TListDevice[]) => [
          //   ...prevData,
          //   ...filteredRecords,
          // ]);
          // setLoadedIds(
          //   (prevIds: any) =>
          //     new Set([
          //       ...prevIds,
          //       ...filteredRecords.map((record) => record.sensorId),
          //     ])
          // );
          setListDevice([
            ...listDevice,
            ...response.data.searchSensorResponses.map((item, index) => ({
              ...item,
              key: startIndex + index + 1,
            })),
          ]);
        }
      }
    } catch (error) {
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const fetchInitialApis = async (
    pageIndex: number,
    setLoadingPage: (val: boolean) => void,
    setLoadingCompany: (val: boolean) => void,
    setCompanies: (val: TSelectValue[]) => void,
    setCompaniesModal: (val: TSelectValue[]) => void,
    setStores: (val: TSelectValue[]) => void,
    setStoresModal: (val: TSelectValue[]) => void,
    setChCode: (val: TSelectValue[]) => void,
    setListDevice: (val: TListDevice[]) => void,
    setHasNext: (val: boolean) => void,
    setPageIndex: (val: number) => void,
    handleSetSearchParams: (values: TFormDeviceReq) => void,
    setManufacturersModal: (val: TSelectValue[]) => void,
    setManufacturers: (val: TSelectValue[]) => void
  ) => {
    setLoadingPage(true);
    let convertStores: TSelectValue[] = [];
    let convertChCode: TSelectValue[] = [];

    try {
      const resManufacturer = await deviceApi.getAllManufacturers();
      if (resManufacturer.ok && resManufacturer.data.length) {
        const convertManufacturers = resManufacturer.data.map((item) => ({
          label: item.code + "_" + item.name,
          value: item.id,
          children: item.code,
        }));
        setManufacturersModal(convertManufacturers);
        setManufacturers([
          OPTION_EMPTY,
          ...convertManufacturers.map((item) => ({
            ...item,
            value: item.children,
          })),
        ]);
      }

      const resCompany = await layoutApi.getCompanies();
      if (resCompany.ok) {
        const convertCompanies = resCompany.data.map((val) => ({
          value: val.id,
          label: val.companyCode + "_" + val.companyName,
          children: val.companyCode,
        }));
        setCompanies([OPTION_EMPTY, ...convertCompanies]);
        setCompaniesModal(convertCompanies);

        const resStore = await layoutApi.getStoresByCompany({
          companyCode: resCompany.data[0].companyCode,
          companyName: resCompany.data[0].companyName,
        });
        if (resStore.ok && resStore.data.length) {
          convertStores = resStore.data.map((val) => ({
            value: val.id,
            label: val.storeCode + "_" + val.storeName,
            children: val.storeCode,
          }));
          setStores([OPTION_EMPTY, ...convertStores]);
          setStoresModal(convertStores);

          const resChCode = await openCaseApi.getChCodeSensor({
            companyCode: resCompany.data[0].companyCode,
            storeCode: resStore.data[0].storeCode,
          });
          if (resChCode.ok) {
            convertChCode = resChCode.data.map((val) => ({
              value: val.chCode,
              label: val.chCode,
            }));
            setChCode([OPTION_EMPTY, ...convertChCode]);
          }

          handleSetSearchParams({
            companyId: resCompany.data[0].id,
            storeId: resStore.data[0].id,
            chCode: "",
            manufacturerCode: "",
            modelCode: "",
          });
        }

        const response = await deviceApi.getListDevice({
          companyId: resCompany.data[0].id,
          storeId: resStore.data[0].id,
          chCode: "",
          manufacturerCode: "",
          modelCode: "",
          pageIndex,
        });
        if (response.ok) {
          const _listDevice = response.data.searchSensorResponses
            .filter((item) => !item.isDeleted)
            .map((item, index) => ({
              ...item,
              key: index + 1,
            }));
          setListDevice(_listDevice);
          setPageIndex(1);
          setHasNext(response.pagination?.hasNext as boolean);
        }
      }
    } catch (error) {
      setListDevice([]);
      setPageIndex(0);
      setHasNext(false);

      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
      setLoadingCompany(false);
    }
  };

  const fetchApiRedirect = async (
    payload: TFormDeviceReq,
    pageIndex: number,
    setLoadingPage: (val: boolean) => void,
    setLoadingCompany: (val: boolean) => void,
    setCompanies: (val: TSelectValue[]) => void,
    setCompaniesModal: (val: TSelectValue[]) => void,
    setStores: (val: TSelectValue[]) => void,
    setStoresModal: (val: TSelectValue[]) => void,
    setChCode: (val: TSelectValue[]) => void,
    setListDevice: (val: TListDevice[]) => void,
    setHasNext: (val: boolean) => void,
    setPageIndex: (val: number) => void,
    handleSetSearchParams: (values: TFormDeviceReq) => void,
    setManufacturersModal: (val: TSelectValue[]) => void,
    setManufacturers: (val: TSelectValue[]) => void
  ) => {
    setLoadingPage(true);

    try {
      const resManufacturer = await deviceApi.getAllManufacturers();
      if (resManufacturer.ok && resManufacturer.data.length) {
        const convertManufacturers = resManufacturer.data.map((item) => ({
          label: item.code + "_" + item.name,
          value: item.id,
          children: item.code,
        }));
        setManufacturersModal(convertManufacturers);
        setManufacturers([
          OPTION_EMPTY,
          ...convertManufacturers.map((item) => ({
            ...item,
            value: item.children,
          })),
        ]);
      }

      const resCompany = await layoutApi.getCompanies();
      let convertStores: TSelectValue[] = [];
      let convertChCode: TSelectValue[] = [];
      if (resCompany.ok) {
        const convertCompanies = resCompany.data.map((val) => ({
          value: val.id,
          label: val.companyCode + "_" + val.companyName,
          children: val.companyCode,
        }));
        setCompanies([OPTION_EMPTY, ...convertCompanies]);
        setCompaniesModal(convertCompanies);

        const findCompany = _.find(resCompany.data, function (item) {
          return item.id === payload.companyId;
        });

        const resStore = await layoutApi.getStoresByCompany({
          companyCode: findCompany
            ? findCompany.companyCode
            : convertCompanies[0].children,
          companyName: findCompany
            ? findCompany.companyName
            : convertCompanies[0].label.split("_")[1],
        });
        if (resStore.ok && resStore.data.length) {
          convertStores = resStore.data.map((val) => ({
            value: val.id,
            label: val.storeCode + "_" + val.storeName,
            children: val.storeCode,
          }));
          setStores([OPTION_EMPTY, ...convertStores]);
          setStoresModal(convertStores);

          const findStore = _.find(resStore.data, function (item) {
            return item.id === payload.storeId;
          });

          const resChCode = await openCaseApi.getChCodeSensor({
            companyCode: findCompany
              ? findCompany.companyCode
              : resCompany.data[0].companyCode,
            storeCode: findStore
              ? findStore.storeCode
              : resStore.data[0].storeCode,
          });
          if (resChCode.ok) {
            convertChCode = resChCode.data.map((val) => ({
              value: val.chCode,
              label: val.chCode,
            }));
            setChCode([OPTION_EMPTY, ...convertChCode]);
          }
        }

        handleSetSearchParams({
          companyId: payload.companyId || resCompany.data[0].id,
          storeId: payload.storeId || resStore.data[0].id,
          chCode: payload.chCode,
          manufacturerCode: payload.manufacturerCode,
          modelCode: payload.modelCode,
        });

        const response = await deviceApi.getListDevice({
          ...payload,
          pageIndex,
        });
        if (response.ok) {
          const _listDevice = response.data.searchSensorResponses
            .filter((item) => !item.isDeleted)
            .map((item, index) => ({
              ...item,
              key: index + 1,
            }));
          setListDevice(_listDevice);
          setPageIndex(1);
          setHasNext(response.pagination?.hasNext as boolean);
        }
      }
    } catch (error) {
      setListDevice([]);
      setPageIndex(0);
      setHasNext(false);

      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
      setLoadingCompany(false);
    }
  };

  const registrationDevice = async (
    params: TFormRegisterDeviceReq,
    handleOkModalSuccess: (data: any) => void,
    setLoadingPage: (val: boolean) => void,
    handleModalFail: () => void,
    handleCommonError: () => void
  ) => {
    setLoadingPage(true);

    try {
      const response = await deviceApi.registrationDevice(params);
      if (response.ok) {
        handleOkModalSuccess(response.data);
      }
    } catch (error: any) {
      handleCommonError();

      if (
        error?.status === EHttpStatusCode.BAD_REQUEST &&
        error?.data?.errors?.[0]?.description
      ) {
        handleModalFail();
        return;
      }
      const err = error as TAxiosResponse;
      if (err.status === EHttpStatusCode.FORBIDDEN) {
        appToast.error({ message: COMMON_MESSAGE_ERROR });
        return;
      }
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const updateDevice = async (
    params: any,
    handleOkModalSuccess: (data: any) => void,
    setLoadingPage: (val: boolean) => void,
    handleModalFail: () => void,
    handleCommonError: () => void
  ) => {
    setLoadingPage(true);

    try {
      const response = await deviceApi.updateDevice(params);
      if (response.ok) {
        handleOkModalSuccess(response.data);
      }
    } catch (error: any) {
      handleCommonError();

      if (
        error?.status === EHttpStatusCode.BAD_REQUEST &&
        error?.data?.errors?.[0]?.description
      ) {
        handleModalFail();
        return;
      }
      const err = error as TAxiosResponse;
      if (err.status === EHttpStatusCode.FORBIDDEN) {
        appToast.error({ message: COMMON_MESSAGE_ERROR });
        return;
      }
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const detailDevice = async (
    payload: string,
    companies: TSelectValue[],
    setStoresModal: (val: TSelectValue[]) => void,
    setPurposesModal: (val: TPurposesRes[]) => void,
    handleSetFormValues: (val: TFormModalDeviceRes) => void,
    setLoadingModal: (val: boolean) => void
  ) => {
    setLoadingModal(true);
    try {
      const response = await deviceApi.getDetailDevice(payload);

      if (response.ok) {
        const { data } = response;
        const findCompany = companies.find(
          (item) => item.value === data.companyId
        );
        if (findCompany) {
          const [responseStore, responsePurpose] = await Promise.allSettled([
            layoutApi.getStoresByCompany({
              companyCode: findCompany.children as string,
              companyName: findCompany.label.split("_")[1],
            }),
            openCaseApi.getAllPurposes({
              companyCode: findCompany.children as string,
              companyName: findCompany.label.split("_")[1],
            }),
          ]);
          // Handle list store
          if (responseStore.status === "fulfilled") {
            const { data, ok } = responseStore.value;
            if (ok && data.length) {
              const convertStores = data.map((val) => ({
                value: val.id,
                label: val.storeCode + "_" + val.storeName,
                children: val.storeCode,
              }));
              setStoresModal(convertStores);
            } else {
              setStoresModal([]);
            }
          } else {
            setStoresModal([]);
          }
          // Handle list purpose
          if (responsePurpose.status === "fulfilled") {
            const { data, ok } = responsePurpose.value;
            if (ok && data.length) {
              const convertPurposes = data.map((val) => ({
                ...val,
                value: val.id,
                label: val.purposeCode + "_" + val.purposeName,
                children: val.purposeCode,
              }));
              setPurposesModal(convertPurposes);
            } else {
              setPurposesModal([]);
            }
          } else {
            setPurposesModal([]);
          }

          handleSetFormValues({ ...data, id: payload });
        } else {
          setPurposesModal([]);
          setStoresModal([]);
        }
      }
    } catch (error) {
      handleShowToastError(error);
    } finally {
      setLoadingModal(false);
    }
  };

  const deleteDevice = async (
    payload: string,
    listDevice: TListDevice[],
    setListDevice: (val: TListDevice[]) => void,
    setLoadingPage: (val: boolean) => void,
    setModalSuccess: (val: boolean) => void,
    setModalType: (val: EModalType | null | string) => void,
    handleCloseModalDelete: () => void,
    handleDeleteFail: () => void
  ) => {
    setLoadingPage(true);

    try {
      const response = await deviceApi.deleteDevice(payload);
      if (response.ok) {
        setListDevice(
          [
            ..._.filter(listDevice, function (item) {
              return item.sensorId !== payload;
            }),
          ]
            .filter((item) => !item.isDeleted)
            .map((item, index) => ({ ...item, key: index + 1 }))
        );

        setModalSuccess(true);
        setModalType(EModalType.SUCCESS_DELETE);
        handleCloseModalDelete();
      }
    } catch (error: any) {
      if (
        error?.status === EHttpStatusCode.BAD_REQUEST &&
        error?.data?.errors?.[0]?.description === "Not found sensor"
      ) {
        handleDeleteFail();
        return;
      }
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const checkExistDevice = async (
    payload: TCheckDeviceExistReq,
    formModal: FormInstance,
    setModalType: (val: EModalType | null | string) => void,
    setLoadingExist: (val: boolean) => void
  ) => {
    setLoadingExist(true);
    try {
      const response = await deviceApi.checkDeviceExist(payload);
      if (response.ok) {
        setModalType(EModalType.CONFIRM_REGISTRATION);
      }
    } catch (error: any) {
      if (
        error.status === EHttpStatusCode.BAD_REQUEST &&
        error?.data?.errors.length &&
        error?.data?.errors[0].description === "Sensor already exists"
      ) {
        formModal.setFields([
          {
            name: [NAME.CH_MODAL],
            errors: [MESSAGE.DATA_EXIST],
          },
        ]);
        return;
      }

      handleShowToastError(error);
    } finally {
      setLoadingExist(false);
    }
  };

  // EFFECT
  const effectCompany = (
    companyParam: string,
    form: FormInstance,
    companies: TSelectValue[]
  ) => {
    if (companyParam) {
      form.setFieldValue(NAME.COMPANY_CODE, companyParam);
    } else {
      if (companies.length) {
        form.setFieldValue(NAME.COMPANY_CODE, companies[1].value);
      } else {
        form.setFieldValue(NAME.COMPANY_CODE, "");
      }
    }
  };

  const effectStore = (
    storeParam: string,
    form: FormInstance,
    stores: TSelectValue[]
  ) => {
    if (storeParam) {
      form.setFieldValue(NAME.STORE_CODE, storeParam);
    } else {
      if (stores.length > 1) {
        form.setFieldValue(NAME.STORE_CODE, stores[1].value);
      } else {
        form.setFieldValue(NAME.STORE_CODE, undefined);
      }
    }
  };

  const effectChCode = (chCodeParam: string, form: FormInstance) => {
    if (chCodeParam) {
      form.setFieldValue(NAME.CH_CODE, chCodeParam);
    }
  };

  const effectManufacturer = (
    manufacturerCodeParam: string,
    form: FormInstance
  ) => {
    if (manufacturerCodeParam) {
      form.setFieldValue(NAME.MANUFACTURER_CODE, manufacturerCodeParam);
    }
  };

  return {
    handler: {
      getListDeviceSearch,
      getListDeviceScroll,
      getStoresByCompany,
      getChCodeSensor,
      getAllPurposes,

      fetchInitialApis,
      fetchApiRedirect,

      deleteDevice,
      detailDevice,
      registrationDevice,
      updateDevice,
      checkExistDevice,
    },
    effect: {
      effectCompany,
      effectStore,
      effectChCode,
      effectManufacturer,
    },
  };
}

export default useDeviceApi;
