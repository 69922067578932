import { Modal } from "antd";
import styled from "styled-components";
import { MATH_MIN, MATH_MIN_DECIMAL, MIN_BATTERY, RADIO_OPT } from "./constant";

function renderBatteryLevel(value: number) {
  return `${Math.min(MATH_MIN, value * MATH_MIN_DECIMAL)}%`;
}

export const OpenCaseWrapper = styled.div<{
  radiooption: number;
  batterylevel: number;
}>`
  .open-case_header {
    display: flex;
    align-items: center;
    margin-bottom: 9px;

    button {
      width: 48px;
      height: 48px;
      background-color: #edeff1;
      border-radius: 100%;
      border: none;
      position: relative;
      cursor: pointer;

      svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    h1 {
      margin-left: 16px;
      line-height: 46px;
    }
  }

  .ant-select-arrow {
    inset-inline-end: 16px;
  }

  .open-case_form {
    form {
      .open-case_form-search {
        display: flex;
        column-gap: 12px;
        margin-bottom: 16px;

        .ant-form-item {
          label {
            color: #1b1b1b;
          }
        }

        .ant-btn-default {
          width: 56px;
          height: 56px;
          background: #d7e7ff;
          border: none;
          margin-top: 28px;
        }

        .ant-select-selector {
          border-radius: 4px;
        }

        .ant-select-disabled .ant-select-selector {
          background-color: var(--color-border-secondary);
        }

        .ant-input-lg {
          height: 56px;
          padding: 16px;

          font-size: var(--font-size-xl);
          font-weight: var(--font-medium);
          line-height: 24px;
          border-radius: 4px;
        }
      }

      .open-case_form-result {
        background-color: var(--background-header-table);
        box-shadow: 0px 0px 4px 0px #05286114;

        .input-ellipsis input {
          text-overflow: ellipsis;
        }

        .ant-form-item {
          width: 388px;
          margin-bottom: 12px;
        }

        .open-case_form-result-top,
        .open-case_form-result-bottom {
          display: flex;
          align-items: center;
          column-gap: 20px;
          padding: 0 20px;

          .ant-input-disabled,
          .ant-picker-input input[disabled],
          .ant-picker-disabled {
            color: var(--color-text-primary);
            background-color: var(--color-white);
            cursor: default;
          }

          .ant-input-lg {
            height: 56px;
            padding: 16px;

            font-size: var(--font-size-xl);
            font-weight: var(--font-medium);
            line-height: 24px;
            border-radius: 4px;
          }

          .open-case_form-hour {
            .ant-picker {
              width: 100%;
              height: 56px;
              padding: 16px;
              font-size: var(--font-size-xl);
              border-radius: 4px;

              input {
                font-size: var(--font-size-xl);
                font-weight: var(--font-medium);
                line-height: 24px;
                text-align: center;
              }

              input::placeholder {
                text-align: center;
                font-size: var(--font-size-xl);
                font-weight: var(--font-normal);
                color: var(--color-secondary);
              }
            }
          }

          .ant-form-item-horizontal {
            margin-top: 12px;
            width: 130px !important;

            .ant-row {
              align-items: center;

              .ant-form-item-label {
                padding: 0;
                margin-left: 8px;

                label::after {
                  display: none;
                }
              }

              .ant-form-item-control {
                margin-left: 12px;

                span {
                  width: 80px;
                  padding: 0 16px;
                  border-radius: 16px;
                  background-color: #f1fff3;
                  color: #12b76a;
                  font-weight: var(--font-bold);
                }

                .ant-input {
                  margin-left: 8px;
                  padding: 0;
                  width: 100px;
                  font-size: var(--font-size-base);
                  font-weight: var(--font-normal);
                  border: none;
                  background: transparent;
                }
              }
            }
          }
        }
        .open-case_form-result-top {
          padding-top: 12px;

          .open-case_battery-container {
            display: flex;
            align-items: center;
            column-gap: 12px;
            margin-top: -20px;

            .open-case_battery:before {
              position: absolute;
              content: "";
              height: 12px;
              width: 4px;
              background-color: ${(props) =>
                props.batterylevel < MIN_BATTERY ? "#D92D20" : "#12b76a"};
              margin: auto;
              top: 0;
              bottom: 0;
              right: -6px;
              border-radius: 0 2px 2px 0;
            }

            .open-case_battery {
              box-sizing: content-box;
              height: 20px;
              width: 40px;
              border: 0.5px solid
                ${(props) =>
                  props.batterylevel < MIN_BATTERY ? "#D92D20" : "#12b76a"};
              border-radius: 2px;
              position: relative;

              .open-case_battery-charge {
                position: absolute;
                height: 16px;
                width: ${(props) => renderBatteryLevel(props.batterylevel)};
                background-color: ${(props) =>
                  props.batterylevel < MIN_BATTERY ? "#D92D20" : "#12b76a"};
                top: 2px;
                left: 2px;
                border-radius: 2px;
              }
              .open-case_battery-charge-level {
                position: absolute;
                top: 2px;
                left: 2px;
                font-size: var(--font-size-xs);
                font-weight: var(--font-normal);
              }
            }
          }
        }
      }

      .ant-form-item {
        width: 290px;

        .ant-form-item-label {
          padding: 0 0 4px;

          label {
            font-weight: var(--font-normal);
          }
        }

        .ant-select-selection-item {
          font-size: var(--font-size-xl);
          font-weight: var(--font-medium);
          line-height: 24px;
        }

        .ant-select-single {
          height: 56px;
        }

        .ant-select-selector {
          padding: 16px;
        }
      }
    }
  }

  .open-case_table {
    margin-top: 32px;

    h2 {
      font-size: var(--font-size-xl);
      font-weight: var(--font-medium);
      color: var(--color-header-table);
      line-height: 24px;
      margin-bottom: 16px;
    }

    .open-case_table-first {
      .ant-table-thead {
        .ant-table-cell {
          border-bottom: 2px solid var(--color-border-secondary);
        }
      }

      .ant-table-placeholder {
        background: #f9fafb;

        .ant-table-cell {
          .ant-empty-image {
            height: 115px !important;
          }
        }
      }
    }

    .open-case_table-second {
      margin-top: 42px;

      form {
        .ant-radio-group {
          display: block;

          .ant-radio-inner {
            background-color: var(--color-white);
          }

          .ant-radio-inner::after {
            background-color: var(--color-sidebar-active);
            inset-block-start: 38%;
            inset-inline-start: 38%;
            width: 22px;
            height: 22px;
          }

          .ant-radio-wrapper > span:last-of-type {
            width: 100%;
            padding-right: 0px;
            padding-left: 12px;
            gap: 12px;
          }

          .ant-select-disabled .ant-select-selector {
            background-color: var(--color-border-secondary);
          }
        }

        .open-case_table-second-radio,
        .open-case_table-second-item {
          display: flex;
          align-items: center;
          column-gap: 16px;
        }

        .open-case_table-second-item {
          .open-case_table-second-select {
            width: 320px;

            .ant-select-lg {
              height: 56px;
              border-radius: 4px;
            }

            .ant-select-selection-item {
              font-size: var(--font-size-base);
              font-weight: var(--font-bold);
              line-height: 24px;
            }
          }

          .ant-form-item {
            margin-bottom: 8px;

            .open-case_table-second-input {
              display: flex;
              width: 190px;
              height: 56px;

              padding: 14px 16px;
              border: 1px solid var(--color-border-primary);
              border-radius: 4px;
              background-color: ${(props) =>
                props.radiooption === RADIO_OPT
                  ? "var(--color-border-secondary)"
                  : "unset"};

              .ant-divider-vertical {
                height: 28px;
                border-inline-start: 1px solid var(--color-border-primary);
                margin-inline-start: 0px;
              }

              .open-case_table-second-input-label {
                width: 75px;
                color: ${(props) =>
                  props.radiooption === RADIO_OPT
                    ? "var(--color-secondary)"
                    : "var(--color-text-primary)"};
                font-weight: var(--font-normal);
                line-height: 24px;
              }

              .ant-input {
                width: 46px;
                padding: 0;
                border: none;
                border-radius: 4px;
                font-size: var(--font-size-base);

                color: ${(props) =>
                  props.radiooption === RADIO_OPT
                    ? "var(--color-secondary)"
                    : "unset"};
                background: ${(props) =>
                  props.radiooption === RADIO_OPT
                    ? "var(--color-border-secondary)"
                    : "unset"};
                cursor: ${(props) =>
                  props.radiooption === RADIO_OPT ? "not-allowed" : "unset"};
              }

              .ant-input:focus-within {
                box-shadow: none;
              }
            }
          }
        }

        .open-case_table-btn {
          display: flex;
          justify-content: space-between;
          margin-top: 16px;

          p {
            color: #ff4d4f;
          }

          .ant-btn-primary {
            display: block;
            width: 160px;
            height: 48px;
            border-radius: 7px;
            margin-left: auto;

            span {
              font-weight: var(--font-bold);
            }
          }
        }
      }

      table {
        .ant-table-thead {
          .ant-table-cell {
            font-size: var(--font-size-xl);
            font-weight: var(--font-bold);
            border-bottom: 4px solid var(--color-border-secondary);
          }
        }

        .ant-table-tbody {
          .ant-table-cell {
            padding: 16px;

            .ant-form-item {
              width: 100%;
              margin-bottom: 0;

              .ant-input-number-outlined {
                width: 100%;
                font-size: var(--font-size-base);
                border: none;
                background: transparent;

                .ant-input-number-input {
                  text-align: center;
                  font-weight: var(--font-medium);
                }
              }

              .ant-input-number-outlined.ant-input-number-disabled {
                color: rgba(0, 0, 0, 0.25);
                background-color: rgba(0, 0, 0, 0.04);
                border-color: #d0d5dd;
                box-shadow: none;
                cursor: not-allowed;
                opacity: 1;
              }

              .ant-input-number-outlined:focus-within {
                box-shadow: none;
              }
            }
          }

          .ant-table-row:first-child .ant-table-cell:last-child {
            padding: 6px 8px;

            textarea {
              max-height: 152px;
              padding: 8px 16px;
              font-weight: var(--font-medium);
              color: var(--color-text-primary);
              resize: none;
            }

            .ant-input::placeholder {
              color: var(--color-secondary);
              font-weight: var(--font-medium);
            }

            .ant-input-suffix {
              .ant-input-data-count {
                bottom: 0;
                display: inline-block;
                margin-right: 8px;
              }
            }
          }
        }
      }
    }

    .open-case_table-third {
      margin-top: 24px;

      .open-case_table-third-select {
        display: flex;
        align-items: center;
        width: 320px;
        height: 56px;

        margin-bottom: 16px;
        padding: 14px 16px;
        border: 1px solid var(--color-border-primary);
        border-radius: 4px;

        .ant-divider-vertical {
          height: 28px;
          border-inline-start: 1px solid var(--color-border-primary);
          margin-inline-start: 0px;
        }

        .open-case_table-third-select-label {
          width: 104px;
          font-weight: var(--font-normal);
          line-height: 24px;
        }

        .ant-picker-outlined {
          border: none;
          padding: 0;

          .ant-picker-input input {
            font-size: var(--font-size-xl);
            font-weight: var(--font-bold);
            line-height: 24px;
          }
        }

        .ant-picker-outlined:focus-within {
          box-shadow: none;
        }
      }

      .open-case_table-hour {
        .ant-table-tbody {
          .ant-table-cell:not(:first-child) {
            padding: 0;
            line-height: 56px;

            .is_active,
            .lower,
            .upper {
              display: inline-block;
              width: 100%;
            }

            .is_active {
              background-color: #ccc !important;
            }

            .lower {
              background-color: #d7e7ff !important;
            }
            .upper {
              background-color: #fecdca !important;
            }
          }
        }
      }

      .ant-table-placeholder {
        background: #f9fafb;

        .ant-table-cell {
          .ant-empty-image {
            height: 115px !important;
          }
        }
      }

      .ant-table-thead {
        .ant-table-cell {
          border-bottom: 2px solid var(--color-border-secondary);
        }
        tr th:not(:first-child) {
          font-weight: var(--font-medium);
        }

        tr th:first-child {
          font-weight: var(--font-normal);
        }
      }

      .ant-table-tbody {
        tr td:not(:first-child) {
          font-weight: var(--font-medium);
        }

        tr td:first-child {
          font-weight: var(--font-normal);
        }
      }

      .open-case_table-third-chart {
        margin-top: 28px;
        position: relative;

        .open-case_table-third-chart-title {
          font-weight: var(--font-normal);
          margin: 32px 16px 16px;
        }

        .ant-table {
          .ant-table-container {
            border-start-start-radius: 0px;
            border-start-end-radius: 0px;

            .ant-table-thead {
              .ant-table-cell:first-child {
                color: var(--color-header-table);
                font-weight: var(--font-bold);
                font-size: var(--font-size-xl);
              }

              .ant-table-cell {
                background-color: var(--background-header-table);
              }

              .ant-table-cell:not(:first-child) {
                padding: 4px 6px;

                .open-case_table-third-chart-custom {
                  display: flex;
                  flex-direction: column;
                  align-items: center;

                  span:first-child {
                    font-weight: var(--font-medium);
                    color: var(--color-white);
                    background-color: var(--color-secondary);
                    padding: 3px 6px;
                    border-radius: 8px;
                    width: 34px;
                    height: 34px;
                    line-height: 28px;
                  }

                  span:last-child {
                    margin-top: 6px;
                    font-weight: var(--font-normal);
                    padding: 4px 7px;
                    border-radius: 2px;
                  }
                }

                .open-case_table-third-chart-yesterday {
                  font-weight: var(--font-medium);
                  background-color: var(--color-sidebar-active);
                  border-radius: 8px;
                  padding: 12px;
                  color: var(--color-white);
                  width: 42px;
                  height: 42px;
                  margin: 0 auto;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }

            .ant-table-tbody {
              .ant-table-cell {
                font-weight: var(--font-medium);
              }
            }
          }
        }

        .open-case_table-third-chart-line {
          .recharts-tooltip-cursor {
            display: none;
          }

          .open-case_chart-custom-tooltip {
            padding: 8px 24px;
            background-color: var(--color-white);
            border-radius: 8px;
            border: 2px solid var(--color-border-secondary);
            text-align: center;
            position: relative;

            label {
              color: var(--color-secondary);
              font-weight: var(--font-medium);
              font-size: var(--font-size-sm);
              margin-bottom: 8px;
            }

            div {
              display: flex;
              align-items: center;

              p {
                z-index: 4;
                margin-left: 4px;
                font-size: var(--font-size-xl);
                font-weight: var(--font-bold);
                color: var(--color-text-secondary);
              }
            }
          }

          .open-case_chart-custom-tooltip::before {
            content: "";
            position: absolute;
            top: 100%;
            left: 38%;
            width: 25px;
            border: 1px solid var(--color-white);
            z-index: 2;
          }

          .open-case_chart-custom-tooltip::after {
            content: "";
            position: absolute;
            top: 85%;
            left: 40%;
            width: 20px;
            height: 20px;
            border-top: unset;
            border-right: unset;
            border-bottom: 2px solid var(--color-border-secondary);
            border-left: 2px solid var(--color-border-secondary);
            background-color: var(--color-white);
            transform: rotate(-45deg);
            z-index: 3;
          }
        }
      }
    }
  }
`;

export const AlarmSettingWrapper = styled(Modal)`
  .ant-modal-content {
    padding: 0;
    background: var(--color-white);
    box-shadow: 0px 2px 8px 0px rgba(5, 40, 97, 0.08);

    border-radius: 12px;

    .ant-modal-close {
      inset-inline-end: 19px;
    }
  }

  .ant-modal-header {
    margin-bottom: 0;
    padding: 16px 24px;
    background: none;

    .ant-modal-title {
      font-weight: var(--font-bold);
      line-height: 24px;
    }
  }

  .ant-modal-footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    padding: 12px 24px 24px;
    margin-top: 0;

    .ant-btn {
      width: 174px;
      height: 48px;
      box-shadow: none;
      font-weight: var(--font-bold);
      margin-inline-start: 0px !important;
    }

    .ant-btn-default {
      color: var(--color-primary);
      border-color: var(--color-primary);
    }
  }

  .ant-modal-body {
    padding: 0 24px;
    height: 211px;
    border-bottom: 1px solid #f1f3f7;
    border-top: 1px solid #f1f3f7;
    text-align: center;

    .temperature-report_message-approve {
      margin-top: 8px;
      font-weight: var(--font-medium);
      font-size: var(--font-size);
      line-height: 20px;
    }
  }
`;
