import { LayoutAdmin } from "@layouts";
import { FC, ReactElement } from "react";
import { Outlet } from "react-router-dom";

type TProps = {
  children?: ReactElement;
};

const PrivateRoute: FC<TProps> = ({ children }) => {
  return <LayoutAdmin>{children ? children : <Outlet />}</LayoutAdmin>;
};

export { PrivateRoute };
