import { useAppDispatch } from "@config/hooks";
import { TLoginReq } from "@models/login";
import { authActions } from "@redux/slices/authSlice";
import { Form, InputRef } from "antd";
import { useEffect, useRef, useState } from "react";
import { ValidateErrorEntity } from "types";
import { LOGIN_CONSTANT, REGEX_ID, REGEX_PASSWORD } from "../constant";
import { ELoginFailResCode } from "types/service";
import { appToast } from "@utils/appToast";
import { COMMON_MESSAGE_ERROR } from "@constants/common";
import { useNavigate } from "react-router-dom";
import { adminPaths } from "routers/routes";

const { MESSAGE, NAME } = LOGIN_CONSTANT;

export default function useLoginPage() {
  const [messageError, setMessageError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();
  const idWatch = Form.useWatch(NAME.USERNAME, form);
  const passwordWatch = Form.useWatch(NAME.PASSWORD, form);

  const navigate = useNavigate();

  const idRef = useRef<InputRef>(null);

  const dispatch = useAppDispatch();

  const handleLoginError = (code: ELoginFailResCode) => {
    idRef.current?.focus();
    switch (code) {
      case ELoginFailResCode.DEACTIVE_USER:
      case ELoginFailResCode.INVALID_PASSWORD_FORMAT:
      case ELoginFailResCode.INVALID_USERNAME_FORMAT:
      case ELoginFailResCode.NOT_EXIST_USER:
      case ELoginFailResCode.WRONG_PASSWORD:
        setMessageError(MESSAGE.WRONG_INFO);
        return;
      default:
        appToast.error({ message: COMMON_MESSAGE_ERROR });
        return;
    }
  };

  const handleSubmitFormSuccess = (values: TLoginReq) => {
    setMessageError("");
    // Check validate ID & Password
    if (
      !REGEX_ID.test(values.username) ||
      !REGEX_PASSWORD.test(values.password)
    ) {
      setMessageError(MESSAGE.WRONG_INFO);
      idRef.current?.focus();
      return;
    }

    // If pass, call api login
    setIsLoading(true);
    dispatch(
      authActions.login({
        data: values,
        onSuccess: () =>
          navigate(adminPaths.temperatureReport, { replace: true }),
        onError: handleLoginError,
        onFinal: () => {
          setIsLoading(false);
        },
      })
    );
  };

  const handleSubmitFormFail = ({
    errorFields,
  }: ValidateErrorEntity<TLoginReq>) => {
    const firstFieldErrorName = errorFields[0].name;
    form.getFieldInstance(firstFieldErrorName)?.focus();
  };

  useEffect(() => {
    idRef.current?.focus();
  }, []);

  return {
    state: {
      messageError,
      isLoading,
      form,
      idWatch,
      passwordWatch,
      idRef,
    },
    handler: {
      handleSubmitFormFail,
      handleSubmitFormSuccess,
    },
  };
}
