import styled from "styled-components";
import { Header } from "antd/lib/layout/layout";
import { Modal } from "antd";

export const HeaderStyled = styled(Header)`
  padding: 0px 16px;
  z-index: var(--header-z-index);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--color-white);
  border-bottom: 1px solid #dee4e9;

  display: flex;
  align-items: center;
`;

export const TopBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .top-bar-left {
    display: flex;

    img {
      width: 173px;
      height: 32px;
    }
  }

  .top-bar-right {
    display: flex;
  }

  @media screen and (max-width: 576px) {
    .top-bar-left {
      .title {
        font-size: 20px;
      }
    }
  }
`;

export const DropdownWrapper = styled.div`
  cursor: pointer;

  .ant-dropdown-trigger {
    display: flex;
    align-items: center;

    .ant-image {
      display: flex;
    }

    .avatar-img {
      width: 40px;
      height: 40px;
      border: 0 solid #e5e5e5;
      padding: 8px;
      object-fit: contain;
      border-radius: 50%;
      background-color: var(--color-primary);
    }

    .avatar-name {
      font-weight: 400;
      margin-left: 8px;
      color: var(--color-text-primary);
    }
  }
`;

export const ModalLogoutWrapper = styled(Modal)`
  .ant-modal-content {
    padding: 0;
    background: var(--color-white);
    box-shadow: 0px 2px 8px 0px rgba(5, 40, 97, 0.08);
    border-radius: 12px;
  }

  .ant-modal-header {
    margin-bottom: 0;
    padding: 16px 24px;
    background: none;

    .ant-modal-title {
      font-weight: var(--font-bold);
      font-size: var(--font-size-base);
      line-height: 24px;
      color: #074173;
    }
  }

  .ant-modal-footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    padding: 12px 24px 24px;
    margin-top: 0;

    .ant-btn {
      width: 176px;
      height: 48px;
      box-shadow: none;
      font-weight: var(--font-bold);
      margin-inline-start: 0px !important;
    }

    .ant-btn-default {
      color: var(--color-primary);
      border-color: var(--color-primary);
    }
  }

  .ant-modal-body {
    padding: 12px 0 24px;
    height: 227px;
    border-bottom: 1px solid #f1f3f7;
    border-top: 1px solid #f1f3f7;
    text-align: center;

    .logout-message {
      margin-top: 36px;
      font-weight: var(--font-medium);
      font-size: var(--font-size-sm);
      line-height: 20px;
    }
  }
`;
