import { LoadingOutlined } from "@ant-design/icons";
import {
  ArrowSelect,
  IconPlusRegister,
  LabelForm,
  LoadingCommon,
  TableList,
} from "@components";
import { images } from "@constants";
import { PLACEHOLDER_INPUT, PLACEHOLDER_SELECT } from "@constants/common";
import { ArrowLeftIcon, SearchIcon } from "@constants/svgs";
import { Button, Form, Input, Select } from "antd";
import { EModalType } from "types/others";
import {
  HEIGHT_TABLE_SCROLL,
  MAX_LENGTH_255,
  MAX_LENGTH_6,
  MAX_LENGTH_8,
  USER_CONSTANT,
} from "./constant";
import useUserPage from "./hooks/useUserPage";
import { ModalDeleteWrapper, ModalWrapper, UserWrapper } from "./UserStyled";

const { TITLE, LABEL, NAME, MESSAGE } = USER_CONSTANT;

function UserPage() {
  const { state, handler } = useUserPage();

  const FIELDS_CONFIG = {
    USERNAME: { required: false, label: LABEL.ID, name: NAME.USERNAME },
    NAME: { required: false, label: LABEL.USERNAME, name: NAME.NAME },
    COMPANY: { required: false, label: LABEL.COMPANY, name: NAME.COMPANY },
    STORE: { required: false, label: LABEL.STORE, name: NAME.STORE },
    ROLE: { required: false, label: LABEL.ROLE, name: NAME.ROLE },

    FORM_USER_ID: {
      required: false,
      label: <LabelForm label={LABEL.USER_ID} />,
      name: NAME.MODAL_USERNAME,
    },
    FORM_USERNAME: {
      required: false,
      label: LABEL.USER_NAME,
      name: NAME.MODAL_NAME,
    },
    FORM_PASSWORD: {
      required: false,
      label:
        state.modalType === EModalType.REGISTRATION ||
        state.modalType === EModalType.CONFIRM_REGISTRATION ? (
          <LabelForm label={LABEL.PASSWORD} />
        ) : (
          LABEL.PASSWORD
        ),
      name: NAME.MODAL_PASSWORD,
    },
    FORM_USER_COMPANY: {
      required: false,
      label: <LabelForm label={LABEL.USER_COMPANY} />,
      name: NAME.MODAL_COMPANY_CODE,
    },
    FORM_USER_STORE: {
      required: false,
      label: <LabelForm label={LABEL.USER_STORE} />,
      name: NAME.MODAL_STORE_CODE,
    },
    FORM_USER_ROLE: {
      required: false,
      label: <LabelForm label={LABEL.USER_ROLE} />,
      name: NAME.MODAL_ROLE,
    },
  };

  return (
    <LoadingCommon loading={state.loadingPage}>
      <UserWrapper listdata={state.listUser.length}>
        <div className="user-page_header">
          <h1>{TITLE.USER_LIST}</h1>
          <Button
            onClick={handler.handleOpenModalRegistration}
            type="primary"
            icon={<IconPlusRegister />}
            disabled={state.disableBtnOther}
          >
            {TITLE.BTN_REGISTRATION}
          </Button>
        </div>

        <div className="user-page_form">
          <Form
            onFinish={handler.handleFinish}
            form={state.form}
            layout="vertical"
          >
            {/* ID */}
            <Form.Item {...FIELDS_CONFIG.USERNAME}>
              <Input
                placeholder={PLACEHOLDER_INPUT}
                allowClear={false}
                size="large"
              />
            </Form.Item>
            {/* USERNAME */}
            <Form.Item {...FIELDS_CONFIG.NAME}>
              <Input
                placeholder={PLACEHOLDER_INPUT}
                allowClear={false}
                size="large"
              />
            </Form.Item>
            {/* ROLE */}
            <Form.Item {...FIELDS_CONFIG.ROLE}>
              <Select
                placeholder={PLACEHOLDER_SELECT}
                allowClear={false}
                onChange={(val) => handler.handleChangeRole(val)}
                size="large"
                options={state.roles}
                suffixIcon={<ArrowSelect />}
              />
            </Form.Item>
            {/* COMPANY */}
            <Form.Item {...FIELDS_CONFIG.COMPANY}>
              <Select
                placeholder={PLACEHOLDER_SELECT}
                allowClear={false}
                size="large"
                options={state.companies}
                onChange={(val) => handler.handleChangeCompany(val)}
                suffixIcon={
                  state.loadingCompany ? (
                    <LoadingOutlined spin={state.loadingCompany} />
                  ) : (
                    <ArrowSelect />
                  )
                }
                disabled={state.disableCompany}
              />
            </Form.Item>
            {/* STORE */}
            <Form.Item {...FIELDS_CONFIG.STORE}>
              <Select
                showSearch
                optionFilterProp="label"
                placeholder={PLACEHOLDER_SELECT}
                allowClear={false}
                disabled={state.disableStore}
                size="large"
                options={state.stores}
                suffixIcon={
                  state.loadingStore ? (
                    <LoadingOutlined spin={state.loadingStore} />
                  ) : (
                    <ArrowSelect />
                  )
                }
              />
            </Form.Item>

            <Button htmlType="submit" icon={<SearchIcon />} />
          </Form>
        </div>

        <TableList
          tableRef={state.tblRef}
          image={images.noResult}
          columns={state.columns}
          handleScroll={handler.handleScroll}
          heightTableScroll={HEIGHT_TABLE_SCROLL}
          listData={state.listUser}
          messageNoData={MESSAGE.NO_DATA}
          rowKey={state.rowKey}
          borderColor="var(--color-border-secondary)"
        />
      </UserWrapper>

      <ModalWrapper
        width={800}
        title={
          <>
            {state.modalType === EModalType.CONFIRM_REGISTRATION && (
              <button
                onClick={() => handler.setModalType(EModalType.REGISTRATION)}
              >
                <ArrowLeftIcon />
              </button>
            )}
            {state.modalType === EModalType.CONFIRM_UPDATE && (
              <button onClick={() => handler.setModalType(EModalType.UPDATE)}>
                <ArrowLeftIcon />
              </button>
            )}
            <span>{state.renderTextModal.title}</span>
          </>
        }
        closeIcon={false}
        maskClosable={false}
        okText={state.renderTextModal.okText}
        cancelText={TITLE.BTN_CANCEL}
        destroyOnClose={true}
        open={state.modalOpen}
        centered
        onCancel={() => {
          handler.handleCloseModal();
        }}
        loading={state.loadingModal}
        confirmLoading={state.loadingExist}
        onOk={() => {
          if (state.modalType === EModalType.DETAIL) {
            handler.handleCloseModal();
          } else if (state.modalType === EModalType.UPDATE) {
            handler.handleValidateForm();
          } else if (state.modalType === EModalType.CONFIRM_REGISTRATION) {
            handler.handleOpenModalConfirmFinal();
          } else if (state.modalType === EModalType.CONFIRM_UPDATE) {
            handler.handleOpenModalConfirmFinal();
          } else {
            handler.handleValidateForm();
          }
        }}
        okButtonProps={{
          style: {
            width: state.modalType === EModalType.DETAIL ? "100%" : "368px",
          },
          disabled: state.disableBtnNext,
        }}
        cancelButtonProps={{
          style: {
            display: state.modalType === EModalType.DETAIL ? "none" : "block",
          },
        }}
      >
        <Form
          layout="vertical"
          form={state.formModal}
          preserve={false}
          disabled={state.disableFields}
        >
          <div className="user-page_modal-form-left">
            <Form.Item {...FIELDS_CONFIG.FORM_USER_ID}>
              <Input
                placeholder={PLACEHOLDER_INPUT}
                size="large"
                maxLength={MAX_LENGTH_6}
                disabled={state.disableFieldsEdit}
                onKeyDown={(event) => {
                  if (event.code === "Space") {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <Form.Item {...FIELDS_CONFIG.FORM_PASSWORD}>
              <Input.Password
                autoComplete="off"
                placeholder={PLACEHOLDER_INPUT}
                maxLength={MAX_LENGTH_8}
                onKeyDown={(event) => {
                  if (event.code === "Space") {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <Form.Item {...FIELDS_CONFIG.FORM_USER_STORE}>
              <Select
                placeholder={PLACEHOLDER_SELECT}
                allowClear={false}
                size="large"
                options={state.storeModal}
                showSearch
                optionFilterProp="label"
                disabled={state.disableFields || state.disableCompanyStore}
                suffixIcon={
                  state.loadingStore ? (
                    <LoadingOutlined spin={state.loadingStore} />
                  ) : (
                    <ArrowSelect />
                  )
                }
              />
            </Form.Item>
          </div>
          <div className="user-page_modal-form-right">
            <Form.Item {...FIELDS_CONFIG.FORM_USERNAME}>
              <Input
                placeholder={PLACEHOLDER_INPUT}
                size="large"
                maxLength={MAX_LENGTH_255}
              />
            </Form.Item>
            <Form.Item {...FIELDS_CONFIG.FORM_USER_COMPANY}>
              <Select
                placeholder={PLACEHOLDER_SELECT}
                allowClear={false}
                size="large"
                options={state.companiesModal}
                disabled={state.disableFields || state.disableCompanyStore}
                onChange={(val) => handler.handleChangeCompanyModal(val)}
                suffixIcon={
                  state.loadingCompany ? (
                    <LoadingOutlined spin={state.loadingCompany} />
                  ) : (
                    <ArrowSelect />
                  )
                }
              />
            </Form.Item>
            <Form.Item {...FIELDS_CONFIG.FORM_USER_ROLE}>
              <Select
                placeholder={PLACEHOLDER_SELECT}
                allowClear={false}
                size="large"
                options={state.rolesModal}
                onChange={(val) => handler.handleChangeRoleModal(val)}
                suffixIcon={
                  state.loadingCompany ? (
                    <LoadingOutlined spin={state.loadingCompany} />
                  ) : (
                    <ArrowSelect />
                  )
                }
              />
            </Form.Item>
          </div>
        </Form>
      </ModalWrapper>

      {/* Modal delete */}
      <ModalDeleteWrapper
        centered
        closeIcon={false}
        open={state.modalDelete}
        title={TITLE.TITLE_DELETE}
        confirmLoading={state.loadingPage}
        onOk={handler.handleOkModalDelete}
        onCancel={handler.handleCloseModalDelete}
        maskClosable={false}
        cancelText={TITLE.BTN_CANCEL}
        okText={TITLE.BTN_DELETE}
        width={416}
      >
        <img src={images.modalDelete} alt="modal_delete" />
        <div className="user-page_modal-delete">
          <p>{state.user?.userName}</p>
          <p>{MESSAGE.CONFIRM_DELETE}</p>
        </div>
      </ModalDeleteWrapper>

      {/* Modal registration/update/delete success/fail */}
      <ModalDeleteWrapper
        centered
        closeIcon={false}
        open={state.modalSuccess}
        title={state.renderTextModal.title}
        onOk={handler.handleCloseModalSuccess}
        maskClosable={false}
        okText={state.renderTextModal.okText}
        width={412}
        okButtonProps={{ style: { width: "100%", marginInlineStart: 0 } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <img src={state.renderTextModal.image} alt="user_modal" />
        <div className="user-page_modal-regist-success">
          <p>{state.renderTextModal.message}</p>
        </div>
      </ModalDeleteWrapper>

      {/* Modal registration/update confirm */}
      <ModalDeleteWrapper
        centered
        closeIcon={false}
        open={state.modalConfirm}
        title={state.renderTextModalConfirm.title}
        onOk={handler.handleOkModalConfirmFinal}
        onCancel={handler.handleCloseModalConfirmFinal}
        maskClosable={false}
        okText={state.renderTextModalConfirm.okText}
        cancelText={TITLE.BTN_CANCEL}
        width={412}
        zIndex={9999}
        confirmLoading={state.loadingPage}
      >
        <img src={images.popUpConfirmFinal} alt="user_modal" />
        <div className="user-page_modal-confirm">
          <p>{state.renderTextModalConfirm.message}</p>
        </div>
      </ModalDeleteWrapper>
    </LoadingCommon>
  );
}

export { UserPage };
