import { TAxiosResponse } from "types";
import { axiosInstance } from "./axiosInstance";
import {
  TPurposesReq,
  TPurposesRes,
  TReportTemperatureSensorReq,
  TReportTemperatureSensorRes,
  TSensorByParamReq,
  TSensorByParamRes,
  TSensorsReq,
  TSensorsRes,
  TSensorTemperatureReq,
  TSensorTemperatureRes,
  TUpdateSensorReq,
  TUpdateSensorRes,
} from "@models/device";
import { TCommonError } from "@models/common";

const openCaseApi = {
  getSensorByParam: async (payload: TSensorByParamReq) => {
    const params: TSensorByParamReq = {
      ...payload,
      companyCode: payload.companyCode || "",
      storeCode: payload.storeCode || "",
      chCode: payload.chCode || "",
    };

    const url = `/sensor?companyCode=${params.companyCode}&storeCode=${params.storeCode}&chCode=${params.chCode}`;
    const response: TSensorByParamRes = await axiosInstance.get(url);

    return response;
  },

  getAllPurposes: async (payload: TPurposesReq) => {
    const url = "/purpose/purposes";
    const response: TAxiosResponse<TPurposesRes[], TCommonError> =
      await axiosInstance.post(url, payload);

    return response;
  },

  getChCodeSensor: async (payload: TSensorsReq) => {
    const url = "/sensor/sensors";
    const response: TAxiosResponse<TSensorsRes[], TCommonError> =
      await axiosInstance.post(url, payload);

    return response;
  },

  getTemperatureSensor: async (payload: TSensorTemperatureReq) => {
    const url = `/sensor/temperature?sensorId=${payload.sensorId}&measuredAt=${payload.measuredAt}`;
    const response: TAxiosResponse<TSensorTemperatureRes, TCommonError> =
      await axiosInstance.get(url);

    return response;
  },

  getReportTemperatureSensor: async (payload: TReportTemperatureSensorReq) => {
    const url = `/sensor/report-temperature?sensorId=${payload.sensorId}&reportDate=${payload.reportDate}`;
    const response: TAxiosResponse<
      TReportTemperatureSensorRes[],
      TCommonError
    > = await axiosInstance.get(url);

    return response;
  },

  updateTemperatureSensor: async (payload: TUpdateSensorReq) => {
    const url = "/sensor/update";
    const params: TUpdateSensorReq = {
      ...payload,
      maxTemp: payload.maxTemp.toString() || "",
      minTemp: payload.minTemp.toString() || "",
      reasonSetting: payload.reasonSetting || "",
    };

    const response: TAxiosResponse<TUpdateSensorRes, TCommonError> =
      await axiosInstance.put(url, params);

    return response;
  },
};

export { openCaseApi };
