import { layoutApi } from "@services/layoutApi";
import { COMPANY_CONSTANT } from "../constant";
import { handleShowToastError } from "@utils/helpers";
import { TSelectValue } from "@models/common";
import _ from "lodash";
import { FormInstance } from "antd/lib";
import {
  COMMON_MESSAGE_ERROR,
  COMPANY_DEFAULT_OPTION,
} from "@constants/common";
import { EModalType } from "types/others";
import { EHttpStatusCode } from "types/service";
import { TAxiosResponse } from "types";
import { appToast } from "@utils/appToast";
import {
  TCheckCompanyExistReq,
  TFormCompanyReq,
  TFormModalRegisterReq,
  TFormModalRes,
  TFormModalUpdateReq,
  TListCompanyReq,
  TListCompanyTable,
} from "@models/company";
import { companyApi } from "@services/companyApi";

const { NAME, MESSAGE } = COMPANY_CONSTANT;

function useCompanyApi() {
  // API
  const registrationCompany = async (
    params: TFormModalRegisterReq,
    handleOkModalSuccess: (val: TFormModalRes) => void,
    setLoadingPage: (val: boolean) => void,
    handleModalFail: () => void,
    handleCommonError: () => void
  ) => {
    setLoadingPage(true);

    try {
      const response = await companyApi.registrationCompany(params);
      if (response.ok) {
        handleOkModalSuccess(response.data);
      }
    } catch (error: any) {
      handleCommonError();
      if (
        error?.status === EHttpStatusCode.BAD_REQUEST &&
        error?.data?.errors?.[0]?.description
      ) {
        handleModalFail();
        return;
      }
      const err = error as TAxiosResponse;
      if (err.status === EHttpStatusCode.FORBIDDEN) {
        appToast.error({ message: COMMON_MESSAGE_ERROR });
        return;
      }
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const updateCompany = async (
    params: TFormModalUpdateReq,
    handleOkModalSuccess: (val: TFormModalRes) => void,
    setLoadingPage: (val: boolean) => void,
    handleModalFail: () => void,
    handleCommonError: () => void
  ) => {
    setLoadingPage(true);

    try {
      const response = await companyApi.updateCompany(params);
      if (response.ok) {
        handleOkModalSuccess(response.data);
      }
    } catch (error: any) {
      handleCommonError();
      if (
        error?.status === EHttpStatusCode.BAD_REQUEST &&
        error?.data?.errors?.[0]?.description
      ) {
        handleModalFail();
        return;
      }
      const err = error as TAxiosResponse;
      if (err.status === EHttpStatusCode.FORBIDDEN) {
        appToast.error({ message: COMMON_MESSAGE_ERROR });
        return;
      }
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const detailCompany = async (
    payload: string,
    handleSetFormValues: (val: TFormModalRes) => void,
    setLoadingModal: (val: boolean) => void
  ) => {
    setLoadingModal(true);
    try {
      const response = await companyApi.getDetailCompany(payload);

      if (response.ok) {
        const { data } = response;
        handleSetFormValues({ ...data, id: payload });
      }
    } catch (error) {
      handleShowToastError(error);
      handleSetFormValues({
        id: "",
        companyCode: "",
        companyName: "",
      });
    } finally {
      setLoadingModal(false);
    }
  };

  const deleteCompany = async (
    payload: string,
    companyParam: string,
    pageIndex: number,
    listCompany: TListCompanyTable[],
    setListCompany: (val: any) => void,
    setLoadingPage: (val: boolean) => void,
    setModalSuccess: (val: boolean) => void,
    setModalType: (val: EModalType | null | string) => void,
    handleCloseModalDelete: () => void,
    handleDeleteFail: () => void,
    setHasNext: (val: boolean) => void
  ) => {
    setLoadingPage(true);

    try {
      const response = await companyApi.deleteCompany(payload);
      if (response.ok) {
        setListCompany(
          [
            ..._.filter(listCompany, function (item) {
              return item.id !== payload;
            }),
          ].map((item, index) => ({ ...item, key: index + 1 }))
        );

        const responseList = await companyApi.getCompaniesByCode({
          companyCode: companyParam || "",
          pageIndex,
        });
        if (responseList.ok) {
          setHasNext(responseList.pagination?.hasNext as boolean);
          if (pageIndex === 1) {
            const _listCompany = responseList.data.map((item, index) => ({
              ...item,
              key: index + 1,
            }));
            setListCompany(_listCompany);
          } else {
            setListCompany((prevState: any) => {
              const existingIds = new Set(
                prevState.map((item: any) => item.id)
              );
              const newList = responseList.data.filter(
                (item) => !existingIds.has(item.id)
              );

              return [...prevState, ...newList].map((item, index) => ({
                ...item,
                key: index + 1,
              }));
            });
          }
        }

        setModalSuccess(true);
        setModalType(EModalType.SUCCESS_DELETE);
        handleCloseModalDelete();
      }
    } catch (error: any) {
      if (
        error?.status === EHttpStatusCode.BAD_REQUEST &&
        error?.data?.errors?.[0]?.description === "Not found company"
      ) {
        handleDeleteFail();
        return;
      }
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const checkExistCompany = async (
    payload: TCheckCompanyExistReq,
    formModal: FormInstance,
    modalType: EModalType,
    setModalType: (val: EModalType | null | string) => void,
    setLoadingPage: (val: boolean) => void
  ) => {
    setLoadingPage(true);
    try {
      const response = await companyApi.checkCompanyExist(payload);
      if (response.ok) {
        setModalType(modalType);
      }
    } catch (error: any) {
      if (
        error.status === EHttpStatusCode.BAD_REQUEST &&
        error?.data?.errors.length &&
        error?.data?.errors[0].description === "Company is already existed"
      ) {
        formModal.setFields([
          {
            name: [NAME.COMPANY_CODE],
            errors: [MESSAGE.DATA_EXIST],
          },
        ]);
        return;
      }

      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const getListCompanySearch = async (
    payload: TListCompanyReq,
    setLoadingPage: (val: boolean) => void,
    setListCompany: (val: TListCompanyTable[]) => void,
    handleSetSearchParams: (val: TFormCompanyReq) => void,
    setPageIndex: (val: number) => void,
    setHasNext: (val: boolean) => void
  ) => {
    setLoadingPage(true);

    try {
      const response = await companyApi.getCompaniesByCode(payload);
      if (response.ok) {
        setListCompany(
          response.data.map((item, index) => ({
            ...item,
            key: index + 1,
          }))
        );
        if (payload.companyCode) {
          handleSetSearchParams(payload);
        } else {
          handleSetSearchParams({ ...payload });
        }
        setPageIndex(1);
        setHasNext(response.pagination?.hasNext as boolean);
      }
    } catch (error) {
      setPageIndex(0);
      setHasNext(false);
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const getListCompanyScroll = async (
    payload: TListCompanyReq,
    listCompany: TListCompanyTable[],
    pageIndex: number,
    setLoadingPage: (val: boolean) => void,
    setListCompany: (val: any) => void,
    setPageIndex: (val: number) => void,
    setHasNext: (val: boolean) => void
  ) => {
    setLoadingPage(true);
    try {
      const response = await companyApi.getCompaniesByCode(payload);

      if (response.ok) {
        setHasNext(response.pagination?.hasNext as boolean);

        if (listCompany.length !== response.pagination?.totalCount) {
          const startIndex = listCompany.length;
          setListCompany([
            ...listCompany,
            ...response.data.map((item, index) => ({
              ...item,
              key: startIndex + index + 1,
            })),
          ]);
          setPageIndex(pageIndex + 1);
        }
      }
    } catch (error) {
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const fetchInitialApis = async (
    pageIndex: number,
    setLoadingPage: (val: boolean) => void,
    setLoadingCompany: (val: boolean) => void,
    handleSetSearchParams: (val: TFormCompanyReq) => void,
    setCompanies: (val: TSelectValue[]) => void,
    setListCompany: (val: TListCompanyTable[]) => void,
    setCompaniesModal: (val: TSelectValue[]) => void,
    setHasNext: (val: boolean) => void,
    setPageIndex: (val: number) => void
  ) => {
    setLoadingPage(true);

    try {
      const resCompany = await layoutApi.getCompanies();
      if (resCompany.ok) {
        const convertCompanies = resCompany.data.map((val) => ({
          value: val.companyCode,
          label: val.companyCode + "_" + val.companyName,
          children: val.id,
        }));
        setCompanies([COMPANY_DEFAULT_OPTION, ...convertCompanies]);
        setCompaniesModal(
          convertCompanies.map((item) => ({
            ...item,
            value: item.children,
            children: item.value,
          }))
        );

        handleSetSearchParams({
          companyCode: COMPANY_DEFAULT_OPTION.value,
        });

        const response = await companyApi.getCompaniesByCode({
          companyCode: COMPANY_DEFAULT_OPTION.value,
          pageIndex,
        });
        if (response.ok) {
          const convertListCompany: TListCompanyTable[] = response.data.map(
            (item, index) => ({
              ...item,
              key: index + 1,
            })
          );
          setListCompany(convertListCompany);
          setPageIndex(1);
          setHasNext(response.pagination?.hasNext as boolean);
        }
      }
    } catch (error) {
      setListCompany([]);
      setPageIndex(0);
      setHasNext(false);

      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
      setLoadingCompany(false);
    }
  };

  const fetchApiRedirect = async (
    payload: TFormCompanyReq,
    pageIndex: number,
    setLoadingPage: (val: boolean) => void,
    setLoadingCompany: (val: boolean) => void,
    handleSetSearchParams: (val: TFormCompanyReq) => void,
    setCompanies: (val: TSelectValue[]) => void,
    setListCompany: (val: TListCompanyTable[]) => void,
    setCompaniesModal: (val: TSelectValue[]) => void,
    setHasNext: (val: boolean) => void,
    setPageIndex: (val: number) => void
  ) => {
    setLoadingPage(true);

    try {
      const resCompany = await layoutApi.getCompanies();
      if (resCompany.ok) {
        const convertCompanies = resCompany.data.map((val) => ({
          value: val.companyCode,
          label: val.companyCode + "_" + val.companyName,
          children: val.id,
        }));
        setCompanies([COMPANY_DEFAULT_OPTION, ...convertCompanies]);
        setCompaniesModal(
          convertCompanies.map((item) => ({
            ...item,
            value: item.children,
            children: item.value,
          }))
        );
      }

      handleSetSearchParams({
        companyCode: payload.companyCode,
      });

      const response = await companyApi.getCompaniesByCode({
        ...payload,
        pageIndex,
      });
      if (response.ok) {
        const convertListCompany: TListCompanyTable[] = response.data.map(
          (item, index) => ({
            ...item,
            key: index + 1,
          })
        );
        setListCompany(convertListCompany);
        setPageIndex(1);
        setHasNext(response.pagination?.hasNext as boolean);
      }
    } catch (error) {
      setListCompany([]);
      setPageIndex(0);
      setHasNext(false);

      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
      setLoadingCompany(false);
    }
  };

  // EFFECT
  const effectCompany = (companyParam: string, form: FormInstance) => {
    if (companyParam) {
      form.setFieldValue(NAME.COMPANY, companyParam);
    } else {
      form.setFieldValue(NAME.COMPANY, undefined);
    }
  };

  return {
    handler: {
      fetchInitialApis,
      fetchApiRedirect,
      getListCompanyScroll,
      getListCompanySearch,
      registrationCompany,
      detailCompany,
      checkExistCompany,
      deleteCompany,
      updateCompany,
    },
    effect: {
      effectCompany,
    },
  };
}

export default useCompanyApi;
