import { useLayoutEffect } from "react";

const useWindowHeight = () => {
  useLayoutEffect(() => {
    const setWindowHeightToCSSVar = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--window-height", `${window.innerHeight}px`);
    };

    setWindowHeightToCSSVar();
    window.addEventListener("resize", setWindowHeightToCSSVar);

    return () => {
      window.removeEventListener("resize", setWindowHeightToCSSVar);
    };
  }, []);
};

export { useWindowHeight };
