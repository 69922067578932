import { Layout } from "antd";
import styled from "styled-components";

export const ErrorLayoutStyled = styled(Layout)`
  min-height: 100vh !important;

  .main-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 62px;
    background-color: var(--color-white);
  }
`;
