export const PURPOSE_CONSTANT = {
  TITLE: {
    HEADER_TITLE: "用途（売場名)",
    BTN_REGISTRATION: "新規登録",

    // Columns
    NO: "NO",
    PURPOSE_CODE: "用途コード(売場）",
    PURPOSE_NAME: "用途名",
    DEPARTMENT: "部門",
    MIN_TEMP: "基準管理温度下限",
    MAX_TEMP: "基準管理温度上限",
    ACTION: "アクション",

    // Modal
    BTN_CANCEL: "キャンセル",
    BTN_NEXT: "次へ",
    BTN_CLOSE: "閉じる",
    BTN_UPDATE: "変更 ",
    BTN_OK: "OK",
    TITLE_REGISTRATION: "用途登録",
    TITLE_DETAIL: "用途照会",
    TITLE_UPDATE: "用途変更",
    TITLE_DELETE: "削除 ",
    BTN_REGISTRATION_MODAL: "登録 ",
    SUCCESS_TITLE: "用途成功",
    SUCCESS_REGISTRATION: "機器登録",

    NOT_FOUND_PURPOSE: "not_found_purpose",
  },
  LABEL: {
    COMPANY: "会社",
    PURPOSE: "用途 (売場）",
    DEPARTMENT: "部門",

    COMPANY_MODAL: "会社・事業部",
    DEPARTMENT_MODAL: "部門",
    PURPOSE_CODE_MODAL: "用途コード(売場）",
    PURPOSE_NAME_MODAL: "用途名",
    PURPOSE_MIN_TEMP: "基準管理温度下限",
    PURPOSE_MAX_TEMP: "基準管理温度上限",
  },
  NAME: {
    COMPANY: "companyId",
    PURPOSE: "purposeId",
    DEPARTMENT: "departmentId",

    COMPANY_ID_MODAL: "companyId",
    COMPANY_CODE_MODAL: "companyCode",
    COMPANY_NAME_MODAL: "companyName",
    DEPARTMENT_ID_MODAL: "departmentId",
    DEPARTMENT_CODE_MODAL: "departmentCode",
    DEPARTMENT_NAME_MODAL: "departmentName",
    PURPOSE_CODE_MODAL: "purposeCode",
    PURPOSE_NAME_MODAL: "purposeName",
    PURPOSE_MIN_TEMP: "purposeMinTemp",
    PURPOSE_MAX_TEMP: "purposeMaxTemp",
  },
  TABLE_KEY: {
    NO: "key",
    COMPANY: "companyCode",
    PURPOSE_CODE: "purposeCode",
    PURPOSE_NAME: "purposeName",
    DEPARTMENT: "departmentCode",
    MIN_TEMP: "minTemp",
    MAX_TEMP: "maxTemp",
  },
  MESSAGE: {
    NO_DATA: "表示するデータがありません。",

    // Modal
    FIELD_INVALID_FORMAT: "値の形式は正しくありません。",
    FIELD_INVALID: "値は無効です。",
    LOWER_UPPER: "温度下限 <= 温度上限である必要があります。",
    DATA_EXIST: "値はすでに存在しています。",

    CONFIRM_REGISTRATION_FINAL:
      "この用途（売場名)を登録します。よろしいですか？",
    REGISTER_SUCCESS: "用途（売場名)が登録に成功された。",
    FAIL_REGISTER: "用途（売場名)が登録に失敗された。",
    CONFIRM_UPDATE_FINAL: "この用途（売場名)を変更します。よろしいですか？",
    UPDATE_SUCCESS: "用途（売場名)が変更に成功された。",
    FAIL_UPDATE: "用途（売場名)が変更に失敗された。",
    DELETE_DEVICE: "この用途（売場名)を削除します。よろしいですか？",
    SUCCESS_DELETE: "用途（売場名)が削除に成功された。",
    FAIL_DELETE: "用途（売場名)が削除に失敗された。",
    NOT_FOUND_PURPOSE:
      "他の人がデータを更新したため、更新できません。 データベースからデータをリロードしてください。",
  },
};

export const HEIGHT_TABLE_SCROLL = 685;
export const LIST_KEYS_ALLOW_PURPOSE = [
  "Backspace",
  "Tab",
  "Shift",
  "Period",
  "NumpadDecimal",
  "ArrowRight",
  "ArrowLeft",
];
