export const LAYOUT_CONSTANT = {
  NAME: {
    COMPANY: "companyCode",
    STORE: "storeCode",
  },
  LABEL: {
    COMPANY: "会社",
    STORE: "店舗",
  },
  TITLE: {
    LAYOUT_DIAGRAM: "レイアウト図",
  },
  MESSAGE: {
    NO_RESULT: "検索結果はありません。",
  },
};

export const STEP_SCALE = 0.1;
export const MIN_SCALE = 0.5;
export const MAX_SCALE = 3;
export const ANGLE_ROTATE = 90;
export const MIN_BOUND = -1000;
export const MAX_BOUND = 1000;
