import {
  ClockCircleOutlined,
  LoadingOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  ArrowSelect,
  DatePickerIcon,
  IconPlusRegister,
  LoadingCommon,
  TableList,
} from "@components";
import { images } from "@constants";
import {
  DATE_FORMAT,
  MAX_LENGTH_255,
  PLACEHOLDER_INPUT,
  PLACEHOLDER_SELECT,
  REGEX_CHECK_FORMAT_TEMP,
  REGEX_FORMAT_TEMPERATURE,
  TIME_PICKER_FORMAT,
} from "@constants/common";
import { ArrowLeftIcon, SearchIcon } from "@constants/svgs";
import {
  Button,
  Collapse,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
  TimePicker,
} from "antd";
import { CollapseProps } from "antd/lib";
import { useCallback } from "react";
import { EModalType } from "types/others";
import DateSeason from "./components/DateSeason";
import {
  BATTERY_SELECT,
  DEVICE_CONSTANT,
  HEIGHT_TABLE_SCROLL,
  INSTALL_LOCATION_SELECT,
  TEMPERATURE_TYPE_SELECT,
} from "./constant";
import {
  DeviceWrapper,
  ModalDeleteWrapper,
  ModalWrapper,
} from "./DeviceStyled";
import useDevicePage, { panelStyle } from "./hooks/useDevicePage";

const { TITLE, LABEL, NAME, MESSAGE, PLACEHOLDER } = DEVICE_CONSTANT;

const renderLabelForm = (label: string) => {
  return (
    <>
      <span style={{ color: "#d92d20", marginRight: "4px" }}>*</span> {label}
    </>
  );
};

const FIELDS_CONFIG = {
  COMPANY: {
    required: false,
    label: LABEL.COMPANY_CODE,
    name: NAME.COMPANY_CODE,
  },
  STORE: { required: false, label: LABEL.STORE_CODE, name: NAME.STORE_CODE },
  CH_CODE: { required: false, label: LABEL.CH_CODE, name: NAME.CH_CODE },
  MANUFACTURER_CODE: {
    required: false,
    label: LABEL.MANUFACTURER_CODE,
    name: NAME.MANUFACTURER_CODE,
  },
  MANUFACTURER_MODEL: {
    required: false,
    label: LABEL.MANUFACTURER_MODEL,
    name: NAME.MANUFACTURER_MODEL,
  },

  COMPANY_MODAL: {
    required: false,
    label: renderLabelForm(LABEL.COMPANY_MODAL),
    name: NAME.COMPANY_MODAL,
  },
  STORE_MODAL: {
    required: false,
    label: renderLabelForm(LABEL.STORE_MODAL),
    name: NAME.STORE_MODAL,
  },
  MANUFACTURER_MODAL: {
    required: false,
    label: renderLabelForm(LABEL.MANUFACTURER_MODAL),
    name: NAME.MANUFACTURER_MODAL,
  },
  MANUFACTURER_MODEL_MODAL: {
    required: false,
    label: renderLabelForm(LABEL.MANUFACTURER_MODEL_MODAL),
    name: NAME.MANUFACTURER_MODEL_MODAL,
  },
  BATTERY: {
    required: false,
    label: renderLabelForm(LABEL.BATTERY),
    name: NAME.BATTERY,
  },
  CH_MODAL: {
    required: false,
    label: renderLabelForm(LABEL.CH_MODAL),
    name: NAME.CH_MODAL,
  },
  TEMPERATURE_DISTINCTION: {
    required: false,
    label: renderLabelForm(LABEL.TEMPERATURE_DISTINCTION),
    name: NAME.TEMPERATURE_DISTINCTION,
  },
  INSTALL_LOCATION: {
    required: false,
    label: renderLabelForm(LABEL.INSTALL_LOCATION),
    name: NAME.INSTALL_LOCATION,
  },
  OPEN_DATE: {
    required: false,
    label: LABEL.OPEN_DATE,
    name: NAME.OPEN_DATE,
  },
  CLOSE_DATE: {
    required: false,
    label: LABEL.CLOSE_DATE,
    name: NAME.CLOSE_DATE,
  },
  TIME_START: {
    required: false,
    label: LABEL.TIME_START,
    name: NAME.TIME_START,
  },
  TIME_END: {
    required: false,
    label: LABEL.TIME_END,
    name: NAME.TIME_END,
  },
  PURPOSE_CODE: {
    required: false,
    label: "",
    name: NAME.PURPOSE_CODE,
  },
  PURPOSE_MIN: {
    required: false,
    label: LABEL.PURPOSE_MIN,
    name: NAME.PURPOSE_MIN,
  },
  PURPOSE_MAX: {
    required: false,
    label: LABEL.PURPOSE_MAX,
    name: NAME.PURPOSE_MAX,
  },
  MACHINE: {
    required: false,
    label: LABEL.MACHINE,
    name: NAME.MACHINE,
  },
  IP_ADDRESS: {
    required: false,
    label: LABEL.IP_ADDRESS,
    name: NAME.IP_ADDRESS,
  },
  LOWER_LIMIT: {
    required: false,
    label: renderLabelForm(LABEL.LOWER_LIMIT),
    name: NAME.LOWER_LIMIT,
  },
  UPPER_LIMIT: {
    required: false,
    label: renderLabelForm(LABEL.UPPER_LIMIT),
    name: NAME.UPPER_LIMIT,
  },
  REASON: {
    required: false,
    label: LABEL.REASON,
    name: NAME.REASON,
  },
  START_DATE_SPRING: {
    required: false,
    label: LABEL.START_DATE,
    name: NAME.START_DATE_SPRING,
  },
  START_TIME_1_SPRING: {
    required: false,
    label: LABEL.START_TIME_1_SPRING,
    name: NAME.START_TIME_1_SPRING,
  },
  START_TIME_2_SPRING: {
    required: false,
    label: LABEL.START_TIME_2_SPRING,
    name: NAME.START_TIME_2_SPRING,
  },
  START_TIME_3_SPRING: {
    required: false,
    label: LABEL.START_TIME_3_SPRING,
    name: NAME.START_TIME_3_SPRING,
  },
  START_TIME_4_SPRING: {
    required: false,
    label: LABEL.START_TIME_4_SPRING,
    name: NAME.START_TIME_4_SPRING,
  },
  START_TIME_5_SPRING: {
    required: false,
    label: LABEL.START_TIME_5_SPRING,
    name: NAME.START_TIME_5_SPRING,
  },
  START_TIME_6_SPRING: {
    required: false,
    label: LABEL.START_TIME_6_SPRING,
    name: NAME.START_TIME_6_SPRING,
  },
  START_DATE_SUMMER: {
    required: false,
    label: LABEL.START_DATE,
    name: NAME.START_DATE_SUMMER,
  },
  START_TIME_1_SUMMER: {
    required: false,
    label: LABEL.START_TIME_1_SUMMER,
    name: NAME.START_TIME_1_SUMMER,
  },
  START_TIME_2_SUMMER: {
    required: false,
    label: LABEL.START_TIME_2_SUMMER,
    name: NAME.START_TIME_2_SUMMER,
  },
  START_TIME_3_SUMMER: {
    required: false,
    label: LABEL.START_TIME_3_SUMMER,
    name: NAME.START_TIME_3_SUMMER,
  },
  START_TIME_4_SUMMER: {
    required: false,
    label: LABEL.START_TIME_4_SUMMER,
    name: NAME.START_TIME_4_SUMMER,
  },
  START_TIME_5_SUMMER: {
    required: false,
    label: LABEL.START_TIME_5_SUMMER,
    name: NAME.START_TIME_5_SUMMER,
  },
  START_TIME_6_SUMMER: {
    required: false,
    label: LABEL.START_TIME_6_SUMMER,
    name: NAME.START_TIME_6_SUMMER,
  },
  START_DATE_AUTUMN: {
    required: false,
    label: LABEL.START_DATE,
    name: NAME.START_DATE_AUTUMN,
  },
  START_TIME_1_AUTUMN: {
    required: false,
    label: LABEL.START_TIME_1_AUTUMN,
    name: NAME.START_TIME_1_AUTUMN,
  },
  START_TIME_2_AUTUMN: {
    required: false,
    label: LABEL.START_TIME_2_AUTUMN,
    name: NAME.START_TIME_2_AUTUMN,
  },
  START_TIME_3_AUTUMN: {
    required: false,
    label: LABEL.START_TIME_3_AUTUMN,
    name: NAME.START_TIME_3_AUTUMN,
  },
  START_TIME_4_AUTUMN: {
    required: false,
    label: LABEL.START_TIME_4_AUTUMN,
    name: NAME.START_TIME_4_AUTUMN,
  },
  START_TIME_5_AUTUMN: {
    required: false,
    label: LABEL.START_TIME_5_AUTUMN,
    name: NAME.START_TIME_5_AUTUMN,
  },
  START_TIME_6_AUTUMN: {
    required: false,
    label: LABEL.START_TIME_6_AUTUMN,
    name: NAME.START_TIME_6_AUTUMN,
  },
  START_DATE_WINTER: {
    required: false,
    label: LABEL.START_DATE,
    name: NAME.START_DATE_WINTER,
  },
  START_TIME_1_WINTER: {
    required: false,
    label: LABEL.START_TIME_1_WINTER,
    name: NAME.START_TIME_1_WINTER,
  },
  START_TIME_2_WINTER: {
    required: false,
    label: LABEL.START_TIME_2_WINTER,
    name: NAME.START_TIME_2_WINTER,
  },
  START_TIME_3_WINTER: {
    required: false,
    label: LABEL.START_TIME_3_WINTER,
    name: NAME.START_TIME_3_WINTER,
  },
  START_TIME_4_WINTER: {
    required: false,
    label: LABEL.START_TIME_4_WINTER,
    name: NAME.START_TIME_4_WINTER,
  },
  START_TIME_5_WINTER: {
    required: false,
    label: LABEL.START_TIME_5_WINTER,
    name: NAME.START_TIME_5_WINTER,
  },
  START_TIME_6_WINTER: {
    required: false,
    label: LABEL.START_TIME_6_WINTER,
    name: NAME.START_TIME_6_WINTER,
  },
};

function DevicePage() {
  const { handler, state } = useDevicePage();

  const getItems: (panelStyle: React.CSSProperties) => CollapseProps["items"] =
    useCallback(
      (panelStyle) => {
        return [
          {
            key: "1",
            label: TITLE.CATEGORY_SWITCH,
            children: (
              <>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  {...FIELDS_CONFIG.PURPOSE_CODE}
                >
                  <Select
                    placeholder={PLACEHOLDER_SELECT}
                    allowClear={false}
                    size="large"
                    options={state.purposesModal}
                    disabled={state.loadingStoreModal || state.disableFields}
                    suffixIcon={
                      state.loadingStoreModal ? (
                        <LoadingOutlined spin={state.loadingStoreModal} />
                      ) : (
                        <ArrowSelect disable={state.disableFields} isModal />
                      )
                    }
                    onChange={(val) => handler.handleChangePurposeModal(val)}
                  />
                </Form.Item>

                <div className="device-page_modal-form-flex">
                  <div className="device-page_modal-form-left">
                    <Form.Item
                      style={{ marginBottom: 0 }}
                      {...FIELDS_CONFIG.PURPOSE_MIN}
                    >
                      <InputNumber
                        placeholder={PLACEHOLDER_INPUT}
                        disabled={true}
                        controls={false}
                        size="large"
                        style={{ width: "352px" }}
                        step="0.1"
                      />
                    </Form.Item>
                  </div>
                  <div className="device-page_modal-form-right">
                    <Form.Item
                      style={{ marginBottom: 0 }}
                      {...FIELDS_CONFIG.PURPOSE_MAX}
                    >
                      <InputNumber
                        placeholder={PLACEHOLDER_INPUT}
                        style={{ width: "352px" }}
                        disabled={true}
                        controls={false}
                        size="large"
                        step="0.1"
                      />
                    </Form.Item>
                  </div>
                </div>
              </>
            ),
            extra: <Switch checked={state.keyPurpose[0] === "1"} />,
            showArrow: false,
            style: { ...panelStyle, border: "1px solid #C3C8CD" },
          },
          {
            key: "2",
            label: TITLE.MANUAL_SWITCH,
            children: (
              <>
                <div className="device-page_modal-form-flex">
                  <div className="device-page_modal-form-left">
                    <Form.Item
                      style={{ marginBottom: "10px" }}
                      {...FIELDS_CONFIG.LOWER_LIMIT}
                    >
                      <InputNumber
                        onBlur={(event) => {
                          let _value = "";
                          const parts = event.target.value.split(".");
                          _value =
                            parts[0] + (parts[1] ? `.${parts[1][0]}` : "");
                          const formattedValue = _value.replace(
                            REGEX_FORMAT_TEMPERATURE,
                            "$1$2"
                          );
                          if (
                            formattedValue &&
                            !formattedValue.match(REGEX_CHECK_FORMAT_TEMP)
                          ) {
                            state.formModal.setFieldValue(
                              NAME.LOWER_LIMIT,
                              "0"
                            );
                          } else {
                            state.formModal.setFieldValue(
                              NAME.LOWER_LIMIT,
                              formattedValue
                            );
                          }
                        }}
                        step="0.1"
                        placeholder={PLACEHOLDER_INPUT}
                        controls={false}
                        keyboard={false}
                        size="large"
                        style={{ width: "352px" }}
                        onKeyDown={(event: any) =>
                          handler.handleKeyDownTemperature(event)
                        }
                      />
                    </Form.Item>
                  </div>
                  <div className="device-page_modal-form-right">
                    <Form.Item
                      style={{ marginBottom: "10px" }}
                      {...FIELDS_CONFIG.UPPER_LIMIT}
                    >
                      <InputNumber
                        onBlur={(event) => {
                          let _value = "";
                          const parts = event.target.value.split(".");
                          _value =
                            parts[0] + (parts[1] ? `.${parts[1][0]}` : "");
                          const formattedValue = _value.replace(
                            REGEX_FORMAT_TEMPERATURE,
                            "$1$2"
                          );
                          if (
                            formattedValue &&
                            !formattedValue.match(REGEX_CHECK_FORMAT_TEMP)
                          ) {
                            state.formModal.setFieldValue(
                              NAME.UPPER_LIMIT,
                              "0"
                            );
                          } else {
                            state.formModal.setFieldValue(
                              NAME.UPPER_LIMIT,
                              formattedValue
                            );
                          }
                        }}
                        step="0.1"
                        placeholder={PLACEHOLDER_INPUT}
                        style={{ width: "352px" }}
                        controls={false}
                        keyboard={false}
                        size="large"
                        onKeyDown={(event: any) =>
                          handler.handleKeyDownTemperature(event)
                        }
                      />
                    </Form.Item>
                  </div>
                </div>

                <Form.Item
                  style={{ marginBottom: 0 }}
                  {...FIELDS_CONFIG.REASON}
                >
                  <Input.TextArea
                    rows={6}
                    placeholder={PLACEHOLDER.COMMENT}
                    maxLength={MAX_LENGTH_255}
                    onBlur={(event) => {
                      state.formModal.setFieldValue(
                        NAME.REASON,
                        event.target.value.trim()
                      );
                    }}
                  />
                </Form.Item>
              </>
            ),
            extra: <Switch checked={state.keyPurpose[0] === "2"} />,
            showArrow: false,
            style: { ...panelStyle, border: "1px solid #C3C8CD" },
          },
        ];
      },
      [state.keyPurpose, state.purposesModal, state.disableFields]
    );

  const getItemsSeason: (
    panelStyle: React.CSSProperties
  ) => CollapseProps["items"] = useCallback(
    (panelStyle) => {
      return [
        {
          key: "1",
          label: LABEL.START_DATE_SPRING,
          children: (
            <DateSeason
              handleDateTimeChange={handler.handleDateTimeChange}
              disableTime={state.disableFields}
              valueWatch={state.springStartDateWatch}
              date={FIELDS_CONFIG.START_DATE_SPRING}
              dateOne={FIELDS_CONFIG.START_TIME_1_SPRING}
              dateTwo={FIELDS_CONFIG.START_TIME_2_SPRING}
              dateThree={FIELDS_CONFIG.START_TIME_3_SPRING}
              dateFour={FIELDS_CONFIG.START_TIME_4_SPRING}
              dateFive={FIELDS_CONFIG.START_TIME_5_SPRING}
              dateSix={FIELDS_CONFIG.START_TIME_6_SPRING}
            />
          ),
          style: panelStyle,
        },
        {
          key: "2",
          label: LABEL.START_DATE_SUMMER,
          children: (
            <DateSeason
              handleDateTimeChange={handler.handleDateTimeChange}
              disableTime={state.disableFields}
              valueWatch={state.summerStartDateWatch}
              date={FIELDS_CONFIG.START_DATE_SUMMER}
              dateOne={FIELDS_CONFIG.START_TIME_1_SUMMER}
              dateTwo={FIELDS_CONFIG.START_TIME_2_SUMMER}
              dateThree={FIELDS_CONFIG.START_TIME_3_SUMMER}
              dateFour={FIELDS_CONFIG.START_TIME_4_SUMMER}
              dateFive={FIELDS_CONFIG.START_TIME_5_SUMMER}
              dateSix={FIELDS_CONFIG.START_TIME_6_SUMMER}
            />
          ),
          style: panelStyle,
        },
        {
          key: "3",
          label: LABEL.START_DATE_AUTUMN,
          children: (
            <DateSeason
              handleDateTimeChange={handler.handleDateTimeChange}
              disableTime={state.disableFields}
              valueWatch={state.autumnStartDateWatch}
              date={FIELDS_CONFIG.START_DATE_AUTUMN}
              dateOne={FIELDS_CONFIG.START_TIME_1_AUTUMN}
              dateTwo={FIELDS_CONFIG.START_TIME_2_AUTUMN}
              dateThree={FIELDS_CONFIG.START_TIME_3_AUTUMN}
              dateFour={FIELDS_CONFIG.START_TIME_4_AUTUMN}
              dateFive={FIELDS_CONFIG.START_TIME_5_AUTUMN}
              dateSix={FIELDS_CONFIG.START_TIME_6_AUTUMN}
            />
          ),
          style: panelStyle,
        },
        {
          key: "4",
          label: LABEL.START_DATE_WINTER,
          children: (
            <DateSeason
              handleDateTimeChange={handler.handleDateTimeChange}
              disableTime={state.disableFields}
              valueWatch={state.winterStartDateWatch}
              date={FIELDS_CONFIG.START_DATE_WINTER}
              dateOne={FIELDS_CONFIG.START_TIME_1_WINTER}
              dateTwo={FIELDS_CONFIG.START_TIME_2_WINTER}
              dateThree={FIELDS_CONFIG.START_TIME_3_WINTER}
              dateFour={FIELDS_CONFIG.START_TIME_4_WINTER}
              dateFive={FIELDS_CONFIG.START_TIME_5_WINTER}
              dateSix={FIELDS_CONFIG.START_TIME_6_WINTER}
            />
          ),
          style: { ...panelStyle, marginBottom: 0 },
        },
      ];
    },
    [
      state.modalType,
      state.keySeason,
      state.springStartDateWatch,
      state.summerStartDateWatch,
      state.autumnStartDateWatch,
      state.winterStartDateWatch,
    ]
  );

  return (
    <>
      <LoadingCommon loading={state.loadingPage}>
        <DeviceWrapper listdata={state.listDevice.length}>
          <div className="device-page_header">
            <h1>{TITLE.DEVICE_LIST}</h1>
            <div className="device-page_btns">
              <Button
                onClick={handler.handleOpenModalRegistration}
                type="primary"
                icon={<IconPlusRegister />}
                disabled={state.disableBtnOther}
              >
                {TITLE.BTN_REGISTRATION}
              </Button>
              <Button disabled={state.disableBtnOther}>
                {TITLE.BTN_EXPORT}
              </Button>
            </div>
          </div>

          <div className="device-page_form">
            <Form
              onFinish={handler.handleFinish}
              form={state.form}
              layout="vertical"
            >
              {/* COMPANY */}
              <Form.Item {...FIELDS_CONFIG.COMPANY}>
                <Select
                  placeholder={PLACEHOLDER_SELECT}
                  allowClear={false}
                  size="large"
                  options={state.companies}
                  onChange={(val) => handler.handleChangeCompany(val)}
                  suffixIcon={
                    state.loadingCompany ? (
                      <LoadingOutlined spin={state.loadingCompany} />
                    ) : (
                      <ArrowSelect />
                    )
                  }
                />
              </Form.Item>

              {/* STORE */}
              <Form.Item {...FIELDS_CONFIG.STORE}>
                <Select
                  showSearch
                  optionFilterProp="label"
                  placeholder={PLACEHOLDER_SELECT}
                  allowClear={false}
                  size="large"
                  options={state.stores}
                  disabled={state.loadingStore}
                  onChange={(val) => handler.handleChangeStore(val)}
                  suffixIcon={
                    state.loadingStore ? (
                      <LoadingOutlined spin={state.loadingStore} />
                    ) : (
                      <ArrowSelect />
                    )
                  }
                />
              </Form.Item>

              {/* CH_CODE */}
              <Form.Item {...FIELDS_CONFIG.CH_CODE}>
                <Select
                  showSearch
                  optionFilterProp="label"
                  placeholder={PLACEHOLDER_SELECT}
                  allowClear={false}
                  size="large"
                  options={state.chCode}
                  disabled={state.loadingCh}
                  suffixIcon={
                    state.loadingCh ? (
                      <LoadingOutlined spin={state.loadingCh} />
                    ) : (
                      <ArrowSelect />
                    )
                  }
                />
              </Form.Item>

              <Form.Item {...FIELDS_CONFIG.MANUFACTURER_CODE}>
                <Select
                  placeholder={PLACEHOLDER_SELECT}
                  allowClear={false}
                  size="large"
                  options={state.manufacturers}
                  disabled={state.loadingCh}
                  suffixIcon={
                    state.loadingCh ? (
                      <LoadingOutlined spin={state.loadingCh} />
                    ) : (
                      <ArrowSelect />
                    )
                  }
                />
              </Form.Item>

              <Form.Item {...FIELDS_CONFIG.MANUFACTURER_MODEL}>
                <Input placeholder={PLACEHOLDER_INPUT} size="large" />
              </Form.Item>

              <Button htmlType="submit" icon={<SearchIcon />} />
            </Form>
          </div>

          <TableList
            tableRef={state.tblRef}
            image={images.noResult}
            columns={state.columns}
            handleScroll={handler.handleScroll}
            heightTableScroll={HEIGHT_TABLE_SCROLL}
            listData={state.listDevice}
            messageNoData={MESSAGE.NO_DATA}
            rowKey={state.rowKey}
            borderColor="var(--color-border-primary)"
          />
        </DeviceWrapper>
      </LoadingCommon>

      <ModalWrapper
        confirmLoading={state.loadingExist}
        loading={state.loadingModal}
        styles={{
          body: {
            overflowY: "auto",
            maxHeight: "660px",
          },
        }}
        width={800}
        title={
          <>
            {state.modalType === EModalType.CONFIRM_REGISTRATION && (
              <button
                onClick={() => handler.setModalType(EModalType.REGISTRATION)}
              >
                <ArrowLeftIcon />
              </button>
            )}
            {state.modalType === EModalType.CONFIRM_UPDATE && (
              <button onClick={() => handler.setModalType(EModalType.UPDATE)}>
                <ArrowLeftIcon />
              </button>
            )}
            <span>{state.renderTextModal.title}</span>
          </>
        }
        closeIcon={false}
        maskClosable={false}
        okText={state.renderTextModal.okText}
        cancelText={TITLE.BTN_CANCEL}
        destroyOnClose={true}
        open={state.modalOpen}
        centered
        onCancel={() => {
          handler.handleCloseModal();
        }}
        onOk={() => {
          if (state.modalType === EModalType.DETAIL) {
            handler.handleCloseModal();
          } else if (state.modalType === EModalType.UPDATE) {
            handler.handleValidateForm();
          } else if (state.modalType === EModalType.CONFIRM_REGISTRATION) {
            handler.handleOpenModalConfirmFinal();
          } else if (state.modalType === EModalType.CONFIRM_UPDATE) {
            handler.handleOpenModalConfirmFinal();
          } else {
            handler.handleValidateForm();
          }
        }}
        okButtonProps={{
          style: {
            width: state.modalType === EModalType.DETAIL ? "100%" : "368px",
          },
          disabled: state.disableBtnNext,
        }}
        cancelButtonProps={{
          style: {
            display: state.modalType === EModalType.DETAIL ? "none" : "block",
          },
        }}
      >
        <Form
          layout="vertical"
          form={state.formModal}
          preserve={false}
          disabled={state.disableFields}
        >
          <div className="device-page_modal-form-flex">
            <div className="device-page_modal-form-left">
              <Form.Item {...FIELDS_CONFIG.COMPANY_MODAL}>
                <Select
                  placeholder={PLACEHOLDER_SELECT}
                  allowClear={false}
                  size="large"
                  options={state.companiesModal}
                  suffixIcon={
                    <ArrowSelect disable={state.disableFieldsEdit} isModal />
                  }
                  disabled={state.disableFieldsEdit}
                  onChange={(val) => handler.handleChangeCompanyModal(val)}
                />
              </Form.Item>

              <Form.Item {...FIELDS_CONFIG.MANUFACTURER_MODAL}>
                <Select
                  placeholder={PLACEHOLDER_SELECT}
                  allowClear={false}
                  size="large"
                  options={state.manufacturersModal}
                  suffixIcon={
                    <ArrowSelect disable={state.disableFields} isModal />
                  }
                />
              </Form.Item>

              <Form.Item {...FIELDS_CONFIG.BATTERY}>
                <Select
                  placeholder={PLACEHOLDER_SELECT}
                  allowClear={false}
                  size="large"
                  options={BATTERY_SELECT}
                  suffixIcon={
                    <ArrowSelect disable={state.disableFields} isModal />
                  }
                />
              </Form.Item>

              <Form.Item {...FIELDS_CONFIG.CH_MODAL}>
                <Input
                  placeholder={PLACEHOLDER_INPUT}
                  size="large"
                  maxLength={MAX_LENGTH_255}
                  disabled={state.disableFieldsEdit}
                  onBlur={(event) => {
                    state.formModal.setFieldValue(
                      NAME.CH_MODAL,
                      event.target.value.trim()
                    );
                  }}
                  onChange={(event) => {
                    state.formModal.setFieldValue(
                      NAME.CH_MODAL,
                      event.target.value.replaceAll(" ", "")
                    );
                  }}
                  onKeyDown={(event) => {
                    if (event.code === "Space") {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>

              <Form.Item {...FIELDS_CONFIG.TEMPERATURE_DISTINCTION}>
                <Select
                  placeholder={PLACEHOLDER_SELECT}
                  allowClear={false}
                  size="large"
                  options={TEMPERATURE_TYPE_SELECT}
                  suffixIcon={
                    <ArrowSelect disable={state.disableFields} isModal />
                  }
                />
              </Form.Item>
            </div>

            <div className="device-page_modal-form-right">
              <Form.Item {...FIELDS_CONFIG.STORE_MODAL}>
                <Select
                  showSearch
                  optionFilterProp="label"
                  placeholder={PLACEHOLDER_SELECT}
                  allowClear={false}
                  size="large"
                  options={state.storesModal}
                  disabled={state.loadingStoreModal || state.disableFieldsEdit}
                  suffixIcon={
                    state.loadingStoreModal ? (
                      <LoadingOutlined spin={state.loadingStoreModal} />
                    ) : (
                      <ArrowSelect disable={state.disableFieldsEdit} isModal />
                    )
                  }
                />
              </Form.Item>

              <Form.Item {...FIELDS_CONFIG.INSTALL_LOCATION}>
                <Select
                  placeholder={PLACEHOLDER_SELECT}
                  allowClear={false}
                  size="large"
                  options={INSTALL_LOCATION_SELECT}
                  suffixIcon={
                    <ArrowSelect disable={state.disableFields} isModal />
                  }
                />
              </Form.Item>

              <Form.Item {...FIELDS_CONFIG.MACHINE}>
                <Input
                  placeholder={PLACEHOLDER_INPUT}
                  size="large"
                  maxLength={MAX_LENGTH_255}
                  onBlur={(event) => {
                    state.formModal.setFieldValue(
                      NAME.MACHINE,
                      event.target.value.trim()
                    );
                  }}
                />
              </Form.Item>

              <Form.Item {...FIELDS_CONFIG.MANUFACTURER_MODEL_MODAL}>
                <Input
                  placeholder={PLACEHOLDER_INPUT}
                  size="large"
                  maxLength={MAX_LENGTH_255}
                  onBlur={(event) => {
                    state.formModal.setFieldValue(
                      NAME.MANUFACTURER_MODEL_MODAL,
                      event.target.value.trim()
                    );
                  }}
                />
              </Form.Item>

              <Form.Item {...FIELDS_CONFIG.IP_ADDRESS}>
                <Input
                  placeholder={PLACEHOLDER_INPUT}
                  size="large"
                  maxLength={MAX_LENGTH_255}
                  onBlur={(event) => {
                    state.formModal.setFieldValue(
                      NAME.IP_ADDRESS,
                      event.target.value.trim()
                    );
                  }}
                />
              </Form.Item>
            </div>
          </div>

          <div className="device-page_modal-form-flex">
            <div className="device-page_modal-form-left">
              <Form.Item {...FIELDS_CONFIG.OPEN_DATE}>
                <DatePicker
                  showNow={false}
                  size="large"
                  format={DATE_FORMAT}
                  inputReadOnly={true}
                  disabledDate={handler.handleDisableFromDate}
                  suffixIcon={<DatePickerIcon disable={state.disableFields} />}
                />
              </Form.Item>

              <Form.Item {...FIELDS_CONFIG.TIME_START}>
                <TimePicker
                  format={TIME_PICKER_FORMAT}
                  size="large"
                  className="device-page_modal-time"
                  showNow={false}
                  needConfirm={false}
                  inputReadOnly={true}
                  suffixIcon={
                    <ClockCircleOutlined
                      style={{
                        color: state.disableFields ? "#98A2B3" : "#344054",
                      }}
                    />
                  }
                />
              </Form.Item>
            </div>
            <div className="device-page_modal-form-right">
              <Form.Item {...FIELDS_CONFIG.CLOSE_DATE}>
                <DatePicker
                  showNow={false}
                  size="large"
                  format={DATE_FORMAT}
                  inputReadOnly={true}
                  disabledDate={handler.handleDisableToDate}
                  suffixIcon={<DatePickerIcon disable={state.disableFields} />}
                />
              </Form.Item>

              <Form.Item {...FIELDS_CONFIG.TIME_END}>
                <TimePicker
                  format={TIME_PICKER_FORMAT}
                  size="large"
                  className="device-page_modal-time"
                  showNow={false}
                  needConfirm={false}
                  inputReadOnly={true}
                  suffixIcon={
                    <ClockCircleOutlined
                      style={{
                        color: state.disableFields ? "#98A2B3" : "#344054",
                      }}
                    />
                  }
                />
              </Form.Item>
            </div>
          </div>

          <div>
            <Collapse
              collapsible={
                state.modalType === EModalType.REGISTRATION ||
                state.modalType === EModalType.UPDATE
                  ? undefined
                  : "disabled"
              }
              className="device-page_collapse"
              bordered={false}
              activeKey={state.keyPurpose}
              accordion
              size="large"
              items={getItems(panelStyle)}
              onChange={handler.handleChangeCollapsePurpose}
            />
          </div>

          <div>
            <Collapse
              className="device-page_collapse-season"
              bordered={false}
              expandIconPosition="end"
              activeKey={state.keySeason}
              expandIcon={(props: any) => {
                if (state.keySeason.includes(props?.panelKey)) {
                  return <ArrowSelect />;
                }
                return <RightOutlined />;
              }}
              size="large"
              items={getItemsSeason(panelStyle)}
              onChange={handler.handleChangeCollapseSeason}
            />
          </div>
        </Form>
      </ModalWrapper>

      {/* Modal delete */}
      <ModalDeleteWrapper
        centered
        closeIcon={false}
        open={state.modalDelete}
        title={TITLE.TITLE_DELETE}
        confirmLoading={state.loadingPage}
        onOk={handler.handleOkModalDelete}
        onCancel={handler.handleCloseModalDelete}
        maskClosable={false}
        cancelText={TITLE.BTN_CANCEL}
        okText={TITLE.TITLE_DELETE}
        width={416}
      >
        <img src={images.modalDelete} alt="modal_delete" />
        <div className="device-page_modal-delete">
          <p>{state.device?.chCode}</p>
          <p>{MESSAGE.DELETE_DEVICE}</p>
        </div>
      </ModalDeleteWrapper>

      {/* Modal registration/update/delete success/fail */}
      <ModalDeleteWrapper
        centered
        closeIcon={false}
        open={state.modalSuccess}
        title={state.renderTextModal.title}
        onOk={handler.handleCloseModalSuccess}
        maskClosable={false}
        okText={state.renderTextModal.okText}
        width={412}
        okButtonProps={{ style: { width: "100%", marginInlineStart: 0 } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <img src={state.renderTextModal.image} alt="store_modal" />
        <div className="device-page_modal-regist-success">
          <p>{state.renderTextModal.message}</p>
        </div>
      </ModalDeleteWrapper>

      {/* Modal registration/update confirm */}
      <ModalDeleteWrapper
        centered
        closeIcon={false}
        open={state.modalConfirm}
        title={state.renderTextModalConfirm.title}
        onOk={handler.handleOkModalConfirmFinal}
        onCancel={handler.handleCloseModalConfirmFinal}
        maskClosable={false}
        okText={state.renderTextModalConfirm.okText}
        cancelText={TITLE.BTN_CANCEL}
        width={412}
        zIndex={9999}
        confirmLoading={state.loadingPage}
      >
        <img src={images.popUpConfirmFinal} alt="device_modal" />
        <div className="device-page_modal-confirm">
          <p>{state.renderTextModalConfirm.message}</p>
        </div>
      </ModalDeleteWrapper>
    </>
  );
}

export { DevicePage };
