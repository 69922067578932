export const MASTER_DATA_CONSTANT = {
  TITLE: {
    MASTER_DATA: "マスタデータ",
    // Tabs
    USER: "ユーザ",
    COMPANY: "会社",
    DISTRICT: "地区",
    STORE: "店舗",
    DEVICE: "デバイス",
    MANUFACTURER: "メーカ",
    PURPOSE: "用途（売場名)",
    DEPARTMENT: "部門",

    BTN_IMPORT: "インポート",

    // Modal
    TITLE_FAIL: "データの読み込みに失敗しました",
    BTN_CLOSE: "閉じる",

    // Column file
    COMPANY_CODE: "会社・事業部コード",
    COMPANY_NAME: "会社、事業部名",

    AREA_CODE: "地区コード",
    AREA_NAME: "地区名",

    MANUFACTURER_CODE: "メーカコード",
    MANUFACTURER_NAME: "メーカ名",

    PURPOSE_CODE: "用途コード(売場）",
    PURPOSE_NAME: "用途名",
    DEPARTMENT_NAME: "部門名",
    TEMPERATURE_LOWER: "基準管理温度下限",
    TEMPERATURE_UPPER: "基準管理温度上限",

    ID: "ID",
    PASSWORD: "パスワード",
    ROLE: "ロール",
    STORE_CODE: "店舗コード",

    STORE_NAME: "店舗名",
    OPENING_DATE: "開店年月日",
    CLOSING_DATE: "閉店年月日",
    LAND_AREA: "売場面積",
    EMAIL: "メールアドレス",
    MANAGER_NAME: "店長名",
    MOBILE_PHONE: "店舗携帯電話",

    ID_MANAGEMENT: "ID-管理CH",
    MANUFACTURER_MODEL: "メーカ型式",
    DATE_INTRODUCTION: "導入年月日",
    DATE_DISPOSAL: "廃棄年月日",
    INSTALL_LOCATION: "設置場所区分",
    TEMPERATURE_CLASSIFICATION: "温度区分",
    SPRING_DATE: "開始日（春）,開始",
  },
  MESSAGE: {
    NO_COMPANY: "インポートできるファイル形式はCSVのみです。",
    IMPORT_SUCCESS: "インポートに成功しました。",
    ERROR_LINE: "行目にエラーが発生しました。",
    ERROR_COLUMN: "にデータがないか、データの形式は正しくありません。",
    FILE_FORMAT: "ファイル形式またはファイル拡張子が正しくありません。",
    FILE_LARGE:
      "ファイルサイズが大きいため、インポートできません。10MBより大きいファイルは利用できません。",
    FILE_INCORRECT: "ファイルのフォーマットが正しくありません。",
    ERROR_HEADER_CODE: "Column name is not correct",
    CHCODE_CHARACTER_ENG: "CH code of Okamura is required 3 characters.",
    CHCODE_CHARACTER_JP: "OkamuraのID－管理CHは3文字の入力が必要です。",
  },
};

export const MAX_SIZE = 10485760;
