import logoForm from "@assets/images/logo.png";
import background_login from "@assets/images/bg.jpg";
import confirmApproveTemperature from "@assets/images/confirm_approve_temperature.png";
import periodTime from "@assets/images/period_time.png";
import noResult from "@assets/images/no_result.png";
import noDataTable from "@assets/images/no_data.png";
import importData from "@assets/images/import_data.png";
import importDataFail from "@assets/images/popup_fail.png";
import logout from "@assets/images/logout.png";
import modalDelete from "@assets/images/modal_delete.png";
import modalFileManage from "@assets/images/file_manage.png";
import popUpConfirmFinal from "@assets/images/popup_confirm_final.png";

const images = {
  logoForm,
  confirmApproveTemperature,
  periodTime,
  background_login,
  noResult,
  noDataTable,
  importData,
  importDataFail,
  logout,
  modalDelete,
  modalFileManage,
  popUpConfirmFinal,
};

export default images;
