type TLabelFormProps = {
  label: string;
};

function LabelForm({ label }: TLabelFormProps) {
  return (
    <>
      <span style={{ color: "#d92d20", marginRight: "4px" }}>*</span> {label}
    </>
  );
}

export { LabelForm };
