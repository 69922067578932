import { TSelectValue } from "@models/common";
import { FormInstance } from "antd/lib";
import { STATISTICS_CONSTANT } from "../constant";
import {
  TAllReportReq,
  TChildrenAllReport,
  TConvertAllReport,
  TExportAllReportReq,
} from "@models/report";
import { Dayjs } from "dayjs";
import { allReportApi } from "@services/allReportApi";
import { handleShowToastError } from "@utils/helpers";
import { DATE_HYPHEN_FORMAT } from "@constants/common";
import {
  handleConvertAllReport,
  handleConvertAllReportSensor,
} from "../utils/helpers";
import _ from "lodash";
import { layoutApi } from "@services/layoutApi";

const { NAME } = STATISTICS_CONSTANT;

function useStatisticsApi() {
  const callApiExportFile = async (
    fromDate: Dayjs,
    toDate: Dayjs,
    company: TSelectValue,
    stores: TSelectValue[][],
    setLoadingPage: (val: boolean) => void,
    handleCloseModalExport: () => void
  ) => {
    while (stores.length > 0) {
      const apiCalls = stores[0].map((item) =>
        exportAllReport(
          {
            dateStart: fromDate.format(DATE_HYPHEN_FORMAT),
            dateEnd: toDate.format(DATE_HYPHEN_FORMAT),
            companyCode: company.value,
            storeCode: item.value,
          },
          setLoadingPage
        )
      );

      await Promise.allSettled(apiCalls).finally(() => {
        if (stores.length === 1) {
          setLoadingPage(false);
          handleCloseModalExport();
        }
      });
      stores.shift();
      await new Promise((resolve) => setTimeout(resolve, 1500));
    }
  };

  const handleExportFile = (
    fromDate: Dayjs,
    toDate: Dayjs,
    companies: TSelectValue[],
    storesCode: TSelectValue[],
    companyCode: string,
    setLoadingPage: (val: boolean) => void,
    handleCloseModalExport: () => void
  ) => {
    const findCompany = _.find(companies, function (val) {
      return val.value === companyCode;
    });
    const _storesCode = _.chunk(storesCode, 6);
    if (findCompany) {
      callApiExportFile(
        fromDate,
        toDate,
        findCompany,
        _storesCode,
        setLoadingPage,
        handleCloseModalExport
      );
    }
  };

  const handleConvertData = (
    id: string,
    listAllReport: TConvertAllReport[],
    newDataAllReportSensor: TChildrenAllReport[],
    setListAllReport: (val: TConvertAllReport[]) => void
  ) => {
    const convertData = listAllReport.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          children: [...item.children, ...newDataAllReportSensor],
        };
      }
      return item;
    });
    setListAllReport(convertData);
  };

  // EFFECT
  const effectCompanies = (
    companies: TSelectValue[],
    form: FormInstance,
    searchParams: URLSearchParams,
    setCode: (val: string) => void
  ) => {
    const companyCode = searchParams.get("companyCode");

    if (companyCode) {
      form.setFieldValue(NAME.COMPANY, companyCode);
      setCode(companyCode);
    } else {
      if (companies.length) {
        form.setFieldValue(NAME.COMPANY, companies[0].value);
      }
    }
  };

  // API
  const exportAllReport = async (
    payload: TExportAllReportReq,
    setLoadingPage: (val: boolean) => void
  ) => {
    setLoadingPage(true);
    try {
      const response = await allReportApi.exportAllReport(payload);

      if (response.ok) {
        const base64 = `data:text/csv;base64,${response.data.fileData}`;
        const elementAnchor = document.createElement("a");
        elementAnchor.href = base64;
        elementAnchor.download = response.data.fileName; // File name
        document.body.appendChild(elementAnchor);
        elementAnchor.click();
        document.body.removeChild(elementAnchor);
      }
    } catch (error) {
      handleShowToastError(error);
    }
  };

  const getCompanies = async (
    setCompanies: (val: TSelectValue[]) => void,
    setCode: (val: string) => void,
    setLoadingCompany: (val: boolean) => void,
    handleSetSearchParams: (companyCode: string, reportDate: string) => void,
    setListAllReport: (val: TConvertAllReport[]) => void,
    setStoresCode: (val: TSelectValue[]) => void,
    setLoadingPage: (val: boolean) => void,
    setPageIndex: (val: number) => void,
    setTotalRecords: (val: number) => void,
    companyCode: string,
    reportDate: string
  ) => {
    try {
      const response = await layoutApi.getCompanies();

      if (response.ok) {
        const convertCompanies = response.data.map((val) => ({
          value: val.companyCode,
          label: val.companyName,
        }));

        if (companyCode || convertCompanies.length) {
          handleSetSearchParams(
            companyCode || convertCompanies[0].value,
            reportDate
          );
          const findCompany = convertCompanies.find(
            (item) => item.value === companyCode
          );

          setCompanies(convertCompanies);
          setCode(convertCompanies[0].value);
          getAllReportSearch(
            {
              companyCode: companyCode || convertCompanies[0].value,
              reportDate,
              pageIndex: 0,
            },
            findCompany || convertCompanies[0],
            setListAllReport,
            setStoresCode,
            setLoadingPage,
            setPageIndex,
            setTotalRecords
          );
        } else {
          setLoadingPage(false);
          setListAllReport([]);
          setStoresCode([]);
          setPageIndex(0), setTotalRecords(0);
        }
      }
    } catch (error) {
      handleShowToastError(error);
    } finally {
      setLoadingCompany(false);
    }
  };

  const getAllStoreCode = async (
    findCompany: TSelectValue,
    setStoresCode: (val: TSelectValue[]) => void
  ) => {
    try {
      const response = await layoutApi.getStoresByCompany({
        companyCode: findCompany.value,
        companyName: findCompany.label,
      });
      if (response.ok) {
        setStoresCode(
          response.data.map((item) => ({
            label: item.storeName,
            value: item.storeCode,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllReportSearch = async (
    payload: TAllReportReq,
    findCompany: TSelectValue,
    setListAllReport: (val: TConvertAllReport[]) => void,
    setStoresCode: (val: TSelectValue[]) => void,
    setLoadingPage: (val: boolean) => void,
    setPageIndex: (val: number) => void,
    setTotalRecords: (val: number) => void
  ) => {
    setLoadingPage(true);

    try {
      const response = await allReportApi.getAllReport(payload);

      if (response.ok) {
        const initialConvertData = handleConvertAllReport(
          response.data,
          payload.reportDate
        );
        await getAllStoreCode(findCompany, setStoresCode);
        setListAllReport(initialConvertData);
        setPageIndex(1);
        setTotalRecords(response.pagination?.totalCount as number);
      }
    } catch (error) {
      handleShowToastError(error);
      setStoresCode([]);
      setListAllReport([]);
      setTotalRecords(0);
      setPageIndex(0);
    } finally {
      setLoadingPage(false);
    }
  };

  const getAllReport = async (
    payload: TAllReportReq,
    listAllReport: TConvertAllReport[],
    pageIndex: number,
    setListAllReport: (val: TConvertAllReport[]) => void,
    setLoadingPage: (val: boolean) => void,
    setPageIndex: (val: number) => void,
    setTotalRecords: (val: number) => void
  ) => {
    setLoadingPage(true);

    try {
      const response = await allReportApi.getAllReport({
        ...payload,
        pageIndex,
      });

      if (response.ok) {
        setTotalRecords(response.pagination?.totalCount as number);
        // initial data
        if (!listAllReport.length) {
          const initialConvertData = handleConvertAllReport(
            response.data,
            payload.reportDate
          );
          setListAllReport(initialConvertData);
          setPageIndex(pageIndex + 1);
        }

        if (listAllReport.length !== response.pagination?.totalCount) {
          setListAllReport([
            ...listAllReport,
            ...handleConvertAllReport(response.data, payload.reportDate),
          ]);
          setPageIndex(pageIndex + 1);
        } else {
          // console.log("equal");
        }
      }
    } catch (error) {
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const getAllReportSensor = async (
    id: string,
    storeCode: string,
    date: string,
    listAllReport: TConvertAllReport[],
    setListAllReport: (val: TConvertAllReport[]) => void,
    setLoadingPage: (val: boolean) => void
  ) => {
    setLoadingPage(true);
    const checkListChildren = _.find(listAllReport, function (val) {
      return val.id === id;
    });

    if (checkListChildren && checkListChildren.children.length) {
      setLoadingPage(false);
    } else {
      try {
        const response = await allReportApi.getAllReportSensor(id, date);

        if (response.ok) {
          const newDataAllReportSensor = handleConvertAllReportSensor(
            response.data,
            storeCode,
            date
          );

          handleConvertData(
            id,
            listAllReport,
            newDataAllReportSensor,
            setListAllReport
          );
        }
      } catch (error) {
        handleShowToastError(error);
      } finally {
        setLoadingPage(false);
      }
    }
  };

  return {
    effect: {
      effectCompanies,
    },
    handler: {
      handleExportFile,

      exportAllReport,
      getAllReportSensor,
      getAllReportSearch,
      getAllReport,
      getCompanies,
    },
  };
}

export default useStatisticsApi;
