import { images } from "@constants";
import { Button, Form, Input, Typography } from "antd";
import { FormLoginWrapper, LoginPageWrapper } from "./LoginPageStyled";
import { LOGIN_CONSTANT } from "./constant";
import useLoginPage from "./hooks/useLoginPage";

const { LABEL, NAME, TITLE, PLACEHOLDER } = LOGIN_CONSTANT;
const { Title } = Typography;

const FIELDS_CONFIG = {
  ID: {
    required: false,
    label: LABEL.ID,
    name: NAME.USERNAME,
  },
  PASSWORD: {
    required: false,
    label: LABEL.PASSWORD,
    name: NAME.PASSWORD,
  },
};

const buildVersion = `${process.env.REACT_APP_BUILD_ENV}_${process.env.REACT_APP_BUILD_DATE}_${process.env.REACT_APP_BUILD_VERSION}`;

function LoginPage() {
  const { state, handler } = useLoginPage();

  return (
    <LoginPageWrapper>
      <div>
        <div className="background-form" />
      </div>
      <FormLoginWrapper changeheight={state.messageError}>
        <div className="logo-form">
          <img src={images.logoForm} alt="logo-form" />
        </div>

        <Form
          name="login"
          onFinish={handler.handleSubmitFormSuccess}
          onFinishFailed={handler.handleSubmitFormFail}
          layout="vertical"
          className="login-form"
          form={state.form}
        >
          <div className="login-form_title">
            <Title level={3}>{TITLE.LOGIN}</Title>
            <p>{TITLE.DESCRIPTION}</p>
          </div>
          <Form.Item
            {...FIELDS_CONFIG.ID}
            style={{ marginBottom: 16 }}
            className="id-input"
          >
            <Input
              ref={state.idRef}
              tabIndex={0}
              allowClear
              size="large"
              autoComplete="off"
              placeholder={PLACEHOLDER.INPUT}
            />
          </Form.Item>
          <Form.Item
            {...FIELDS_CONFIG.PASSWORD}
            style={{ marginBottom: state.messageError ? "16px" : "24px" }}
          >
            <Input.Password
              size="large"
              autoComplete="off"
              placeholder={PLACEHOLDER.INPUT}
            />
          </Form.Item>

          {state.messageError && (
            <p className="logo-form_message-error">{state.messageError}</p>
          )}

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={state.isLoading}
              className="login-form_button"
              disabled={!state.idWatch || !state.passwordWatch}
            >
              {TITLE.LOGIN}
            </Button>
          </Form.Item>

          <div className="ver-build">{buildVersion}</div>
        </Form>
      </FormLoginWrapper>
    </LoginPageWrapper>
  );
}
export { LoginPage };
