import datePickerTemperature from "@assets/svgs/datepicker.svg";
import user from "@assets/svgs/user.svg";
import userApprove from "@assets/svgs/user_approve.svg";
import statisticsDownIcon from "@assets/svgs/statistics_tickdown.svg";
import statisticsUpIcon from "@assets/svgs/statistics_tickup.svg";
import infoUserIcon from "@assets/svgs/info_icon.svg";

export { ReactComponent as TemperatureReportIcon } from "@assets/svgs/tempereture_report.svg";
export { ReactComponent as AlarmConditionIcon } from "@assets/svgs/alarm_condition.svg";
export { ReactComponent as FileManagementIcon } from "@assets/svgs/file_management.svg";
export { ReactComponent as LayoutDiagramIcon } from "@assets/svgs/layout_diagram.svg";
export { ReactComponent as MasterDataIcon } from "@assets/svgs/master_data.svg";
export { ReactComponent as SettingIcon } from "@assets/svgs/setting.svg";
export { ReactComponent as SpreadsheetIcon } from "@assets/svgs/spreadsheet.svg";
export { ReactComponent as StatusInquiryIcon } from "@assets/svgs/status_inquiry.svg";
export { ReactComponent as UserSidebarIcon } from "@assets/svgs/user_sidebar.svg";
export { ReactComponent as TemperatureDetailIcon } from "@assets/svgs/temperature_detail.svg";
export { ReactComponent as ThreeDotIcon } from "@assets/svgs/three_dot.svg";
export { ReactComponent as ArrowLeftIcon } from "@assets/svgs/arrow_left.svg";
export { ReactComponent as SearchIcon } from "@assets/svgs/search_icon.svg";
export { ReactComponent as MapIcon } from "@assets/svgs/map_icon.svg";
export { ReactComponent as RotateRightIcon } from "@assets/svgs/rotate_right.svg";
export { ReactComponent as RotateLeftIcon } from "@assets/svgs/rotate_left.svg";
export { ReactComponent as InfoUserIcon } from "@assets/svgs/info_user.svg";
export { ReactComponent as InfoCallIcon } from "@assets/svgs/info_call.svg";
export { ReactComponent as InfoSMSIcon } from "@assets/svgs/info_sms.svg";
export { ReactComponent as StoreIcon } from "@assets/svgs/shop.svg";
export { ReactComponent as DeviceIcon } from "@assets/svgs/device_icon.svg";
export { ReactComponent as PurposeIcon } from "@assets/svgs/purpose_icon.svg";
export { ReactComponent as UserListIcon } from "@assets/svgs/user_list.svg";
export { ReactComponent as CompanyIcon } from "@assets/svgs/company_icon.svg";

const svgs = {
  user,
  infoUserIcon,
  datePickerTemperature,
  userApprove,
  statisticsDownIcon,
  statisticsUpIcon,
};

export default svgs;
