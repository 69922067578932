import { Form, Input, InputNumber, TableProps } from "antd";
import { OPEN_CASE_CONSTANT, RADIO_OPT } from "./constant";
import { Dayjs } from "dayjs";
import {
  THourTemperature,
  TReportTemperature,
  TTableDefrost,
  TTableSetting,
} from "@models/device";
import { renderClassNameHourTemp } from "./utils/helpers";
import { FormInstance } from "antd/lib";
import {
  DATA_EMPTY,
  REGEX_CHECK_FORMAT_TEMP,
  REGEX_FORMAT_TEMPERATURE,
} from "@constants/common";

const { TITLE, TABLE_KEY, NAME, PLACEHOLDER } = OPEN_CASE_CONSTANT;
const listKeysAllow = [
  "Backspace",
  "Tab",
  "Shift",
  "Period",
  "NumpadDecimal",
  "ArrowRight",
  "ArrowLeft",
];

export const columns: TableProps<TTableDefrost>["columns"] = [
  {
    key: TABLE_KEY.WEATHER,
    dataIndex: TABLE_KEY.WEATHER,
    title: "",
    align: "center",
    width: "226px",
  },
  {
    key: TABLE_KEY.START_DATE,
    dataIndex: TABLE_KEY.START_DATE,
    title: TITLE.START_DATE,
    align: "center",
    width: "226px",
  },
  {
    key: TABLE_KEY.START_DATE_1,
    dataIndex: TABLE_KEY.START_DATE_1,
    title: TITLE.START_DATE_1,
    align: "center",
    width: "226px",
  },
  {
    key: TABLE_KEY.START_DATE_2,
    dataIndex: TABLE_KEY.START_DATE_2,
    title: TITLE.START_DATE_2,
    align: "center",
    width: "226px",
  },
  {
    key: TABLE_KEY.START_DATE_3,
    dataIndex: TABLE_KEY.START_DATE_3,
    title: TITLE.START_DATE_3,
    align: "center",
    width: "226px",
  },
  {
    key: TABLE_KEY.START_DATE_4,
    dataIndex: TABLE_KEY.START_DATE_4,
    title: TITLE.START_DATE_4,
    align: "center",
    width: "226px",
  },
  {
    key: TABLE_KEY.START_DATE_5,
    dataIndex: TABLE_KEY.START_DATE_5,
    title: TITLE.START_DATE_5,
    align: "center",
    width: "226px",
  },
  {
    key: TABLE_KEY.START_DATE_6,
    dataIndex: TABLE_KEY.START_DATE_6,
    title: TITLE.START_DATE_6,
    align: "center",
    width: "226px",
  },
];

export const getColumns2 = (
  radioOpt: number,
  form: FormInstance
): TableProps<TTableSetting>["columns"] => {
  return [
    {
      key: TABLE_KEY.INDIVIDUAL,
      dataIndex: TABLE_KEY.INDIVIDUAL,
      title: "",
      align: "center",
      width: "300px",
      render(value) {
        return (
          <>
            {value} <span style={{ color: "#D92D20" }}>*</span>
          </>
        );
      },
    },
    {
      key: TABLE_KEY.PRESET_TEMPERATURE,
      dataIndex: TABLE_KEY.PRESET_TEMPERATURE,
      title: TITLE.PRESET_TEMPERATURE,
      align: "center",
      width: "328px",
      render: (_, __, index) => {
        const nameForm = !index ? NAME.LOWER_NUMBER : NAME.UPPER_NUMBER;

        return (
          <Form.Item name={nameForm}>
            <InputNumber
              onBlur={(event) => {
                let _value = "";
                const parts = event.target.value.split(".");
                _value = parts[0] + (parts[1] ? `.${parts[1][0]}` : "");
                const formattedValue = _value.replace(
                  REGEX_FORMAT_TEMPERATURE,
                  "$1$2"
                );
                if (
                  formattedValue &&
                  !formattedValue.match(REGEX_CHECK_FORMAT_TEMP)
                ) {
                  form.setFieldValue(nameForm, "0");
                } else {
                  form.setFieldValue(nameForm, formattedValue);
                }
              }}
              step="0.1"
              disabled={radioOpt === 1}
              controls={false}
              keyboard={false}
              size="large"
              onKeyDown={(event: any) => {
                if (
                  /^-?\d*$/.test(event.key) ||
                  listKeysAllow.includes(event.code)
                ) {
                  return;
                }
                event.preventDefault();
              }}
            />
          </Form.Item>
        );
      },
    },
    {
      key: TABLE_KEY.MASK_REASON,
      dataIndex: TABLE_KEY.MASK_REASON,
      title: TITLE.MASK_REASON,
      align: "center",
      onCell: (_, index) => {
        if (index && index % RADIO_OPT !== 0) {
          return { rowSpan: 0 };
        }
        return { rowSpan: 2 };
      },
      render: () => {
        return (
          <Form.Item name={NAME.SETTING}>
            <Input.TextArea
              disabled={radioOpt === 1}
              rows={6}
              placeholder={PLACEHOLDER.COMMENT}
              maxLength={200}
              showCount
            />
          </Form.Item>
        );
      },
    },
  ];
};

const renderHourTemp = (value: any) => {
  if (value[0] === "なし") {
    return DATA_EMPTY;
  } else {
    if (value[2] || value[4] || value[3] === false) {
      return (
        <span style={{ textDecorationLine: "line-through" }}>{value[0]}</span>
      );
    } else {
      return (
        <span className={renderClassNameHourTemp(value[1])}>{value[0]}</span>
      );
    }
  }
};

export const columns3: TableProps<THourTemperature>["columns"] = [
  {
    key: TABLE_KEY.TIME,
    dataIndex: TABLE_KEY.TIME,
    title: TITLE.TIME,
    align: "center",
    width: "80px",
  },
  {
    key: TABLE_KEY.HOUR_0,
    dataIndex: TABLE_KEY.HOUR_0,
    title: TITLE.HOUR_0,
    align: "center",
    render: (value) => renderHourTemp(value),
    width: "72px",
  },
  {
    key: TABLE_KEY.HOUR_1,
    dataIndex: TABLE_KEY.HOUR_1,
    title: TITLE.HOUR_1,
    align: "center",
    render: (value) => renderHourTemp(value),
    width: "72px",
  },
  {
    key: TABLE_KEY.HOUR_2,
    dataIndex: TABLE_KEY.HOUR_2,
    title: TITLE.HOUR_2,
    align: "center",
    render: (value) => renderHourTemp(value),
    width: "72px",
  },
  {
    key: TABLE_KEY.HOUR_3,
    dataIndex: TABLE_KEY.HOUR_3,
    title: TITLE.HOUR_3,
    align: "center",
    render: (value) => renderHourTemp(value),
    width: "72px",
  },
  {
    key: TABLE_KEY.HOUR_4,
    dataIndex: TABLE_KEY.HOUR_4,
    title: TITLE.HOUR_4,
    align: "center",
    render: (value) => renderHourTemp(value),
    width: "72px",
  },
  {
    key: TABLE_KEY.HOUR_5,
    dataIndex: TABLE_KEY.HOUR_5,
    title: TITLE.HOUR_5,
    align: "center",
    render: (value) => renderHourTemp(value),
    width: "72px",
  },
  {
    key: TABLE_KEY.HOUR_6,
    dataIndex: TABLE_KEY.HOUR_6,
    title: TITLE.HOUR_6,
    align: "center",
    render: (value) => renderHourTemp(value),
    width: "72px",
  },
  {
    key: TABLE_KEY.HOUR_7,
    dataIndex: TABLE_KEY.HOUR_7,
    title: TITLE.HOUR_7,
    align: "center",
    render: (value) => renderHourTemp(value),
    width: "72px",
  },
  {
    key: TABLE_KEY.HOUR_8,
    dataIndex: TABLE_KEY.HOUR_8,
    title: TITLE.HOUR_8,
    align: "center",
    render: (value) => renderHourTemp(value),
    width: "72px",
  },
  {
    key: TABLE_KEY.HOUR_9,
    dataIndex: TABLE_KEY.HOUR_9,
    title: TITLE.HOUR_9,
    align: "center",
    render: (value) => renderHourTemp(value),
    width: "72px",
  },
  {
    key: TABLE_KEY.HOUR_10,
    dataIndex: TABLE_KEY.HOUR_10,
    title: TITLE.HOUR_10,
    align: "center",
    render: (value) => renderHourTemp(value),
    width: "72px",
  },
  {
    key: TABLE_KEY.HOUR_11,
    dataIndex: TABLE_KEY.HOUR_11,
    title: TITLE.HOUR_11,
    align: "center",
    render: (value) => renderHourTemp(value),
    width: "72px",
  },
  {
    key: TABLE_KEY.HOUR_12,
    dataIndex: TABLE_KEY.HOUR_12,
    title: TITLE.HOUR_12,
    align: "center",
    render: (value) => renderHourTemp(value),
    width: "72px",
  },
  {
    key: TABLE_KEY.HOUR_13,
    dataIndex: TABLE_KEY.HOUR_13,
    title: TITLE.HOUR_13,
    align: "center",
    render: (value) => renderHourTemp(value),
    width: "72px",
  },
  {
    key: TABLE_KEY.HOUR_14,
    dataIndex: TABLE_KEY.HOUR_14,
    title: TITLE.HOUR_14,
    align: "center",
    render: (value) => renderHourTemp(value),
    width: "72px",
  },
  {
    key: TABLE_KEY.HOUR_15,
    dataIndex: TABLE_KEY.HOUR_15,
    title: TITLE.HOUR_15,
    align: "center",
    render: (value) => renderHourTemp(value),
    width: "72px",
  },
  {
    key: TABLE_KEY.HOUR_16,
    dataIndex: TABLE_KEY.HOUR_16,
    title: TITLE.HOUR_16,
    align: "center",
    render: (value) => renderHourTemp(value),
    width: "72px",
  },
  {
    key: TABLE_KEY.HOUR_17,
    dataIndex: TABLE_KEY.HOUR_17,
    title: TITLE.HOUR_17,
    align: "center",
    render: (value) => renderHourTemp(value),
    width: "72px",
  },
  {
    key: TABLE_KEY.HOUR_18,
    dataIndex: TABLE_KEY.HOUR_18,
    title: TITLE.HOUR_18,
    align: "center",
    render: (value) => renderHourTemp(value),
    width: "72px",
  },
  {
    key: TABLE_KEY.HOUR_19,
    dataIndex: TABLE_KEY.HOUR_19,
    title: TITLE.HOUR_19,
    align: "center",
    render: (value) => renderHourTemp(value),
    width: "72px",
  },
  {
    key: TABLE_KEY.HOUR_20,
    dataIndex: TABLE_KEY.HOUR_20,
    title: TITLE.HOUR_20,
    align: "center",
    render: (value) => renderHourTemp(value),
    width: "72px",
  },
  {
    key: TABLE_KEY.HOUR_21,
    dataIndex: TABLE_KEY.HOUR_21,
    title: TITLE.HOUR_21,
    align: "center",
    render: (value) => renderHourTemp(value),
    width: "72px",
  },
  {
    key: TABLE_KEY.HOUR_22,
    dataIndex: TABLE_KEY.HOUR_22,
    title: TITLE.HOUR_22,
    align: "center",
    render: (value) => renderHourTemp(value),
    width: "72px",
  },
  {
    key: TABLE_KEY.HOUR_23,
    dataIndex: TABLE_KEY.HOUR_23,
    title: TITLE.HOUR_23,
    align: "center",
    render: (value) => renderHourTemp(value),
    width: "72px",
  },
];

const renderDateTable = (dayAgo: number, reportDate: Dayjs) => {
  return reportDate.subtract(dayAgo, "day").date();
};

export const getColumns4 = (
  reportDate: Dayjs
): TableProps<TReportTemperature>["columns"] => {
  return [
    {
      title: TITLE.DAY_CHART,
      dataIndex: TABLE_KEY.DAY_CHART,
      key: TABLE_KEY.DAY_CHART,
      align: "center",
      width: "92px",
    },
    {
      title: (
        <span className="open-case_table-third-chart-custom">
          <span>{renderDateTable(TITLE.DAY_21, reportDate)}</span>
          <span>21日前</span>
        </span>
      ),
      dataIndex: TABLE_KEY.DAY_AGO_21,
      key: TABLE_KEY.DAY_AGO_21,
      align: "center",
      width: "78px",
    },
    {
      title: (
        <span className="open-case_table-third-chart-custom">
          <span>{renderDateTable(TITLE.DAY_20, reportDate)}</span>
          <span>20日前</span>
        </span>
      ),
      dataIndex: TABLE_KEY.DAY_AGO_20,
      key: TABLE_KEY.DAY_AGO_20,
      align: "center",
      width: "78px",
    },
    {
      title: (
        <span className="open-case_table-third-chart-custom">
          <span>{renderDateTable(TITLE.DAY_19, reportDate)}</span>
          <span>19日前</span>
        </span>
      ),
      dataIndex: TABLE_KEY.DAY_AGO_19,
      key: TABLE_KEY.DAY_AGO_19,
      align: "center",
      width: "78px",
    },
    {
      title: (
        <span className="open-case_table-third-chart-custom">
          <span>{renderDateTable(TITLE.DAY_18, reportDate)}</span>
          <span>18日前</span>
        </span>
      ),
      dataIndex: TABLE_KEY.DAY_AGO_18,
      key: TABLE_KEY.DAY_AGO_18,
      align: "center",
      width: "78px",
    },
    {
      title: (
        <span className="open-case_table-third-chart-custom">
          <span>{renderDateTable(TITLE.DAY_17, reportDate)}</span>
          <span>17日前</span>
        </span>
      ),
      dataIndex: TABLE_KEY.DAY_AGO_17,
      key: TABLE_KEY.DAY_AGO_17,
      align: "center",
      width: "78px",
    },
    {
      title: (
        <span className="open-case_table-third-chart-custom">
          <span>{renderDateTable(TITLE.DAY_16, reportDate)}</span>
          <span>16日前</span>
        </span>
      ),
      dataIndex: TABLE_KEY.DAY_AGO_16,
      key: TABLE_KEY.DAY_AGO_16,
      align: "center",
      width: "78px",
    },
    {
      title: (
        <span className="open-case_table-third-chart-custom">
          <span>{renderDateTable(TITLE.DAY_15, reportDate)}</span>
          <span>15日前</span>
        </span>
      ),
      dataIndex: TABLE_KEY.DAY_AGO_15,
      key: TABLE_KEY.DAY_AGO_15,
      align: "center",
      width: "78px",
    },
    {
      title: (
        <span className="open-case_table-third-chart-custom">
          <span>{renderDateTable(TITLE.DAY_14, reportDate)}</span>
          <span>14日前</span>
        </span>
      ),
      dataIndex: TABLE_KEY.DAY_AGO_14,
      key: TABLE_KEY.DAY_AGO_14,
      align: "center",
      width: "78px",
    },
    {
      title: (
        <span className="open-case_table-third-chart-custom">
          <span>{renderDateTable(TITLE.DAY_13, reportDate)}</span>
          <span>13日前</span>
        </span>
      ),
      dataIndex: TABLE_KEY.DAY_AGO_13,
      key: TABLE_KEY.DAY_AGO_13,
      align: "center",
      width: "78px",
    },
    {
      title: (
        <span className="open-case_table-third-chart-custom">
          <span>{renderDateTable(TITLE.DAY_12, reportDate)}</span>
          <span>12日前</span>
        </span>
      ),
      dataIndex: TABLE_KEY.DAY_AGO_12,
      key: TABLE_KEY.DAY_AGO_12,
      align: "center",
      width: "78px",
    },
    {
      title: (
        <span className="open-case_table-third-chart-custom">
          <span>{renderDateTable(TITLE.DAY_11, reportDate)}</span>
          <span>11日前</span>
        </span>
      ),
      dataIndex: TABLE_KEY.DAY_AGO_11,
      key: TABLE_KEY.DAY_AGO_11,
      align: "center",
      width: "78px",
    },
    {
      title: (
        <span className="open-case_table-third-chart-custom">
          <span>{renderDateTable(TITLE.DAY_10, reportDate)}</span>
          <span>10日前</span>
        </span>
      ),
      dataIndex: TABLE_KEY.DAY_AGO_10,
      key: TABLE_KEY.DAY_AGO_10,
      align: "center",
      width: "78px",
    },
    {
      title: (
        <span className="open-case_table-third-chart-custom">
          <span>{renderDateTable(TITLE.DAY_9, reportDate)}</span>
          <span>9日前</span>
        </span>
      ),
      dataIndex: TABLE_KEY.DAY_AGO_9,
      key: TABLE_KEY.DAY_AGO_9,
      align: "center",
      width: "78px",
    },
    {
      title: (
        <span className="open-case_table-third-chart-custom">
          <span>{renderDateTable(TITLE.DAY_8, reportDate)}</span>
          <span>8日前</span>
        </span>
      ),
      dataIndex: TABLE_KEY.DAY_AGO_8,
      key: TABLE_KEY.DAY_AGO_8,
      align: "center",
      width: "78px",
    },
    {
      title: (
        <span className="open-case_table-third-chart-custom">
          <span>{renderDateTable(TITLE.DAY_7, reportDate)}</span>
          <span>7日前</span>
        </span>
      ),
      dataIndex: TABLE_KEY.DAY_AGO_7,
      key: TABLE_KEY.DAY_AGO_7,
      align: "center",
      width: "78px",
    },
    {
      title: (
        <span className="open-case_table-third-chart-custom">
          <span>{renderDateTable(TITLE.DAY_6, reportDate)}</span>
          <span>6日前</span>
        </span>
      ),
      dataIndex: TABLE_KEY.DAY_AGO_6,
      key: TABLE_KEY.DAY_AGO_6,
      align: "center",
      width: "78px",
    },
    {
      title: (
        <span className="open-case_table-third-chart-custom">
          <span>{renderDateTable(TITLE.DAY_5, reportDate)}</span>
          <span>5日前</span>
        </span>
      ),
      dataIndex: TABLE_KEY.DAY_AGO_5,
      key: TABLE_KEY.DAY_AGO_5,
      align: "center",
      width: "78px",
    },
    {
      title: (
        <span className="open-case_table-third-chart-custom">
          <span>{renderDateTable(TITLE.DAY_4, reportDate)}</span>
          <span>4日前</span>
        </span>
      ),
      dataIndex: TABLE_KEY.DAY_AGO_4,
      key: TABLE_KEY.DAY_AGO_4,
      align: "center",
      width: "78px",
    },
    {
      title: (
        <span className="open-case_table-third-chart-custom">
          <span>{renderDateTable(TITLE.DAY_3, reportDate)}</span>
          <span>3日前</span>
        </span>
      ),
      dataIndex: TABLE_KEY.DAY_AGO_3,
      key: TABLE_KEY.DAY_AGO_3,
      align: "center",
      width: "78px",
    },
    {
      title: (
        <span className="open-case_table-third-chart-custom">
          <span>{renderDateTable(TITLE.DAY_2, reportDate)}</span>
          <span>2日前</span>
        </span>
      ),
      dataIndex: TABLE_KEY.DAY_AGO_2,
      key: TABLE_KEY.DAY_AGO_2,
      align: "center",
      width: "78px",
    },
    {
      title: (
        <span className="open-case_table-third-chart-custom">
          <span>{renderDateTable(1, reportDate)}</span>
          <span>1日前</span>
        </span>
      ),
      dataIndex: TABLE_KEY.DAY_AGO_1,
      key: TABLE_KEY.DAY_AGO_1,
      align: "center",
      width: "78px",
    },
    {
      title: (
        <span className="open-case_table-third-chart-yesterday">
          {renderDateTable(0, reportDate)}
        </span>
      ),
      dataIndex: TABLE_KEY.DAY_AGO_0,
      key: TABLE_KEY.DAY_AGO_0,
      align: "center",
      width: "78px",
    },
  ];
};
