import { useAppSelector } from "@config/hooks";
import { Error_403 } from "@pages/ErrorPages/Error_403";
import React, { Fragment } from "react";
import {
  ActionFunction,
  LazyRouteFunction,
  LoaderFunction,
  Route,
  RouteObject,
  Routes,
  ShouldRevalidateFunction,
} from "react-router-dom";
import { ErrorRoute } from "./ErrorRoute/ErrorRoute";
import { PrivateRoute } from "./PrivateRoute/PrivateRoute";
import { getInvalidRoute, privateRoutes, publicRoutes } from "./routes";

export type TRouteType = {
  path?: string;
  index?: boolean;
  children?: React.ReactNode;
  caseSensitive?: boolean;
  id?: string;
  title?: string;
  loader?: LoaderFunction;
  action?: ActionFunction;
  element?: React.ReactNode | null;
  Component?: React.ComponentType | null;
  errorElement?: React.ReactNode | null;
  ErrorBoundary?: React.ComponentType | null;
  handle?: RouteObject["handle"];
  shouldRevalidate?: ShouldRevalidateFunction;
  lazy?: LazyRouteFunction<RouteObject>;
  permissions?: string[];
};

const renderRoute = (route: TRouteType, currentPermissions: any) => {
  const permissionRoute = route?.permissions ?? [];

  if (!permissionRoute.length) {
    return <Route key={route.id} path={route.path} element={route.element} />;
  }

  const notHasPermission = permissionRoute.includes(currentPermissions);

  if (notHasPermission) {
    return <Route key={route.id} path={route.path} element={route.element} />;
  }

  return null;
};

export const AppRouting = () => {
  const user = useAppSelector((state) => state.auth.currentUser);
  const currentPermissions = user?.role?.[0] ?? {};
  const invalidRoute = getInvalidRoute(user);

  return (
    <Routes>
      {user ? (
        <Fragment>
          <Route element={<PrivateRoute />}>
            {privateRoutes.map((route: TRouteType) => {
              return renderRoute(route, currentPermissions);
            })}
          </Route>
          <Route element={<ErrorRoute />}>
            {privateRoutes.map((route: TRouteType) => {
              return (
                <Route
                  key={route.id}
                  path={route.path}
                  element={<Error_403 />}
                />
              );
            })}
          </Route>
        </Fragment>
      ) : (
        <Route>
          {publicRoutes.map((route: TRouteType) => {
            return (
              <Route key={route.id} path={route.path} element={route.element} />
            );
          })}
        </Route>
      )}
      <Route
        key={invalidRoute.id}
        path={invalidRoute.path}
        element={invalidRoute.element}
      />
    </Routes>
  );
};
