export const DEVICE_CONSTANT = {
  TITLE: {
    DEVICE_LIST: "機器一覧",
    BTN_REGISTRATION: "新規登録",
    BTN_EXPORT: "エクスポート",

    // TABLE
    NO: "NO",
    COMPANY_CODE: "会社",
    STORE_CODE: "店舗",
    CH_CODE: "ID-管理CH",
    MANUFACTURER_CODE_COL: "メーカ名",
    MANUFACTURER_MODEL: "メーカ型式",
    ACTION: "アクション",

    BTN_CANCEL: "キャンセル",
    BTN_NEXT: "次へ",
    BTN_UPDATE: "変更 ",
    BTN_OK: "OK",
    BTN_REGISTRATION_MODAL: "登録 ",
    BTN_CLOSE: "閉じる",
    SUCCESS_TITLE: "用途成功",
    SUCCESS_UPDATE: "変更成功",
    SUCCESS_DELETE: "用途成功",
    FAIL_DELETE: "用途成功",

    // MODAL DELETE
    TITLE_DELETE: "削除 ",
    // MODAL REGISTRATION/UPDATE/DETAIL
    TITLE_UPDATE: "機器変更",
    TITLE_REGISTRATION: "機器登録",
    TITLE_DETAIL: "機器照会",

    CATEGORY_SWITCH: "カテゴリ",
    MANUAL_SWITCH: "個別警告温度",

    NOT_FOUND_DEVICE: "not_found_device",
  },
  LABEL: {
    COMPANY_CODE: "会社",
    STORE_CODE: "店舗",
    CH_CODE: "ID-管理CH",
    MANUFACTURER_CODE: "メーカ名",
    MANUFACTURER_MODEL: "メーカ型式",

    // MODAL
    COMPANY_MODAL: "会社・事業部",
    STORE_MODAL: "店舗名",
    MANUFACTURER_MODAL: "メーカコード",
    MANUFACTURER_MODEL_MODAL: "メーカ型式",
    BATTERY: "電池使用区分",
    CH_MODAL: "ID-管理CH",
    TEMPERATURE_DISTINCTION: "温度区分",
    INSTALL_LOCATION: "設置場所区分",
    OPEN_DATE: "導入年月日",
    CLOSE_DATE: "廃棄年月日",
    TIME_START: "稼働時間（始）",
    TIME_END: "稼働時間（終）",
    PURPOSE: "用途コード(売場）",
    PURPOSE_MIN: "下限",
    PURPOSE_MAX: "上限",
    MACHINE: "機器",
    IP_ADDRESS: "IPアドレス",
    LOWER_LIMIT: "設定温度下限 (℃ )",
    UPPER_LIMIT: "設定温度上限 (℃ )",
    REASON: "理由",
    START_DATE_SPRING: "開始日(春)",
    START_DATE: "開始日",
    START_TIME_1_SPRING: "開始時間➀（春）",
    START_TIME_2_SPRING: "開始時間➁（春）",
    START_TIME_3_SPRING: "開始時間➂（春）",
    START_TIME_4_SPRING: "開始時間➃（春）",
    START_TIME_5_SPRING: "開始時間➄（春）",
    START_TIME_6_SPRING: "開始時間➅（春）",
    START_DATE_SUMMER: "開始日(夏)",
    START_TIME_1_SUMMER: "開始時間➀（夏）",
    START_TIME_2_SUMMER: "開始時間➁（夏）",
    START_TIME_3_SUMMER: "開始時間➂（夏）",
    START_TIME_4_SUMMER: "開始時間➃（夏）",
    START_TIME_5_SUMMER: "開始時間➄（夏）",
    START_TIME_6_SUMMER: "開始時間➅（夏）",
    START_DATE_AUTUMN: "開始日(秋)",
    START_TIME_1_AUTUMN: "開始時間➀（秋）",
    START_TIME_2_AUTUMN: "開始時間➁（秋）",
    START_TIME_3_AUTUMN: "開始時間➂（秋）",
    START_TIME_4_AUTUMN: "開始時間➃（秋）",
    START_TIME_5_AUTUMN: "開始時間➄（秋）",
    START_TIME_6_AUTUMN: "開始時間➅（秋）",
    START_DATE_WINTER: "開始日(冬)",
    START_TIME_1_WINTER: "開始時間➀（冬）",
    START_TIME_2_WINTER: "開始時間➁（冬）",
    START_TIME_3_WINTER: "開始時間➂（冬）",
    START_TIME_4_WINTER: "開始時間➃（冬）",
    START_TIME_5_WINTER: "開始時間➄（冬）",
    START_TIME_6_WINTER: "開始時間➅（冬）",
  },
  NAME: {
    COMPANY_CODE: "companyId",
    STORE_CODE: "storeId",
    CH_CODE: "chCode",
    MANUFACTURER_CODE: "manufacturerCode",
    MANUFACTURER_MODEL: "modelCode",

    // MODAL
    COMPANY_MODAL: "companyCode",
    STORE_MODAL: "storeCode",
    CH_MODAL: "chCode",
    MANUFACTURER_MODAL: "manufacturerCode",
    MANUFACTURER_MODEL_MODAL: "manufacturerName",
    BATTERY: "batteryValue",
    TEMPERATURE_DISTINCTION: "temperatureType",
    INSTALL_LOCATION: "installedLocation",
    OPEN_DATE: "openDate",
    CLOSE_DATE: "closeDate",
    TIME_START: "timeStart",
    TIME_END: "timeEnd",
    PURPOSE: "purpose",
    PURPOSE_CODE: "purposeCode",
    PURPOSE_NAME: "purposeName",
    PURPOSE_MIN: "purposeMinTemp",
    PURPOSE_MAX: "purposeMaxTemp",
    MACHINE: "machine",
    IP_ADDRESS: "ipAddress",
    LOWER_LIMIT: "minTemp",
    UPPER_LIMIT: "maxTemp",
    REASON: "reasonSetting",
    START_DATE_SPRING: "springStartDate",
    START_TIME_1_SPRING: "springStartTime1",
    START_TIME_2_SPRING: "springStartTime2",
    START_TIME_3_SPRING: "springStartTime3",
    START_TIME_4_SPRING: "springStartTime4",
    START_TIME_5_SPRING: "springStartTime5",
    START_TIME_6_SPRING: "springStartTime6",
    START_DATE_SUMMER: "summerStartDate",
    START_TIME_1_SUMMER: "summerStartTime1",
    START_TIME_2_SUMMER: "summerStartTime2",
    START_TIME_3_SUMMER: "summerStartTime3",
    START_TIME_4_SUMMER: "summerStartTime4",
    START_TIME_5_SUMMER: "summerStartTime5",
    START_TIME_6_SUMMER: "summerStartTime6",
    START_DATE_AUTUMN: "autumnStartDate",
    START_TIME_1_AUTUMN: "autumnStartTime1",
    START_TIME_2_AUTUMN: "autumnStartTime2",
    START_TIME_3_AUTUMN: "autumnStartTime3",
    START_TIME_4_AUTUMN: "autumnStartTime4",
    START_TIME_5_AUTUMN: "autumnStartTime5",
    START_TIME_6_AUTUMN: "autumnStartTime6",
    START_DATE_WINTER: "winterStartDate",
    START_TIME_1_WINTER: "winterStartTime1",
    START_TIME_2_WINTER: "winterStartTime2",
    START_TIME_3_WINTER: "winterStartTime3",
    START_TIME_4_WINTER: "winterStartTime4",
    START_TIME_5_WINTER: "winterStartTime5",
    START_TIME_6_WINTER: "winterStartTime6",
  },
  MESSAGE: {
    NOT_FOUND_DEVICE:
      "他の人がデータを更新したため、更新できません。 データベースからデータをリロードしてください。",
    DELETE_DEVICE: "この機器を削除します。よろしいですか？",
    UPDATE_DEVICE: "機器から変更します、よろしいでしょうか?",
    UPDATE_SUCCESS: "機器が変更に成功された。",
    REGISTER_DEVICE: "機器から登録します、よろしいでしょうか?",
    REGISTER_SUCCESS: "機器が登録に成功された。",
    SUCCESS_DELETE: "機器が削除に成功された。",
    FAIL_REGISTER: "機器が登録に失敗された。",
    FAIL_UPDATE: "機器が変更に失敗された。",
    FAIL_DELETE: "機器が削除に失敗された。",
    CONFIRM_UPDATE_FINAL: "この機器を変更します。よろしいですか？",

    NO_DATA: "表示するデータがありません。",
    CONFIRM_REGISTRATION_FINAL: "この機器を登録します。よろしいですか？",
    LOWER_UPPER: "温度下限 <= 温度上限である必要があります。",
    START_END: "稼働時間（始）<= 稼働時間（終）である必要があります。",

    FIELD_INVALID_FORMAT: "値の形式は正しくありません。",
    FIELD_INVALID: "値は無効です。",
    DATA_EXIST: "値はすでに存在しています。",
  },
  TABLE_KEY: {
    NO: "key",
    COMPANY_CODE: "companyCode",
    STORE_CODE: "storeCode",
    CH_CODE: "chCode",
    MANUFACTURER_CODE: "manufacturerCode",
    MANUFACTURER_MODEL: "modelCode",
  },
  PLACEHOLDER: {
    FROM_HOUR: "何時から",
    TO_HOUR: "何時まで",
    COMMENT: "コメントを入力してください",
  },
};

export const HEIGHT_TABLE_SCROLL = 685;
export const LIST_KEYS_ALLOW_PURPOSE = [
  "Backspace",
  "Tab",
  "Shift",
  "Period",
  "NumpadDecimal",
  "ArrowRight",
  "ArrowLeft",
];
export const DATE_MONTH_FORMAT = "MM/DD";

export const BATTERY_SELECT = [
  { label: "0_使用しない", value: "0" },
  { label: "1_使用する", value: "1" },
];

export const TEMPERATURE_TYPE_SELECT = [
  { label: "1_冷蔵", value: "冷蔵" },
  { label: "2_冷凍", value: "冷凍" },
  { label: "3_非冷", value: "非冷" },
  { label: "9_使用しない", value: "使用しない" },
];

export const INSTALL_LOCATION_SELECT = [
  { label: "0_売場", value: "売場" },
  { label: "1_バック", value: "バック" },
];
