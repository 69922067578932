import { DatePicker, Form } from "antd";
import TimePickerItem from "./TimePickerItem";
import { DATE_MONTH_FORMAT } from "../constant";
import { DatePickerIcon } from "@components";

type TDateSeasonProps = {
  date: any;
  dateOne: any;
  dateTwo: any;
  dateThree: any;
  dateFour: any;
  dateFive: any;
  dateSix: any;
  valueWatch: any;
  disableTime: boolean;
  handleDateTimeChange: (name: string) => void;
};

function DateSeason(props: TDateSeasonProps) {
  const {
    date,
    dateOne,
    dateTwo,
    dateThree,
    dateFour,
    dateFive,
    dateSix,
    valueWatch,
    disableTime,
    handleDateTimeChange,
  } = props;

  return (
    <>
      <Form.Item {...date}>
        <DatePicker
          style={{ width: "100%" }}
          showNow={false}
          size="large"
          format={DATE_MONTH_FORMAT}
          inputReadOnly={true}
          suffixIcon={<DatePickerIcon disable={disableTime} />}
          onChange={() => handleDateTimeChange(date.name)}
        />
      </Form.Item>

      <div className="device-page_modal-form-flex">
        <div className="device-page_modal-form-left">
          <Form.Item {...dateOne}>
            <TimePickerItem disableTime={disableTime} valueWatch={valueWatch} />
          </Form.Item>

          <Form.Item {...dateThree}>
            <TimePickerItem disableTime={disableTime} valueWatch={valueWatch} />
          </Form.Item>

          <Form.Item style={{ marginBottom: 0 }} {...dateFive}>
            <TimePickerItem disableTime={disableTime} valueWatch={valueWatch} />
          </Form.Item>
        </div>

        <div className="device-page_modal-form-right">
          <Form.Item {...dateTwo}>
            <TimePickerItem disableTime={disableTime} valueWatch={valueWatch} />
          </Form.Item>

          <Form.Item {...dateFour}>
            <TimePickerItem disableTime={disableTime} valueWatch={valueWatch} />
          </Form.Item>

          <Form.Item style={{ marginBottom: 0 }} {...dateSix}>
            <TimePickerItem disableTime={disableTime} valueWatch={valueWatch} />
          </Form.Item>
        </div>
      </div>
    </>
  );
}

export default DateSeason;
