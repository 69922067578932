export const USER_CONSTANT = {
  TITLE: {
    USER_LIST: "ユーザー一覧",
    BTN_REGISTRATION: "新規登録",

    // Columns
    NO: "NO",
    ID: "ID",
    USERNAME: "ユーザー名",
    COMPANY: "会社",
    STORE: "店舗",
    AUTHORITY: "権限",
    ACTION: "アクション",

    // Modal
    TITLE_DELETE: "削除",
    BTN_DELETE: "削除 ",
    BTN_CANCEL: "キャンセル",
    USER_REGISTRATION: "ユーザー登録",
    USER_UPDATE: "ユーザー変更",
    USER_DETAIL: "ユーザー照会",
    BTN_MODAL_REGIST: "登録 ",
    BTN_CLOSE: "閉じる",
    STORE_LIST_REGISTRATION: "店舗一覧登録",
    BTN_OK: "OK",
    STORE_LIST_UPDATE: "変更成功",
    BTN_NEXT: "次へ",
    CONFIRM_UPDATE_FINAL: "用途成功",
    CONFIRM_REGISTRATION_FINAL: "用途成功",

    FAIL_REGISTER: "用途成功",
    SUCCESS_DELETE: "用途成功",
    FAIL_DELETE: "用途成功",

    NOT_FOUND_STORE: "not_found_store",
    SELF_DELETE: "not_self_delete",
  },
  LABEL: {
    ID: "ID",
    USERNAME: "ユーザー名  ",
    COMPANY: "会社",
    ROLE: "権限",
    STORE: "店舗",
    STORE_CODE_TABLE: "店舗コード",

    // Modal
    USER_ID: "ID",
    USER_NAME: "ユーザー名",
    PASSWORD: "パスワード",
    USER_COMPANY: "会社",
    USER_STORE: "店舗",
    USER_ROLE: "権限",
  },
  NAME: {
    USERNAME: "username",
    NAME: "name",
    COMPANY: "companyCode",
    STORE: "storeCode",
    ROLE: "role",

    // Modal
    MODAL_USERNAME: "usernameModal",
    MODAL_NAME: "nameModal",
    MODAL_PASSWORD: "passwordModal",
    MODAL_COMPANY_CODE: "companyCodeModal",
    MODAL_STORE_CODE: "storeCodeModal",
    MODAL_ROLE: "roleModal",
  },
  TABLE_KEY: {
    NO: "key",
    ID: "id",
    USERNAME: "username",
    COMPANY_INFO: "companyCode",
    STORE_INFO: "storeCode",
    ROLE_INFO: "role",
  },
  MESSAGE: {
    NO_DATA: "表示するデータがありません。",
    SUCCESS_DELETE: "ユーザーが削除に成功された。",
    SUCCESS_REGISTER: "ユーザが登録に成功された。",
    SUCCESS_UPDATE: "ユーザが変更に成功された。",
    SELF_DELETE_ACCOUNT: "自分のアカウントを削除できません。",

    FAIL_DELETE: "ユーザが削除に失敗された。",
    FAIL_REGISTER: "ユーザが登録に失敗された。",
    FAIL_UPDATE: "ユーザが変更に失敗された。",

    CONFIRM_DELETE: "このユーザーを削除します。よろしいですか？",
    CONFIRM_UPDATE_FINAL: "このユーザを変更します。よろしいですか？",
    CONFIRM_REGISTRATION_FINAL: "このユーザを登録します。よろしいですか？",

    NOT_FOUND_STORE:
      "他の人がデータを更新したため、更新できません。 データベースからデータをリロードしてください。",
    DATA_EXIST: "値はすでに存在しています。",
    EXIST_COMPANY_STORE: "この店舗に管理者がすでに存在している。",
    FIELD_INVALID_FORMAT: "値の形式は正しくありません。",
    FIELD_INVALID: "値は無効です。",
    FIELD_ID_ERROR:
      "1つ店舗で店舗ID（パナソニック）または店舗ID（OKAMURA）のみ入力可能。",
  },
};

export const HEIGHT_TABLE_SCROLL = 685;
export const MAX_LENGTH_255 = 255;
export const MAX_LENGTH_3 = 3;
export const MAX_LENGTH_6 = 6;
export const MAX_LENGTH_8 = 8;
export const MAX_LENGTH_50 = 50;
export const MAX_LENGTH_11 = 11;
export const LIST_KEYS_ALLOW_PHONE = [
  "Backspace",
  "Tab",
  "Shift",
  "ArrowRight",
  "ArrowLeft",
];
export const REGEX_STORE_CODE = /^[a-zA-Z0-9]{1,3}$/;
