import { DATE_HYPHEN_FORMAT } from "@constants/common";
import { TSelectValue } from "@models/common";
import {
  TAllReportForm,
  TAllReportReq,
  TConvertAllReport,
} from "@models/report";
import { handleDisabledDate } from "@utils/helpers";
import { DatePicker, Form } from "antd";
import { GetProps } from "antd/lib";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { adminPaths } from "routers/routes";
import { getColumns } from "../columns";
import { STATISTICS_CONSTANT } from "../constant";
import useStatisticsApi from "./useStatisticsApi";

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

const { NAME, MESSAGE } = STATISTICS_CONSTANT;

function useStatisticsPage() {
  const [form] = Form.useForm();
  const watchFromDate = Form.useWatch(NAME.START_DATE, form);
  const watchToDate = Form.useWatch(NAME.END_DATE, form);

  const [rowKeys, setRowKeys] = useState<readonly React.Key[]>([]);
  const [chooseDate, setChooseDate] = useState(dayjs().subtract(1, "d"));
  const [openModalPeriodTime, setModalPeriodTime] = useState(false);
  const [messageErrorExport, setMessageErrorExport] = useState("");

  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingCompany, setLoadingCompany] = useState(true);
  const [companies, setCompanies] = useState<TSelectValue[]>([]);
  const [code, setCode] = useState(""); // companyCode
  const [storesCode, setStoresCode] = useState<TSelectValue[]>([]);
  const [listAllReport, setListAllReport] = useState<TConvertAllReport[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);

  const { effect, handler } = useStatisticsApi();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleRedirectOpenCase = (
    val: string,
    isCh: boolean,
    storeCode: string,
    other?: string
  ) => {
    const chCode = isCh ? val : other;

    navigate(
      `${
        adminPaths.openCase
      }?companyCode=${code}&storeCode=${storeCode}&reportDate=${chooseDate.format(
        DATE_HYPHEN_FORMAT
      )}&chCode=${chCode}`
    );
  };

  const handleRedirectDailyReport = (
    storeCode: string,
    reportDate?: string
  ) => {
    const _reportDate = reportDate
      ? dayjs(reportDate).format(DATE_HYPHEN_FORMAT)
      : chooseDate.format(DATE_HYPHEN_FORMAT);

    navigate(
      `${adminPaths.temperatureReport}?companyCode=${code}&storeCode=${storeCode}&reportDate=${_reportDate}`
    );
  };

  const handleSetSearchParams = (companyCode: string, reportDate: string) => {
    searchParams.set("companyCode", companyCode);
    searchParams.set("reportDate", reportDate);
    setSearchParams(searchParams, { replace: true });
  };

  const handleSearch = (val: TAllReportForm) => {
    setChooseDate(val.reportDate);
    setRowKeys([]);
    setListAllReport([]);
    handleSetSearchParams(
      val.companyCode,
      val.reportDate.format(DATE_HYPHEN_FORMAT)
    );

    const findCompany = companies.find(
      (item) => item.value === val.companyCode
    );
    if (findCompany) {
      handler.getAllReportSearch(
        {
          companyCode: val.companyCode,
          reportDate: val.reportDate.format(DATE_HYPHEN_FORMAT),
          pageIndex: 0,
        },
        findCompany,
        setListAllReport,
        setStoresCode,
        setLoadingPage,
        setPageIndex,
        setTotalRecords
      );
    }
  };

  const handleCheckExport = () => {
    const toDate: Dayjs = watchToDate || dayjs().subtract(1, "d");
    const fromDate: Dayjs = watchFromDate || dayjs().subtract(1, "d");

    if (Math.abs(toDate.diff(fromDate, "d")) > 6) {
      setMessageErrorExport(MESSAGE.SPECIFY_PERIOD);
    } else {
      setMessageErrorExport("");
      handler.handleExportFile(
        fromDate,
        toDate,
        companies,
        storesCode,
        form.getFieldValue(NAME.COMPANY),
        setLoadingPage,
        handleCloseModalExport
      );
    }
  };

  const handleDisableFromDate: RangePickerProps["disabledDate"] = (current) => {
    const disableDate = handleDisabledDate?.(current, {
      type: "date",
      from: undefined,
    });

    return (
      disableDate ||
      current.isAfter(dayjs(), "day") ||
      current.isAfter(watchToDate, "day")
    );
  };

  const handleDisableToDate: RangePickerProps["disabledDate"] = (current) => {
    return (
      current.isAfter(dayjs().subtract(1, "d"), "day") ||
      current.isBefore(watchFromDate, "day")
    );
  };

  const handleOpenModalExport = () => {
    const date = form.getFieldValue(NAME.DATE);
    form.setFieldValue(NAME.START_DATE, date);
    form.setFieldValue(NAME.END_DATE, date);
    setModalPeriodTime(true);
  };

  const handleCloseModalExport = () => {
    const date = form.getFieldValue(NAME.DATE);
    form.setFieldValue(NAME.START_DATE, date);
    form.setFieldValue(NAME.END_DATE, date);

    setModalPeriodTime(false);
    setMessageErrorExport("");
  };

  const handleScroll = (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (
      scrollTop + clientHeight >= scrollHeight - 5 &&
      !loadingPage &&
      listAllReport.length < totalRecords
    ) {
      getAllReport({
        companyCode: code,
        reportDate: chooseDate.format(DATE_HYPHEN_FORMAT),
        pageIndex,
      });
    }
  };

  const customColumns = useMemo(() => {
    const columns = getColumns({
      chooseDate,
      handleRedirectOpenCase,
      handleRedirectDailyReport,
    });
    return columns;
  }, [chooseDate, code]);

  // API
  const getCompanies = async (companyCode: string, reportDate: string) => {
    handler.getCompanies(
      setCompanies,
      setCode,
      setLoadingCompany,
      handleSetSearchParams,
      setListAllReport,
      setStoresCode,
      setLoadingPage,
      setPageIndex,
      setTotalRecords,
      companyCode,
      reportDate
    );
  };

  const getAllReport = async (payload: TAllReportReq) => {
    handler.getAllReport(
      payload,
      listAllReport,
      pageIndex,
      setListAllReport,
      setLoadingPage,
      setPageIndex,
      setTotalRecords
    );
  };

  const getAllReportSensor = async (id: string, storeCode: string) => {
    handler.getAllReportSensor(
      id,
      storeCode,
      form.getFieldValue(NAME.DATE).format(DATE_HYPHEN_FORMAT),
      listAllReport,
      setListAllReport,
      setLoadingPage
    );
  };

  // EFFECT
  useEffect(() => {
    const companyCode = searchParams.get("companyCode") || "";
    const reportDate =
      searchParams.get("reportDate") ||
      dayjs().subtract(1, "d").format(DATE_HYPHEN_FORMAT);

    form.setFieldValue(NAME.DATE, dayjs(reportDate));
    setChooseDate(dayjs(reportDate));
    getCompanies(companyCode, reportDate);
  }, []);

  useEffect(() => {
    effect.effectCompanies(companies, form, searchParams, setCode);
  }, [companies]);

  return {
    state: {
      form,
      customColumns,
      listAllReport,
      rowKeys,
      openModalPeriodTime,
      messageErrorExport,

      loadingPage,
      loadingCompany,
      companies,
    },
    handler: {
      setCode,
      setRowKeys,
      handleSearch,
      handleScroll,
      getAllReportSensor,

      handleCheckExport,
      handleDisableFromDate,
      handleDisableToDate,
      handleOpenModalExport,
      handleCloseModalExport,
    },
  };
}

export default useStatisticsPage;
