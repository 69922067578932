import { REFRESH_TOKEN_COOKIE, TOKEN_COOKIE } from "@constants/common";
import { TLoginReq, TLoginRes, TRefreshTokenRes } from "@models/login";
import { TAxiosResponse } from "types";
import Cookies from "universal-cookie";
import { axiosInstance } from "./axiosInstance";

const cookies = new Cookies();

const authApi = {
  login: async (payload: TLoginReq) => {
    const url = "/auth/login";
    const response: TAxiosResponse<TLoginRes> = await axiosInstance.post(
      url,
      payload
    );

    return response;
  },

  refreshToken: async () => {
    const refreshToken = cookies.get(REFRESH_TOKEN_COOKIE);
    const accessToken = cookies.get(TOKEN_COOKIE);

    const url = "/auth/refresh-token";
    const data = { accessToken, refreshToken };
    const response: TAxiosResponse<TRefreshTokenRes> = await axiosInstance.post(
      url,
      data
    );
    return response;
  },

  logout: async () => {
    const url = "/auth/logout";
    const response: TAxiosResponse = await axiosInstance.post(url);
    return response;
  },
};

export { authApi };
