import { LoadingOutlined } from "@ant-design/icons";
import { ArrowSelect, LoadingCommon } from "@components";
import EmptyDataTable from "@components/EmptyDataTable/EmptyDataTable";
import { images, svgs } from "@constants";
import { DATE_FORMAT } from "@constants/common";
import { SearchIcon } from "@constants/svgs";
import { handleDisabledDate } from "@utils/helpers";
import {
  Button,
  ConfigProvider,
  DatePicker,
  Divider,
  Form,
  Select,
  Table,
} from "antd";
import dayjs from "dayjs";
import { STATISTICS_CONSTANT } from "./constant";
import { ModalPeriodTimeWrapper, StatisticsWrapper } from "./StatisticsStyled";
import useStatisticsPage from "./hooks/useStatisticsPage";

const { TITLE, LABEL, NAME, MESSAGE } = STATISTICS_CONSTANT;

const FIELDS_CONFIG = {
  COMPANY: { required: false, label: LABEL.COMPANY, name: NAME.COMPANY },
  DATE: { required: false, label: LABEL.DATE, name: NAME.DATE },
  START_DATE: {
    required: false,
    label: TITLE.START_DATE,
    name: NAME.START_DATE,
  },
  END_DATE: {
    required: false,
    label: TITLE.END_DATE,
    name: NAME.END_DATE,
  },
};

function StatisticsPage() {
  const { state, handler } = useStatisticsPage();

  return (
    <LoadingCommon loading={state.loadingPage}>
      <StatisticsWrapper listdata={state.listAllReport.length}>
        <div className="statistics-page_header">
          <h1>{TITLE.SPREADSHEET}</h1>
          <div className="statistics-page_btns">
            <Button onClick={handler.handleOpenModalExport}>
              {TITLE.BTN_EXPORT}
            </Button>
          </div>
        </div>

        <div className="statistics-page_form">
          <Form
            form={state.form}
            name="statistics-page"
            layout="vertical"
            onFinish={handler.handleSearch}
            initialValues={{ [NAME.DATE]: dayjs().subtract(1, "d") }}
          >
            {/* COMPANY */}
            <Form.Item {...FIELDS_CONFIG.COMPANY}>
              <Select
                allowClear={false}
                size="large"
                options={state.companies}
                suffixIcon={
                  state.loadingCompany ? (
                    <LoadingOutlined spin={state.loadingCompany} />
                  ) : (
                    <ArrowSelect />
                  )
                }
                onChange={(val) => handler.setCode(val)}
                disabled={state.loadingCompany}
              />
            </Form.Item>

            {/* DATE */}
            <Form.Item {...FIELDS_CONFIG.DATE}>
              <DatePicker
                showNow={false}
                allowClear={false}
                size="large"
                format={DATE_FORMAT}
                inputReadOnly={true}
                disabledDate={handleDisabledDate}
                suffixIcon={
                  <img
                    src={svgs.datePickerTemperature}
                    alt="datepicker_temperature"
                  />
                }
              />
            </Form.Item>

            <Button htmlType="submit" icon={<SearchIcon />} />
          </Form>
        </div>

        <div className="statistics-page_table">
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  headerBg: "var(--background-header-table)",
                  borderColor: "var(--color-border-secondary)",
                  rowHoverBg: "var(--color-border-primary)",
                  headerColor: "var(--color-header-table)",
                  fontWeightStrong: 700,
                },
              },
            }}
            renderEmpty={() =>
              !state.listAllReport.length ? (
                <EmptyDataTable message={MESSAGE.NO_DATA} />
              ) : undefined
            }
          >
            <Table
              rowKey={"id"}
              dataSource={state.listAllReport}
              columns={state.customColumns}
              pagination={false}
              virtual={state.listAllReport.length > 0}
              scroll={{ y: 685 }}
              onScroll={handler.handleScroll}
              bordered
              rowClassName={(record) =>
                state.rowKeys.includes(record.id) ? "parent-expanded" : ""
              }
              onRow={(record) => {
                if (record.key >= 0 && record.key <= 249) {
                  return {
                    className: "",
                  };
                }

                return {
                  className: "children",
                };
              }}
              expandable={{
                expandedRowKeys: state.rowKeys,
                onExpandedRowsChange: (expandedRows) =>
                  handler.setRowKeys(expandedRows),
                expandIcon: ({ expanded, onExpand, record }) => {
                  if (!record.children) {
                    return undefined;
                  }
                  return (
                    <>
                      {expanded ? (
                        <img
                          src={svgs.statisticsUpIcon}
                          onClick={(e) => onExpand(record, e)}
                        />
                      ) : (
                        <img
                          src={svgs.statisticsDownIcon}
                          onClick={(e) => {
                            handler.getAllReportSensor(
                              record.id,
                              record.storeCode
                            );
                            onExpand(record, e);
                          }}
                        />
                      )}
                    </>
                  );
                },
              }}
            />
          </ConfigProvider>
        </div>
      </StatisticsWrapper>

      <ModalPeriodTimeWrapper
        closeIcon={false}
        destroyOnClose
        centered
        open={state.openModalPeriodTime}
        title={TITLE.TITLE_DOWNLOAD}
        confirmLoading={state.loadingPage}
        onOk={() => {
          handler.handleCheckExport();
        }}
        onCancel={() => {
          handler.handleCloseModalExport();
        }}
        maskClosable={false}
        okText={TITLE.BTN_OK}
        cancelText={TITLE.BTN_CANCEL}
        width={464}
        messageerror={state.messageErrorExport}
      >
        <img
          style={{ marginTop: "16px" }}
          src={images.periodTime}
          alt="period_time"
        />
        <p className="period-time_message">{MESSAGE.SPECIFY_DATE}</p>
        <Form
          form={state.form}
          layout="vertical"
          initialValues={{
            [NAME.START_DATE]: dayjs().subtract(1, "d"),
            [NAME.END_DATE]: dayjs().subtract(1, "d"),
          }}
        >
          <div className="period-time_datepicker">
            <Form.Item {...FIELDS_CONFIG.START_DATE}>
              <DatePicker
                size="large"
                format={DATE_FORMAT}
                allowClear={false}
                inputReadOnly
                suffixIcon={
                  <img
                    src={svgs.datePickerTemperature}
                    alt="period-time_datepicker-start"
                  />
                }
                disabledDate={handler.handleDisableFromDate}
              />
            </Form.Item>
            <Divider type="horizontal" />
            <Form.Item {...FIELDS_CONFIG.END_DATE}>
              <DatePicker
                size="large"
                format={DATE_FORMAT}
                allowClear={false}
                inputReadOnly
                suffixIcon={
                  <img
                    src={svgs.datePickerTemperature}
                    alt="period-time_datepicker-end"
                  />
                }
                disabledDate={handler.handleDisableToDate}
              />
            </Form.Item>
          </div>
          {state.messageErrorExport && (
            <p className="period-time_message-error">
              {state.messageErrorExport}
            </p>
          )}
        </Form>
      </ModalPeriodTimeWrapper>
    </LoadingCommon>
  );
}

export { StatisticsPage };
