import { LoadingOutlined } from "@ant-design/icons";
import { ArrowSelect } from "@components";

type TSuffixIcon = {
  loading: boolean;
};

function SuffixIcon({ loading }: TSuffixIcon) {
  return loading ? <LoadingOutlined spin={loading} /> : <ArrowSelect />;
}

export default SuffixIcon;
