import { images } from "@constants";
import { DropdownButton } from "./DropdownButton";
import { HeaderStyled, TopBarContainer } from "./HeaderStyled";

function Header() {
  return (
    <HeaderStyled>
      <TopBarContainer className="top-bar-container">
        <div className="top-bar-left">
          <img src={images.logoForm} alt="logo" />
        </div>
        <div className="top-bar-right">
          <DropdownButton />
        </div>
      </TopBarContainer>
    </HeaderStyled>
  );
}

export { Header };
