import { TCommonError } from "@models/common";
import { TAxiosResponse } from "types";
import { axiosInstance } from "./axiosInstance";
import {
  TDownloadDocumentReq,
  TDownloadDocumentRes,
  TFileDocumentReq,
  TFileDocumentRes,
} from "@models/file";
import { DEFAULT_PAGE_SIZE } from "@constants/common";

const fileManageApi = {
  getFileDocuments: async (payload: TFileDocumentReq) => {
    const _page = payload.pageIndex ? payload.pageIndex : 1;

    const response: TAxiosResponse<TFileDocumentRes[], TCommonError> =
      await axiosInstance.get("/Document/documents", {
        params: {
          ExportedType: payload.exportedType,
          Keyword: payload.keyword.trim(),
          PageIndex: _page,
          PageSize: DEFAULT_PAGE_SIZE,
        },
      });
    return response;
  },

  deleteFileDocument: async (payload: string) => {
    const url = `Document/${payload}`;
    const response: TAxiosResponse<any, TCommonError> =
      await axiosInstance.delete(url);
    return response;
  },

  downloadFileDocument: async (payload: TDownloadDocumentReq) => {
    const url = `Document/generate-presigned-url?Id=${payload.id}&KeyName=${payload.keyName}`;
    const response: TAxiosResponse<TDownloadDocumentRes, TCommonError> =
      await axiosInstance.get(url);
    return response;
  },
};

export { fileManageApi };
