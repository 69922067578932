import { Checkbox, Form } from "antd";

type TCheckboxExportProps = {
  disableItemStore: boolean;
  message: string;
  config: any;
};

function CheckboxExport({
  disableItemStore,
  config,
  message,
}: TCheckboxExportProps) {
  return disableItemStore ? null : (
    <Form.Item className="period-time_checkbox" {...config}>
      <Checkbox>{message}</Checkbox>
    </Form.Item>
  );
}

export default CheckboxExport;
