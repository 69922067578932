import { TSelectValue } from "@models/common";
import {
  TAllStoreConditionsReq,
  TAllStoreStatusConvertData,
  TAllStoreStatusForm,
  TAllStoreStatusFormConvert,
  TAllStoreStatusState,
} from "@models/store";
import { Form, TableProps } from "antd";
import { SorterResult } from "antd/es/table/interface";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { adminPaths } from "routers/routes";
import { getColumns } from "../columns";
import useAllDeviceApi from "./useAllDeviceApi";
import { ALL_DEVICE_CONSTANT } from "../constant";
import { DATE_HYPHEN_FORMAT } from "@constants/common";
import dayjs from "dayjs";

const { NAME } = ALL_DEVICE_CONSTANT;

function useAllDevice() {
  const { handler, effect } = useAllDeviceApi();

  const [form] = Form.useForm();

  const [modalDownload, setModalDownload] = useState(false);
  const [companiesSelect, setCompaniesSelect] = useState<TSelectValue[]>([]);
  const [areasSelect, setAreasSelect] = useState<TSelectValue[]>([]);
  const [purposesSelect, setPurposesSelect] = useState<TSelectValue[]>([]);
  const [locationsSelect, setLocationsSelect] = useState<TSelectValue[]>([]); // installedLocation
  const [resultData, setResultData] = useState<TAllStoreStatusState>({
    totalCompanySensor: 0,
    totalRecord: 0,
    data: [],
  });
  const [page, setPage] = useState(0);
  const [sortColumn, setSortColumn] = useState({
    order: "ascend",
    field: "storecode",
  });
  const [formValues, setFormValues] =
    useState<TAllStoreStatusFormConvert | null>(null);

  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingOther, setLoadingOther] = useState(false);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const _companyCode = searchParams.get("companyCode") || "";
  const _areaCode = searchParams.get("areaCode") || "";
  const _storeCode = searchParams.get("storeCode") || "";
  const _storeName = searchParams.get("storeName") || "";
  const _manufacturerName = searchParams.get("manufacturerName") || "";
  const _purposeCode = searchParams.get("purposeCode") || "";
  const _installedLocation = searchParams.get("installedLocation") || "";
  const _sortField = searchParams.get("sortField") || "";
  const _sortDirection = searchParams.get("sortDirection") || "";
  const _companyId = searchParams.get("companyId") || "";
  const _areaId = searchParams.get("areaId") || "";
  const _purposeId = searchParams.get("purposeId") || "";

  const handleNavigateBack = () => {
    handler.handleNavigateBack(navigate);
  };

  const handleRedirectPage = (record: TAllStoreStatusConvertData) => {
    const companyCode = form.getFieldValue(NAME.COMPANY);
    navigate(
      `${adminPaths.openCase}?companyCode=${companyCode}&storeCode=${
        record.storeCode
      }&reportDate=${dayjs()
        .subtract(1, "d")
        .format(DATE_HYPHEN_FORMAT)}&chCode=${record.chCode}`
    );
  };

  const handleChangeTable: TableProps<TAllStoreStatusConvertData>["onChange"] =
    (_pagination, _filters, sorter) => {
      const { field, order } =
        sorter as SorterResult<TAllStoreStatusConvertData>;

      if (resultData.data.length) {
        handleSetSortColumn(field as string, order as string);
      }
      setSortColumn({
        field: field as string,
        order: order as string,
      });
    };

  const handleGetDataSelect = (
    companyCode: string,
    areaCode: string,
    purposeCode: string
  ) => {
    const findCompany = _.find(companiesSelect, function (item) {
      return item.value === companyCode;
    });
    const findArea = _.find(areasSelect, function (item) {
      return item.value === areaCode;
    });
    const findPurpose = _.find(purposesSelect, function (item) {
      return item.value === purposeCode;
    });

    return {
      findCompany,
      findArea,
      findPurpose,
    };
  };

  const handleSetSortColumn = (field: string, order: string) => {
    searchParams.set("sortField", field.toLowerCase());
    searchParams.set("sortDirection", handler.handleSortOrder(order));
    setSearchParams(searchParams);

    const { findArea, findCompany, findPurpose } = handleGetDataSelect(
      _companyCode,
      _areaCode,
      _purposeCode
    );

    if (findCompany) {
      const params: TAllStoreStatusFormConvert = {
        areaId: findArea?.children as string,
        companyId: findCompany.children as string,
        installedLocation: _installedLocation,
        manufacturerName: _manufacturerName,
        purposeId: findPurpose?.children as string,
        storeCode: _storeCode,
        storeName: _storeName,
        sortField: field.toLowerCase(),
        sortDirection: handler.handleSortOrder(order),
      };
      setFormValues(params);
      setResultData({ ...resultData, data: [] });
      setPage(1);

      handler.getAllStoreStatusSort(
        { ...params, page: 0 },
        resultData,
        setLoadingPage,
        setResultData
      );
    }
  };

  const handleSetSearchParams = (values: TAllStoreStatusForm) => {
    searchParams.set("companyCode", values.companyCode);
    searchParams.set("areaCode", values.areaCode || "");
    searchParams.set("storeCode", values.storeCode.trim());
    searchParams.set("storeName", values.storeName.trim());
    searchParams.set("manufacturerName", values.manufacturerName.trim());
    searchParams.set("purposeCode", values.purposeCode || "");
    searchParams.set("installedLocation", values.installedLocation || "");
    searchParams.set("sortField", values.sortField);
    searchParams.set("sortDirection", values.sortDirection);
    searchParams.set("companyId", values.companyId);
    searchParams.set("areaId", values.areaId || "");
    searchParams.set("purposeId", values.purposeId || "");
    setSearchParams(searchParams, { replace: true });
  };

  const handleSearch = (values: TAllStoreStatusForm) => {
    const { areaCode, companyCode, purposeCode } = values;
    const { findArea, findCompany, findPurpose } = handleGetDataSelect(
      companyCode,
      areaCode,
      purposeCode
    );

    if (findCompany) {
      const params: TAllStoreStatusFormConvert = {
        companyId: findCompany.children as string,
        areaId: findArea?.children as string,
        purposeId: findPurpose?.children as string,
        storeCode: values.storeCode,
        storeName: values.storeName,
        installedLocation: values.installedLocation,
        manufacturerName: values.manufacturerName,
        sortDirection: handler.handleSortOrder(sortColumn.order),
        sortField: sortColumn.field.toLowerCase(),
      };

      setFormValues(params);
      setResultData({ ...resultData, data: [] });
      setPage(1);
      handleSetSearchParams({
        areaCode,
        companyCode: findCompany.value,
        purposeCode,
        installedLocation: values.installedLocation,
        manufacturerName: values.manufacturerName,
        storeCode: values.storeCode,
        storeName: values.storeName,
        sortDirection: handler.handleSortOrder(sortColumn.order),
        sortField: sortColumn.field.toLowerCase(),
        areaId: findArea?.children as string,
        companyId: findCompany.children as string,
        purposeId: findPurpose?.children as string,
      });
      handler.getAllStoreStatusSearch(
        { ...params, page: 0 },
        setLoadingPage,
        setResultData
      );
    }
  };

  const handleResetField = () => {
    setAreasSelect([]);
    setPurposesSelect([]);
    setLocationsSelect([]);
    form.setFieldValue(NAME.AREA, undefined);
    form.setFieldValue(NAME.PURPOSE, undefined);
    form.setFieldValue(NAME.INSTALL, undefined);
  };

  const handleExportData = async () => {
    const _companies = _.chunk(companiesSelect, 6);

    while (_companies.length > 0) {
      const apiCalls = _companies[0].map((item) =>
        handler.exportAllStoreStatusCSV(
          {
            companyCode: item.value,
            companyName: item.label,
          },
          setLoadingPage
        )
      );

      await Promise.allSettled(apiCalls).finally(() => {
        if (_companies.length === 1) {
          setLoadingPage(false);
          setModalDownload(false);
        }
      });
      _companies.shift();
      await new Promise((resolve) => setTimeout(resolve, 1500));
    }
  };

  const handleChangeCompany = (val: string) => {
    handleResetField();
    const findCompany = _.find(companiesSelect, function (item) {
      return item.value === val;
    });

    if (findCompany) {
      getAllStoreConditions({
        companyCode: findCompany.value,
        companyName: findCompany.label,
      });
    }
  };

  const handleScroll = (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (
      scrollTop + clientHeight >= scrollHeight - 5 &&
      !loadingPage &&
      formValues &&
      resultData.data.length < resultData.totalRecord
    ) {
      setPage((prev) => prev + 1);
      handler.getAllStoreStatusScroll(
        { ...formValues, page: page + 1 },
        resultData,
        setLoadingPage,
        setResultData
      );
    }
  };

  const handleSortOrderV2 = (order: string) => {
    return handler.handleSortOrderV2(order);
  };

  const columns = useMemo(() => {
    const columns = getColumns(
      searchParams,
      handleRedirectPage,
      handleSortOrderV2
    );
    return columns;
  }, []);

  // API

  const getAllStoreConditions = (payload: TAllStoreConditionsReq) => {
    handler.getAllStoreConditions(
      payload,
      setAreasSelect,
      setLocationsSelect,
      setPurposesSelect,
      setLoadingOther
    );
  };

  useEffect(() => {
    if (_sortField && _sortDirection) {
      setSortColumn({
        field: _sortField,
        order: _sortDirection,
      });
    }
    if (_areaCode) {
      form.setFieldValue(NAME.AREA, _areaCode);
    } else {
      form.setFieldValue(NAME.AREA, undefined);
    }
    if (_purposeCode) {
      form.setFieldValue(NAME.PURPOSE, _purposeCode);
    } else {
      form.setFieldValue(NAME.PURPOSE, undefined);
    }
    if (_installedLocation) {
      form.setFieldValue(NAME.INSTALL, _installedLocation);
    } else {
      form.setFieldValue(NAME.INSTALL, undefined);
    }

    form.setFieldValue(NAME.COMPANY, _companyCode);
    form.setFieldValue(NAME.STORE_CODE, _storeCode);
    form.setFieldValue(NAME.STORE_NAME, _storeName);
    form.setFieldValue(NAME.MANUFACTURER, _manufacturerName);
    const params = {
      areaId: _areaId,
      companyId: _companyId,
      installedLocation: _installedLocation,
      manufacturerName: _manufacturerName,
      purposeId: _purposeId,
      sortDirection: _sortDirection || "asc",
      sortField: _sortField || "store",
      storeCode: _storeCode,
      storeName: _storeName,
    };
    if (_companyId) {
      setFormValues({ ...params, companyId: _companyId });
      setPage(1);

      handler.getAllStoreStatusInit(
        {
          ...params,
          companyId: _companyId,
          page: 0,
        },
        {
          areaCode: _areaCode,
          purposeCode: _purposeCode,
        },
        setLoadingPage,
        setResultData,
        setCompaniesSelect,
        getAllStoreConditions,
        handleSetSearchParams
      );
    } else {
      setFormValues({ ...params, companyId: "" });
      setPage(1);

      handler.getAllStoreStatusInit(
        {
          ...params,
          companyId: "",
          page: 0,
        },
        {
          areaCode: _areaCode,
          purposeCode: _purposeCode,
        },
        setLoadingPage,
        setResultData,
        setCompaniesSelect,
        getAllStoreConditions,
        handleSetSearchParams
      );
    }
  }, []);

  useEffect(() => {
    effect.effectCompanies(companiesSelect, form, searchParams);
  }, [companiesSelect]);

  return {
    state: {
      form,
      companiesSelect,
      areasSelect,
      locationsSelect,
      purposesSelect,
      resultData,

      loadingPage,
      loadingOther,
      columns,
      modalDownload,
    },
    handler: {
      handleNavigateBack,
      handleChangeTable,
      handleSearch,
      handleScroll,
      handleChangeCompany,

      setModalDownload,
      handleExportData,
    },
  };
}

export default useAllDevice;
