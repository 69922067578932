import {
  TDownloadDocumentReq,
  TFileDocumentConvert,
  TFileDocumentReq,
  TFileDocumentRes,
} from "@models/file";
import { fileManageApi } from "@services/fileManageApi";
import { handleShowToastError } from "@utils/helpers";
import { InputRef } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { FILE_MANAGE_CONSTANT } from "../constant";
import dayjs from "dayjs";
import {
  COMMON_MESSAGE_ERROR,
  DATE_FORMAT,
  TIME_PICKER_FORMAT,
} from "@constants/common";
import { customColumns } from "../columns";
import _ from "lodash";
import { TAxiosResponse } from "types";
import { EHttpStatusCode } from "types/service";
import { appToast } from "@utils/appToast";
import { SetURLSearchParams } from "react-router-dom";

const { TITLE } = FILE_MANAGE_CONSTANT;

type TCustomHook = {
  exportedType: string;
  searchParams: URLSearchParams;
  setSearchParams: SetURLSearchParams;
};

function useFileManage({
  exportedType,
  searchParams,
  setSearchParams,
}: TCustomHook) {
  const [listDocuments, setListDocuments] = useState<TFileDocumentConvert[]>(
    []
  );
  const [hasNext, setHasNext] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const valueInputRef = useRef<InputRef>(null);
  const [loadingPage, setLoadingPage] = useState(false);

  const [modalView, setModalView] = useState(false);
  const [modalDownload, setModalDownload] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [info, setInfo] = useState<TFileDocumentConvert | null>(null);

  const columns = useMemo(() => {
    return customColumns({
      setInfo,
      setModalDelete,
      setModalDownload,
      setModalView,
    });
  }, []);

  const handleSearch = () => {
    if (valueInputRef.current && valueInputRef.current.input) {
      const keyword = valueInputRef.current.input.value;
      setListDocuments([]);
      setPageIndex(1);
      setSearchParams(searchParams, { replace: true });

      getFileDocumentSearch({
        exportedType,
        keyword,
        pageIndex: 0,
      });
    }
  };

  const handleMappingStatusFile = (status: string) => {
    switch (status) {
      case "Pending":
        return TITLE.PENDING;
      case "Expired":
        return TITLE.EXPIRED;
      case "Error":
        return TITLE.ERROR;
      default:
        return TITLE.SUCCESS;
    }
  };

  const handleConvertDate = (date: string | null) => {
    return date ? date.split("-").join("/") : null;
  };

  const handleConvertData = (data: TFileDocumentRes[]) => {
    const convertData: TFileDocumentConvert[] = data.map((item, index) => {
      return {
        ...item,
        key: index + 1,
        status: handleMappingStatusFile(item.status),
        exportedAt: dayjs(item.exportedAt).utc().format(DATE_FORMAT),
        exportedTime: dayjs(item.exportedAt).utc().format(TIME_PICKER_FORMAT),
        startDate: handleConvertDate(item.startDate),
        endDate: handleConvertDate(item.endDate),
      };
    });
    return convertData;
  };

  const handleOkModalView = () => {
    setModalView(false);
    setInfo(null);
  };

  const handleOkModalDownload = () => {
    if (info) {
      downloadFileDocument({ id: info.id, keyName: info.name });
    }
  };

  const handleCancelModalDownload = () => {
    setModalDownload(false);
    setInfo(null);
  };

  const handleOkModalDelete = () => {
    if (info) {
      deleteFileDocument(info.id);
    }
  };

  const handleCancelModalDelete = () => {
    setModalDelete(false);
    setInfo(null);
  };

  const handleScroll = (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (
      scrollTop + clientHeight >= scrollHeight - 5 &&
      !loadingPage &&
      hasNext
    ) {
      setPageIndex((prev) => prev + 1);
      getFileDocumentScroll({
        exportedType,
        keyword: valueInputRef.current?.input?.value as string,
        pageIndex: pageIndex + 1,
      });
    }
  };

  // API

  const getFileDocumentSearch = async (payload: TFileDocumentReq) => {
    setLoadingPage(true);

    try {
      const response = await fileManageApi.getFileDocuments(payload);

      if (response.ok) {
        const initialConvertData = handleConvertData(response.data);
        setListDocuments(initialConvertData);
        setPageIndex(1);
        setHasNext(response.pagination?.hasNext as boolean);
      }
    } catch (error) {
      setListDocuments([]);
      setPageIndex(0);
      setHasNext(false);

      const err = error as TAxiosResponse;
      if (err.status === EHttpStatusCode.FORBIDDEN) {
        appToast.error({ message: COMMON_MESSAGE_ERROR });
        return;
      }

      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const getFileDocumentScroll = async (payload: TFileDocumentReq) => {
    setLoadingPage(true);
    try {
      const response = await fileManageApi.getFileDocuments({
        ...payload,
        pageIndex: payload.pageIndex,
      });

      if (response.ok) {
        setHasNext(response.pagination?.hasNext as boolean);

        if (listDocuments.length !== response.pagination?.totalCount) {
          const startIndex = listDocuments.length;
          setListDocuments([
            ...listDocuments,
            ...handleConvertData(response.data).map((item, index) => ({
              ...item,
              key: startIndex + index + 1,
            })),
          ]);
          setPageIndex(pageIndex + 1);
        }
      }
    } catch (error) {
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const deleteFileDocument = async (payload: string) => {
    setLoadingPage(true);

    try {
      const response = await fileManageApi.deleteFileDocument(payload);
      if (response.ok) {
        setListDocuments(
          [
            ..._.filter(listDocuments, function (item) {
              return item.id !== payload;
            }),
          ].map((item, index) => ({ ...item, key: index + 1 }))
        );
        handleCancelModalDelete();
      }
    } catch (error) {
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
    }
  };

  const downloadFileDocument = async (info: TDownloadDocumentReq) => {
    setLoadingPage(true);
    try {
      const response = await fileManageApi.downloadFileDocument(info);
      if (response.ok) {
        const elementAnchor = document.createElement("a");
        elementAnchor.href = response.data.presignedUrl;
        elementAnchor.download = info.keyName; // File name
        document.body.appendChild(elementAnchor);
        elementAnchor.click();
        document.body.removeChild(elementAnchor);
      }
    } catch (error) {
      handleShowToastError(error);
    } finally {
      setLoadingPage(false);
      handleCancelModalDownload();
    }
  };

  useEffect(() => {
    getFileDocumentSearch({
      exportedType,
      keyword: "",
      pageIndex,
    });
    setPageIndex(1);
    searchParams.set("exportedType", exportedType);
    setSearchParams(searchParams, { replace: true });
  }, []);

  return {
    state: {
      loadingPage,
      valueInputRef,
      listDocuments,
      columns,

      // MODAL
      modalDelete,
      modalDownload,
      modalView,
      info,
    },
    handler: {
      handleSearch,
      handleScroll,

      handleOkModalView,
      handleOkModalDownload,
      handleCancelModalDownload,
      handleCancelModalDelete,
      handleOkModalDelete,
    },
  };
}

export default useFileManage;
