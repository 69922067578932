import { LoadingOutlined } from "@ant-design/icons";
import { ArrowSelect } from "@components";

type TSuffixIcon = {
  loading: boolean;
  disable: boolean;
};

function SuffixIcon({ loading, disable }: TSuffixIcon) {
  return loading ? (
    <LoadingOutlined spin={loading} />
  ) : (
    <ArrowSelect disable={disable} />
  );
}

export default SuffixIcon;
