import { TCommonError } from "@models/common";
import {
  TAllReportReq,
  TAllReportRes,
  TAllReportSensorRes,
  TExportAllReportReq,
  TExportAllReportRes,
} from "@models/report";
import { TAxiosResponse } from "types";
import { axiosInstance } from "./axiosInstance";
import { DEFAULT_PAGE_SIZE } from "@constants/common";

const allReportApi = {
  getAllReport: async (payload: TAllReportReq) => {
    const page = payload.pageIndex ? payload.pageIndex + 1 : 1;
    const size = payload.pageSize || DEFAULT_PAGE_SIZE;
    const url = `/Store/summary?CompanyCode=${payload.companyCode}&ReportDate=${payload.reportDate}&PageIndex=${page}&PageSize=${size}`;
    const response: TAxiosResponse<TAllReportRes[], TCommonError> =
      await axiosInstance.get(url);
    return response;
  },

  getAllReportSensor: async (id: string, date: string) => {
    const url = `/Store/summary/${id}/sensors?reportDate=${date}`;
    const response: TAxiosResponse<TAllReportSensorRes, TCommonError> =
      await axiosInstance.get(url);
    return response;
  },

  exportAllReport: async (payload: TExportAllReportReq) => {
    const url = `/Store/export-summary-report?CompanyCode=${payload.companyCode}&StoreCode=${payload.storeCode}&DateStart=${payload.dateStart}&DateEnd=${payload.dateEnd}`;
    const response: TAxiosResponse<TExportAllReportRes, TCommonError> =
      await axiosInstance.get(url);
    return response;
  },
};

export { allReportApi };
