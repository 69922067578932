import { EUserAccessRoles, TUser } from "@models/user";
import {
  AllDevicePage,
  CompanyPage,
  DevicePage,
  FileManagePage,
  LayoutDiagramPage,
  LoginPage,
  MasterDataPage,
  OpenCasePage,
  PurposePage,
  StatisticsPage,
  StorePage,
  TemperatureReportPage,
  UserPage,
} from "@pages";
import { Navigate } from "react-router-dom";
import { TRouteType } from "routers";

export const adminPaths = {
  temperatureReport: "/dailyreport",
  stastictics: "/allreport",
  layoutDiagram: "/layout",
  openCase: "/detaildevice",
  masterData: "/masterdata",
  fileManagement: "/filemanage",
  allDevice: "/alldevice",
  home: "/",
};

const temperatureReport = "/dailyreport";
const statistics = "/allreport";
const layoutDiagram = "/layout";
const openCase = "/detaildevice";
const masterData = "/masterdata";
const fileManagement = "/filemanage";
const allDevice = "/alldevice";
const store = "/store";
const device = "/device";
const purpose = "/purpose";
const userList = "/user-list";
const company = "/company";

export const publicPaths = {
  login: "/login",
};

export const getInvalidRoute = (user: TUser | null) => {
  return {
    id: "InvalidRoute",
    path: "*",
    element: <Navigate to={user ? temperatureReport : publicPaths.login} />,
    title: user ? "HOME" : "LOGIN",
  };
};

export const LoginRoute: TRouteType = {
  id: "/login",
  path: publicPaths.login,
  title: "LOGIN",
  element: <LoginPage />,
};

export const DailyReportRoute: TRouteType = {
  id: temperatureReport,
  path: temperatureReport,
  title: "DAILY_REPORT",
  element: <TemperatureReportPage />,
  permissions: [
    EUserAccessRoles.ADMIN,
    EUserAccessRoles.MENTEX,
    EUserAccessRoles.STORE,
  ],
};

export const AllReportRoute: TRouteType = {
  id: statistics,
  path: statistics,
  title: "ALL_REPORT",
  element: <StatisticsPage />,
  permissions: [EUserAccessRoles.ADMIN, EUserAccessRoles.MENTEX],
};

export const LayoutDiagramRoute: TRouteType = {
  id: layoutDiagram,
  path: layoutDiagram,
  title: "LAYOUT_DIAGRAM",
  element: <LayoutDiagramPage />,
  permissions: [
    EUserAccessRoles.ADMIN,
    EUserAccessRoles.MENTEX,
    EUserAccessRoles.STORE,
  ],
};

export const DetailDeviceRoute: TRouteType = {
  id: openCase,
  path: openCase,
  title: "OPEN_CASE",
  element: <OpenCasePage />,
  permissions: [
    EUserAccessRoles.ADMIN,
    EUserAccessRoles.MENTEX,
    EUserAccessRoles.STORE,
  ],
};

export const AllDeviceRoute: TRouteType = {
  id: allDevice,
  path: allDevice,
  title: "ALL_DEVICE",
  element: <AllDevicePage />,
  permissions: [EUserAccessRoles.ADMIN, EUserAccessRoles.MENTEX],
};

export const MasterDataRoute: TRouteType = {
  id: masterData,
  path: masterData,
  title: "MASTER_DATA",
  element: <MasterDataPage />,
  permissions: [EUserAccessRoles.ADMIN],
};

export const FileManagementRoute: TRouteType = {
  id: fileManagement,
  path: fileManagement,
  title: "FILE_MANAGEMENT",
  element: <FileManagePage />,
  permissions: [EUserAccessRoles.ADMIN],
};

export const StoreRoute: TRouteType = {
  id: store,
  path: store,
  title: "STORE",
  element: <StorePage />,
  permissions: [EUserAccessRoles.ADMIN, EUserAccessRoles.MENTEX],
};

export const DeviceRoute: TRouteType = {
  id: device,
  path: device,
  title: "DEVICE",
  element: <DevicePage />,
  permissions: [EUserAccessRoles.ADMIN, EUserAccessRoles.MENTEX],
};

export const PurposeRoute: TRouteType = {
  id: purpose,
  path: purpose,
  title: "PURPOSE",
  element: <PurposePage />,
  permissions: [EUserAccessRoles.ADMIN, EUserAccessRoles.MENTEX],
};

export const UserListRoute: TRouteType = {
  id: userList,
  path: userList,
  title: "USER_LIST",
  element: <UserPage />,
  permissions: [EUserAccessRoles.ADMIN, EUserAccessRoles.MENTEX],
};

export const CompanyRoute: TRouteType = {
  id: company,
  path: company,
  title: "COMPANY",
  element: <CompanyPage />,
  permissions: [EUserAccessRoles.ADMIN, EUserAccessRoles.MENTEX],
};

export const publicRoutes = [LoginRoute];
export const privateRoutes = [
  DailyReportRoute,
  AllReportRoute,
  LayoutDiagramRoute,
  DetailDeviceRoute,
  AllDeviceRoute,
  MasterDataRoute,
  FileManagementRoute,
  StoreRoute,
  DeviceRoute,
  PurposeRoute,
  UserListRoute,
  CompanyRoute,
];
