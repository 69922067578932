import { images } from "@constants";
import { EmptyDataTableWrapper } from "./EmptyDataTableStyled";

type Props = {
  message: string;
  image?: string;
};

function EmptyDataTable({ message, image }: Props) {
  return (
    <EmptyDataTableWrapper
      image={image || images.noDataTable}
      description={message}
      imageStyle={{
        height: image ? "200px" : "270px",
      }}
    />
  );
}

export default EmptyDataTable;
