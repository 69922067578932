type TArrowSelectProps = {
  disable?: boolean;
  isModal?: boolean;
};

function ArrowSelect({ disable, isModal }: TArrowSelectProps) {
  const handleShowStrokeIcon = () => {
    if (disable && !isModal) {
      return "var(--color-border-primary)";
    } else if (disable && isModal) {
      return "var(--color-secondary)";
    } else {
      return "#344054";
    }
  };

  return (
    <svg
      width="14"
      height="9"
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.999756 1.00005L6.9998 7L12.9998 1"
        stroke={handleShowStrokeIcon()}
        strokeWidth="1.5"
        strokeMiterlimit="16"
      />
    </svg>
  );
}

export { ArrowSelect };
