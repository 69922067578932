import { TAxiosResponse } from "types";
import { axiosInstance } from "./axiosInstance";
import { TCommonError } from "@models/common";
import {
  TDailyReport,
  TDailyReportReq,
  TDailyReportRes,
  TExportDailyReport,
  TExportDailyReportReq,
  TExportDailyReportRes,
  TReportComment,
  TReportCommentReq,
  TReportCommentRes,
  TReportSensorComment,
  TReportSensorCommentReq,
  TReportSensorCommentRes,
} from "@models/device";

const dailyReportApi = {
  getDailyReport: async (payload: TDailyReport) => {
    const _payload: TDailyReportReq = {
      ...payload,
      companyCode: payload.companyCode || "",
      storeCode: payload.storeCode || "",
      purposeCode: payload.purposeCode || "",
    };
    const url = `/Sensor/daily-report?CompanyCode=${_payload.companyCode}&StoreCode=${_payload.storeCode}&PurposeCode=${_payload.purposeCode}&ReportDate=${_payload.reportDate}`;

    const response: TAxiosResponse<TDailyReportRes, TCommonError> =
      await axiosInstance.get(url);
    return response;
  },

  reportComment: async (payload: TReportComment) => {
    const _payload: TReportCommentReq = {
      ...payload,
      comments: payload.comments ? payload.comments.trim() : "",
      companyCode: payload.companyCode || "",
      storeName: payload?.storeName?.split("_")[1] || "",
      storeCode: payload.storeCode || "",
    };
    const url = "/Report/comment";

    const response: TAxiosResponse<TReportCommentRes, TCommonError> =
      await axiosInstance.post(url, _payload);
    return response;
  },

  reportTempSensorComment: async (payload: TReportSensorComment) => {
    const _payload: TReportSensorCommentReq = {
      ...payload,
      sensorId: payload.sensorId || "",
      comment: payload.comment ? payload.comment.trim() : "",
    };
    const url = "/ReportTempSensor/comment";

    const response: TAxiosResponse<TReportSensorCommentRes, TCommonError> =
      await axiosInstance.post(url, _payload);
    return response;
  },

  exportDailyReport: async (payload: TExportDailyReport) => {
    const _payload: TExportDailyReportReq = {
      ...payload,
      companyCode: payload.companyCode || "",
      companyName: payload.companyName || "",
      storeCode: payload.storeCode || "",
    };
    const url = `/Store/export-daily-report?CompanyCode=${_payload.companyCode}&StoreCode=${_payload.storeCode}&CompanyName=${_payload.companyName}&FromDate=${_payload.fromDate}&ToDate=${_payload.toDate}&StoreName=${_payload.storeName}`;

    const response: TAxiosResponse<TExportDailyReportRes, TCommonError> =
      await axiosInstance.get(url);
    return response;
  },
};

export { dailyReportApi };
