import { Modal } from "antd";
import styled from "styled-components";

export const StatisticsWrapper = styled.div<{
  listdata: number;
}>`
  .statistics-page_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    h1 {
      line-height: 40px;
    }

    .statistics-page_btns {
      button {
        width: 160px;
        height: 48px;
        border-radius: 7px;
        font-weight: var(--font-bold);
      }

      button:first-child {
        box-shadow: none;
      }

      button:last-child {
        color: var(--color-primary);
        border-color: var(--color-primary);
      }
    }
  }

  .statistics-page_form {
    form {
      display: flex;
      column-gap: 16px;

      .ant-btn-default {
        width: 56px;
        height: 56px;
        background: #d7e7ff !important;
        border: none;
        margin-top: 28px;
      }

      .ant-form-item.statistics-page_input {
        width: 290px;
      }

      .ant-form-item {
        width: 311px;
        margin-bottom: 12px;

        .ant-picker {
          width: 100%;
          height: 56px;
        }

        .ant-select-single {
          height: 56px;
        }

        .ant-select-selector,
        .ant-picker-large {
          padding: 16px;
        }

        .ant-form-item-label {
          padding: 0 0 4px;

          label {
            font-weight: var(--font-normal);
          }
        }

        .ant-picker-input input,
        .ant-select-selection-item {
          font-size: var(--font-size-xl);
          font-weight: var(--font-medium);
          line-height: 24px;
        }

        .ant-select-arrow {
          inset-inline-end: 16px;
        }
      }
    }
  }

  .statistics-page_table {
    margin-right: ${(props) => (props.listdata <= 0 ? "unset" : "-8px")};

    .ant-table {
      font-weight: var(--font-medium);

      .ant-table-tbody-virtual-scrollbar-vertical {
        background: var(--color-secondary);
        border-radius: 4px;
      }

      .ant-table-tbody-virtual-scrollbar {
        visibility: visible !important;
        right: -8px !important;

        .ant-table-tbody-virtual-scrollbar-thumb {
          background: var(--color-text-secondary) !important;
        }
      }

      .ant-table-container {
        border-start-end-radius: 0px;
        border-top: none;
      }

      .ant-table-thead {
        .ant-table-cell {
          padding: 16px 8px;
          border-inline-end: 1px solid var(--color-border-primary) !important;
          border-bottom: 4px solid var(--color-border-primary);
        }

        tr:first-child .ant-table-cell:first-child,
        tr:first-child .ant-table-cell:nth-child(2),
        tr:first-child .ant-table-cell:nth-child(3),
        tr:first-child .ant-table-cell:nth-child(4),
        tr:first-child .ant-table-cell:nth-child(5),
        tr:first-child .ant-table-cell:nth-child(6),
        tr:first-child .ant-table-cell:nth-child(7),
        tr:first-child .ant-table-cell:nth-child(8),
        tr:first-child .ant-table-cell:nth-child(9) {
          border-bottom: 4px solid var(--color-border-primary);
        }

        tr:first-child .ant-table-cell:nth-child(10),
        tr:first-child .ant-table-cell:nth-child(11),
        tr:first-child .ant-table-cell:nth-child(12),
        tr:first-child .ant-table-cell:nth-child(13),
        tr:first-child .ant-table-cell:nth-child(14),
        tr:first-child .ant-table-cell:nth-child(15),
        tr:first-child .ant-table-cell:nth-child(16),
        tr:first-child .ant-table-cell:nth-child(17) {
          border-bottom: 1px solid var(--color-border-primary);
        }

        tr:first-child .ant-table-cell:nth-child(10),
        tr:first-child .ant-table-cell:nth-child(11),
        tr:first-child .ant-table-cell:nth-child(12),
        tr:first-child .ant-table-cell:nth-child(13),
        tr:first-child .ant-table-cell:nth-child(14),
        tr:first-child .ant-table-cell:nth-child(15),
        tr:first-child .ant-table-cell:nth-child(16),
        tr:last-child .ant-table-cell:nth-child(2),
        tr:last-child .ant-table-cell:nth-child(8),
        tr:last-child .ant-table-cell:nth-child(14),
        tr:last-child .ant-table-cell:nth-child(20),
        tr:last-child .ant-table-cell:nth-child(26),
        tr:last-child .ant-table-cell:nth-child(32),
        tr:last-child .ant-table-cell:nth-child(38) {
          border-inline-end: 2px solid var(--color-border-primary) !important;
        }

        .ant-table-cell-scrollbar {
          background-color: var(--color-white);
          border-inline-end: none !important;
        }

        tr:first-child .ant-table-cell {
          border-top: 1px solid var(--color-border-primary);
        }

        tr:first-child .ant-table-cell:nth-child(17) {
          border-start-end-radius: 8px;
        }
      }

      .ant-table-tbody {
        height: ${(props) => (props.listdata <= 0 ? "628px" : "unset")};

        .ant-table-tbody-virtual-holder {
          max-height: 685px !important;
        }

        .ant-table-placeholder {
          .ant-empty {
            text-align: left;
            margin-inline-start: 700px;
          }
        }

        .ant-table-row {
          .ant-table-cell:nth-child(11),
          .ant-table-cell:nth-child(17),
          .ant-table-cell:nth-child(23),
          .ant-table-cell:nth-child(29),
          .ant-table-cell:nth-child(35),
          .ant-table-cell:nth-child(41),
          .ant-table-cell:nth-child(47) {
            border-inline-end: 2px solid var(--color-border-secondary) !important;
          }

          .ant-table-cell {
            padding: 16px 8px;
            background-color: #f9fafb;

            .statistics-page_ellipsis {
              display: -webkit-box;
              -webkit-line-clamp: 1; /* Number of lines */
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
            }

            .statistics-page_store-number {
              font-weight: var(--font-medium);
              color: var(--color-link);
              cursor: pointer;
            }

            .statistics-page_store-name {
              font-weight: var(--font-medium);
              color: #0094ff;
              cursor: pointer;
            }

            .statistics-page_ch,
            .statistics-page_sale-name {
              color: var(--color-link);
              font-weight: var(--font-normal);
              cursor: pointer;
            }

            .statistics-page_limit {
              font-weight: var(--font-bold);
            }
          }

          .ant-table-cell:nth-child(14),
          .ant-table-cell:nth-child(15),
          .ant-table-cell:nth-child(20),
          .ant-table-cell:nth-child(21),
          .ant-table-cell:nth-child(26),
          .ant-table-cell:nth-child(27),
          .ant-table-cell:nth-child(32),
          .ant-table-cell:nth-child(33),
          .ant-table-cell:nth-child(38),
          .ant-table-cell:nth-child(39),
          .ant-table-cell:nth-child(44),
          .ant-table-cell:nth-child(45),
          .ant-table-cell:nth-child(50),
          .ant-table-cell:nth-child(51) {
            padding: 0;
            line-height: 56px;

            .lower,
            .upper {
              display: inline-block;
              width: 100%;
            }

            .lower {
              background-color: #d7e7ff !important;
            }
            .upper {
              background-color: #fecdca !important;
            }
          }
        }

        .ant-table-row.parent-expanded {
          .ant-table-cell {
            background-color: var(--color-border-primary) !important;
          }
        }

        .ant-table-row.children {
          .ant-table-cell {
            background-color: #f9fafb;
          }

          .ant-table-cell:nth-child(1),
          .ant-table-cell:nth-child(2),
          .ant-table-cell:nth-child(3),
          .ant-table-cell:nth-child(4) {
            border-bottom: none;
          }

          .ant-table-cell:nth-child(1),
          .ant-table-cell:nth-child(2),
          .ant-table-cell:nth-child(3) {
            border-inline-end: none;
          }
        }

        .ant-table-row:hover {
          .ant-table-cell {
            background-color: var(--color-row-hover);
          }
        }
      }
    }
  }
`;

export const ModalPeriodTimeWrapper = styled(Modal)<{
  messageerror: string;
}>`
  .ant-modal-content {
    padding: 0;
    background: var(--color-white);
    box-shadow: 0px 2px 8px 0px rgba(5, 40, 97, 0.08);
    border-radius: 12px;
  }

  .ant-modal-header {
    margin-bottom: 0;
    padding: 16px 24px;
    background: none;

    .ant-modal-title {
      font-weight: var(--font-bold);
      font-size: var(--font-size-base);
      line-height: 24px;
    }
  }

  .ant-modal-footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    padding: 12px 50px 24px;
    margin-top: 0;

    .ant-btn {
      width: 174px;
      height: 48px;
      box-shadow: none;
      font-weight: var(--font-bold);
      margin-inline-start: 0px !important;
    }

    .ant-btn-default {
      color: var(--color-primary);
      border-color: var(--color-primary);
    }
  }

  .ant-modal-body {
    padding: 0 24px;
    border-bottom: 1px solid #f1f3f7;
    border-top: 1px solid #f1f3f7;
    text-align: center;

    .period-time_message {
      margin-top: 14px;
      font-weight: var(--font-medium);
      font-size: var(--font-size-xl);
      line-height: 24px;
    }

    .period-time_datepicker {
      display: flex;
      align-items: center;
      column-gap: 4px;
      margin: ${(props) => (props.messageerror ? "16px 0 8px" : "16px 0")};

      .ant-form-item {
        margin-bottom: 0;

        .ant-form-item-label {
          font-weight: var(--font-normal);
          line-height: 16px;
          padding: 0 0 4px;

          label {
            font-size: var(--font-size-base);
          }
        }
      }

      .ant-divider-horizontal {
        min-width: 8px;
        width: 8px;
        border-block-start: 1px solid #cfd6e2;
        margin-top: 46px;
      }

      .ant-picker {
        height: 56px;

        .ant-picker-input input {
          font-size: var(--font-size-xl);
          font-weight: var(--font-medium);
          line-height: 24px;
        }

        .ant-picker-suffix {
          font-size: 24px;
          color: var(--color-secondary);
        }
      }

      .ant-picker-large {
        padding: 16px 12px;
      }
    }

    .period-time_message-error {
      font-size: var(--font-size-sm);
      font-weight: var(--font-normal);
      color: var(--color-error);
      text-align: left;
      margin-bottom: ${(props) => (props.messageerror ? "16px" : "0")};
    }
  }
`;
