import { ErrorLayout } from "components/ErrorLayout/ErrorLayout";
import { FC, ReactElement } from "react";
import { Outlet } from "react-router-dom";

type TProps = {
  children?: ReactElement;
};

const ErrorRoute: FC<TProps> = ({ children }) => {
  return <ErrorLayout>{children ? children : <Outlet />}</ErrorLayout>;
};

export { ErrorRoute };
